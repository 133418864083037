import React, { useState, useEffect } from "react";
import { Row, Col, Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../unitsOfMeasures.css";
import api from "../../../services/api";
import Fontawesome from "../../fontawesome/fontawesome";
import { Prefix } from "../../../services/prefix";
import Loading from "../../loader/loading";
import Swal from "sweetalert2";
import { mutate as GlobalMutate } from "swr";
import { Formik, Field, Form, ErrorMessage } from "formik";
import Schema from "./schema";
import { useFetch } from "../../hooks/useFetch";
import axios from "axios";
import Select from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import { loadOptions } from "../../selectAsync/loadOptions";
import { colourStyles } from "../../selectAsync/colourStyles";
import { ModalContainer } from "../../../style/stylesModals";
import { CustomButton, TextButton } from "../../../style/stylesButton";

const IndexEditUnitsOfMeasures = ({
  id,
  page,
  rowsPage,
  search,
  orderBy,
  order,
}) => {
  const [loader, setLoader] = useState();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };
  const [valueSelect, setValueSelect] = useState([]);

  let pageExact = page + 1;
  let valueSearch = !search ? "" : search;

  const unitofmeasuresList = useFetch(
    Prefix.replace(/[\\"]/g, "") + "/unit-of-measures/" + id
  );

  const [registerData, setRegisterData] = useState({});

  useEffect(() => {
    if (unitofmeasuresList?.data) {
      setRegisterData({
        name: unitofmeasuresList?.data?.model?.name,
        active: unitofmeasuresList?.data?.model?.active,
        id_measure: unitofmeasuresList?.data?.model?.id_measure,
      });
    }
  }, [unitofmeasuresList.data]);

  const HandleChange = (e) => {
    if (e.target.name == "name") {
      setRegisterData({
        ...registerData,
        [e.target.name]: e.target.value.replace("%", ""),
      });
    } else {
      setRegisterData({
        ...registerData,
        [e.target.name]: e.target.value.trim(),
      });
    }
  };

  const HandleSelect = (e, name) => {
    if (valueSelect.filter((fill) => fill.nameField == name).length > 0) {
      valueSelect
        .filter((fill) => fill.nameField == name)
        .map((map) => {
          let valueIndice = valueSelect.indexOf(map);
          valueSelect.splice(valueIndice);
        });

      setValueSelect((prev) => {
        return [...prev, { value: e.value, label: e.label, nameField: name }];
      });
      setRegisterData({ ...registerData, [name]: e.value });
    } else {
      setValueSelect((prev) => {
        return [...prev, { value: e.value, label: e.label, nameField: name }];
      });
      setRegisterData({ ...registerData, [name]: e.value });
    }
  };

  /*Atualizar dados do cliente*/
  function handleRegister(values) {
    setLoader(<Loading />);
    api
      .put(Prefix.replace(/[\\"]/g, "") + "/unit-of-measures/" + id, values)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Uow!",
          text: response.data.message,
          confirmButtonText: "Fechar",
        });
        setLoader(false);
        setRegisterData({});
        setShow(false);

        GlobalMutate(
          Prefix.replace(/[\\"]/g, "") +
            "/unit-of-measures?per_page=" +
            rowsPage +
            "&page=" +
            pageExact +
            "&search=" +
            valueSearch +
            "&order_by=" +
            orderBy +
            "&order=" +
            order
        );
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: err.response.data.message,
          confirmButtonText: "Tentar novamente",
        });
        setLoader();
      });
  }

  return (
    <>
      <Link
        to="#"
        variant="success"
        onClick={handleShow}
        className="link_color_datatable"
      >
        <Fontawesome classe="fas fa-pencil-alt" />
      </Link>

      <ModalContainer
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h3>
              Atualizar
              <br /> Unidade de Medida
            </h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loader && <Loading />}
          <Formik
            initialValues={registerData}
            validationSchema={Schema}
            onSubmit={handleRegister}
            enableReinitialize
          >
            {({ values }) => (
              <Form>
                <Row>
                  <Col>
                    <div className="formStep">
                      <label className="important">Grandeza</label>
                      <AsyncPaginate
                        value={
                          valueSelect.length == 0
                            ? {
                                value:
                                  unitofmeasuresList.data?.model?.measure.id,
                                label:
                                  unitofmeasuresList?.data?.model?.measure.name,
                              }
                            : valueSelect
                                .filter(
                                  (fill) => fill.nameField == "id_measure"
                                )
                                .map((map) => {
                                  return {
                                    value: map.value,
                                    label: map.label,
                                  };
                                })
                        }
                        loadOptions={loadOptions}
                        onChange={(e) => HandleSelect(e, "id_measure")} //values and name of select paginate
                        placeholder="Selecione..."
                        styles={colourStyles}
                        additional={{
                          page: 1,
                          endpoint: "/measures",
                          //params: `uf=${values.uf}`, //Separete with & (and commercial)
                        }}
                      />
                      <ErrorMessage
                        name="id_measure"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="formStep">
                      <label className="important">Unidade de Medida</label>
                      <Field
                        type="text"
                        placeholder="Digite um nome"
                        name="name"
                        onBlur={HandleChange}
                      />
                      <ErrorMessage
                        name="name"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className="d-flex justify-content-center">
                    <CustomButton type="submit">
                      <TextButton> Atualizar </TextButton>
                    </CustomButton>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </ModalContainer>
    </>
  );
};
export default IndexEditUnitsOfMeasures;
