import React, { useContext } from "react";
import api from "../../../services/api";
import { Prefix } from "../../../services/prefix";
import { mutate as globalMutate } from "swr";
import { Context } from "../../../services/context";

export default function UpdateStatus({
  id,
  page,
  rowsPage,
  activeDefault,
  orderBy,
  order,
}) {
  let pageExact = page + parseInt(1);

  const { selectclient, selectuf, selectcity } = useContext(Context);

  function HandleStatus() {
    api
      .put(Prefix.replace(/[\\"]/g, "") + "/units/" + id, {
        active:  Boolean(activeDefault === 1 ? 0 : 1),
      })
      .then(() => {

        globalMutate(
         Prefix.replace(/[\\"]/g, "") +
          "/units?per_page=" +
          rowsPage +
          "&page=" +
          pageExact +
          "&id_customer=" +
          selectclient +
          "&id_city=" +
          selectcity +
          "&uf=" +
          selectuf +
          "&order_by=" +
          orderBy +
          "&order=" +
          order
        );

      });
  }

  return (
    <>
      <label class="switch">
        <input
          type="checkbox"
          id={id + "units"}
          onChange={HandleStatus}
          checked={activeDefault == 1 || activeDefault == true ? true : false}
        />
        <span class="slider round"></span>
      </label>
    </>
  );
}
