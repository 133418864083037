import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import Slider from '@material-ui/core/Slider';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { useFetch } from '../../../../components/hooks/useFetch';
import { Prefix } from '../../../../services/prefix';
import Swal from 'sweetalert2';
import api from '../../../../services/api';
import { CardTracks, ButtonRange } from './EditMonitoringTracksStyle';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';

const EditMonitoringTracks = ({ idContractItem, HandleRange, sensor }) => {
  const [defaultTracks, setDefaultTracks] = useState([]);

  const listTracks = useFetch(
    Prefix.replace(/[\\"]/g, '') + '/monitor-profile-attributes?id=' + sensor,
  );

  useEffect(() => {
    setDefaultTracks(
      listTracks.data?.models.data.map((map) => {
        return {
          id: map.id,
          max: map.max,
          min: map.min,
          safety_margin: map.safety_margin,
          id_profile_attribute: map.id_profile_attribute,
        };
      }),
    );
  }, [listTracks.data]);

  function ValueLabelComponent(props) {
    const { children, open, value } = props;

    return (
      <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
        {children}
      </Tooltip>
    );
  }

  ValueLabelComponent.propTypes = {
    children: PropTypes.element.isRequired,
    open: PropTypes.bool.isRequired,
    value: PropTypes.number.isRequired,
  };

  const handleEditTrack = (event, newValue) => {
    const id = !newValue ? event.target.id : event.target.ariaLabel;

    let security = !defaultTracks[id]
      ? false
      : parseFloat(defaultTracks[id].max) == parseFloat(defaultTracks[id].min)
      ? true
      : false;

    if (security && defaultTracks[id] !== null && id !== null) {
      Swal.fire({
        icon: 'warning',
        title: 'Oops...',
        text: 'A margem de segurança exige um intervalo entre a mínima e a máxima, portanto selecione um valor que não a torne inválida.',
        confirmButtonText: 'Continuar',
      }).then((result) => {
        if (result.isConfirmed) {
          let newArray = [...defaultTracks];
          newArray[id] = {
            ...newArray[id],
            min: parseFloat(defaultTracks[id].min) - 1,
            max: parseFloat(defaultTracks[id].max) + 1,
          };

          setDefaultTracks(newArray);
        }
      });
    }
    if (defaultTracks[id] !== null && id !== null) {
      let newArray = [...defaultTracks];
      newArray[id] = { ...newArray[id], min: newValue[0], max: newValue[1] };
      setDefaultTracks(newArray);
    }
  };

  const handleSecurity = (e) => {
    const name = 'safety_margin';
    const value = e.target.ariaValueNow;
    const id = e.target.ariaLabel;

    if (defaultTracks[id] !== null && id !== null) {
      let newArray = [...defaultTracks];
      newArray[id] = { ...newArray[id], [name]: parseFloat(value) };

      setDefaultTracks(newArray);
    }
  };

  const handleUpdate = () => {
    defaultTracks.map((map) => {
      api
        .put(
          Prefix.replace(/[\\"]/g, '') +
            '/monitor-profile-attributes/' +
            map.id,
          {
            max: map.max,
            min: map.min,
            safety_margin: map.safety_margin,
            id_profile_attribute: map.id_profile_attribute,
          },
        )
        .then((r) => {
          Swal.fire({
            icon: 'success',
            title: 'Uow...',
            text: 'Suas alterações foram realizadas com sucesso!',
            confirmButtonText: 'Fechar',
          }).then((response) => {
            if (response.isConfirmed) {
              listTracks.mutate();
              HandleRange();
            }
          });
        })
        .catch((err) => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Houve um problema com a alteração! Por favor tente novamente mais tarde.',
            confirmButtonText: 'Fechar',
          });
        });
    });
  };

  return (
    <>
      <div className="customerStep">
        <form>
          {listTracks.data?.models?.data.map((map, key) => {
            return (
              <Row>
                <Col>
                  <CardTracks>
                    <h3 className="text-center">
                      Faixa de{' '}
                      {map.profile_attribute.unit_of_measure.measure.name}
                    </h3>
                    <label>Mínima e Máxima</label>
                    <Slider
                      value={
                        !defaultTracks
                          ? [parseFloat(map?.min), parseFloat(map?.max)]
                          : [
                              parseFloat(defaultTracks[key]?.min),
                              parseFloat(defaultTracks[key]?.max),
                            ]
                      }
                      step={0.25}
                      min={map.profile_attribute.min}
                      max={map.profile_attribute.max}
                      aria-label={key}
                      onChange={handleEditTrack}
                      onChangeCommitted={handleEditTrack}
                      valueLabelDisplay="on"
                      aria-labelledby="range-slider"
                      marks={[
                        {
                          value: map.profile_attribute.min,
                          label:
                            map.profile_attribute.min +
                            ' ' +
                            map.profile_attribute.unit_of_measure.name,
                        },
                        {
                          value: map.profile_attribute.max,
                          label:
                            map.profile_attribute.max +
                            ' ' +
                            map.profile_attribute.unit_of_measure.name,
                        },
                      ]}
                    />

                    <label>Margem segura</label>
                    <Slider
                      defaultValue={map.safety_margin}
                      aria-labelledby="discrete-slider-restrict"
                      aria-label={key}
                      step={0.25}
                      min={1}
                      max={
                        !defaultTracks
                          ? ''
                          : parseFloat(
                              parseFloat(defaultTracks[key]?.max) -
                                parseFloat(defaultTracks[key]?.min) -
                                0.25,
                            ) < 0.5
                          ? 0.5
                          : parseFloat(
                              parseFloat(defaultTracks[key]?.max) -
                                parseFloat(defaultTracks[key]?.min) -
                                0.25,
                            ) > 10
                          ? 10
                          : parseFloat(
                              parseFloat(defaultTracks[key]?.max) -
                                parseFloat(defaultTracks[key]?.min) -
                                0.25,
                            )
                      }
                      onChange={handleSecurity}
                      onChangeCommitted={handleSecurity}
                      valueLabelDisplay="auto"
                      marks={[
                        {
                          value: 1,
                          label: 1,
                        },
                        {
                          value: !defaultTracks
                            ? ''
                            : parseFloat(
                                parseFloat(defaultTracks[key]?.max) -
                                  parseFloat(defaultTracks[key]?.min) -
                                  0.25,
                              ) < 0.5
                            ? 0.5
                            : parseFloat(
                                parseFloat(defaultTracks[key]?.max) -
                                  parseFloat(defaultTracks[key]?.min) -
                                  0.25,
                              ) > 10
                            ? 10
                            : parseFloat(
                                parseFloat(defaultTracks[key]?.max) -
                                  parseFloat(defaultTracks[key]?.min) -
                                  0.25,
                              ),
                          label: !defaultTracks
                            ? ''
                            : parseFloat(
                                parseFloat(defaultTracks[key]?.max) -
                                  parseFloat(defaultTracks[key]?.min) -
                                  0.25,
                              ) < 0.5
                            ? 0.5
                            : parseFloat(
                                parseFloat(defaultTracks[key]?.max) -
                                  parseFloat(defaultTracks[key]?.min) -
                                  0.25,
                              ) > 10
                            ? 10
                            : parseFloat(
                                parseFloat(defaultTracks[key]?.max) -
                                  parseFloat(defaultTracks[key]?.min) -
                                  0.25,
                              ),
                        },
                      ]}
                    />
                  </CardTracks>
                </Col>
              </Row>
            );
          })}

          <Row>
            <Col className="d-flex justify-content-center mt-4">
              <ButtonRange onClick={handleUpdate} type="button">
                Atualizar
              </ButtonRange>
            </Col>
          </Row>
        </form>
      </div>
    </>
  );
};
export default EditMonitoringTracks;
