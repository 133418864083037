import React, { useState, useRef, useEffect, useContext } from 'react';
import { useFetch } from '../../../components/hooks/useFetch';
import { Prefix } from '../../../services/prefix';
import './sector.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Context } from '../../../services/context';

const FilterSector = () => {
  const { selectclient, selectunit, setSelectClient, setSelectUnit } =
    useContext(Context);

  const resetFilter = () => {
    setSelectClient('');
    setSelectUnit('');
  };

  const listClient = useFetch(Prefix.replace(/[\\"]/g, '') + '/customers');
  const listUnits = useFetch(Prefix.replace(/[\\"]/g, '') + '/units');

  return (
    <Row>
      <Col>
        <div className="filterAlerts">
          <select onChange={(e) => setSelectClient(e.target.value)}>
            <option selected disabled>
              Filtrar por Cliente
            </option>
            {listClient.data?.models.data.map((map, key) => {
              return (
                <option key={key} value={map.id}>
                  {map.name}
                </option>
              );
            })}
          </select>
          <select onChange={(e) => setSelectUnit(e.target.value)}>
            {!selectclient ? (
              <option selected disabled>
                Filtrar por Unidade
              </option>
            ) : (
              ''
            )}
            <option value="">Nenhuma Unidade</option>
            {listUnits.data?.models.data
              .filter((filtro) => filtro.id_customer == selectclient)
              .map((map, key) => {
                return (
                  <option key={key} value={map.id}>
                    {map.name}
                  </option>
                );
              })}
          </select>

          <Link to="#" className="ml-4" onClick={resetFilter}>
            <i class="fas fa-undo-alt"></i>
          </Link>
        </div>
      </Col>
    </Row>
  );
};
export default FilterSector;
