import React from 'react';
import moment from 'moment';
import LinkTelegram from './linkTelegram/linkTelegram';
import VerifyMessage from './verifyMessage/verifyMessage';

export const columns = [
  {
    //0
    name: 'id',
    label: '',
    options: {
      filter: false,
      sort: false,
      display: 'excluded',
    },
  },

  {
    //1
    name: 'date',
    label: 'Data',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
          width: '20%',
        },
      }),
      filter: false,
      sort: false,
      customBodyRender: (value, tableMeta) => {
        return moment(value).format('DD/MM/YYYY | HH:mm:ss');
      },
    },
  },
  {
    //1
    name: 'name',
    label: 'Nome',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
          width: '20%',
        },
      }),
      filter: false,
      sort: false,
    },
  },
  {
    //1
    name: 'text',
    label: 'Mensagem',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
          width: '40%',
        },
      }),
      filter: false,
      sort: false,
      customBodyRender: (value, tableMeta) => {
        return <VerifyMessage message={value} />;
      },
    },
  },
  {
    //1
    name: 'chat_id',
    label: 'Vincular',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
          width: '20%',
        },
      }),
      filter: false,
      sort: false,
      customBodyRender: (value, tableMeta) => {
        return <LinkTelegram chatId={value} />;
      },
    },
  },
];
