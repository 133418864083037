import React from "react";
import moment from 'moment';

export const columns = [
  {
    //0
    name: "id",
    label: "",
    options: {
      setCellHeaderProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      setCellProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      filter: false,
      sort: false,
      display: "excluded",
    },
  },
  {
    //1
    name: "created_at",
    label: "Data",
    options: {
      setCellHeaderProps: () => ({
        style: {
          "text-align": "center",
          "width": " 32%",
        },
      }),
      setCellProps: () => ({
        style: {
          "text-align": "center",
          "width": " 32%",
        },
      }),
      filter: false,
      sort: false,
      display: true,
      customBodyRender: (value, tableMeta) => {
        const dateValue = value.split('T');
        return moment(dateValue[0]).format("DD/MM/YYYY");

      },
    },

  },
  {
    //2
    name: "comments",
    label: "Comentário",
    options: {
      setCellHeaderProps: () => ({
        style: {
          "text-align": "center",
          "width": " 32%",
        },
      }),
      setCellProps: () => ({
        style: {
          "text-align": "center",
          "width": " 32%",
        },
      }),
      filter: false,
      sort: false,
      display: true,
    },
  },
  {
    //3
    name: "restricted",
    label: "Restrito",
    options: {
      setCellHeaderProps: () => ({
        style: {
          "text-align": "center",
          "width": " 32%",
        },
      }),
      setCellProps: () => ({
        style: {
          "text-align": "center",
          "width": " 32%",
        },
      }),
      filter: false,
      sort: false,
      display: true,
      customBodyRender: (value, tableMeta) => {
        return value == true ? <img src="../../../media/icons/proposalcheck.svg" width='24px' /> : <img src="../../../media/icons/proposalfalse.svg" width='24px' />;
      },
    },
  },
];
