import React, { useState } from 'react';
import { Col, Row, Alert, Modal, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { IconOption } from '../../optionsProposalsStyle';
import { mutate as GlobalMutate } from 'swr';
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import { SchemaComments } from './schema';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { GetMuiTheme } from '../../../../components/muiTheme/muiTheme';
import api from '../../../../services/api';
import { Prefix } from '../../../../services/prefix';
import Loading from '../../../loader/loading';
import Swal from 'sweetalert2';
import { useFetch } from '../../../hooks/useFetch';
import {
  Caption,
  ButtonProposal,
  TextProposal,
  Input,
  ConfirmUpload,
  BoxUpload,
  BoxConfirmUpload,
  ButtonDesabled,
} from './styles';
import { ModalContainer } from '../../../../style/stylesModals';
import MUIDataTable from 'mui-datatables';
import CustomSearchRender from '../../../customSearchRender/CustomSearchRender';
import CustomFooter from '../../../CustomFooter/CustomFooter';
import { columns } from './columns';
import { DropContainer, UploadMessage } from './uploadFile';
import Dropzone from 'react-dropzone';
import { uniqueId } from 'lodash';
import { element } from 'prop-types';
import { Label } from '@material-ui/icons';

const Attachments = ({ id }) => {
  const [Loader, setLoader] = useState(false);
  const [pagina, setPagina] = useState(0);
  const [perPage, setPerPage] = useState(15);
  const [search, setSearch] = useState('');
  const [show, setShow] = useState(false);
  let pageExact = pagina + parseInt(1);
  const [valueSelect, setValueSelect] = useState([]);
  const [nameAttach, setNameAttach] = useState();
  const [uploaded, setUploaded] = useState([]);
  const [registerData, setRegisterData] = useState();
  const data = new FormData();

  const attachmentsList = useFetch(
    Prefix.replace(/[\\"]/g, '') +
    '/proposal-attachments?per_page=' +
    perPage +
    '&page=' +
    pageExact +
    '&search=' +
    search +
    '&id_proposal=' +
    id,
  );

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const HandleUpload = (files) => {
    if (!nameAttach) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Digite um nome antes de enviar o arquivo...',
        confirmButtonText: 'Continuar',
      });
    } else {
      const uploadedFiles = files.map((file) => ({
        file,
        id: uniqueId(),
        name: nameAttach,
        readableSize: file.size,
        path: URL.createObjectURL(file),
        progress: 0,
        uploaded: false,
        error: false,
        url: null,
        id_proposal: id,
      }));

      setUploaded({
        ...uploaded,
        uploadedFiles,
      });
    }
  };

  const handleRegister = () => {
    setLoader(<Loading />);

    const data = new FormData();
    uploaded.uploadedFiles.forEach((element) => {
      data.append('path', element.file);
      data.append('name', element.name);
      data.append('id_proposal', element.id_proposal);
    });

    api
      .post(Prefix.replace(/[\\"]/g, '') + '/proposal-attachments', data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then((response) => {
        Swal.fire({
          icon: 'success',
          title: 'Uow!',
          text: response.data.message,
          confirmButtonText: 'Fechar',
        }).then(() => {
          setLoader(false);
        });
        GlobalMutate(
          Prefix.replace(/[\\"]/g, '') +
          '/proposal-attachments?per_page=' +
          perPage +
          '&page=' +
          pageExact +
          '&search=' +
          search +
          '&id_proposal=' +
          id,
        );
        setUploaded([]);
      })
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: err.response.data.message,
          confirmButtonText: 'Tentar novamente',
        });
        setLoader(false);
        setUploaded([]);
      });
  };

  function renderDragMessage(isDragActive, isDragReject) {
    if (!isDragActive) {
      return (
        <UploadMessage>Arraste os arquivos aqui (Máximo: 5mb)</UploadMessage>
      );
    }
    if (isDragReject) {
      return <UploadMessage type="error">Arquivo não suportado</UploadMessage>;
    }

    return <UploadMessage type="success">Solte os arquivos aqui</UploadMessage>;
  }

  function HandleDelete(e) {
    setLoader(true);
    let idArr = [];
    e.map((del) => {
      idArr.push(
        attachmentsList.data?.models?.data?.filter(
          (filtro, index) => index == del.index,
        )[0].id,
      );
    });

    idArr.map((idDel) =>
      api
        .delete(
          Prefix.replace(/[\\"]/g, '') + '/proposal-attachments/' + idDel,
          {
            id: idArr,
          },
        )
        .then((response) => {
          Swal.fire({
            icon: 'success',
            title: 'Uow!',
            text: response.data.message,
            confirmButtonText: 'Fechar',
          });
          setLoader();
          GlobalMutate(
            Prefix.replace(/[\\"]/g, '') +
            '/proposal-attachments?per_page=' +
            perPage +
            '&page=' +
            pageExact +
            '&search=' +
            search +
            '&id_proposal=' +
            id,
          );
        })
        .catch((err) => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: err.response.data.message,
            confirmButtonText: 'Tentar novamente',
          });
          setLoader();
        }),
    );
  }

  const options = {
    draggableColumns: {
      enabled: true,
    },
    serverSide: true,
    onTableChange: (action, tableState) => {
      setPagina(tableState.page);
      setPerPage(tableState.rowsPerPage);
    },
    onSearchChange: (searchText) => {
      setSearch(!search ? '' : searchText);
    },
    customSearchRender: (searchText, handleSearch, hideSearch, options) => {
      return (
        <CustomSearchRender
          placeholderSearch="Pesquisar Anexo"
          searchText={searchText}
          onSearch={handleSearch}
          onHide={hideSearch}
          options={options}
        />
      );
    },
    filter: false,
    page: pagina,
    count: attachmentsList.data?.models?.data?.total,
    expandableRows: false,
    expandableRowsHeader: false,
    expandableRowsOnClick: false,
    rowsPerPage: perPage,
    rowsPerPageOptions: [15, 30, 100],
    onRowsDelete: (rowsDeleted, newData) => {
      HandleDelete(rowsDeleted.data);
    },
    searchPlaceholder: 'Pesquise uma Anexo',
    selectableRows: true,
    searchOpen: false,

    textLabels: {
      body: {
        noMatch: 'Desculpe, não encontramos Anexo!',
        toolTip: 'Sort',
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
      pagination: {
        next: 'Avançar',
        previous: 'Voltar',
        rowsPerPage: 'Linhas por página:',
        displayRows: 'de',
      },
      toolbar: {
        search: 'Pesquisar',
        downloadCsv: 'Baixar CSV',
        print: 'Imprimir',
        viewColumns: 'Ver Colunas',
        filterTable: 'Filtrar tabela',
      },
      filter: {
        all: 'Tudo',
        title: 'FILTROS',
        reset: 'RESETAR',
      },
      viewColumns: {
        title: 'Exibir colunas',
        titleAria: 'Exibir/Ocultar colunas da tabela',
      },
      selectedRows: {
        text: 'Coluna(s) selecionada(s)',
        delete: 'Excluir',
        deleteAria: 'Excluir linhas selecionadas',
      },
    },
    customFooter: (
      count,
      page,
      rowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels,
    ) => {
      let numberPages = attachmentsList.data?.models.data.last_page;
      let rowsPerPageOptions = [15, 30, 100];
      return (
        <CustomFooter
          page={page}
          rowsPerPage={rowsPerPage}
          changeRowsPerPage={changeRowsPerPage}
          changePage={changePage}
          numberPages={numberPages}
          rowsPerPageOptions={rowsPerPageOptions}
        />
      );
    },
  };
  return (
    <>
      {!show ? (
        <Link className="dropdown-item" to="#" onClick={handleShow}>
          <IconOption src="../../../media/icons/attachments.svg" />
          Anexos
        </Link>
      ) : (
        <ModalContainer show={show} onHide={handleClose} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Anexos</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Caption>Anexos relacionados a proposta</Caption>
            <Row className="d-flex justify-content-center">
              <Col sm={7}>
                <div>
                  <Input
                    placeholder="Digite um nome"
                    type="text"
                    name="name"
                    onBlur={(e) => setNameAttach(e.target.value)}
                  />
                </div>
              </Col>
            </Row>
            <Row className="d-flex justify-content-center">
              <Col sm={7}>
                {uploaded.length == 0 ? (
                  <BoxUpload>
                    <Dropzone
                      maxFiles="1"
                      accept="application/pdf, image/*"
                      onDrop={(acceptedFiles) => HandleUpload(acceptedFiles)}
                    >
                      {({
                        getRootProps,
                        getInputProps,
                        isDragActive,
                        isDragReject,
                      }) => (
                        <DropContainer
                          {...getRootProps()}
                          isDragActive={isDragActive}
                          isDragReject={isDragReject}
                        >
                          <input {...getInputProps()} />
                          {renderDragMessage(isDragActive, isDragReject)}
                        </DropContainer>
                      )}
                    </Dropzone>
                  </BoxUpload>
                ) : (
                  <>
                    <BoxConfirmUpload>
                      <ConfirmUpload src="../../../media/icons/loader-ckeck.svg" />
                      <br />
                      <span>Carregamento Completo</span>
                    </BoxConfirmUpload>
                  </>
                )}
              </Col>
            </Row>

            <Row>
              <Col className="d-flex justify-content-center ">
                {uploaded.length == 0 ? (
                  <>
                    <ButtonDesabled onClick={handleRegister} disabled>
                      <TextProposal> Adicionar </TextProposal>
                    </ButtonDesabled>
                  </>
                ) : (
                  <>
                    <ButtonProposal onClick={handleRegister}>
                      <TextProposal> Adicionar </TextProposal>
                    </ButtonProposal>
                  </>
                )}
              </Col>
            </Row>

            <div style={{ position: 'relative', zIndex: '0' }}>
              <MUIDataTable
                data={attachmentsList.data?.models?.data}
                columns={columns}
                options={options}
              />
            </div>
          </Modal.Body>
        </ModalContainer>
      )}
    </>
  );
};
export default Attachments;
