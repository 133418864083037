import React, { useState, useRef } from "react";
import { Col, Row, Button, Modal } from "react-bootstrap";
import "../users.css";
import { Prefix } from "../../../services/prefix";
import Loading from "../../loader/loading";
import Swal from "sweetalert2";
import api from "../../../services/api";
import { userType } from "../../data/userType";
import Fontawesome from "../../fontawesome/fontawesome";
import { mutate as GlobalMutate } from "swr";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Schema from "./schema";
import { useFetch } from "../../hooks/useFetch";
import { NoOptionsMessage } from "../../selectAsync/NoOptionsMessage";
import { ListUsersTypes } from "../listUsersTypes";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import { useStyles } from "../../buttons/AddButtonStyle";
import { ModalContainer } from "../../../style/stylesModals"; 
import { CustomButton, TextButton } from "../../../style/stylesButton";

const IndexAddUser = (props) => {
  const [loader, setLoader] = useState();
  const [show, setShow] = useState(false);

  const [initialState, setInitialState] = useState({
    name: "",
    email: "",
    password: "",
    password_confirmation: "",
    type: "",
    active: "1",
  });
  const [registerData, SetRegisterData] = useState(initialState);

  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
  };

  function HandleRegister(values) {
    setLoader(<Loading />);
    api
      .post(Prefix.replace(/[\\"]/g, "") + "/users", values)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Uow!",
          text: response.data.message,
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: "Fechar",
          cancelButtonText: "Novo Cadastro",
          allowOutsideClick: false,
        }).then((result) => {
          if (!result.isConfirmed) {
            setShow(true);
          }
        });
        setLoader();
        setShow(false);
        GlobalMutate(
          Prefix.replace(/[\\"]/g, "") +
            "/users?per_page=" +
            props.perPage +
            "&page=" +
            props.pageExact +
            "&search=" +
            props.search +
            "&order_by=" +
            props.orderBy +
            "&order=" +
            props.order
        );
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: err.response.data.message,
          confirmButtonText: "Tentar novamente",
        });
        setLoader();
      });
  }

  const classes = useStyles();

  return (
    <>
      {show === false ? (
        <Fab className={classes.fab} onClick={handleShow}>
          <AddIcon className={classes.icon} />
        </Fab>
      ) : (
        ""
      )}
      <ModalContainer
        show={show}
        size={props.modalSize}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title><h3>{props.titleLabel}</h3></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik //--> Configuração do Formik
            initialValues={registerData}
            validationSchema={Schema}
            onSubmit={HandleRegister}
          >
            {(
              { values } //--> Captação de valores
            ) => (
              <Form>
                <Row>
                  {loader}
                  <Col>
                    <div className="formStep">
                      <label class="important">Nome do usuário</label>
                      <Field
                        type="text"
                        placeholder="Digite um usuário"
                        name="name"
                        value={values.name}
                      />
                      <ErrorMessage
                        name="name"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div className="formStep">
                      <label class="important">E-mail</label>
                      <Field
                        type="email"
                        placeholder="Digite um e-mail"
                        name="email"
                        value={values.email}
                      />
                      <ErrorMessage
                        name="email"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div className="formStep">
                      <label class="important">Senha</label>
                      <Field
                        type="password"
                        placeholder="Digite uma senha"
                        name="password"
                        value={values.password}
                      />
                      <ErrorMessage
                        name="password"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div className="formStep">
                      <label class="important">Confirme a senha</label>
                      <Field
                        type="password"
                        placeholder="Confirme a senha"
                        name="password_confirmation"
                        value={values.password_confirmation}
                      />
                      <ErrorMessage
                        name="password_confirmation"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div className="formStep">
                      <label class="important">Tipo de conta</label>
                      <Field name="type" as="select">
                        <option selected>Selecione...</option>
                        {ListUsersTypes.map((type) => {
                          return (
                            <option value={type.value}>{type.label}</option>
                          );
                        })}
                      </Field>
                      <ErrorMessage
                        name="type"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col className="d-flex justify-content-center">
                    <CustomButton type="submit">
                      <TextButton>Cadastrar</TextButton>
                    </CustomButton>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </ModalContainer>
    </>
  );
};
export default IndexAddUser;
