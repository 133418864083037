import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';

export const CardMonitoring = styled.div`
  width: 100%;
  min-height: 223px;
  background: ${(props) => (props.type == true ? '#F6EDFE' : '#DFFFFE')};
  border-radius: 15px;
  color: #000;
  border: 2px solid #fff;

  &:hover {
    opacity: 0.7;
    transition: 0.4s;
  }

  @media (max-width: 800px) {
    margin-bottom: 10px;
  }
`;

export const TitleCardMonitoring = styled.div`
  width: 100%;
  height: 40px;
  border-radius: 15px 15px 0 0;
  padding: 0 15px;
  display: flex;
  align-items: center;
  color: #000;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1.2px;
`;

export const TitleBoxOne = styled.span`
  width: 80%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const TitleBoxTwo = styled.span`
  width: 20%;
  display: flex;
  justify-content: end;
`;

export const TabletIcon = styled.img`
  margin-right: 15px;
`;

export const TagMonitoring = styled.div`
  width: 100%;
  height: 25px;
  background: ${(props) => (props.type == true ? '#5F4083' : '#21C1BE')};
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  letter-spacing: 1.2px;
  font-size: 10px;
`;

export const Measure = styled.div`
  color: #000;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const RealMeasure = styled.div`
  display: flex;
  justify-content: center;
`;

export const IconMeasure = styled.img`
  margin-right: 10px;
`;

export const LimitMeasure = styled.div`
  width: 100%
  height: 25px;
  background: #fff;
  display: flex;
  font-size: 12px;
  border-radius: 10px;
  padding: 5px;
  margin-top: 5px
`;

export const InsideLimitMeasure = styled.div`
  display: flex;
  width: 50%;
  justify-content: center;
  text-align: center;
  flex-direction: column;

  & > span {
    font-weight: 100;
  }
`;

export const TagInfo = styled.div`
  width: 100%;
  height: 25px;
  background: #909090;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  letter-spacing: 1px;
  margin-top: 10px;
  font-size: 10px;

  & > strong {
    margin-right: 3px;
  }
`;

export const LastReading = styled.div`
  width: 100%;
  height: 25px;
  padding: 5px;
  text-align: center;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  font-size: 10px;
`;

export const Ellipse = styled.img`
  margin: 0 10px;
`;

export const RoomMonitoring = styled.div`
  max-width: 100%;
  min-height: 200px;
  background: #ebebeb;
  border-radius: 15px;
  padding: 15px;
  margin: 5px;
`;

export const Location = styled.div``;
export const TotalItems = styled.div`
  margin-right: 15px;
`;

export const LocationMonitoring = styled.div`
  width: 100%;
  height: 35px;
  background: #fff;
  border-radius: 10px;
  margin-bottom: 10px;
  display: flex;
  padding-left: 10px;
  align-items: center;
  justify-content: space-between;
`;

export const IconLocationMonitoring = styled.img`
  margin: 0 10px;
  width: 20px;
`;

export const IconArrowLocation = styled.img`
  margin: 0 10px;
  width: 15px;
`;

export const LayoutGrid = styled.img`
  width: 28px;
  height: 28px;
  cursor: pointer;

  &:hover {
    opacity: 0.4;
    transition: 0.5s;
  }
`;

export const NoRead = styled.div`
  width: 100%;
  height: 85px;
  color: #b7454d;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid #b7454d;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1.2px;
`;
