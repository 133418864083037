import React, { useState, useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Signal from '../signal/signal';
import Lights from '../lights/lights';
import Battery from '../battery/battery';
import {
  CardMonitoring,
  TitleCardMonitoring,
  TabletIcon,
  TitleBoxOne,
  TitleBoxTwo,
  TagMonitoring,
  Measure,
  IconMeasure,
  LimitMeasure,
  RealMeasure,
  InsideLimitMeasure,
  TagInfo,
  LastReading,
  Ellipse,
  RoomMonitoring,
  LocationMonitoring,
  IconLocationMonitoring,
  IconArrowLocation,
  Location,
  TotalItems,
  LayoutGrid,
  NoRead,
} from './monitoringStyle';
import { useFetch } from '../../../components/hooks/useFetch';
import { Prefix } from '../../../services/prefix';
import { versionCollector } from '../../../components/data/versionCollector';
import { AsyncPaginate } from 'react-select-async-paginate';
import { loadOptions } from '../../../components/selectAsync/loadOptions';
import { NoOptionsMessage } from '../../../components/selectAsync/NoOptionsMessage';
import { Context } from '../../../services/context';
import Loading from '../../../components/loader/loading';
import moment from 'moment';

const Monitoring = ({ idUnit, nameUnit }) => {
  const [valueSelect, setValueSelect] = useState([]);
  const [layout, setLayout] = useState(false);
  const { setViewSismogran } = useContext(Context);

  const listRooms = useFetch(
    !idUnit
      ? null
      : valueSelect.length == 0
        ? Prefix.replace(/[\\"]/g, '') +
        '/sismogran/map/units/' +
        idUnit +
        '/rooms'
        : Prefix.replace(/[\\"]/g, '') +
        '/sismogran/map/units/' +
        idUnit +
        '/rooms?sector=' +
        valueSelect
          .filter((fill) => fill.nameField == 'id_sector')
          .map((sec) => sec.value) +
        '&room=' +
        valueSelect
          .filter((fill) => fill.nameField == 'id_room')
          .map((room) => room.value),
  );

  const invertArray = listRooms.data?.models.sort(
    (a, b) => b.contractItems.length - a.contractItems.length,
  );

  const HandleSelect = (e, name) => {
    if (valueSelect.filter((fill) => fill.nameField == name).length > 0) {
      valueSelect
        .filter((fill) => fill.nameField == name)
        .map((map) => {
          let valueIndice = valueSelect.indexOf(map);
          valueSelect.splice(valueIndice);
        });

      setValueSelect((prev) => {
        return [...prev, { value: e.value, label: e.label, nameField: name }];
      });
    } else {
      setValueSelect((prev) => {
        return [...prev, { value: e.value, label: e.label, nameField: name }];
      });
    }
  };

  return (
    <>
      {listRooms.data && (
        <Row className="mb-4">
          <Col md={6} className="d-flex justify-content-between filterMonit">
            <AsyncPaginate
              value={valueSelect
                .filter((fill) => fill.nameField == 'id_sector')
                .map((map) => {
                  return {
                    value: map.value,
                    label: map.label,
                  };
                })}
              loadOptions={loadOptions}
              components={{ NoOptionsMessage }}
              onChange={(e) => HandleSelect(e, 'id_sector')} //values and name of select paginate
              placeholder="Filtrar por setor..."
              cacheUniqs={[valueSelect]} //Set your param that is going to change when you select another field. Just use this option when you are setting params and need to refresh cache.
              classNamePrefix="filterMonitoring"
              additional={{
                page: 1,
                endpoint: '/sectors',
                params: `id_unit=${idUnit}`, //Separete with & (and commercial)
              }}
            />
            <AsyncPaginate
              value={valueSelect
                .filter((fill) => fill.nameField == 'id_room')
                .map((map) => {
                  return {
                    value: map.value,
                    label: map.label,
                  };
                })}
              loadOptions={loadOptions}
              components={{ NoOptionsMessage }}
              onChange={(e) => HandleSelect(e, 'id_room')} //values and name of select paginate
              placeholder="Filtrar por sala"
              cacheUniqs={[valueSelect]} //Set your param that is going to change when you select another field. Just use this option when you are setting params and need to refresh cache.
              classNamePrefix="filterMonitoring"
              additional={{
                page: 1,
                endpoint: '/rooms',
                params: `id_unit=${idUnit}&id_sector=${valueSelect
                  .filter((fill) => fill.nameField == 'id_sector')
                  .map((sec) => sec.value)}`, //Separete with & (and commercial)
              }}
            />
          </Col>
          <Col
            md={{ span: 4, offset: 2 }}
            className="d-flex justify-content-end align-items-center"
          >
            <LayoutGrid
              src="../../../../media/icons/grid1.png"
              onClick={() => setLayout(false)}
            />
            <LayoutGrid
              src="../../../../media/icons/grid2.png"
              onClick={() => setLayout(true)}
            />
          </Col>
        </Row>
      )}
      <Row>
        {!listRooms.data ? (
          <Loading />
        ) : (
          invertArray?.map((monit) => (
            <Col
              sm={
                layout == false
                  ? 12
                  : monit.contractItems.length == 1
                    ? 3
                    : monit.contractItems.length == 2
                      ? 6
                      : monit.contractItems.length == 3
                        ? 9
                        : 12
              }
            >
              <RoomMonitoring>
                <LocationMonitoring>
                  <Location>
                    <IconLocationMonitoring src="../../../media/icons/Location.svg" />{' '}
                    {monit.sector}
                    <IconArrowLocation src="../../../media/icons/arrow-location.svg" />
                    {monit.name}
                  </Location>
                  {layout == false && (
                    <TotalItems>
                      <strong>{monit.contractItems.length}</strong>{' '}
                      Equipamento(s)
                    </TotalItems>
                  )}
                </LocationMonitoring>

                <Row>
                  {monit?.contractItems?.map((items) => (
                    <Col
                      md={
                        layout == false
                          ? 3
                          : monit.contractItems.length == 1
                            ? 12
                            : monit.contractItems.length == 2
                              ? 6
                              : monit.contractItems.length == 3
                                ? 4
                                : 3
                      }
                    >
                      <Link
                        onClick={() =>
                          items.sensors?.length == 0
                            ? null
                            : setViewSismogran(items.id)
                        }
                      >
                        <CardMonitoring
                          type={items.monitor_environment}
                          className="mb-3"
                        >
                          <TitleCardMonitoring>
                            <TitleBoxOne title={items.nickname}>
                              <TabletIcon src="../../../media/icons/tablet.svg" />
                              {items.nickname}
                            </TitleBoxOne>
                            <TitleBoxTwo>
                              <Signal
                                signal={items.signal_quality}
                                network={items.network}
                                situation={items.situation}
                              />
                              {/* <Battery
                                level={items.batterylevel}
                                signal={
                                  items.situation == 'SUCCESS'
                                    ? 1
                                    : items.situation == 'WARNING'
                                    ? 2
                                    : items.situation == 'DANGER'
                                    ? 3
                                    : items.situation == 'DARK'
                                    ? 4
                                    : 5
                                }
                              /> */}
                              <Lights
                                message={items.message}
                                signal={
                                  items.situation == 'SUCCESS'
                                    ? 1
                                    : items.situation == 'WARNING'
                                      ? 2
                                      : items.situation == 'DANGER'
                                        ? 3
                                        : items.situation == 'DARK'
                                          ? 4
                                          : 5
                                }
                              />
                            </TitleBoxTwo>
                          </TitleCardMonitoring>
                          <TagMonitoring type={items.monitor_environment}>
                            {!items.monitor_environment
                              ? 'Coletor de equipamento'
                              : 'Coletor de ambiente'}
                          </TagMonitoring>
                          <Row className="m-2">
                            {items.sensors?.length == 0 ? (
                              <Col md={12}>
                                <NoRead>Sem leituras</NoRead>
                              </Col>
                            ) : (
                              items.sensors?.map((sens) => (
                                <Col>
                                  <Measure>
                                    <RealMeasure>
                                      <IconMeasure
                                        src={
                                          sens.name_measure == 'Temperatura'
                                            ? '../../../media/icons/temperature.svg'
                                            : '../../../media/icons/humidity.svg'
                                        }
                                      />
                                      {!sens.value ? 0.0 : sens.value}
                                      {!sens.name_unitOfMeasure
                                        ? ''
                                        : sens.name_measure == 'Umidade'
                                          ? ' %' + sens.name_unitOfMeasure
                                          : sens.name_unitOfMeasure}
                                    </RealMeasure>

                                    <LimitMeasure>
                                      <InsideLimitMeasure>
                                        <strong>Min</strong>
                                        <span>{sens.min}</span>
                                      </InsideLimitMeasure>
                                      <InsideLimitMeasure>
                                        <strong>Máx</strong>
                                        <span>{sens.max}</span>
                                      </InsideLimitMeasure>
                                    </LimitMeasure>
                                  </Measure>
                                </Col>
                              ))
                            )}
                          </Row>
                          <TagInfo>
                            <strong>Coletor: </strong> {items.serial}{' '}
                            <Ellipse src="../../../media/icons/ellipse.svg" />
                            <strong>Tag: </strong>{' '}
                            {versionCollector.filter(
                              (fill) => fill.value == items.version,
                            ).length == 0
                              ? 'Nenhuma'
                              : versionCollector
                                .filter((fill) => fill.value == items.version)
                                .map((version) => version.label)}
                          </TagInfo>
                          <LastReading>
                            <strong>Última leitura:</strong>{' '}
                            {!items.readed_at
                              ? 'Sem leituras'
                              : moment(items.readed_at).format(
                                'DD/MM/YY HH:mm:ss',
                              )}
                          </LastReading>
                        </CardMonitoring>
                      </Link>
                    </Col>
                  ))}
                </Row>
              </RoomMonitoring>
            </Col>
          ))
        )}
      </Row>
    </>
  );
};
export default Monitoring;
