import React from 'react';
import Grow from '@material-ui/core/Grow';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import { withStyles } from '@material-ui/core/styles';

const defaultSearchStyles = (theme) => ({
  main: {
    display: 'flex',
    flex: '1 0 auto',
  },
  searchText: {
    flex: '0.8 0',
  },
  clearIcon: {
    '&:hover': {
      color: theme.palette.error.main,
    },
  },
});

class CustomSearchRender extends React.Component {
  constructor(props) {
    super(props);
    this.state = { name: '', typing: false, typingTimeout: 0 };
  }

  handleTextChange = (event) => {
    const self = this;

    if (self.state.typingTimeout) {
      clearTimeout(self.state.typingTimeout);
    }

    self.setState({
      name: event.target.value,
      typing: false,
      typingTimeout: setTimeout(function () {
        self.props.onSearch(self.state.name);
      }, 800),
    });
  };

  componentDidMount() {
    document.addEventListener('keydown', this.onKeyDown, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown, false);
  }

  onKeyDown = (event) => {
    if (event.keyCode === 27) {
      this.props.onHide();
    }
  };

  clearSearch = () => {
    this.props.onSearch('');
  };

  render() {
    const { classes, options, onHide, searchText, placeholderSearch } =
      this.props;

    return (
      <Grow appear in={true} timeout={500}>
        <div className={classes.main} ref={(el) => (this.rootRef = el)}>
          <TextField
            placeholder={placeholderSearch}
            className={classes.searchText}
            InputProps={{
              'aria-label': options.textLabels.toolbar.search,
            }}
            defaultValue={searchText}
            onKeyUp={this.handleTextChange}
            fullWidth={true}
            inputRef={(el) => (this.searchField = el)}
          />
          <IconButton className={classes.clearIcon} onClick={this.clearSearch}>
            <RotateLeftIcon />
          </IconButton>
        </div>
      </Grow>
    );
  }
}

export default withStyles(defaultSearchStyles, { name: 'CustomSearchRender' })(
  CustomSearchRender,
);
