import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import Slider from '@material-ui/core/Slider';
import { useFetch } from '../../hooks/useFetch';
import { Prefix } from '../../../services/prefix';
import Swal from 'sweetalert2';
import api from '../../../services/api';
import { CardTracks, ButtonRange } from './editRangesStyles';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';
import { mutate as mutateGlobal } from 'swr';

const AddRanges = ({ sensor, idEquipmentModel, idEquipment }) => {
  const [defaultTracks, setDefaultTracks] = useState([]);

  const listTracks = useFetch(
    Prefix.replace(/[\\"]/g, '') + '/profile-attributes?id=' + sensor,
  );

  useEffect(() => {
    setDefaultTracks(
      listTracks.data?.models.data.map((map) => {
        return {
          id_model_equipament: idEquipmentModel,
          max: map.max,
          min: map.min,
          safety_margin: map.safety_margin,
          id_unit_of_measure: map.id_unit_of_measure,
          id_profile_attribute: map.id,
        };
      }),
    );
  }, [listTracks.data]);

  function ValueLabelComponent(props) {
    const { children, open, value } = props;

    return (
      <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
        {children}
      </Tooltip>
    );
  }

  ValueLabelComponent.propTypes = {
    children: PropTypes.element.isRequired,
    open: PropTypes.bool.isRequired,
    value: PropTypes.number.isRequired,
  };

  const handleEditTrack = (event, newValue) => {
    const id = !newValue ? event.target.id : event.target.ariaLabel;

    let security = !defaultTracks[id]
      ? false
      : parseFloat(defaultTracks[id].max) == parseFloat(defaultTracks[id].min)
      ? true
      : false;

    if (security && defaultTracks[id] !== null && id !== null) {
      Swal.fire({
        icon: 'warning',
        title: 'Oops...',
        text: 'A margem de segurança exige um intervalo entre a mínima e a máxima, portanto selecione um valor que não a torne inválida.',
        confirmButtonText: 'Continuar',
      }).then((result) => {
        if (result.isConfirmed) {
          let newArray = [...defaultTracks];
          newArray[id] = {
            ...newArray[id],
            min: parseFloat(defaultTracks[id].min) - 1,
            max: parseFloat(defaultTracks[id].max) + 1,
          };

          setDefaultTracks(newArray);
        }
      });
    }
    if (defaultTracks[id] !== null && id !== null) {
      let newArray = [...defaultTracks];
      newArray[id] = { ...newArray[id], min: newValue[0], max: newValue[1] };
      setDefaultTracks(newArray);
    }
  };

  const handleSecurity = (e) => {
    const name = 'safety_margin';
    const value = e.target.ariaValueNow;
    const id = e.target.ariaLabel;

    if (defaultTracks[id] !== null && id !== null) {
      let newArray = [...defaultTracks];
      newArray[id] = { ...newArray[id], [name]: parseFloat(value) };

      setDefaultTracks(newArray);
    }
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const id = e.target.id;

    let newArray = [...defaultTracks];
    newArray[id] = { ...newArray[id], [name]: value };
    setDefaultTracks(newArray);
  };

  const handleUpdate = () => {
    defaultTracks.map((map) => {
      api
        .post(Prefix.replace(/[\\"]/g, '') + '/monitor-profile-attributes', {
          id_contract_item: idEquipment,
          max: map.max,
          min: map.min,
          active: 1,
          safety_margin: map.safety_margin,
          id_profile_attribute: map.id_profile_attribute,
          safety_margin_time: map.safety_margin_time,
          variation: map.variation.replace(',', '.'),
        })
        .then((r) => {
          Swal.fire({
            icon: 'success',
            title: 'Uow...',
            text: 'Suas alterações foram realizadas com sucesso!',
            confirmButtonText: 'Fechar',
          }).then((response) => {
            if (response.isConfirmed) {
              listTracks.mutate();
              mutateGlobal(
                Prefix.replace(/[\\"]/g, '') +
                  '/monitor-profile-attributes?id_profile_attribute=' +
                  sensor +
                  '&id_contract_item=' +
                  idEquipment,
              );
            }
          });
        })
        .catch((err) => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Houve um problema com a alteração! Por favor tente novamente mais tarde.',
            confirmButtonText: 'Fechar',
          });
        });
    });
  };

  return (
    <>
      <div className="customerStep">
        <form>
          {listTracks.data?.models?.data.map((map, key) => {
            return (
              <Row>
                <Col>
                  <CardTracks>
                    <label>Mínima e Máxima</label>
                    <Slider
                      value={
                        !defaultTracks
                          ? [parseFloat(map?.min), parseFloat(map?.max)]
                          : [
                              parseFloat(defaultTracks[key]?.min),
                              parseFloat(defaultTracks[key]?.max),
                            ]
                      }
                      step={0.25}
                      min={map.min}
                      max={map.max}
                      aria-label={key}
                      onChange={handleEditTrack}
                      onChangeCommitted={handleEditTrack}
                      valueLabelDisplay="on"
                      aria-labelledby="range-slider"
                      marks={[
                        {
                          value: map.min,
                          label: map.min + ' ' + map.unit_of_measure.name,
                        },
                        {
                          value: map.max,
                          label: map.max + ' ' + map.unit_of_measure.name,
                        },
                      ]}
                    />

                    <label>Margem segura</label>
                    <Slider
                      defaultValue={map.safety_margin}
                      aria-labelledby="discrete-slider-restrict"
                      aria-label={key}
                      step={0.25}
                      min={1}
                      max={
                        !defaultTracks
                          ? ''
                          : parseFloat(
                              parseFloat(defaultTracks[key]?.max) -
                                parseFloat(defaultTracks[key]?.min) -
                                0.25,
                            ) < 0.5
                          ? 0.5
                          : parseFloat(
                              parseFloat(defaultTracks[key]?.max) -
                                parseFloat(defaultTracks[key]?.min) -
                                0.25,
                            ) > 10
                          ? 10
                          : parseFloat(
                              parseFloat(defaultTracks[key]?.max) -
                                parseFloat(defaultTracks[key]?.min) -
                                0.25,
                            )
                      }
                      onChange={handleSecurity}
                      onChangeCommitted={handleSecurity}
                      valueLabelDisplay="auto"
                      marks={[
                        {
                          value: 1,
                          label: 1,
                        },
                        {
                          value: !defaultTracks
                            ? ''
                            : parseFloat(
                                parseFloat(defaultTracks[key]?.max) -
                                  parseFloat(defaultTracks[key]?.min) -
                                  0.25,
                              ) < 0.5
                            ? 0.5
                            : parseFloat(
                                parseFloat(defaultTracks[key]?.max) -
                                  parseFloat(defaultTracks[key]?.min) -
                                  0.25,
                              ) > 10
                            ? 10
                            : parseFloat(
                                parseFloat(defaultTracks[key]?.max) -
                                  parseFloat(defaultTracks[key]?.min) -
                                  0.25,
                              ),
                          label: !defaultTracks
                            ? ''
                            : parseFloat(
                                parseFloat(defaultTracks[key]?.max) -
                                  parseFloat(defaultTracks[key]?.min) -
                                  0.25,
                              ) < 0.5
                            ? 0.5
                            : parseFloat(
                                parseFloat(defaultTracks[key]?.max) -
                                  parseFloat(defaultTracks[key]?.min) -
                                  0.25,
                              ) > 10
                            ? 10
                            : parseFloat(
                                parseFloat(defaultTracks[key]?.max) -
                                  parseFloat(defaultTracks[key]?.min) -
                                  0.25,
                              ),
                        },
                      ]}
                    />
                    <Row>
                      <Col>
                        <label>Tempo offline</label>
                        <input
                          type="number"
                          className="form-control"
                          name="safety_margin_time"
                          id={key}
                          defaultValue={map.safety_margin_time}
                          onChange={handleChange}
                        />
                      </Col>
                      <Col>
                        <label>Variação</label>
                        <input
                          type="text"
                          className="form-control"
                          name="variation"
                          id={key}
                          onChange={handleChange}
                        />
                      </Col>
                    </Row>
                  </CardTracks>
                </Col>
              </Row>
            );
          })}

          <Row>
            <Col className="d-flex justify-content-center mt-4">
              <ButtonRange onClick={handleUpdate} type="button">
                Cadastrar
              </ButtonRange>
            </Col>
          </Row>
        </form>
      </div>
    </>
  );
};
export default AddRanges;
