import React, { useState, useRef, useContext } from "react";
import { Row, Col, Button, Alert, Modal } from "react-bootstrap";
import "../room.css";
import api from "../../../services/api";
import Fontawesome from "../../fontawesome/fontawesome";
import { Prefix } from "../../../services/prefix";
import Loading from "../../loader/loading";
import Swal from "sweetalert2";
import { useFetch } from "../../hooks/useFetch";
import { mutate as GlobalMutate } from "swr";
import { Context } from "../../../services/context";
import { AsyncPaginate } from "react-select-async-paginate";
import { loadOptions } from "../../selectAsync/loadOptions";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import { v4 as uuidv4 } from "uuid";
import { Schema } from "./schema";
import { NoOptionsMessage } from "../../selectAsync/NoOptionsMessage";
import { colourStyles } from "../../selectAsync/colourStyles";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import { useStyles } from "../../buttons/AddButtonStyle";
import { ModalContainer } from "../../../style/stylesModals";
import { CustomButton, TextButton } from "../../../style/stylesButton";

const IndexAddRoom = (props) => {
  const {
    selectclient,
    selectunit,
    selectsector,
    setSelectClient,
    setSelectUnit,
    setSelectSector,
  } = useContext(Context);
  const [show, setShow] = useState(false);
  const [idClient, setIdClient] = useState();
  const [idUnit, setIdUnit] = useState();
  const [idSector, setIdSector] = useState();
  const [loader, setLoader] = useState();
  const [valueSelect, setValueSelect] = useState([]);
  const [registerData, setRegisterData] = useState({
    initialData: [
      {
        key: uuidv4(),
        name: "",
        active: 1,
      },
    ],
  });

  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    setRegisterData({
      initialData: [
        {
          key: uuidv4(),
          name: "",
          active: 1,
        },
      ],
    });
    setValueSelect([]);
  };

  const HandleSelect = (e, name, key) => {
    if (
      valueSelect.filter((fill) => fill.key == key && fill.nameField == name)
        .length > 0
    ) {
      valueSelect
        .filter((fill) => fill.key == key && fill.nameField == name)
        .map((map) => {
          let position = valueSelect.indexOf(map);
          valueSelect.splice(position, 1);
        });
      setValueSelect(() => {
        return [
          ...valueSelect,
          { value: e.value, label: e.label, nameField: name, key: key },
        ];
      });
    } else {
      setValueSelect((prev) => {
        return [
          ...prev,
          { value: e.value, label: e.label, nameField: name, key: key },
        ];
      });
    }
  };

  const HandleRegister = (values) => {
    setLoader(true);
    values.initialData.map((room) =>
      api
        .post(Prefix.replace(/[\\"]/g, "") + "/rooms", {
          name: room.name,
          active: 1,
          id_sector: valueSelect
            .filter((fill) => fill.nameField === "listSectors")
            .map((m) => m.value)[0],
        })
        .then((response) => {
          Swal.fire({
            icon: "success",
            title: "Uow!",
            text: response.data.message,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: "Fechar",
            cancelButtonText: "Novo Cadastro",
            allowOutsideClick: false,
          }).then((result) => {
            if (!result.isConfirmed) {
              setShow(true);
            }
          });
          setLoader(false);
          setShow(false);
          GlobalMutate(
            Prefix.replace(/[\\"]/g, "") +
              "/rooms?per_page=" +
              props.perPage +
              "&page=" +
              props.pageExact +
              "&id_customer=" +
              selectclient +
              "&id_unit=" +
              selectunit +
              "&id_sector=" +
              selectsector +
              "&order_by=" +
              props.orderBy +
              "&order=" +
              props.order
          );
          setValueSelect([]);
          setRegisterData({
            initialData: [
              {
                key: uuidv4(),
                name: "",
                active: 1,
              },
            ],
          });
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: err.response.data.message,
            confirmButtonText: "Tentar novamente",
          });
        })
    );
    setLoader(false);
  };

  const classes = useStyles();

  return (
    <>
      {show === false ? (
        <Fab className={classes.fab} onClick={handleShow}>
          <AddIcon className={classes.icon} />
        </Fab>
      ) : (
        ""
      )}
      <ModalContainer
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h3>Salas</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik //--> Configuração do Formik
            initialValues={registerData}
            validationSchema={Schema}
            onSubmit={HandleRegister}
            enableReinitialize
          >
            {({ values, setFieldValue, handleChange }) => (
              <Form>
                <Alert variant="success">
                  <FieldArray name="initialData">
                    {({ push, remove }) => (
                      <div>
                        <div className="customerStep">
                          <Alert variant="success">
                            <Row>
                              <Col sm={12}>
                                <div className="formStep">
                                  <label class="important">Cliente</label>
                                  <AsyncPaginate
                                    value={valueSelect
                                      .filter(
                                        (fill) =>
                                          fill.nameField == "listCustomers" &&
                                          fill.key == "listCustomers"
                                      )
                                      .map((map) => {
                                        return {
                                          value: map.value,
                                          label: map.label,
                                        };
                                      })}
                                    loadOptions={loadOptions}
                                    // name={`initialData[${index}].list_clients`}
                                    onChange={(e) => {
                                      HandleSelect(
                                        e,
                                        "listCustomers",
                                        "listCustomers"
                                      );
                                    }} //values and name of select paginate
                                    placeholder="Selecione um Cliente..."
                                    components={{ NoOptionsMessage }}
                                    cacheUniqs={[valueSelect]} //Set your param that is going to change when you select another field. Just use this option when you are setting params and need to refresh cache.
                                    styles={colourStyles}
                                    additional={{
                                      page: 1,
                                      endpoint: "/customers",
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col sm={12}>
                                <div className="formStep">
                                  <label class="important">Unidade</label>
                                  <AsyncPaginate
                                    value={valueSelect
                                      .filter(
                                        (fill) =>
                                          fill.nameField == "listUnits" &&
                                          fill.key == "listUnits"
                                      )
                                      .map((map) => {
                                        return {
                                          value: map.value,
                                          label: map.label,
                                        };
                                      })}
                                    loadOptions={loadOptions}
                                    onChange={(e) => {
                                      HandleSelect(e, "listUnits", "listUnits");
                                    }} //values and name of select paginate
                                    placeholder="Selecione um Cliente..."
                                    components={{ NoOptionsMessage }}
                                    cacheUniqs={[
                                      valueSelect
                                        .filter(
                                          (fill) =>
                                            fill.nameField == "listCustomers"
                                        )
                                        .map((map) => map.value)[0],
                                    ]} //Set your param that is going to change when you select another field. Just use this option when you are setting params and need to refresh cache.
                                    styles={colourStyles}
                                    additional={{
                                      page: 1,
                                      endpoint: "/units",
                                      params: `id_customer=${
                                        valueSelect
                                          .filter(
                                            (fill) =>
                                              fill.nameField == "listCustomers"
                                          )
                                          .map((map) => map.value)[0]
                                      }`,
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col sm={12}>
                                <div className="formStep">
                                  <label class="important">Setor</label>
                                  <AsyncPaginate
                                    value={valueSelect
                                      .filter(
                                        (fill) =>
                                          fill.nameField == "listSectors" &&
                                          fill.key == "listSectors"
                                      )
                                      .map((map) => {
                                        return {
                                          value: map.value,
                                          label: map.label,
                                        };
                                      })}
                                    loadOptions={loadOptions}
                                    // name={`initialData[${index}].list_clients`}
                                    onChange={(e) => {
                                      HandleSelect(
                                        e,
                                        "listSectors",
                                        "listSectors"
                                      );
                                    }} //values and name of select paginate
                                    placeholder="Selecione um Cliente..."
                                    components={{ NoOptionsMessage }}
                                    cacheUniqs={[
                                      valueSelect
                                        .filter(
                                          (fill) =>
                                            fill.nameField == "listUnits"
                                        )
                                        .map((map) => map.value)[0],
                                    ]} //Set your param that is going to change when you select another field. Just use this option when you are setting params and need to refresh cache.
                                    styles={colourStyles}
                                    additional={{
                                      page: 1,
                                      endpoint: "/sectors",
                                      params: `id_unit=${
                                        valueSelect
                                          .filter(
                                            (fill) =>
                                              fill.nameField == "listUnits"
                                          )
                                          .map((map) => map.value)[0]
                                      }&id_client=${
                                        valueSelect
                                          .filter(
                                            (fill) =>
                                              fill.nameField == "listCustomers"
                                          )
                                          .map((map) => map.value)[0]
                                      }`,
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </Alert>
                        </div>

                        {values.initialData.map((p, index) => {
                          return (
                            <>
                              {valueSelect
                                .filter(
                                  (fill) => fill.nameField == "listSectors"
                                )
                                .map((map) => map.value).length < 1 ? (
                                ""
                              ) : (
                                <Alert variant="success" name="initialData">
                                  <Row>
                                    <Col>
                                      <div className="formStep">
                                        <div className="d-flex justify-content-between">
                                          <label class="important">Sala</label>{" "}
                                          {values.initialData.length < 2 ? (
                                            ""
                                          ) : (
                                            <Button
                                              variant="danger"
                                              className="btn-remove-item"
                                              onClick={() => {
                                                remove(index);
                                              }}
                                            >
                                              x
                                            </Button>
                                          )}
                                        </div>
                                        <Field
                                          type="text"
                                          placeholder="Digite o nome da sala"
                                          name={`initialData[${index}].name`}
                                          onChange={handleChange}
                                        />
                                        <ErrorMessage
                                          name={`initialData[${index}].name`}
                                          component="span"
                                          className="errorMessages"
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </Alert>
                              )}
                            </>
                          );
                        })}
                        <div>
                          {valueSelect.filter(
                            (fill) => fill.nameField == "listSectors"
                          ).length == 0 ? (
                            ""
                          ) : (
                            <Row>
                              <Col className="d-flex justify-content-center ">
                                <Button
                                  style={{
                                    backgroundColor: "#1bc5bd",
                                    width: "160px",
                                    height: "60px",
                                    border: 0,
                                    borderRadius: "30px",
                                    marginTop: "20px",
                                  }}
                                  onClick={() => {
                                    push({
                                      key: uuidv4(),
                                      name: "",
                                      active: 1,
                                    });
                                  }}
                                  disabled={
                                    valueSelect.filter(
                                      (fill) => fill.nameField == "listSectors"
                                    ).length == 0
                                      ? "disabled"
                                      : ""
                                  }
                                >
                                  Adicionar
                                </Button>
                              </Col>
                              <Col className="d-flex justify-content-center ">
                                {values.initialData.length == 0 ? (
                                  ""
                                ) : (
                                  <CustomButton type="submit">
                                    <TextButton>Cadastrar</TextButton>
                                  </CustomButton>
                                )}
                              </Col>
                            </Row>
                          )}
                        </div>
                      </div>
                    )}
                  </FieldArray>
                </Alert>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </ModalContainer>
    </>
  );
};

export default IndexAddRoom;
