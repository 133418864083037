import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  icon: {
    color: '#fff !important',
    '&:hover': {
      color: '#000 !important',
    },
  },
  fab: {
    position: 'fixed',
    bottom: '10px',
    right: '10px',
    background: '#881517',
    zIndex: 999,
  },
}));
