import React, { createContext } from 'react';

import useAuth from './useAuth';

const Context = createContext();

function AuthProvider({ children }) {
  const {
    employeeView,
    setEmployeeView,
    viewEquipments,
    setViewEquipments,
    viewSismogran,
    setViewSismogran,
    osItemServices,
    setOsItemServices,
    showUnitsWz,
    setShowUnitsWz,
    showRoomsWz,
    setShowRoomsWz,
    showSectorsWz,
    setShowSectorsWz,
    listSectorsWz,
    setListSectorsWz,
    listUnitsWz,
    setListUnitsWz,
    loadingPage,
    setLoadingPage,
    idContractLicenses,
    setIdContractLicenses,
    showOs,
    setShowOs,
    contrato,
    setContrato,
    cliente,
    setCliente,
    categoria,
    setCategoria,
    attachmentOs,
    setAttachmentOs,
    dashboard,
    setDashboard,
    editContractOs,
    setEditContractOs,
    contractOsShow,
    setContractOsShow,
    contractOs,
    setContractOs,
    os,
    setOs,
    alertClient,
    setAlertClient,
    alertNotification,
    setAlertNotification,
    updateEditEquipament,
    redirectPage,
    setRedirectPage,
    isActiveToggle,
    setIsActiveToggle,
    addIdContract,
    setAddIdContract,
    editAttributes,
    setEditAttributes,
    dataEdit,
    setDataEdit,
    editEquipament,
    setEditEquipament,
    redirectTo,
    itemContractId,
    setItemContractId,
    refreshPage,
    models,
    setModels,
    attachments,
    setAttachments,
    authenticated,
    setAuthenticated,
    loading,
    handleLogin,
    handleLogout,
    handleEmail,
    handlePassword,
    setRememberPassword,
    showAccess,
    idContractSchemes,
    setIdContractSchemes,
    redirect,
    selectclient,
    setSelectClient,
    selectuf,
    setSelectUf,
    selectcity,
    setSelectCity,
    selectunit,
    setSelectUnit,
    selectsector,
    setSelectSector,
    showEquipmentView,
    setShowEquipmentView,
    showNotificationView,
    setShowNotificationView,
    monitoringNotifications,
    setMonitoringNotifications,
    idProposal, // Id de proposta indo para Componentes de proposta.
    setIdProposal,
    setCustomerActionPlan,
    customerActionPlan,
    showNotificationTimer,
    setShowNotificationTimer
  } = useAuth();

  return (
    <Context.Provider
      value={{
        employeeView,
        setEmployeeView,
        viewEquipments,
        setViewEquipments,
        viewSismogran,
        setViewSismogran,
        osItemServices,
        setOsItemServices,
        //--------------------Context para componentes Wizards
        showUnitsWz,
        setShowUnitsWz,
        showRoomsWz,
        setShowRoomsWz,
        showSectorsWz,
        setShowSectorsWz,
        listSectorsWz,
        setListSectorsWz,
        listUnitsWz,
        setListUnitsWz,
        ////-------------------- Fim Context para componentes Wizards
        loadingPage,
        setLoadingPage,
        idContractLicenses,
        setIdContractLicenses,
        showOs,
        setShowOs,
        contrato,
        setContrato,
        cliente,
        setCliente,
        categoria,
        setCategoria,
        attachmentOs,
        setAttachmentOs,
        dashboard,
        setDashboard,
        editContractOs,
        setEditContractOs,
        contractOs,
        setContractOs,
        os,
        setOs,
        alertClient,
        setAlertClient,
        alertNotification,
        setAlertNotification,
        updateEditEquipament,
        redirectPage,
        setRedirectPage,
        isActiveToggle,
        setIsActiveToggle,
        addIdContract,
        setAddIdContract,
        editAttributes,
        setEditAttributes,
        dataEdit,
        setDataEdit,
        editEquipament,
        setEditEquipament,
        redirectTo,
        itemContractId,
        setItemContractId,
        refreshPage,
        models,
        setModels,
        attachments,
        setAttachments,
        loading,
        authenticated,
        setAuthenticated,
        handleLogin,
        handleLogout,
        handleEmail,
        handlePassword,
        setRememberPassword,
        showAccess,
        redirect,
        selectclient,
        setSelectClient,
        selectuf,
        setSelectUf,
        selectcity,
        setSelectCity,
        selectunit,
        setSelectUnit,
        selectsector,
        setSelectSector,
        showEquipmentView,
        setShowEquipmentView,
        showNotificationView,
        setShowNotificationView,
        monitoringNotifications,
        setMonitoringNotifications,
        idProposal, // Id de proposta indo para Componentes de proposta.
        setIdProposal,
        setCustomerActionPlan,
        customerActionPlan,
        showNotificationTimer,
        setShowNotificationTimer
      }}
    >
      {children}
    </Context.Provider>
  );
}
export { Context, AuthProvider };
