import React from "react";
import { formatMoeda } from "../../../../services/mask";

export const columns = [
  {
    //0
    name: "id",
    label: "",
    options: {
      setCellHeaderProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      setCellProps: () => ({
        style: {
          "text-align": "center",
          "font-family": "Poppins",
        },
      }),
      filter: false,
      sort: false,
      display: "excluded",
    },
  },
  {
    //1
    name: "equipment_model",
    label: "Equipamento",
    options: {
      setCellHeaderProps: () => ({
        style: {
          "text-align": "left",
        },
      }),
      setCellProps: () => ({
        style: {
          "text-align": "left",
        },
      }),
      filter: false,
      sort: false,
      display: true,
      customBodyRender: (value, tableMeta) => {
        return (
          <>
            <b> {value.name}</b>
            <br />
            {value.equipment_brand.name}
          </>
        );
      },
    },
  },
  {
    //2
    name: "service",
    label: "Serviço",
    options: {
      setCellHeaderProps: () => ({
        style: {
          "text-align": "left",
        },
      }),
      setCellProps: () => ({
        style: {
          "text-align": "left",
        },
      }),
      filter: false,
      sort: false,
      display: true,
      customBodyRender: (value, tableMeta) => {
        return (
          <>
            <b> {value === "Calibra"? "Calibração": value}</b>
          </>
        );
      },
    },
  },

  {
    //3
    name: "quantity",
    label: "Quantidade",
    options: {
      setCellHeaderProps: () => ({
        style: {
          "text-align": "left",
        },
      }),
      setCellProps: () => ({
        style: {
          "text-align": "left",
        },
      }),
      filter: false,
      sort: false,
      display: true,
      customBodyRender: (value, tableMeta) => {
        return (
          <>
            <b> {value}</b>
            <br />
            Equipamentos
          </>
        );
      },
    },
  },

  {
    //4
    name: "value",
    label: "Valor Unitário",
    options: {
      setCellHeaderProps: () => ({
        style: {
          "text-align": "left",
        },
      }),
      setCellProps: () => ({
        style: {
          "text-align": "left",
        },
      }),
      filter: false,
      sort: false,
      display: true,
      customBodyRender: (value, tableMeta) => {
        return (
          <>
            <b> {formatMoeda(value)}</b>
            <br />
            /Unidade
          </>
        );
      },
    },
  },

  {
    //5
    name: "",
    label: "SubTotal",
    options: {
      setCellHeaderProps: () => ({
        style: {
          "text-align": "left",
        },
      }),
      setCellProps: () => ({
        style: {
          "text-align": "left",
        },
      }),
      filter: false,
      sort: false,
      display: true,
      customBodyRender: (value, tableMeta) => {
        return (
          <>
            <b>{formatMoeda(tableMeta.rowData[4] * tableMeta.rowData[3])}</b>
            <br />
            /Mensal
          </>
        );
      },
    },
  },
];
