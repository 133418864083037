import * as Yup from 'yup';

Yup.setLocale({
  mixed: {
    required: 'Campo obrigatório',
  },
  string: {
    email: 'Preencha um e-mail válido',
    min: 'Mínimo de ${min} caracteres',
    max: 'Máximo de ${max} caracteres',
  },
  number: {
    min: 'Deve ser maior ou igual a ${min}',
    max: 'Deve ser menor ou igual a ${max}',
  },
});

const Schema = Yup.object().shape({
  name: Yup.string().min(3).required(),
  id_family: Yup.number().required(),
  complexity: Yup.number().required(),
  corrective: Yup.boolean().required(),
  preventive: Yup.boolean().required(),
  calibration: Yup.boolean().required(),
  default_calibration_frequency: Yup.number(),
  default_preventive_frequency: Yup.number(),
});
export default Schema;
