import React, { useState, useRef, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import '../equipamentsCategory.css';
import { Prefix } from '../../../services/prefix';
import Loading from '../../loader/loading';
import Swal from 'sweetalert2';
import api from '../../../services/api';
import { mutate as GlobalMutate } from 'swr';
import Alert from 'react-bootstrap/Alert';
import Fontawesome from '../../fontawesome/fontawesome';
import Modal from 'react-bootstrap/Modal';
import { useFetch } from '../../hooks/useFetch';
import Schema from './schema';
import Select from 'react-select';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Switch } from '@material-ui/core';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Button from 'react-bootstrap/Button';
import { AsyncPaginate } from 'react-select-async-paginate';
import { loadOptions } from '../../selectAsync/loadOptions';
import { colourStyles } from '../../selectAsync/colourStyles';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputSpinner from 'react-bootstrap-input-spinner';
import { ModalContainer } from '../../../style/stylesModals';
import { CustomButton, TextButton } from '../../../style/stylesButton';

const IndexEditEquipamentsFamilies = ({
  id,
  page,
  rowsPage,
  search,
  orderBy,
  order,
}) => {
  const [loader, setLoader] = useState();
  const [show, setShow] = useState(0);
  const [valueSelect, setValueSelect] = useState([]);
  const handleClose = () => setShow(0);
  const handleShow = () => setShow(true);

  let pageExact = page + 1;
  let valueSearch = !search ? '' : search;

  const listFamily = useFetch(
    Prefix.replace(/[\\"]/g, '') + '/equipment-families',
  );

  const listEquipaments = useFetch(
    Prefix.replace(/[\\"]/g, '') + '/equipment-categories/' + id,
  );

  const [registerData, setRegisterData] = useState({});

  useEffect(() => {
    if (listEquipaments?.data) {
      setRegisterData({
        name: listEquipaments?.data?.model?.name,
        active: listEquipaments?.data?.model?.active,
        id_equipment_family: listEquipaments?.data?.model?.id_equipment_family,
        complexity: listEquipaments?.data?.model?.complexity,
        corrective: listEquipaments?.data?.model?.corrective,
        preventive: listEquipaments?.data?.model?.preventive,
        calibration: listEquipaments?.data?.model?.calibration,
        default_calibration_frequency:
          listEquipaments?.data?.model?.default_calibration_frequency,
        default_preventive_frequency:
          listEquipaments?.data?.model?.default_preventive_frequency,
      });
    }
  }, [listEquipaments.data]);

  const buttonSend = useRef(null);

  /*Atualizar dados do cliente*/
  function HandleRegister(e) {
    e.preventDefault();
    setLoader(<Loading />);
    api
      .put(
        Prefix.replace(/[\\"]/g, '') + '/equipment-categories/' + id,
        registerData,
      )
      .then((response) => {
        Swal.fire({
          icon: 'success',
          title: 'Uow!',
          text: response.data.message,
          confirmButtonText: 'Continuar',
        });
        setLoader();
        setShow(false);
        GlobalMutate(
          Prefix.replace(/[\\"]/g, '') +
          '/equipment-categories?per_page=' +
          rowsPage +
          '&page=' +
          pageExact +
          '&search=' +
          valueSearch +
          '&order_by=' +
          orderBy +
          '&order=' +
          order,
        );
        setRegisterData(registerData);
        listEquipaments.mutate();
        listFamily.mutate();
      })
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: err.response !== undefined ? err.response.data.message : '',
          confirmButtonText: 'Tentar novamente',
        });
      });
  }

  const HandleChangeString = (e) => {
    setRegisterData({
      ...registerData,
      [e.target.name]: e.target.value.trim(),
    });
  };

  const HandleChange = (e) => {
    setRegisterData({ ...registerData, [e.target.name]: e.target.checked });
  };
  const HandleSelect = (e, name) => {
    if (valueSelect.filter((fill) => fill.nameField == name).length > 0) {
      valueSelect
        .filter((fill) => fill.nameField == name)
        .map((map) => {
          let valueIndice = valueSelect.indexOf(map);
          valueSelect.splice(valueIndice);
        });

      setValueSelect((prev) => {
        return [...prev, { value: e.value, label: e.label, nameField: name }];
      });
      setRegisterData({ ...registerData, [name]: e.value });
    } else {
      setValueSelect((prev) => {
        return [...prev, { value: e.value, label: e.label, nameField: name }];
      });
      setRegisterData({ ...registerData, [name]: e.value });
    }
  };

  const AntSwitch = withStyles((theme) => ({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: 'flex',
    },
    switchBase: {
      padding: 2,
      color: '#ADB5BD',
      '&$checked': {
        transform: 'translateX(12px)',
        color: '#ffffff',
        '& + $track': {
          opacity: 1,
          backgroundColor: '#1BC5BD',
          borderColor: '#1BC5BD',
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: 'none',
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
    },
    checked: {},
  }))(Switch);

  return (
    <>
      <Link
        to="#"
        variant="success"
        onClick={handleShow}
        className="link_color_datatable"
      >
        <Fontawesome classe="fas fa-pencil-alt" />
      </Link>

      <ModalContainer
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={0}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h3>
              Atualizar
              <br />
              Categoria de Equipamento
            </h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loader && <Loading />}
          <Formik
            initialValues={registerData}
            validationSchema={Schema}
            onSubmit={HandleRegister}
            enableReinitialize
          >
            {({ values, errors }) => (
              <Form>
                <Row>
                  <Col>
                    <div className="formStep">
                      <div className="labelForm">
                        <label className="important">Nome completo</label>
                      </div>
                      <Field
                        type="text"
                        placeholder="Digite um nome"
                        name="name"
                        onChange={HandleChangeString}
                      />
                      <ErrorMessage
                        name="name"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="formStep">
                      <label className="important">
                        Família do Equipamento
                      </label>
                      <AsyncPaginate
                        id="idFamily"
                        value={
                          valueSelect.length == 0
                            ? {
                              value:
                                listEquipaments.data?.model
                                  .id_equipment_family,
                              label:
                                listEquipaments.data?.model?.equipment_family
                                  ?.name,
                            }
                            : valueSelect
                              .filter(
                                (fill) =>
                                  fill.nameField == 'id_equipment_family',
                              )
                              .map((map) => {
                                return {
                                  value: map.value,
                                  label: map.label,
                                };
                              })
                        }
                        loadOptions={loadOptions}
                        onChange={(e) => HandleSelect(e, 'id_equipment_family')} //values and name of select paginate
                        placeholder="Selecione..."
                        cacheUniqs={[valueSelect]} //Set your param that is going to change when you select another field. Just use this option when you are setting params and need to refresh cache.
                        styles={colourStyles}
                        additional={{
                          page: 1,
                          endpoint: '/equipment-families',
                          // params: `uf=${values.uf}`, //Separete with & (and commercial)
                        }}
                      />

                      <ErrorMessage
                        name="id_equipment_family"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="formStep">
                      <label>Complexidade:</label>
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="tooltip-disabled">
                            Quanto menor o valor, menor a complexidade do
                            equipamento e vice-versa.
                          </Tooltip>
                        }
                      >
                        <a href="#" style={{ color: '#000', marginLeft: 5 }}>
                          <i className="far fa-question-circle"></i>
                        </a>
                      </OverlayTrigger>
                      <div>
                        {/* <Field
                          name="complexity"
                          type="number"
                          min="0"
                          max="5"
                          onChange={HandleChangeString}
                        /> */}
                        <InputSpinner
                          type="number"
                          name="complexity"
                          variant="success"
                          size="md"
                          precision={2}
                          max={5}
                          min={0}
                          value={
                            !registerData.complexity
                              ? 0
                              : registerData.complexity
                          }
                          onChange={(e) =>
                            setRegisterData({
                              ...registerData,
                              ['complexity']: e,
                            })
                          }
                        />
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col className="text-center mt-3">
                    <h3>Tipos de Serviços</h3>
                  </Col>
                </Row>

                <Row>
                  <Col className="text-center">
                    <label class="important">
                      Selecione ao menos um tipo de serviço abaixo:{' '}
                    </label>
                  </Col>
                </Row>
                <Alert
                  className="mt-4"
                  style={{ backgroundColor: '#f0f1f2', border: 0 }}
                >
                  <Row>
                    <Col className="text-center mt-2 mb-2">
                      <h4>Padrão</h4>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-center">
                      <div className="custom-control custom-switch customerStep ">
                        <Form>
                          <label style={{ marginRight: 5 }}>Corretiva</label>
                          <FormControlLabel
                            control={
                              <AntSwitch
                                checked={values.corrective}
                                onChange={HandleChange}
                                name="corrective"
                                label="Corretiva"
                              />
                            }
                          />
                          <ErrorMessage
                            name="corrective"
                            component="span"
                            className="errorMessages"
                          />
                        </Form>
                      </div>
                    </Col>
                    <Col className="text-center">
                      <div className="custom-control custom-switch customerStep ">
                        <Form>
                          <label style={{ marginRight: 5 }}>Preventiva</label>
                          <FormControlLabel
                            control={
                              <AntSwitch
                                checked={values.preventive}
                                onChange={HandleChange}
                                name="preventive"
                                label="Preventiva"
                              />
                            }
                          />
                          <ErrorMessage
                            name="preventive"
                            component="span"
                            className="errorMessages"
                          />
                        </Form>
                      </div>
                    </Col>
                    <Col className="text-center">
                      <div className="custom-control custom-switch customerStep ">
                        <Form>
                          <label style={{ marginRight: 5 }}>Calibração</label>
                          <FormControlLabel
                            control={
                              <AntSwitch
                                checked={values.calibration}
                                onChange={HandleChange}
                                name="calibration"
                                label="Calibração"
                              />
                            }
                          />
                          <ErrorMessage
                            name="calibration"
                            component="span"
                            className="errorMessages"
                          />
                        </Form>
                      </div>
                    </Col>
                  </Row>
                  <br />
                </Alert>
                <Row>
                  <Col className="text-center mt-3">
                    <h3>Periodicidade</h3>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="formStep text-center">
                      <label>Calibração</label>
                      <div>
                        <InputSpinner
                          type="number"
                          name="default_calibration_frequency"
                          variant="success"
                          size="md"
                          precision={2}
                          min={0}
                          max={12}
                          value={
                            !registerData.default_calibration_frequency
                              ? 0
                              : registerData.default_calibration_frequency
                          }
                          onChange={(e) =>
                            setRegisterData({
                              ...registerData,
                              ['default_calibration_frequency']: e,
                            })
                          }
                        />
                      </div>
                    </div>
                  </Col>

                  <Col>
                    <div className="formStep text-center">
                      <label>Prevenção</label>
                      <div>
                        <InputSpinner
                          type="number"
                          name="default_preventive_frequency"
                          variant="success"
                          size="md"
                          precision={2}
                          min={0}
                          max={12}
                          value={
                            !registerData.default_preventive_frequency
                              ? 0
                              : registerData.default_preventive_frequency
                          }
                          onChange={(e) =>
                            setRegisterData({
                              ...registerData,
                              ['default_preventive_frequency']: e,
                            })
                          }
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className="d-flex justify-content-center">
                    <CustomButton onClick={HandleRegister}>
                      <TextButton> Atualizar </TextButton>
                    </CustomButton>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </ModalContainer>
    </>
  );
};
export default IndexEditEquipamentsFamilies;
