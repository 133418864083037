import React, { useState, useRef, useContext } from "react";
import { Row, Col, Button, Modal } from "react-bootstrap";
import "../sectors.css";
import { Prefix } from "../../../services/prefix";
import Loading from "../../loader/loading";
import Swal from "sweetalert2";
import api from "../../../services/api";
import Alert from "react-bootstrap/Alert";
import { useFetch } from "../../hooks/useFetch";
import Fontawesome from "../../fontawesome/fontawesome";
import { mutate as GlobalMutate } from "swr";
import { Context } from "../../../services/context";
import { Formik, Form, FieldArray, getIn, ErrorMessage, Field } from "formik";
import Schema from "./schema";
import { v4 as uuidv4 } from "uuid";
import Select from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import { loadOptions } from "../../selectAsync/loadOptions";
import { colourStyles } from "../../selectAsync/colourStyles";
import "./style.css";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import { useStyles } from "../../buttons/AddButtonStyle";
import { ModalContainer } from "../../../style/stylesModals";
import { CustomButton, TextButton } from "../../../style/stylesButton";

const IndexAddSector = (props) => {
  const { selectclient, selectunit, setSelectClient, setSelectUnit } =
    useContext(Context);
  const [show, setShow] = useState(false);
  const [idClient, setIdClient] = useState();
  const [idUnit, setIdUnit] = useState();
  const [loader, setLoader] = useState();
  const [valueSelect, setValueSelect] = useState([]);
  const [registerData, setRegisterData] = useState({
    initialData: [
      {
        key: uuidv4(),
        name: "",
        active: "1",
      },
    ],
  });

  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    setValueSelect([]);
  };

  const HandleSelect = (e, name, key) => {
    if (
      valueSelect.filter((fill) => fill.key == key && fill.nameField == name)
        .length > 0
    ) {
      valueSelect
        .filter((fill) => fill.key == key && fill.nameField == name)
        .map((map) => {
          let position = valueSelect.indexOf(map);
          valueSelect.splice(position, 1);
        });
      setValueSelect(() => {
        return [
          ...valueSelect,
          { value: e.value, label: e.label, nameField: name, key: key },
        ];
      });
    } else {
      setValueSelect((prev) => {
        return [
          ...prev,
          { value: e.value, label: e.label, nameField: name, key: key },
        ];
      });
    }
  };

  function HandleRegister(values) {
    setLoader(true);
    values.initialData.map((setor) =>
      api
        .post(Prefix.replace(/[\\"]/g, "") + "/sectors", {
          name: setor.name,
          active: 1,
          id_unit: valueSelect
            .filter((fill) => fill.nameField == "id_units")
            .map((map) => map.value)[0],
        })
        .then((response) => {
          Swal.fire({
            icon: "success",
            title: "Uow!",
            text: response.data.message,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: "Fechar",
            cancelButtonText: "Novo Cadastro",
            allowOutsideClick: false,
            focusCancel: true,
          }).then((result) => {
            if (!result.isConfirmed) {
              setShow(true);
            }
          });
          setLoader(false);
          setShow(false);
          setValueSelect([]);
          GlobalMutate(
            Prefix.replace(/[\\"]/g, "") +
              "/sectors?per_page=" +
              props.perPage +
              "&page=" +
              props.pageExact +
              "&id_unit=" +
              selectunit +
              "&id_customer=" +
              selectclient +
              "&order_by=" +
              props.orderBy +
              "&order=" +
              props.order
          );
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: err.response.data.message,
            confirmButtonText: "Tentar novamente",
          });
          setLoader(false);
        })
    );
  }

  const classes = useStyles();

  return (
    <>
      {show === false ? (
        <Fab className={classes.fab} onClick={handleShow}>
          <AddIcon className={classes.icon} />
        </Fab>
      ) : (
        ""
      )}
      <ModalContainer
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h3>Setores</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik //--> Configuração do Formik
            initialValues={registerData}
            validationSchema={Schema}
            onSubmit={HandleRegister}
            enableReinitialize
          >
            {({ values, handleChange }) => (
              <Form>
                <Alert variant="success">
                  <FieldArray name="initialData">
                    {({ push, remove }) => (
                      <div>
                        <div className="customerStep">
                          <Alert variant="success">
                            <Row>
                              <Col>
                                <div className="formStep">
                                  <label className="important">Cliente</label>
                                  <AsyncPaginate
                                    style={{ zIndex: 1 }}
                                    value={valueSelect
                                      ?.filter(
                                        (fill) =>
                                          fill.nameField == "id_customers"
                                      )
                                      .map((map) => {
                                        return {
                                          value: map.value,
                                          label: map.label,
                                        };
                                      })}
                                    loadOptions={loadOptions}
                                    onChange={(e) =>
                                      HandleSelect(
                                        e,
                                        "id_customers",
                                        "id_customers"
                                      )
                                    }
                                    //values and name of select paginate
                                    placeholder="Selecione..."
                                    cacheUniqs={[valueSelect]} //Set your param that is going to change when you select another field. Just use this option when you are setting params and need to refresh cache.
                                    styles={colourStyles}
                                    additional={{
                                      page: 1,
                                      endpoint: "/customers",
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row style={{ zIndex: 1 }}>
                              <Col>
                                <div className="formStep">
                                  <label className="important">Unidade</label>
                                  <AsyncPaginate
                                    value={valueSelect
                                      .filter(
                                        (fill) => fill.nameField == "id_units"
                                      )
                                      .map((map) => {
                                        return {
                                          value: map.value,
                                          label: map.label,
                                        };
                                      })}
                                    loadOptions={loadOptions}
                                    onChange={(e) =>
                                      HandleSelect(e, "id_units", "id_units")
                                    } //values and name of select paginate
                                    placeholder="Selecione..."
                                    cacheUniqs={[
                                      valueSelect
                                        .filter(
                                          (fill) =>
                                            fill.nameField == "id_customers"
                                        )
                                        .map((map) => map.value),
                                    ]} //Set your param that is going to change when you select another field. Just use this option when you are setting params and need to refresh cache.
                                    styles={colourStyles}
                                    additional={{
                                      page: 1,
                                      endpoint: "/units",
                                      params: `id_customer=${valueSelect
                                        .filter(
                                          (fill) =>
                                            fill.nameField == "id_customers"
                                        )
                                        .map((map) => map.value)}`, //Separete with & (and commercial)
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </Alert>
                        </div>

                        {values.initialData.map((p, index) => {
                          return (
                            <>
                              <div
                                className="customerStep"
                                style={{
                                  display:
                                    valueSelect.filter(
                                      (fill) =>
                                        fill.nameField == "id_customers" &&
                                        fill.key == "id_customers"
                                    ).length > 0 &&
                                    valueSelect.filter(
                                      (fill) =>
                                        fill.nameField == "id_units" &&
                                        fill.key == "id_units"
                                    ).length > 0
                                      ? "block"
                                      : "none",
                                }}
                              >
                                <Alert variant="success" style={{ zIndex: 0 }}>
                                  <Row>
                                    <Col>
                                      <div
                                        className="formStep"
                                        style={{ zIndex: 0 }}
                                      >
                                        <div
                                          className="d-flex justify-content-between"
                                          style={{ zIndex: 0 }}
                                        >
                                          <label class="important">Setor</label>{" "}
                                          {values.initialData.length < 2 ? (
                                            ""
                                          ) : (
                                            <Button
                                              variant="danger"
                                              className="btn-remove-item"
                                              onClick={() => {
                                                remove(index);
                                              }}
                                            >
                                              x
                                            </Button>
                                          )}
                                        </div>
                                        <Field
                                          type="text"
                                          placeholder="Digite o nome do setor"
                                          name={`initialData[${index}].name`}
                                          onChange={handleChange}
                                        />
                                        <ErrorMessage
                                          name={`initialData[${index}].name`}
                                          component="span"
                                          className="errorMessages"
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </Alert>
                              </div>
                            </>
                          );
                        })}
                        <div>
                          {valueSelect.filter(
                            (fill) => fill.nameField == "id_units"
                          ).length == 0 ? (
                            ""
                          ) : (
                            <Row>
                              <Col className="d-flex justify-content-center ">
                                <Button
                                  style={{
                                    backgroundColor: "#1bc5bd",
                                    width: "160px",
                                    height: "60px",
                                    border: 0,
                                    borderRadius: "30px",
                                    marginTop: "20px",
                                  }}
                                  onClick={() => {
                                    push({
                                      key: uuidv4(),
                                      name: "",
                                      active: "1",
                                    });
                                  }}
                                  disabled={
                                    valueSelect.filter(
                                      (fill) => fill.nameField == "id_units"
                                    ).length == 0
                                      ? "disabled"
                                      : ""
                                  }
                                >
                                  Adicionar
                                </Button>
                              </Col>
                              <Col className="d-flex justify-content-center ">
                                {values.initialData.length == 0 ? (
                                  ""
                                ) : (
                                  <CustomButton type="submit">
                                   <TextButton>Cadastrar</TextButton> 
                                  </CustomButton>
                                )}
                              </Col>
                            </Row>
                          )}
                        </div>
                      </div>
                    )}
                  </FieldArray>
                </Alert>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </ModalContainer>
    </>
  );
};

export default IndexAddSector;
