import React from 'react';
import Edit from '../../components/units_of_measures/editUnitsOfMeasures/indexEditUnitsOfMeasures';
import UpdateStatus from '../../components/units_of_measures/updateStatus/updateStatus';


export const columns = [
  {
    name: 'id',
    label: '',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      display: 'excluded',
    },
  },
  {
    name: 'name',
    label: 'Unidade de Medida',
    options: {
      setCellHeaderProps: () => ({
        style: {
          display: "flex",
          justifyContent: "center",
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: true,
      customBodyRender: (value, tableMeta) => {
        return (
          <>
            {value == 'Ur' || value == 'uR' || value == 'UR' || value == 'ur' ?
              "%" + value
              :
              value}
          </>
        );

      },
    },
  },

  {
    name: 'measure',
    label: 'Grandeza',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,

      customBodyRender: (value, tableMeta) => {
        return value?.name;

      },
    },
  },

  {
    name: 'active',
    label: 'Status',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
          width: '10%',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: true,
      sort: false,
      print: false,
      customBodyRender: (value, tableMeta) => {
        return (
          <UpdateStatus
            activeDefault={value}
            id={tableMeta.rowData[0]}
            page={tableMeta.tableState.page}
            rowsPage={tableMeta.tableState.rowsPerPage}
            search={tableMeta.tableState.searchText}
            orderBy={tableMeta.tableState.sortOrder.name}
            order={tableMeta.tableState.sortOrder.direction}
          />
        );
      },
    },
  },

  {
    name: 'edit',
    label: 'Editar',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
          width: '10%',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      print: false,
      customBodyRender: (value, tableMeta) => {
        return (
          <Edit
            id={tableMeta.rowData}
            page={tableMeta.tableState.page}
            rowsPage={tableMeta.tableState.rowsPerPage}
            search={tableMeta.tableState.searchText}
            orderBy={tableMeta.tableState.sortOrder.name}
            order={tableMeta.tableState.sortOrder.direction}
          />
        );
      },
    },
  },
];
