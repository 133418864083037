import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { IconOption } from '../optionsProposalsStyle';
import { Modal, Col, Row, Button } from 'react-bootstrap';
import facebook from './img/facebook.png';
import instagram from './img/instagram.png';
import linkedin from './img/linkedin.png';

const ModalAcceptProposal = (props) => {
  const [show, setShow] = useState(false);

  const handleShow = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  return (
    <>
      {show === false ? (
        <Button
          style={{
            marginLeft: '2px',
            backgroundColor: '#ffffff',
            color: '#782e2e',
            borderColor: '#ffffff',
            borderRadius: '30px',
            fontSize: '10px',
            width: '150px',
            height: '40px',
          }}
          onClick={handleShow}
        >
          {props.buttonLabel}
        </Button>
      ) : (
        ''
      )}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h2
            style={{
              textAlign: 'center',
              fontWeight: 'bold',
              color: '#000000',
            }}
          >
            Parabéns!
          </h2>
          <p style={{ textAlign: 'center', color: '#000000' }}>
            Acabamos de receber sua aprovação de proposta e nossa equipe
            comercial dará prosseguimento.
          </p>
          <br />
          <p
            style={{
              fontSize: '13px',
              textAlign: 'center',
              color: '#000000',
            }}
          >
            {' '}
            Aproveite e siga-nos em nossas redes sociais e fique por dentro de
            novidades.
          </p>
          <br />
          <div
            style={{
              textAlign: 'center',
            }}
          >
            <a
              href="https://www.facebook.com/mtechsp"
              style={{
                marginLeft: '5px',
                marginRight: '5px',
                cursor: 'pointer',
              }}
            >
              <img src={facebook} width="40px" height="40px"></img>
            </a>
            <a
              href="https://www.instagram.com/mtechsp"
              style={{
                marginLeft: '5px',
                marginRight: '5px',
                cursor: 'pointer',
              }}
            >
              <img src={instagram} width="40px" height="40px"></img>
            </a>
            <a
              href="https://www.linkedin.com/company/mtechsp"
              style={{
                marginLeft: '5px',
                marginRight: '5px',
                cursor: 'pointer',
              }}
            >
              <img src={linkedin} width="40px" height="40px"></img>
            </a>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default ModalAcceptProposal;
