import React from 'react';
import IndexModalAlert from '../../../components/seriousAlerts/indexModalAlert';
import AlertsDetails from '../../components/alerts/alertsDetails/alertsDetails';
import Light from '../../components/monitoringsCustomer/lights/lights';

export const columns = [
  {//0
    name: 'type',
    label: 'Status',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: true,
      sort: false,
      // display: "excluded"
      customBodyRender: (value) => {
        if (value == 'DANGER') {
          return <Light signal="3" />;
        } else {
          return <Light signal="2" />;
        }
      },
    },
  },
  {//1
    name: 'contract_item',
    label: 'Equipamento',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      customBodyRender: (value) => {
        return value?.nickname
      },
      // display: "excluded"
    },
  },
  {//2
    name: 'espid',
    label: 'Serial',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      // display: "excluded"
    },
  },
  {//3
    name: '_id',
    label: 'Código de Leitura',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      display: true, // 'excluded',
    },
  },


  {//4
    name: 'value',
    label: 'Leitura',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: true,
      sort: false,
      // display: "excluded"
      customBodyRender: (value) => {
        if (value) return `${parseFloat(value).toFixed(2)}`;
        return 0;
      },
    },
  },
  // {
  //   name: 'value',
  //   label: 'Umidade',
  //   options: {
  //     setCellHeaderProps: () => ({
  //       style: {
  //         'text-align': 'center',
  //       },
  //     }),
  //     setCellProps: () => ({
  //       style: {
  //         'text-align': 'center',
  //       },
  //     }),
  //     filter: true,
  //     sort: false,
  //     // display: "excluded"
  //     customBodyRender: (value, tableMeta) => {
  //       if (value) return `${parseFloat(value).toFixed(2)} %`;
  //       return false;
  //     },
  //   },
  // },
  {//5
    name: 'contract_item',
    label: 'Grandeza',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: true,
      sort: false,
      // display: "excluded"
      customBodyRender: (value) => {
        return value.monitor_profile_attruibutes[0]?.profile_attribute
          .unit_of_measure.name;
      },
    },
  },
  {//6
    name: 'epoch',
    label: 'Data e Hora',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: true,
      sort: false,
      // display: "excluded"
      customBodyRender: (value) => {
        let [data, hora] = value.split(' ');

        return (
          <>
            <label>{data.split('-').reverse().join('/')}</label>
            <br />
            <label>{hora}</label>
          </>
        );
      },
    },
  },
  {//7
    name: 'obs',
    label: 'Observação',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'left',
        },
      }),
      filter: true,
      sort: false,
      // display: "excluded"
    },
  },
  {//8
    name: "",
    label: 'Detalhes',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: true,
      sort: false,
      // display: "excluded"
      customBodyRender: (value, tableMeta) => {
        return (
          <>
            <AlertsDetails
              contract_item={tableMeta.rowData[5]}
              epoch={tableMeta.rowData[6]}
              value={tableMeta.rowData[4]}
              max={tableMeta.rowData[9]}
              min={tableMeta.rowData[10]}
              parent={tableMeta.rowData[11]}
            />
          </>
        );
      },
    },
  },
  {//9
    name: 'max',
    label: 'Máximo',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'left',
        },
      }),
      filter: true,
      sort: false,
      display: "excluded"
    },
  },
  {//10
    name: 'min',
    label: 'Mínimo',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'left',
        },
      }),
      filter: true,
      sort: false,
      display: "excluded"
    },
  },
  {//11
    name: 'parent',
    label: '',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      display: "excluded"
    },
  },
];

export const columnsAdmin = [
  {//0
    name: 'id',
    label: '',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      display: 'excluded',
    },
  },
  {//1
    name: 'name',
    label: 'Nome',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      display: true,
    },
  },
  {//2
    name: 'fantasy_name',
    label: 'Nome Fantasia',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      display: true,
    },
  },
  {//3
    name: 'corporate_name',
    label: 'Razão Social',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
    },
  },
  {//4
    name: 'document',
    label: 'CNPJ',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
    },
  },
  {//5
    name: 'id',
    label: 'Alertas',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: true,
      sort: false,
      customBodyRender: (value, tableMeta) => {
        return <IndexModalAlert id={value} />;
      },
    },
  },
];
