import React from 'react';
import Fontawesome from '../fontawesome/fontawesome';
import { NavLink } from 'react-router-dom';

const NavDtlabClient = ({ group }) => {
  return (
    <>
      <li>
        <NavLink
          exact
          activeClassName="active"
          className="slide-in-left"
          to="/gestao/cliente/inicio"
        >
          <Fontawesome classe="fa fa-home mr-3" />
          Visão Geral
        </NavLink>
      </li>
    </>
  );
};
export default NavDtlabClient;
