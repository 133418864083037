import React from 'react';
import Fontawesome from '../fontawesome/fontawesome';
import { NavLink } from 'react-router-dom';

const NavDtlab = ({ group }) => {
  return (
    <>
      <li>
        <NavLink
          exact
          activeClassName="active"
          className="slide-in-left"
          to="/gestao/inicio"
        >
          <Fontawesome classe="fa fa-home mr-3" />
          Visão Geral
        </NavLink>
      </li>

      <li className="slide-in-left">
        <a
          href="#homeSubmenu4"
          data-toggle="collapse"
          aria-expanded="false"
          className="dropdown-toggle"
        >
          <Fontawesome classe="fas fa-users mr-3" /> Clientes
        </a>
        <ul className="collapse list-unstyled" id="homeSubmenu4">
          <li>
            <NavLink
              exact
              activeClassName="active"
              className="slide-in-left"
              to="/gestao/clientes"
            >
              <Fontawesome classe="fas fa-angle-right mr-3" />
              Gerenciar
            </NavLink>
          </li>
          <li>
            <NavLink
              exact
              activeClassName="active"
              className="slide-in-left"
              to="/gestao/contratos"
            >
              <Fontawesome classe="fas fa-angle-right mr-3" />
              Contratos
            </NavLink>
          </li>
          <li>
            <NavLink
              exact
              activeClassName="active"
              className="slide-in-left"
              to="/gestao/unidades"
            >
              <Fontawesome classe="fas fa-angle-right mr-3" />
              Unidades
            </NavLink>
          </li>
          <li>
            <NavLink
              exact
              activeClassName="active"
              className="slide-in-left"
              to="/gestao/setores"
            >
              <Fontawesome classe="fas fa-angle-right mr-3" />
              Setores
            </NavLink>
          </li>
          <li>
            <NavLink
              exact
              activeClassName="active"
              className="slide-in-left"
              to="/gestao/salas"
            >
              <Fontawesome classe="fas fa-angle-right mr-3" />
              Salas
            </NavLink>
          </li>
        </ul>
      </li>
      <li className="slide-in-left">
        <a
          href="#homeSubmenu5"
          data-toggle="collapse"
          aria-expanded="false"
          className="dropdown-toggle"
        >
          <Fontawesome classe="fas fa-laptop mr-3" /> Equipamentos
        </a>
        <ul className="collapse list-unstyled" id="homeSubmenu5">
          <li>
            <NavLink
              exact
              activeClassName="active"
              className="slide-in-left"
              to="/gestao/familias-equipamentos"
            >
              <Fontawesome classe="fas fa-angle-right mr-3" />
              Famílias
            </NavLink>
          </li>
          <li>
            <NavLink
              exact
              activeClassName="active"
              className="slide-in-left"
              to="/gestao/categorias-equipamentos"
            >
              <Fontawesome classe="fas fa-angle-right mr-3" />
              Catálogo
            </NavLink>
          </li>
          <li>
            <NavLink
              exact
              activeClassName="active"
              className="slide-in-left"
              to="/gestao/modelos-equipamentos"
            >
              <Fontawesome classe="fas fa-angle-right mr-3" />
              Modelos
            </NavLink>
          </li>
          <li>
            <NavLink
              exact
              activeClassName="active"
              className="slide-in-left"
              to="/gestao/marcas-equipamentos"
            >
              <Fontawesome classe="fas fa-angle-right mr-3" />
              Marcas
            </NavLink>
          </li>
          {/* <li>
            <NavLink
              exact
              activeClassName="active"
              className="slide-in-left"
              to="/gestao/equipamentos-backup"
            >
              <Fontawesome classe="fas fa-angle-right mr-3" />
              Backup
            </NavLink>
          </li> */}
          <li>
            <NavLink
              exact
              activeClassName="active"
              className="slide-in-left"
              to="/gestao/grandezas"
            >
              <Fontawesome classe="fas fa-angle-right mr-3" />
              Grandezas
            </NavLink>
          </li>
          <li>
            <NavLink
              exact
              activeClassName="active"
              className="slide-in-left"
              to="/gestao/unidades-medidas"
            >
              <Fontawesome classe="fas fa-angle-right mr-3" />
              Unidades de Medida
            </NavLink>
          </li>
        </ul>
      </li>

      <li className="slide-in-left">
        <a
          href="#homeSubmenu8"
          data-toggle="collapse"
          aria-expanded="false"
          className="dropdown-toggle"
        >
          <Fontawesome classe="fas fa-cogs mr-3" /> Administração
        </a>
        <ul className="collapse list-unstyled" id="homeSubmenu8">
          <li>
            <NavLink
              exact
              activeClassName="active"
              className="slide-in-left"
              to="/gestao/colaboradores"
            >
              <Fontawesome classe="fas fa-angle-right mr-3" />
              Colaboradores
            </NavLink>
          </li>
          <li>
            <NavLink
              exact
              activeClassName="active"
              className="slide-in-left"
              to="/gestao/usuarios"
            >
              <Fontawesome classe="fas fa-angle-right mr-3" />
              Usuários
            </NavLink>
          </li>
          <li>
            <NavLink
              exact
              activeClassName="active"
              className="slide-in-left"
              to="/gestao/cidades"
            >
              <Fontawesome classe="fas fa-angle-right mr-3" />
              Cidades
            </NavLink>
          </li>
          <li>
            <NavLink
              exact
              activeClassName="active"
              className="slide-in-left"
              to="/gestao/tipoContrato"
            >
              <Fontawesome classe="fas fa-angle-right mr-3" />
              Tipos de Contratos
            </NavLink>
          </li>

          <li>
            <NavLink
              exact
              activeClassName="active"
              className="slide-in-left"
              to="/gestao/licenca"
            >
              <Fontawesome classe="fas fa-angle-right mr-3" />
              Licenças
            </NavLink>
          </li>
          <li>
            <NavLink
              exact
              activeClassName="active"
              className="slide-in-left"
              to="/gestao/Modulos"
            >
              <Fontawesome classe="fas fa-angle-right mr-3" />
              Módulos
            </NavLink>
          </li>
          <li>
            <NavLink
              exact
              activeClassName="active"
              className="slide-in-left"
              to="/gestao/bases"
            >
              <Fontawesome classe="fas fa-angle-right mr-3" />
              Bases
            </NavLink>
          </li>
          <li>
            <NavLink
              exact
              activeClassName="active"
              className="slide-in-left"
              to="/gestao/novos-cadastros"
            >
              <Fontawesome classe="fas fa-angle-right mr-3" />
              Novos cadastros
            </NavLink>
          </li>
          {/* <li>
            <NavLink
              exact
              activeClassName="active"
              className="slide-in-left"
              to="/gestao/historico"
            >
              <Fontawesome classe="fas fa-angle-right mr-3" />
              Histórico
            </NavLink>
          </li> */}
        </ul>
      </li>
      {/* <li className="slide-in-left">
        <NavLink
          exact
          activeClassName="active"
          className="slide-in-left"
          to="/gestao/relatorios"
        >
          <Fontawesome classe="fas fa-chart-pie mr-3" /> Relatórios
        </NavLink>
      </li>
      <li className="slide-in-left">
        <NavLink
          exact
          activeClassName="active"
          className="slide-in-left"
          to="/comercial/propostas"
        >
          <Fontawesome classe="fas fa-paste mr-3" /> Propostas
        </NavLink>
      </li> */}
    </>
  );
};
export default NavDtlab;
