import React from 'react'

export const Foreign = (props) => {

  return(
        <>
            {/*Recebe dois parâmetros, um nameField e outro secondNameField, para assim inserir dois dados separados por vírgula, ex.: Fortaleza, CE*/}
            {eval('props.value.'+props.nameField)}{props.secondNameField ? ', '+eval('props.value.'+props.secondNameField) : ""}
        </>
  )
}
export default Foreign;