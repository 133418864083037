import React, { useState, useContext } from "react";
import { Row, Col } from "react-bootstrap";
import Swal from "sweetalert2";
import { mutate as mutateGlobal } from "swr";
import api from "../../../../services/api";
import { Prefix } from "../../../../services/prefix";
import Loading from "../../../../components/loader/loading";
import { useStyles } from "../../../../components/buttons/AddButtonStyle";
import {
  CustomButton,
  TextButton,
} from "../../../../style/stylesButton";
import { ButtonClose, ContainerAlert } from './style'
import { Formik, Form, FieldArray, getIn, ErrorMessage } from "formik";
import Schema from "./schema";
import { Context } from "../../../../services/context";
import moment from "moment/moment";

const IndexAddPlan = ({
  pageExact,
  perPage,
  orderBy,
  order,
  search,
}) => {
  const { customerActionPlan, setCustomerActionPlan } = useContext(Context)

  const [loader, setLoader] = useState();
  const [valueSelect, setValueSelect] = useState([]);
  const [registerData, setRegisterData] = useState({
    initialData: [
      {
        description: '',
        solution: '',
        id_contract_item: customerActionPlan?.id_contract_item,
        started_at: customerActionPlan?.epoch,
        cause: ''
      },
    ],
  });

  const handleClose = () => setCustomerActionPlan(false);

  function handleRegister(values) {
    setLoader(true);
    values.initialData.map((m) => {
      api
        .post(Prefix.replace(/[\\"]/g, "") + "/contract-item-actions", {
          description: m.description,
          solution: m.solution,
          id_contract_item: m.id_contract_item,
          started_at: m.started_at.split('/').reverse().join('-'),
          cause: m.cause
        })
        .then((response) => {
          Swal.fire({
            icon: "success",
            title: "Feito!",
            text: response.data.message,
            timer: 3000
          });
          handleClose()
          setLoader(false);
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Desculpe...",
            text: err.response.data.message,
            confirmButtonText: "Tentar novamente",
          });
          setLoader(false);
        });
    });
  };

  const classes = useStyles();

  return (
    <>

      <Formik
        initialValues={registerData}
        validationSchema={Schema}
        onSubmit={handleRegister}
        enableReinitialize
      >
        {({ values, handleChange, setFieldValue }) => (
          <Form>
            <ContainerAlert variant="light" className="mt-2">
              <FieldArray name="initialData">
                {({ push, remove }) => (
                  <div>
                    {values?.initialData.map((p, index) => {
                      return (
                        <>
                          <Row className="d-flex justify-content-end">
                            <ButtonClose onClick={() => setCustomerActionPlan(false)}>
                              X
                            </ButtonClose>
                          </Row>
                          <Row className="d-flex justify-content-center">
                            <div style={{ color: "#545454" }}>
                              <h3 >Cadastro de Plano de Ação</h3>
                            </div>
                          </Row>
                          <Row>
                            <Col>
                              <div className="formStep">
                                <div className="labelForm">
                                  <label class="important">
                                    Ocorrência
                                  </label>
                                </div>
                                <textarea
                                  type="text"
                                  name={`initialData[${index}].description`}
                                  placeholder="Descreva a Ocorrência"
                                  maxlength="180"
                                  rows="7"
                                  cols="10"
                                  onChange={handleChange}
                                ></textarea>
                                <ErrorMessage
                                  name={`initialData[${index}].description`}
                                  component="span"
                                  className="errorMessages"
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div className="formStep">
                                <div className="labelForm">
                                  <label class="important">
                                    Causa
                                  </label>
                                </div>
                                <textarea
                                  type="text"
                                  name={`initialData[${index}].cause`}
                                  placeholder="Descreva a Ocorrência"
                                  maxlength="180"
                                  rows="7"
                                  cols="10"
                                  onChange={handleChange}
                                ></textarea>
                                <ErrorMessage
                                  name={`initialData[${index}].cause`}
                                  component="span"
                                  className="errorMessages"
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div className="formStep">
                                <div className="labelForm">
                                  <label class="important">Ação</label>
                                </div>
                                <textarea
                                  type="text"
                                  name={`initialData[${index}].solution`}
                                  placeholder="Descreva a(as) Ação(ões) tomada(s)"
                                  maxlength="180"
                                  rows="7"
                                  cols="10"
                                  onChange={handleChange}
                                ></textarea>
                                <ErrorMessage
                                  name={`initialData[${index}].solution`}
                                  component="span"
                                  className="errorMessages"
                                />
                              </div>
                            </Col>
                          </Row>
                        </>
                      );
                    })}
                    <div>
                      <Row>
                        <Col className="d-flex justify-content-center ">
                          <CustomButton
                            type="submit"
                          >
                            <TextButton>Cadastrar</TextButton>
                          </CustomButton>
                        </Col>
                      </Row>
                    </div>
                  </div>
                )}
              </FieldArray>
            </ContainerAlert>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default IndexAddPlan;
