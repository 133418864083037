import React from 'react';
import { useFetch } from '../../hooks/useFetch';
import { Prefix } from '../../../services/prefix';
import AddRange from './addRanges';
import EditRange from './editRanges';

const FindRanges = ({
  sensor,
  idEquipmentModel,
  idEquipment,
  idProfileAttribute,
}) => {
  const findMonitor = useFetch(
    Prefix.replace(/[\\"]/g, '') +
      '/monitor-profile-attributes?id_profile_attribute=' +
      idProfileAttribute +
      '&id_contract_item=' +
      idEquipment,
  );
  return (
    <>
      {findMonitor.data?.models?.data.length == 0 ? (
        <AddRange
          sensor={idProfileAttribute}
          idEquipmentModel={idEquipmentModel}
          idEquipment={idEquipment}
        />
      ) : (
        <EditRange
          sensor={findMonitor.data?.models?.data.map((map) => map.id)}
          idEquipmentModel={idEquipmentModel}
        />
      )}
    </>
  );
};
export default FindRanges;
