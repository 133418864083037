export const Prefix = localStorage.getItem('@user_group');

// import { useContext } from 'react';
// import { Context } from './context';

// export const Prefix = () => {
//   const { prefix } = useContext(Context);

//   return prefix;
// };
