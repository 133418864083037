import React, { useState } from 'react';
import { Modal, Col, Row } from 'react-bootstrap';
import { Formik, Form, ErrorMessage } from 'formik';
import Schema from './schema';
import { NoOptionsMessage } from '../../selectAsync/NoOptionsMessage';
import Select from 'react-select';
import { colourStyles } from '../../selectAsync/colourStyles';
import { Prefix } from '../../../services/prefix';
import Swal from 'sweetalert2';
import api from '../../../services/api';
import { mutate as mutateGlobal } from 'swr';
import { Link } from 'react-router-dom';
import { ButtonProposal, TextProposal, Input } from './styles';
import { ModalContainer } from '../../../style/stylesModals';

const AddProposal = ({ id, page, perPage, search, started }) => {
  const [show, setShow] = useState(false);
  const [registerData, setRegisterData] = useState({
    id_proposal_request: id,
    active: 1,
    note: '',
    started_at: started,
    restricted: null,
  });
  const pageExact = parseInt(page) + 1;
  const [valueSelect, setValueSelect] = useState([]);

  const restrict = [
    { value: 1, name: 'Restrito' },
    { value: 0, name: 'Não é restrito' },
  ];

  const handleShow = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  const HandleSelect = (e, name) => {
    if (valueSelect.filter((fill) => fill.nameField == name).length > 0) {
      valueSelect
        .filter((fill) => fill.nameField == name)
        .map((map) => {
          let valueIndice = valueSelect.indexOf(map);
          valueSelect.splice(valueIndice);
        });

      setValueSelect((prev) => {
        return [...prev, { value: e.value, label: e.label, nameField: name }];
      });
      setRegisterData({ ...registerData, [name]: e.value });
    } else {
      setValueSelect((prev) => {
        return [...prev, { value: e.value, label: e.label, nameField: name }];
      });
      setRegisterData({ ...registerData, [name]: e.value });
    }
  };

  const HandleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setRegisterData({ ...registerData, [name]: value });
  };

  const HandleRegister = (values) => {
    api
      .post(Prefix.replace(/[\\"]/g, '') + '/proposals', values)
      .then(() => {
        Swal.fire({
          icon: 'success',
          title: 'Uow!',
          text: 'Cadastro realizado com sucesso!',
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: 'Fechar',
          cancelButtonText: 'Novo Cadastro',
          allowOutsideClick: false,
        }).then((result) => {
          if (!result.isConfirmed) {
            setShow(true);
          }
          mutateGlobal(
            Prefix.replace(/[\\"]/g, '') +
              '/proposal-requests?per_page=' +
              perPage +
              '&page=' +
              pageExact +
              '&search=' +
              search,
          );
        });
        setShow(false);
        mutateGlobal(
          Prefix.replace(/[\\"]/g, '') +
            '/proposal-requests?per_page=' +
            perPage +
            '&page=' +
            pageExact +
            '&search=' +
            search,
        );
      })
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: err.response.data.message,
          confirmButtonText: 'Tentar novamente',
        });
      });
  };

  return (
    <>
      {!show ? (
        <Link to="#" onClick={handleShow}>
          <img
            src="../../../media/icons/add-proposal.svg"
            alt="Adicionar Proposta"
          />
        </Link>
      ) : (
        <ModalContainer
          show={show}
          onHide={handleClose}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Cadastro de proposta</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              components={{ NoOptionsMessage }}
              initialValues={registerData}
              validationSchema={Schema}
              onSubmit={HandleRegister}
              enableReinitialize
            >
              {({ values }) => (
                <Form>
                  <Row>
                    <Col>
                      <div className="formStep">
                        <Input
                          as="textarea"
                          placeholder="Digite uma observação para esta proposta"
                          name="note"
                          onChange={HandleChange}
                        />
                        <ErrorMessage
                          name="note"
                          component="span"
                          className="errorMessages"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col>
                      <div className="formStep">
                        <Select
                          value={valueSelect
                            .filter((fill) => fill.nameField == 'restricted')
                            .map((map) => {
                              return {
                                value: map.value,
                                label: map.label,
                              };
                            })}
                          styles={colourStyles}
                          components={{ NoOptionsMessage }}
                          classNamePrefix="filterProposal"
                          onChange={(e) => HandleSelect(e, 'restricted')}
                          placeholder="Selecione..."
                          options={restrict.map((state) => {
                            return { value: state.value, label: state.name };
                          })}
                        />

                        <ErrorMessage
                          name="restricted"
                          component="span"
                          className="errorMessages"
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="d-flex justify-content-center ">
                      <ButtonProposal type="submit">
                        <TextProposal> Adicionar </TextProposal>
                      </ButtonProposal>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Modal.Body>
        </ModalContainer>
      )}
    </>
  );
};
export default AddProposal;
