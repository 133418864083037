import React from 'react';
import api from '../../../services/api';
import { Prefix } from '../../../services/prefix';
import { Form } from 'react-bootstrap';
import { mutate as GlobalMutate } from 'swr';
import { orderBy } from 'lodash';

export default function UpdateStatus({
  id,
  type,
  activedefault,
  search,
  page,
  rowsPage,
  orderBy,
  order,
}) {
  let pageExact = page + parseInt(1);

  function HandleStatus() {
    if (type == 'active') {
      const formData = {
        active: activedefault == false ? 1 : 0,
      };

      api
        .put(Prefix.replace(/[\\"]/g, '') + '/contracts/' + id, formData)
        .then(() => {
          GlobalMutate(
            Prefix.replace(/[\\"]/g, "") +
            "/contracts?per_page=" +
            rowsPage +
            "&page=" +
            pageExact +
            "&search=" +
            search +
            "&order_by=" +
            orderBy +
            "&order=" +
            order
        );
        });
    } else if (type == 'cover_preventive') {
      const formData = {
        cover_preventive: activedefault == false ? 1 : 0,
      };
      api
        .put(Prefix.replace(/[\\"]/g, '') + '/contracts/' + id, formData)
        .then(() => {
          GlobalMutate(
            Prefix.replace(/[\\"]/g, "") +
            "/contracts?per_page=" +
            rowsPage +
            "&page=" +
            pageExact +
            "&search=" +
            search +
            "&order_by=" +
            orderBy +
            "&order=" +
            order
        );
        });
    } else if (type == 'cover_corretive') {
      const formData = {
        cover_corretive: activedefault == false ? 1 : 0,
      };

      api
        .put(Prefix.replace(/[\\"]/g, '') + '/contracts/' + id, formData)
        .then(() => {
          GlobalMutate(
            Prefix.replace(/[\\"]/g, "") +
            "/contracts?per_page=" +
            rowsPage +
            "&page=" +
            pageExact +
            "&search=" +
            search +
            "&order_by=" +
            orderBy +
            "&order=" +
            order
        );
        });
    } else if (type == 'cover_calibration') {
      const formData = {
        cover_calibration: activedefault == false ? 1 : 0,
      };

      api
        .put(Prefix.replace(/[\\"]/g, '') + '/contracts/' + id, formData)
        .then(() => {
          GlobalMutate(
            Prefix.replace(/[\\"]/g, "") +
            "/contracts?per_page=" +
            rowsPage +
            "&page=" +
            pageExact +
            "&search=" +
            search +
            "&order_by=" +
            orderBy +
            "&order=" +
            order
        );
        });
    }

    GlobalMutate(
      Prefix.replace(/[\\"]/g, "") +
            "/contracts?per_page=" +
            rowsPage +
            "&page=" +
            pageExact +
            "&search=" +
            search +
            "&order_by=" +
            orderBy +
            "&order=" +
            order
        );
  }

  return (
    <>
      <Form>
        <Form.Check
          class="switch"
          type="switch"
          id={id + '-' + type}
          onChange={HandleStatus}
          checked={activedefault}
        />
      </Form>
    </>
  );
}
