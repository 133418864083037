import React from 'react';
import {maskCPF, maskTelFixo, maskTel} from '../../../services/mask'

export const columns = [
  {
      //0
      name: 'id',
      label: '',
      options: {
        setCellHeaderProps: () => ({
          style: {
            'text-align': 'center',
          },
        }),
        setCellProps: () => ({
          style: {
            'text-align': 'center',
          },
        }),
        filter: false,
        sort: false,
        display: 'excluded',
      },
    },

    {
      //1
      name: 'employee',
      label: 'Nome',
      options: {
        setCellHeaderProps: () => ({
          style: {
            'text-align': 'center',
          },
        }),
        setCellProps: () => ({
          style: {
            'text-align': 'center',
          },
        }),
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return value.name;
        },
      },
    },
    {
      //2
      name: 'employee',
      label: 'Email',
      options: {
        setCellHeaderProps: () => ({
          style: {
            'text-align': 'center',
          },
        }),
        setCellProps: () => ({
          style: {
            'text-align': 'center',
          },
        }),
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return value.email;
        },
      },
    },
    {
      //3
      name: 'employee',
      label: 'RG',
      options: {
        print: false,
        setCellHeaderProps: () => ({
          style: {
            'text-align': 'center',
          },
        }),
        setCellProps: () => ({
          style: {
            'text-align': 'center',
          },
        }),
        filter: true,
        sort: false,
        display:false,
        customBodyRender: (value, tableMeta) => {
          return value.rg
        },
      },
    },
    {
      //4
      name: 'employee',
      label: 'CPF',
      options: {
        print: false,
        setCellHeaderProps: () => ({
          style: {
            'text-align': 'center',
          },
        }),
        setCellProps: () => ({
          style: {
            'text-align': 'center',
          },
        }),
        filter: true,
        sort: false,
        display:false,
        customBodyRender: (value, tableMeta) => {
          return maskCPF(value.cpf)
        },
      },
    },
    {
      //5
      name: 'employee',
      label: 'Endereço',
      options: {
        print: false,
        setCellHeaderProps: () => ({
          style: {
            'text-align': 'center',
          },
        }),
        setCellProps: () => ({
          style: {
            'text-align': 'center',
          },
        }),
        filter: true,
        sort: false,
        display: false,
        customBodyRender: (value, tableMeta) => {
          return value.address
        },
      },
    },
    {
      //6
      name: 'employee',
      label: 'Bairro',
      options: {
        print: false,
        setCellHeaderProps: () => ({
          style: {
            'text-align': 'center',
          },
        }),
        setCellProps: () => ({
          style: {
            'text-align': 'center',
          },
        }),
        filter: true,
        sort: false,
        display:false,
        customBodyRender: (value, tableMeta) => {
          return value.neighborhood
        },
      },
    },
    {
      //7
      name: 'employee',
      label: 'Nº',
      options: {
        print: false,
        setCellHeaderProps: () => ({
          style: {
            'text-align': 'center',
          },
        }),
        setCellProps: () => ({
          style: {
            'text-align': 'center',
          },
        }),
        filter: true,
        sort: false,
        display:false,
        customBodyRender: (value, tableMeta) => {
          return value.number
        },
      },
    },
    {
      //8
      name: 'employee',
      label: 'Telefone',
      options: {
        print: false,
        setCellHeaderProps: () => ({
          style: {
            'text-align': 'center',
          },
        }),
        setCellProps: () => ({
          style: {
            'text-align': 'center',
          },
        }),
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return maskTelFixo(value.phone1)
        },
      },
    },
    {
      //9
      name: 'employee',
      label: 'Celular',
      options: {
        print: false,
        setCellHeaderProps: () => ({
          style: {
            'text-align': 'center',
          },
        }),
        setCellProps: () => ({
          style: {
            'text-align': 'center',
          },
        }),
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return maskTel(value.phone2)
        },
      },
    },
    {
      //10
      name: 'employee',
      label: 'Estado',
      options: {
        print: false,
        setCellHeaderProps: () => ({
          style: {
            'text-align': 'center',
          },
        }),
        setCellProps: () => ({
          style: {
            'text-align': 'center',
          },
        }),
        filter: true,
        sort: false,
        display: false,
        customBodyRender: (value, tableMeta) => {
          return value.city.uf
        },
      },
    },
    {
      //11
      name: 'employee',
      label: 'Cidade',
      options: {
        print: false,
        setCellHeaderProps: () => ({
          style: {
            'text-align': 'center',
          },
        }),
        setCellProps: () => ({
          style: {
            'text-align': 'center',
          },
        }),
        filter: true,
        sort: false,
        display: false,
        customBodyRender: (value, tableMeta) => {
          return value.city.name
        },
      },
    },
    {
      //12
      name: 'manager',
      label: 'Gestor(a)' ,
      options: {
        print: false,
        setCellHeaderProps: () => ({
          style: {
            'text-align': 'center',
          },
        }),
        setCellProps: () => ({
          style: {
            'text-align': 'center',
          },
        }),
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return !value ? 'Não' : 'Sim'
        },
      },
    },
    {
      //13
      name: 'employee' ,
      label: 'Técnico(a)',
      options: {
        print: false,
        setCellHeaderProps: () => ({
          style: {
            'text-align': 'center',
          },
        }),
        setCellProps: () => ({
          style: {
            'text-align': 'center',
          },
        }),
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return !value.technician? 'Não' : 'Sim'
        },
      },
    },
    {
      //14
      name: 'employee',
      label: 'Admissão',
      options: {
        print: false,
        setCellHeaderProps: () => ({
          style: {
            'text-align': 'center',
          },
        }),
        setCellProps: () => ({
          style: {
            'text-align': 'center',
          },
        }),
        filter: true,
        sort: false,
        display: false,
        customBodyRender: (value, tableMeta) => {
          return value.admission_at == null ? 'Não Informado': value.admission_at
        },
      },
    },
    {
      //15
      name: 'employee',
      label: 'Demissão',
      options: {
        print: false,
        setCellHeaderProps: () => ({
          style: {
            'text-align': 'center',
          },
        }),
        setCellProps: () => ({
          style: {
            'text-align': 'center',
          },
        }),
        filter: true,
        sort: false,
        display: false,
        customBodyRender: (value, tableMeta) => {
          return value.exoneration_at == null ? 'Em Atividade' : value.exoneration_at
        },
      },
    },
   
];
