import React from 'react';
import Edit from '../../components/equipaments_backup/editEquipamentBackup/indexEditEquipamentBackup';
import UpdateStatus from '../../components/equipaments_backup/updateStatus/updateStatus';
import IndexBackupAttachments from '../../components/equipaments_backup/backup_attachments/indexBackupAttachments';

export const columns = [
  {
    name: 'id',
    label: 'ID Contrato',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      display: 'excluded',
    },
  },
  {
    name: 'contract_item',
    label: 'Modelo de Equipamento',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: true,
      sort: false,
      customBodyRender: (value, tableMeta) => {
        return value?.nickname;
      },
    },
  },
  {
    name: 'serial',
    label: 'Número de Série',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: true,
      sort: false,
    },
  },
  {
    name: 'start_at',
    label: 'Data de Início',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      customBodyRender: (value, tableMeta) => {
        return value.split('-').reverse().join('/');
      },
    },
  },

  {
    name: 'end_at',
    label: 'Data de Término',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      customBodyRender: (value, tableMeta) => {
        return (
          <>
            <b>
              {' '}
              {!value ? 'Em Vigor' : value.split('-').reverse().join('/')}{' '}
            </b>
          </>
        );
      },
    },
  },
  {
    name: 'id',
    label: 'Anexos',
    options: {
      filter: true,
      sort: false,
      print: false,
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      customBodyRender: (value, tableMeta) => {
        return (
          <IndexBackupAttachments
            id={value}
            buttonLabel="Anexos"
            iconClass="fas fa-paperclip"
            modalSize="lg"
            titleLabel="Anexos de Equipamento de Backup"
            idEquipamentBackup={tableMeta.rowData[0]}
          />
        );
      },
    },
  },
  {
    name: 'active',
    label: 'Ativo',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
          width: '10%',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: true,
      sort: false,
      print: false,

      customBodyRender: (value, tableMeta) => {
        return (
          <UpdateStatus
            activeDefault={value}
            id={tableMeta.rowData[0]}
            page={tableMeta.tableState.page}
            rowsPage={tableMeta.tableState.rowsPerPage}
          />
        );
      },
    },
  },
  {
    name: 'id',
    label: 'Editar',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
          width: '10%',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      print: false,

      customBodyRender: (value, tableMeta) => {
        return (
          <Edit
            id={value}
            page={tableMeta.tableState.page}
            rowsPage={tableMeta.tableState.rowsPerPage}
            search={tableMeta.tableState.searchText}
          />
        );
      },
    },
  },
];
