import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./contract_managers.css";
import api from "../../../services/api";
import Fontawesome from "../../fontawesome/fontawesome";
import { Prefix } from "../../../services/prefix";
import Loading from "../../loader/loading";
import Swal from "sweetalert2";
import { useFetch } from "../../hooks/useFetch";
import MUIDataTable from "mui-datatables";
import { columns } from "./columnsManagers";
import CustomFooter from "../../CustomFooter/CustomFooter";
import CustomSearchRender from "../../customSearchRender/CustomSearchRender";
import { mutate as GlobalMutate } from "swr";
import Select from "react-select";
import { Row, Col, Button, Modal, Form } from "react-bootstrap";
import { ModalContainer } from "../../../style/stylesModals";
import { CustomButton, TextButton } from "../../../style/stylesButton";

export const IndexManagers = ({
  iconClass,
  text,
  modalSize,
  idContract,
  idClient,
}) => {
  const [manager, setManager] = useState(false);
  const [linkManager, setLinkManager] = useState(false);
  const [loader, setLoader] = useState();
  const [formData, updateFormData] = useState({ active: 1 });
  const [show, setShow] = useState(false);
  const [data, setData] =
    useState(); /*Armazena novo array contendo todos os itens exceto os removidos */ /*copiar*/
  const [pagina, setPagina] = useState(0);
  const [perPage, setPerPage] = useState(15);
  const [search, setSearch] = useState("");

  let pageExact = pagina + parseInt(1);

  const contractManagers = useFetch(
    Prefix.replace(/[\\"]/g, "") +
      "/contract-managers?per_page=" +
      perPage +
      "&page=" +
      pageExact +
      "&search=" +
      search
  );
  const employeesList = useFetch(
    Prefix.replace(/[\\"]/g, "") +
      "/employees?per_page=" +
      perPage +
      "&page=" +
      pageExact +
      "&employee=1"
  );
  //user.type != '0' && user.type != '2' && user.type != '3' && user.type != '4'

  const managerList = employeesList.data?.models?.data.map((map) => {
    return { value: map.id, label: map.name };
  });

  const options = {
    draggableColumns: {
      enabled: true,
    },
    serverSide: true,
    onTableChange: (action, tableState) => {
      setPagina(tableState.page);
      setPerPage(tableState.rowsPerPage);
    },
    onSearchChange: (searchText) => {
      setSearch(!searchText ? "" : searchText);
    },
    customSearchRender: (searchText, handleSearch, hideSearch, options) => {
      return (
        <CustomSearchRender
          placeholderSearch="Pesquisar gestor"
          searchText={searchText}
          onSearch={handleSearch}
          onHide={hideSearch}
          options={options}
        />
      );
    },
    filter: false,
    page: pagina,
    count: contractManagers.data?.models?.total,
    expandableRows: false,
    expandableRowsHeader: false,
    expandableRowsOnClick: false,
    rowsPerPage: perPage,
    rowsPerPageOptions: [15, 30, 100],

    onRowsDelete: (rowsDeleted, newData) => {
      HandleDelete(rowsDeleted.data);
    },

    selectableRows: "multiple",
    searchOpen: true,

    textLabels: {
      body: {
        noMatch: "Desculpe, não encontramos gestores!",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
      pagination: {
        next: "Avançar",
        previous: "Voltar",
        rowsPerPage: "Linhas por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Pesquisar",
        downloadCsv: "Baixar CSV",
        print: "Imprimir",
        viewColumns: "Ver Colunas",
        filterTable: "Filtrar tabela",
      },
      filter: {
        all: "Tudo",
        title: "FILTROS",
        reset: "RESETAR",
      },
      viewColumns: {
        title: "Exibir colunas",
        titleAria: "Exibir/Ocultar colunas da tabela",
      },
      selectedRows: {
        text: "Coluna(s) selecionada(s)",
        delete: "Excluir",
        deleteAria: "Excluir linhas selecionadas",
      },
    },

    customFooter: (
      count,
      page,
      rowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels
    ) => {
      let numberPages = contractManagers.data?.models?.last_page;
      let rowsPerPageOptions = [15, 30, 100];
      return (
        <CustomFooter
          page={page}
          rowsPerPage={rowsPerPage}
          changeRowsPerPage={changeRowsPerPage}
          changePage={changePage}
          numberPages={numberPages}
          rowsPerPageOptions={rowsPerPageOptions}
        />
      );
    },
  };

  /*Abre o modal */
  const handleShow = () => setShow(true);

  /*Fecha o modal */
  const handleClose = () => {
    setShow(false);
  };

  const handleChangeLink = (e) => {
    updateFormData({
      ...formData,
      id_employee: e.value,
      id_contract: idContract,
    });
  };

  const handleChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value.trim(),
    });
  };

  function HandleLinkManager() {
    setLoader(<Loading />);
    api
      .post(Prefix.replace(/[\\"]/g, "") + "/contract-managers", formData)
      .then((response) => {
        setLinkManager(false);
        setShow(false);

        Swal.fire({
          icon: "success",
          title: "Uow!",
          text: response.data.message,
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: "Fechar",
          cancelButtonText: "Novo Cadastro",
          allowOutsideClick: false,
        }).then((result) => {
          if (!result.isConfirmed) {
            setLinkManager(true);
            setShow(true);
          }
        });
        setManager(false);
        setLinkManager(false);
        contractManagers.mutate();
        setLoader();
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: err.response.data.message,
          confirmButtonText: "Tentar novamente",
        });
        setLoader();
      });
  }

  function HandleDelete(e) {
    setLoader(<Loading />);
    let idArr = [];

    e.map((del) => {
      idArr.push(
        contractManagers.data?.models?.data.filter(
          (filtro, index) => index == del.index
        )[0].id
      );
    });

    idArr.map((idDel) =>
      api
        .delete(Prefix.replace(/[\\"]/g, "") + "/contract-managers/" + idDel)
        .then((response) => {
          Swal.fire({
            icon: "success",
            title: "Uow!",
            text: response.data.message,
            confirmButtonText: "Fechar",
          });
          setLoader();
          contractManagers.mutate();
          GlobalMutate(
            Prefix.replace(/[\\"]/g, "") +
              "/contract-managers?per_page=" +
              perPage +
              "&page=" +
              pageExact +
              "&search="
          );
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: err.response.data.message,
            confirmButtonText: "Tentar novamente",
          });
          setLoader();
        })
    );
  }

  return (
    <>
      {show === false ? (
        <Link className="dropdown-item" to="#" onClick={handleShow}>
          <Fontawesome classe={iconClass} /> {text}
        </Link>
      ) : (
        <ModalContainer
          show={show}
          size="lg"
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <h3>Gestores do Contrato</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {loader}
            <div
              className="customerStep"
              style={{
                display: !manager && !linkManager ? "block" : "none",
              }}
            >
              <div className="d-flex justify-content-center">
                <div>
                  <Button
                    style={{
                      backgroundColor: "#1bc5bd",
                      width: "160px",
                      height: "60px",
                      border: 0,
                      borderRadius: "30px",
                      marginTop: "20px",
                    }}
                    type="button"
                    onClick={() => setLinkManager(true)}
                  >
                    <Fontawesome classe="fas fa-link mr-2" />
                    Vincular
                  </Button>
                </div>
              </div>
              <MUIDataTable
                data={contractManagers.data?.models?.data.filter(
                  (filtro) => filtro.id_contract == idContract
                )}
                columns={columns}
                options={options}
              />
            </div>

            {!show ? (
              ""
            ) : (
              <div
                className="customerStep"
                style={{ display: linkManager ? "block" : "none" }}
              >
                <h2>Vincular Contrato</h2>

                <Row>
                  <Col>
                    <div className="formStep py-3">
                      <label class="important">Selecione Gestor(es):</label>
                      <Select
                        className="basic-single"
                        placeholder="Selecione Colaborador(es)..."
                        name="name"
                        options={managerList}
                        onChange={handleChangeLink}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Label>Observações:</Form.Label>
                      <Form.Control
                        type="text"
                        as="textarea"
                        rows={3}
                        name="attribuition"
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col className="d-flex justify-content-center mt-4">
                    <Button
                      style={{
                        backgroundColor: "#1bc5bd",
                        width: "160px",
                        height: "60px",
                        border: 0,
                        borderRadius: "30px",
                        marginTop: "20px",
                        marginRight: "10px"
                      }}
                      onClick={() => setLinkManager(false)}
                    >
                      Voltar
                    </Button>
                    <CustomButton type='button' onClick={HandleLinkManager}>
                      <TextButton>Vincular</TextButton> 
                    </CustomButton>
                  </Col>
                </Row>
              </div>
            )}
          </Modal.Body>
        </ModalContainer>
      )}
    </>
  );
};
export default IndexManagers;
