import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { Prefix } from '../../../services/prefix';
import Loading from '../../../components/loader/loading';
import api from '../../../services/api';
import Swal from 'sweetalert2';

const ChangePassword = () => {
  const [formData, setFormaData] = useState();
  const [loader, setLoader] = useState();

  function HandleChange(e) {
    setFormaData({
      ...formData,
      [e.target.name]: e.target.value.trim(),
    });
  }

  function HandleRegister() {
    setLoader(<Loading />);

    api
      .put(Prefix.replace(/[\\"]/g, '') + '/user', formData)
      .then((response) => {
        Swal.fire({
          icon: 'success',
          title: 'Uow!',
          text: response.data.message,
          confirmButtonText: 'Fechar',
        });
        setLoader();
      })
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: err.response.data.message,
          confirmButtonText: 'Tentar novamente',
        });
        setLoader();
      });
  }

  return (
    <>
      <div className="formStep">
        <Row>
          {loader}
          <Col>
            <label>Senha antiga:</label>
            <input type="password" name="password_old" onBlur={HandleChange} />
          </Col>
        </Row>
        <Row>
          <Col>
            <label>Nova senha:</label>
            <input type="password" name="password" onBlur={HandleChange} />
          </Col>
        </Row>
        <Row>
          <Col>
            <label>Confirmar nova senha:</label>
            <input
              type="password"
              name="password_confirmation"
              onBlur={HandleChange}
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col className="d-flex justify-content-center">
            <Button variant="success" onClick={HandleRegister}>
              Alterar
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default ChangePassword;
