import React from 'react';
import { Link } from 'react-router-dom';
import EquipmenView from '../equipmentView/equipmentView';
import LinkTelegram from '../telegramView/linkTelegram/linkTelegram';

const OptionsEmployees = ({
  idUser,
  idCustomer,
  page,
  rowsPage,
  search,
  orderBy,
  order,
}) => {
  return (
    <div className="dropdown">
      <Link
        to="#"
        className="dropdown link_color_datatable"
        id="dropdownNotification"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <img src={`/media/icons/elipse_options.svg`} width="5" />
      </Link>
      <ul
        className="dropdown-menu fade-in-top drop-options"
        aria-labelledby="dropdownNotification"
      >
        <li>
          <EquipmenView
            idUser={idUser}
            idCustomer={idCustomer}
            page={page}
            rowsPage={rowsPage}
            search={search}
            orderBy={orderBy}
            order={order}
          />
        </li>
        <li>
          <LinkTelegram
            idUser={idUser}
            idCustomer={idCustomer}
            page={page}
            rowsPage={rowsPage}
            search={search}
            orderBy={orderBy}
            order={order}
          />
        </li>
      </ul>
    </div>
  );
};
export default OptionsEmployees;
