import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Alert, Modal, Form } from 'react-bootstrap';
import Fontawesome from '../../../components/fontawesome/fontawesome';
import { Prefix } from '../../../services/prefix';
import { useFetch } from '../../../components/hooks/useFetch';
import Select from 'react-select';
import api from '../../../services/api';
import Loading from '../../../components/loader/loading';
import Swal from 'sweetalert2';
import download from 'downloadjs';
import { ModalContainer } from "../../../style/stylesModals"; 
import { CustomButton, TextButton, ButtonDesabled} from "../../../style/stylesButton";

const ReportsAlerts = () => {
  const [loader, setLoader] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [formData, setFormData] = useState([]);

  let dayName = new Array(
    'domingo',
    'segunda',
    'terça',
    'quarta',
    'quinta',
    'sexta',
    'sábado',
  );
  let monName = new Array(
    'janeiro',
    'fevereiro',
    'março',
    'abril',
    'maio',
    'junho',
    'agosto',
    'outubro',
    'novembro',
    'dezembro',
  );
  const now = new Date();

  let birthday =
    dayName[now.getDay()] +
    ', ' +
    now.getDate() +
    ' de ' +
    monName[now.getMonth()] +
    ' de ' +
    now.getFullYear();

  const items = useFetch(Prefix.replace(/[\\"]/g, '') + '/contract-items');

  const itemsClient = items.data?.models.data.map((map) => {
    return { value: map.serial, label: map.serial };
  });

  const alertas = [
    { value: 'DANGER', label: 'Alerta Grave' },
    { value: 'WARNING', label: 'Alerta Simples' },
  ];

  const tecnologias = [
    { value: 'wifi', label: 'Wifi' },
    { value: 'gsm', label: 'GSM' },
  ];

  const HandleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value.trim(),
    });
  };

  const HandleChangeSerial = (e) => {
    setFormData({
      ...formData,
      serial: e.value,
    });
  };

  const HandleChangeAlert = (e) => {
    setFormData({
      ...formData,
      type: e.value,
    });
  };

  const HandleChangeTec = (e) => {
    setFormData({
      ...formData,
      network: e.value,
    });
  };

  function HandleRegister() {
    setLoader(true);
    api
      .post(
        Prefix.replace(/[\\"]/g, '') + '/readings/warnings-export',
        formData,
        {
          responseType: 'blob',
        },
      )
      .then((response) => {
        const content = response.headers['content-type'];
        download(response.data, 'Relatorio de Alertas em ' + birthday, content);
        Swal.fire({
          icon: 'success',
          title: 'Uow...',
          text: 'Download realizado com sucesso!',
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: 'Fechar',
          cancelButtonText: 'Novo Relatório',
          allowOutsideClick: false,
        }).then((result) => {
          setLoader(false);
          if (!result.isConfirmed) {
            setShow(true);
          }
        });
        setShow(false);
        setFormData({});
      })
      .catch(() =>
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Para gerar um relatório é necessário escolher uma data e um coletor.',
          confirmButtonText: 'Tentar novamente',
        }).then(() => {
          setLoader(false);
        }),
      );
  }
  return (
    <>
      <Button variant="success" onClick={handleShow}>
        <Fontawesome classe="fas fa-file-pdf mr-3" />
        Gerar Relatório
      </Button>
      {!show ? (
        ''
      ) : (
        <ModalContainer
          show={show}
          size="md"
          onHide={handleClose}
          backdrop="static"
          keyboard={true}
        >
          <Modal.Header closeButton>
            <Modal.Title><h3>Relatório de Alertas</h3></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {loader && <Loading />}
            <Alert variant="dark">
              <form className="customerStep" onSubmit={HandleRegister}>
                <Row>
                  <Col>
                    <div className="formStep">
                      <input
                        type="date"
                        name="epoch"
                        placeholder="Informe à data de admissão..."
                        onChange={HandleChange}
                      />
                    </div>
                    <div className="formStep py-3">
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        closeMenuOnSelect={false}
                        placeholder="Selecione ou digite um Serial..."
                        name="serial"
                        options={itemsClient}
                        onChange={HandleChangeSerial}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="formStep py-3">
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        closeMenuOnSelect={false}
                        placeholder="Selecione tipo de alerta..."
                        name="alerta"
                        options={alertas}
                        onChange={HandleChangeAlert}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="formStep py-3">
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        closeMenuOnSelect={false}
                        placeholder="Selecione Tecnologia..."
                        name="tecnologia"
                        options={tecnologias}
                        onChange={HandleChangeTec}
                      />
                    </div>
                  </Col>
                </Row>

                <Row className="d-flex justify-content-center">
                  <CustomButton type="button" onClick={HandleRegister}>
                     <TextButton>Gerar Relatório</TextButton>
                  </CustomButton>
                </Row>
              </form>
            </Alert>
          </Modal.Body>
        </ModalContainer>
      )}
    </>
  );
};
export default ReportsAlerts;
