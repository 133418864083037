import React from "react";
import api from "../../services/api";
import { Prefix } from "../../services/prefix";
import { mutate as globalMutate } from "swr";
import Swal from "sweetalert2";

export default function Memorable({
  id,
  page,
  rowsPage,
  activeDefault,
  search,
  orderBy,
  order,
}) {
  let pageExact = page + parseInt(1);
  let valueSearch = !search ? "" : search;

  function HandleStatus() {
    api
      .put(Prefix.replace(/[\\"]/g, "") + "/equipment-families/" + id, {
        monitoring: Boolean(activeDefault == 1 ? 0 : 1),
      })
      .then(() => {
        globalMutate(
          Prefix.replace(/[\\"]/g, "") +
            "/equipment-families?per_page=" +
            rowsPage +
            "&page=" +
            pageExact +
            "&search=" +
            valueSearch +
            "&order_by=" +
            orderBy +
            "&order=" +
            order
        );
      })
      .catch((r) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Não foi possível alterar esta família, é possível que esteja vinculada a outras informações.",
          showConfirmButton: true,
        });
      });
  }

  return (
    <>
      <label class="switch">
        <input
          type="checkbox"
          id={id + "Memorable"}
          onChange={HandleStatus}
          checked={activeDefault}
        />
        <span class="slider round"></span>
      </label>
    </>
  );
}
