import React, { useState, useRef, useEffect, useContext } from 'react';
import { useFetch } from '../../../components/hooks/useFetch';
import { Prefix } from '../../../services/prefix';
import { uf } from '../../data/uf';
import './units.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useOnClickOutside } from '../../../components/hooks/useOnClickOutSide';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Context } from '../../../services/context';

const FilterUnits = () => {
  const {
    selectclient,
    selectuf,
    selectcity,
    setSelectClient,
    setSelectUf,
    setSelectCity,
  } = useContext(Context);

  const resetFilter = () => {
    setSelectClient('');
    setSelectUf('');
    setSelectCity('');
  };

  useEffect(() => {
    setSelectUf('');
    setSelectCity('');
  }, [selectclient]);

  const listClient = useFetch(Prefix.replace(/[\\"]/g, '') + '/customers');
  const listCity = useFetch(Prefix.replace(/[\\"]/g, '') + '/cities');

  return (
    <Row>
      <Col>
        <div className="filterAlerts">
          <select onChange={(e) => setSelectClient(e.target.value)}>
            <option selected disabled>
              Filtrar por Cliente
            </option>
            {listClient.data?.models.data.map((map, key) => {
              return (
                <option key={key} value={map.id}>
                  {map.name}
                </option>
              );
            })}
          </select>
          <select onChange={(e) => setSelectUf(e.target.value)}>
            {!selectclient ? (
              <option selected disabled>
                Filtrar por Estado
              </option>
            ) : (
              ''
            )}
            <option value=""> Nenhum </option>
            {uf.map((map, key) => {
              return (
                <option key={key} value={map.value}>
                  {map.name}
                </option>
              );
            })}
          </select>
          <select onChange={(e) => setSelectCity(e.target.value)}>
            {!selectuf ? (
              <option selected disabled>
                Filtrar por Cidade
              </option>
            ) : (
              ''
            )}
            <option value=""> Nenhum </option>
            {listCity.data?.models.data
              .filter((filtro) => filtro.uf == selectuf)
              .map((map, key) => {
                return (
                  <option key={key} value={map.id}>
                    {map.name}
                  </option>
                );
              })}
          </select>

          <Link to="#" className="ml-4" onClick={resetFilter}>
            <i class="fas fa-undo-alt"></i>
          </Link>
        </div>
      </Col>
    </Row>
  );
};
export default FilterUnits;
