import React, { useState, useRef, useContext } from "react";
import { Row, Col, Button, Modal } from "react-bootstrap";
import "./sectors.css";
import { Prefix } from "../../../services/prefix";
import Loading from "../../loader/loading";
import Swal from "sweetalert2";
import api from "../../../services/api";
import Alert from "react-bootstrap/Alert";
import { useFetch } from "../../hooks/useFetch";
import Fontawesome from "../../fontawesome/fontawesome";
import { mutate as GlobalMutate } from "swr";
import { Context } from "../../../services/context";
import { Formik, Form, FieldArray, getIn, ErrorMessage, Field } from "formik";
import Schema from "./schema";
import { v4 as uuidv4 } from "uuid";
import Select from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import { loadOptions } from "../../selectAsync/loadOptions";
import { colourStyles } from "../../selectAsync/colourStyles";
import "./style.css";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import { useStyles } from "../../buttons/AddButtonStyle";
import { ModalContainer } from "../../../style/stylesModals"; 
import { CustomButton, TextButton, ButtonDesabled} from "../../../style/stylesButton";

const IndexAddSector = ( {pageExact, perPage, search}) => {
  const {
    showSectorsWz,
    setShowSectorsWz,
    setShowRoomsWz,
    listUnitsWz,
    setListUnitsWz,
    listSectorsWz,
    setListSectorsWz,
  } = useContext(Context);
  const { selectclient, selectunit } = useContext(Context);
  const [idUnit, setIdUnit] = useState();
  const [loader, setLoader] = useState();
  const [valueSelect, setValueSelect] = useState([]);
  const [registerData, setRegisterData] = useState({
    initialData: [
      {
        key: uuidv4(),
        name: "",
        active: "1",
      },
    ],
  });

  const handleClose = () => {
    setShowSectorsWz(false);
    setListUnitsWz([]);
    setListSectorsWz([])
    setValueSelect([]);
  };

  const HandleSelect = (e, name, key) => {
    if (
      valueSelect.filter((fill) => fill.key == key && fill.nameField == name)
        .length > 0
    ) {
      valueSelect
        .filter((fill) => fill.key == key && fill.nameField == name)
        .map((map) => {
          let position = valueSelect.indexOf(map);
          valueSelect.splice(position, 1);
        });
      setValueSelect(() => {
        return [
          ...valueSelect,
          { value: e.value, label: e.label, nameField: name, key: key },
        ];
      });
    } else {
      setValueSelect((prev) => {
        return [
          ...prev,
          { value: e.value, label: e.label, nameField: name, key: key },
        ];
      });
    }
  };

  function HandleRegister(values) {
    setLoader(true);
    values.initialData.map((setor) =>
      api
        .post(Prefix.replace(/[\\"]/g, "") + "/sectors", {
          name: setor.name,
          active: 1,
          id_unit: idUnit,
        })
        .then((response) => {
          listSectorsWz.push({
            value: response?.data?.model?.id,
            label: response?.data?.model?.name,
          });
          Swal.fire({
            icon: "success",
            title: "Uow!",
            text: response.data.message,
            showCloseButton: true,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: "Novo Cadastro",
            cancelButtonText: ">> Cadastrar Salas",
            allowOutsideClick: false,
            focusCancel: true,
          }).then((result) => {
            if (result.isConfirmed) {
              setShowSectorsWz(true);
            } else if (result.dismiss == "cancel") {
              setShowSectorsWz(false);
              setShowRoomsWz(true);
            }
          });
          setLoader(false);
          setShowSectorsWz(false);
          setValueSelect([]);
          GlobalMutate(
            Prefix.replace(/[\\"]/g, "") +
              "/sectors?per_page=" +
              perPage +
              "&page=" +
              pageExact +
              "&id_unit=" +
              selectunit +
              "&id_customer=" +
              selectclient
          );
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: err.response.data.message,
            confirmButtonText: "Tentar novamente",
          });
          setLoader(false);
        })
    );
  }

  const classes = useStyles();

  return (
    <>
      <ModalContainer
        show={showSectorsWz}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title><h3>Setores</h3></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik //--> Configuração do Formik
            initialValues={registerData}
            validationSchema={Schema}
            onSubmit={HandleRegister}
            enableReinitialize
          >
            {({ values, handleChange }) => (
              <Form>
                <Alert variant="success">
                  <FieldArray name="initialData">
                    {({ push, remove }) => (
                      <div>
                        <div className="customerStep">
                          <Alert variant="success">
                            <Row style={{ zIndex: 1 }}>
                              <Col>
                                <div className="formStep">
                                  <label className="important">Unidade</label>
                                    <AsyncPaginate
                                      className="basic-single"
                                      classNamePrefix="select"
                                      closeMenuOnSelect={false}
                                      placeholder="Selecione o Cliente..."
                                      options={listUnitsWz.map((map) => {
                                        return {
                                          value: map.value,
                                          label: map.label,
                                        };
                                      })}
                                      name='unidade'
                                      onChange={(e)=> setIdUnit(e.value)}
                                    />
                                </div>
                              </Col>
                            </Row>
                          </Alert>
                        </div>

                        {values.initialData.map((p, index) => {
                          return (
                            <>
                              <div
                                className="customerStep"
                                style={{
                                  display: !idUnit
                                      ? "none"
                                      : "block",
                                }}
                              >
                                <Alert variant="success" style={{ zIndex: 0 }}>
                                  <Row>
                                    <Col>
                                      <div
                                        className="formStep"
                                        style={{ zIndex: 0 }}
                                      >
                                        <div
                                          className="d-flex justify-content-between"
                                          style={{ zIndex: 0 }}
                                        >
                                          <label class="important">Setor</label>{" "}
                                          {values.initialData.length < 1 ? (
                                            ""
                                          ) : (
                                            <Button
                                              variant="danger"
                                              className="btn-remove-item"
                                              onClick={() => {
                                                remove(index);
                                              }}
                                            >
                                              x
                                            </Button>
                                          )}
                                        </div>
                                        <Field
                                          type="text"
                                          placeholder="Digite o nome do setor"
                                          name={`initialData[${index}].name`}
                                          onChange={handleChange}
                                        />
                                        <ErrorMessage
                                          name={`initialData[${index}].name`}
                                          component="span"
                                          className="errorMessages"
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </Alert>
                              </div>
                            </>
                          );
                        })}
                        <div>
                          { !idUnit ? (
                            ""
                          ) : (
                            <Row>
                              <Col className="d-flex justify-content-center ">
                                <Button
                                  style={{ 
                                    'backgroundColor': '#1bc5bd',
                                    'width': '160px',
                                    'height': '60px',
                                    'border': 0,
                                    'borderRadius': '30px',
                                    'marginTop': '20px'
                                  }}
                                  onClick={() => {
                                    push({
                                      key: uuidv4(),
                                      name: "",
                                      active: "1",
                                    });
                                  }}
                                  disabled={!idUnit ? "disabled"
                                      : ""
                                  }
                                >
                                  Adicionar
                                </Button>
                              </Col>
                              <Col className="d-flex justify-content-center ">
                                {values.initialData.length == 0 ? (
                                  ""
                                ) : (
                                  <CustomButton
                                    type="submit"
                                  >
                                    <TextButton>Cadastrar</TextButton>
                                  </CustomButton>
                                )}
                              </Col>
                            </Row>
                          )}
                        </div>
                      </div>
                    )}
                  </FieldArray>
                </Alert>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </ModalContainer>
    </>
  );
};

export default IndexAddSector;
