import React from 'react';
import api from '../../../../services/api';
import { Prefix } from '../../../../services/prefix';
import { mutate as globalMutate } from 'swr';

export default function MonitoringStatus({
  id,
  id_contract_item,
  page,
  rowsPage,
  monitoringDefault,
  search,
}) {
  let pageExact = page + parseInt(1);

  function HandleStatus() {
    api
      .put(Prefix.replace(/[\\"]/g, '') + '/contract-notifications/' + id, {
        monitoring: monitoringDefault === 1 ? 0 : 1,
      })
      .then(() => {
        globalMutate(
          Prefix.replace(/[\\"]/g, '') +
          '/contract-notifications?per_page=' +
          rowsPage +
          '&page=' +
          pageExact +
          '&search=' +
          search +
          '&id_contract_item=' +
          id_contract_item,
        );
      });
  }

  return (
    <>
      <label class="switch">
        <input
          type="checkbox"
          id={id + 'monitoring'}
          onChange={HandleStatus}
          checked={monitoringDefault == 1 ? true : false}
        />
        <span class="slider round"></span>
      </label>
    </>
  );
}
