import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const Signal = ({ signal, network, situation }) => {
  const networkName = network == 'wifi' ? 'WIFI' : 'GSM';
  return (
    <>
      {situation == 'DARK' ? (
        <OverlayTrigger
          overlay={<Tooltip id="tooltip-disabled">{networkName}</Tooltip>}
        >
          <span style={{ marginRight: '4px' }}>
            <img src="/media/signal/gsm/nolevel.svg" width="22" />
          </span>
        </OverlayTrigger>
      ) : network == 'wifi' ? (
        //Wifi

        signal > 74 ? (
          <OverlayTrigger
            overlay={<Tooltip id="tooltip-disabled">{networkName}</Tooltip>}
          >
            <span style={{ marginRight: '4px', marginBottom: '3px' }}>
              <img src="/media/signal/wifi/level4.svg" width="22" />
            </span>
          </OverlayTrigger>
        ) : signal < 76 && signal > 49 ? (
          <OverlayTrigger
            overlay={<Tooltip id="tooltip-disabled">{networkName}</Tooltip>}
          >
            <span style={{ marginRight: '4px', marginBottom: '3px' }}>
              <img src="/media/signal/wifi/level3.svg" width="22" />
            </span>
          </OverlayTrigger>
        ) : signal < 51 && signal > 24 ? (
          <OverlayTrigger
            overlay={<Tooltip id="tooltip-disabled">{networkName}</Tooltip>}
          >
            <span style={{ marginRight: '4px', marginBottom: '3px' }}>
              <img src="/media/signal/wifi/level2.svg" width="22" />
            </span>
          </OverlayTrigger>
        ) : signal < 25 && signal > 0 ? (
          <OverlayTrigger
            overlay={<Tooltip id="tooltip-disabled">{networkName}</Tooltip>}
          >
            <span style={{ marginRight: '4px', marginBottom: '3px' }}>
              <img src="/media/signal/wifi/level1.svg" width="22" />
            </span>
          </OverlayTrigger>
        ) : signal == 0 ? (
          <OverlayTrigger
            overlay={<Tooltip id="tooltip-disabled">{networkName}</Tooltip>}
          >
            <span style={{ marginRight: '4px', marginBottom: '3px' }}>
              <img src="/media/signal/wifi/level0.svg" width="22" />
            </span>
          </OverlayTrigger>
        ) : signal == 0 ? (
          <OverlayTrigger
            overlay={<Tooltip id="tooltip-disabled">{networkName}</Tooltip>}
          >
            <span style={{ marginRight: '4px', marginBottom: '3px' }}>
              <img src="/media/signal/wifi/level0.svg" width="22" />
            </span>
          </OverlayTrigger>
        ) : (
          ''
        )
      ) : //GSM

      signal > 74 ? (
        <OverlayTrigger
          overlay={<Tooltip id="tooltip-disabled">{networkName}</Tooltip>}
        >
          <span style={{ marginRight: '6px' }}>
            <img src="/media/signal/gsm/level4.svg" width="14" />
          </span>
        </OverlayTrigger>
      ) : signal < 76 && signal > 49 ? (
        <OverlayTrigger
          overlay={<Tooltip id="tooltip-disabled">{networkName}</Tooltip>}
        >
          <span style={{ marginRight: '6px' }}>
            <img src="/media/signal/gsm/level3.svg" width="14" />
          </span>
        </OverlayTrigger>
      ) : signal < 51 && signal > 24 ? (
        <OverlayTrigger
          overlay={<Tooltip id="tooltip-disabled">{networkName}</Tooltip>}
        >
          <span style={{ marginRight: '6px' }}>
            <img src="/media/signal/gsm/level2.svg" width="14" />
          </span>
        </OverlayTrigger>
      ) : signal < 25 && signal > 0 ? (
        <OverlayTrigger
          overlay={<Tooltip id="tooltip-disabled">{networkName}</Tooltip>}
        >
          <span style={{ marginRight: '6px' }}>
            <img src="/media/signal/gsm/level1.svg" width="14" />
          </span>
        </OverlayTrigger>
      ) : signal == 0 ? (
        <OverlayTrigger
          overlay={<Tooltip id="tooltip-disabled">{networkName}</Tooltip>}
        >
          <span style={{ marginRight: '6px' }}>
            <img src="/media/signal/gsm/level0.svg" width="15" />
          </span>
        </OverlayTrigger>
      ) : (
        ''
      )}
    </>
  );
};
export default Signal;
