import React from 'react';
import UpdateStatus from './updateStatus/updateStatus';
import EquipmentView from '../../components/equipmentView/equipmentView';

export const columns = [
  {
    //0
    name: 'user',
    label: 'id',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      display: 'excluded',
      customBodyRender: (value, tableMeta) => {
        return value?.id;
      },
    },
  },
  {
    //1
    name: 'user',
    label: 'Nome',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      customBodyRender: (value, tableMeta) => {
        return value?.name;
      },
    },
  },
  {
    //2
    name: 'user',
    label: 'Email',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      customBodyRender: (value, tableMeta) => {
        return value?.email;
      },
    },
  },
  {
    //3
    name: 'equipmentView',
    label: 'Permissões',
    options: {
      print: false,
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
          width: '20%',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
          width: '20%',
        },
      }),
      filter: true,
      sort: false,
      customBodyRender: (value, tableMeta) => {
        return (
          <EquipmentView
            id={tableMeta.rowData[0].id}
            idClient={tableMeta.rowData[6]}
          />
        );
      },
    },
  },
  {
    //4
    name: 'user',
    label: 'Email',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
          width: '40%',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
          width: '40%',
        },
      }),
      filter: false,
      sort: false,
      display: 'excluded',
      customBodyRender: (value, tableMeta) => {
        return value?.email;
      },
    },
  },
  {
    //5
    name: 'user',
    label: 'type',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      display: 'excluded',
      customBodyRender: (value, tableMeta) => {
        return value?.type;
      },
    },
  },
  {
    //6
    name: 'id_customer',
    label: 'Id Cliente',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      display: 'excluded',
    },
  },
];
