import React, { useState } from 'react';
import Sidebar from '../../components/sidebar/sidebar';
import './customer.css';
import Topbar from '../../components/topbar/topbar';
import Card from 'react-bootstrap/Card';
import { columns } from './columns.jsx';
import Content from '../../components/customer/addcustomer/indexAddCustomer';
import MUIDataTable from 'mui-datatables';
import CustomSearchRender from './CustomSearchRender';
import Fontawesome from '../../components/fontawesome/fontawesome';
import { Prefix } from '../../services/prefix';
import { useFetch } from '../../components/hooks/useFetch';
import Loading from '../../components/loader/loading';
import api from '../../services/api';
import Swal from 'sweetalert2';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export const Customer = () => {
  const [loader, setLoader] = useState();
  const [pagina, setPagina] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [search, setSearch] = useState();

  let pageExact = pagina + parseInt(1);

  const customerList = useFetch(
    Prefix.replace(/[\\"]/g, '') +
      '/clientes/paginacao/' +
      perPage +
      '?page=' +
      pageExact,
  );

  const dataSearch = useFetch(
    Prefix.replace(/[\\"]/g, '') +
      '/clientes/buscar?page=' +
      pageExact +
      '&name=' +
      search +
      '&per_page=' +
      perPage,
  );
  const dataList = !search
    ? customerList.data?.clients
    : dataSearch.data?.Clients;

  const options = {
    draggableColumns: {
      enabled: true,
    },
    serverSide: true,
    onTableChange: (action, tableState) => {
      setPagina(tableState.page);
      setPerPage(tableState.rowsPerPage);
    },
    onSearchChange: (searchText) => {
      setSearch(searchText);
    },
    customSearchRender: (searchText, handleSearch, hideSearch, options) => {
      return (
        <CustomSearchRender
          placeholderSearch="Pesquisar cliente"
          searchText={searchText}
          onSearch={handleSearch}
          onHide={hideSearch}
          options={options}
        />
      );
    },
    filter: false,
    page: pagina,
    count: dataList?.total,
    expandableRows: true,
    expandableRowsHeader: false,
    expandableRowsOnClick: false,
    rowsPerPage: perPage,
    rowsPerPageOptions: [15, 30, 100],
    isRowExpandable: (dataIndex, expandedRows) => {
      if (dataIndex === 3 || dataIndex === 4) return false;
      if (
        expandedRows.data.length > 4 &&
        expandedRows.data.filter((d) => d.dataIndex === dataIndex).length === 0
      )
        return false;
      return true;
    },
    renderExpandableRow: (rowData, rowMeta) => {
      const colSpan = rowData.length + 1;
      return (
        <TableRow>
          <TableCell colSpan={colSpan}>
            <Row>
              <Col className="text-center">
                <strong>Nome do cliente:</strong> {rowData[2]}
                <br />
                <strong>Endereço:</strong>{' '}
                {rowData[8] +
                  ', ' +
                  rowData[10] +
                  ', ' +
                  rowData[9] +
                  ', ' +
                  rowData[7]}
                <br />
                <strong>Telefone:</strong> {rowData[12]}
              </Col>
            </Row>
          </TableCell>
        </TableRow>
      );
    },
    onRowsDelete: (rowsDeleted, newData) => {
      HandleDelete(rowsDeleted.data);
    },
    searchPlaceholder: 'Pesquise um cliente',
    selectableRows: 'multiple',
    searchOpen: true,
    body: {
      noMatch: 'Desculpe, não encontramos clientes!',
      toolTip: 'Sort',
      columnHeaderTooltip: (column) => `Sort for ${column.label}`,
    },
    textLabels: {
      pagination: {
        next: 'Avançar',
        previous: 'Voltar',
        rowsPerPage: 'Linhas por página:',
        displayRows: 'de',
      },
      toolbar: {
        search: 'Pesquisar',
        downloadCsv: 'Baixar CSV',
        print: 'Imprimir',
        viewColumns: 'Ver Colunas',
        filterTable: 'Filtrar tabela',
      },
      filter: {
        all: 'Tudo',
        title: 'FILTROS',
        reset: 'RESETAR',
      },
      viewColumns: {
        title: 'Exibir colunas',
        titleAria: 'Exibir/Ocultar colunas da tabela',
      },
      selectedRows: {
        text: 'Coluna(s) selecionada(s)',
        delete: 'Excluir',
        deleteAria: 'Excluir linhas selecionadas',
      },
    },
  };

  function HandleDelete(e) {
    setLoader(<Loading />);
    let idArr = [];
    e.map((del) => {
      idArr.push(
        dataList?.data.filter((filtro, index) => index == del.index)[0].id,
      );
    });

    api
      .post(Prefix.replace(/[\\"]/g, '') + '/clientes/deletavarios', {
        id: idArr,
      })
      .then((response) => {
        Swal.fire({
          icon: 'success',
          title: 'Uow!',
          text: response.data.message,
          confirmButtonText: 'Fechar',
        });
        setLoader();
        customerList.mutate();
      })
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: err.response.data.message,
          confirmButtonText: 'Tentar novamente',
        });
        setLoader();
      });
  }

  return (
    <div className="wrapper d-flex align-items-stretch">
      <Sidebar />
      <div id="content">
        {loader}
        <Topbar />
        <div className="content_all">
          <Card>
            <Card.Header as="h5">
              <h3 class="card-label header-card">
                <span>
                  <Fontawesome classe="fas fa-users mr-3" /> Clientes{' '}
                  <small>Gestão de clientes</small>
                </span>
                <Content
                  titleLabel="Cadastro de Cliente"
                  buttonLabel="Adicionar"
                  iconClass="fas fa-plus-circle"
                  modalSize="md"
                />
              </h3>
            </Card.Header>
            <Card.Body>
              <Card.Text>
                <MUIDataTable
                  data={dataList?.data}
                  columns={columns}
                  options={options}
                />
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Customer;
