import React from "react";
import Edit from "../../components/equipaments_category/editEquipamentsCategory/editEquipamentsCategory";
import UpdateStatus from "../../components/equipaments_category/updateStatus/updateStatus";

export const columns = [
  {
    name: "id",
    label: "",
    options: {
      setCellHeaderProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      setCellProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      filter: false,
      sort: false,
      display: "excluded",
    },
  },
  {
    name: "name",
    label: "Equipamento",
    options: {
      setCellHeaderProps: () => ({
        style: {
          display: "flex",
          justifyContent: "center",
        },
      }),
      setCellProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      filter: false,
      sort: true,
    },
  },

  {
    name: "equipment_family",
    label: "Família",
    options: {
      setCellHeaderProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      setCellProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      filter: false,
      sort: false,

      customBodyRender: (value, tableMeta) => {
        return value?.name;
      },
    },
  },

  {
    name: "complexity",
    label: "Complexidade",
    options: {
      setCellHeaderProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      setCellProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      filter: false,
      sort: false,
      display: false,
    },
  },
  {
    name: "max_acceptable_error",
    label: "Erro máximo",
    options: {
      setCellHeaderProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      setCellProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      filter: false,
      sort: false,
      display: false,
    },
  },
  {
    name: "corrective",
    label: "Corretivas",
    options: {
      setCellHeaderProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      setCellProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      filter: false,
      sort: false,
      display: false,
    },
  },
  {
    name: "preventive",
    label: "Preventivas",
    options: {
      setCellHeaderProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      setCellProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      filter: false,
      sort: false,
      display: false,
    },
  },
  {
    name: "calibration",
    label: "Calibrações",
    options: {
      setCellHeaderProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      setCellProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      filter: false,
      sort: false,
      display: false,
    },
  },
  {
    name: "validation",
    label: "Validação",
    options: {
      setCellHeaderProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      setCellProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      filter: false,
      sort: false,
      display: false,
    },
  },
  {
    name: "qualification",
    label: "Qualificação",
    options: {
      setCellHeaderProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      setCellProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      filter: false,
      sort: false,
      display: false,
    },
  },
  {
    name: "calibration_third",
    label: "Cal. Terceirizada",
    options: {
      setCellHeaderProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      setCellProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      filter: false,
      sort: false,
      display: false,
    },
  },
  {
    name: "corrective_third",
    label: "Cor. Terceirizada",
    options: {
      setCellHeaderProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      setCellProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      filter: false,
      sort: false,
      display: false,
    },
  },
  {
    name: "preventive_third",
    label: "Prev. Terceirizada",
    options: {
      setCellHeaderProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      setCellProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      filter: false,
      sort: false,
      display: false,
    },
  },
  {
    name: "validation_third",
    label: "Val. Terceirizada",
    options: {
      setCellHeaderProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      setCellProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      filter: false,
      sort: false,
      display: false,
    },
  },
  {
    name: "qualification_third",
    label: "Qual. Terceirizada",
    options: {
      setCellHeaderProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      setCellProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      filter: false,
      sort: false,
      display: false,
    },
  },
  {
    name: "default_calibration_frequency",
    label: "Freq. Calibração",
    options: {
      setCellHeaderProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      setCellProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      filter: false,
      sort: false,
      display: false,
    },
  },
  {
    name: "default_preventive_frequency",
    label: "Freq. Preventiva",
    options: {
      setCellHeaderProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      setCellProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      filter: false,
      sort: false,
      display: false,
    },
  },

  {
    name: "active",
    label: "Status",
    options: {
      setCellHeaderProps: () => ({
        style: {
          "text-align": "center",
          width: "10%",
        },
      }),
      setCellProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      filter: true,
      sort: false,
      print: false,
      customBodyRender: (value, tableMeta) => {
        return (
          <UpdateStatus
            activeDefault={value}
            id={tableMeta.rowData[0]}
            page={tableMeta.tableState.page}
            rowsPage={tableMeta.tableState.rowsPerPage}
            orderBy={tableMeta.tableState.sortOrder.name}
            order={tableMeta.tableState.sortOrder.direction}
            search={tableMeta.tableState.searchText}
          />
        );
      },
    },
  },

  {
    name: "id",
    label: "Editar",
    options: {
      setCellHeaderProps: () => ({
        style: {
          "text-align": "center",
          width: "10%",
        },
      }),
      setCellProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      filter: false,
      sort: false,
      print: false,
      customBodyRender: (value, tableMeta) => {
        return (
          <Edit
            page={tableMeta.tableState.page}
            rowsPage={tableMeta.tableState.rowsPerPage}
            id={value}
            search={tableMeta.tableState.searchText}
            orderBy={tableMeta.tableState.sortOrder.name}
            order={tableMeta.tableState.sortOrder.direction}
          />
        );
      },
    },
  },
];
