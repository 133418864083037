import styled from 'styled-components';

export const Caption = styled.p`
  text-align: center;
  color: #000000;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
`;

export const ButtonProposal = styled.button`
  background-color: #822a2a;
  width: 200px;
  height: 60px;
  border: 0;
  border-radius: 30px;
  margin-top: 50px;
`;

export const TextProposal = styled.span`
  font-family: Poppins;
  text-align: center;
  vertical-align: center;
  color: #ffffff;
`;

export const Input = styled.input`
  height: 50px;
  background: #f6f6f6;
  border-radius: 13px;
  width: 100%;
  border: 0;
  border-color: #e2e5ec;
  padding: 0 10px;
  :-ms-input-placeholder {
    padding: 0 10px;
  }
  &:input:focus {
    border-color: #e2e5ec;
  }
  ::placeholder,
  ::-webkit-input-placeholder {
    padding-left: 10px;
  }
`;

export const SelectInput = styled.select`
  height: 50px;
  background: #f6f6f6;
  border-radius: 13px;

  border: 0;
  border-color: #e2e5ec;
  &:input:focus {
    border-color: #e2e5ec;
  }
`;
