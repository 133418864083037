import React, { useEffect, useState, useContext } from "react";
import api from "../../../services/api";
import { Prefix } from "../../../services/prefix";
import { useFetch } from "../../hooks/useFetch";
import { mutate as globalMutate } from "swr";
import Form from "react-bootstrap/Form";
import { Context } from "../../../services/context";

export default function UpdateStatus({ id, page, rowsPage, activeDefault, orderBy, order}) {
  let pageExact = page + parseInt(1);

  const {
    selectclient,
    selectunit,
    selectsector,
    setSelectClient,
    setSelectUnit,
    setSelectSector,
  } = useContext(Context);

  function HandleStatus() {
    api
      .put(Prefix.replace(/[\\"]/g, "") + "/rooms/" + id, {
        active: Boolean(activeDefault === 1 ? 0 : 1),
      })
      .then(() => {
        globalMutate(
          Prefix.replace(/[\\"]/g, "") +
            "/rooms?per_page=" +
            rowsPage +
            "&page=" +
            pageExact +
            "&id_customer=" +
            selectclient +
            "&id_unit=" +
            selectunit +
            "&id_sector=" +
            selectsector +
            "&order_by=" +
            orderBy +
            "&order=" +
            order
        );
      });
  }

  return (
    <>
      <label class="switch">
        <input
          type="checkbox"
          id={id + "rooms"}
          onChange={HandleStatus}
          checked={activeDefault}
        />
        <span class="slider round"></span>
      </label>
    </>
  );
}
