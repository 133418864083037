import React, { useState, useContext } from 'react';
import Sidebar from '../../components/sidebar/sidebar';
import './dashboard.css';
import Topbar from '../../components/topbar/topbar';
import { Row, Col, Card, Alert } from 'react-bootstrap';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MapBrazil } from 'react-brazil-map';
import Chart from 'react-apexcharts';
import Draggable from 'react-draggable';
import { useFetch } from '../../components/hooks/useFetch';
import { Prefix } from '../../services/prefix';
import Fontawesome from '../../components/fontawesome/fontawesome';
import { uf } from '../../components/data/uf';
import Loading from '../../components/loader/loading';
import { Context } from '../../services/context';
import GaugeChart from 'react-advanced-gauge-chart';
import Maps from '../maps/map';

const Dashboard = () => {
  const [district, setDistrict] = useState('');
  const { dashboard, setDashboard } = useContext(Context);

  // const listDashboard = useFetch(
  //   Prefix.replace(/[\\"]/g, '') + '/dtlab/dashboard',
  // );
  // const listMonitoring = useFetch(
  //   Prefix.replace(/[\\"]/g, '') + '/monitoring/dashboard',
  // );
  // const listOs = useFetch(Prefix.replace(/[\\"]/g, '') + '/os/dashboard');
  // if (!listDashboard.data) return <Loading />;

  // let now = new Date();
  // let month = now.getMonth();

  // function converteMes(e) {
  //   switch (parseInt(e) + 1) {
  //     case 1:
  //       return 'Janeiro';
  //       break;
  //     case 2:
  //       return 'Fevereiro';
  //       break;
  //     case 3:
  //       return 'Março';
  //       break;
  //     case 4:
  //       return 'Abril';
  //       break;
  //     case 5:
  //       return 'Maio';
  //       break;
  //     case 6:
  //       return 'Junho';
  //       break;
  //     case 7:
  //       return 'Julho';
  //       break;
  //     case 8:
  //       return 'Agosto';
  //       break;
  //     case 9:
  //       return 'Setembro';
  //       break;
  //     case 10:
  //       return 'Outubro';
  //       break;
  //     case 11:
  //       return 'Novembro';
  //       break;
  //     case 12:
  //       return 'Dezembro';
  //       break;
  //   }
  // }

  return (
    <>
      <ToastContainer />
      <div className="wrapper d-flex align-items-stretch">
        <Sidebar />
        <div id="content">
          <Topbar />
          <div className="content_all">
            {dashboard == 1 ? (
              <Row>
                <Col sm={12} className="card-mt-4">
                  <Maps />
                </Col>
              </Row>
            ) : (
              <>
                {/* <Row className="defaultCards">
                  <Col sm={4} className="card-mt-4">
                    <Card>
                      <Card.Header>
                        <h3 className="card-label">
                          Clientes <small>Quantidade de clientes</small>
                        </h3>
                      </Card.Header>
                      <Card.Body className="height-limit">
                        <Card.Text as="div">
                          <Row>
                            <Col>
                              <Chart
                                options={{
                                  labels: ['Ativos', 'Inativos'],
                                  colors: ['#752929', '#450808'],
                                  plotOptions: {
                                    pie: {
                                      expandOnClick: true,
                                    },
                                  },
                                }}
                                series={[
                                  listDashboard.data?.models?.clients?.enabled,
                                  listDashboard.data?.models?.clients?.disabled,
                                ]}
                                type="donut"
                                width="100%"
                              />
                            </Col>
                          </Row>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>

                  <Col sm={!district ? 4 : 8} className="card-mt-4">
                    <Card>
                      <Card.Header>
                        <h3 className="card-label">
                          Estados <small>Filtro por estados brasileiros</small>
                        </h3>
                      </Card.Header>
                      <Card.Body className="height-limit">
                        <Card.Text as="div">
                          <Row className="defaultCards">
                            <Col
                              sm={!district ? 12 : 6}
                              className="d-flex justify-content-center"
                            >
                              <MapBrazil
                                onChange={setDistrict}
                                bg="#8f8f8f"
                                fill="#752929"
                                colorStroke="white"
                                width="100%"
                              />
                            </Col>
                            <Col
                              sm={!district ? 0 : 6}
                              className="d-lg-flex flex-lg-column justify-content-lg-start align-items-lg-start justify-content-sm-center align-items-sm-center estados-dash"
                            >
                              {!district ? (
                                ''
                              ) : (
                                <>
                                  <h3>
                                    Dados do estado de{' '}
                                    <strong>
                                      {uf
                                        .filter(
                                          (filtro) => filtro.value == district,
                                        )
                                        .map((map) => map.name)}
                                    </strong>
                                  </h3>
                                  <Alert
                                    variant="secondary"
                                    className="text-center mt-3"
                                  >
                                    <strong>
                                      <Fontawesome classe="fas fa-users mr-3" />
                                      Quantidade de clientes:
                                    </strong>{' '}
                                    {listDashboard.data?.models?.clients?.uf
                                      .filter((filtro) => filtro.uf == district)
                                      .map((map) => map.client)}
                                  </Alert>
                                  <Alert
                                    variant="secondary"
                                    className="text-center"
                                  >
                                    <strong>
                                      <Fontawesome classe="fas fa-building mr-3" />
                                      Quantidade de unidades:
                                    </strong>{' '}
                                    {listDashboard.data?.models?.clients?.uf
                                      .filter((filtro) => filtro.uf == district)
                                      .map((map) => map.unit)}
                                  </Alert>
                                  <Alert
                                    variant="secondary"
                                    className="text-center"
                                  >
                                    <strong>
                                      <Fontawesome classe="fas fa-desktop mr-3" />
                                      Quantidade de equipamentos:
                                    </strong>{' '}
                                    {listDashboard.data?.models?.clients?.uf
                                      .filter((filtro) => filtro.uf == district)
                                      .map((map) => map.itens)}
                                  </Alert>
                                  <Alert
                                    variant="secondary"
                                    className="text-center"
                                  >
                                    <strong>
                                      <Fontawesome classe="fas fa-file-pdf mr-3" />
                                      Quantidade de contratos:
                                    </strong>{' '}
                                    {listDashboard.data?.models?.clients?.uf
                                      .filter((filtro) => filtro.uf == district)
                                      .map((map) => map.contracts)}
                                  </Alert>
                                </>
                              )}
                            </Col>
                          </Row>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>

                  <Col sm={4} className="card-mt-4">
                    <Card>
                      <Card.Header>
                        <h3 className="card-label">
                          Contratos <small>Quantidade de contratos</small>
                        </h3>
                      </Card.Header>
                      <Card.Body className="height-limit">
                        <Card.Text as="div">
                          <Row className="defaultCards">
                            <Col>
                              <div className="donut">
                                <Chart
                                  options={{
                                    labels: ['Ativos', 'Inativos'],
                                    colors: ['#752929', '#450808'],
                                    plotOptions: {
                                      pie: {
                                        expandOnClick: true,
                                      },
                                    },
                                  }}
                                  series={[
                                    listDashboard.data?.models?.contracts
                                      .ContractslEnabled,
                                    listDashboard.data?.models?.contracts
                                      .ContractslDisabled,
                                  ]}
                                  type="donut"
                                  width="100%"
                                />
                              </div>
                            </Col>
                          </Row>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>

                  <Col sm={4} className="card-mt-4">
                    <Card>
                      <Card.Header>
                        <h3 className="card-label">
                          Tipos de contratos <small>Contratos por tipos</small>
                        </h3>
                      </Card.Header>
                      <Card.Body className="height-limit">
                        <Card.Text as="div">
                          <div className="table-responsive defaultCards">
                            <table className="table table-contract-type-dash">
                              <tbody>
                                {listDashboard.data?.models?.contracts?.contractTypes.map(
                                  (map, key) => {
                                    return (
                                      <tr key={key}>
                                        <td width="50">
                                          <div className="icon-contract-type-dash">
                                            {map.type.split(' ').length > 2
                                              ? map.type
                                                  .split(' ')[0]
                                                  .substring(0, 1) +
                                                map.type
                                                  .split(' ')[2]
                                                  .substring(0, 1)
                                              : map.type.split(' ').length == 2
                                              ? map.type
                                                  .split(' ')[0]
                                                  .substring(0, 1) +
                                                map.type
                                                  .split(' ')[1]
                                                  .substring(0, 1)
                                              : map.type.substring(0, 1)}
                                          </div>
                                        </td>
                                        <td>
                                          <span>{map.type}</span>
                                        </td>
                                        <td>
                                          <span>{map.count} contratos</span>
                                        </td>
                                      </tr>
                                    );
                                  },
                                )}
                              </tbody>
                            </table>
                          </div>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>

                  <Col sm={4} className="card-mt-4">
                    <Card>
                      <Card.Header>
                        <h3 className="card-label">
                          Colaboradores{' '}
                          <small>Quantidade de colaboradores</small>
                        </h3>
                      </Card.Header>
                      <Card.Body className="height-limit">
                        <Card.Text as="div">
                          <Row className="defaultCards">
                            <Col>
                              <div className="donut">
                                <Chart
                                  options={{
                                    labels: ['Funcionários', 'Técnicos'],
                                    colors: ['#752929', '#450808'],
                                    plotOptions: {
                                      pie: {
                                        expandOnClick: true,
                                      },
                                    },
                                  }}
                                  series={[
                                    listDashboard.data?.models?.employees
                                      ?.employee,
                                    listDashboard.data?.models?.employees
                                      .technical,
                                  ]}
                                  type="donut"
                                  width="100%"
                                />
                              </div>
                            </Col>
                          </Row>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>

                  <Col sm={4} className="card-mt-4">
                    <Card>
                      <Card.Header>
                        <h3 className="card-label">
                          Equipamentos <small>de contratos</small>
                        </h3>
                      </Card.Header>
                      <Card.Body className="height-limit">
                        <Card.Text as="div">
                          <Row className="defaultCards">
                            <Col>
                              <div className="donut">
                                <Chart
                                  options={{
                                    labels: ['Não monitorável', 'Monitorável'],
                                    colors: ['#752929', '#450808'],
                                    plotOptions: {
                                      pie: {
                                        expandOnClick: true,
                                      },
                                    },
                                  }}
                                  series={[
                                    listDashboard.data?.models?.equipaments
                                      .noMonitoring,
                                    listDashboard.data?.models?.equipaments
                                      .monitoring,
                                  ]}
                                  type="donut"
                                  width="100%"
                                />
                              </div>
                            </Col>
                          </Row>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>

                  <Col sm={4} className="card-mt-4">
                    <Card>
                      <Card.Header>
                        <h3 className="card-label">
                          Top 5 Famílias <small>Famílias de Equipamentos</small>
                        </h3>
                      </Card.Header>
                      <Card.Body className="height-limit">
                        <Card.Text as="div">
                          <div className="table-responsive defaultCards">
                            <table className="table table-contract-type-dash">
                              <tbody>
                                {listDashboard.data?.models.equipaments.family.map(
                                  (map, key) => {
                                    return (
                                      <tr key={key}>
                                        <td width="50">
                                          <div className="icon-contract-type-dash">
                                            {map.family.split(' ').length > 2
                                              ? map.family
                                                  .split(' ')[0]
                                                  .substring(0, 1) +
                                                map.family
                                                  .split(' ')[2]
                                                  .substring(0, 1)
                                              : map.family.split(' ').length ==
                                                2
                                              ? map.family
                                                  .split(' ')[0]
                                                  .substring(0, 1) +
                                                map.family
                                                  .split(' ')[1]
                                                  .substring(0, 1)
                                              : map.family.substring(0, 1)}
                                          </div>
                                        </td>
                                        <td>
                                          <span>{map.family}</span>
                                        </td>
                                        <td>
                                          <span>{map.count} famílias</span>
                                        </td>
                                      </tr>
                                    );
                                  },
                                )}
                              </tbody>
                            </table>
                          </div>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>

                  <Col sm={4} className="card-mt-4">
                    <Card>
                      <Card.Header>
                        <h3 className="card-label">
                          Top 5 Marcas <small>Marcas de Equipamentos</small>
                        </h3>
                      </Card.Header>
                      <Card.Body className="height-limit">
                        <Card.Text as="div">
                          <div className="table-responsive defaultCards">
                            <table className="table table-contract-type-dash">
                              <tbody>
                                {listDashboard.data?.models?.equipaments?.brand.map(
                                  (map, key) => {
                                    return (
                                      <tr key={key}>
                                        <td width="50">
                                          <div className="icon-contract-type-dash">
                                            {map.brand.split(' ').length > 2
                                              ? map.brand
                                                  .split(' ')[0]
                                                  .substring(0, 1) +
                                                map.brand
                                                  .split(' ')[2]
                                                  .substring(0, 1)
                                              : map.brand.split(' ').length == 2
                                              ? map.brand
                                                  .split(' ')[0]
                                                  .substring(0, 1) +
                                                map.brand
                                                  .split(' ')[1]
                                                  .substring(0, 1)
                                              : map.brand.substring(0, 1)}
                                          </div>
                                        </td>
                                        <td>
                                          <span>{map.brand}</span>
                                        </td>
                                        <td>
                                          <span>{map.count} marcas</span>
                                        </td>
                                      </tr>
                                    );
                                  },
                                )}
                              </tbody>
                            </table>
                          </div>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col sm={4} className="card-mt-4">
                    <Card>
                      <Card.Header>
                        <h3 className="card-label">
                          Status <small>Resumo de serviços</small>
                        </h3>
                      </Card.Header>
                      <Card.Body className="height-limit">
                        <Card.Text as="div">
                          <div className="table-responsive">
                            <table className="table table-contract-type-dash">
                              <tbody>
                                <tr>
                                  <td width="50">
                                    {!listMonitoring.data?.models?.whatsapp ? (
                                      <div className="icon-whatsapp-dash-negative">
                                        <i
                                          class="fas fa-exclamation-circle"
                                          style={{ fontSize: '20px' }}
                                        ></i>
                                      </div>
                                    ) : (
                                      <div className="icon-whatsapp-dash">
                                        <i
                                          class="fas fa-exclamation-circle"
                                          style={{ fontSize: '20px' }}
                                        ></i>
                                      </div>
                                    )}
                                  </td>
                                  <td>
                                    <span>
                                      Serviço
                                      <strong className="mr-2 ml-2">
                                        {' '}
                                        WP{' '}
                                      </strong>
                                      {!listMonitoring.data?.models?.whatsapp
                                        ? 'com problemas'
                                        : 'funcionando'}
                                    </span>
                                  </td>
                                </tr>
                                <tr>
                                  <td width="50">
                                    <div className="icon-whatsapp-dash">
                                      <i
                                        class="fab fa-whatsapp"
                                        style={{ fontSize: '20px' }}
                                      ></i>
                                    </div>
                                  </td>
                                  <td>
                                    <span>
                                      {listMonitoring.data?.models?.collector
                                        .whatsapp == 1
                                        ? listMonitoring.data?.models?.collector
                                            .whatsapp + ' pessoa'
                                        : listMonitoring.data?.models?.collector
                                            .whatsapp + ' pessoas'}{' '}
                                      recebendo mensagens
                                    </span>
                                  </td>
                                </tr>
                                <tr>
                                  <td width="50">
                                    <div className="icon-whatsapp-dash">
                                      <i
                                        class="far fa-envelope"
                                        style={{ fontSize: '19px' }}
                                      ></i>
                                    </div>
                                  </td>
                                  <td>
                                    <span>
                                      {listMonitoring.data?.models?.collector
                                        .email == 1
                                        ? listMonitoring.data?.models?.collector
                                            .email + ' pessoa'
                                        : listMonitoring.data?.models?.collector
                                            .email + ' pessoas'}{' '}
                                      pessoas recebendo e-mails
                                    </span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col sm={8} className="card-mt-4">
                    <Card>
                      <Card.Header>
                        <h3 className="card-label">
                          Dispositivos <small>Resumo dos dispositivos</small>
                        </h3>
                      </Card.Header>
                      <Card.Body className="height-limit">
                        <Card.Text as="div">
                          <Row>
                            <Col>
                              <Alert variant="secondary">
                                <div className="donut">
                                  <Chart
                                    options={{
                                      colors: ['#1fc6be', '#5f4082'],
                                      xaxis: {
                                        categories: [
                                          'Funcionando',
                                          'Com problemas',
                                        ],
                                      },
                                    }}
                                    series={[
                                      {
                                        name: 'Equipamento',
                                        data: [
                                          listMonitoring.data?.models?.collector
                                            .equipmentCollectorEnabled,
                                          listMonitoring.data?.models?.collector
                                            .equipmentCollectorDisabled,
                                        ],
                                      },
                                      {
                                        name: 'Ambiente',
                                        data: [
                                          listMonitoring.data?.models?.collector
                                            .environmentCollectorEnabled,
                                          listMonitoring.data?.models?.collector
                                            .environmentCollectorDisabled,
                                        ],
                                      },
                                    ]}
                                    type="bar"
                                    height="250"
                                    width="100%"
                                    stacked="true"
                                    stackType="100%"
                                  />
                                </div>
                              </Alert>
                            </Col>
                            <Col>
                              <Alert variant="danger">
                                <Fontawesome
                                  classe="far fa-file-alt mr-3"
                                  fontSize="19px"
                                  color="#585858"
                                />{' '}
                                Resumo
                              </Alert>
                              <Row>
                                <Col className="mb-1 d-flex align-items-center">
                                  <i
                                    class="fas fa-mobile-alt mr-3"
                                    style={{
                                      fontSize: '19px',
                                      color: '#30beb8',
                                    }}
                                  ></i>
                                  <span className="count-resumo">
                                    {parseInt(
                                      listMonitoring.data?.models?.collector
                                        .equipmentCollectorEnabled,
                                    ) +
                                      parseInt(
                                        listMonitoring.data?.models?.collector
                                          .equipmentCollectorDisabled,
                                      )}
                                  </span>{' '}
                                  Total Equipamento
                                </Col>
                              </Row>
                              <Row>
                                <Col className="mb-1 d-flex align-items-center">
                                  <i
                                    class="fas fa-mobile-alt mr-3"
                                    style={{
                                      fontSize: '19px',
                                      color: '#735891',
                                    }}
                                  ></i>
                                  <span className="count-resumo">
                                    {parseInt(
                                      listMonitoring.data?.models?.collector
                                        .environmentCollectorEnabled,
                                    ) +
                                      parseInt(
                                        listMonitoring.data?.models?.collector
                                          .environmentCollectorDisabled,
                                      )}
                                  </span>{' '}
                                  Total Ambiente
                                </Col>
                              </Row>
                              <Row>
                                <Col className="mb-1 d-flex align-items-center">
                                  <i
                                    class="fas fa-mobile-alt mr-3"
                                    style={{ fontSize: '19px', color: '#000' }}
                                  ></i>
                                  <span className="count-resumo">
                                    {parseInt(
                                      listMonitoring.data?.models?.collector
                                        .equipmentCollectorEnabled,
                                    ) +
                                      parseInt(
                                        listMonitoring.data?.models?.collector
                                          .equipmentCollectorDisabled,
                                      ) +
                                      parseInt(
                                        listMonitoring.data?.models?.collector
                                          .environmentCollectorEnabled,
                                      ) +
                                      parseInt(
                                        listMonitoring.data?.models?.collector
                                          .environmentCollectorDisabled,
                                      )}
                                  </span>{' '}
                                  Total de dispositivos
                                </Col>
                              </Row>

                              <Row>
                                <Col sm={12} className="mt-3">
                                  <Alert variant="danger">
                                    <Fontawesome
                                      classe="fas fa-temperature-low mr-3"
                                      fontSize="19px"
                                      color="#585858"
                                    />{' '}
                                    Grandezas utilizadas
                                  </Alert>
                                </Col>
                                <Col className="mb-1 d-flex align-items-center">
                                  {listMonitoring.data?.models?.collector?.measures
                                    .map((map) => {
                                      return map;
                                    })
                                    .join(', ')}
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>

                <Row>
                  <Col sm={4} className="card-mt-4">
                    <Card>
                      <Card.Header>
                        <h3 className="card-label">
                          OS's de {converteMes(month)}
                          <small> Resumo de {converteMes(month)}</small>
                        </h3>
                      </Card.Header>
                      <Card.Body className="height-limit">
                        <Card.Text as="div">
                          <Row className="defaultCards">
                            <Col>
                              <Alert variant="secondary">
                                <div className="donut">
                                  <Chart
                                    options={{
                                      labels:
                                        listOs.data?.models?.os
                                          ?.preventiveMonth == 0 &&
                                        listOs.data?.models?.os
                                          ?.correctiveMonth == 0 &&
                                        listOs.data?.models?.os
                                          ?.calibrationMonth == 0
                                          ? ['Sem dados']
                                          : [
                                              'Preventiva',
                                              'Corretiva',
                                              'Calibração',
                                            ],
                                      colors:
                                        listOs.data?.models?.os
                                          ?.preventiveMonth == 0 &&
                                        listOs.data?.models?.os
                                          ?.correctiveMonth == 0 &&
                                        listOs.data?.models?.os
                                          ?.calibrationMonth == 0
                                          ? ['#cecece']
                                          : ['#752929', '#450808', '#000'],
                                    }}
                                    series={
                                      listOs.data?.models?.os
                                        ?.preventiveMonth == 0 &&
                                      listOs.data?.models?.os
                                        ?.correctiveMonth == 0 &&
                                      listOs.data?.models?.os
                                        ?.calibrationMonth == 0
                                        ? [100]
                                        : [
                                            listOs.data?.models?.os
                                              .preventiveMonth,
                                            listOs.data?.models?.os
                                              .correctiveMonth,
                                            listOs.data?.models?.os
                                              .calibrationMonth,
                                          ]
                                    }
                                    type="donut"
                                    width="93%"
                                  />
                                </div>
                              </Alert>
                            </Col>
                          </Row>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col sm={4} className="card-mt-4">
                    <Card>
                      <Card.Header>
                        <h3 className="card-label">
                          Preventivas de {converteMes(month)}{' '}
                          <small>Resumo de {converteMes(month)}</small>
                        </h3>
                      </Card.Header>
                      <Card.Body className="height-limit">
                        <Card.Text as="div">
                          <Row className="defaultCards">
                            <Col>
                              <Alert variant="secondary"> */}
                {/* <div className="donut">
                                <Chart
                                  options={{
                                    labels:
                                      listOs.data?.models.os.preventiveMonth ==
                                      0
                                        ? ['Sem dados']
                                        : [
                                            'Não processado',
                                            'Em análise',
                                            'Pendente',
                                            'Em andamento',
                                            'Concluído',
                                            'Fechado',
                                            'Cancelado',
                                          ],
                                    colors:
                                      listOs.data?.models.os.preventiveMonth ==
                                      0
                                        ? ['#cecece']
                                        : [
                                            '#752929',
                                            '#450808',
                                            '#000',
                                            '#000',
                                            '#000',
                                            '#000',
                                            '#000',
                                          ],
                                  }}
                                  series={
                                    listOs.data?.models.os.correctiveMonth == 0
                                      ? [100]
                                      : [
                                          listOs.data?.models.os.OsMonth
                                            .preventive.naoprocessado,
                                          listOs.data?.models.os.OsMonth
                                            .preventive.emanalise,
                                          listOs.data?.models.os.OsMonth
                                            .preventive.pendente,
                                          listOs.data?.models.os.OsMonth
                                            .preventive.emandamento,
                                          listOs.data?.models.os.OsMonth
                                            .preventive.concluido,
                                          listOs.data?.models.os.OsMonth
                                            .preventive.fechado,
                                          listOs.data?.models.os.OsMonth
                                            .preventive.cancelado,
                                        ]
                                  }
                                  type="donut"
                                  width="93%"
                                />
                              </div> */}
                {/* <GaugeChart
                                  id="gauge-chart5"
                                  nrOfLevels={420}
                                  arcsLength={[0.3, 0.3, 0.3]}
                                  colors={['#5BE12C', '#F5CD19', '#EA4228']}
                                  percent={
                                    (parseFloat(
                                      listOs.data?.models?.os?.OsMonth
                                        ?.preventive.concluido,
                                    ) *
                                      parseFloat(
                                        listOs.data?.models?.os?.OsMonth
                                          ?.preventive.naoprocessado,
                                      )) /
                                    100
                                  }
                                  textColor="#000"
                                  style={{
                                    height: '250px',
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                />
                              </Alert>
                            </Col>
                          </Row>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col sm={4} className="card-mt-4">
                    <Card>
                      <Card.Header>
                        <h3 className="card-label">
                          Corretivas de {converteMes(month)}{' '}
                          <small>Resumo de {converteMes(month)}</small>
                        </h3>
                      </Card.Header>
                      <Card.Body className="height-limit">
                        <Card.Text as="div">
                          <Row className="defaultCards">
                            <Col>
                              <Alert variant="secondary">
                                <div className="donut"> */}
                {/* <Chart
                                  options={{
                                    labels:
                                      listOs.data?.models.os.correctiveMonth ==
                                      0
                                        ? ['Sem dados']
                                        : [
                                            'Não processado',
                                            'Em análise',
                                            'Pendente',
                                            'Em andamento',
                                            'Concluído',
                                            'Fechado',
                                            'Cancelado',
                                          ],
                                    colors:
                                      listOs.data?.models.os.correctiveMonth ==
                                      0
                                        ? ['#cecece']
                                        : [
                                            '#752929',
                                            '#450808',
                                            '#000',
                                            '#000',
                                            '#000',
                                            '#000',
                                            '#000',
                                          ],
                                  }}
                                  series={
                                    listOs.data?.models.os.correctiveMonth == 0
                                      ? [100]
                                      : [
                                          listOs.data?.models.os.OsMonth
                                            .corretive.naoprocessado,
                                          listOs.data?.models.os.OsMonth
                                            .corretive.emanalise,
                                          listOs.data?.models.os.OsMonth
                                            .corretive.pendente,
                                          listOs.data?.models.os.OsMonth
                                            .corretive.emandamento,
                                          listOs.data?.models.os.OsMonth
                                            .corretive.concluido,
                                          listOs.data?.models.os.OsMonth
                                            .corretive.fechado,
                                          listOs.data?.models.os.OsMonth
                                            .corretive.cancelado,
                                        ]
                                  }
                                  type="donut"
                                  width="93%"
                                /> */}
                {/* <GaugeChart
                                    id="gauge-chart4"
                                    nrOfLevels={420}
                                    arcsLength={[0.3, 0.3, 0.3]}
                                    colors={['#5BE12C', '#F5CD19', '#EA4228']}
                                    percent={
                                      (parseFloat(
                                        listOs.data?.models?.os?.OsMonth
                                          ?.corretive.concluido,
                                      ) *
                                        parseFloat(
                                          listOs.data?.models?.os?.OsMonth
                                            ?.corretive.naoprocessado,
                                        )) /
                                      100
                                    }
                                    textColor="#000"
                                    style={{
                                      height: '250px',
                                      display: 'flex',
                                      alignItems: 'center',
                                    }}
                                  />
                                </div>
                              </Alert>
                            </Col>
                          </Row>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col sm={4} className="card-mt-4">
                    <Card>
                      <Card.Header>
                        <h3 className="card-label">
                          Calibrações de {converteMes(month)}{' '}
                          <small>Resumo de {converteMes(month)}</small>
                        </h3>
                      </Card.Header>
                      <Card.Body className="height-limit">
                        <Card.Text as="div">
                          <Row className="defaultCards">
                            <Col>
                              <Alert variant="secondary">
                                <div className="donut"> */}
                {/* <Chart
                                  options={{
                                    labels:
                                      listOs.data?.models.os.calibrationMonth ==
                                      0
                                        ? ['Sem dados']
                                        : [
                                            'Não processado',
                                            'Em análise',
                                            'Pendente',
                                            'Em andamento',
                                            'Concluído',
                                            'Fechado',
                                            'Cancelado',
                                          ],
                                    colors:
                                      listOs.data?.models.os.calibrationMonth ==
                                      0
                                        ? ['#000']
                                        : [
                                            '#752929',
                                            '#450808',
                                            '#000',
                                            '#000',
                                            '#000',
                                            '#000',
                                            '#000',
                                          ],
                                  }}
                                  series={
                                    listOs.data?.models.os.correctiveMonth == 0
                                      ? [100]
                                      : [
                                          listOs.data?.models.os.OsMonth
                                            .calibration.naoprocessado,
                                          listOs.data?.models.os.OsMonth
                                            .calibration.emanalise,
                                          listOs.data?.models.os.OsMonth
                                            .calibration.pendente,
                                          listOs.data?.models.os.OsMonth
                                            .calibration.emandamento,
                                          listOs.data?.models.os.OsMonth
                                            .calibration.concluido,
                                          listOs.data?.models.os.OsMonth
                                            .calibration.fechado,
                                          listOs.data?.models.os.OsMonth
                                            .calibration.cancelado,
                                        ]
                                  }
                                  type="donut"
                                  width="100%"
                                  height="340"
                                /> */}
                {/* <GaugeChart
                                    id="gauge-chart3"
                                    nrOfLevels={420}
                                    arcsLength={[0.3, 0.3, 0.3]}
                                    colors={['#5BE12C', '#F5CD19', '#EA4228']}
                                    percent={
                                      (parseFloat(
                                        listOs.data?.models?.os?.OsMonth
                                          ?.calibration.concluido,
                                      ) *
                                        parseFloat(
                                          listOs.data?.models?.os?.OsMonth
                                            .calibration.naoprocessado,
                                        )) /
                                      100
                                    }
                                    textColor="#000"
                                    style={{
                                      height: '250px',
                                      display: 'flex',
                                      alignItems: 'center',
                                    }}
                                  />
                                </div>
                              </Alert>
                            </Col>
                          </Row>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row> */}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
