import React, { useState, useEffect } from 'react';
import { Row, Col, Modal, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../customer.css';
import { uf } from '../../data/uf';
import api from '../../../services/api';
import Fontawesome from '../../fontawesome/fontawesome';
import { Prefix } from '../../../services/prefix';
import Loading from '../../loader/loading';
import Swal from 'sweetalert2';
import { mutate as GlobalMutate } from 'swr';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import Schema from './schema';
import {
  maskCEP,
  maskTel,
  maskTelFixo,
  maskCpfCnpj,
} from '../../../services/mask';
import { cpf, cnpj } from 'cpf-cnpj-validator';
import { useFetch } from '../../hooks/useFetch';
import axios from 'axios';
import Select from 'react-select';
import { AsyncPaginate } from 'react-select-async-paginate';
import { loadOptions } from '../../selectAsync/loadOptions';
import { colourStyles } from '../../selectAsync/colourStyles';
import { ModalContainer } from '../../../style/stylesModals';
import { CustomButton, TextButton } from '../../../style/stylesButton';

const IndexEditCustomer = ({
  id,
  page,
  rowsPerPage,
  search,
  orderBy,
  order,
}) => {
  const [loader, setLoader] = useState();
  const [show, setShow] = useState(false);
  const [messageDocument, setMessageDocument] = useState(null);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };
  const [valueSelect, setValueSelect] = useState([]);
  const [nameCity, setNameCity] = useState('');
  const [initialValue, setInitialValue] = useState({});

  let pageExact = page + parseInt(1);
  let valueSearch = !search ? '' : search;

  const clientsList = useFetch(
    Prefix.replace(/[\\"]/g, '') + '/customers/' + id,
  );
  const listCity = useFetch(
    Prefix.replace(/[\\"]/g, '') + '/cities?search=' + nameCity,
  );
  const [registerData, setRegisterData] = useState({});

  useEffect(() => {
    if (clientsList?.data) {
      setInitialValue({
        name: clientsList?.data?.model?.name,
        corporate_name: clientsList?.data?.model?.corporate_name,
        fantasy_name: clientsList?.data?.model?.fantasy_name,
        document: maskCpfCnpj(clientsList?.data?.model?.document),
        document_type: clientsList.data?.model?.document_type,
        address: clientsList.data?.model?.address,
        number: clientsList.data?.model?.number,
        neighborhood: clientsList.data?.model?.neighborhood,
        zipcode: maskCEP(clientsList.data?.model?.zipcode),
        uf: clientsList.data?.model?.city?.uf,
        id_city: clientsList.data?.model?.city?.id,
        phone1: maskTel(clientsList.data?.model?.phone1),
        phone2: !clientsList.data?.model?.phone2
          ? ''
          : maskTelFixo(clientsList.data?.model?.phone2),
        active: clientsList.data?.model?.active,
      });
    }
  }, [clientsList.data]);

  const HandleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setRegisterData({ ...registerData, [name]: value });
  };

  const handleZipCode = (e) => {
    let value = e.target.value;

    let zip = e.target.value.replace('.', '').replace('-', '');
    axios.get(`https://viacep.com.br/ws/${zip}/json/`).then((r) => {
      setNameCity(r.data.localidade);

      if (
        valueSelect.filter((fill) => fill.nameField == 'id_city').length == 0
      ) {
        setValueSelect((prev) => {
          return [
            ...prev,
            {
              value: listCity.data?.models?.data[0]?.id,
              label: r.data.localidade,
              nameField: 'id_city',
            },
          ];
        });
      } else {
        const valuesForwardCity = {
          value: listCity.data?.models?.data[0]?.id,
          label: r.data.localidade,
        };
        HandleSelect(valuesForwardCity, 'id_city');
      }

      if (valueSelect.filter((fill) => fill.nameField == 'uf').length == 0) {
        setValueSelect((prev) => {
          return [
            ...prev,
            {
              value: r.data.uf,
              label: uf.filter((fill) => fill.value == r.data.uf)[0]?.name,
              nameField: 'uf',
            },
          ];
        });
      } else {
        const valuesForwardUf = {
          value: r.data.uf,
          label: uf.filter((fill) => fill.value == r.data.uf)[0]?.name,
        };
        HandleSelect(valuesForwardUf, 'uf');
      }

      clientsList.mutate();
      api
        .get(
          Prefix.replace(/[\\"]/g, '') + '/cities?search=' + r.data.localidade,
        )
        .then((response) => {
          setRegisterData({
            ...registerData,
            address: r.data.logradouro,
            neighborhood: r.data.bairro,
            uf: r.data.uf,
            id_city: response.data?.models?.data[0]?.id,
            zipcode: value,
          });
          setInitialValue({
            ...initialValue,
            address: r.data.logradouro,
            neighborhood: r.data.bairro,
            uf: r.data.uf,
            id_city: response.data?.models?.data[0]?.id,
            zipcode: value,
          });
        });
    });
  };

  const validateDocument = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setRegisterData({ ...registerData, [name]: value });
    let document = value
      .replace('/', '')
      .replace('-', '')
      .replace('.', '')
      .replace('.', '');
    if (document.length <= 11) {
      setRegisterData({ ...registerData, document_type: 'cpf' });
      setMessageDocument(cpf.isValid(document));
    } else {
      setRegisterData({ ...registerData, document_type: 'cnpj' });
      setMessageDocument(cnpj.isValid(document));
    }
  };

  const HandleSelect = (e, name) => {
    if (valueSelect.filter((fill) => fill.nameField == name).length > 0) {
      valueSelect
        .filter((fill) => fill.nameField == name)
        .map((map) => {
          let valueIndice = valueSelect.indexOf(map);
          valueSelect.splice(valueIndice);
        });

      setValueSelect((prev) => {
        return [...prev, { value: e.value, label: e.label, nameField: name }];
      });
      setRegisterData({ ...registerData, [name]: e.value });
    } else {
      setValueSelect((prev) => {
        return [...prev, { value: e.value, label: e.label, nameField: name }];
      });
      setRegisterData({ ...registerData, [name]: e.value });
    }
  };

  /*Atualizar dados do cliente*/
  function handleRegister() {
    setLoader(true);
    api
      .put(Prefix.replace(/[\\"]/g, '') + '/customers/' + id, registerData)
      .then((response) => {
        Swal.fire({
          icon: 'success',
          title: 'Uow!',
          text: response.data.message,
          confirmButtonText: 'Fechar',
        });
        setLoader(false);
        setRegisterData({});
        setShow(false);
        clientsList.mutate();
        GlobalMutate(
          Prefix.replace(/[\\"]/g, '') +
            '/customers?per_page=' +
            rowsPerPage +
            '&page=' +
            pageExact +
            '&search=' +
            valueSearch +
            '&order_by=' +
            orderBy +
            '&order=' +
            order,
        );
      })
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: err.response.data.message,
          confirmButtonText: 'Tentar novamente',
        });
        setLoader();
      });
  }

  return (
    <>
      <Link
        to="#"
        variant="success"
        onClick={handleShow}
        className="link_color_datatable"
      >
        <Fontawesome classe="fas fa-pencil-alt" />
      </Link>

      <ModalContainer
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h3>Atualizar Cliente</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loader && <Loading />}
          <Formik
            initialValues={initialValue}
            validationSchema={Schema}
            onSubmit={handleRegister}
            enableReinitialize
          >
            {({ values }) => (
              <Form>
                <Row>
                  <Col>
                    <div className="formStep">
                      <label className="important">Nome completo</label>
                      <Field
                        type="text"
                        placeholder="Digite um nome"
                        name="name"
                        onBlur={HandleChange}
                      />
                      <ErrorMessage
                        name="name"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="formStep">
                      <label>Razão Social</label>
                      <Field
                        type="text"
                        placeholder="Digite uma razão social"
                        name="corporate_name"
                        onBlur={HandleChange}
                      />
                      <ErrorMessage
                        name="corporate_name"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="formStep">
                      <label>Nome Fantasia</label>
                      <Field
                        type="text"
                        placeholder="Digite um nome fantasia"
                        name="fantasy_name"
                        onBlur={HandleChange}
                      />
                      <ErrorMessage
                        name="fantasy_name"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div className="formStep">
                      <div className="labelForm">
                        <label className="important">CPF/CNPJ</label>
                      </div>
                      <Field
                        placeholder={'Digite um CPF ou CNPJ'}
                        name="document"
                        onBlur={validateDocument}
                        onKeyPress={maskCpfCnpj}
                      />
                      {registerData.document_type == 'cpf' &&
                      messageDocument === false ? (
                        <span className="errorMessages">CPF inválido</span>
                      ) : registerData.document_type == 'cnpj' &&
                        messageDocument === false ? (
                        <span className="errorMessages">CNPJ inválido</span>
                      ) : (
                        <ErrorMessage
                          name="document"
                          component="span"
                          className="errorMessages"
                        />
                      )}
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div className="formStep">
                      <label className="important">CEP</label>
                      <Field
                        type="text"
                        placeholder="Digite um CEP"
                        name="zipcode"
                        onKeyPress={maskCEP}
                        onBlur={handleZipCode}
                      />
                      <ErrorMessage
                        name="zipcode"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                  <Col>
                    <div className="formStep">
                      <label className="important">Estado</label>
                      <Select
                        value={
                          valueSelect.length == 0
                            ? uf
                                .filter((fill) => fill.value == values.uf)
                                .map((state) => {
                                  return {
                                    value: state.value,
                                    label: state.name,
                                  };
                                })
                            : valueSelect
                                .filter((fill) => fill.nameField == 'uf')
                                .map((map) => {
                                  return {
                                    value: map.value,
                                    label: map.label,
                                  };
                                })
                        }
                        styles={colourStyles}
                        onChange={(e) => HandleSelect(e, 'uf')}
                        placeholder="Selecione..."
                        options={uf.map((state) => {
                          return { value: state.value, label: state.name };
                        })}
                      />
                      <ErrorMessage
                        name="uf"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div className="formStep">
                      <label className="important">Cidade</label>
                      <AsyncPaginate
                        value={
                          valueSelect.length == 0
                            ? {
                                value: clientsList.data?.model.city.id,
                                label: clientsList.data?.model.city.name,
                              }
                            : valueSelect
                                .filter((fill) => fill.nameField == 'id_city')
                                .map((map) => {
                                  return {
                                    value: map.value,
                                    label: map.label,
                                  };
                                })
                        }
                        loadOptions={loadOptions}
                        onChange={(e) => HandleSelect(e, 'id_city')} //values and name of select paginate
                        placeholder="Selecione..."
                        cacheUniqs={[values.uf]} //Set your param that is going to change when you select another field. Just use this option when you are setting params and need to refresh cache.
                        styles={colourStyles}
                        additional={{
                          page: 1,
                          endpoint: '/cities',
                          params: `uf=${values.uf}`, //Separete with & (and commercial)
                        }}
                      />
                      <ErrorMessage
                        name="id_city"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col sm={9}>
                    <div className="formStep">
                      <label className="important">Endereço</label>
                      <Field
                        type="text"
                        placeholder="Digite um endereço"
                        name="address"
                        onBlur={HandleChange}
                      />
                      <ErrorMessage
                        name="address"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                  <Col sm={3}>
                    <div className="formStep">
                      <label className="important">Nº</label>
                      <Field type="text" name="number" onBlur={HandleChange} />
                      <ErrorMessage
                        name="number"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div className="formStep">
                      <label className="important">Bairro</label>
                      <Field
                        type="text"
                        placeholder="Digite um bairro"
                        name="neighborhood"
                        onBlur={HandleChange}
                      />
                      <ErrorMessage
                        name="neighborhood"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div className="formStep">
                      <label className="important">Celular</label>
                      <Field
                        type="text"
                        placeholder="Digite um numero fixo de telefone"
                        name="phone1"
                        onBlur={HandleChange}
                        onKeyPress={maskTel}
                      />
                      <ErrorMessage
                        name="phone1"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="formStep">
                      <label>Telefone</label>
                      <Field
                        type="text"
                        placeholder="Digite um número de celular"
                        name="phone2"
                        onBlur={HandleChange}
                        onKeyPress={maskTelFixo}
                      />
                      <ErrorMessage
                        name="phone2"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className="d-flex justify-content-center">
                    <CustomButton type="submit">
                      <TextButton> Atualizar </TextButton>
                    </CustomButton>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </ModalContainer>
    </>
  );
};
export default IndexEditCustomer;
