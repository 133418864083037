import React from "react";
import api from "../../../services/api";
import { Prefix } from "../../../services/prefix";
import { mutate as globalMutate } from "swr";

export default function UpdateStatus({
  id,
  page,
  rowsPage,
  activeDefault,
  search,
  orderBy,
  order,
}) {
  let pageExact = page + parseInt(1);

  function HandleStatus() {
    api
      .put(Prefix.replace(/[\\"]/g, "") + "/users/" + id, {
        active: Boolean(activeDefault === true || activeDefault == 1 ? 0 : 1),
      })
      .then(() => {
        globalMutate(
          Prefix.replace(/[\\"]/g, "") +
            "/users?per_page=" +
            rowsPage +
            "&page=" +
            pageExact +
            "&search=" +
            search +
            "&order_by=" +
            orderBy +
            "&order=" +
            order
        );
      });
  }

  return (
    <>
      <label class="switch">
        <input
          type="checkbox"
          id={id + "users"}
          onChange={HandleStatus}
          checked={activeDefault == true || activeDefault == 1 ? true : false}
        />
        <span class="slider round"></span>
      </label>
    </>
  );
}
