import React, { useContext, useState, useEffect } from 'react';
import { Button, BackButton } from './linkTelegramStyle';
import { Row, Col } from 'react-bootstrap';
import Fontawesome from '../../../fontawesome/fontawesome';
import { Context } from '../../../../services/context';
import { AsyncPaginate } from 'react-select-async-paginate';
import { loadOptions } from '../../../selectAsync/loadOptions';
import { colourStyles } from '../../../selectAsync/colourStyles';
import Swal from 'sweetalert2';
import api from '../../../../services/api';
import { Prefix } from '../../../../services/prefix';
import MUIDataTable from 'mui-datatables';
import { columns } from './columns';
import { useFetch } from '../../../hooks/useFetch';
import CustomSearchRender from '../../../customSearchRender/CustomSearchRender';
import CustomFooter from '../../../CustomFooter/CustomFooter';
import { Link } from 'react-router-dom';

const LinkTelegram = ({ chatId, idCustomer, idUser }) => {
  const { employeeView, setEmployeeView } = useContext(Context);
  const [valueSelect, setValueSelect] = useState([]);
  const [perPage, setPerPage] = useState(15);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState('');
  const [orderBy, setOrderBy] = useState('id');
  const [order, setOrder] = useState('desc');
  const [formData, setFormData] = useState({
    plataform: 'telegram',
    identification: employeeView.chatId,
    id_user: employeeView.idUser,
    active: 1,
    monitoring: 1,
  });

  const pageExact = parseInt(page) + 1;

  const itemsList = useFetch(
    Prefix.replace(/[\\"]/g, '') +
      '/contract-notifications?id_user=' +
      employeeView.idUser +
      '&per_page=' +
      perPage +
      '&page=' +
      pageExact +
      '&search=' +
      search +
      '&order_by=' +
      orderBy +
      '&order=' +
      order,
  );

  const verifyLink = () => {
    if (itemsList.data && itemsList.data?.models?.data.length > 0) {
      setEmployeeView({
        ...employeeView,
        chatId: itemsList.data?.models?.data[0].identification,
      });
    }
  };

  useEffect(() => {
    verifyLink();
  }, [itemsList.data]);

  const handleShow = () => {
    setEmployeeView({
      ...employeeView,
      linkTelegram: true,
      telegramView: false,
      employees: false,
      chatId: chatId,
      idUser: chatId ? employeeView.idUser : idUser,
      idCustomer: chatId ? employeeView.idCustomer : idCustomer,
    });
  };

  const HandleNewLink = () => {
    setEmployeeView({
      ...employeeView,
      linkTelegram: false,
      telegramView: true,
      chatId: chatId,
      idCustomer: employeeView.idCustomer,
    });
  };

  const handleClose = () => {
    setEmployeeView({
      ...employeeView,
      linkTelegram: false,
      employees: true,
    });
  };

  const HandleSelect = (e, name) => {
    if (valueSelect.filter((fill) => fill.nameField == name).length > 0) {
      valueSelect
        .filter((fill) => fill.nameField == name)
        .map((map) => {
          let valueIndice = valueSelect.indexOf(map);
          valueSelect.splice(valueIndice);
        });

      setValueSelect((prev) => {
        return [...prev, { value: e.value, label: e.label, nameField: name }];
      });
      setFormData({ ...formData, [name]: e.value });
    } else {
      setValueSelect((prev) => {
        return [...prev, { value: e.value, label: e.label, nameField: name }];
      });
      setFormData({ ...formData, [name]: e.value });
    }
  };

  const HandleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value.trim(),
    });
  };

  const HandleRegister = () => {
    api
      .post(Prefix.replace(/[\"]/g, '') + '/contract-notifications', formData)
      .then((response) => {
        Swal.fire({
          icon: 'success',
          title: 'Uow!',
          text: response.data.message,
          confirmButtonText: 'Fechar',
        });
        itemsList.mutate();
        // GlobalMutate(
        //   Prefix.replace(/[\\"]/g, '') +
        //     '/contract-item-views?per_page=' +
        //     perPage +
        //     '&page=' +
        //     pageExact +
        //     '&search=' +
        //     search,
        // );
      })
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: err.response.data.message,
          confirmButtonText: 'Tentar novamente',
        });
      });
  };

  function HandleDelete(e) {
    let idArr = [];

    e.map((del) => {
      idArr.push(
        itemsList.data?.models.data.filter(
          (filtro, index) => index == del.index,
        )[0].id,
      );
    });

    idArr.map(
      (
        idDel, //Faz exclusão com map um a um para evitar bugs
      ) =>
        api
          .delete(
            Prefix.replace(/[\\"]/g, '') + '/contract-notifications/' + idDel,
          )
          .then((response) => {
            Swal.fire({
              icon: 'success',
              title: 'Uow!',
              text: response.data.message,
              confirmButtonText: 'Fechar',
            });
            itemsList.mutate();
          })
          .catch((err) => {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: err.response.data.message,
              confirmButtonText: 'Tentar novamente',
            });
          }),
    );
  }

  const options = {
    filterType: 'dropdown',
    draggableColumns: {
      enabled: true,
    },
    filter: false,
    serverSide: true,
    sortOrder: {
      name: orderBy,
      direction: order,
    },
    onTableChange: (action, tableState) => {
      setOrderBy(
        !tableState.sortOrder.name ? orderBy : tableState.sortOrder.name,
      );
      setOrder(
        !tableState.sortOrder.direction
          ? order
          : tableState.sortOrder.direction,
      );
      setPage(tableState.page);
      setPerPage(tableState.rowsPerPage);
    },
    onSearchChange: (searchText) => {
      setSearch(!searchText ? '' : searchText); //faz condição para string vazia exibir vazio no lugar de null ou undefined
    },
    customSearchRender: (searchText, handleSearch, hideSearch, options) => {
      return (
        <CustomSearchRender
          placeholderSearch="Pesquisar Equipamento"
          searchText={searchText}
          search={search}
          onSearch={handleSearch}
          onHide={hideSearch}
          options={options}
        />
      );
    },
    filter: false,
    page: page,
    count: !itemsList.data ? 0 : itemsList.data?.models.total,
    expandableRows: false,
    expandableRowsHeader: false,
    expandableRowsOnClick: false,
    rowsPerPage: perPage,
    rowsPerPageOptions: [15, 30, 100],
    onRowsDelete: (rowsDeleted, newData) => {
      HandleDelete(rowsDeleted.data);
    },
    textLabels: {
      body: {
        noMatch: 'Desculpe, não encontramos equipamentos vinculados!',
        toolTip: 'Sort',
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
      pagination: {
        next: 'Avançar',
        previous: 'Voltar',
        rowsPerPage: 'Linhas por página:',
        displayRows: 'de',
      },

      toolbar: {
        search: 'Pesquisar',
        downloadCsv: 'Baixar CSV',
        print: 'Imprimir',
        viewColumns: 'Ver Colunas',
        filterTable: 'Filtrar tabela',
      },
      filter: {
        all: 'Tudo',
        title: 'FILTROS',
        reset: 'RESETAR',
      },
      viewColumns: {
        title: 'Exibir colunas',
        titleAria: 'Exibir/Ocultar colunas da tabela',
      },
      selectedRows: {
        text: 'Coluna(s) selecionada(s)',
        delete: 'Excluir',
        deleteAria: 'Excluir linhas selecionadas',
      },
    },
    customFooter: (
      count,
      page,
      rowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels,
    ) => {
      let numberPages = !itemsList.data ? 0 : itemsList.data?.models.last_page;
      let rowsPerPageOptions = [15, 30, 100];
      return (
        <CustomFooter
          page={page}
          rowsPerPage={rowsPerPage}
          changeRowsPerPage={changeRowsPerPage}
          changePage={changePage}
          numberPages={numberPages}
          rowsPerPageOptions={rowsPerPageOptions}
        />
      );
    },
  };

  return (
    <>
      {!employeeView.linkTelegram ? (
        <>
          {!employeeView.chatId && employeeView.telegramView ? (
            <Button widthSize="100%" onClick={handleShow}>
              <Fontawesome classe="fab fa-telegram-plane mr-2" /> Vincular
            </Button>
          ) : (
            <Link className="dropdown-item" to="#" onClick={handleShow}>
              <Fontawesome classe="fab fa-telegram-plane mr-2" /> Notificações
              Telegram
            </Link>
          )}
        </>
      ) : (
        <form className="customerStep">
          {employeeView.chatId && (
            <>
              <Row>
                <Col style={{ zIndex: '999' }}>
                  <div className="formStep">
                    <label className="important">Equipamento</label>
                    <AsyncPaginate
                      value={valueSelect
                        .filter((fill) => fill.nameField == 'id_contract_item')
                        .map((map) => {
                          return {
                            value: map.value,
                            label: map.label,
                          };
                        })}
                      loadOptions={loadOptions}
                      onChange={(e) => HandleSelect(e, 'id_contract_item')} //values and name of select paginate
                      placeholder="Selecione o equipamento..."
                      //Set your param that is going to change when you select another field. Just use this option when you are setting params and need to refresh cache.
                      cacheUniqs={[valueSelect]}
                      styles={colourStyles}
                      additional={{
                        page: 1,
                        endpoint: '/contract-items',
                        params: `id_customer=${employeeView.idCustomer}`,
                        paramName: 'nickname',
                        paramNameObs: 'serial',
                      }}
                    />
                  </div>
                </Col>

                <Col>
                  <div className="formStep">
                    <label class="important">Nome de Preferência: </label>
                    <input
                      type="text"
                      placeholder="Informe um apelido..."
                      name="name"
                      onKeyUp={HandleChange}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col className="d-flex justify-content-center mt-4">
                  <Button type="button" onClick={HandleRegister}>
                    Vincular
                  </Button>
                </Col>
              </Row>
            </>
          )}

          {!employeeView.chatId && (
            <>
              <Row>
                <Col className="d-flex justify-content-center mt-4">
                  <Button type="button" onClick={HandleNewLink}>
                    Vincular novos
                  </Button>
                </Col>
              </Row>
            </>
          )}

          <Row>
            <Col>
              <MUIDataTable
                data={itemsList.data?.models.data}
                columns={columns}
                options={options}
              />
            </Col>
          </Row>

          <Row>
            <Col className="d-flex justify-content-center mt-4">
              <BackButton type="button" onClick={handleClose}>
                Voltar
              </BackButton>
            </Col>
          </Row>
        </form>
      )}
    </>
  );
};
export default LinkTelegram;
