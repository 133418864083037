import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Fontawesome from '../../fontawesome/fontawesome';

const Types = ({ value }) => {
  return value == 1 ? (
    <OverlayTrigger
      overlay={
        <Tooltip id="tooltip-disabled">Equipamento de Monitoramento</Tooltip>
      }
    >
      <div>
        <Fontawesome classe="fas fa-tachometer-alt" color="#822a2a" />
      </div>
    </OverlayTrigger>
  ) : (
    <OverlayTrigger
      overlay={<Tooltip id="tooltip-disabled">Equipamento de Gestão</Tooltip>}
    >
      <div>
        <Fontawesome classe="fas fa-tools" color="#1bc5bd" />
      </div>
    </OverlayTrigger>
  );
};
export default Types;
