import React from 'react';
import { GroupStar, Star } from './potentialStyle';

const Potential = ({ potential }) => {
  return (
    <GroupStar>
      <Star
        src={
          potential >= 1
            ? '/media/icons/light-star.png'
            : '/media/icons/dark-star.png'
        }
      />
      <Star
        src={
          potential >= 2
            ? '/media/icons/light-star.png'
            : '/media/icons/dark-star.png'
        }
      />
      <Star
        src={
          potential >= 3
            ? '/media/icons/light-star.png'
            : '/media/icons/dark-star.png'
        }
      />
      <Star
        src={
          potential >= 4
            ? '/media/icons/light-star.png'
            : '/media/icons/dark-star.png'
        }
      />
      <Star
        src={
          potential == 5
            ? '/media/icons/light-star.png'
            : '/media/icons/dark-star.png'
        }
      />
    </GroupStar>
  );
};
export default Potential;
