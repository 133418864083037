import React, { useState } from 'react';
import { Prefix } from '../../../services/prefix';
import { useFetch } from '../../../components/hooks/useFetch';
import Loading from '../../../components/loader/loading';
import Topbar from '../../../components/topbar/topbar';
import CustomSearchRender from '../actionPlan/CustomSearchRender';
import CustomFooter from '../../../components/CustomFooter/CustomFooter';
import api from '../../../services/api';
import SidebarSisboard from '../../../components/sidebar/sidebar';
import Fontawesome from '../../components/fontawesome/fontawesome';
import MUIDataTable from 'mui-datatables';
import { columns } from './columns';
import Swal from 'sweetalert2';
import { mutate as mutateGlobal } from 'swr';
import loading from '../../../components/loader/loading';
import { Col, Row, Table, Card } from 'react-bootstrap';
import { TableCell, TableRow } from '@material-ui/core';
import { maskTel } from '../../../services/mask';
import './style.css';
import {
  CardSt,
  CardHeaderSt,
  IconTitleSt,
  TitleSt,
  TextTitleSt,
  SmallTitleSt,
} from '../../../style/globalStyle';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { GetMuiTheme } from '../../../components/muiTheme/muiTheme';

const Notification = () => {
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState();
  const [pagina, setPagina] = useState(0);
  const [perPage, setPerPage] = useState(15);
  const [search, setSearch] = useState('');

  const handleShow = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };

  let pageExact = pagina + parseInt(1);

  const listNotification = useFetch(
    Prefix.replace(/[\\"]/g, '') +
      '/customer-employees?per_page=' +
      perPage +
      '&page=' +
      pageExact +
      '&search=' +
      search,
  );

  const listItems = useFetch(Prefix.replace(/[\\"]/g, '') + '/contract-items');

  if (!listNotification.data) return <Loading />;

  const options = {
    draggableColumns: {
      enabled: true,
    },
    serverSide: true,
    onTableChange: (action, tableState) => {
      setPagina(tableState.page);
      setPerPage(tableState.rowsPerPage);
    },
    onSearchChange: (searchText) => {
      setSearch(!searchText ? '' : searchText);
    },
    customSearchRender: (searchText, handleSearch, hideSearch, options) => {
      return (
        <CustomSearchRender
          placeholderSearch="Pesquisar Notificação de WhatsApp"
          searchText={searchText}
          onSearch={handleSearch}
          onHide={hideSearch}
          options={options}
        />
      );
    },
    filter: false,
    page: pagina,
    count: listNotification.data?.models?.total, //Exibe o total vindo da constante cityList
    expandableRows: true,
    expandableRowsHeader: false,
    expandableRowsOnClick: false,
    rowsPerPage: perPage,
    rowsPerPageOptions: [15, 30, 100],
    isRowExpandable: (dataIndex, expandedRows) => {
      // if (dataIndex === 3 || dataIndex === 4) return false;
      if (
        expandedRows.data.length > 4 &&
        expandedRows.data.filter((d) => d.dataIndex === dataIndex).length === 0
      )
        return false;
      return true;
    },
    renderExpandableRow: (rowData, rowMeta) => {
      const colSpan = rowData.length + 1;
      return (
        <>
          {rowData[3].contract_notification.length == 0 ? (
            <>
              <TableRow>
                <TableCell colSpan={colSpan}>
                  <Row>
                    <Col className="text-center">
                      Não há notificações para este colaborador!
                    </Col>
                  </Row>
                </TableCell>
              </TableRow>
            </>
          ) : (
            <>
              <TableRow>
                <TableCell colSpan={colSpan}>
                  <Row>
                    <Col className="text-center">
                      <h4>Notificação em Coletores</h4>
                      <Table striped bordered hover>
                        <thead>
                          <tr>
                            <th>Coletor:</th>
                            <th>Plataforma:</th>
                            <th>Dado de Contato:</th>
                            <th>Monitorável:</th>
                          </tr>
                        </thead>
                        <tbody>
                          {rowData[3].contract_notification.map((map) => {
                            return (
                              <>
                                <tr>
                                  <td>
                                    {listItems.data?.models?.data
                                      .filter(
                                        (fill) =>
                                          fill.id == map.id_contract_item,
                                      )
                                      .map((map) => {
                                        return map.nickname;
                                      })}
                                  </td>
                                  <td>
                                    {map.plataform == 'whatsapp' ? (
                                      <Fontawesome classe="fab fa-whatsapp" />
                                    ) : (
                                      <Fontawesome classe="far fa-envelope" />
                                    )}
                                  </td>
                                  <td>
                                    {isNaN(map.identification) == false
                                      ? maskTel(map.identification)
                                      : map.identification}
                                  </td>
                                  <td>
                                    {map.monitoring == 0 ? (
                                      <Fontawesome classe="fas fa-times" />
                                    ) : (
                                      <Fontawesome classe="fas fa-check" />
                                    )}
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </TableCell>
              </TableRow>
            </>
          )}
        </>
      );
    },
    onRowsDelete: (rowsDeleted, newData) => {
      HandleDelete(rowsDeleted.data);
    },
    searchPlaceholder: 'Pesquisar Notificação de WhatsApp',
    selectableRows: false,
    searchOpen: true,

    textLabels: {
      body: {
        noMatch: 'Desculpe, não encontramos Notificação de WhatsApp!',
        toolTip: 'Sort',
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
      pagination: {
        next: 'Avançar',
        previous: 'Voltar',
        rowsPerPage: 'Linhas por página:',
        displayRows: 'de',
      },
      toolbar: {
        search: 'Pesquisar',
        downloadCsv: 'Baixar CSV',
        print: 'Imprimir',
        viewColumns: 'Ver Colunas',
        filterTable: 'Filtrar tabela',
      },
      filter: {
        all: 'Tudo',
        title: 'FILTROS',
        reset: 'RESETAR',
      },
      viewColumns: {
        title: 'Exibir colunas',
        titleAria: 'Exibir/Ocultar colunas da tabela',
      },
      selectedRows: {
        text: 'Coluna(s) selecionada(s)',
        delete: 'Excluir',
        deleteAria: 'Excluir linhas selecionadas',
      },
    },
    customFooter: (
      count,
      page,
      rowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels,
    ) => {
      let numberPages = listNotification.data?.models?.last_page;
      let rowsPerPageOptions = [15, 30, 100];
      return (
        <CustomFooter
          page={page}
          rowsPerPage={rowsPerPage}
          changeRowsPerPage={changeRowsPerPage}
          changePage={changePage}
          numberPages={numberPages}
          rowsPerPageOptions={rowsPerPageOptions}
        />
      );
    },
  };

  function HandleDelete(e) {
    setLoader(<Loading />);
    let idArr = [];

    e.map((del) => {
      idArr.push(
        listNotification.data?.models?.data.filter(
          (filtro, index) => index == del.index,
        )[0].id,
      );
    });

    idArr.map((idDel) =>
      api
        .delete(
          Prefix.replace(/[\\"]/g, '') + '/}contract-notifications/' + idDel,
        )
        .then((response) => {
          Swal.fire({
            icon: 'success',
            title: 'Uow!',
            text: response.data.message,
            confirmButtonText: 'Fechar',
          });
          setLoader();
          mutateGlobal(
            Prefix.replace(/[\\"]/g, '') +
              '/contract-notifications?per_page=' +
              perPage +
              '&page=' +
              pageExact +
              '&search=',
          );
        })
        .catch((err) => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: err.response.data.message,
            confirmButtonText: 'Tentar novamente',
          });
          setLoader();
        }),
    );
  }

  return (
    <div className="wrapper d-flex align-items-stretch">
      <SidebarSisboard />
      <div id="content">
        {/* {loader} */}
        <Topbar />
        <div className="content_all">
          <CardSt>
            <CardHeaderSt className="d-flex justify-content-between">
              <TitleSt>
                <IconTitleSt
                  style={{ width: '36px' }}
                  src="../../../media/icons-pages/notification-page-sis.svg"
                />
                <TextTitleSt>
                  Notificações{' '}
                  <SmallTitleSt style={{ marginRight: '8px' }}>
                    Gestão de Notificações{' '}
                  </SmallTitleSt>
                </TextTitleSt>
              </TitleSt>
            </CardHeaderSt>
            <CardSt.Body as="div">
              <CardSt.Text as="div">
                <MuiThemeProvider theme={GetMuiTheme()}>
                  <MUIDataTable
                    data={listNotification.data?.models?.data}
                    columns={columns}
                    options={options}
                  />
                </MuiThemeProvider>
              </CardSt.Text>
            </CardSt.Body>
          </CardSt>
        </div>
      </div>
    </div>
  );
};
export default Notification;
