import React from 'react';
import { Prefix } from '../../../services/prefix';
import { useFetch } from '../../../components/hooks/useFetch';
import './images.css';

const Image = ({ idModel, widthSize, heightSize }) => {
  const images = useFetch(
    Prefix.replace(/[\\"]/g, '') +
      '/equipment-images?id_equipment_model=' +
      idModel,
  );
  if (!images.data) return '';
  const state = images.data?.models?.data;
  const linkImage = !state.length
    ? '../../media/icons/no-image.jpg'
    : state[0].path;
  return (
    <div
      className="img-device"
      style={{
        backgroundImage: 'url(' + linkImage + ')',
        width: widthSize,
        height: heightSize,
      }}
    ></div>
  );
};
export default Image;
