import React from 'react';
import Edit from '../../components/equipaments_model/edit/editEquipamentsModel';
import UpdateStatus from '../../components/equipaments_model/updateStatus/updateStatus';
import UploadImage from '../../components/equipaments_model/imageUpload/imageUpload';
import IndexLogo from '../../components/equipaments_model/imageUpload/changeLogo';

export const columns = [
  {
    //0
    name: 'id',
    label: '',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      display: 'excluded',
    },
  },
  {
    //1
    name: 'id',
    label: 'Imagem',
    options: {
      filter: false,
      sort: false,
      display: true,
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
          display: 'flex',
          'justify-content': 'center',
        },
      }),
      customBodyRender: (value, tableMeta) => {
        return <IndexLogo idModel={value} widthSize="55px" heightSize="55px" />;
      },
    },
  },
  {
    //2
    name: 'id_equipment_category',
    label: '',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      display: 'excluded',
    },
  },
  {
    //3
    name: 'id_brand',
    label: '',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      display: 'excluded',
    },
  },
  {
    //4
    name: 'name',
    label: 'Modelo',
    options: {
      setCellHeaderProps: () => ({
        style: {
          display: "flex",
          justifyContent: "center",
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: true,
    },
  },
  {
    //5
    name: 'equipment_category',
    label: 'Equipamento',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,

      customBodyRender: (value, tableMeta) => {
        return value?.name;
      },
    },
  },
  {
    //6
    name: 'anvisa_registry',
    label: 'Registro da Anvisa',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
    },
  },
  {
    //7
    name: 'id_brand',
    label: 'Marca do Modelo',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      display: 'none',
    },
  },
  {
    //8
    name: '',
    label: 'Galeria',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
          width: '10%',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: true,
      sort: false,
      print: false,
      customBodyRender: (value, tableMeta) => {
        return (
          <UploadImage
            id={tableMeta.rowData[0]}
            page={tableMeta.tableState.page}
            rowsPerPage={tableMeta.tableState.rowsPerPage}
            searchText={
              !tableMeta.tableState.searchText
                ? ''
                : tableMeta.tableState.searchText
            }
            orderBy={tableMeta.tableState.sortOrder.name}
            order={tableMeta.tableState.sortOrder.direction}
          />
        );
      },
    },
  },

  {
    //9
    name: 'active',
    label: 'Status',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
          width: '10%',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: true,
      sort: false,
      print: false,
      customBodyRender: (value, tableMeta) => {
        return (
          <UpdateStatus
            activeDefault={value}
            id={tableMeta.rowData[0]}
            page={tableMeta.tableState.page}
            rowsPage={tableMeta.tableState.rowsPerPage}
            orderBy={tableMeta.tableState.sortOrder.name}
            order={tableMeta.tableState.sortOrder.direction}
            search={!tableMeta.tableState.searchText ? '' : tableMeta.tableState.searchText}
          />
        );
      },
    },
  },

  {
    //10
    name: 'equipment_category',
    label: '',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      display: 'excluded',
      customBodyRender: (value, tableMeta) => {
        return value;
      },
    },
  },

  {
    //11
    name: 'active',
    label: '',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      display: 'excluded',
    },
  },

  {
    //12
    name: 'Edit',
    label: 'Editar',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
          width: '10%',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      print: false,
      customBodyRender: (value, tableMeta) => {
        return (
          <Edit
            page={tableMeta.tableState.page}
            rowsPage={tableMeta.tableState.rowsPerPage}
            id={tableMeta.rowData[0]}
            search={!tableMeta.tableState.searchText ? '' : tableMeta.tableState.searchText}
            orderBy={tableMeta.tableState.sortOrder.name}
            order={tableMeta.tableState.sortOrder.direction}
          />
        );
      },
    },
  },
];
