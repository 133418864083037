import React, { useState, useContext, useEffect } from 'react';
import { Modal, Button, Col, Row } from 'react-bootstrap';
import { Prefix } from '../../../services/prefix';
import MUIDataTable from 'mui-datatables';
import { useFetch } from '../../hooks/useFetch';
import Swal from 'sweetalert2';
import CustomFooter from '../../CustomFooter/CustomFooter';
import Loading from '../../loader/loading';
import api from '../../../services/api';
import CustomSearchRender from '../../customSearchRender/CustomSearchRender';
import { ColumnsServicesOs } from './columnsServicesOs';
import { mutate as GlobalMutate } from 'swr';
import { Link } from 'react-router-dom';
import Fontawesome from '../../fontawesome/fontawesome';
import Select from 'react-select';
import { Context } from '../../../services/context';
import { services } from '../../data/Temp-services';
import Attachments from './osItemAttachments';
import Comments from './osItemComments';
import '../os.css';

const ServicesOs = ({ id, idContractItem }) => {
  const { redirect, osItemServices, setOsItemServices } = useContext(Context);
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState();
  const [pagina, setPagina] = useState(0);
  const [perPage, setPerPage] = useState(15);
  const [search, setSearch] = useState('');
  const [add, setAdd] = useState(false);
  const [repeat, setRepeat] = useState([
    {
      id: 0,
      id_service: null,
      id_os_technician: null,
    },
  ]);
  let pageExact = pagina + parseInt(1);

  const listServicesOS = useFetch(
    Prefix.replace(/[\\"]/g, '') +
    '/os-services?all=false&per_page=' +
    perPage +
    '&page=' +
    pageExact +
    '&search=' +
    search +
    '&id_os=' +
    id,
  );

  useEffect(() => {
    listServicesOS.mutate();
  }, [show]);

  const technicianList = useFetch(
    Prefix.replace(/[\\"]/g, '') + '/os-technicians?all=true&id_os=' + id,
  );

  const findContractItem = useFetch(
    Prefix.replace(/[\\"]/g, '') + '/contract-items/' + idContractItem,
  );
  const idEquipamentCategory = !findContractItem.data?.model
    ? ''
    : findContractItem.data?.model?.equipament_model?.id_equipament_category;

  const servicesList = useFetch(
    Prefix.replace(/[\\"]/g, '') +
    '/services?all=true&id_equipament_category=' +
    idEquipamentCategory,
  );

  const optionsTechnicians = technicianList.data?.models.map((map) => {
    return {
      value: map.id,
      label: map?.technician?.technician_client?.user?.name,
    };
  });

  const optionsServices = servicesList.data?.models.map((map) => {
    return { value: map.id, label: map.name };
  });

  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
  };

  const options = {
    filterType: 'dropdown',
    draggableColumns: {
      enabled: true,
    },
    serverSide: true,
    onTableChange: (action, tableState) => {
      setPagina(tableState.page);
      setPerPage(tableState.rowsPerPage);
    },
    onSearchChange: (searchText) => {
      setSearch(!searchText ? '' : searchText);
    },
    customSearchRender: (searchText, handleSearch, hideSearch, options) => {
      return (
        <CustomSearchRender
          placeholderSearch="Pesquisar serviço"
          searchText={searchText}
          search={search}
          onSearch={handleSearch}
          onHide={hideSearch}
          options={options}
        />
      );
    },
    filter: false,
    page: pagina,
    count: listServicesOS.data?.models?.total,
    expandableRows: false,
    expandableRowsHeader: false,
    expandableRowsOnClick: false,
    rowsPerPage: perPage,
    rowsPerPageOptions: [15, 30, 100],
    onRowsDelete: (rowsDeleted, newData) => {
      HandleDelete(rowsDeleted.data);
    },
    searchOpen: true,
    textLabels: {
      body: {
        noMatch: 'Desculpe, não encontramos serviços!',
        toolTip: 'Sort',
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
      pagination: {
        next: 'Avançar',
        previous: 'Voltar',
        rowsPerPage: 'Linhas por página:',
        displayRows: 'de',
      },

      toolbar: {
        search: 'Pesquisar',
        downloadCsv: 'Baixar CSV',
        print: 'Imprimir',
        viewColumns: 'Ver Colunas',
        filterTable: 'Filtrar tabela',
      },
      filter: {
        all: 'Tudo',
        title: 'FILTROS',
        reset: 'RESETAR',
      },
      viewColumns: {
        title: 'Exibir colunas',
        titleAria: 'Exibir/Ocultar colunas da tabela',
      },
      selectedRows: {
        text: 'Coluna(s) selecionada(s)',
        delete: 'Excluir',
        deleteAria: 'Excluir linhas selecionadas',
      },
    },
    customFooter: (
      count,
      page,
      rowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels,
    ) => {
      let numberPages = !listServicesOS.data
        ? 0
        : listServicesOS.data?.models?.last_page;
      let rowsPerPageOptions = [15, 30, 100];
      return (
        <CustomFooter
          page={page}
          rowsPerPage={rowsPerPage}
          changeRowsPerPage={changeRowsPerPage}
          changePage={changePage}
          numberPages={numberPages}
          rowsPerPageOptions={rowsPerPageOptions}
        />
      );
    },
  };

  function HandleRegisterServices() {
    repeat.map((map) => {
      let infoServices = {
        id_service: map.id_service,
        id_os_technician: map.id_os_technician,
      };
      api
        .post(Prefix.replace(/[\\"]/g, '') + '/os-services', infoServices)
        .then((response) => {
          Swal.fire({
            icon: 'success',
            title: 'Uow...',
            text: response.data.message,
            confirmButtonText: 'Fechar',
          });
          setShow(false);
          listServicesOS.mutate();
        })
        .catch((err) => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: err.response.data.message,
            confirmButtonText: 'Tentar novamente',
          });
        });
    });
  }

  function HandleDelete(e) {
    setLoader(<Loading />);
    let idArr = [];

    e.map((del) => {
      idArr.push(
        listServicesOS.data?.models?.data.filter(
          (filtro, index) => index == del.index,
        )[0].id,
      );
    });

    idArr.map(
      (
        idDel, //Faz exclusão com map um a um para evitar bugs
      ) =>
        api
          .delete(Prefix.replace(/[\\"]/g, '') + '/os-services/' + idDel)
          .then((response) => {
            Swal.fire({
              icon: 'success',
              title: 'Uow!',
              text: response.data.message,
              confirmButtonText: 'Fechar',
            });
            setLoader();
            listServicesOS.mutate();
          })
          .catch((err) => {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: err.response.data.message,
              confirmButtonText: 'Tentar novamente',
            });
            setLoader();
          }),
    );
  }

  const handleChangeService = (e, id) => {
    let newArray = [...repeat];
    newArray[id] = { ...newArray[id], id_service: e.value };
    setRepeat(newArray);
  };

  const handleChangeTechOs = (e, id) => {
    let newArray = [...repeat];
    newArray[id] = { ...newArray[id], id_os_technician: e.value };
    setRepeat(newArray);
  };

  function addService() {
    setRepeat((oldArray) => [
      ...oldArray,
      {
        id: oldArray.length,
        id_service: null,
        id_os_technician: null,
        finished_at: null,
      },
    ]);
  }

  function removeService(e) {
    setRepeat(repeat.filter((item) => item.id != e));
  }

  function HandleAdd() {
    setOsItemServices({ ...osItemServices, service: 1 });
  }

  function HandleBack() {
    setOsItemServices({
      ...osItemServices,
      service: null,
      attach: null,
      comment: null,
    });
  }
  return (
    <>
      {!show ? (
        <Link className="dropdown-item" to="#" onClick={handleShow}>
          <Fontawesome classe="fas fa-list-ol mr-3" /> Serviços da OS
        </Link>
      ) : (
        <Modal
          show={show}
          size="lg"
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title className="d-flex align-items-center">
              {osItemServices.service && (
                <Link to="#" onClick={HandleBack}>
                  <img
                    src="../../../media/icons/previous.png"
                    className="backHeader"
                  />
                </Link>
              )}
              Serviços da OS{' '}
              {!osItemServices.service && (
                <Button variant="success" className="ml-4" onClick={HandleAdd}>
                  <Fontawesome classe="fas fa-plus-circle" /> Adicionar
                </Button>
              )}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="customerStep flip-in-hor-top">
              {!osItemServices.service ? (
                // <MUIDataTable
                //   data={listServicesOS.data?.models?.data}
                //   columns={ColumnsServicesOs}
                //   options={options}
                // />
                <MUIDataTable
                  data={services}
                  columns={ColumnsServicesOs}
                  options={options}
                />
              ) : osItemServices.service == 1 ? (
                <>
                  <Row>
                    <Col className="d-flex justify-content-center mt-4 mb-4">
                      <h2>
                        Quais serão os serviços desta OS?{' '}
                        <Button
                          variant="success"
                          className="ml-4"
                          onClick={addService}
                        >
                          Adicionar
                        </Button>
                      </h2>
                    </Col>
                  </Row>

                  {repeat.map((map, key) => {
                    return (
                      <Row key={key} className="mb-3">
                        <Col md={6}>
                          <Select
                            options={optionsServices}
                            placeholder="Selecione o(s) serviço(s)..."
                            onChange={(e) => handleChangeService(e, key)}
                          />
                        </Col>
                        <Col md={key == 0 ? 6 : 5}>
                          <Select
                            options={optionsTechnicians}
                            placeholder="Selecione um equipamento..."
                            onChange={(e) => handleChangeTechOs(e, key)}
                          />
                        </Col>
                        {key == 0 ? (
                          ''
                        ) : (
                          <Col md={1}>
                            <Button
                              variant="danger"
                              onClick={() => removeService(key)}
                            >
                              X
                            </Button>
                          </Col>
                        )}
                      </Row>
                    );
                  })}
                  <Row>
                    <Col className="d-flex justify-content-center mt-4">
                      <Button
                        variant="success"
                        onClick={HandleRegisterServices}
                      >
                        Finalizar
                      </Button>
                    </Col>
                  </Row>
                </>
              ) : osItemServices.service == 2 ? (
                <>
                  <Attachments idService={1} />
                </>
              ) : (
                <>
                  <Comments idService={1} />
                </>
              )}
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};
export default ServicesOs;
