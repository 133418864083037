import React from 'react';
import Fontawesome from '../../fontawesome/fontawesome';
import { Link } from 'react-router-dom';
import IndexEmployers from '../../employers_clients/indexEmployers';
import Permission from '../userPermission/permission';
import Password from '../../../components/users/password/password';

const OptionsUsers = (props) => {

  return (
    <div className="dropdown">
      <Link
        to="#"
        className="dropdown link_color_datatable"
        id="dropdownNotification"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <Fontawesome classe="fas fa-bars" />
      </Link>
      <ul
        className="dropdown-menu fade-in-top drop-options"
        aria-labelledby="dropdownNotification"
      >
        <li>
          <Permission
            idUser={props.id}
            buttonLabel="Permissões"
            modalSize="lg"
            iconClass="fas fa-shield-alt"
          />
        </li>
        <li>
          <Password
            id={props.id}
          />
        </li>
      </ul>
    </div>
  );
};
export default OptionsUsers;
