export const services = [
  {
    id: 1,
    services: 'Calibração, Preventiva',
    technician: 'Técnico1, Técnico2',
    execution_at: '12/08/2021',
    duration: '01:00:00',
  },
];

export default {
  services,
};
