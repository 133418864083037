import React, { useState, useRef, useEffect } from "react";
import { Row, Col, Button, Modal } from "react-bootstrap";
import "../modules.css";
import { Prefix } from "../../../services/prefix";
import Loading from "../../loader/loading";
import Swal from "sweetalert2";
import api from "../../../services/api";
import Fontawesome from "../../fontawesome/fontawesome";
import { useFetch } from "../../hooks/useFetch";
import { mutate as GlobalMutate } from "swr";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Schema from "./schema";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import { useStyles } from "../../buttons/AddButtonStyle";
import { ModalContainer } from "../../../style/stylesModals"; 
import { CustomButton, TextButton } from "../../../style/stylesButton";

const IndexAddModules = (props) => {
  const [nameModulo, setNameModulo] = useState();
  const [loader, setLoader] = useState();
  const [show, setShow] = useState(false);
  const [initialState, setInitialState] = useState({
    name: "",
    active: "",
    id_scheme: "",
    relation: "",
  });
  const [registerData, setRegisterData] = useState(initialState);

  const classes = useStyles();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const loaderModulo = useFetch(Prefix.replace(/[\\"]/g, "") + "/modules");
  const schemeList = useFetch(Prefix.replace(/[\\"]/g, "") + "/schemes");

  if (!schemeList.data) return <Loading />;

  function HandleRegister(values) {
    setLoader(true);
    api
      .post(Prefix.replace(/[\\"]/g, "") + "/modules", values)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Uow!",
          text: response.data.message,
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: "Fechar",
          cancelButtonText: "Novo Cadastro",
          allowOutsideClick: false,
          focusCancel: true,
        }).then((result) => {
          if (!result.isConfirmed) {
            setShow(true);
          }
        });
        setLoader(false);
        setShow(false);
        GlobalMutate(
          Prefix.replace(/[\\"]/g, "") +
            "/modules?per_page=" +
            props.perPage +
            "&page=" +
            props.pageExact +
            "&search=" +
            props.search +
            "&order_by=" +
            props.orderBy +
            "&order=" +
            props.order
        );
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: err.response.data.message,
          confirmButtonText: "Tentar novamente",
        });
        setLoader(false);
      });
  }

  return (
    <>
      {show === false ? (
        <Fab className={classes.fab} onClick={handleShow}>
          <AddIcon className={classes.icon} />
        </Fab>
      ) : (
        ""
      )}
      <ModalContainer
        show={show}
        size={props.modalSize}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title><h3>{props.titleLabel}</h3></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik //--> Configuração do Formik
            initialValues={initialState}
            validationSchema={Schema}
            onSubmit={HandleRegister}
          >
            {(
              { values } //--> Captação de valores
            ) => (
              <Form>
                <Row>
                  {loader}
                  <Col>
                    <div className="formStep">
                      <label class="important">Módulo</label>
                      <Field
                        type="text"
                        placeholder="Digite um módulo"
                        name="name"
                      />
                      <ErrorMessage
                        name="name"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  {loader}
                  <Col>
                    <div className="formStep">
                      <label class="important">Situação</label>
                      <Field as="select" type="text" name="active">
                        <option selected>Selecione uma situação</option>
                        <option value="1">Ativo</option>
                        <option value="0">Desativado</option>
                      </Field>
                      <ErrorMessage
                        name="active"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  {loader}
                  <Col>
                    <div className="formStep">
                      <label class="important">Licença</label>
                      <Field
                        as="select"
                        type="text"
                        placeholder="Escolha uma licença"
                        name="id_scheme"
                      >
                        <option selected>Selecione uma licença...</option>
                        {schemeList.data?.models?.data.map((value) => (
                          <>
                            <option key={value.id} value={value.id}>
                              {value.name}
                            </option>
                          </>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="id_scheme"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  {loader}
                  <Col>
                    <div className="formStep">
                      <label class="important">Relacionamento</label>
                      <Field
                        type="text"
                        placeholder="Digite o endereço do módulo"
                        name="relation"
                      />
                      <ErrorMessage
                        name="relation"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className="d-flex justify-content-center">
                    <CustomButton type="submit">
                    <TextButton>Cadastrar</TextButton>  
                    </CustomButton>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </ModalContainer>
    </>
  );
};
export default IndexAddModules;
