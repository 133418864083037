import React from 'react';
import UpdateStatus from './updateStatus/updateStatus';
import OptionsEmployees from './optionsEmployees/optionsEmployees';

export const columns = [
  {
    //0
    name: 'id',
    label: '',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      display: 'excluded',
    },
  },

  {
    //1
    name: 'user',
    label: 'Nome',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      customBodyRender: (value, tableMeta) => {
        return !value ? '' : value?.name;
      },
    },
  },
  {
    //3
    name: 'user',
    label: 'Status',
    options: {
      print: false,
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
          width: '20%',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),

      filter: true,
      sort: false,
      customBodyRender: (value, tableMeta) => {
        return (
          <UpdateStatus
            activeDefault={value.active}
            id={value.id}
            id_client={tableMeta.rowData[6]}
            page={tableMeta.tableState.page}
            rowsPage={tableMeta.tableState.rowsPerPage}
            search={
              !tableMeta.tableState.search ? '' : tableMeta.tableState.search
            }
            orderBy={tableMeta.tableState.sortOrder.name}
            order={tableMeta.tableState.sortOrder.direction}
          />
        );
      },
    },
  },
  {
    //2
    name: 'equipmentView',
    label: 'Opções',
    options: {
      print: false,
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
          width: '10%',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
          width: '10%',
        },
      }),
      filter: true,
      sort: false,
      customBodyRender: (value, tableMeta) => {
        return (
          <OptionsEmployees
            idUser={tableMeta.rowData[7].id}
            idCustomer={tableMeta.rowData[8].id}
          />
        );
      },
    },
  },

  {
    //4
    name: 'user',
    label: 'Email',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      display: 'excluded',
      customBodyRender: (value, tableMeta) => {
        return value?.email;
      },
    },
  },
  {
    //5
    name: 'user',
    label: 'type',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      display: 'excluded',
      customBodyRender: (value, tableMeta) => {
        return value?.type;
      },
    },
  },
  {
    //6
    name: 'id_customer',
    label: 'Id Client',
    options: {
      filter: false,
      sort: false,
      display: 'excluded',
    },
  },
  {
    //7
    name: 'user',
    label: 'id',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      display: 'excluded',
    },
  },
  {
    //7
    name: 'customer',
    label: 'id_customer',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      display: 'excluded',
    },
  },
];
