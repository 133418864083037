import React from "react";
import Sidebar from "../../components/sidebar/sidebar";
import Topbar from "../../components/topbar/topbar";
import Card from "react-bootstrap/Card";
import Fontawesome from "../../components/fontawesome/fontawesome";
import { Row, Col, Alert } from "react-bootstrap";
import GenerateContracts from "../../components/reports/generateContracts/GenerateContracts";
import GenerateClients from "../../components/reports/generateClients/GenerateClients";
import GenerateEmployees from "../../components/reports/generateEmployees/GenerateEmployees";
import GenerateUserLog from "../../components/reports/generateUserLog/GenerateUserLog";
import GenerateActionPlan from "../../components/reports/generateActionPlan/GenerateActionPlan";
import GenerateOs from "../../components/reports/generateOs/GenerateOs";
import {
  CardSt,
  CardHeaderSt,
  IconTitleSt,
  TitleSt,
  TextTitleSt,
  SmallTitleSt,
} from "../../style/globalStyle";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { GetMuiTheme } from "../../components/muiTheme/muiTheme";

const Reports = () => {
  return (
    <>
      <div className="wrapper d-flex align-items-stretch">
        <Sidebar />
        <div id="content">
          <Topbar />
          <div className="content_all">
            <CardSt>
              <CardHeaderSt className="d-flex justify-content-between">
                <TitleSt>
                  <IconTitleSt
                    style={{ width: "28px" }}
                    src="../../../media/icons-pages/reports-page.svg"
                  />
                  <TextTitleSt>
                    Relatórios{" "}
                    <SmallTitleSt>Emissão de relatórios</SmallTitleSt>
                  </TextTitleSt>
                </TitleSt>
              </CardHeaderSt>
              <CardSt.Body as="div">
                <CardSt.Text as="div">
                  <div>
                    <Row>
                      <Col>
                        <Alert
                          variant="success"
                          className="d-flex justify-content-between align-items-center"
                        >
                          <span>
                            {" "}
                            <Fontawesome classe="fas fa-angle-double-right mr-3" />
                            Relatório de Clientes
                          </span>{" "}
                          <GenerateClients />
                        </Alert>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Alert
                          variant="success"
                          className="d-flex justify-content-between align-items-center"
                        >
                          <span>
                            {" "}
                            <Fontawesome classe="fas fa-angle-double-right mr-3" />
                            Relatório de Contratos{" "}
                          </span>{" "}
                          <GenerateContracts />
                        </Alert>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Alert
                          variant="success"
                          className="d-flex justify-content-between align-items-center"
                        >
                          <span>
                            {" "}
                            <Fontawesome classe="fas fa-angle-double-right mr-3" />
                            Relatório de Colaboradores{" "}
                          </span>{" "}
                          <GenerateEmployees />
                        </Alert>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Alert
                          variant="success"
                          className="d-flex justify-content-between align-items-center"
                        >
                          <span>
                            {" "}
                            <Fontawesome classe="fas fa-angle-double-right mr-3" />
                            Relatório de Plano de Ação{" "}
                          </span>{" "}
                          <GenerateActionPlan />
                        </Alert>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Alert
                          variant="success"
                          className="d-flex justify-content-between align-items-center"
                        >
                          <span>
                            {" "}
                            <Fontawesome classe="fas fa-angle-double-right mr-3" />
                            Relatório de Ordem de Serviço
                          </span>
                          <GenerateOs />
                        </Alert>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Alert
                          variant="success"
                          className="d-flex justify-content-between align-items-center"
                        >
                          <span>
                            {" "}
                            <Fontawesome classe="fas fa-angle-double-right mr-3" />
                            Relatório de Histórico de Usuários{" "}
                          </span>{" "}
                          <GenerateUserLog />
                        </Alert>
                      </Col>
                    </Row>
                  </div>
                </CardSt.Text>
              </CardSt.Body>
            </CardSt>
          </div>
        </div>
      </div>
    </>
  );
};
export default Reports;
