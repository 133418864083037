import React, { useState } from 'react';
import Sidebar from '../../components/sidebar/sidebar';
import './customer.css';
import Topbar from '../../components/topbar/topbar';
import { columns } from './columns.jsx';
import Content from '../../components/customer/addcustomer/indexAddCustomer';
import CustomerUpload from '../../components/customer/addcustomerUpload/indexAddCustomerUpload';
import MUIDataTable from 'mui-datatables';
import { Prefix } from '../../services/prefix';
import { useFetch } from '../../components/hooks/useFetch';
import Loading from '../../components/loader/loading';
import api from '../../services/api';
import Swal from 'sweetalert2';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CustomFooter from '../../components/CustomFooter/CustomFooter';
import CustomSearchRender from '../../components/customSearchRender/CustomSearchRender';
import { Table } from 'react-bootstrap';
import { maskTel, maskTelFixo } from '../../services/mask';
import {
  CardSt,
  CardHeaderSt,
  IconTitleSt,
  TitleSt,
  TextTitleSt,
  SmallTitleSt,
} from '../../style/globalStyle';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { GetMuiTheme } from '../../components/muiTheme/muiTheme';

export const Customer = () => {
  const [loader, setLoader] = useState();
  const [pagina, setPagina] = useState(0);
  const [perPage, setPerPage] = useState(15);
  const [search, setSearch] = useState('');
  const [orderBy, setOrderBy] = useState('fantasy_name');
  const [order, setOrder] = useState('desc');

  let pageExact = pagina + parseInt(1);

  const clientsList = useFetch(
    Prefix.replace(/[\\"]/g, '') +
      '/customers?per_page=' +
      perPage +
      '&page=' +
      pageExact +
      '&search=' +
      search +
      '&order_by=' +
      orderBy +
      '&order=' +
      order,
  );

  const options = {
    draggableColumns: {
      enabled: true,
    },
    serverSide: true,
    filter: false,
    sortOrder: {
      name: orderBy,
      direction: order,
    },
    onTableChange: (action, tableState) => {
      setOrderBy(
        !tableState.sortOrder.name ? orderBy : tableState.sortOrder.name,
      );
      setOrder(
        !tableState.sortOrder.direction
          ? order
          : tableState.sortOrder.direction,
      );
      setPagina(tableState.page);
      setPerPage(tableState.rowsPerPage);
    },
    onSearchChange: (searchText) => {
      setSearch(!searchText ? '' : searchText);
    },
    customSearchRender: (searchText, handleSearch, hideSearch, options) => {
      return (
        <CustomSearchRender
          placeholderSearch="Pesquisar cliente"
          searchText={searchText}
          onSearch={handleSearch}
          onHide={hideSearch}
          options={options}
        />
      );
    },
    page: pagina,
    count: clientsList.data?.models?.total,
    expandableRows: true,
    expandableRowsHeader: false,
    expandableRowsOnClick: false,
    rowsPerPage: perPage,
    rowsPerPageOptions: [15, 30, 100],
    isRowExpandable: (dataIndex, expandedRows) => {
      //if (dataIndex === 3 || dataIndex === 4) return false; Desabilita a 3º e 4º linha
      if (
        expandedRows.data.length > 4 &&
        expandedRows.data.filter((d) => d.dataIndex === dataIndex).length === 0
      )
        return false;
      return true;
    },
    renderExpandableRow: (rowData, rowMeta) => {
      const colSpan = rowData.length + 1;
      return (
        <TableRow>
          <TableCell colSpan={colSpan}>
            <Row>
              <Col className="text-center">
                <h4>Dados do Cliente</h4>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Endereço:</th>
                      <th>Nº:</th>
                      <th>Bairro:</th>
                      <th>Cidade:</th>
                      <th>Estado:</th>
                      <th>CEP:</th>
                      <th>Celular:</th>
                      <th>Telefone:</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{rowData[8]}</td>
                      <td>{rowData[10]}</td>
                      <td>{rowData[9]}</td>
                      <td>{rowData[14]}</td>
                      <td>{rowData[15]}</td>
                      <td>{rowData[7]}</td>
                      <td>
                        {!rowData[12] ? 'Não Informado' : maskTel(rowData[12])}
                      </td>
                      <td>
                        {!rowData[13]
                          ? 'Não Informado'
                          : maskTelFixo(rowData[13])}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </TableCell>
        </TableRow>
      );
    },
    onRowsDelete: (rowsDeleted, newData) => {
      HandleDelete(rowsDeleted.data);
    },
    searchPlaceholder: 'Pesquise um cliente',
    selectableRows: 'multiple',
    searchOpen: true,

    textLabels: {
      body: {
        noMatch: 'Desculpe, não encontramos clientes!',
        toolTip: 'Sort',
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
      pagination: {
        next: 'Avançar',
        previous: 'Voltar',
        rowsPerPage: 'Linhas por página:',
        displayRows: 'de',
      },
      toolbar: {
        search: 'Pesquisar',
        downloadCsv: 'Baixar CSV',
        print: 'Imprimir',
        viewColumns: 'Ver Colunas',
        filterTable: 'Filtrar tabela',
      },
      filter: {
        all: 'Tudo',
        title: 'FILTROS',
        reset: 'RESETAR',
      },
      viewColumns: {
        title: 'Exibir colunas',
        titleAria: 'Exibir/Ocultar colunas da tabela',
      },
      selectedRows: {
        text: 'Coluna(s) selecionada(s)',
        delete: 'Excluir',
        deleteAria: 'Excluir linhas selecionadas',
      },
    },

    customFooter: (
      count,
      page,
      rowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels,
    ) => {
      let numberPages = clientsList.data?.models?.last_page;
      let rowsPerPageOptions = [15, 30, 100];
      return (
        <CustomFooter
          page={page}
          rowsPerPage={rowsPerPage}
          changeRowsPerPage={changeRowsPerPage}
          changePage={changePage}
          numberPages={numberPages}
          rowsPerPageOptions={rowsPerPageOptions}
        />
      );
    },
  };

  function HandleDelete(e) {
    setLoader(<Loading />);
    let idArr = [];

    e.map((del, key) => {
      idArr.push(
        clientsList.data?.models.data.filter(
          (filtro, index) => index == del.index,
        )[0].id,
      );
    });

    idArr.map((idDel) =>
      api
        .delete(Prefix.replace(/[\\"]/g, '') + '/customers/' + idDel)
        .then((response) => {
          Swal.fire({
            icon: 'success',
            title: 'Uow!',
            text: response.data.message,
            confirmButtonText: 'Fechar',
          });
          setLoader();
          clientsList.mutate();
        })
        .catch((err) => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: err.response.data.message,
            confirmButtonText: 'Tentar novamente',
          });
          setLoader(false);
        }),
    );
  }

  return (
    <div className="wrapper d-flex align-items-stretch">
      <Sidebar />
      <div id="content">
        {loader}
        <Topbar />
        <div className="content_all">
          <CardSt>
            <CardHeaderSt className="d-flex justify-content-between">
              <TitleSt>
                <IconTitleSt
                  style={{ width: '36px' }}
                  src="../../../media/icons-pages/users-page.svg"
                />
                <TextTitleSt>
                  Clientes <SmallTitleSt>Gestão de clientes</SmallTitleSt>
                </TextTitleSt>
              </TitleSt>
              {/* <CustomerUpload /> */}
            </CardHeaderSt>
            <CardSt.Body as="div">
              <CardSt.Text as="div">
                <MuiThemeProvider theme={GetMuiTheme()}>
                  <MUIDataTable
                    data={clientsList.data?.models?.data}
                    columns={columns}
                    options={options}
                  />
                </MuiThemeProvider>
              </CardSt.Text>
            </CardSt.Body>
          </CardSt>
          <Content
            titleLabel="Cadastro de Cliente"
            buttonLabel="Adicionar"
            iconClass="fas fa-plus-circle"
            modalSize="md"
            pageExact={pageExact}
            perPage={perPage}
            search={!search ? '' : search}
            orderBy={orderBy}
            order={order}
          />
        </div>
      </div>
    </div>
  );
};

export default Customer;
