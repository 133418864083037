import React from 'react';
import api from '../../../services/api';
import { Prefix } from '../../../services/prefix';
import { mutate as GlobalMutate } from 'swr';

export default function UpdateStatus({
  id,
  page,
  rowsPage,
  activeDefault,
  search,
  orderBy,
  order
}) {
  let pageExact = page + parseInt(1);
  let valueSearch = !search? '':search;


  function HandleStatus() {
    api
      .put(Prefix.replace(/[\\"]/g, '') + '/schemes/' + id, {
        active:  activeDefault == 1 ? 0 : 1,
      })
      .then(() => {
        GlobalMutate(
          Prefix.replace(/[\\"]/g, "") +
            "/schemes?per_page=" +
            rowsPage +
            "&page=" +
            pageExact +
            "&search=" +
            valueSearch +
            "&order_by=" +
            orderBy +
            "&order=" +
            order
        );
      });
  }

  return (
    <>
      <label className="switch d-flex justify-content-center">
        <input
          type="checkbox"
          id={id + 'schemes'}
          onChange={HandleStatus}
          checked={activeDefault == 1 ? true : false}
        />
        <span className="slider round"></span>
      </label>
    </>
  );
}
