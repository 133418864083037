import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Button, Modal } from "react-bootstrap";
import Fontawesome from "../../fontawesome/fontawesome";
import "../modules.css";
import { Prefix } from "../../../services/prefix";
import Loading from "../../loader/loading";
import Swal from "sweetalert2";
import api from "../../../services/api";
import { Link } from "react-router-dom";
import { useFetch } from "../../hooks/useFetch";
import { mutate as GlobalMutate } from "swr";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Schema from "./schema";
import { ModalContainer } from "../../../style/stylesModals"; 
import { CustomButton, TextButton } from "../../../style/stylesButton";

const IndexEditModules = ({ id, page, rowsPage, search, orderBy, order }) => {
  const [loader, setLoader] = useState();
  const [show, setShow] = useState(false);

  let pageExact = page + parseInt(1);
  let valueSearch = !search ? "" : search;

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const schemeList = useFetch(
    !show ? null : Prefix.replace(/[\\"]/g, "") + "/schemes"
  );
  const modulesList = useFetch(
    !show ? null : Prefix.replace(/[\\"]/g, "") + "/modules/" + id
  );

  const [registerData, setRegisterData] = useState({});

  useEffect(() => {
    if (modulesList?.data) {
      setRegisterData({
        name: modulesList.data?.model?.name,
        active: modulesList.data?.model?.active,
        id_scheme: modulesList.data?.model?.id_scheme,
        relation: modulesList.data?.model?.relation,
      });
    }
  }, [modulesList.data]);

  const HandleChange = (e) => {
    let eventValue = e.target.value;
    let eventName = e.target.name;
    setRegisterData({
      ...registerData,
      [eventName]: eventValue,
    });
  };

  function HandleRegister(registerData) {
    if (registerData.relation == modulesList.data?.model.relation) {
      delete registerData.relation;
    }
    setLoader(true);
    api
      .put(Prefix.replace(/[\\"]/g, "") + "/modules/" + id, registerData)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Uow!",
          text: response.data.message,
          confirmButtonText: "Fechar",
        });
        setShow(false);
        setLoader(false);
        GlobalMutate(
          Prefix.replace(/[\\"]/g, "") +
            "/modules?per_page=" +
            rowsPage +
            "&page=" +
            pageExact +
            "&search=" +
            valueSearch +
            "&order_by=" +
            orderBy +
            "&order=" +
            order
        );
        modulesList.mutate();
      })
      .catch((err) => {
        // setErrorsForm(err.response.data.errors);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: err.response.data.message,
          confirmButtonText: "Tentar novamente",
        });
        setLoader(false);
      });
  }

  return (
    <>
      <Link to="#" onClick={handleShow} className="link_color_datatable">
        <Fontawesome classe="fas fa-pencil-alt" />
      </Link>

      <ModalContainer
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        {loader}
        <Modal.Header closeButton>
          <Modal.Title><h3>Atualização de Módulo</h3></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik //--> Configuração do Formik
            initialValues={registerData}
            validationSchema={Schema}
            onSubmit={HandleRegister}
            enableReinitialize
          >
            {(
              { values } //--> Captação de valores
            ) => (
              <Form>
                <Row>
                  <Col>
                    <div className="formStep">
                      <label class="important">Módulo</label>
                      <Field
                        type="text"
                        name="name"
                        defaultValue={values.name}
                        value={registerData?.name}
                        onChange={HandleChange}
                      />
                      <ErrorMessage
                        name="name"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="formStep">
                      <label class="important">Licença</label>
                      <Field
                        as="select"
                        type="text"
                        placeholder="Escolha uma licença"
                        name="id_scheme"
                        defaultValue={values?.id_scheme}
                        onChange={HandleChange}
                      >
                        <option selected disabled>
                          Selecione uma licença...
                        </option>
                        {schemeList.data?.models?.data.map((value) => (
                          <>
                            <option key={value.id} value={value.id}>
                              {value.name}
                            </option>
                          </>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="id_scheme"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="formStep">
                      <label class="important">Relacionamento</label>
                      <Field
                        type="text"
                        name="relation"
                        defaultValue={values?.relation}
                        value={registerData?.relation}
                        onChange={HandleChange}
                      />
                      <ErrorMessage
                        name="relation"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className="d-flex justify-content-center">
                  <CustomButton type="submit">
                    <TextButton>Atualizar</TextButton>  
                    </CustomButton>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </ModalContainer>
    </>
  );
};
export default IndexEditModules;
