import React, { useState } from 'react';
import { Row, Col, Card, Table } from 'react-bootstrap';
import MUIDataTable from 'mui-datatables';
import { useFetch } from '../../components/hooks/useFetch';
import { Prefix } from '../../services/prefix';
import api from '../../services/api';
import Swal from 'sweetalert2';
import { columns } from './columnsOs';
import Loading from '../../components/loader/loading';
import CustomSearchRender from '../../components/customSearchRender/CustomSearchRender';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Fontawesome from '../../components/fontawesome/fontawesome';
import Sidebar from '../../components/sidebar/sidebar';
import Topbar from '../../components/topbar/topbar';
import CustomFooter from '../../components/CustomFooter/CustomFooter';
import AddOs from '../../components/os/addOs/addOs';

const ServiceOrder = () => {
  const [pagina, setPagina] = useState(0);
  const [perPage, setPerPage] = useState(15);
  const [search, setSearch] = useState('');
  const [loader, setLoader] = useState();

  let pageExact = pagina + parseInt(1);

  const osList = useFetch(
    Prefix.replace(/[\\"]/g, '') +
      '/os?all=false&per_page=' +
      perPage +
      '&page=' +
      pageExact +
      '&search=' +
      search,
  );

  const options = {
    filterType: 'checkbox',
    draggableColumns: {
      enabled: true,
    },
    filter: false,
    serverSide: true,
    onTableChange: (action, tableState) => {
      setPagina(tableState.page);
      setPerPage(tableState.rowsPerPage);
    },
    onSearchChange: (searchText) => {
      setSearch(!searchText ? '' : searchText);
    },
    customSearchRender: (searchText, handleSearch, hideSearch, options) => {
      return (
        <CustomSearchRender
          placeholderSearch="Pesquisar"
          searchText={searchText}
          onSearch={handleSearch}
          onHide={hideSearch}
          options={options}
        />
      );
    },
    filter: false,
    page: pagina,
    count: osList.data?.models.total,
    expandableRows: false,
    expandableRowsHeader: false,
    expandableRowsOnClick: false,
    rowsPerPage: perPage,
    rowsPerPageOptions: [15, 30, 100],

    renderExpandableRow: (rowData, rowMeta) => {
      const colSpan = rowData.length + 1;
      return (
        <TableRow>
          <TableCell colSpan={colSpan}>
            <Row>
              <Col className="text-center">
                <h4>Dados da OS</h4>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Serviço:</th>
                      <th>Agendamento:</th>
                      <th>Execução:</th>
                      <th>Urgência:</th>
                      <th>Realizado:</th>
                      <th>Tec. Terceirizado:</th>
                      <th>Empresa Terceirizada:</th>
                      <th>Técnico:</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{rowData[2]}</td>
                      <td>{rowData[3]}</td>
                      <td>{!rowData[4] ? 'Não executado' : rowData[4]}</td>
                      <td>{rowData[6]}</td>
                      <td>{rowData[11] == 1 ? 'Sim' : 'Não'}</td>
                      <td>{!rowData[13] ? 'Não informado' : rowData[13]}</td>
                      <td>{!rowData[12] ? 'Não informado' : rowData[12]}</td>
                      <td>{!rowData[13] ? 'Não Informado' : rowData[13]}</td>
                    </tr>
                    <tr>
                      <th colspan="4">Descrição</th>
                      <th colspan="4">Observação</th>
                    </tr>
                    <tr>
                      <td colspan="4">{rowData[5]}</td>
                      <td colspan="4">{rowData[7]}</td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </TableCell>
        </TableRow>
      );
    },
    onRowsDelete: (rowsDeleted, newData) => {
      HandleDelete(rowsDeleted.data);
    },
    searchPlaceholder: 'Pesquisar',
    selectableRows: 'multiple',
    searchOpen: true,
    textLabels: {
      body: {
        noMatch: 'Desculpe, não encontramos dados!',
        toolTip: 'Sort',
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
      pagination: {
        next: 'Avançar',
        previous: 'Voltar',
        rowsPerPage: 'Linhas por página:',
        displayRows: 'de',
      },

      toolbar: {
        search: 'Pesquisar',
        downloadCsv: 'Baixar CSV',
        print: 'Imprimir',
        viewColumns: 'Ver Colunas',
        filterTable: 'Filtrar tabela',
      },
      filter: {
        all: 'Tudo',
        title: 'FILTROS',
        reset: 'RESETAR',
      },
      viewColumns: {
        title: 'Exibir colunas',
        titleAria: 'Exibir/Ocultar colunas da tabela',
      },
      selectedRows: {
        text: 'Coluna(s) selecionada(s)',
        delete: 'Excluir',
        deleteAria: 'Excluir linhas selecionadas',
      },
    },
    customFooter: (
      count,
      page,
      rowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels,
    ) => {
      let numberPages = osList.data?.models.last_page;
      let rowsPerPageOptions = [15, 30, 100];
      return (
        <CustomFooter
          page={page}
          rowsPerPage={rowsPerPage}
          changeRowsPerPage={changeRowsPerPage}
          changePage={changePage}
          numberPages={numberPages}
          rowsPerPageOptions={rowsPerPageOptions}
        />
      );
    },
  };

  function HandleDelete(e) {
    setLoader(<Loading />);
    let listAttachFilter = osList.data?.models.data;
    let idArr = [];
    e.map((del) => {
      idArr.push(
        listAttachFilter.filter((filtro, index) => index == del.index)[0].id,
      );
    });

    idArr.map(
      (
        idDel, //Faz exclusão com map um a um para evitar bugs
      ) =>
        api
          .delete(Prefix.replace(/[\\"]/g, '') + '/os/' + idDel)
          .then((response) => {
            Swal.fire({
              icon: 'success',
              title: 'Uow!',
              text: response.data.message,
              confirmButtonText: 'Fechar',
            });
            setLoader();
            osList.mutate();
          })
          .catch((err) => {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: err.response.data.message,
              confirmButtonText: 'Tentar novamente',
            });
            setLoader();
          }),
    );
  }

  return (
    <>
      <div className="wrapper d-flex align-items-stretch">
        <Sidebar />
        {loader}
        <div id="content">
          <Topbar />
          <div className="content_all">
            <Card>
              <Card.Header as="h5">
                <h3 class="card-label header-card">
                  <span>
                    <Fontawesome classe="far fa-calendar-check mr-3" />
                    Ordens de serviço <small>Gestão de ordens de serviço</small>
                  </span>
                  <Row>
                    <Col sm="p-0 px-2 mt-2 mt-lg-0">
                      <AddOs
                        titleLabel="Adicionar OS"
                        buttonLabel="Adicionar"
                        iconClass="fas fa-plus-circle"
                        pageExact={pageExact}
                        perPage={perPage}
                        search={search}
                      />
                    </Col>
                  </Row>
                </h3>
              </Card.Header>
              <Card.Body>
                <div className="customerStep">
                  {loader}

                  <Row>
                    <Col>
                      <MUIDataTable
                        data={osList.data?.models.data}
                        columns={columns}
                        options={options}
                      />
                    </Col>
                  </Row>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};
export default ServiceOrder;
