import React from 'react';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import './style.css';

export const GetMuiTheme = () =>
  createMuiTheme({
    overrides: {
      MuiTableCell: {
        head: {
          backgroundColor: '#F8F8FF !important',
        },
        root: {
          fontFamily: 'Inter',
        },

        footer: {
          backgroundColor: '#F8F8FF !important',
        },
      },
    },
  });
