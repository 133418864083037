import React, { useContext, useState } from 'react';
import './style.css';
import Checkbox from '@material-ui/core/Checkbox';
import { Context } from '../../../services/context';
import Fontawesome from '../../fontawesome/fontawesome';

export const Login = (props) => {
  const ctx = useContext(Context);

  const [view, setView] = useState(false);

  const Eye = () => {
    function showPassword() {
      setView(() => !view);
    }
    return (
      <div onClick={showPassword}>
        <Fontawesome classe={view ? 'fas fa-eye' : ' fas fa-eye-slash'} />
      </div>
    );
  };

  return (
    <div className="container_login">
      <form className="col-12 flip-in-hor-bottom mt_form">
        <div className="col-12 text-center">
          <p className="mt_login_title">Acesso ao Painel</p>
        </div>
        <div className="input-group">
          <input
            className="form-control"
            type="text"
            placeholder="E-mail"
            name="email"
            autocomplete="off"
            value={ctx.showAccess().email}
            onChange={(e) => ctx.handleEmail(e.target.value)}
          />
        </div>
        <div className="input_password">
          <div className="input-group">
            <input
              className="form-control"
              type={view ? 'text' : 'password'}
              placeholder="Senha"
              name="senha"
              autocomplete="off"
              value={ctx.showAccess().password}
              onChange={(e) => ctx.handlePassword(e.target.value)}
            />
          </div>
          <div>
            <Eye />
          </div>
        </div>
        <div className="row mt_login_option">
          <div className="col-12 col-12 col-md-6 mt_option">
            <label>
              <Checkbox
                color="primary"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
                onChange={(e) => {
                  ctx.setRememberPassword(e.target.checked);
                }}
                checked={
                  ctx.showAccess().rememberPassword == true ? true : undefined
                }
              />
              Lembrar acesso
            </label>
          </div>
          <div className="col-12 col-12 col-md-6 text-right mt_option mt_login_forgot">
            <a href="javascript:;" onClick={props.functionPassword}>
              Esqueceu sua senha?
            </a>
          </div>
        </div>
        <div className="mt_actions">
          {/*<button className="mt_button">Acessar</button>*/}
          <button className="mt_button" onClick={ctx.handleLogin}>
            Acessar
          </button>
        </div>
        <div class="row mt_conta">
          <span class="mt_message">Ainda não tem uma conta ?</span>
          &nbsp;&nbsp;
          <a
            href="javascript:;"
            onClick={props.functionRegister}
            class="mt_require_register"
          >
            Solicite um cadastro
          </a>
        </div>
      </form>
    </div>
  );
};

export default Login;
