import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
import Sidebar from "../../components/sidebar/sidebar";
import "./equipamentsCategory.css";
import Topbar from "../../components/topbar/topbar";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { columns } from "./columns.jsx";
import Content from "../../components/equipaments_category/addEquipamentsCategory/addEquipamentsCategory";
import MUIDataTable from "mui-datatables";
import Fontawesome from "../../components/fontawesome/fontawesome";
import { useFetch } from "../../components/hooks/useFetch";
import { Prefix } from "../../services/prefix";
import Loading from "../../components/loader/loading";
import api from "../../services/api";
import Swal from "sweetalert2";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import CustomSearchRender from "../../components/customSearchRender/CustomSearchRender";
import CustomFooter from "../../components/CustomFooter/CustomFooter";
import {
  CardSt,
  CardHeaderSt,
  IconTitleSt,
  TitleSt,
  TextTitleSt,
  SmallTitleSt,
} from "../../style/globalStyle";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { GetMuiTheme } from "../../components/muiTheme/muiTheme";
import { mutate as GlobalMutate } from "swr";

export const EquipamentsCategory = () => {
  const [loader, setLoader] = useState();
  const [pagina, setPagina] = useState(0);
  const [perPage, setPerPage] = useState(15);
  const [search, setSearch] = useState("");
  const [orderBy, setOrderBy] = useState("id");
  const [order, setOrder] = useState("desc");

  let pageExact = pagina + parseInt(1);

  const equipamentsCategoryList = useFetch(
    Prefix.replace(/[\\"]/g, "") +
      "/equipment-categories?per_page=" +
      perPage +
      "&page=" +
      pageExact +
      "&search=" +
      search +
      "&order_by=" +
      orderBy +
      "&order=" +
      order
  );

  const options = {
    filterType: "checkbox",
    draggableColumns: {
      enabled: true,
    },
    sortOrder: {
      name: orderBy,
      direction: order,
    },
    filter: false,

    serverSide: true,
    onTableChange: (action, tableState) => {
      setOrderBy(
        !tableState.sortOrder.name ? orderBy : tableState.sortOrder.name
      );
      setOrder(
        !tableState.sortOrder.direction ? order : tableState.sortOrder.direction
      );
      setPagina(tableState.page);
      setPerPage(tableState.rowsPerPage);
    },
    onSearchChange: (searchText) => {
      setSearch(!searchText ? "" : searchText);
    },
    customSearchRender: (searchText, handleSearch, hideSearch, options) => {
      return (
        <CustomSearchRender
          placeholderSearch="Pesquisar categoria de equipamento"
          searchText={searchText}
          onSearch={handleSearch}
          onHide={hideSearch}
          options={options}
        />
      );
    },
    filter: false,
    page: pagina,
    count: equipamentsCategoryList.data?.models?.total,
    expandableRows: false,
    expandableRowsHeader: false,
    expandableRowsOnClick: false,
    rowsPerPage: perPage,
    rowsPerPageOptions: [15, 30, 100],
    isRowExpandable: (dataIndex, expandedRows) => {
      //if (dataIndex === 3 || dataIndex === 4) return false;
      if (
        expandedRows.data.length > 4 &&
        expandedRows.data.filter((d) => d.dataIndex === dataIndex).length === 0
      )
        return false;
      return true;
    },
    renderExpandableRow: (rowData, rowMeta) => {
      const colSpan = rowData.length + 1;
      return (
        <TableRow>
          <TableCell colSpan={colSpan}>
            <Row>
              <Col className="text-center"></Col>
            </Row>
          </TableCell>
        </TableRow>
      );
    },

    onRowsDelete: (rowsDeleted, newData) => {
      HandleDelete(rowsDeleted.data);
    },

    searchPlaceholder: "Pesquise um categoria de equipamento",
    selectableRows: "multiple",

    searchOpen: true,

    textLabels: {
      body: {
        noMatch: "Desculpe, não encontramos categorias de equipamento!",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
      pagination: {
        next: "Avançar",
        previous: "Voltar",
        rowsPerPage: "Linhas por página:",
        displayRows: "de",
      },

      toolbar: {
        search: "Pesquisar",
        downloadCsv: "Baixar CSV",
        print: "Imprimir",
        viewColumns: "Ver Colunas",
        filterTable: "Filtrar tabela",
      },
      filter: {
        all: "Tudo",
        title: "FILTROS",
        reset: "RESETAR",
      },
      viewColumns: {
        title: "Exibir colunas",
        titleAria: "Exibir/Ocultar colunas da tabela",
      },
      selectedRows: {
        text: "Coluna(s) selecionada(s)",
        delete: "Excluir",
        deleteAria: "Excluir linhas selecionadas",
      },
    },
    customFooter: (
      count,
      page,
      rowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels
    ) => {
      let numberPages = equipamentsCategoryList.data?.models?.last_page;
      let rowsPerPageOptions = [15, 30, 100];
      return (
        <CustomFooter
          page={page}
          rowsPerPage={rowsPerPage}
          changeRowsPerPage={changeRowsPerPage}
          changePage={changePage}
          numberPages={numberPages}
          rowsPerPageOptions={rowsPerPageOptions}
        />
      );
    },
  };
  function HandleDelete(e) {
    let idArr = [];

    e.map((del) => {
      idArr.push(
        equipamentsCategoryList.data?.models?.data.filter(
          (filtro, index) => index === del.index
        )[0].id
      );
    });

    idArr.map((idDel) =>
      api
        .delete(Prefix.replace(/[\\"]/g, "") + "/equipment-categories/" + idDel)
        .then((response) => {
          Swal.fire({
            icon: "success",
            title: "Uow!",
            text: response.data.message,
            confirmButtonText: "Fechar",
          });
          GlobalMutate(
            Prefix.replace(/[\\"]/g, "") +
              "/equipment-categories?per_page=" +
              perPage +
              "&page=" +
              pageExact +
              "&search=" +
              search +
              "&order_by=" +
              orderBy +
              "&order=" +
              order
          );
          equipamentsCategoryList.mutate();
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: err.response.data.message,
            confirmButtonText: "Tentar novamente",
          });
          setLoader(false);
        })
    );
  }

  return (
    <div className="wrapper d-flex align-items-stretch">
      <Sidebar />
      <div id="content">
        {loader}
        <Topbar />
        <div className="content_all">
          <CardSt>
            <CardHeaderSt className="d-flex justify-content-between">
              <TitleSt>
                <IconTitleSt
                  style={{ width: "36px" }}
                  src="../../../media/icons-pages/category-page.svg"
                />
                <TextTitleSt>
                  Categorias de Equipamentos{" "}
                  <SmallTitleSt>
                    Gestão de Categorias de Equipamentos
                  </SmallTitleSt>
                </TextTitleSt>
              </TitleSt>
            </CardHeaderSt>
            <CardSt.Body as="div">
              <CardSt.Text as="div">
                <MuiThemeProvider theme={GetMuiTheme()}>
                  <MUIDataTable
                    data={equipamentsCategoryList.data?.models?.data}
                    columns={columns}
                    options={options}
                  />
                </MuiThemeProvider>
              </CardSt.Text>
            </CardSt.Body>
          </CardSt>
          <Content
            titleLabel="Adicionar Categoria"
            buttonLabel="Adicionar"
            iconClass="fas fa-plus-circle"
            pageExact={pageExact}
            perPage={perPage}
            search={search}
            orderBy={orderBy}
            order={order}
          />
        </div>
      </div>
    </div>
  );
};

export default EquipamentsCategory;
