import React from 'react';

export const ColumnsCommentsOs = [
  {
    name: 'id',
    label: 'id',
    options: {
      filter: false,
      sort: false,
      display: 'excluded',
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      customBodyRender: (value, tableMeta) => {
        return value;
      },
    },
  },
  {
    name: 'user',
    label: 'Usuário',
    options: {
      filter: false,
      sort: false,
      display: true,
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      customBodyRender: (value, tableMeta) => {
        return value.name;
      },
    },
  },
  {
    name: 'message',
    label: 'Comentário',
    options: {
      filter: false,
      sort: false,
      display: true,
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      customBodyRender: (value, tableMeta) => {
        return value;
      },
    },
  },
];
