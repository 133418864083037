import React from 'react';
import api from '../../../services/api';
import { Prefix } from '../../../services/prefix';
import { mutate as globalMutate } from 'swr';

export default function UpdateStatus({ id, page, rowsPage, activeDefault, orderBy, order, search }) {
  let pageExact = page + parseInt(1);
  let valueSearch = !search? '':search;

  function HandleStatus() {
    api
      .put(Prefix.replace(/[\\"]/g, '') + '/contract-types/' + id, {
        active: activeDefault == true ? false : true
      })
      .then(() => {
        globalMutate(
          Prefix.replace(/[\\"]/g, "") +
          "/contract-types?per_page=" +
          rowsPage +
          "&page=" +
          pageExact +
          "&search=" +
          valueSearch +
          "&order_by=" +
          orderBy +
          "&order=" +
          order
        );
      });
  }

  return (
    <>
      <label class="switch">
        <input
          type="checkbox"
          id={id + 'contractType'}
          onChange={HandleStatus}
          checked={activeDefault}
        />
        <span class="slider round"></span>
      </label>
    </>
  );
}
