import styled from 'styled-components';

export const BoxVerify = styled.div`
  width: 100%;
  height: 30px;
  background: ${(props) => props.bgColor};
  color: ${(props) => (props.bgColor == '#1bc5bd' ? '#fff' : '#000')};
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
