import React, { useState } from 'react';
import { Link } from 'react-router-dom';
// import Info from './options/info/info';
import Equipments from './options/equipments/equipments';
import Comments from './options/comments/comments';
import Attachments from './options/attachments/attachments';
import DownloadPDF from './options/downloadPDF';
import Duplicate from './options/duplicate';
import SendProposal from './options/sendProposal';
import Feedback from './options/feedback/feedback';
import SeeProposal from './options/seeProposal/seeProposal';

const OptionsProposals = ({ id, count, idRequest }) => {

  return (
    <>
      <div className="dropdown drop-bases">
        <Link
          to="#"
          className="dropdown link_color_datatable"
          id="dropdownNotification"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <img src={`/media/icons/elipse_options.svg`} width="5" alt="Opções" />
        </Link>
        <ul
          className="dropdown-menu fade-in-top drop-options "
          aria-labelledby="dropdownNotification"
        >
          {/* <li>
            <Info id={idProposal} />
          </li> */}
          <li>
            <Equipments id={id} />
          </li>
          <li>
            <Comments id={id} />
          </li>
          <li>
            <Attachments id={id} />
          </li>
          <li>
            <DownloadPDF id={id} />
          </li>
          <li>
            <Duplicate id={id} />
          </li>
          <li>
            <SendProposal id={id} />
          </li>
          <li>
            <Feedback id={id} />
          </li>
          <li>
            <SeeProposal id={id} count={count} idRequest={idRequest}/>
          </li>
        </ul>
      </div>
    </>
  );
};
export default OptionsProposals;
