import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Button, Modal, Alert } from "react-bootstrap";
import "../room.css";
import api from "../../../services/api";
import Fontawesome from "../../fontawesome/fontawesome";
import { Prefix } from "../../../services/prefix";
import Loading from "../../loader/loading";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { useFetch } from "../../hooks/useFetch";
import { mutate as GlobalMutate } from "swr";
import { Context } from "../../../services/context";
import { AsyncPaginate, component } from "react-select-async-paginate";
import { NoOptionsMessage } from "../../selectAsync/NoOptionsMessage";
import { colourStyles } from "../../selectAsync/colourStyles";
import { loadOptions } from "../../selectAsync/loadOptions";
import Select, { components } from "react-select";
import { Formik, Form, FieldArray, ErrorMessage, Field } from "formik";
import { Schema } from "./schema";
import { ModalContainer } from "../../../style/stylesModals"; 
import { CustomButton, TextButton } from "../../../style/stylesButton";

const IndexEditRoom = ({ id, idRoom, page, rowsPage, orderBy, order }) => {
  const {
    selectclient,
    selectunit,
    selectsector,
    setSelectClient,
    setSelectUnit,
    setSelectSector,
  } = useContext(Context);

  const [loader, setLoader] = useState(); /* Carrega o Loader */
  const [idUnit, setIdUnit] = useState();
  const [show, setShow] = useState(false); /* Habilita ou desabilita o modal */
  const [registerData, setRegisterData] = useState({});
  const [valueSelect, setValueSelect] = useState([]);

  let pageExact = page + parseInt(1);

  const handleShow = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };

  const roomList = useFetch(
    !show ? null : Prefix.replace(/[\\"]/g, "") + "/rooms/" + id
  );

  useEffect(() => {
    if (roomList.data) {
      setRegisterData({
        initialData: [
          {
            name: roomList.data?.model?.name,
            active: "1",
            id_sector: roomList.data?.model?.id_sector,
            id_unit: roomList.data?.model?.sector?.unit?.id,
          },
        ],
      });
    }
  }, [show, roomList.data]);

  const HandleSelect = (e, name, key) => {
    if (
      valueSelect.filter((fill) => fill.key == key && fill.nameField == name)
        .length > 0
    ) {
      valueSelect
        .filter((fill) => fill.key == key && fill.nameField == name)
        .map((map) => {
          let position = valueSelect.indexOf(map);
          valueSelect.splice(position, 1);
        });
      setValueSelect(() => {
        return [
          ...valueSelect,
          { value: e.value, label: e.label, nameField: name, key: key },
        ];
      });
    } else {
      setValueSelect((prev) => {
        return [
          ...prev,
          { value: e.value, label: e.label, nameField: name, key: key },
        ];
      });
    }
  };

  function HandleRegister(values) {
    values.initialData.map((map) => {
      setLoader(true);
      api
        .put(Prefix.replace(/[\\"]/g, "") + "/rooms/" + id, {
          name: map.name,
          active: "1",
          id_sector: map.id_sector,
        })
        .then((response) => {
          Swal.fire({
            icon: "success",
            title: "Uow!",
            text: response.data.message,
            confirmButtonText: "Fechar",
          });
          setLoader();
          setShow(false);
          GlobalMutate(
            Prefix.replace(/[\\"]/g, "") +
              "/rooms?per_page=" +
              rowsPage +
              "&page=" +
              pageExact +
              "&id_customer=" +
              selectclient +
              "&id_unit=" +
              selectunit +
              "&id_sector=" +
              selectsector +
              "&order_by=" +
              orderBy +
              "&order=" +
              order
          );
          roomList.mutate();
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: err.response.data.message,
            confirmButtonText: "Tentar novamente",
          });
          setLoader();
        });
    });
  }

  return (
    <>
      <Link to="#" onClick={handleShow} className="link_color_datatable">
        <Fontawesome classe="fas fa-pencil-alt" />
      </Link>
      <ModalContainer
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title><h3>Atualização de salas</h3></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik //--> Configuração do Formik
            initialValues={registerData}
            validationSchema={Schema}
            onSubmit={HandleRegister}
            enableReinitialize
          >
            {({ values, handleChange, setFieldValue }) => (
              <Form>
                <Alert variant="success">
                  <FieldArray name="initialData">
                    {({ push, remove }) => (
                      <div>
                        {values?.initialData?.map((p, index) => {
                          return (
                            <>
                              <Row>
                                <Col>
                                  <div className="formStep">
                                    <label class="important">Unidade</label>
                                    <AsyncPaginate
                                      value={
                                        valueSelect.length == 0
                                          ? {
                                              value:
                                                roomList.data?.model?.sector
                                                  ?.unit?.id,
                                              label:
                                                roomList.data?.model?.sector
                                                  ?.unit?.name,
                                            }
                                          : valueSelect
                                              .filter(
                                                (fill) =>
                                                  fill.nameField == "id_unit" &&
                                                  fill.key == "id_unit"
                                              )
                                              .map((map) => {
                                                return {
                                                  value: map.value,
                                                  label: map.label,
                                                };
                                              })
                                      }
                                      loadOptions={loadOptions}
                                      onChange={(e) => {
                                        HandleSelect(e, "id_unit", "id_unit");
                                        setFieldValue(
                                          `initialData[${index}].id_unit`,
                                          e.value
                                        );
                                      }} //values and name of select paginate
                                      placeholder="Selecione uma cidade..."
                                      components={{ NoOptionsMessage }}
                                      cacheUniqs={[valueSelect]} //Set your param that is going to change when you select another field. Just use this option when you are setting params and need to refresh cache.
                                      styles={colourStyles}
                                      additional={{
                                        page: 1,
                                        endpoint: "/units",
                                      }}
                                    />
                                    <ErrorMessage
                                      name={`initialData[${index}].id_unit`}
                                      component="span"
                                      className="errorMessages"
                                    />
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col>
                                  <div className="formStep">
                                    <label class="important">Setor</label>
                                    <AsyncPaginate
                                      value={
                                        valueSelect.length == 0
                                          ? {
                                              value:
                                                roomList.data?.model?.sector
                                                  ?.id,
                                              label:
                                                roomList.data?.model?.sector
                                                  ?.name,
                                            }
                                          : valueSelect
                                              .filter(
                                                (fill) =>
                                                  fill.nameField ==
                                                    "id_sector" &&
                                                  fill.key == "id_sector"
                                              )
                                              .map((map) => {
                                                return {
                                                  value: map.value,
                                                  label: map.label,
                                                };
                                              })
                                      }
                                      loadOptions={loadOptions}
                                      onChange={(e) => {
                                        HandleSelect(
                                          e,
                                          "id_sector",
                                          "id_sector"
                                        );
                                        setFieldValue(
                                          `initialData[${index}].id_sector`,
                                          e.value
                                        );
                                      }} //values and name of select paginate
                                      placeholder="Selecione uma cidade..."
                                      components={{ NoOptionsMessage }}
                                      cacheUniqs={[
                                        valueSelect.length == 0
                                          ? ""
                                          : valueSelect
                                              .filter(
                                                (fill) =>
                                                  fill.nameField == "id_unit"
                                              )
                                              .map((map) => map.value)[0],
                                      ]} //Set your param that is going to change when you select another field. Just use this option when you are setting params and need to refresh cache.
                                      styles={colourStyles}
                                      additional={{
                                        page: 1,
                                        endpoint: "/sectors",
                                        params: `id_unit=${
                                          valueSelect.length == 0
                                            ? values.initialData[0].id_unit
                                            : valueSelect
                                                ?.filter(
                                                  (fill) =>
                                                    fill.nameField == "id_unit"
                                                )
                                                .map((map) => map.value)
                                        }`,
                                      }}
                                    />
                                    <ErrorMessage
                                      name={`initialData[${index}].id_sector`}
                                      component="span"
                                      className="errorMessages"
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <div className="formStep">
                                    <label class="important">Sala</label>
                                    <Field
                                      type="text"
                                      placeholder="Sala"
                                      name={`initialData[${index}].name`}
                                      onChange={handleChange}
                                    />
                                    <ErrorMessage
                                      name={`initialData[${index}].name`}
                                      component="span"
                                      className="errorMessages"
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </>
                          );
                        })}
                        <div>
                          <Row>
                            <Col className="d-flex justify-content-center ">
                              <CustomButton
                                type="submit"
                              >
                                <TextButton>Atualizar</TextButton>
                              </CustomButton>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    )}
                  </FieldArray>
                </Alert>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </ModalContainer>
    </>
  );
};
export default IndexEditRoom;
