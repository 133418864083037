import React from "react";
import IndexEditSchemes from "../../components/schemes/editschemes/indexEditSchemes";
import UpdateStatus from "../../components/schemes/updateStatus/updateStatus";

export const columns = [
  {
    name: "id",
    label: "",
    options: {
      setCellHeaderProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      setCellProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      filter: false,
      sort: false,
      display: "false",
    },
  },
  {
    name: "name",
    label: "Nome da Licença",
    options: {
      setCellHeaderProps: () => ({
        style: {
          display: "flex",
          justifyContent: "center",
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align':'center',
        },
      }),
      filter: false,
      sort: true,
    },
  },

  {
    name: "active",
    label: "Status",
    options: {
      print: false,
      setCellHeaderProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      setCellProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      setCellHeaderProps: () => ({
        style: {
          width: "10%",
        },
      }),
      filter: true,
      sort: false,
      customBodyRender: (value, tableMeta) => {
        return (
          <UpdateStatus
            activeDefault={value}
            id={tableMeta.rowData[0]}
            page={tableMeta.tableState.page}
            rowsPage={tableMeta.tableState.rowsPerPage}
            orderBy={tableMeta.tableState.sortOrder.name}
            order={tableMeta.tableState.sortOrder.direction}
            search={tableMeta.tableState.searchText}
          />
        );
      },
    },
  },

  {
    name: "id",
    label: "Editar",
    options: {
      setCellHeaderProps: () => ({
        style: {
          "text-align": "center",
          width: "10%",
        },
      }),
      setCellProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      filter: false,
      sort: false,
      print: false,

      customBodyRender: (value, tableMeta) => {
        return (
          <IndexEditSchemes
            id={value}
            page={tableMeta.tableState.page}
            rowsPage={tableMeta.tableState.rowsPerPage}
            search={tableMeta.tableState.searchText}
            orderBy={tableMeta.tableState.sortOrder.name}
            order={tableMeta.tableState.sortOrder.direction}
          />
        );
      },
    },
  },
];
