import React, { useState, useContext } from 'react';
import './employers_clients.css';
import { columns } from './columns.jsx';
import MUIDataTable from 'mui-datatables';
import CustomSearchRender from '../customSearchRender/CustomSearchRender';
import Fontawesome from '../../components/fontawesome/fontawesome';
import { Prefix } from '../../services/prefix';
import { useFetch } from '../../components/hooks/useFetch';
import Loading from '../../components/loader/loading';
import api from '../../services/api';
import Swal from 'sweetalert2';
import CustomFooter from '../CustomFooter/CustomFooter';
import { Row, Col, Table } from 'react-bootstrap';
import { TableRow, TableCell } from '@material-ui/core';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Schema from './schema';
import Select from 'react-select';
import { loadOptions } from '../selectAsync/loadOptions';
import { colourStyles } from '../selectAsync/colourStyles';
import { NoOptionsMessage } from '../selectAsync/NoOptionsMessage';
import { AsyncPaginate } from 'react-select-async-paginate';
import { uf } from '../data/uf';
import { Context } from '../../services/context';
import { mutate as GlobalMutate } from 'swr';
import EquipmentView from './equipmentView/equipmentView';
import TelegramView from './telegramView/telegramView';
import LinkTelegram from './telegramView/linkTelegram/linkTelegram';
import { Button, BackButton } from './employeeStyle';

export const IndexEmployers = ({ id }) => {
  const { employeeView, setEmployeeView } = useContext(Context);
  const [loader, setLoader] = useState();
  const [pagina, setPagina] = useState(0);
  const [perPage, setPerPage] = useState(15);
  const [search, setSearch] = useState('');
  const [initialState, setInitialState] = useState({
    active: 1,
    id_customer: id,
    name: '',
    email: '',
    password: '',
    password_confirmation: '',
    technician: null,
    id_city: null,
  });
  const [registerData, setRegisterData] = useState(initialState);
  const [valueSelect, setValueSelect] = useState([]);
  const [orderBy, setOrderBy] = useState('id');
  const [order, setOrder] = useState('desc');

  let pageExact = pagina + parseInt(1);

  const tech = [
    { value: 0, name: 'Não é técnico' },
    { value: 1, name: 'É técnico' },
  ];

  function HandleAddEmployer() {
    setEmployeeView({ ...employeeView, addEmployees: true, employees: false });
  }

  function HandleBackEmployer() {
    setEmployeeView({ ...employeeView, addEmployees: false, employees: true });
  }

  const employeeClientList = useFetch(
    Prefix.replace(/[\\"]/g, '') +
      '/customer-employees?per_page=' +
      perPage +
      '&page=' +
      pageExact +
      '&search=' +
      search +
      '&id_customer=' +
      id +
      '&order_by=' +
      orderBy +
      '&order=' +
      order,
  );

  const options = {
    draggableColumns: {
      enabled: true,
    },
    serverSide: true,
    sortOrder: {
      name: orderBy,
      direction: order,
    },
    onTableChange: (action, tableState) => {
      setOrderBy(
        !tableState.sortOrder.name ? orderBy : tableState.sortOrder.name,
      );
      setOrder(
        !tableState.sortOrder.direction
          ? order
          : tableState.sortOrder.direction,
      );
      setPagina(tableState.page);
      setPerPage(tableState.rowsPerPage);
    },
    onSearchChange: (searchText) => {
      setSearch(!searchText ? '' : searchText);
    },
    customSearchRender: (searchText, handleSearch, hideSearch, options) => {
      return (
        <CustomSearchRender
          placeholderSearch="Pesquisar funcionário"
          searchText={searchText}
          onSearch={handleSearch}
          onHide={hideSearch}
          options={options}
        />
      );
    },
    selectableRows: true,
    filter: false,
    page: pagina,
    count: employeeClientList.data?.models?.total,
    expandableRows: true,
    expandableRowsHeader: false,
    expandableRowsOnClick: false,
    rowsPerPage: perPage,
    rowsPerPageOptions: [15, 30, 100],
    isRowExpandable: (dataIndex, expandedRows) => {
      //if (dataIndex === 3 || dataIndex === 4) return false;
      if (
        expandedRows.data.length > 4 &&
        expandedRows.data.filter((d) => d.dataIndex === dataIndex).length === 0
      )
        return false;
      return true;
    },
    renderExpandableRow: (rowData, rowMeta) => {
      const colSpan = rowData.length + 1;
      return (
        <TableRow>
          <TableCell colSpan={colSpan}>
            <Row>
              <Col className="text-center">
                <h4>Dados do Funcionário</h4>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Email:</th>
                      <th>Tipo:</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td> {!rowData[4] ? 'Não informado' : rowData[4]}</td>
                      <td>
                        {' '}
                        {rowData[5] !== 1
                          ? 'Não é técnico'
                          : 'Técnico do Cliente'}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </TableCell>
        </TableRow>
      );
    },
    onRowsDelete: (rowsDeleted, newData) => {
      HandleDelete(rowsDeleted.data);
    },
    searchPlaceholder: 'Pesquise um funcionário',

    searchOpen: true,
    body: {
      noMatch: 'Desculpe, não encontramos funcionários!',
      toolTip: 'Sort',
      columnHeaderTooltip: (column) => `Sort for ${column.label}`,
    },
    textLabels: {
      pagination: {
        next: 'Avançar',
        previous: 'Voltar',
        rowsPerPage: 'Linhas por página:',
        displayRows: 'de',
      },
      toolbar: {
        search: 'Pesquisar',
        downloadCsv: 'Baixar CSV',
        print: 'Imprimir',
        viewColumns: 'Ver Colunas',
        filterTable: 'Filtrar tabela',
      },
      filter: {
        all: 'Tudo',
        title: 'FILTROS',
        reset: 'RESETAR',
      },
      viewColumns: {
        title: 'Exibir colunas',
        titleAria: 'Exibir/Ocultar colunas da tabela',
      },
      selectedRows: {
        text: 'Coluna(s) selecionada(s)',
        delete: 'Excluir',
        deleteAria: 'Excluir linhas selecionadas',
      },
    },
    customFooter: (
      count,
      page,
      rowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels,
    ) => {
      let numberPages = employeeClientList.data?.models?.last_page;
      let rowsPerPageOptions = [15, 30, 100];
      return (
        <CustomFooter
          page={page}
          rowsPerPage={rowsPerPage}
          changeRowsPerPage={changeRowsPerPage}
          changePage={changePage}
          numberPages={numberPages}
          rowsPerPageOptions={rowsPerPageOptions}
        />
      );
    },
  };

  const HandleSelect = (e, name) => {
    if (valueSelect.filter((fill) => fill.nameField == name).length > 0) {
      valueSelect
        .filter((fill) => fill.nameField == name)
        .map((map) => {
          let valueIndice = valueSelect.indexOf(map);
          valueSelect.splice(valueIndice);
        });

      setValueSelect((prev) => {
        return [...prev, { value: e.value, label: e.label, nameField: name }];
      });
      setRegisterData({ ...registerData, [name]: e.value });
    } else {
      setValueSelect((prev) => {
        return [...prev, { value: e.value, label: e.label, nameField: name }];
      });
      setRegisterData({ ...registerData, [name]: e.value });
    }
  };

  function HandleDelete(e) {
    setLoader(<Loading />);
    let idArr = [];

    e.map((del) => {
      idArr.push(
        employeeClientList.data?.models?.data.filter(
          (filtro, index) => index == del.index,
        )[0].id,
      );
    });

    idArr.map((idDel) =>
      api
        .delete(Prefix.replace(/[\\"]/g, '') + '/customer-employees/' + idDel)
        .then((response) => {
          Swal.fire({
            icon: 'success',
            title: 'Uow!',
            text: response.data.message,
            confirmButtonText: 'Fechar',
          });
          setLoader();
          GlobalMutate(
            Prefix.replace(/[\\"]/g, '') +
              '/customer-employees?per_page=' +
              perPage +
              '&page=' +
              pageExact +
              '&search=' +
              search +
              '&id_customer=' +
              id +
              '&order_by=' +
              orderBy +
              '&order=' +
              order,
          );
          employeeClientList.mutate();
        })
        .catch((err) => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: err.response.data.message,
            confirmButtonText: 'Tentar novamente',
          });
          setLoader();
        }),
    );
  }

  /*Captura dados do primeiro formulário e coloca em um único json */
  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setRegisterData({ ...registerData, [name]: value });
  };

  function HandleRegisterEmployer(values) {
    setLoader(true);
    api
      .post(Prefix.replace(/[\\"]/g, '') + '/customer-employees', registerData)
      .then((response) => {
        Swal.fire({
          icon: 'success',
          title: 'Uow!',
          text: response.data.message,
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: 'Fechar',
          cancelButtonText: 'Novo Cadastro',
          allowOutsideClick: false,
          focusCancel: true,
        }).then((result) => {
          if (result.isConfirmed) {
            setEmployeeView({
              ...employeeView,
              employees: true,
              addEmployees: false,
            });
          }
        });
        setLoader(false);
        setRegisterData(initialState);
        employeeClientList.mutate();
        GlobalMutate(
          Prefix.replace(/[\\"]/g, '') +
            '/customer-employees?per_page=' +
            perPage +
            '&page=' +
            pageExact +
            '&search=' +
            search +
            '&id_customer=' +
            id +
            '&order_by=' +
            orderBy +
            '&order=' +
            order,
        );
      })
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: err.response.data.message,
          confirmButtonText: 'Tentar novamente',
        });
        setLoader(false);
      });
  }

  return (
    <>
      {loader && <Loading />}
      {employeeView.employees ? (
        <div className="customerStep">
          <div className="text-center">
            <div>
              <Button
                type="button"
                style={{
                  backgroundColor: '#1bc5bd',
                  width: '160px',
                  height: '60px',
                  border: 0,
                  borderRadius: '30px',
                  marginTop: '20px',
                  marginBottom: '20px',
                }}
                onClick={HandleAddEmployer}
              >
                <Fontawesome classe="fas fa-plus-circle mr-2" />
                Adicionar
              </Button>
            </div>
          </div>

          <MUIDataTable
            data={employeeClientList.data?.models?.data}
            columns={columns}
            options={options}
          />
        </div>
      ) : employeeView.addEmployees ? (
        <div className="customerStep">
          <Formik
            initialValues={registerData}
            validationSchema={Schema}
            onSubmit={HandleRegisterEmployer}
            enableReinitialize
          >
            {({ values }) => (
              <Form noValidate>
                <Row>
                  <Col>
                    <div className="formStep">
                      <div className="d-flex justify-content-between">
                        <label class="important">Nome do funcionário:</label>
                      </div>
                      <Field
                        type="text"
                        name="name"
                        placeholder="Digite um nome"
                        onKeyUp={handleChange}
                      />
                      <ErrorMessage
                        name="name"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                  <Col>
                    <div className="formStep">
                      <div className="d-flex justify-content-between">
                        <label class="important">E-mail do funcionário:</label>
                      </div>
                      <Field
                        type="email"
                        name="email"
                        placeholder="Digite um e-mail"
                        onKeyUp={handleChange}
                      />
                      <ErrorMessage
                        name="email"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="formStep">
                      <label className="important">Estado</label>

                      <Select
                        value={valueSelect
                          .filter((fill) => fill.nameField == 'uf')
                          .map((map) => {
                            return {
                              value: map.value,
                              label: map.label,
                            };
                          })}
                        styles={colourStyles}
                        components={{ NoOptionsMessage }}
                        onChange={(e) => HandleSelect(e, 'uf')}
                        placeholder="Selecione..."
                        options={uf.map((state) => {
                          return { value: state.value, label: state.name };
                        })}
                      />

                      <ErrorMessage
                        name="uf"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>

                  <Col>
                    <div className="formStep">
                      <label className="important">Cidade</label>
                      <AsyncPaginate
                        value={valueSelect
                          .filter((fill) => fill.nameField == 'id_city')
                          .map((map) => {
                            return {
                              value: map.value,
                              label: map.label,
                            };
                          })}
                        loadOptions={loadOptions}
                        onChange={(e) => HandleSelect(e, 'id_city')} //values and name of select paginate
                        placeholder="Selecione..."
                        cacheUniqs={[values?.uf]} //Set your param that is going to change when you select another field. Just use this option when you are setting params and need to refresh cache.
                        styles={colourStyles}
                        additional={{
                          page: 1,
                          endpoint: '/cities',
                          params: `uf=${values?.uf}`, //Separete with & (and commercial)
                        }}
                      />
                      <ErrorMessage
                        name="id_city"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>

                  <Col>
                    <div className="formStep">
                      <label className="important">É técnico?</label>

                      <Select
                        value={valueSelect
                          .filter((fill) => fill.nameField == 'technician')
                          .map((map) => {
                            return {
                              value: map.value,
                              label: map.label,
                            };
                          })}
                        styles={colourStyles}
                        components={{ NoOptionsMessage }}
                        onChange={(e) => HandleSelect(e, 'technician')}
                        placeholder="Selecione..."
                        options={tech.map((state) => {
                          return { value: state.value, label: state.name };
                        })}
                      />

                      <ErrorMessage
                        name="technician"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div className="formStep">
                      <div className="d-flex justify-content-between">
                        <label class="important">Senha:</label>
                      </div>
                      <Field
                        type="password"
                        name="password"
                        placeholder="Digite uma senha"
                        onKeyUp={handleChange}
                      />
                      <ErrorMessage
                        name="password"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                  <Col>
                    <div className="formStep">
                      <div className="d-flex justify-content-between">
                        <label class="important">Confirmação de senha:</label>
                      </div>
                      <Field
                        type="password"
                        name="password_confirmation"
                        placeholder="Confirme a senha"
                        onKeyUp={handleChange}
                      />
                      <ErrorMessage
                        name="password_confirmation"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className="d-flex justify-content-center mt-4">
                    <BackButton
                      onClick={HandleBackEmployer}
                      style={{ marginRight: '10px' }}
                    >
                      Voltar
                    </BackButton>
                    <Button type="submit">Cadastrar</Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </div>
      ) : employeeView.equipmentView ? (
        <EquipmentView />
      ) : employeeView.telegramView ? (
        <TelegramView />
      ) : (
        <LinkTelegram />
      )}
    </>
  );
};

export default IndexEmployers;
