import React from "react";
import IndexEditUnity from "../../components/units/editunits/indexEditUnity";
import Foreign from "../../components/foreign/foreign";
import UpdateStatus from "../../components/units/updateStatus/updateStatus";
import GoogleMap from "../../components/units/maps/showMap";

export const columns = [
  {
    //0
    name: "id",
    label: "",
    options: {
      setCellHeaderProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      setCellProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      filter: false,
      sort: false,
      display: "excluded",
    },
  },
  {
    //1
    name: "id_city",
    label: "",
    options: {
      setCellHeaderProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      setCellProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      filter: false,
      sort: false,
      display: "excluded",
    },
  },
  {
    //2
    name: "name",
    label: "Unidade",
    options: {
      setCellHeaderProps: () => ({
        style: {
          display: "flex",
          justifyContent: "center",
        },
      }),
      setCellProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      filter: true,
      sort: true,
    },
  },
  {
    //3
    name: "customer",
    label: "Cliente",
    options: {
      setCellHeaderProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      setCellProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      filter: true,
      sort: false,
      customBodyRender: (value, tableMeta) => {
        if (!value) {
          return "";
        } else {
          return value.fantasy_name;
        }
      },
    },
  },
  {
    //4
    name: "address",
    label: "Endereço",
    options: {
      setCellHeaderProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      setCellProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      filter: false,
      sort: false,
    },
  },
  {
    //5
    name: "neighborhood",
    label: "Bairro",
    options: {
      setCellHeaderProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      setCellProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      filter: true,
      sort: false,
      display: false,
    },
  },
  {
    //6
    name: "number",
    label: "Número",
    options: {
      setCellHeaderProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      setCellProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      filter: false,
      sort: false,
      display: false,
    },
  },
  {
    //7
    name: "phone",
    label: "Telefone",
    options: {
      setCellHeaderProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      setCellProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      filter: false,
      sort: false,
      customBodyRender: (value, tableMeta) => {
        return !value ? "Sem número" : value;
      },
    },
  },
  {
    //8
    name: "city",
    label: "Cidade",
    options: {
      setCellHeaderProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      setCellProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      filter: true,
      sort: false,
      display: false,
      customBodyRender: (value, tableMeta) => {
        return value.name;
      },
    },
  },
  {
    //9
    name: "city",
    label: "UF Setor",
    options: {
      setCellHeaderProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      setCellProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      filter: true,
      sort: false,
      display: false,
      customBodyRender: (value) => {
        return value.uf;
      },
    },
  },
  {
    //10
    name: "city",
    label: "Região",
    options: {
      setCellHeaderProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      setCellProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      filter: true,
      sort: false,
      display: true,
      customBodyRender: (value, tableMeta) => {
        return <Foreign value={value} nameField="name" secondNameField="uf" />;
      },
    },
  },
  {
    //11
    name: "active",
    label: "Status",
    options: {
      setCellHeaderProps: () => ({
        style: {
          "text-align": "center",
          width: "10%",
        },
      }),
      setCellProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      filter: false,
      sort: false,
      print: false,
      customBodyRender: (value, tableMeta) => {
        return (
          <UpdateStatus
            activeDefault={value}
            id={tableMeta.rowData[0]}
            page={tableMeta.tableState.page}
            rowsPage={tableMeta.tableState.rowsPerPage}
            search={
              !tableMeta.tableState.searchText
                ? ""
                : tableMeta.tableState.searchText
            }
            orderBy={tableMeta.tableState.sortOrder.name}
            order={tableMeta.tableState.sortOrder.direction}
          />
        );
      },
    },
  },
  {
    //11
    name: "geo",
    label: "Localização",
    options: {
      setCellHeaderProps: () => ({
        style: {
          "text-align": "center",
          width: "10%",
        },
      }),
      setCellProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      filter: false,
      sort: false,
      print: false,
      customBodyRender: (value, tableMeta) => {
        return (
          <GoogleMap
            id={tableMeta.rowData[0]}
            address={tableMeta.rowData[4]}
            number={tableMeta.rowData[6]}
            city={tableMeta.rowData[8].name}
            uf={tableMeta.rowData[9].uf}
          />
        );
      },
    },
  },
  {
    //13
    name: "IndexEditUnity",
    label: "Editar",
    options: {
      setCellHeaderProps: () => ({
        style: {
          "text-align": "center",
          width: "10%",
        },
      }),
      setCellProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      filter: false,
      sort: false,
      print: false,
      customBodyRender: (value, tableMeta) => {
        return (
          <IndexEditUnity
            id={tableMeta.rowData[0]}
            page={tableMeta.tableState.page}
            rowsPage={tableMeta.tableState.rowsPerPage}
            // search={tableMeta.tableState.searchText}
            orderBy={tableMeta.tableState.sortOrder.name}
            order={tableMeta.tableState.sortOrder.direction}
          />
        );
      },
    },
  },
];
