import styled from 'styled-components';

export const Notification = styled.img`
  cursor: pointer;
  margin-left: 15px;

  &:hover {
    opacity: 0.5;
  }
`;
