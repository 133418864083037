import React, { useState, useContext, useEffect } from "react";
import { Row, Col, Button, Modal, Alert } from "react-bootstrap";
import "../units.css";
import { uf } from "../../data/uf";
import api from "../../../services/api";
import Fontawesome from "../../fontawesome/fontawesome";
import { Prefix } from "../../../services/prefix";
import Loading from "../../loader/loading";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { useFetch } from "../../hooks/useFetch";
import { mutate as GlobalMutate } from "swr";
import { maskTelDin } from "../../../services/mask";
import { Context } from "../../../services/context";
import Geocode from "react-geocode";
import { keyMap } from "../../googleMap/keyMaps";
import axios from "axios";
import { AsyncPaginate, component } from "react-select-async-paginate";
import { NoOptionsMessage } from "../../selectAsync/NoOptionsMessage";
import { colourStyles } from "../../selectAsync/colourStyles";
import { loadOptions } from "../../selectAsync/loadOptions";
import Select, { components } from "react-select";
import { Formik, Form, FieldArray, ErrorMessage, Field } from "formik";
import { Schema } from "./schema";
import { ModalContainer } from "../../../style/stylesModals"; 
import { CustomButton, TextButton } from "../../../style/stylesButton";

const IndexEditUnity = ({ id, page, rowsPage, search, orderBy, order }) => {
  const { selectclient, selectuf, selectcity } = useContext(Context);
  const [show, setShow] = useState(false); /* Habilita ou desabilita o modal */
  const [ufCity, setUfCity] = useState("");
  const [valueSelect, setValueSelect] = useState([]);
  const [filterCity, setFilterCity] = useState();
  const [registerData, setRegisterData] = useState({});


  let pageExact = page + parseInt(1);

  const handleShow = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };

  const unitList = useFetch(
    !show ? null : Prefix.replace(/[\\"]/g, "") + "/units/" + id
  );

  useEffect(() => {
    if (unitList.data) {
      setRegisterData({
        initialData: [
          {
            name: unitList.data?.model.name,
            active: unitList.data?.model.active,
            address: unitList.data?.model.address,
            neighborhood: unitList.data?.model.neighborhood,
            number: unitList.data?.model.number,
            id_customer: unitList.data?.model.id_customer,
            id_city: unitList.data?.model.city.id,
            state: unitList.data?.model.city.uf,
            phone: unitList.data?.model.phone,
            latitude: unitList.data?.model.lat,
            longitude: unitList.data?.model.lng,
          },
        ],
      });
    }
  }, [show, unitList.data]);

  Geocode.setApiKey(keyMap); //Import API key from a file
  Geocode.setLanguage("pt-BR"); //Specify language of the parsed address. List of the available language codes. https://developers.google.com/maps/faq#languagesupport
  Geocode.setRegion("pt-BR");
  Geocode.setLocationType("ROOFTOP"); //ROOFTOP => indicates that the returned result is a precise geocode for which we have location information accurate down to street address precision. https://developers.google.com/maps/documentation/geocoding/overview#:~:text=%22ROOFTOP%22%20indicates%20that%20the%20returned,points%20(such%20as%20intersections)
  Geocode.enableDebug(false); //Enable or disable logs. Its optional.

  const HandleSelect = (e, name, key) => {
    if (
      valueSelect.filter((fill) => fill.key == key && fill.nameField == name)
        .length > 0
    ) {
      valueSelect
        .filter((fill) => fill.key == key && fill.nameField == name)
        .map((map) => {
          let position = valueSelect.indexOf(map);
          valueSelect.splice(position, 1);
        });
      setValueSelect(() => {
        return [
          ...valueSelect,
          { value: e.value, label: e.label, nameField: name, key: key },
        ];
      });
    } else {
      setValueSelect((prev) => {
        return [
          ...prev,
          { value: e.value, label: e.label, nameField: name, key: key },
        ];
      });
    }
  };

  function HandleRegister(values) {
    values.initialData.map((map) => {
      axios
        .get(
          "https://maps.googleapis.com/maps/api/geocode/json?address=" +
            map.address +
            "," +
            map.number +
            "," +
            map.neighborhood +
            "," +
            map.id_city +
            ",&key=" +
            keyMap
        )
        .then((response) => {
          const { lat, lng } = response.data.results[0].geometry.location;
          api
            .put(Prefix.replace(/[\\"]/g, "") + "/units/" + id, {
              id_customer: map.id_customer,
              name: map.name,
              address: map.address,
              number: map.number,
              neighborhood: map.neighborhood,
              id_city: map.id_city,
              phone: map.phone,
              active: 1,
              lat: lat,
              lng: lng,
            })
            .then((response) => {
              Swal.fire({
                icon: "success",
                title: "Uow!",
                text: response.data.message,
                confirmButtonText: "Fechar",
              });
              setShow(false);
              GlobalMutate(
                Prefix.replace(/[\\"]/g, "") +
                  "/units?per_page=" +
                  rowsPage +
                  "&page=" +
                  pageExact +
                  "&id_customer=" +
                  selectclient +
                  "&id_city=" +
                  selectcity +
                  "&uf=" +
                  selectuf +
                  "&order_by=" +
                  orderBy +
                  "&order=" +
                  order
              );
            })
            .catch((err) => {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: err.response.data.message,
                confirmButtonText: "Tentar novamente",
              });
            });
        });
    });
  }

  return (
    <>
      {!show ? (
        <Link to="#" onClick={handleShow} className="link_color_datatable">
          <Fontawesome classe="fas fa-pencil-alt" />
        </Link>
      ) : (
        <ModalContainer
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title><h3>Atualização de Unidade</h3></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik //--> Configuração do Formik
              initialValues={registerData}
              validationSchema={Schema}
              onSubmit={HandleRegister}
              enableReinitialize
            >
              {({ values, handleChange, setFieldValue }) => (
                <Form>
                  <Alert variant="success">
                    <FieldArray name="initialData">
                      {({ push, remove }) => (
                        <div>
                          {values?.initialData?.map((p, index) => {
                            return (
                              <>
                                <Row>
                                  <Col sm={12}>
                                    <div className="formStep">
                                      <label class="important">Cliente</label>
                                      <AsyncPaginate
                                        value={
                                          valueSelect.length == 0
                                            ? {
                                                value:
                                                  unitList.data?.model?.customer
                                                    ?.id,
                                                label:
                                                  unitList.data?.model?.customer
                                                    ?.name,
                                              }
                                            : valueSelect
                                                .filter(
                                                  (fill) =>
                                                    fill.nameField ==
                                                      "id_customer" &&
                                                    fill.key == "id_customer"
                                                )
                                                .map((map) => {
                                                  return {
                                                    value: map.value,
                                                    label: map.label,
                                                  };
                                                })
                                        }
                                        loadOptions={loadOptions}
                                        onChange={(e) => {
                                          HandleSelect(
                                            e,
                                            "id_customer",
                                            "id_customer"
                                          );
                                          setFieldValue(
                                            `initialData[${index}].id_customer`,
                                            e.value
                                          );
                                        }} //values and name of select paginate
                                        placeholder="Selecione uma cidade..."
                                        components={{ NoOptionsMessage }}
                                        cacheUniqs={[
                                          valueSelect.length == 0
                                            ? ""
                                            : valueSelect
                                                .filter(
                                                  (fill) =>
                                                    fill.nameField == "state"
                                                )
                                                .map((map) => map.value)[0],
                                        ]} //Set your param that is going to change when you select another field. Just use this option when you are setting params and need to refresh cache.
                                        styles={colourStyles}
                                        additional={{
                                          page: 1,
                                          endpoint: "/customers",
                                        }}
                                      />
                                      <ErrorMessage
                                        name={`initialData[${index}].id_customer`}
                                        component="span"
                                        className="errorMessages"
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <div className="formStep">
                                      <label class="important">Unidade</label>
                                      <Field
                                        type="text"
                                        placeholder="Unidade"
                                        name={`initialData[${index}].name`}
                                        onKeyUp={handleChange}
                                      />
                                      <ErrorMessage
                                        name={`initialData[${index}].name`}
                                        component="span"
                                        className="errorMessages"
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col sm={9}>
                                    <div className="formStep">
                                      <label class="important">Endereço</label>
                                      <Field
                                        type="text"
                                        placeholder="Digite um endereço"
                                        name={`initialData[${index}].address`}
                                        onKeyUp={handleChange}
                                      />
                                      <ErrorMessage
                                        name={`initialData[${index}].address`}
                                        component="span"
                                        className="errorMessages"
                                      />
                                    </div>
                                  </Col>
                                  <Col sm={3}>
                                    <div className="formStep">
                                      <label class="important">Número</label>
                                      <Field
                                        type="text"
                                        name={`initialData[${index}].number`}
                                        onKeyUp={handleChange}
                                      />
                                      <ErrorMessage
                                        name={`initialData[${index}].number`}
                                        component="span"
                                        className="errorMessages"
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <div className="formStep">
                                      <label class="important">Bairro</label>
                                      <Field
                                        type="text"
                                        placeholder="Digite o bairro"
                                        name={`initialData[${index}].neighborhood`}
                                        onKeyUp={handleChange}
                                      />
                                      <ErrorMessage
                                        name={`initialData[${index}].neighborhood`}
                                        component="span"
                                        className="errorMessages"
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col sm={6}>
                                    <div className="formStep">
                                      <label class="important">Estado</label>
                                      <Select
                                        value={
                                          valueSelect.length == 0
                                            ? uf
                                                .filter(
                                                  (fill) =>
                                                    fill.value ==
                                                    registerData.initialData[0]
                                                      .state
                                                )
                                                .map((map) => {
                                                  return {
                                                    value: map.value,
                                                    label: map.value,
                                                  };
                                                })
                                            : valueSelect
                                                .filter(
                                                  (fill) =>
                                                    fill.nameField == "state"
                                                )
                                                .map((map) => {
                                                  return {
                                                    value: map.value,
                                                    label: map.label,
                                                  };
                                                })
                                        }
                                        styles={colourStyles}
                                        onChange={(e) =>
                                          HandleSelect(e, "state")
                                        }
                                        components={{ NoOptionsMessage }}
                                        placeholder="Selecione..."
                                        options={uf.map((state) => {
                                          return {
                                            value: state.value,
                                            label: state.value,
                                          };
                                        })}
                                      />
                                      <ErrorMessage
                                        name={`initialData[${index}].state`}
                                        component="span"
                                        className="errorMessages"
                                      />
                                    </div>
                                  </Col>
                                  <Col sm={6}>
                                    <div className="formStep">
                                      <label class="important">Cidade</label>
                                      <AsyncPaginate
                                        value={
                                          valueSelect.length == 0
                                            ? {
                                                value:
                                                  unitList.data?.model?.id_city,
                                                label:
                                                  unitList.data?.model?.city
                                                    .name,
                                              }
                                            : valueSelect
                                                .filter(
                                                  (fill) =>
                                                    fill.nameField ==
                                                      "id_city" &&
                                                    fill.key == "id_city"
                                                )
                                                .map((map) => {
                                                  return {
                                                    value: map.value,
                                                    label: map.label,
                                                  };
                                                })
                                        }
                                        loadOptions={loadOptions}
                                        onChange={(e) => {
                                          HandleSelect(e, "id_city", "id_city");
                                          setFieldValue(
                                            `initialData[${index}].id_city`,
                                            e.value
                                          );
                                        }} //values and name of select paginate
                                        placeholder="Selecione cidade..."
                                        components={{ NoOptionsMessage }}
                                        cacheUniqs={[
                                          valueSelect.length == 0
                                            ? ""
                                            : valueSelect
                                                .filter(
                                                  (fill) =>
                                                    fill.nameField == "state"
                                                )
                                                .map((map) => map.value)[0],
                                        ]} //Set your param that is going to change when you select another field. Just use this option when you are setting params and need to refresh cache.
                                        styles={colourStyles}
                                        additional={{
                                          page: 1,
                                          endpoint: "/cities",
                                          params: `uf=${
                                            valueSelect
                                              .filter(
                                                (fill) =>
                                                  fill.nameField == "state"
                                              )
                                              .map((map) => map.value)[0]
                                          }`,
                                        }}
                                      />
                                      <ErrorMessage
                                        name={`initialData[${index}].id_city`}
                                        component="span"
                                        className="errorMessages"
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col>
                                    <div className="formStep">
                                      <label>Telefone</label>
                                      <Field
                                        type="text"
                                        placeholder="Digite um número de telefone"
                                        name={`initialData[${index}].phone`}
                                        onChange={handleChange}
                                        onKeyPress={maskTelDin}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </>
                            );
                          })}
                          <div>
                            <Row>
                              <Col className="d-flex justify-content-center ">
                                <CustomButton
                                  type="submit"
                                >
                                  <TextButton>Atualizar</TextButton> 
                                </CustomButton>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      )}
                    </FieldArray>
                  </Alert>
                </Form>
              )}
            </Formik>
          </Modal.Body>
        </ModalContainer>
      )}
    </>
  );
};
export default IndexEditUnity;
