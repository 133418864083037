import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Alert, Modal, Form } from 'react-bootstrap';
import Fontawesome from '../../fontawesome/fontawesome';
import { Prefix } from '../../../services/prefix';
import { useFetch } from '../../hooks/useFetch';
import Select, { components } from 'react-select';
import { cities } from '../../../components/data/city';
import api from '../../../services/api';
import Loading from '../../loader/loading';
import Swal from 'sweetalert2';
import download from 'downloadjs';
import './styleClients.css';
import { AsyncPaginate } from 'react-select-async-paginate';
import { loadOptions } from '../../selectAsync/loadOptions';
import colourStyles from '../../selectAsync/colourStyles';
import { NoOptionsMessage } from '../../selectAsync/NoOptionsMessage';
import { ModalContainer } from "../../../style/stylesModals"; 
import { CustomButton, TextButton, ButtonDesabled} from "../../../style/stylesButton";

const GenerateClients = () => {
  const [loader, setLoader] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    setValueSelect([]);
    setFormData([]);
  };
  const [formData, setFormData] = useState([]);
  const [valueSelect, setValueSelect] = useState([]);

  let dayName = new Array(
    'domingo',
    'segunda',
    'terça',
    'quarta',
    'quinta',
    'sexta',
    'sábado',
  );
  let monName = new Array(
    'janeiro',
    'fevereiro',
    'março',
    'abril',
    'maio',
    'junho',
    'agosto',
    'outubro',
    'novembro',
    'dezembro',
  );
  const now = new Date();

  let birthday =
    dayName[now.getDay()] +
    ', ' +
    now.getDate() +
    ' de ' +
    monName[now.getMonth()] +
    ' de ' +
    now.getFullYear();

  const nameClients = useFetch(Prefix.replace(/[\\"]/g, '') + '/customers');

  const list = nameClients.data?.models?.data.map((map) => {
    return { value: map.id, label: map.name };
  });

  const listUf = cities.map((map) => {
    return { value: map.uf, label: map.uf };
  });

  const handleChangeClientsUf = (e) => {
    let ArrUf = [];
    if (!e || e.length == 0) {
    } else {
      e.map((map) => {
        ArrUf.push(map.value);
      });
      setFormData({
        ...formData,
        uf: ArrUf,
      });
    }
  };

  const HandleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.checked,
    });
  };

  const HandleChangeText = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value.trim(),
    });
  };

  const HandleSelect = (e, name) => {
    let updateId = [];
    if (!e) {
      setValueSelect([]);
      setFormData({ ...formData, [name]: [] });
    } else {
      if (valueSelect.length > e.length) {
        setValueSelect(
          e.map((map) => {
            return { value: map.value, label: map.label, nameField: name };
          }),
        );
        e.map((map) => {
          updateId.push(map.value);
        });
        setFormData({ ...formData, [name]: updateId });
      } else {
        e.map((map) => {
          //inicio do map
          updateId.push(map.value);

          if (valueSelect.length > 0) {
            // se a contagem do valueselect maior que 0
            setValueSelect(() => {
              return [
                ...valueSelect,
                { value: map.value, label: map.label, nameField: name },
              ];
            });
          } else {
            setValueSelect(() => {
              return [{ value: map.value, label: map.label, nameField: name }];
            });
            setFormData({ ...formData, [name]: [map.value] });
          }
          setFormData({ ...formData, [name]: updateId });
        });
      }
    }
  };

  function HandleRegister() {
    setLoader(<Loading />);
    api
      .post(Prefix.replace(/[\\"]/g, '') + '/customers-export', formData, {
        responseType: 'blob',
      })
      .then((response) => {
        const content = response.headers['content-type'];
        download(
          response.data,
          'Relatorio de Cliente(s) em ' + birthday,
          content,
        );
        Swal.fire({
          icon: 'success',
          title: 'Uow...',
          text: 'Download realizado com sucesso!',
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: 'Fechar',
          cancelButtonText: 'Novo Relatório',
          allowOutsideClick: false,
        }).then((result) => {
          if (!result.isConfirmed) {
            setShow(true);
            setLoader(false);
          }
        });
        setShow(false);
        setFormData({});
      })
      .catch(
        (error) =>
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error,
            confirmButtonText: 'Tentar novamente',
          }),
        setLoader(false),
      );
  }

  return (
    <>
      <Button variant="success" onClick={handleShow}>
        <Fontawesome classe="fas fa-file-pdf mr-3" />
        Gerar Relatório
      </Button>
      {!show ? (
        ''
      ) : (
        <ModalContainer
          show={show}
          size="md"
          onHide={handleClose}
          backdrop="static"
          keyboard={true}
        >
          <Modal.Header closeButton>
            <Modal.Title><h3>Relatório de Cliente(s)</h3></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {<Loading /> && loader}
            <Alert variant="success">
              <form className="customerStep" onSubmit={HandleRegister}>
                <Row>
                  <Col>
                    <div className="formStep">
                      <label className="important">Cliente</label>
                      <AsyncPaginate
                        value={
                          valueSelect.length == 0
                            ? null
                            : valueSelect.map((map) => {
                              return {
                                value: map.value,
                                label: map.label,
                              };
                            })
                        }
                        loadOptions={loadOptions}
                        isMulti
                        components={{ NoOptionsMessage }}
                        cacheUniqs={[
                          valueSelect
                            .filter((fill) => fill.nameField == 'id')
                            .map((map) => map.label),
                        ]}
                        onChange={(e) => HandleSelect(e, 'id')} //values and name of select paginate
                        placeholder="Selecione Cliente..."
                        styles={colourStyles}
                        additional={{
                          page: 1,
                          endpoint: '/customers',
                        }}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div
                      className="formStep py-3"
                      style={{ display: 'absolute' }}
                    >
                      <Select
                        closeMenuOnSelect={false}
                        placeholder="Selecione Estado(s)..."
                        isMulti
                        name="uf"
                        options={listUf}
                        components={{ NoOptionsMessage }}
                        onChange={handleChangeClientsUf}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="custom-control custom-switch-div formStep switch-reports">
                      <Form.Check
                        type="switch"
                        name="contracts"
                        id={!formData ? '' : 'contracts' + formData.id}
                        label="Contratos"
                        checked={!formData ? '' : Boolean(formData?.contracts)}
                        onChange={HandleChange}
                        className="switch-reports"
                      />
                    </div>
                    <div className="custom-control custom-switch-div formStep switch-reports">
                      <Form.Check
                        type="switch"
                        name="employees"
                        id={!formData ? '' : 'employees' + formData.id}
                        label="Colaboradores"
                        checked={!formData ? '' : Boolean(formData?.employees)}
                        onChange={HandleChange}
                        className="switch-reports"
                      />
                    </div>
                    <div className="custom-control custom-switch-div formStep switch-reports">
                      <Form.Check
                        type="switch"
                        name="technician"
                        id={!formData ? '' : 'technician' + formData.id}
                        label="Técnicos"
                        checked={!formData ? '' : Boolean(formData?.technician)}
                        onChange={HandleChange}
                        className="switch-reports"
                      />
                    </div>
                  </Col>
                  <Col className=" ">
                    <div className="custom-control custom-switch-div formStep switch-reports">
                      <Form.Check
                        type="switch"
                        name="numbers"
                        id={!formData ? '' : 'numbers' + formData.id}
                        label="Quantidades"
                        checked={!formData ? '' : Boolean(formData?.numbers)}
                        onChange={HandleChange}
                      />
                    </div>
                    <div className="custom-control custom-switch-div formStep switch-reports">
                      <Form.Check
                        type="switch"
                        name="pagebreak"
                        id={!formData ? '' : 'pagebreak' + formData.id}
                        label="Quebra de Páginas"
                        checked={!formData ? '' : Boolean(formData?.pagebreak)}
                        onChange={HandleChange}
                        className="switch-reports"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Label>Observações:</Form.Label>
                      <Form.Control
                        type="text"
                        as="textarea"
                        rows={3}
                        name="obs"
                        onChange={HandleChangeText}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="d-flex justify-content-center">
                <CustomButton type='button' onClick={HandleRegister}>
                    <TextButton>Gerar Relatório</TextButton>
                  </CustomButton>
                </Row>
              </form>
            </Alert>
          </Modal.Body>
        </ModalContainer>
      )}
    </>
  );
};
export default GenerateClients;
