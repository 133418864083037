import React, { useState } from 'react';
import { Col, Row, Button, Modal } from 'react-bootstrap';
import '../employer.css';
import { Prefix } from '../../../services/prefix';
import Loading from '../../loader/loading';
import Swal from 'sweetalert2';
import api from '../../../services/api';
import { useFetch } from '../../hooks/useFetch';
import Fontawesome from '../../fontawesome/fontawesome';
import { mutate as GlobalMutate } from 'swr';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Formik, Form, Field, ErrorMessage, useFormik } from 'formik';
import { maskTel, maskTelFixo, maskCPF } from '../../../services/mask';
import { AsyncPaginate } from 'react-select-async-paginate';
import { loadOptions } from '../../selectAsync/loadOptions';
import { colourStyles } from '../../selectAsync/colourStyles';
import { uf } from '../../data/uf';
import Select, { components } from 'react-select';
import Schema from './schema';
import { ListEmployeeTypes } from '../listEmployeeTypes';
import { cpf, cnpj } from 'cpf-cnpj-validator';
import { NoOptionsMessage } from '../../selectAsync/NoOptionsMessage';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { useStyles } from '../../buttons/AddButtonStyle';
import { ModalContainer } from "../../../style/stylesModals"; 
import { CustomButton, TextButton } from "../../../style/stylesButton";

const AddEmployer = (props) => {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setValueSelect([]);
  };
  const handleShow = () => setShow(true);
  const [loader, setLoader] = useState(false);
  const [valueSelect, setValueSelect] = useState([]);
  const [messageDocument, setMessageDocument] = useState(null);
  const [initialState, setInitialState] = useState({
    name: '',
    username: '',
    email: '',
    password: '',
    password_confirmation: '',
    active: '1',
    type: '',
    rg: '',
    cpf: '',
    address: '',
    neighborhood: '',
    number: '',
    phone1: '',
    phone2: '',
    admission_at: '',
    exoneration_at: '',
    id_city: '',
    uf: '',
    technician: '',
  });
  const [registerData, setRegisterData] = useState(initialState);

  const tipos = useFetch(Prefix.replace(/[\\"]/g, '') + '/employees/types');

  const HandleSelect = (e, name) => {
    if (valueSelect.filter((fill) => fill.nameField == name).length > 0) {
      valueSelect
        .filter((fill) => fill.nameField == name)
        .map((map) => {
          let valueIndice = valueSelect.indexOf(map);
          valueSelect.splice(valueIndice);
        });

      setValueSelect((prev) => {
        return [...prev, { value: e.value, label: e.label, nameField: name }];
      });
      setRegisterData({ ...registerData, [name]: e.value });
    } else {
      setValueSelect((prev) => {
        return [...prev, { value: e.value, label: e.label, nameField: name }];
      });
      setRegisterData({ ...registerData, [name]: e.value });
    }
  };

  const HandleChange = (e) => {
    let eventName = e.target.name;
    let value = e.target.value;
    setRegisterData({ ...registerData, [eventName]: value });
  };

  const validateDocument = (e) => {
    let document = e.target.value
      .replace('/', '')
      .replace('-', '')
      .replace('.', '')
      .replace('.', '');
    setMessageDocument(cpf.isValid(document));
    if (cpf.isValid(document)) {
      setRegisterData({ ...registerData, cpf: document });
    }
  };

  function HandleRegister(registerData) {
    setLoader(true);
    api
      .post(Prefix.replace(/[\\"]/g, '') + '/employees', registerData)
      .then((response) => {
        Swal.fire({
          icon: 'success',
          title: 'Uow!',
          text: response.data.message,
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: 'Fechar',
          cancelButtonText: 'Novo Cadastro',
          allowOutsideClick: false,
        }).then((result) => {
          if (!result.isConfirmed) {
          }
        });
        setLoader();
        setShow(false);
        setValueSelect([]);
        GlobalMutate(
          Prefix.replace(/[\\"]/g, '') +
            '/employees?per_page=' +
            props.perPage +
            '&page=' +
            props.pageExact +
            '&search=' +
            props.search +
            '&order_by=' +
            props.orderBy +
            '&order=' +
            props.order,
        );
      })
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: err.response.data.message,
          confirmButtonText: 'Tentar novamente',
        });
        setLoader(false);
      });
  }

  const classes = useStyles();

  return (
    <>
      {show === false ? (
        <Fab className={classes.fab} onClick={handleShow}>
          <AddIcon className={classes.icon} />
        </Fab>
      ) : (
        ''
      )}
      <ModalContainer
        show={show}
        size={props.modalSize}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title><h3>{props.titleLabel}</h3></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={registerData}
            validationSchema={Schema}
            onSubmit={HandleRegister}
            enableReinitialize
          >
            {({ values }) => (
              <Form>
                <Row>
                  {loader}
                  <Col>
                    <div className="formStep">
                      <label className="important">Nome</label>
                      <Field
                        type="text"
                        name="name"
                        placeholder="Digite o nome..."
                        onChange={HandleChange}
                      />
                      <ErrorMessage
                        name="name"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div className="formStep">
                      <label>CPF</label>
                      <Field
                        maxlength="14"
                        type="text"
                        name="cpf"
                        placeholder={'Digite o cpf...'}
                        value={maskCPF(values.cpf)}
                        onBlur={validateDocument}
                      />
                      {messageDocument === false ? (
                        <span className="errorMessages">CPF inválido</span>
                      ) : (
                        ''
                      )}
                      <ErrorMessage
                        name="cpf"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>

                  <Col>
                    <div className="formStep">
                      <label>RG</label>
                      <Field
                        maxlength="16"
                        type="text"
                        name="rg"
                        placeholder="Digite o rg..."
                        onChange={HandleChange}
                      />
                      <ErrorMessage
                        name="rg"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div className="formStep">
                      <label>Endereço</label>
                      <Field
                        type="text"
                        name="address"
                        placeholder="Digite o endereço..."
                        onChange={HandleChange}
                      />
                      <ErrorMessage
                        name="address"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div className="formStep">
                      <label>Número</label>
                      <Field
                        type="text"
                        name="number"
                        placeholder="Digite o número..."
                        onChange={HandleChange}
                      />
                      <ErrorMessage
                        name="number"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                  <Col>
                    <div className="formStep">
                      <label>Bairro</label>
                      <Field
                        type="text"
                        name="neighborhood"
                        placeholder="Digite o bairro..."
                        onChange={HandleChange}
                      />
                      <ErrorMessage
                        name="neighborhood"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div className="formStep">
                      <label>Estado</label>
                      <Select
                        value={valueSelect
                          .filter((fill) => fill.nameField == 'uf')
                          .map((map) => {
                            return {
                              value: map.value,
                              label: map.label,
                            };
                          })}
                        styles={colourStyles}
                        onChange={(e) => HandleSelect(e, 'uf')}
                        components={{ NoOptionsMessage }}
                        placeholder="Selecione..."
                        options={uf.map((state) => {
                          return { value: state.value, label: state.name };
                        })}
                      />
                    </div>
                  </Col>
                  <Col>
                    <div className="formStep">
                      <label className="important">Cidade</label>
                      <AsyncPaginate
                        value={valueSelect
                          .filter((fill) => fill.nameField == 'id_city')
                          .map((map) => {
                            return {
                              value: map.value,
                              label: map.label,
                            };
                          })}
                        loadOptions={loadOptions}
                        onChange={(e) => HandleSelect(e, 'id_city')} //values and name of select paginate
                        placeholder="Selecione..."
                        components={{ NoOptionsMessage }}
                        cacheUniqs={[values.uf]} //Set your param that is going to change when you select another field. Just use this option when you are setting params and need to refresh cache.
                        styles={colourStyles}
                        additional={{
                          page: 1,
                          endpoint: '/cities',
                          params: `uf=${registerData.uf}`, //Separete with & (and commercial)
                        }}
                      />
                      <ErrorMessage
                        name="id_city"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div className="formStep">
                      <label>Telefone</label>
                      <Field
                        maxLength="14"
                        type="text"
                        name="phone1"
                        placeholder="Digite o número do telefone..."
                        value={
                          values.phone1 == '' ? '' : maskTelFixo(values.phone1)
                        }
                        onChange={HandleChange}
                      />
                      <ErrorMessage
                        name="phone1"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>

                  <Col>
                    <div className="formStep">
                      <label>Celular</label>
                      <Field
                        maxLength="16"
                        type="text"
                        name="phone2"
                        placeholder="Digite o número do celular..."
                        value={
                          values.phone2 == '' ? '' : maskTel(values.phone2)
                        }
                        onChange={HandleChange}
                      />
                      <ErrorMessage
                        name="phone2"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div className="formStep">
                      <label>Nome de Usuário</label>
                      <Field
                        type="text"
                        name="username"
                        placeholder="Digite o nome de usuário..."
                        onChange={HandleChange}
                      />
                      <ErrorMessage
                        name="username"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>

                  <Col>
                    <div className="formStep">
                      <label className="important">E-mail</label>
                      <Field
                        type="text"
                        name="email"
                        placeholder="Digite o e-mail..."
                        onChange={HandleChange}
                      />
                      <ErrorMessage
                        name="email"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div className="formStep">
                      <label className="important">Senha</label>
                      <Field
                        type="text"
                        name="password"
                        placeholder="Digite uma senha..."
                        onChange={HandleChange}
                      />
                      <ErrorMessage
                        name="password"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>

                  <Col>
                    <div className="formStep">
                      <label className="important">Confirmar Senha</label>
                      <Field
                        type="text"
                        name="password_confirmation"
                        placeholder="Confirme à senha..."
                        onChange={HandleChange}
                      />
                      <ErrorMessage
                        name="password_confirmation"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div className="formStep">
                      <label className="important">Tipo</label>
                      <Field name="type" as="select" onChange={HandleChange}>
                        <option selected>Selecione...</option>

                        {ListEmployeeTypes.map((array) => {
                          return (
                            <>
                              <option value={array.value}>{array.label}</option>
                            </>
                          );
                        })}
                      </Field>
                      <ErrorMessage
                        name="type"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                  <Col>
                    <div className="formStep">
                      <label class="important">Técnico(a) ?</label>
                      <Field
                        as="select"
                        name="technician"
                        onChange={HandleChange}
                      >
                        <option selected>Selecione...</option>
                        <option value="1">Sim</option>
                        <option value="0">Não</option>
                      </Field>
                      <ErrorMessage
                        name="technician"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div className="formStep">
                      <label>Data da Admissão</label>
                      <Field
                        type="date"
                        name="admission_at"
                        onBlur={HandleChange}
                      />
                      <ErrorMessage
                        name="admission_at"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>

                  <Col>
                    <div className="formStep">
                      <label>Data da Demissão</label>
                      <Field
                        type="date"
                        name="exoneration_at"
                        placeholder="Informe à data de demissão..."
                        onBlur={HandleChange}
                      />
                      <ErrorMessage
                        name="exoneration_at"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                </Row>
                <div
                  className="customerStep"
                  style={{
                    display:
                      values.type == 7 ||
                      values.type == 11 ||
                      values.type == null
                        ? 'none'
                        : 'block',
                  }}
                ></div>

                <Row>
                  <Col className="d-flex justify-content-center">
                    <CustomButton type="submit">
                      <TextButton>Cadastrar</TextButton>
                    </CustomButton>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </ModalContainer>
    </>
  );
};
export default AddEmployer;
