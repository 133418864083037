import styled from 'styled-components';
import { Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export const Background = styled.div`
  width: 100%;
  height: 100vh;
  background: url('../../../media/bg/404-bg.png') #646464;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Box = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const NotFoundTitle = styled.h2`
  font-family: inter;
  font-size: 180px;
  color: #fff;
  font-weight: bold;
`;

export const Subtitle = styled.h3`
  font-family: inter;
  font-size: 60px;
  color: #fff;
  font-weight: 500;
`;

export const Text = styled.p`
  font-family: inter;
  font-size: 25px;
  color: #fff;
  font-weight: 300;
`;

export const Button = styled(Link)`
  font-family: inter;
  font-size: 19px;
  color: ${(props) => props.color};
  font-weight: 300;
  background: ${(props) => props.background};
  width: 60%;
  border: 0;
  border-radius: 100px;
  height: 74px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    opacity: 0.9;
  }
`;

export const Rows = styled(Row)`
  width: 100%;
  margin-top: 20px;
`;
