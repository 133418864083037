import React from 'react';
import Fontawesome from '../../fontawesome/fontawesome';
import { useFetch } from '../../hooks/useFetch';
import { Prefix } from '../../../services/prefix';

const DownloadAttachment = ({ id }) => {
  const listAttach = useFetch(
    Prefix.replace(/[\\"]/g, '') + '/os-attachments/' + id,
  );
  if (listAttach.error) return '';
  if (!listAttach.data) return '';
  return (
    <>
      <a href={listAttach.data?.model?.path} target="_blank">
        <Fontawesome classe="far fa-file-pdf icon-attachment" />
      </a>
    </>
  );
};
export default DownloadAttachment;
