import React, { useState, useContext } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import Fontawesome from '../../components/fontawesome/fontawesome';
import Loading from '../../../components/loader/loading';
import Swal from 'sweetalert2';
import api from '../../../services/api';
import { Prefix } from '../../../services/prefix';
import { useFetch } from '../../../components/hooks/useFetch';
import { mutate as GlobalMutate } from 'swr';
import { Context } from '../../../services/context';
import { AsyncPaginate } from 'react-select-async-paginate';
import { loadOptions } from '../../../components/selectAsync/loadOptions';
import { colourStyles } from '../../../components/selectAsync/colourStyles';
import { Link } from 'react-router-dom';

const EditNotificationView = ({ id, id_contract_item }) => {
  const { showNotificationView, setShowNotificationView } = useContext(Context);

  const [pagina, setPagina] = useState(0);
  const [perPage, setPerPage] = useState(15);
  const [search, setSearch] = useState('');
  const [loader, setLoader] = useState();
  const [valueSelect, setValueSelect] = useState([]);
  const [formData, setFormData] = useState({
    noc: 1,
    active: 1,
  });

  let pageExact = pagina + parseInt(1);

  const handleShow = () => {
    setShowNotificationView({
      ...showNotificationView,
      id: id,
      id_contract_item: id_contract_item,
    });
  };

  const ToBack = () => {
    setShowNotificationView([]);
  };

  const notifiedEmployees = useFetch(
    Prefix.replace(/[\\"]/g, '') + '/contract-notifications/' + id,
  );

  const HandleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value.trim(),
    });
  };

  const HandleSelect = (e, name) => {
    if (valueSelect.filter((fill) => fill.nameField == name).length > 0) {
      valueSelect
        .filter((fill) => fill.nameField == name)
        .map((map) => {
          let valueIndice = valueSelect.indexOf(map);
          valueSelect.splice(valueIndice);
        });

      setValueSelect((prev) => {
        return [...prev, { value: e.value, label: e.label, nameField: name }];
      });
      setFormData({ ...formData, [name]: e.value });
    } else {
      setValueSelect((prev) => {
        return [...prev, { value: e.value, label: e.label, nameField: name }];
      });
      setFormData({ ...formData, [name]: e.value });
    }
  };

  const HandleRegister = () => {
    setLoader(<Loading />);
    api
      .put(
        Prefix.replace(/[\"]/g, '') +
          '/contract-notifications/' +
          showNotificationView.id,
        formData,
      )
      .then((response) => {
        Swal.fire({
          icon: 'success',
          title: 'Uow!',
          text: response.data.message,
          confirmButtonText: 'Fechar',
        }).then(() => {
          notifiedEmployees.mutate();
        });
        setLoader();
        GlobalMutate(
          Prefix.replace(/[\\"]/g, '') +
            '/contract-item-views?per_page=' +
            perPage +
            '&page=' +
            pageExact +
            '&search=' +
            search,
        );
      })
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: err.response.data.message,
          confirmButtonText: 'Tentar novamente',
        });
        setLoader();
      });
  };
  return (
    <>
      {showNotificationView.length == 0 ? (
        <Link to="#" onClick={handleShow} className="link_color_datatable">
          <Fontawesome classe="fas fa-pencil-alt" />
        </Link>
      ) : (
        ''
      )}
      {loader && <Loading />}

      {showNotificationView.length == 0 ? (
        ''
      ) : (
        <form className="customerStep" onSubmit={HandleRegister}>
          <Row>
            <Col>
              <div className="formStep">
                <label className="important">Colaborador(a)</label>
                <AsyncPaginate
                  value={
                    valueSelect.length == 0
                      ? {
                          value: notifiedEmployees.data?.model?.user.id,
                          label: notifiedEmployees.data?.model?.user.name,
                        }
                      : valueSelect
                          .filter((fill) => fill.nameField == 'id_user')
                          .map((map) => {
                            return {
                              value: map.value,
                              label: map.label,
                            };
                          })
                  }
                  loadOptions={loadOptions}
                  defaultValue={notifiedEmployees.data?.model?.id_user}
                  onChange={(e) => HandleSelect(e, 'id_user')} //values and name of select paginate
                  placeholder="Selecione o Colaborador(a)..."
                  //Set your param that is going to change when you select another field. Just use this option when you are setting params and need to refresh cache.
                  cacheUniqs={[valueSelect]}
                  styles={colourStyles}
                  additional={{
                    page: 1,
                    endpoint: '/client-employees',
                    // params: `id_client=${idClient}`,
                    paramId: 'user.id',
                    paramName: 'user.name',
                  }}
                />
              </div>
            </Col>
            <Col>
              <div className="formStep">
                <label class="important">Nome de Preferêncial: </label>
                <input
                  defaultValue={notifiedEmployees.data?.model?.name}
                  type="text"
                  placeholder="Informe seu Cognome..."
                  name="name"
                  onKeyUp={HandleChange}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="formStep">
                <label class="important">Plataforma</label>
                <select name="plataform" onChange={HandleChange}>
                  <option
                    value="email"
                    selected={
                      notifiedEmployees.data?.model?.plataform == 'email'
                        ? 'selected'
                        : ''
                    }
                  >
                    E-Mail
                  </option>
                  <option
                    value="whatsapp"
                    selected={
                      notifiedEmployees.data?.model?.plataform == 'whatsapp'
                        ? 'selected'
                        : ''
                    }
                  >
                    WhatsApp
                  </option>
                </select>
              </div>
            </Col>
            <Col>
              {formData?.plataform == 'email' ? (
                <div className="formStep">
                  <label class="important"> E-Mail</label>
                  <input
                    type="text"
                    defaultValue={notifiedEmployees.data?.model?.identification}
                    placeholder="Informe seu E-mail..."
                    name="identification"
                    onKeyUp={HandleChange}
                  />
                </div>
              ) : (
                <div className="formStep">
                  <label class="important"> WhatsApp</label>
                  <input
                    type="text"
                    defaultValue={notifiedEmployees.data?.model?.identification}
                    placeholder="Informe seu WhatsApp..."
                    name="identification"
                    onKeyUp={HandleChange}
                  />
                </div>
              )}
            </Col>
          </Row>
          {/* <Row className="mt-3">
            <Col  >
              <div class="custom-control custom-switch">
                <Form.Check
                  label="Monitorável"
                  id={'monitoring'}
                  type="switch"
                  name="monitoring"
                  id={!formData ? '' : 'monitoring' + formData?.id}
                  checked={
                    !formData ? '' : Boolean(formData?.monitoring)
                  }
                  onChange={HandleChecked}
                />
              </div>
            </Col>
          </Row> */}
          <Row className="justify-content-center mt-5">
            <Button className="mr-3" variant="success" onClick={HandleRegister}>
              Atualizar
            </Button>
            <Button variant="success" onClick={ToBack}>
              Voltar
            </Button>
          </Row>
        </form>
      )}
    </>
  );
};
export default EditNotificationView;
