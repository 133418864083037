import React from 'react';

const UserType = (props) => {
  return (
    <>
      {props.type[props.typeId] === 1
        ? 'Cliente'
        : props.type[props.typeId] === 2
        ? 'Técnico'
        : props.type[props.typeId] === 7
        ? 'Operador (Planejamento)'
        : props.type[props.typeId] === 8
        ? 'Comercial'
        : props.type[props.typeId] === 9
        ? 'Controladoria'
        : props.type[props.typeId] === 12
        ? 'Staff Administrativo'
        : props.type[props.typeId] === 13
        ? 'Administrador'
        : props.type[props.typeId] === 16
        ? 'Master'
        : 'Funcionário de Cliente'}
    </>
  );
};
export default UserType;
