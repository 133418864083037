import React, { useState, useRef, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useFetch } from '../../../components/hooks/useFetch';
import { Prefix } from '../../../services/prefix';
import './alerts.css';
import { Link } from 'react-router-dom';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import * as locales from 'react-date-range/dist/locale';
import { useOnClickOutside } from '../../../components/hooks/useOnClickOutSide';

const FilterAlerts = ({
  startDate,
  setStartDate,
  unit,
  setUnit,
  sector,
  setSector,
  room,
  setRoom,
  serial,
  setSerial,
  alert,
  setAlert,
}) => {
  const [showDate, setShowDate] = useState(false);

  const ref = useRef();
  useOnClickOutside(ref, () => setShowDate(false));

  const resetFilter = () => {
    setStartDate([
      {
        startDate: null,

        key: 'selection',
      },
    ]);
    setUnit('');
    setSector('');
    setRoom('');
    setSerial('');
    setAlert('');
  };

  const listUnits = useFetch(Prefix.replace(/[\\"]/g, '') + '/units');
  const listSectors = useFetch(Prefix.replace(/[\\"]/g, '') + '/sectors');
  const listRooms = useFetch(Prefix.replace(/[\\"]/g, '') + '/rooms');
  const listItems = useFetch(Prefix.replace(/[\\"]/g, '') + '/contract-items');
  return (
    <Row>
      <Col>
        <div className="filterAlerts">
          {!showDate ? (
            <Link to="#" onClick={() => setShowDate(true)}>
              {' '}
              <i
                class="far fa-calendar-alt"
                style={{ fontSize: '18px', marginRight: '10px' }}
              ></i>
            </Link>
          ) : (
            <div style={{ zIndex: '9999', position: 'absolute' }} ref={ref}>
              <DateRange
                editableDateInputs={true}
                onChange={(item) => setStartDate([item.selection])}
                moveRangeOnFirstSelection={false}
                ranges={startDate}
                locale={locales['pt']}
              />
            </div>
          )}
          <select onChange={(e) => setUnit(e.target.value)}>
            <option selected disabled>
              Filtrar por unidade
            </option>
            {listUnits.data?.models.data.map((map, key) => {
              return (
                <option key={key} value={map.id}>
                  {map.name}
                </option>
              );
            })}
          </select>
          {unit && (
            <select onChange={(e) => setSector(e.target.value)}>
              <option selected disabled>
                Filtrar por setor
              </option>
              {listSectors.data?.models.data.map((map, key) => {
                return (
                  <option key={key} value={map.id}>
                    {map.name}
                  </option>
                );
              })}
            </select>
          )}
          {sector && (
            <select onChange={(e) => setRoom(e.target.value)}>
              <option selected disabled>
                Filtrar por sala
              </option>
              {listRooms.data?.models.data.map((map, key) => {
                return (
                  <option key={key} value={map.id}>
                    {map.name}
                  </option>
                );
              })}
            </select>
          )}
          <select onChange={(e) => setSerial(e.target.value)}>
            {serial == '' ? (
              <option selected disabled>
                Filtrar por serial
              </option>
            ) : (
              <option value="">Todos</option>
            )}
            {listItems.data?.models.data.map((map) => {
              return <option value={map.serial}>{map.serial}</option>;
            })}
          </select>
          <select onChange={(e) => setAlert(e.target.value)}>
            {alert == '' ? (
              <option selected disabled>
                Filtrar por tipo
              </option>
            ) : (
              <option value="">Todos</option>
            )}
            <option value="WARNING">Alerta amarelo</option>
            <option value="DANGER">Alerta vermelho</option>
          </select>
          <Link to="#" className="ml-4" onClick={resetFilter}>
            <i class="fas fa-undo-alt"></i>
          </Link>
        </div>
      </Col>
    </Row>
  );
};
export default FilterAlerts;
