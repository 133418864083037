import React, { useState } from 'react';
import Fontawesome from '../../../components/fontawesome/fontawesome';
import '../equipamentsCategory.css';
import Alert from 'react-bootstrap/Alert';
import api from '../../../services/api';
import { Prefix } from '../../../services/prefix';
import Loading from '../../loader/loading';
import Swal from 'sweetalert2';
import { Modal, Badge, Button, Col, Row } from 'react-bootstrap';
import { mutate as GlobalMutate } from 'swr';
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';
import Schema from './schema';
import Slider from '@material-ui/core/Slider';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { AsyncPaginate } from 'react-select-async-paginate';
import { loadOptions } from '../../selectAsync/loadOptions';
import { colourStyles } from '../../selectAsync/colourStyles';
import { Switch } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { v4 as uuidv4 } from 'uuid';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { useStyles } from '../../buttons/AddButtonStyle';
import InputSpinner from 'react-bootstrap-input-spinner';
import { ModalContainer } from '../../../style/stylesModals';
import {
  CustomButton,
  TextButton,
  ButtonDesabled,
} from '../../../style/stylesButton';

const AddEquipamentsCategory = ({
  iconClass,
  titleLabel,
  buttonLabel,
  modalSize,
  pageExact,
  perPage,
  search,
  orderBy,
  order,
}) => {
  const [loader, setLoader] = useState();
  const [initialState, setInitialState] = useState({
    name: '',
    active: 1,
    id_equipment_family: '',
    complexity: '',
    corrective: false,
    preventive: false,
    calibration: false,
    default_calibration_frequency: 0,
    default_preventive_frequency: 0,
  });
  const [registerData, setRegisterData] = useState(initialState);
  const [family, setFamilies] = useState(0);
  const [show, setShow] = useState(false);
  const [valueSelect, setValueSelect] = useState([]);

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const HandleSelect = (e, name) => {
    if (valueSelect.filter((fill) => fill.nameField == name).length > 0) {
      valueSelect
        .filter((fill) => fill.nameField == name)
        .map((map) => {
          let valueIndice = valueSelect.indexOf(map);
          valueSelect.splice(valueIndice);
        });

      setValueSelect((prev) => {
        return [...prev, { value: e.value, label: e.label, nameField: name }];
      });
      setRegisterData({ ...registerData, [name]: e.value });
    } else {
      setValueSelect((prev) => {
        return [...prev, { value: e.value, label: e.label, nameField: name }];
      });
      setRegisterData({ ...registerData, [name]: e.value });
    }
  };

  const HandleChangeString = (e) => {
    setRegisterData({
      ...registerData,
      [e.target.name]: e.target.value,
    });
  };

  const HandleChange = (e) => {
    setRegisterData({ ...registerData, [e.target.name]: e.target.checked });
  };

  function HandleRegister(values) {
    setLoader(true);
    api
      .post(Prefix.replace(/[\\"]/g, '') + '/equipment-categories', values)
      .then(() => {
        Swal.fire({
          icon: 'success',
          title: 'Uow!',
          text: 'Cadastro realizado com sucesso!',
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: 'Fechar',
          cancelButtonText: 'Novo Cadastro',
          allowOutsideClick: '',
        }).then((result) => {
          if (!result.isConfirmed) {
            setShow(true);
          }
          setRegisterData({
            name: '',
            active: 1,
            id_equipment_family: '',
            complexity: '',
            corrective: false,
            preventive: false,
            calibration: false,
            default_calibration_frequency: '',
            default_preventive_frequency: '',
          });
          setValueSelect([]);
        });
        setLoader(false);
        setRegisterData(initialState);
        setShow(false);
        GlobalMutate(
          Prefix.replace(/[\\"]/g, '') +
          '/equipment-categories?per_page=' +
          perPage +
          '&page=' +
          pageExact +
          '&search=' +
          search +
          '&order_by=' +
          orderBy +
          '&order=' +
          order,
        );
      })
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: err.response.data.message,
          confirmButtonText: 'Tentar novamente',
        });
        setLoader(false);
      });
  }

  const AntSwitch = withStyles((theme) => ({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: 'flex',
    },
    switchBase: {
      padding: 2,
      color: '#ADB5BD',
      '&$checked': {
        transform: 'translateX(12px)',
        color: '#ffffff',
        '& + $track': {
          opacity: 1,
          backgroundColor: '#1BC5BD',
          borderColor: '#1BC5BD',
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: 'none',
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
    },
    checked: {},
  }))(Switch);

  const classes = useStyles();

  return (
    <>
      {!show ? (
        <Fab className={classes.fab} onClick={handleShow}>
          <AddIcon className={classes.icon} />
        </Fab>
      ) : (
        <ModalContainer
          show={show}
          size={modalSize}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <h3>{titleLabel}</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={registerData}
              validationSchema={Schema}
              onSubmit={HandleRegister}
              enableReinitialize
            >
              {({ errors, values, setFieldValue }) => (
                <Form>
                  <Row>
                    <Col>
                      <div className="formStep">
                        <div className="labelForm">
                          <label className="important">Nome completo</label>
                        </div>
                        <Field
                          type="text"
                          placeholder="Digite um nome"
                          name="name"
                          onChange={HandleChangeString}
                        />
                        <ErrorMessage
                          name="name"
                          component="span"
                          className="errorMessages"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col style={{ zIndex: '100' }}>
                      <div className="formStep">
                        <label class="important">Família do Equipamento:</label>
                        <div>
                          <AsyncPaginate
                            value={valueSelect
                              .filter(
                                (fill) =>
                                  fill.nameField == 'id_equipment_family',
                              )
                              .map((map) => {
                                return {
                                  value: map.value,
                                  label: map.label,
                                };
                              })}
                            loadOptions={loadOptions}
                            onChange={(e) => {
                              HandleSelect(e, 'id_equipment_family');
                            }}
                            placeholder="Selecione..."
                            cacheUniqs={[valueSelect]}
                            styles={colourStyles}
                            additional={{
                              page: 1,
                              endpoint: '/equipment-families',
                              // params: `uf=${values.uf}`, //Separete with & (and commercial)
                            }}
                          />
                          <ErrorMessage
                            name="id_equipment_family"
                            component="span"
                            className="errorMessages"
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="formStep">
                        <label>Complexidade:</label>
                        <OverlayTrigger
                          overlay={
                            <Tooltip id="tooltip-disabled">
                              Quanto menor o valor, menor a complexidade do
                              equipamento e vice-versa.
                            </Tooltip>
                          }
                        >
                          <a href="#" style={{ color: '#000', marginLeft: 5 }}>
                            <i className="far fa-question-circle"></i>
                          </a>
                        </OverlayTrigger>
                        <div>
                          <InputSpinner
                            type="number"
                            name="complexity"
                            variant="success"
                            size="md"
                            precision={2}
                            max={5}
                            min={0}
                            value={
                              !registerData.complexity
                                ? 0
                                : registerData.complexity
                            }
                            onChange={(e) =>
                              setRegisterData({
                                ...registerData,
                                ['complexity']: e,
                              })
                            }
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="text-center mt-3">
                      <h3>Tipos de Serviços</h3>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="text-center">
                      <label class="important">
                        Selecione ao menos um tipo de serviço abaixo:{' '}
                      </label>
                    </Col>
                  </Row>

                  <Alert
                    className="mt-3"
                    style={{ backgroundColor: '#f0f1f2', border: 0 }}
                  >
                    <Row>
                      <Col className="text-center mt-2 mb-2">
                        <h4>Padrão</h4>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="text-center">
                        <div className="custom-control custom-switch customerStep">
                          <Form>
                            <label>Corretiva</label>
                            <FormControlLabel
                              control={
                                <AntSwitch
                                  checked={values.corrective}
                                  onChange={HandleChange}
                                  name="corrective"
                                  label="Corretiva"
                                />
                              }
                            />
                            <ErrorMessage
                              name="corrective"
                              component="span"
                              className="errorMessages"
                            />
                          </Form>
                        </div>
                      </Col>
                      <Col className="text-center">
                        <div className="custom-control custom-switch customerStep ">
                          <Form>
                            <label>Preventiva</label>
                            <FormControlLabel
                              control={
                                <AntSwitch
                                  checked={values.preventive}
                                  onChange={HandleChange}
                                  name="preventive"
                                  label="Preventiva"
                                />
                              }
                            />
                            <ErrorMessage
                              name="preventive"
                              component="span"
                              className="errorMessages"
                            />
                          </Form>
                        </div>
                      </Col>
                      <Col className="text-center">
                        <div className="custom-control custom-switch customerStep ">
                          <Form>
                            <label>Calibração</label>
                            <FormControlLabel
                              control={
                                <AntSwitch
                                  checked={values.calibration}
                                  onChange={HandleChange}
                                  name="calibration"
                                />
                              }
                            />
                            <ErrorMessage
                              name="calibration"
                              component="span"
                              className="errorMessages"
                            />
                          </Form>
                        </div>
                      </Col>
                    </Row>
                    <br />
                  </Alert>
                  <Row>
                    <Col className="text-center mt-3">
                      <h3>Periodicidade</h3>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="formStep text-center">
                        <label>Calibração</label>
                        <div>
                          <InputSpinner
                            type="number"
                            name="default_calibration_frequency"
                            variant="success"
                            size="md"
                            precision={2}
                            min={0}
                            max={12}
                            value={
                              !registerData.default_calibration_frequency
                                ? 0
                                : registerData.default_calibration_frequency
                            }
                            onChange={(e) =>
                              setRegisterData({
                                ...registerData,
                                ['default_calibration_frequency']: e,
                              })
                            }
                          />
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <div className="formStep text-center">
                        <label>Prevenção</label>
                        <div>
                          <InputSpinner
                            type="number"
                            name="default_preventive_frequency"
                            variant="success"
                            size="md"
                            precision={2}
                            min={0}
                            max={12}
                            value={
                              !registerData.default_preventive_frequency
                                ? 0
                                : registerData.default_preventive_frequency
                            }
                            onChange={(e) =>
                              setRegisterData({
                                ...registerData,
                                ['default_preventive_frequency']: e,
                              })
                            }
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="d-flex justify-content-center mt-3">
                      {!values.id_equipment_family ||
                        !values.name ||
                        ((values.corrective === false ||
                          values.corrective === 0) &&
                          (values.preventive === false ||
                            values.preventive === 0) &&
                          (values.calibration === false ||
                            values.calibration === 0)) ? (
                        <>
                          <ButtonDesabled onClick={HandleRegister} disabled>
                            <TextButton> Adicionar </TextButton>
                          </ButtonDesabled>
                        </>
                      ) : (
                        <>
                          <CustomButton onClick={HandleRegister}>
                            <TextButton> Adicionar </TextButton>
                          </CustomButton>
                        </>
                      )}
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Modal.Body>
        </ModalContainer>
      )}
    </>
  );
};
export default AddEquipamentsCategory;
