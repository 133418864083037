import React, { useState } from "react";
import { Col, Row, Alert, Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { IconOption } from "../../optionsProposalsStyle";
import { mutate as GlobalMutate } from "swr";
import api from "../../../../services/api";
import { Prefix } from "../../../../services/prefix";
import Loading from "../../../loader/loading";
import { useFetch } from "../../../hooks/useFetch";
import CustomSearchRender from "../../../customSearchRender/CustomSearchRender";
import CustomFooter from "../../../CustomFooter/CustomFooter";
import { Caption } from "./styles";
import { ModalContainer } from "../../../../style/stylesModals";
import { BoxFeedback, DateFeedback, TextFeedback } from "./styles";
import moment from "moment";

const Feedback = ({ id }) => {
  const [Loader, setLoader] = useState(false);
  const [pagina, setPagina] = useState(0);
  const [perPage, setPerPage] = useState(15);
  const [search, setSearch] = useState("");
  const [show, setShow] = useState(false);
  let pageExact = pagina + parseInt(1);

  const feedbackList = useFetch(
    Prefix.replace(/[\\"]/g, "") +
      "/proposals?all=false&per_page=" +
      perPage +
      "&page=" +
      pageExact +
      "&search=" +
      search +
      "&id=" +
      id
  );

  const dateValue = feedbackList.data?.models?.data[0].feedback_at.split("T");

  const handleShow = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  return (
    <>
      {!show ? (
        <Link className="dropdown-item" to="#" onClick={handleShow}>
          <IconOption src="../../../media/icons/feedback.svg" />
          Feedback
        </Link>
      ) : (
        <ModalContainer show={show} onHide={handleClose} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Feedback</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Caption>Retorno de rejeição da proposta</Caption>
            <div className="d-flex justify-content-center">
              {!feedbackList.data?.models?.data[0].feedback ? (
                <BoxFeedback>
                  <TextFeedback>
                  Proposta aguardando retorno do cliente...
                  </TextFeedback>
                </BoxFeedback>
              ) : (
                <BoxFeedback>
                  <TextFeedback>
                    {feedbackList.data?.models?.data[0].feedback}
                  </TextFeedback>
                  <DateFeedback>
                    Data: {moment(dateValue).format("DD/MM/YYYY")}
                  </DateFeedback>
                </BoxFeedback>
              )}
            </div>
          </Modal.Body>
        </ModalContainer>
      )}
    </>
  );
};
export default Feedback;
