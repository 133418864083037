import React, { useState, useEffect } from 'react'
import { ModalContainer } from '../../../../style/stylesModals';
import { Modal, Table } from 'react-bootstrap';
import { useStyles } from '../../../../components/buttons/AddButtonStyle';
import { ImgIcon } from "./style"
import moment from 'moment/moment';
import { useSWRInfinite } from 'swr';
import { useFetch } from '../../../../components/hooks/useFetch';
import api from '../../../../services/api';
import { Prefix } from '../../../../services/prefix';


const AlertsDetails = ({ contract_item, epoch, value, max, min, parent }) => {

  const [loader, setLoader] = useState();
  const [show, setShow] = useState(false);
  const [dataTime, setDataTime] = useState();
  const [stop, setStop] = useState();
  const [measure, setMeasure] = useState();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const query = useFetch(show && (
    Prefix.replace(/[\\"]/g, '') +
    '/readings/parent-warnings?parent=' + parent
  ));
  const epochs = query.data?.model

  const classes = useStyles();

  return (
    <>
      <div onClick={handleShow}>
        <ImgIcon src='/media/icons/info.svg' />
      </div>
      <ModalContainer
        show={show}
        size="lg"
        onHide={handleClose}
        backdrop="static"
        keyboard={true}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title><h3>Informações do Equipamento</h3></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered responsive>
            <thead>
              <tr className='text-center'>
                <th>Cod.</th>
                <th colSpan={3} >Equipamento</th>
                <th >Serial</th>
              </tr>
            </thead>
            <tbody>
              <tr className='text-center'>
                <td >{contract_item?.id}</td>
                <td colSpan={3} >{contract_item?.nickname}</td>
                <td >{contract_item?.serial}</td>
              </tr>
            </tbody>
            {/* -------------------------Localização---------------------------- */}
            <thead>
              <tr className='text-center'>
                <th>Estado</th>
                <th>Cidade</th>
                <th>Unidade</th>
                <th>Setor</th>
                <th>Sala</th>
              </tr>
            </thead>
            <tbody>
              <tr className='text-center'>
                <td >{contract_item?.room?.sector?.unit?.city?.uf}</td>
                <td>{contract_item?.room?.sector?.unit?.city?.name}</td>
                <td>{contract_item?.room?.sector?.unit?.name}</td>
                <td>{contract_item?.room?.sector?.name}</td>
                <td>{contract_item?.room?.name}</td>
              </tr>
            </tbody>
          </Table>

          <Table striped bordered responsive>
            <thead>
              <tr className='text-center'>
                <th colSpan={5} className="text-center">Dados de Monitoramento</th>
              </tr>
              <tr className='text-center'>
                <th style={{ width: "15%" }}>Máximo</th>
                <th style={{ width: "15%" }}>Mínimo</th>
                <th style={{ width: "15%" }}>Valor Extrapolado</th>
                <th style={{ width: "15%" }}>Unid. de Medida</th>
                <th>Intervalo de Ocorrência</th>
              </tr>
            </thead>
            <tbody>
              <tr className='text-center'>
                <td style={{ width: "15%" }}>{max}</td>
                <td style={{ width: "15%" }} >{min}</td>
                <td style={{ width: "15%" }} className=" text-center ">
                  <div className='d-flex justify-content-center'>
                    <div style={{ marginRight: "5px" }}>
                      {value}
                    </div>
                    < div >
                      {
                        value < min ? (<img src="/media/icons/svg/Code/down.svg" />) :
                          value > max ? (<img src="/media/icons/svg/Code/up.svg" />) :
                            value < max && value > min ? (<img src="/media/icons/svg/Code/warning-alerta.svg" style={{ width: "30px" }} />) : null
                      }
                    </div>
                  </div>
                </td>
                <td className='text-center'>
                  {
                    contract_item?.monitor_profile_attruibutes[0]?.profile_attribute?.unit_of_measure?.name
                  }
                </td>
                <td>
                  {epochs?.epoch_end ? (moment(epochs?.epoch_init).format('DD/MM/YYYY HH:mm:ss') + " até " + moment(epochs?.epoch_end).format('DD/MM/YYYY HH:mm:ss'))
                    :
                    (moment(epochs?.epoch_init).format('DD/MM/YYYY HH:mm:ss'))
                  }
                </td>
              </tr>
            </tbody>
          </Table>
        </Modal.Body>
      </ModalContainer>
    </>

  )
}
export default AlertsDetails;
