import React from 'react';
import UpdateStatus from '../../contracts/updateStatus/updateModalSchemes.jsx';
import Exclusion from './licenseExclusion';

export const columns = [
  {
    name: 'id',
    label: 'id',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      display: 'excluded',
    },
  },

  {
    name: 'name',
    label: 'Nome da Licença',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
    },
  },

  {
    name: 'active',
    label: 'Status',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: true,
      sort: false,
      customBodyRender: (value, tableMeta) => {
        return <UpdateStatus name={tableMeta.rowData[1]} />;
      },
    },
  },
  {
    name: 'id',
    label: 'Excluir',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: true,
      sort: false,
      customBodyRender: (value, tableMeta) => {
        return <Exclusion id={tableMeta.rowData[0]} />;
      },
    },
  },
];
