import * as Yup from "yup";

Yup.setLocale({
  mixed: {
    required: "Campo obrigatório",
  },
  string: {
    email: "Preencha um e-mail válido",
    min: "Mínimo de ${min} caracteres",
    max: "Máximo de ${max} caracteres",
  },
  number: {
    min: "Deve ser maior ou igual a ${min}",
    max: "Deve ser menor ou igual a ${max}",
  },
});

const Schema = Yup.object().shape({
  initialData: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().min(3).required(),
      company: Yup.string().required(),
      cnpj: Yup.string().max(20).required(),
      phone: Yup.string().min(10).required(),
      phone2: Yup.string().min(10),
      email: Yup.string().email().required(),
      address: Yup.string(),
      neighborhood: Yup.string(),
      uf: Yup.string(),
      zipcode: Yup.string(),
      id_city: Yup.number().required(),
      description: Yup.string().required(),
    })
  ),
});
export default Schema;
