import React, { memo, useContext, useEffect } from 'react';
import './sidebar.css';
import Logotipo from '../logotipo/logotipo';
import Navbar from 'react-bootstrap/Navbar';
import Select from 'react-select';
import NavbarDefault from './navbar';
import { Prefix } from '../../services/prefix';
import { Context } from '../../services/context';
import { useLocation } from 'react-router-dom';
import history from '../../services/history';
import { useFetch } from '../hooks/useFetch';
import { useWindowSize } from '@react-hook/window-size';

const Sidebar = () => {
  const context = useContext(Context);

  let group = Prefix.replace(/[\\"]/g, '');
  let location = useLocation();
  let pathname = location.pathname.split('/')[1];

  const listLicences = useFetch('/user');
  const licences = listLicences.data?.customer?.licenses;

  const options =
    group == 'customer'
      ? licences && licences.filter((fill) => fill.active == 1)
      : [
          { value: '1', label: 'GESTÃO', active: 1 },
          { value: '2', label: 'MONITORAMENTO', active: 1 },
          // { value: '3', label: 'PLANEJAMENTO', active: 1 },
        ];

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? '#5a1112' : '#333',
      padding: 5,
      paddingLeft: 15,
      background: '#fff',
      fontWeight: state.isSelected ? 'bold' : '',
    }),
  };

  function redirectApp(e) {
    if (group == 'admin' && e.label == 'GESTÃO') {
      history.push('/gestao/inicio');
      window.location.reload();
    } else if (group == 'customer' && e.label == 'GESTÃO') {
      history.push('/gestao/cliente/inicio');
      window.location.reload();
    } else if (e.label == 'MONITORAMENTO') {
      history.push('/monitoramento/inicio');
      window.location.reload();
    } else if (e.label == 'PLANEJAMENTO') {
      history.push('/planejamento/inicio');
      window.location.reload();
    } else {
      history.push('/');
      window.location.reload();
    }
  }

  const [width, height] = useWindowSize();

  useEffect(() => {
    width <= 768
      ? context.setIsActiveToggle(true)
      : context.setIsActiveToggle(false);
  }, [width]);

  return (
    <>
      <Navbar
        id="sidebar"
        className={!context.isActiveToggle ? 'active' : 'disabled'}
      >
        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        <div className="pl-4 pr-1">
          <div className="d-flex justify-content-center mb-2">
            <Logotipo
              src="../../media/logos/logo-dtlab.svg"
              classe="fade-in mt_logo_sidebar img-fluid"
              alt="Logo DTLAB"
            />
          </div>

          <div className="mb-4">
            {group == 'tech' ? (
              ''
            ) : (
              <Select
                styles={customStyles}
                options={options}
                value={
                  !options
                    ? ''
                    : pathname == 'gestao'
                    ? options[0]
                    : pathname == 'monitoramento'
                    ? options[1]
                    : options[3]
                }
                placeholder="Escolher sistema..."
                onChange={redirectApp}
              />
            )}
          </div>
          <NavbarDefault />
        </div>
      </Navbar>
    </>
  );
};

export default memo(Sidebar);
