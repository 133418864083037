import styled from 'styled-components';

export const ExportContent = styled.div`
  background: #fff;
  border-radius: 30px;
`;

export const InputDate = styled.input`
  width: 100%;
  height: 40px;
`;

export const ButtonSend = styled.button`
  background: #00ba88;
  border-radius: 100px;
  height: 40px;
  border: 0;
  color: #fff;
  padding: 0 5px;
  width: 25%;

  &:hover {
    opacity: 0.5;
    transition: 0.5s;
  }
`;
