import React, { useState, useEffect } from "react";
import { Row, Col, Button, Modal } from "react-bootstrap";
import "../measures.css";
import { uf } from "../../data/uf";
import api from "../../../services/api";
import Fontawesome from "../../fontawesome/fontawesome";
import { Prefix } from "../../../services/prefix";
import Loading from "../../loader/loading";
import Swal from "sweetalert2";
import { useFetch } from "../../hooks/useFetch";
import { mutate as mutateGlobal } from "swr";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Schema from "./schema";
import axios from "axios";
import Select, { components } from "react-select";
import { colourStyles } from "../../selectAsync/colourStyles";
import { NoOptionsMessage } from "../../selectAsync/NoOptionsMessage";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import { useStyles } from "../../buttons/AddButtonStyle";
import { ModalContainer } from "../../../style/stylesModals";
import { CustomButton, TextButton, ButtonDesabled } from "../../../style/stylesButton";

const AddMeasure = (props) => {
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const [initialState, setInitialState] = useState({
    name: "",
    active: 1,
    id_monitor: "",
  });
  const [registerData, setRegisterData] = useState(initialState);
  const [valueSelect, setValueSelect] = useState([]);

  const handleClose = () => {
    setShow(false);
    setRegisterData(initialState);
  };
  const handleShow = () => {
    setShow(true);
  };

  const options = [
    { value: "1", label: "Temperatura" },
    { value: "2", label: "Umidade" },
  ];

  const listFamilies = useFetch(
    Prefix.replace(/[\\"]/g, "") + "/equipment-families"
  );

  function HandleRegister(values) {
    setLoader(true);
    api
      .post(Prefix.replace(/[\\"]/g, "") + "/measures", values)
      .then(() => {
        Swal.fire({
          icon: "success",
          title: "Uow!",
          text: "Cadastro realizado com sucesso!",
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: "Fechar",
          cancelButtonText: "Novo Cadastro",
          allowOutsideClick: false,
        }).then((result) => {
          if (!result.isConfirmed) {
            setShow(true);
          }
          setRegisterData({
            name: "",
            active: 1,
            id_monitor: "",
          });
          setValueSelect([]);
        });
        setLoader(false);
        setRegisterData(initialState);
        setShow(false);
        mutateGlobal(
          Prefix.replace(/[\\"]/g, "") +
          "/measures?per_page=" +
          props.perPage +
          "&page=" +
          props.pageExact +
          "&search=" +
          props.search +
          "&order_by=" +
          props.orderBy +
          "&order=" +
          props.order
        );
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: err.response.data.message,
          confirmButtonText: "Tentar novamente",
        });
        setLoader(false);
      });
  }

  const HandleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setRegisterData({ ...registerData, [name]: value });
  };

  const HandleSelect = (e, name) => {
    if (valueSelect.filter((fill) => fill.nameField == name).length > 0) {
      valueSelect
        .filter((fill) => fill.nameField == name)
        .map((map) => {
          let valueIndice = valueSelect.indexOf(map);
          valueSelect.splice(valueIndice);
        });

      setValueSelect((prev) => {
        return [...prev, { value: e.value, label: e.label, nameField: name }];
      });
      setRegisterData({ ...registerData, [name]: e.value });
    } else {
      setValueSelect((prev) => {
        return [...prev, { value: e.value, label: e.label, nameField: name }];
      });
      // setRegisterData({ ...registerData, [name]: e.value });
      // setRegisterData({ ...registerData, 'name': e.label });
    }
  };

  const classes = useStyles();

  return (
    <>
      {show === false && (
        <Fab className={classes.fab} onClick={handleShow}>
          <AddIcon className={classes.icon} />
        </Fab>
      )}
      <ModalContainer
        show={show}
        size={props.modalSize}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title><h3>{props.titleLabel}</h3></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loader && <Loading />}
          <Formik
            initialValues={registerData}
            validationSchema={Schema}
            onSubmit={HandleRegister}
            enableReinitialize
          >
            {({ values, setFieldValue }) => (
              <Form>
                <Row>
                  <Col>
                    <div className="formStep">
                      <div className="customerStep">
                        <label className="important">
                          Tipo de Monitoramento
                        </label>
                      </div>
                      <Select
                        value={valueSelect
                          .filter((fill) => fill.nameField == "id_monitor")
                          .map((map) => {
                            return {
                              value: map.value,
                              label: map.label,
                            };
                          })}
                        styles={colourStyles}
                        onChange={(e) => {
                          HandleSelect(e, "id_monitor");
                          setRegisterData({
                            ...registerData,
                            name: e.label,
                            id_monitor: e.value,
                          });
                        }}
                        placeholder="Selecione..."
                        options={options}
                      />
                      <ErrorMessage
                        name="id_monitor"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className="d-flex justify-content-center">
                    <CustomButton type="submit">
                      <TextButton> Adicionar </TextButton>
                    </CustomButton>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </ModalContainer>
    </>
  );
};
export default AddMeasure;
