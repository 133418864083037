import React from 'react';

export const columns = [
  {
    //0
    name: 'id',
    label: '',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',

        },
      }),
      filter: false,
      sort: false,
      display: 'excluded',
    },
  },

  {
    //1
    name: 'city',
    label: 'Nome',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',

        },
      }),
      filter: false,
      sort: false,
      customBodyRender: (value, tableMeta) => {
        return value.name;
      },
    },
  },
  // {
  //   //2
  //   name: 'contract_item',
  //   label: 'Serial',
  //   options: {
  //     print: false,
  //     setCellHeaderProps: () => ({
  //       style: {
  //         'text-align': 'center',
  //       },
  //     }),
  //     setCellProps: () => ({
  //       style: {
  //         'text-align': 'center',
  //       },
  //     }),
  //     setCellHeaderProps: () => ({
  //       style: {
  //         'text-align': 'center',
  //         width: '30%',

  //       },
  //     }),
  //     filter: true,
  //     sort: false,
  //     customBodyRender: (value, tableMeta) => {
  //       return value.serial
  //     },
  //   },
  // },
];
