import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import Fontawesome from '../../fontawesome/fontawesome';
import { Context } from '../../../services/context';
import { useFetch } from '../../hooks/useFetch';
import { Prefix } from '../../../services/prefix';
import Swal from 'sweetalert2';
import api from '../../../services/api';
import { mutate as GlobalMutate } from 'swr';

const Exclusion = ({ id }) => {
  const { idContractLicenses } = useContext(Context);

  const licenseContract = useFetch(
    Prefix.replace(/[\\"]/g, '') +
      '/contract-licenses?id_contract=' +
      idContractLicenses +
      '&id_scheme=' +
      id,
  );

  const idDel = licenseContract.data?.models.data.map((map) => map.id);

  function HandleDelete(e) {
    api
      .delete(Prefix.replace(/[\\"]/g, '') + '/contract-licenses/' + idDel)
      .then((response) => {
        Swal.fire({
          icon: 'success',
          title: 'Uow!',
          text: response.data.message,
          confirmButtonText: 'Fechar',
        });
        GlobalMutate(Prefix.replace(/[\\"]/g, '') + '/scheme');
        GlobalMutate(Prefix.replace(/[\\"]/g, '') + '/contract-licenses');
      })
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: !err.response
            ? 'Houve um problema ou esta licença nunca foi habilitada!'
            : err.response.data.message,
          confirmButtonText: 'Tentar novamente',
        });
      });
  }

  return (
    <>
      <Link to="#" onClick={HandleDelete} className="link_color_datatable">
        <Fontawesome classe="fas fa-trash-alt" />
      </Link>
    </>
  );
};
export default Exclusion;
