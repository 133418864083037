import React, { memo } from 'react';
import './sidebar.css';
import { useLocation } from 'react-router-dom';
import NavPlanning from './navPlanning';
import NavDtlab from './navDtlab';
import NavDtlabClient from './navDtlabClient';
import NavSismogran from './navSismogran';
import NavCommercial from './navCommercial';
import { Prefix } from '../../services/prefix';

const NavbarDefault = () => {
  let location = useLocation();
  let pathname = location.pathname.split('/')[1];
  let group = Prefix.replace(/[\\"]/g, '');
  return (
    <ul className="list-unstyled ">
      {pathname == 'gestao' && group == 'admin' ? (
        <NavDtlab group={group} />
      ) : pathname == 'gestao' && group == 'customer' ? (
        <NavDtlabClient group={group} />
      ) : pathname == 'monitoramento' ? (
        <NavSismogran group={group} />
      ) : pathname == 'comercial' ? (
        <NavCommercial group={group} />
      ) : (
        ''
      )}
    </ul>
  );
};

export default memo(NavbarDefault);
