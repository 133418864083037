import React, { useState, useEffect } from "react";
import { Row, Col, Button, Modal } from "react-bootstrap";
import "../customer.css";
import { uf } from "../../data/uf";
import api from "../../../services/api";
import { Prefix } from "../../../services/prefix";
import Loading from "../../loader/loading";
import Swal from "sweetalert2";
import { useFetch } from "../../hooks/useFetch";
import { mutate as mutateGlobal } from "swr";
import {
  maskCEP,
  maskTel,
  maskTelFixo,
  maskCpfCnpj,
} from "../../../services/mask";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Schema from "./schema";
import { cpf, cnpj } from "cpf-cnpj-validator";
import axios from "axios";
import Select from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import { loadOptions } from "../../selectAsync/loadOptions";
import { colourStyles } from "../../selectAsync/colourStyles";
import { NoOptionsMessage } from "../../selectAsync/NoOptionsMessage";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import { useStyles } from "../../buttons/AddButtonStyle";
import { ModalContainer } from "../../../style/stylesModals";
import { CustomButton, TextButton } from "../../../style/stylesButton";

const IndexAddCustomer = ({
  iconClass,
  titleLabel,
  buttonLabel,
  modalSize,
  pageExact,
  perPage,
  search,
  orderBy,
  order,
}) => {
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const [messageDocument, setMessageDocument] = useState(null);
  const [zipCode, setZipCode] = useState("00000000");
  const [initialState, setInitialState] = useState({
    name: "",
    corporate_name: "",
    fantasy_name: "",
    document: "",
    document_type: "",
    address: "",
    number: "",
    neighborhood: "",
    zipcode: "",
    uf: "",
    id_city: "",
    email: "",
    phone1: "",
    phone2: "",
    password: "",
    password_confirmation: "",
    active: 1,
  });
  const [registerData, setRegisterData] = useState(initialState);
  const [valueSelect, setValueSelect] = useState([]);
  const [nameCity, setNameCity] = useState("");

  const handleClose = () => {
    setShow(false);
    setRegisterData(initialState);
  };
  const handleShow = () => {
    setShow(true);
  };

  const listCity = useFetch(
    Prefix.replace(/[\\"]/g, "") + "/cities?search=" + nameCity
  );

  useEffect(() => {
    if (zipCode !== "00000000") {
      axios.get(`https://viacep.com.br/ws/${zipCode}/json/`).then((r) => {
        setNameCity(r.data.localidade);

        if (
          valueSelect.filter((fill) => fill.nameField == "id_city").length == 0
        ) {
          setValueSelect((prev) => {
            return [
              ...prev,
              {
                value: listCity.data?.models?.data[0]?.id,
                label: r.data.localidade,
                nameField: "id_city",
              },
            ];
          });
        } else {
          const valuesForwardCity = {
            value: listCity.data?.models?.data[0]?.id,
            label: r.data.localidade,
          };
          HandleSelect(valuesForwardCity, "id_city");
        }

        if (valueSelect.filter((fill) => fill.nameField == "uf").length == 0) {
          setValueSelect((prev) => {
            return [
              ...prev,
              {
                value: r.data.uf,
                label: uf.filter((fill) => fill.value == r.data.uf)[0]?.name,
                nameField: "uf",
              },
            ];
          });
        } else {
          const valuesForwardUf = {
            value: r.data.uf,
            label: uf.filter((fill) => fill.value == r.data.uf)[0].name,
          };
          HandleSelect(valuesForwardUf, "uf");
        }

        api
          .get(
            Prefix.replace(/[\\"]/g, "") + "/cities?search=" + r.data.localidade
          )
          .then((response) => {
            setRegisterData({
              ...registerData,
              address: r.data.logradouro,
              neighborhood: r.data.bairro,
              uf: r.data.uf,
              id_city: response.data?.models?.data[0]?.id,
            });
          });
      });
    }
  }, [zipCode]);

  function HandleRegister(values) {
    setLoader(true);
    //Validate to delete phone2 of object
    if (values.phone2 == "") {
      delete values.phone2;
    }

    api
      .post(Prefix.replace(/[\\"]/g, "") + "/customers", values)
      .then(() => {
        Swal.fire({
          icon: "success",
          title: "Uow!",
          text: "Cadastro realizado com sucesso!",
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: "Fechar",
          cancelButtonText: "Novo Cadastro",
          allowOutsideClick: false,
        }).then((result) => {
          if (!result.isConfirmed) {
            setShow(true);
          }
        });
        setLoader(false);
        setRegisterData(initialState);
        setShow(false);
        mutateGlobal(
          Prefix.replace(/[\\"]/g, "") +
            "/customers?per_page=" +
            perPage +
            "&page=" +
            pageExact +
            "&search=" +
            search +
            "&order_by=" +
            orderBy +
            "&order=" +
            order
        );
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: err.response.data.message,
          confirmButtonText: "Tentar novamente",
        });
        setLoader(false);
      });
  }

  const HandleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setRegisterData({ ...registerData, [name]: value });
  };

  const HandleSelect = (e, name) => {
    if (valueSelect.filter((fill) => fill.nameField == name).length > 0) {
      valueSelect
        .filter((fill) => fill.nameField == name)
        .map((map) => {
          let valueIndice = valueSelect.indexOf(map);
          valueSelect.splice(valueIndice);
        });

      setValueSelect((prev) => {
        return [...prev, { value: e.value, label: e.label, nameField: name }];
      });
      setRegisterData({ ...registerData, [name]: e.value });
    } else {
      setValueSelect((prev) => {
        return [...prev, { value: e.value, label: e.label, nameField: name }];
      });
      setRegisterData({ ...registerData, [name]: e.value });
    }
  };

  const handleZipCode = (e) => {
    setZipCode(e.target.value.replace(".", "").replace("-", ""));
  };

  const validateDocument = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    let document = value
      .replace("/", "")
      .replace("-", "")
      .replace(".", "")
      .replace(".", "");
    if (document.length <= 11) {
      setRegisterData({ ...registerData, [name]: value, document_type: "cpf" });
      setMessageDocument(cpf.isValid(document));
    } else {
      setRegisterData({
        ...registerData,
        [name]: value,
        document_type: "cnpj",
      });
      setMessageDocument(cnpj.isValid(document));
    }
  };

  const classes = useStyles();

  return (
    <>
      {show === false && (
        <Fab className={classes.fab} onClick={handleShow}>
          <AddIcon className={classes.icon} />
        </Fab>
      )}
      <ModalContainer
        show={show}
        size={modalSize}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h3>{titleLabel}</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loader && <Loading />}
          <Formik
            components={{ NoOptionsMessage }}
            initialValues={registerData}
            validationSchema={Schema}
            onSubmit={HandleRegister}
            enableReinitialize
          >
            {({ values }) => (
              <Form>
                <Row>
                  <Col>
                    <div className="formStep">
                      <div className="labelForm">
                        <label className="important">Nome completo</label>
                      </div>
                      <Field
                        type="text"
                        placeholder="Digite um nome"
                        name="name"
                        onChange={HandleChange}
                      />
                      <ErrorMessage
                        name="name"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="formStep">
                      <label>Razão Social</label>
                      <Field
                        type="text"
                        placeholder="Digite uma razão social"
                        name="corporate_name"
                        onChange={HandleChange}
                      />
                      <ErrorMessage
                        name="corporate_name"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="formStep">
                      <label>Nome Fantasia</label>
                      <Field
                        type="text"
                        placeholder="Digite um nome fantasia"
                        name="fantasy_name"
                        onChange={HandleChange}
                      />
                      <ErrorMessage
                        name="fantasy_name"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div className="formStep">
                      <div className="labelForm">
                        <label className="important">CPF/CNPJ</label>
                      </div>
                      <Field
                        type="text"
                        placeholder={"Digite um CPF ou CNPJ"}
                        name="document"
                        onKeyPress={maskCpfCnpj}
                        onBlur={validateDocument}
                      />
                      {registerData.document_type == "cpf" &&
                      messageDocument === false ? (
                        <span className="errorMessages">CPF inválido</span>
                      ) : registerData.document_type == "cnpj" &&
                        messageDocument === false ? (
                        <span className="errorMessages">CNPJ inválido</span>
                      ) : (
                        <ErrorMessage
                          name="document"
                          component="span"
                          className="errorMessages"
                        />
                      )}
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div className="formStep">
                      <label className="important">CEP</label>
                      <Field
                        type="text"
                        placeholder="Digite um CEP"
                        name="zipcode"
                        onKeyPress={maskCEP}
                        onBlur={handleZipCode}
                        onChange={HandleChange}
                      />

                      <ErrorMessage
                        name="zipcode"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                  <Col>
                    <div className="formStep">
                      <label className="important">Estado</label>

                      <Select
                        value={valueSelect
                          .filter((fill) => fill.nameField == "uf")
                          .map((map) => {
                            return {
                              value: map.value,
                              label: map.label,
                            };
                          })}
                        styles={colourStyles}
                        components={{ NoOptionsMessage }}
                        onChange={(e) => HandleSelect(e, "uf")}
                        placeholder="Selecione..."
                        options={uf.map((state) => {
                          return { value: state.value, label: state.name };
                        })}
                      />

                      <ErrorMessage
                        name="uf"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="formStep">
                      <label className="important">Cidade</label>
                      <AsyncPaginate
                        value={valueSelect
                          .filter((fill) => fill.nameField == "id_city")
                          .map((map) => {
                            return {
                              value: map.value,
                              label: map.label,
                            };
                          })}
                        loadOptions={loadOptions}
                        onChange={(e) => HandleSelect(e, "id_city")} //values and name of select paginate
                        placeholder="Selecione..."
                        cacheUniqs={[values?.uf]} //Set your param that is going to change when you select another field. Just use this option when you are setting params and need to refresh cache.
                        styles={colourStyles}
                        additional={{
                          page: 1,
                          endpoint: "/cities",
                          params: `uf=${values?.uf}`, //Separete with & (and commercial)
                        }}
                      />
                      <ErrorMessage
                        name="id_city"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm={8}>
                    <div className="formStep">
                      <div className="labelForm">
                        <label className="important">Endereço</label>
                      </div>
                      <Field
                        type="text"
                        placeholder="Digite um endereço"
                        name="address"
                        onChange={HandleChange}
                      />
                      <ErrorMessage
                        name="address"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="formStep">
                      <label className="important">Nº</label>
                      <Field
                        type="text"
                        name="number"
                        onChange={HandleChange}
                      />
                      <ErrorMessage
                        name="number"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="formStep">
                      <label className="important">Bairro</label>
                      <Field
                        type="text"
                        placeholder="Digite um bairro"
                        name="neighborhood"
                        onChange={HandleChange}
                      />
                      <ErrorMessage
                        name="neighborhood"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="formStep">
                      <label className="important">E-mail</label>
                      <Field
                        type="email"
                        placeholder="Digite um e-mail"
                        name="email"
                        onChange={HandleChange}
                      />
                      <ErrorMessage
                        name="email"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="formStep">
                      <label className="important">Celular</label>
                      <Field
                        type="text"
                        placeholder="Digite um celular"
                        name="phone1"
                        onKeyPress={maskTel}
                        onChange={HandleChange}
                      />

                      <ErrorMessage
                        name="phone1"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="formStep">
                      <label>Telefone</label>
                      <Field
                        type="text"
                        placeholder="Digite um número de telefone"
                        name="phone2"
                        onKeyPress={maskTelFixo}
                        onChange={HandleChange}
                      />
                      <ErrorMessage
                        name="phone2"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="formStep">
                      <label className="important">Senha</label>
                      <Field
                        type="password"
                        placeholder="Digite uma senha"
                        name="password"
                        onChange={HandleChange}
                      />
                      <ErrorMessage
                        name="password"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="formStep">
                      <label className="important">Confirmar Senha</label>
                      <Field
                        type="password"
                        placeholder="Confirme a senha"
                        name="password_confirmation"
                        onChange={HandleChange}
                      />
                      <ErrorMessage
                        name="password_confirmation"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className="d-flex justify-content-center">
                    <CustomButton type="submit">
                      <TextButton> Adicionar </TextButton>
                    </CustomButton>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </ModalContainer>
    </>
  );
};
export default IndexAddCustomer;
