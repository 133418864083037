import React from 'react';
import Edit from '../../components/room/editroom/indexEditRoom';
import UpdateStatus from '../../components/room/updateStatus/updateStatus';

export const columns = [
  {//0
    name: 'id',
    label: '',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      display: 'excluded',
    },
  },

  {//1
    name: 'sector',
    label: '',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      display: 'excluded',
      customBodyRender: (value, tableMeta) => {
        return value?.unit?.id;
      },
    },
  },

  {//2
    name: 'sector',
    label: 'Cliente',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      display: false,
      customBodyRender: (value, tableMeta) => {
        return value?.unit?.id_client;
      },
    },
  },

  {//3
    name: 'name',
    label: 'Sala',
    options: {
      setCellHeaderProps: () => ({
        style: {
          display: "flex",
          justifyContent: "center",
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: true,
      sort: true,
    },
  },

  {//4
    name: 'sector',
    label: 'Setor',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      customBodyRender: (value, tableMeta) => {
        return value?.name;
      },
    },
  },

  {//5
    name: 'sector',
    label: 'Unidade',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      customBodyRender: (value, tableMeta) => {
        return value?.unit.name;
      },
    },
  },

  {//6
    name: 'sector',
    label: 'Endereço',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      display: false,
      customBodyRender: (value, tableMeta) => {
        return value?.unit?.address;
      },
    },
  },

  {//7
    name: 'sector',
    label: 'Bairro',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: true,
      sort: false,
      display: false,
      customBodyRender: (value, tableMeta) => {
        return value?.unit?.neighborhood;
      },
    },
  },

  {//8
    name: 'sector',
    label: 'Número',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      display: false,
      customBodyRender: (value, tableMeta) => {
        return value?.unit?.number;
      },
    },
  },
  {//9
    name: 'sector',
    label: 'Cidade Sala',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      display: false,
      customBodyRender: (value, tableMeta) => {
        return value?.unit?.city?.name;
      },
    },
  },

  {//10
    name: 'sector',
    label: 'UF Sala',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      display: false,
      customBodyRender: (value, tableMeta) => {
        return value?.unit?.city?.uf;
      },
    },
  },

  {//11
    name: 'active',
    label: 'Status',
    options: {
      filter: true,
      sort: false,
      print: false,
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
          width: '10%',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      customBodyRender: (value, tableMeta) => {
        return (
          <UpdateStatus
            activeDefault={value}
            id={tableMeta.rowData[0]}
            page={tableMeta.tableState.page}
            rowsPage={tableMeta.tableState.rowsPerPage}
            orderBy={tableMeta.tableState.sortOrder.name}
            order={tableMeta.tableState.sortOrder.direction}
          />
        );
      },
    },
  },
  {//12
    name: 'Edit',
    label: 'Editar',
    options: {
      filter: false,
      sort: false,
      print: false,
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
          width: '10%',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      print: false,

      customBodyRender: (value, tableMeta) => {
        return (
          <Edit
            id={tableMeta.rowData[0]}
            idRoom={value}
            page={tableMeta.tableState.page}
            rowsPage={tableMeta.tableState.rowsPerPage}
            search={tableMeta.tableState.searchText}
            orderBy={tableMeta.tableState.sortOrder.name}
            order={tableMeta.tableState.sortOrder.direction}
          />
        );
      },
    },
  },
];
