import React, { useState } from "react";
import Fontawesome from "../../../components/fontawesome/fontawesome";
import "../equipamentsModel.css";
import api from "../../../services/api";
import { Prefix } from "../../../services/prefix";
import Loading from "../../loader/loading";
import Swal from "sweetalert2";
import { Modal, Badge, Button, Col, Row } from "react-bootstrap";
import { mutate as GlobalMutate } from "swr";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import Schema from "./schema";
import Slider from "@material-ui/core/Slider";
import { AsyncPaginate } from "react-select-async-paginate";
import { loadOptions } from "../../selectAsync/loadOptions";
import { colourStyles } from "../../selectAsync/colourStyles";
import { v4 as uuidv4 } from "uuid";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import { useStyles } from "../../buttons/AddButtonStyle";
import { ModalContainer } from "../../../style/stylesModals";
import { CustomButton, TextButton, ButtonDesabled } from "../../../style/stylesButton";

const AddEquipamentsModel = (props) => {
  const [registerData, setRegisterData] = useState({
    active: 1,
    name: "",
    id_equipment_brand: "",
    id_equipment_category: "",
    anvisa_registry: "",
  });
  const [family, setFamilies] = useState(0);
  const [show, setShow] = useState(false);
  const [valueSelect, setValueSelect] = useState([]);

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const handleEditTrack = (event, newValue) => {
    const id = !newValue ? event.target.id : event.target.ariaLabel;

    if (registerData.measures[id] !== null && id !== null) {
      let newArray = [...registerData.measures];
      newArray[id] = { ...newArray[id], min: newValue[0], max: newValue[1] };
      setRegisterData({ ...registerData, measures: newArray });
    }

    if (
      registerData.measures.length > 0 &&
      (registerData.measures[id]?.min || registerData.measures[id]?.max)
    ) {
      let security = !registerData.measures[id]
        ? false
        : parseFloat(registerData.measures[id]?.max) ==
          parseFloat(registerData.measures[id]?.min)
        ? true
        : false;

      if (security && registerData.measures[id] !== null && id !== null) {
        Swal.fire({
          icon: "warning",
          title: "Oops...",
          text: "A margem de segurança exige um intervalo entre a mínima e a máxima, portanto selecione um valor que não a torne inválida.",
          confirmButtonText: "Continuar",
        }).then((result) => {
          if (result.isConfirmed) {
            let newArray = [...registerData.measures];
            newArray[id] = {
              ...newArray[id],
              min: parseFloat(registerData.measures[id].min) - 1,
              max: parseFloat(registerData.measures[id].max) + 1,
            };
            setRegisterData({ ...registerData, measures: newArray });
          }
        });
      }
    }
  };

  const handleEditTrackSecurity = (event, newValue) => {
    const id = !newValue ? event.target.id : event.target.ariaLabel;

    if (
      registerData.measures[id] !== null &&
      id !== null &&
      newValue !== registerData.measures[id].safety_margin
    ) {
      let newArray = [...registerData.measures];
      newArray[id] = { ...newArray[id], safety_margin: newValue };
      setRegisterData({ ...registerData, measures: newArray });
    }
  };

  //Função de Select para React Select Async (Simple)
  const HandleSelect = (e, name) => {
    //Adicional ao HandleSelect
    if(name == "id_equipment_category"){
    api
      .get(Prefix.replace(/[\\"]/g, "") + "/equipment-categories/" + e.value)
      .then((response) => {
          setFamilies(response.data.model.equipment_family.monitoring);
      });
    }

    //Escopo do HandleSelect
    if (valueSelect.filter((fill) => fill.nameField == name).length > 0) {
      valueSelect
        .filter((fill) => fill.nameField == name)
        .map((map) => {
          let valueIndice = valueSelect.indexOf(map);
          valueSelect.splice(valueIndice);
        });

      setValueSelect((prev) => {
        return [...prev, { value: e.value, label: e.label, nameField: name }];
      });

      setRegisterData({ ...registerData, [name]: e.value });
    } else {
      setValueSelect((prev) => {
        return [...prev, { value: e.value, label: e.label, nameField: name }];
      });
      if(family == 1){
        setRegisterData({
          ...registerData,
          [name]: e.value,
          measures: [
            {
              id: uuidv4(),
              id_measure: null,
              min: -200,
              max: 300,
              safety_margin_time: '',
              safety_margin: 2,
            },
          ],
        });
      }else{
        setRegisterData({ ...registerData, [name]: e.value });
      }
      
    }
  };

  //Função de Select para React Select Async (Simple) para Array
  const HandleSelectArray = (e, name, key) => {
    if (
      valueSelect.filter((fill) => fill.nameField == name).length > 0 &&
      valueSelect.filter((fill) => fill.keyField == key).length > 0
    ) {
      valueSelect
        .filter((fill) => fill.nameField == name && fill.keyField == key)
        .map((map) => {
          let valueIndice = valueSelect.indexOf(map);
          valueSelect.splice(valueIndice);
        });

      setValueSelect((prev) => {
        return [
          ...prev,
          { value: e.value, label: e.label, nameField: name, keyField: key },
        ];
      });

      let newArray = [...registerData.measures];
      newArray[key] = { ...newArray[key], [name]: e.value };
      setRegisterData({ ...registerData, measures: newArray });
    } else {
      setValueSelect((prev) => {
        return [
          ...prev,
          { value: e.value, label: e.label, nameField: name, keyField: key },
        ];
      });
      let newArray = [...registerData.measures];
      newArray[key] = { ...newArray[key], [name]: e.value };
      setRegisterData({ ...registerData, measures: newArray });
    }
  };

  const HandleAttribute = (e) => {
    const id = e.target.getAttribute("id");
    const name = e.target.getAttribute("name").split("].")[1];
    const value = e.target.value;

    let newArray = [...registerData.measures];
    newArray[id] = { ...newArray[id], [name]: value };
    setRegisterData({ ...registerData, measures: newArray });
  };

  const AddAttrib = () => {
    let newArray = [...registerData.measures];
    newArray = [
      ...newArray,
      {
        id: uuidv4(),
        id_measure: null,
        min: -200,
        max: 300,
        safety_margin_time: "",
        safety_margin: 2,
      },
    ];
    setRegisterData({ ...registerData, measures: newArray });
  };

  function RemoveAttribute(e) {
    let newArray = [...registerData.measures.filter((i) => i.id != e)];
    setRegisterData({ ...registerData, measures: newArray });

    let newValueSelect = [...valueSelect.filter((i) => i.keyField != e)];
    setValueSelect(newValueSelect);
  }

  const handleChange = (e) => {
    setRegisterData({
      ...registerData,
      [e.target.name]: e.target.value.trim(),
    });
  };

  function HandleRegister() {
    const valuesSend = {
      active: registerData.active,
      name: registerData.name,
      id_equipment_brand: registerData.id_equipment_brand,
      id_equipment_category: registerData.id_equipment_category,
      anvisa_registry: registerData.anvisa_registry,
    };
    api
      .post(Prefix.replace(/[\\"]/g, "") + "/equipment-models", valuesSend)
      .then((response) => {
        if (family == 1) {
          registerData.measures.map((atrib) =>
            api
              .post(Prefix.replace(/[\\"]/g, "") + "/profile-attributes", {
                active: 1,
                id_equipment_model: response.data.model.id,
                id_unit_of_measure: atrib.id_measure,
                min: atrib.min,
                max: atrib.max,
                safety_margin: atrib.safety_margin,
                safety_margin_time: atrib.safety_margin_time,
              })
              .then(() => {})
              .catch((err) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: err.response.data.message,
                  confirmButtonText: "Tentar novamente ",
                });
              })
          );
        }

        Swal.fire({
          icon: "success",
          title: "Uow!",
          text: response.data.message,
          confirmButtonText: "Fechar",
          showCancelButton: true,
          cancelButtonText: "Novo Cadastro",
        }).then(() => {
          setShow(false);
          setRegisterData({
            active: 1,
            name: "",
            id_equipment_brand: "",
            id_equipment_category: "",
            anvisa_registry: "",
          });
          setFamilies(0);
          setValueSelect([]);
        });
        GlobalMutate(
          Prefix.replace(/[\\"]/g, "") +
            "/equipment-models?per_page=" +
            props.perPage +
            "&page=" +
            props.pageExact +
            '&search=' + props.search + '&order_by=' + props.orderBy + '&order=' + props.order,
        );
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: err.response.data.message,
          confirmButtonText: "Tentar novamente",
        });
      });
  }

  const classes = useStyles();

  return (
    <>
      {!show ? (
        <Fab className={classes.fab} onClick={handleShow}>
          <AddIcon className={classes.icon} />
        </Fab>
      ) : (
        <ModalContainer
          show={show}
          size={props.modalSize}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title><h3>{props.titleLabel}</h3></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={registerData}
              validationSchema={Schema}
              onSubmit={HandleRegister}
              enableReinitialize
            >
              {({ errors, values }) => (
                <Form>
                  <Row>
                    <Col>
                      <div className="formStep">
                        <label class="important">Equipamento:</label>
                        <div>
                          <AsyncPaginate
                            value={valueSelect
                              .filter(
                                (fill) =>
                                  fill.nameField == "id_equipment_category"
                              )
                              .map((map) => {
                                return {
                                  value: map.value,
                                  label: map.label,
                                };
                              })}
                            loadOptions={loadOptions}
                            onChange={(e) => {
                              HandleSelect(e, "id_equipment_category");
                            }}
                            placeholder="Selecione..."
                            cacheUniqs={[valueSelect]}
                            styles={colourStyles}
                            additional={{
                              page: 1,
                              endpoint: "/equipment-categories",
                              // params: `uf=${values.uf}`, //Separete with & (and commercial)
                            }}
                          />

                          <ErrorMessage
                            name="id_equipment_category"
                            component="span"
                            className="errorMessages"
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="formStep">
                        <label class="important">Marca:</label>
                        <div>
                          <AsyncPaginate
                            value={valueSelect
                              .filter(
                                (fill) => fill.nameField == "id_equipment_brand"
                              )
                              .map((map) => {
                                return {
                                  value: map.value,
                                  label: map.label,
                                };
                              })}
                            loadOptions={loadOptions}
                            onChange={(e) => {
                              HandleSelect(e, "id_equipment_brand");
                            }}
                            placeholder="Selecione..."
                            cacheUniqs={[valueSelect]}
                            styles={colourStyles}
                            additional={{
                              page: 1,
                              endpoint: "/equipment-brands",
                              // params: `uf=${values.uf}`, //Separete with & (and commercial)
                            }}
                          />

                          <ErrorMessage
                            name="id_equipment_brand"
                            component="span"
                            className="errorMessages"
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="formStep">
                        <label class="important">Nome do modelo:</label>
                        <div>
                          <Field
                            type="text"
                            name="name"
                            onKeyUp={handleChange}
                          />

                          <ErrorMessage
                            name="name"
                            component="span"
                            className="errorMessages"
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="formStep">
                        <label class="important">Registro da Anvisa:</label>
                        <div>
                          <Field
                            type="text"
                            name="anvisa_registry"
                            onKeyUp={handleChange}
                          />

                          <ErrorMessage
                            name="anvisa_registry"
                            component="span"
                            className="errorMessages"
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                
                  {registerData.id_equipment_brand != "" && family == 1 && (
                    <>
                      <FieldArray name="measures">
                        {({ push, remove }) => (
                          <>
                            <Row>
                              <Col xs={12} sm={12} md={12} lg={12}>
                                <Row>
                                  <Col className="attrTitle d-flex justify-content-between mb-3">
                                    <div>Atributos de Monitoramento </div>
                                    <Badge
                                      style={{
                                        color: "white",
                                        cursor: "pointer",
                                      }}
                                      className="d-flex align-items-center"
                                      variant="success"
                                      onClick={() => {
                                        push({
                                          id_measure: null,
                                          min: -200,
                                          max: 300,
                                          safety_margin_time: "",
                                          safety_margin: 2,
                                        });
                                        AddAttrib();
                                      }}
                                    >
                                      Adicionar
                                    </Badge>
                                  </Col>
                                </Row>
                                <hr class="horizontalLine"></hr>
                              </Col>
                            </Row>
                            
                            {values?.measures?.map((divAttrib, key) => (
                              <Row key={key} className="rowAttr rowAttr_in">
                                <Col xs={12} sm={12} md={12} lg={12}>
                                  <div className="formStep">
                                    <label className="important">
                                      Grandeza
                                    </label>
                                    {key > 0 && (
                                      <Button
                                        type="button"
                                        variant="danger"
                                        className="btnRemoveItem"
                                        onClick={() => {
                                          remove(key);
                                          RemoveAttribute(
                                            divAttrib.id,
                                            "id_measure"
                                          );
                                        }}
                                      >
                                        X
                                      </Button>
                                    )}

                                    <AsyncPaginate
                                      value={valueSelect
                                        .filter(
                                          (fill) =>
                                            fill.nameField == "id_measure" &&
                                            fill.keyField == key
                                        )
                                        .map((map) => {
                                          return {
                                            value: map.value,
                                            label: map.label,
                                          };
                                        })}
                                      loadOptions={loadOptions}
                                      onChange={(e) => {
                                        HandleSelectArray(e, "id_measure", key);
                                      }}
                                      placeholder="Selecione..."
                                      cacheUniqs={[valueSelect]}
                                      styles={colourStyles}
                                      additional={{
                                        page: 1,
                                        endpoint: "/unit-of-measures",
                                        paramName: `${"measure.name"}`,
                                        paramNameObs: `${"name"}`,
                                      }}
                                    />
                                  </div>
                                  <ErrorMessage
                                    name={`measures[${key}].id_measure`}
                                    component="span"
                                    className="errorMessages"
                                  />
                                </Col>

                                <Col xs={12} sm={12} md={12} lg={12}>
                                  <label className="important">
                                    Mínimo e máximo
                                  </label>
                                  <Slider
                                    value={[
                                      parseFloat(
                                        registerData.measures[key]?.min
                                      ),
                                      parseFloat(
                                        registerData.measures[key]?.max
                                      ),
                                    ]}
                                    min={-200}
                                    max={300}
                                    step={0.25}
                                    aria-label={key}
                                    onChange={handleEditTrack}
                                    onChangeCommitted={handleEditTrack}
                                    valueLabelDisplay="auto"
                                    aria-labelledby="range-slider"
                                    marks={[
                                      {
                                        value: -200,
                                        label: -200,
                                      },
                                      {
                                        value: 300,
                                        label: 300,
                                      },
                                    ]}
                                  />
                                  <ErrorMessage
                                    name={`measures[${key}].min`}
                                    component="span"
                                    className="errorMessages"
                                  />
                                  <ErrorMessage
                                    name={`measures[${key}].max`}
                                    component="span"
                                    className="errorMessages"
                                  />
                                </Col>

                                <Col xs={12} sm={12} md={12} lg={12}>
                                  <label className="important">
                                    Margem de segurança
                                  </label>
                                  <Slider
                                    defaultValue={
                                      registerData.measures.length == 0 ||
                                      !registerData.measures[key]
                                        ? 0.25
                                        : parseFloat(
                                            registerData.measures[key]
                                              ?.safety_margin
                                          )
                                    }
                                    min={0.25}
                                    max={10}
                                    aria-label={key}
                                    step={0.25}
                                    onChange={handleEditTrackSecurity}
                                    onChangeCommitted={handleEditTrackSecurity}
                                    valueLabelDisplay="auto"
                                    aria-labelledby="range-slider"
                                    marks={[
                                      {
                                        value: 0.25,
                                        label: 0.25,
                                      },
                                      {
                                        value: 10,
                                        label: 10,
                                      },
                                    ]}
                                  />

                                  <ErrorMessage
                                    name={`measures[${key}].safety_margin`}
                                    component="span"
                                    className="errorMessages"
                                  />
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                  <div className="formStep">
                                    <label className="important">
                                      Tempo offline
                                    </label>
                                    <Field
                                      type="number"
                                      id={key}
                                      name={`measures[${key}].safety_margin_time`}
                                      onChange={HandleAttribute}
                                      min="2"
                                    />
                                  </div>
                                  <ErrorMessage
                                    name={`measures[${key}].safety_margin_time`}
                                    component="span"
                                    className="errorMessages"
                                  />
                                </Col>
                              </Row>
                            ))}
                          </>
                        )}
                      </FieldArray>
                    </>
                  )}

                  <Row>
                    <Col className="d-flex justify-content-center">
                      <CustomButton type="submit">
                        <TextButton> Adicionar </TextButton>
                      </CustomButton>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Modal.Body>
        </ModalContainer>
      )}
    </>
  );
};
export default AddEquipamentsModel;
