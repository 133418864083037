import React, { useState, useContext } from "react";
import { Modal, Col, Row, Button, Alert } from "react-bootstrap";
import "./units.css";
import { Prefix } from "../../../services/prefix";
import Loading from "../../loader/loading";
import Swal from "sweetalert2";
import api from "../../../services/api";
import { useFetch } from "../../hooks/useFetch";
import Fontawesome from "../../fontawesome/fontawesome";
import { mutate as GlobalMutate } from "swr";
import { maskTelDin } from "../../../services/mask";
import Geocode from "react-geocode";
import { keyMap } from "../../googleMap/keyMaps";
import { Context } from "../../../services/context";
import axios from "axios";
import {
  Formik,
  Form,
  Field,
  ErrorMessage,
  FieldArray,
  setFieldValue,
} from "formik";
import { uf } from "../../data/uf";
import { Schema } from "./schema";
import { AsyncPaginate } from "react-select-async-paginate";
import { loadOptions } from "../../selectAsync/loadOptions";
import { colourStyles } from "../../selectAsync/colourStyles";
import { v4 as uuidv4 } from "uuid";
import Select, { components } from "react-select";
import { NoOptionsMessage } from "../../selectAsync/NoOptionsMessage";
import { useStyles } from "../../buttons/AddButtonStyle";
import { ModalContainer } from "../../../style/stylesModals";
import {
  CustomButton,
  TextButton,
  ButtonDesabled,
} from "../../../style/stylesButton";

const IndexAddUnity = ({ pageExact, perPage, search, orderBy, order }) => {
  const {
    showUnitsWz,
    setShowUnitsWz,
    listUnitsWz,
    setListUnitsWz,
    setShowSectorsWz,
  } = useContext(Context);

  const {
    selectclient,
    selectuf,
    selectcity,
    setSelectClient,
    setSelectUf,
    setSelectCity,
  } = useContext(Context);
  let valueSearch = !search ? "" : search;
  const [show, setShow] = useState(showUnitsWz);
  const [idClient, setIdClient] = useState("");
  const [loader, setLoader] = useState();
  const [idUnits, setIdUnits] = useState([]);
  const [valueSelect, setValueSelect] = useState([]);
  const [registerData, setRegisterData] = useState({
    initialData: [
      {
        key: uuidv4(),
        id: 0,
        name: "",
        address: "",
        number: "",
        neighborhood: "",
        estado: "",
        id_city: "",
        phone: "",
        active: 1,
        lat: "",
        lng: "",
        id_client: idClient,
        name_city: "",
      },
    ],
  });

  const handleClose = () => {
    GlobalMutate(
      Prefix.replace(/[\\"]/g, "") +
        "/units?per_page=" +
        perPage +
        "&page=" +
        pageExact +
        "&id_customer=" +
        selectclient +
        "&id_city=" +
        selectcity +
        "&uf=" +
        selectuf +
        "&order_by=" +
        orderBy +
        "&order=" +
        order
    );
    setShowUnitsWz(false);
    setValueSelect([]);
    setIdClient("");
    setListUnitsWz([]);
    setRegisterData({
      initialData: [
        {
          key: uuidv4(),
          id: 0,
          name: "",
          address: "",
          number: "",
          neighborhood: "",
          estado: "",
          id_city: "",
          phone: "",
          active: 1,
          lat: "",
          lng: "",
          id_client: idClient,
          name_city: "",
        },
      ],
    });
  };

  const HandleSelect = (e, name, key) => {
    if (
      valueSelect.filter((fill) => fill.key == key && fill.nameField == name)
        .length > 0
    ) {
      valueSelect
        .filter((fill) => fill.key == key && fill.nameField == name)
        .map((map) => {
          let position = valueSelect.indexOf(map);
          valueSelect.splice(position, 1);
        });
      setValueSelect(() => {
        return [
          ...valueSelect,
          { value: e.value, label: e.label, nameField: name, key: key },
        ];
      });
    } else {
      setValueSelect((prev) => {
        return [
          ...prev,
          { value: e.value, label: e.label, nameField: name, key: key },
        ];
      });
    }
  };

  const handleRemoveSelect = (key) => {
    valueSelect
      .filter((fill) => fill.key == key)
      .map((map) => {
        let position = valueSelect.indexOf(map);
        valueSelect.splice(position, 1);
      });
  };

  Geocode.setApiKey(keyMap); //Import API key from a file
  Geocode.setLanguage("pt-BR"); //Specify language of the parsed address. List of the available language codes. https://developers.google.com/maps/faq#languagesupport
  Geocode.setRegion("pt-BR");
  Geocode.setLocationType("ROOFTOP"); //ROOFTOP => indicates that the returned result is a precise geocode for which we have location information accurate down to street address precision. https://developers.google.com/maps/documentation/geocoding/overview#:~:text=%22ROOFTOP%22%20indicates%20that%20the%20returned,points%20(such%20as%20intersections)
  Geocode.enableDebug(false); //Enable or disable logs. Its optional.

  function HandleRegister(values) {
    setLoader(true);
    values.initialData.map((map) => {
      const listAddress =
        map.address +
        "," +
        map.number +
        "," +
        map.neighborhood +
        "," +
        map.name_city +
        "," +
        map.estado;

      axios
        .get(
          "https://maps.googleapis.com/maps/api/geocode/json?address=" +
            listAddress +
            "&key=" +
            keyMap
        )
        .then((response) => {
          const { lat, lng } = response.data.results[0].geometry.location;
          api
            .post(Prefix.replace(/[\\"]/g, "") + "/units", {
              active: 1,
              address: map.address,
              neighborhood: map.neighborhood,
              name: map.name,
              number: map.number,
              lat: parseFloat(lat),
              lng: parseFloat(lng),
              id_customer: parseInt(
                valueSelect
                  .filter(
                    (fill) =>
                      fill.nameField == "listCustomers" &&
                      fill.key == "listCustomers"
                  )
                  .map((map) => map.value)
              ),
              id_city: parseInt(map.id_city),
              phone: map.phone,
            })
            .then((response) => {
              listUnitsWz.push({
                value: response?.data?.model?.id,
                label: response?.data?.model?.name,
              });
              Swal.fire({
                icon: "success",
                title: "Uow!",
                text: response.data.message,
                showCloseButton: true,
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: "Novo Cadastro",
                cancelButtonText: ">> Cadastrar Setores",
                allowOutsideClick: false,
                focusCancel: true,
              }).then((result) => {
                if (result.isConfirmed) {
                  setShowUnitsWz(true);
                } else if (result.dismiss == "cancel") {
                  setShowUnitsWz(false);
                  setShowSectorsWz(true);
                } else if (result.dismiss == "close") {
                  setListUnitsWz([]);
                }
              });
              setLoader();
              setShowUnitsWz(false);
              setValueSelect([]);
              GlobalMutate(
                Prefix.replace(/[\\"]/g, "") +
                  "/units?per_page=" +
                  perPage +
                  "&page=" +
                  pageExact +
                  "&id_customer=" +
                  selectclient +
                  "&id_city=" +
                  selectcity +
                  "&uf=" +
                  selectuf +
                  "&order_by=" +
                  orderBy +
                  "&order=" +
                  order
              );
            })
            .catch((err) => {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: err.response.data.message,
                confirmButtonText: "Cadastrar novamente",
              });
              setLoader();
            });
        });
    });
  }

  const classes = useStyles();

  return (
    <>
      <ModalContainer
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h3>Unidades</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik //--> Configuração do Formik
            initialValues={registerData}
            validationSchema={Schema}
            onSubmit={HandleRegister}
            enableReinitialize
          >
            {({ values, setFieldValue, handleChange }) => (
              <Form>
                <Alert variant="success">
                  <FieldArray name="initialData">
                    {({ push, remove }) => (
                      <div>
                        <Alert
                          variant="success"
                          className="shake-horizontal"
                          style={{ zIndex: 1 }}
                        >
                          <Row>
                            <Col sm={12}>
                              <div className="formStep">
                                <label class="important">Cliente</label>
                                <AsyncPaginate
                                  value={valueSelect
                                    .filter(
                                      (fill) =>
                                        fill.nameField == "listCustomers" &&
                                        fill.key == "listCustomers"
                                    )
                                    .map((map) => {
                                      return {
                                        value: map.value,
                                        label: map.label,
                                      };
                                    })}
                                  loadOptions={loadOptions}
                                  // name={`initialData[${index}].list_clients`}
                                  onChange={(e) => {
                                    HandleSelect(
                                      e,
                                      "listCustomers",
                                      "listCustomers"
                                    );
                                  }} //values and name of select paginate
                                  placeholder="Selecione um Cliente..."
                                  components={{ NoOptionsMessage }}
                                  cacheUniqs={[
                                    valueSelect
                                      .filter(
                                        (fill) =>
                                          fill.nameField == "listCustomers" &&
                                          fill.key == "listCustomers"
                                      )
                                      .map((map) => map.value),
                                  ]} //Set your param that is going to change when you select another field. Just use this option when you are setting params and need to refresh cache.
                                  styles={colourStyles}
                                  additional={{
                                    page: 1,
                                    endpoint: "/customers",
                                  }}
                                />
                              </div>
                            </Col>
                          </Row>
                        </Alert>
                        {values.initialData.map((p, index) => {
                          return (
                            <>
                              {valueSelect.filter(
                                (fill) =>
                                  fill.nameField == "listCustomers" &&
                                  fill.key == "listCustomers"
                              ).length < 1 ? (
                                ""
                              ) : (
                                <Alert variant="success">
                                  <Row>
                                    <Col>
                                      <div className="formStep">
                                        <div className="d-flex justify-content-between">
                                          <label class="important">
                                            Unidade
                                          </label>{" "}
                                          {values.initialData.length < 2 ? (
                                            ""
                                          ) : (
                                            <Button
                                              variant="danger"
                                              className="btn-remove-item"
                                              onClick={() => {
                                                remove(index);
                                                handleRemoveSelect(p.key);
                                              }}
                                            >
                                              x
                                            </Button>
                                          )}
                                        </div>
                                        <Field
                                          type="text"
                                          placeholder="Digite um nome de unidade"
                                          name={`initialData[${index}].name`}
                                          onChange={handleChange}
                                        />
                                        <ErrorMessage
                                          name={`initialData[${index}].name`}
                                          component="span"
                                          className="errorMessages"
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm={9}>
                                      <div className="formStep">
                                        <label class="important">
                                          Endereço
                                        </label>
                                        <Field
                                          type="text"
                                          placeholder="Digite um endereço"
                                          name={`initialData[${index}].address`}
                                          onChange={handleChange}
                                        />
                                        <ErrorMessage
                                          name={`initialData[${index}].address`}
                                          component="span"
                                          className="errorMessages"
                                        />
                                      </div>
                                    </Col>
                                    <Col sm={3}>
                                      <div className="formStep">
                                        <label class="important">Nº</label>
                                        <Field
                                          type="text"
                                          name={`initialData[${index}].number`}
                                          onChange={handleChange}
                                        />
                                        <ErrorMessage
                                          name={`initialData[${index}].number`}
                                          component="span"
                                          className="errorMessages"
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm={6}>
                                      <div className="formStep">
                                        <label class="important">Bairro</label>
                                        <Field
                                          type="text"
                                          placeholder="Digite o bairro"
                                          name={`initialData[${index}].neighborhood`}
                                          onChange={handleChange}
                                        />
                                        <ErrorMessage
                                          name={`initialData[${index}].neighborhood`}
                                          component="span"
                                          className="errorMessages"
                                        />
                                      </div>
                                    </Col>

                                    <Col>
                                      <div className="formStep">
                                        <label class="important">Estado</label>
                                        <Select
                                          closeMenuOnSelect={false}
                                          styles={colourStyles}
                                          placeholder="Selecione..."
                                          name={`initialData[${index}].estado`}
                                          components={{ NoOptionsMessage }}
                                          options={uf}
                                          onChange={(e) => {
                                            HandleSelect(e, "estado", p.key);
                                            setFieldValue(
                                              `initialData[${index}].estado`,
                                              e.value
                                            );
                                          }}
                                        />
                                        <ErrorMessage
                                          name={`initialData[${index}].estado`}
                                          component="span"
                                          className="errorMessages"
                                        />
                                      </div>
                                    </Col>

                                    <Col sm={12}>
                                      <div className="formStep">
                                        <label class="important">Cidade</label>
                                        <AsyncPaginate
                                          value={valueSelect
                                            .filter(
                                              (fill) =>
                                                fill.nameField == "id_city" &&
                                                fill.key == p.key
                                            )
                                            .map((map) => {
                                              return {
                                                value: map.value,
                                                label: map.label,
                                              };
                                            })}
                                          loadOptions={loadOptions}
                                          name={`initialData[${index}].id_city`}
                                          onChange={(e) => {
                                            HandleSelect(e, "id_city", p.key);
                                            setFieldValue(
                                              `initialData[${index}].id_city`,
                                              e.value
                                            );
                                            setFieldValue(
                                              `initialData[${index}].name_city`,
                                              e.label
                                            );
                                          }} //values and name of select paginate
                                          placeholder="Selecione..."
                                          cacheUniqs={[
                                            valueSelect
                                              .filter(
                                                (fill) =>
                                                  fill.nameField == "estado" &&
                                                  fill.key == p.key
                                              )
                                              .map((map) => map.value),
                                          ]} //Set your param that is going to change when you select another field. Just use this option when you are setting params and need to refresh cache.
                                          styles={colourStyles}
                                          additional={{
                                            page: 1,
                                            endpoint: "/cities",
                                            params: `uf=${valueSelect
                                              .filter(
                                                (fill) =>
                                                  fill.nameField == "estado" &&
                                                  fill.key == p.key
                                              )
                                              .map((map) => map.value)}`, //Separete with & (and commercial)
                                          }}
                                        />
                                        <ErrorMessage
                                          name={`initialData[${index}].id_city`}
                                          component="span"
                                          className="errorMessages"
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <div className="formStep">
                                        <label>Telefone</label>
                                        <Field
                                          type="text"
                                          placeholder="Digite um número de telefone"
                                          name={`initialData[${index}].phone`}
                                          onChange={handleChange}
                                          onKeyPress={maskTelDin}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </Alert>
                              )}
                            </>
                          );
                        })}
                        <div>
                          {valueSelect.filter(
                            (fill) =>
                              fill.nameField == "listCustomers" &&
                              fill.key == "listCustomers"
                          ).length == 0 ? (
                            ""
                          ) : (
                            <Row>
                              <Col
                                className="d-flex justify-content-center "
                                style={{ border: "1 solid black" }}
                              >
                                <Button
                                  style={{
                                    backgroundColor: "#1bc5bd",
                                    width: "160px",
                                    height: "60px",
                                    border: 0,
                                    borderRadius: "30px",
                                    marginTop: "20px",
                                  }}
                                  onClick={() => {
                                    push({
                                      key: uuidv4(),
                                      id: 0,
                                      name: "",
                                      address: "",
                                      number: "",
                                      neighborhood: "",
                                      estado: "",
                                      id_city: "",
                                      phone: "",
                                      active: 1,
                                      lat: "",
                                      lng: "",
                                      id_client: idClient,
                                      name_city: "",
                                    });
                                  }}
                                  disabled={
                                    valueSelect.filter(
                                      (fill) =>
                                        fill.nameField == "listCustomers" &&
                                        fill.key == "listCustomers"
                                    ).length == 0
                                      ? "disabled"
                                      : ""
                                  }
                                >
                                  Adicionar
                                </Button>
                              </Col>
                              <Col className="d-flex justify-content-center ">
                                {values.initialData.length == 0 ? (
                                  ""
                                ) : (
                                  <CustomButton type="submit">
                                    <TextButton>Cadastrar</TextButton>
                                  </CustomButton>
                                )}
                              </Col>
                            </Row>
                          )}
                        </div>
                      </div>
                    )}
                  </FieldArray>
                </Alert>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </ModalContainer>
    </>
  );
};
export default IndexAddUnity;
