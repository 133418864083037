import React, { useState, useRef, useContext } from "react";
import { Row, Col, Button, Alert, Modal } from "react-bootstrap";
import "./room.css";
import api from "../../../services/api";
import Fontawesome from "../../fontawesome/fontawesome";
import { Prefix } from "../../../services/prefix";
import Loading from "../../loader/loading";
import Swal from "sweetalert2";
import { useFetch } from "../../hooks/useFetch";
import { mutate as GlobalMutate } from "swr";
import { Context } from "../../../services/context";
import { AsyncPaginate } from "react-select-async-paginate";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import { v4 as uuidv4 } from "uuid";
import { Schema } from "./schema";
import { useStyles } from "../../buttons/AddButtonStyle";
import { ModalContainer } from "../../../style/stylesModals";
import {
  CustomButton,
  TextButton,
  ButtonDesabled,
} from "../../../style/stylesButton";

const IndexAddRoom = ({ pageExact, perPage, search }) => {
  const {
    showRoomsWz,
    setShowRoomsWz,
    listSectorsWz,
    listUnitsWz,
    setListSectorsWz,
    setListUnitsWz,
  } = useContext(Context);

  const {
    selectclient,
    selectunit,
    selectsector,
    setSelectClient,
    setSelectUnit,
    setSelectSector,
  } = useContext(Context);

  const [idSector, setIdSector] = useState();
  const [loader, setLoader] = useState(); /* Carrega o Loader */
  const [valueSelect, setValueSelect] = useState([]);
  const [registerData, setRegisterData] = useState({
    initialData: [
      {
        key: uuidv4(),
        name: "",
        active: 1,
      },
    ],
  });

  const handleClose = () => {
    setShowRoomsWz(false);
    setListUnitsWz([]);
    setListSectorsWz([]);
    setRegisterData({
      initialData: [
        {
          key: uuidv4(),
          name: "",
          active: 1,
        },
      ],
    });
    GlobalMutate(
      Prefix.replace(/[\\"]/g, "") +
        "/rooms?per_page=" +
        perPage +
        "&page=" +
        pageExact +
        "&id_customer=" +
        selectclient +
        "&id_unit=" +
        selectunit +
        "&id_sector=" +
        selectsector
    );
    setValueSelect([]);
  };

  const HandleSelect = (e, name, key) => {
    if (
      valueSelect.filter((fill) => fill.key == key && fill.nameField == name)
        .length > 0
    ) {
      valueSelect
        .filter((fill) => fill.key == key && fill.nameField == name)
        .map((map) => {
          let position = valueSelect.indexOf(map);
          valueSelect.splice(position, 1);
        });
      setValueSelect(() => {
        return [
          ...valueSelect,
          { value: e.value, label: e.label, nameField: name, key: key },
        ];
      });
    } else {
      setValueSelect((prev) => {
        return [
          ...prev,
          { value: e.value, label: e.label, nameField: name, key: key },
        ];
      });
    }
  };

  const HandleRegister = (values) => {
    setLoader(true);
    values.initialData.map((room) =>
      api
        .post(Prefix.replace(/[\\"]/g, "") + "/rooms", {
          name: room.name,
          active: 1,
          id_sector: idSector,
        })
        .then((response) => {
          Swal.fire({
            icon: "success",
            title: "Uow!",
            text: response.data.message,
            showCloseButton: true,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: "Fechar",
            cancelButtonText: "Novo Cadastro de Sala",
            allowOutsideClick: false,
          }).then((result) => {
            if (!result.isConfirmed) {
              setShowRoomsWz(true);
            } else if (result.isConfirmed) {
              setListUnitsWz([]);
              setListSectorsWz([]);
            }
          });
          setLoader(false);
          setShowRoomsWz(false);
          GlobalMutate(
            Prefix.replace(/[\\"]/g, "") +
              "/rooms?per_page=" +
              perPage +
              "&page=" +
              pageExact +
              "&id_customer=" +
              selectclient +
              "&id_unit=" +
              selectunit +
              "&id_sector=" +
              selectsector
          );
          setRegisterData({
            initialData: [
              {
                key: uuidv4(),
                name: "",
                active: 1,
              },
            ],
          });
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: err.response.data.message,
            confirmButtonText: "Tentar novamente",
          });
        })
    );
    setLoader(false);
  };

  const classes = useStyles();

  return (
    <>
      <ModalContainer
        show={showRoomsWz}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h3>Salas</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik //--> Configuração do Formik
            initialValues={registerData}
            validationSchema={Schema}
            onSubmit={HandleRegister}
            enableReinitialize
          >
            {({ values, setFieldValue, handleChange }) => (
              <Form>
                <Alert variant="success">
                  <FieldArray name="initialData">
                    {({ push, remove }) => (
                      <div>
                        <div className="customerStep">
                          <Alert variant="success">
                            <Row>
                              <Col sm={12}>
                                <div className="formStep">
                                  <label class="important">Setor</label>
                                  <AsyncPaginate
                                    className="basic-single"
                                    classNamePrefix="select"
                                    closeMenuOnSelect={false}
                                    placeholder="Selecione um Setor..."
                                    options={listSectorsWz.map((map) => {
                                      return {
                                        value: map.value,
                                        label: map.label,
                                      };
                                    })}
                                    name="setor"
                                    onChange={(e) => setIdSector(e.value)}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </Alert>
                        </div>

                        {values.initialData.map((p, index) => {
                          return (
                            <>
                              {!idSector ? (
                                ""
                              ) : (
                                <Alert variant="success" name="initialData">
                                  <Row>
                                    <Col>
                                      <div className="formStep">
                                        <div className="d-flex justify-content-between">
                                          <label class="important">Sala</label>{" "}
                                          {values.initialData.length < 1 ? (
                                            ""
                                          ) : (
                                            <Button
                                              variant="danger"
                                              className="btn-remove-item"
                                              onClick={() => {
                                                remove(index);
                                              }}
                                            >
                                              x
                                            </Button>
                                          )}
                                        </div>
                                        <Field
                                          type="text"
                                          placeholder="Digite o nome da sala.."
                                          name={`initialData[${index}].name`}
                                          onChange={handleChange}
                                        />
                                        <ErrorMessage
                                          name={`initialData[${index}].name`}
                                          component="span"
                                          className="errorMessages"
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </Alert>
                              )}
                            </>
                          );
                        })}
                        <div>
                          {!idSector ? (
                            ""
                          ) : (
                            <Row>
                              <Col className="d-flex justify-content-center ">
                                <Button
                                  style={{
                                    backgroundColor: "#1bc5bd",
                                    width: "160px",
                                    height: "60px",
                                    border: 0,
                                    borderRadius: "30px",
                                    marginTop: "20px",
                                  }}
                                  onClick={() => {
                                    push({
                                      key: uuidv4(),
                                      name: "",
                                      active: 1,
                                    });
                                  }}
                                  disabled={!idSector ? "disabled" : ""}
                                >
                                  Adicionar
                                </Button>
                              </Col>
                              <Col className="d-flex justify-content-center ">
                                {values.initialData.length == 0 ? (
                                  ""
                                ) : (
                                  <CustomButton type="submit">
                                    <TextButton>Finalizar</TextButton>
                                  </CustomButton>
                                )}
                              </Col>
                            </Row>
                          )}
                        </div>
                      </div>
                    )}
                  </FieldArray>
                </Alert>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </ModalContainer>
    </>
  );
};

export default IndexAddRoom;
