import React from "react";
import UpdateStatus from "../../components/employers/updateStatus/updateStatus";
import Options from "../../components/employers/optionsEmployer/optionsEmployer";

export const columns = [
  {
    //0
    name: "id",
    label: "",
    options: {
      setCellHeaderProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      setCellProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      filter: false,
      sort: false,
      display: "excluded",
    },
  },
  {
    //1
    name: "name",
    label: "Colaboradores",
    options: {
      setCellHeaderProps: () => ({
        style: {
          display: "flex",
          justifyContent: "center",
        },
      }),
      setCellProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      filter: false,
      sort: true,
    },
  },

  {
    //2
    name: "admission_at",
    label: "Admissão",
    options: {
      setCellHeaderProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      setCellProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      filter: false,
      sort: false,
      customBodyRender: (value, tableMeta) => {
        if (!value) {
        } else {
          return value.split("-").reverse().join("/");
        }
      },
    },
  },

  {
    //3
    name: "exoneration_at",
    label: "Demissão",
    options: {
      setCellHeaderProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      setCellProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      filter: false,
      sort: false,
      customBodyRender: (value, tableMeta) => {
        return (
          <>
            <b>
              {" "}
              {!value
                ? "Em Atividade"
                : value.split("-").reverse().join("/")}{" "}
            </b>
          </>
        );
      },
    },
  },

  {
    //4
    name: "phone1",
    label: "Telefone",
    options: {
      setCellHeaderProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      setCellProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      filter: false,
      sort: false,
      display: false,
    },
  },

  {
    //5
    name: "email",
    label: "E-mail",
    options: {
      setCellHeaderProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      setCellProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      filter: false,
      sort: false,
      display: false,
    },
  },
  {
    //6
    name: "active",
    label: "Status",
    options: {
      setCellHeaderProps: () => ({
        style: {
          "text-align": "center",
          width: "10%",
        },
      }),
      setCellProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      filter: true,
      sort: false,
      print: false,

      customBodyRender: (value, tableMeta) => {
        return (
          <UpdateStatus
            id={tableMeta.rowData[0]}
            page={tableMeta.tableState.page}
            rowsPage={tableMeta.tableState.rowsPerPage}
            search={
              !tableMeta.tableState.searchText
                ? ""
                : tableMeta.tableState.searchText
            }
            activeDefault={value}
            orderBy={tableMeta.tableState.sortOrder.name}
            order={tableMeta.tableState.sortOrder.direction}
          />
        );
      },
    },
  },

  {
    //7
    name: "id",
    label: "Editar",
    options: {
      setCellHeaderProps: () => ({
        style: {
          "text-align": "center",
          width: "10%",
        },
      }),
      setCellProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      filter: false,
      sort: false,
      print: false,

      customBodyRender: (value, tableMeta) => {
        return (
          <Options
            id={value}
            page={tableMeta.tableState.page}
            rowsPage={tableMeta.tableState.rowsPerPage}
            search={tableMeta.tableState.searchText}
            orderBy={tableMeta.tableState.sortOrder.name}
            order={tableMeta.tableState.sortOrder.direction}
            idUser={tableMeta.rowData[16]}
          />
        );
      },
    },
  },
  {
    //8
    name: "cpf",
    label: "cpf",
    options: {
      setCellHeaderProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      setCellProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      filter: false,
      sort: false,
      display: false,
    },
  },
  {
    //9
    name: "rg",
    label: "rg",
    options: {
      setCellHeaderProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      setCellProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      filter: false,
      sort: false,
      display: false,
    },
  },
  {
    //10
    name: "address",
    label: "address",
    options: {
      setCellHeaderProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      setCellProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      filter: false,
      sort: false,
      display: false,
    },
  },
  {
    //11
    name: "number",
    label: "numero",
    options: {
      setCellHeaderProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      setCellProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      filter: false,
      sort: false,
      display: false,
    },
  },
  {
    //12
    name: "neighborhood",
    label: "Bairro",
    options: {
      setCellHeaderProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      setCellProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      filter: false,
      sort: false,
      display: false,
    },
  },
  {
    //13
    name: "city",
    label: "Cidade",
    options: {
      setCellHeaderProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      setCellProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      filter: false,
      sort: false,
      display: false,
      customBodyRender: (value, tableMeta) => {
        return value?.name;
      },
    },
  },
  {
    //14
    name: "phone2",
    label: "Telefone",
    options: {
      setCellHeaderProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      setCellProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      filter: false,
      sort: false,
      display: false,
    },
  },
  {
    //15
    name: "user",
    label: "Type",
    options: {
      setCellHeaderProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      setCellProps: () => ({
        style: {
          "text-align": "center",
        },
      }),
      filter: false,
      sort: false,
      display: false,
      customBodyRender: (value, tableMeta) => {
        return value?.type;
      },
    },
  },

  {
    //16
    name: "id_user",
    label: "Usuário",
    options: {
      filter: false,
      sort: false,
      display: "excluded",
    },
  },
];
