import React, { memo, useState, useContext } from "react";
import { Row, Col } from "react-bootstrap";
import Lights from "../lights/lights";
import { useFetch } from "../../../components/hooks/useFetch";
import { Prefix } from "../../../services/prefix";
import Loading from "../../../components/loader/loading";
import {
  TitleSensor,
  ContentSensor,
  Export,
  SensorInfo,
  MonitoringRange,
  Notification,
  ContainerGeneral,
  ContainerCardSensor,
} from "./detailsMonitoringStyle";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import MonitoringTracks from "../monitoringTracks/editMonitoringTracks/EditMonitoringTracks";
import NotificationView from "../notificationView/notificationView";
import ReadingSensor from "../readingSensor/readingSensor";
import moment from "moment";
import ExportReport from "../exportReport/exportReport";
import IndexAddPlan from "./addPlan/indexAddPlan";
import { Context } from "../../../services/context";
import NotificationTimer from "./notificationTimer/notificationTimer";

const Sensor = ({ sensor, month, year, full }) => {
  const { customerActionPlan, setCustomerActionPlan, showNotificationTimer, setShowNotificationTimer } = useContext(Context);
  const [loader, setLoader] = useState(false);
  const [showRange, setShowRange] = useState(null);
  const [showNotification, setShowNotification] = useState(null);
  const [showExport, setShowExport] = useState(null);

  const urlSensor = !year
    ? Prefix.replace(/[\\"]/g, "") +
    "/sismogran/map/items/" +
    sensor +
    "/sensors"
    : Prefix.replace(/[\\"]/g, "") +
    "/sismogran/map/items/" +
    sensor +
    "/sensors" +
    "/" +
    year +
    "/" +
    month;

  const listSensor = useFetch(urlSensor, 30000);

  if (!listSensor.data) return <Loading />;

  const HandleRange = (e) => {
    setShowRange(!showRange ? e : null);
  };

  const HandleNotification = (e) => {
    setShowNotification(!showNotification ? e : null);
  };

  const HandleExport = (e) => {
    setShowExport(!showExport ? e : null);
  };

  const HandleNotificationTimer = (e) => {
    setShowNotificationTimer(!showNotificationTimer ? e : null);
  };

  return (
    <>
      {loader && <Loading />}
      <ContainerGeneral direction={full}>
        {listSensor.data?.models &&
          listSensor.data?.models.map((sns, index) => (
            <ContainerCardSensor size={full}>
              <ContentSensor>
                <TitleSensor>
                  <span>
                    {sns.situation == "SUCCESS" ? (
                      <Lights signal="1" message={sns.message} />
                    ) : sns.situation == "WARNING" ? (
                      <Lights signal="2" message={sns.message} />
                    ) : sns.situation == "DANGER" ? (
                      <Lights signal="3" message={sns.message} />
                    ) : (
                      <Lights signal="4" message={sns.message} />
                    )}
                    <strong className="ml-3">
                      {sns.profile_attribute.unit_of_measure.measure.name}
                    </strong>
                  </span>
                  <span>
                    {index == 0 && (
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="tooltip-disabled">
                            Configuração de Notificação
                          </Tooltip>
                        }
                      >
                        <Notification
                          src="../../../media/icons/notification-icon.svg"
                          onClick={() => HandleNotificationTimer(sns.id)}
                        />
                      </OverlayTrigger>
                    )}
                    <OverlayTrigger
                      overlay={
                        <Tooltip id="tooltip-disabled">
                          Faixa de monitoramento
                        </Tooltip>
                      }
                    >
                      <MonitoringRange
                        src="../../../media/icons/monitoring-range.svg"
                        onClick={() => HandleRange(sns.id)}
                      />
                    </OverlayTrigger>
                    <OverlayTrigger
                      overlay={
                        <Tooltip id="tooltip-disabled">
                          Baixar relatório do mês
                        </Tooltip>
                      }
                    >
                      <Export
                        src="../../../media/icons/report.svg"
                        onClick={() => HandleExport(sns.id)}
                      />
                    </OverlayTrigger>
                  </span>
                </TitleSensor>

                {showRange && showRange == sns.id && (
                  <MonitoringTracks
                    idContractItem={showRange && sensor}
                    HandleRange={HandleRange}
                    sensor={sns.id}
                  />
                )}

                {showNotificationTimer && showNotificationTimer == sns.id && (
                  <NotificationTimer
                    idContractItem={showRange && sensor}
                    HandleRange={HandleNotificationTimer}
                    data={sns}
                  />
                )}

                {showNotification && showNotification == sns.id && (
                  <NotificationView
                    idContractItem={showRange && sensor}
                    HandleNotification={HandleNotification}
                  />
                )}

                {showExport && showExport == sns.id && (
                  <ExportReport
                    sensor={sns.id}
                    idContractItem={showExport && sensor}
                  />
                )}

                {customerActionPlan &&
                  customerActionPlan?.id_measure ==
                  sns?.profile_attribute?.unit_of_measure?.id_measure && (
                    <IndexAddPlan dataMonitoring={customerActionPlan} />
                  )}

                <SensorInfo>
                  <Row>
                    <Col>
                      <strong>Mínimo:</strong>{" "}
                      {sns.profile_attribute.unit_of_measure.measure.name ==
                        "Temperatura"
                        ? parseFloat(sns.min).toFixed(2) +
                        " " +
                        sns.profile_attribute.unit_of_measure.name
                        : parseFloat(sns.min).toFixed(2) +
                        " %" +
                        sns.profile_attribute.unit_of_measure.name}
                      <br />
                      <strong>Máximo:</strong>{" "}
                      {sns.profile_attribute.unit_of_measure.measure.name ==
                        "Temperatura"
                        ? parseFloat(sns.max).toFixed(2) +
                        " " +
                        sns.profile_attribute.unit_of_measure.name
                        : parseFloat(sns.max).toFixed(2) +
                        " %" +
                        sns.profile_attribute.unit_of_measure.name}
                    </Col>
                    <Col className="text-right">
                      <strong>Última leitura:</strong> <br />
                      <span>
                        {!sns.readed_at
                          ? "Sem leituras"
                          : sns.profile_attribute.unit_of_measure.measure
                            .name == "Temperatura"
                            ? parseFloat(sns.value).toFixed(2) +
                            " " +
                            sns.profile_attribute.unit_of_measure.name
                            : parseFloat(sns.value).toFixed(2) +
                            " %" +
                            sns.profile_attribute.unit_of_measure.name}{" "}
                        |{" "}
                        {!sns.readed_at
                          ? "Sem leituras"
                          : moment(sns.readed_at.split(" ")[0]).format(
                            "DD/MM/YYYY"
                          ) +
                          " " +
                          sns.readed_at.split(" ")[1]}
                      </span>
                    </Col>
                  </Row>
                </SensorInfo>

                <ReadingSensor sensor={sns.id} />
              </ContentSensor>
            </ContainerCardSensor>
          ))}
      </ContainerGeneral>
    </>
  );
};
export default memo(Sensor);
