import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import '../../../style/global.css';
import ModalAcceptProposal from '../../../components/proposals/options/modalAcceptProposal';
import ModalRejectProposal from '../../../components/proposals/options/modalRejectProposal';
import nophoto from './nophoto.png';
import { useParams } from 'react-router-dom';
import { useFetch } from '../../../components/hooks/useFetch';
import { Prefix } from '../../../services/prefix';
import moment from 'moment';
import {
  DateProposal,
  Proposal,
  TitleProposal,
  BoxInner,
} from './proposalCustomerStyle';

const ProposalCustomer = () => {
  const { id, count } = useParams();
  const requestInfo = useFetch(
    Prefix.replace(/[\\"]/g, '') + '/proposal-requests/' + id,
  );

  const date = moment(requestInfo.data?.model?.created_at.split('T')[0]).format(
    'DD/MM/YYYY',
  );
  const numberProposal = ('0000' + id).slice(-4);

  return (
    <div className="container-fluido ">
      <div className="container mt_container2">
        <div className="row mt_login_top">
          <div className="col-12 text-center">
            <img
              src="/media/logos/logo-dtlab.svg"
              className="mt_img_logo img-fluid"
            />
          </div>
        </div>
        <div className="row mt_signin">
          <div className="container_login">
            <div style={{ width: '100%' }}>
              <Row>
                <Col>
                  <Proposal>
                    PROPOSTA {numberProposal} / {count}
                  </Proposal>
                </Col>
                <Col>
                  <DateProposal>{date}</DateProposal>
                </Col>
              </Row>
              <div
                style={{
                  marginTop: '5px',
                  backgroundColor: '#ffffff',
                  padding: '20px',
                  borderRadius: '5px',
                  fontSize: '10px',
                }}
              >
                <Row>
                  <Col>
                    <TitleProposal>INFORMAÇÕES DA PROPOSTA</TitleProposal>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col sm={3}>
                    <BoxInner>
                      <strong>NÚMERO:</strong>
                      <p>
                        Proposta {numberProposal} / {count}
                      </p>
                    </BoxInner>
                  </Col>
                  <Col sm={9}>
                    <BoxInner>
                      <strong>CLIENTE:</strong>
                      <p>{requestInfo.data?.model?.name}</p>
                    </BoxInner>
                  </Col>
                </Row>

                <Row className="mb-3 mt-3">
                  <Col>
                    <TitleProposal>EQUIPAMENTOS DA PROPOSTA</TitleProposal>
                  </Col>
                </Row>
                <div>
                  <Row>
                    <div
                      style={{
                        backgroundColor: '#f3f6f9',
                        marginLeft: '2%',
                        borderRadiusLeft: '5px',
                        paddingLeft: '2%',
                        fontSize: '10px',
                        width: '45%',
                      }}
                    >
                      <strong>EQUIPAMENTO</strong>
                    </div>
                    <div
                      style={{
                        backgroundColor: '#f3f6f9',
                        paddingLeft: '2%',
                        fontSize: '10px',
                        width: '15%',
                      }}
                    >
                      <strong>SERVIÇO</strong>
                    </div>
                    <div
                      style={{
                        backgroundColor: '#f3f6f9',
                        paddingLeft: '2%',
                        fontSize: '10px',
                        width: '20%',
                      }}
                    >
                      <strong>VALOR UNITÁRIO</strong>
                    </div>
                    <div
                      style={{
                        backgroundColor: '#f3f6f9',
                        borderRadiusRight: '5px',
                        marginRight: '2%',
                        paddingLeft: '2%',
                        fontSize: '10px',
                        width: '15%',
                      }}
                    >
                      <strong>SUBTOTAL</strong>
                    </div>
                  </Row>
                </div>
                <br />
                <div>
                  <Row>
                    <div
                      style={{
                        marginLeft: '2%',
                        paddingLeft: '2%',
                        width: '8%',
                      }}
                    >
                      <img src={nophoto} width="100%" />
                    </div>
                    <div
                      style={{
                        marginLeft: '2%',
                        borderRadiusLeft: '5px',
                        paddingLeft: '2%',
                        fontSize: '10px',
                        width: '35%',
                      }}
                    >
                      <strong>MONITOR LCD PHILLIPS LK158</strong>
                      <br />
                      <p style={{ color: '#782e2e' }}>
                        <strong style={{ color: '#782e2e' }}>
                          QUANTIDADE:
                        </strong>{' '}
                        120
                      </p>
                    </div>
                    <div
                      style={{
                        paddingLeft: '2%',
                        fontSize: '10px',
                        width: '15%',
                      }}
                    >
                      <strong>PREVENTIVA</strong>
                      <br />
                      <p style={{ color: '#782e2e' }}>/POR UNIDADE</p>
                    </div>
                    <div
                      style={{
                        paddingLeft: '2%',
                        fontSize: '10px',
                        width: '20%',
                      }}
                    >
                      <strong>R$15,90</strong>
                      <br />
                      <p style={{ color: '#782e2e' }}>/POR UNIDADE</p>
                    </div>
                    <div
                      style={{
                        borderRadiusRight: '5px',
                        marginRight: '2%',
                        paddingLeft: '2%',
                        fontSize: '10px',
                        width: '15%',
                      }}
                    >
                      <strong>R$1.908,00</strong>
                      <br />
                      <p style={{ color: '#782e2e' }}>/MENSAL</p>
                    </div>
                  </Row>
                </div>
                <div>
                  <Row>
                    <div
                      style={{
                        marginLeft: '2%',
                        paddingLeft: '2%',
                        width: '8%',
                      }}
                    >
                      <img src={nophoto} width="100%" />
                    </div>
                    <div
                      style={{
                        marginLeft: '2%',
                        borderRadiusLeft: '5px',
                        paddingLeft: '2%',
                        fontSize: '10px',
                        width: '35%',
                      }}
                    >
                      <strong>MONITOR LCD PHILLIPS LK158</strong>
                      <br />
                      <p style={{ color: '#782e2e' }}>
                        <strong style={{ color: '#782e2e' }}>
                          QUANTIDADE:
                        </strong>{' '}
                        120
                      </p>
                    </div>
                    <div
                      style={{
                        paddingLeft: '2%',
                        fontSize: '10px',
                        width: '15%',
                      }}
                    >
                      <strong>PREVENTIVA</strong>
                      <br />
                      <p style={{ color: '#782e2e' }}>/POR UNIDADE</p>
                    </div>
                    <div
                      style={{
                        paddingLeft: '2%',
                        fontSize: '10px',
                        width: '20%',
                      }}
                    >
                      <strong>R$15,90</strong>
                      <br />
                      <p style={{ color: '#782e2e' }}>/POR UNIDADE</p>
                    </div>
                    <div
                      style={{
                        borderRadiusRight: '5px',
                        marginRight: '2%',
                        paddingLeft: '2%',
                        fontSize: '10px',
                        width: '15%',
                      }}
                    >
                      <strong>R$1.908,00</strong>
                      <br />
                      <p style={{ color: '#782e2e' }}>/MENSAL</p>
                    </div>
                  </Row>
                </div>
                <div>
                  <Row>
                    <div
                      style={{
                        marginLeft: '2%',
                        paddingLeft: '2%',
                        width: '8%',
                      }}
                    >
                      <img src={nophoto} width="100%" />
                    </div>
                    <div
                      style={{
                        marginLeft: '2%',
                        borderRadiusLeft: '5px',
                        paddingLeft: '2%',
                        fontSize: '10px',
                        width: '35%',
                      }}
                    >
                      <strong>MONITOR LCD PHILLIPS LK158</strong>
                      <br />
                      <p style={{ color: '#782e2e' }}>
                        <strong style={{ color: '#782e2e' }}>
                          QUANTIDADE:
                        </strong>{' '}
                        120
                      </p>
                    </div>
                    <div
                      style={{
                        paddingLeft: '2%',
                        fontSize: '10px',
                        width: '15%',
                      }}
                    >
                      <strong>PREVENTIVA</strong>
                      <br />
                      <p style={{ color: '#782e2e' }}>/POR UNIDADE</p>
                    </div>
                    <div
                      style={{
                        paddingLeft: '2%',
                        fontSize: '10px',
                        width: '20%',
                      }}
                    >
                      <strong>R$15,90</strong>
                      <br />
                      <p style={{ color: '#782e2e' }}>/POR UNIDADE</p>
                    </div>
                    <div
                      style={{
                        borderRadiusRight: '5px',
                        marginRight: '2%',
                        paddingLeft: '2%',
                        fontSize: '10px',
                        width: '15%',
                      }}
                    >
                      <strong>R$1.908,00</strong>
                      <br />
                      <p style={{ color: '#782e2e' }}>/MENSAL</p>
                    </div>
                  </Row>
                </div>
                <div>
                  <Row>
                    <div
                      style={{
                        marginLeft: '2%',
                        paddingLeft: '2%',
                        width: '8%',
                      }}
                    >
                      <img src={nophoto} width="100%" />
                    </div>
                    <div
                      style={{
                        marginLeft: '2%',
                        borderRadiusLeft: '5px',
                        paddingLeft: '2%',
                        fontSize: '10px',
                        width: '35%',
                      }}
                    >
                      <strong>MONITOR LCD PHILLIPS LK158</strong>
                      <br />
                      <p style={{ color: '#782e2e' }}>
                        <strong style={{ color: '#782e2e' }}>
                          QUANTIDADE:
                        </strong>{' '}
                        120
                      </p>
                    </div>
                    <div
                      style={{
                        paddingLeft: '2%',
                        fontSize: '10px',
                        width: '15%',
                      }}
                    >
                      <strong>PREVENTIVA</strong>
                      <br />
                      <p style={{ color: '#782e2e' }}>/POR UNIDADE</p>
                    </div>
                    <div
                      style={{
                        paddingLeft: '2%',
                        fontSize: '10px',
                        width: '20%',
                      }}
                    >
                      <strong>R$15,90</strong>
                      <br />
                      <p style={{ color: '#782e2e' }}>/POR UNIDADE</p>
                    </div>
                    <div
                      style={{
                        borderRadiusRight: '5px',
                        marginRight: '2%',
                        paddingLeft: '2%',
                        fontSize: '10px',
                        width: '15%',
                      }}
                    >
                      <strong>R$1.908,00</strong>
                      <br />
                      <p style={{ color: '#782e2e' }}>/MENSAL</p>
                    </div>
                  </Row>
                </div>
              </div>
              <br />
              <div style={{ textAlign: 'center' }}>
                <ModalRejectProposal buttonLabel="REPROVAR" />
                <ModalAcceptProposal buttonLabel="APROVAR" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProposalCustomer;
