import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import '../backup.css';
import { Prefix } from '../../../services/prefix';
import { Link } from 'react-router-dom';
import Loading from '../../loader/loading';
import Swal from 'sweetalert2';
import Modal from 'react-bootstrap/Modal';
import Fontawesome from '../../fontawesome/fontawesome';
import { mutate as GlobalMutate } from 'swr';
import api from '../../../services/api';
import { useFetch } from '../../hooks/useFetch';
import Alert from '../../alert/alert';

const IndexEditBackup = ({ id, page, rowsPage, search }) => {
  const [loader, setLoader] = useState();
  const [show, setShow] = useState(false);
  const [dados, setDados] = useState({
    active: 1,
  });
  let pageExact = page + parseInt(1);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const itemList = useFetch(Prefix.replace(/[\\"]/g, '') + '/contract-items');

  const backupList = useFetch(
    Prefix.replace(/[\\"]/g, '') + '/equipament-backup/' + id,
  );
  if (!backupList.data) return <Loading />;

  const handleChange = (e) => {
    setDados({
      ...dados,
      [e.target.name]: e.target.value.trim(),
    });
  };

  const handleSelect = (e) => {
    const itemContract = itemList.data?.models.filter(
      (filtro) => filtro.id == e.target.value,
    );
    setDados({
      ...dados,
      id_contract_item: e.target.value,
      id_equipament_model: itemContract[0].id_model,
      serial: itemContract[0].serial,
      active: '1',
    });
  };

  function HandleUpdate(e) {
    e.preventDefault();
    setLoader(<Loading />);
    api
      .put(Prefix.replace(/[\\"]/g, '') + '/equipament-backup/' + id, dados)
      .then((response) => {
        Swal.fire({
          icon: 'success',
          title: 'Uow!',
          text: response.data.message,
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: 'Fechar',
          cancelButtonText: 'Novo Cadastro',
          allowOutsideClick: false,
        }).then((result) => {
          if (!result.isConfirmed) {
            setShow(true);
          }
        });
        setLoader();
        setShow(false);
        backupList.mutate();
        if (!search) {
          GlobalMutate(
            Prefix.replace(/[\\"]/g, '') +
              '/equipament-backup?per_page=' +
              rowsPage +
              '&page=' +
              pageExact +
              '&search=',
          );
        } else {
          GlobalMutate(
            Prefix.replace(/[\\"]/g, '') +
              '/equipament-backup?per_page=' +
              rowsPage +
              '&page=' +
              pageExact +
              '&search=' +
              search,
          );
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: err.response.data.message,
          confirmButtonText: 'Tentar novamente',
        });
        setLoader();
      });
  }

  return (
    <>
      {' '}
      <Link
        variant="success"
        onClick={handleShow}
        className="link_color_datatable"
      >
        <Fontawesome classe="fas fa-pencil-alt" />
      </Link>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Atualizar Equipamento de Backup</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="customerStep">
            {loader}
            <Row>
              <Col>
                <div className="formStep">
                  <label class="important">Equipamento</label>

                  <select
                    name="id_contract_item"
                    defaultValue={backupList.data?.model.id_contract_item}
                    onChange={handleSelect}
                  >
                    <option selected disabled>
                      Selecione um equipamento...
                    </option>
                    {itemList.data?.models.map((map, key) => {
                      return <option value={map.id}>{map.nickname}</option>;
                    })}
                  </select>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="formStep">
                  <label>Observações</label>
                  <textarea
                    type="text"
                    name="obs"
                    defaultValue={backupList.data?.model.obs}
                    onKeyUp={handleChange}
                  ></textarea>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="formStep">
                  <label class="important">Data de Início</label>
                  <input
                    type="date"
                    name="start_at"
                    defaultValue={backupList.data?.model.start_at}
                    onChange={handleChange}
                  />
                </div>
              </Col>
              <Col>
                <div className="formStep">
                  <label class="important">Data de Término</label>
                  <input
                    type="date"
                    name="end_at"
                    defaultValue={backupList.data?.model.end_at}
                    onChange={handleChange}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex justify-content-center">
                <Button variant="success mt-3" onClick={HandleUpdate}>
                  Atualizar
                </Button>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default IndexEditBackup;
