export const ListEmployeeTypes = [
  { value: 2, label: 'Técnico' },
  // { value: 6, label: 'Monitoramento (NOC)' },
  { value: 7, label: 'Operador (Planejamento)' },
  { value: 8, label: 'Comercial' },
  { value: 9, label: 'Controladoria' },
  { value: 12, label: 'Staff Administrativo' },
  { value: 13, label: 'Administrador' },
  { value: 16, label: 'Master' },
];
export default {
  ListEmployeeTypes,
};
