import React, { Component, memo } from 'react';

class ImageUser extends Component {
  render() {
    return (
      <div
        className={this.props.classe}
        style={{ background: 'url(' + this.props.file + ')', margin: '0 auto' }}
      ></div>
    );
  }
}
export default memo(ImageUser);
