import React, { useState, useEffect, useRef, useContext } from "react";
import { Row, Col, Button, Modal, Alert } from "react-bootstrap";
import Fontawesome from "../../fontawesome/fontawesome";
import "../sectors.css";
import { Prefix } from "../../../services/prefix";
import { userType } from "../../data/userType";
import Loading from "../../loader/loading";
import Swal from "sweetalert2";
import api from "../../../services/api";
import { uf } from "../../data/uf";
import { Link } from "react-router-dom";
import { useFetch } from "../../hooks/useFetch";
// import Alert from '../../alert/alert';
import { mutate as GlobalMutate } from "swr"; //importando o mutate do swr
import { Context } from "../../../services/context";
import { AsyncPaginate, component } from "react-select-async-paginate";
import { NoOptionsMessage } from "../../selectAsync/NoOptionsMessage";
import { colourStyles } from "../../selectAsync/colourStyles";
import { loadOptions } from "../../selectAsync/loadOptions";
import { Formik, Form, FieldArray, ErrorMessage, Field } from "formik";
import { Schema } from "./schema";
import { ModalContainer } from "../../../style/stylesModals";
import { CustomButton, TextButton } from "../../../style/stylesButton";

const IndexEditSectors = ({
  id,
  idClient,
  page,
  rowsPage,
  search,
  orderBy,
  order,
}) => {
  const { selectclient, selectunit, setSelectClient, setSelectUnit } =
    useContext(Context);

  const [loader, setLoader] = useState();
  const [setores, setSetores] = useState([]);
  const [show, setShow] = useState(false);
  const [valueSelect, setValueSelect] = useState([]);
  const [registerData, setRegisterData] = useState({});
  let pageExact = page + parseInt(1);

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };

  const sectorList = useFetch(
    !show ? null : Prefix.replace(/[\\"]/g, "") + "/sectors/" + id
  );

  useEffect(() => {
    if (sectorList.data) {
      setRegisterData({
        initialData: [
          {
            active: "1",
            name: sectorList.data?.model?.name,
            id_unit: sectorList.data?.model?.id_unit,
          },
        ],
      });
    }
  }, [show, sectorList.data]);

  const HandleSelect = (e, name, key) => {
    if (
      valueSelect.filter((fill) => fill.key == key && fill.nameField == name)
        .length > 0
    ) {
      valueSelect
        .filter((fill) => fill.key == key && fill.nameField == name)
        .map((map) => {
          let position = valueSelect.indexOf(map);
          valueSelect.splice(position, 1);
        });
      setValueSelect(() => {
        return [
          ...valueSelect,
          { value: e.value, label: e.label, nameField: name, key: key },
        ];
      });
    } else {
      setValueSelect((prev) => {
        return [
          ...prev,
          { value: e.value, label: e.label, nameField: name, key: key },
        ];
      });
    }
  };

  function HandleRegister(values) {
    values.initialData.map((map) => {
      api
        .put(Prefix.replace(/[\\"]/g, "") + "/sectors/" + id, {
          active: "0",
          name: map.name,
          id_unit: map.id_unit,
        })
        .then((response) => {
          Swal.fire({
            icon: "success",
            title: "Uow!",
            text: response.data.message,
            confirmButtonText: "Continuar",
          });
          setLoader();
          setShow(false);
          GlobalMutate(
            Prefix.replace(/[\\"]/g, "") +
              "/sectors?per_page=" +
              rowsPage +
              "&page=" +
              pageExact +
              "&id_unit=" +
              selectunit +
              "&id_customer=" +
              selectclient +
              "&order_by=" +
              orderBy +
              "&order=" +
              order
          );
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: err.response.data.message,
            confirmButtonText: "Tentar novamente",
          });
        });
    });
  }

  return (
    <>
      {!show ? (
        <Link
          variant="success"
          onClick={handleShow}
          className="link_color_datatable"
        >
          <Fontawesome classe="fas fa-pencil-alt" />
        </Link>
      ) : (
        <ModalContainer
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <h3>Atualizar setor</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik //--> Configuração do Formik
              initialValues={registerData}
              validationSchema={Schema}
              onSubmit={HandleRegister}
              enableReinitialize
            >
              {({ values, handleChange, setFieldValue }) => (
                <Form>
                  <Alert variant="success">
                    <FieldArray name="initialData">
                      {({ push, remove }) => (
                        <div>
                          {values?.initialData?.map((p, index) => {
                            return (
                              <>
                                <Row>
                                  <Col>
                                    <div className="formStep">
                                      <label class="important">Unidade</label>
                                      <AsyncPaginate
                                        value={
                                          valueSelect.length == 0
                                            ? {
                                                value:
                                                  sectorList.data?.model?.unit
                                                    ?.id,
                                                label:
                                                  sectorList.data?.model?.unit
                                                    ?.name,
                                              }
                                            : valueSelect
                                                .filter(
                                                  (fill) =>
                                                    fill.nameField ==
                                                      "id_unit" &&
                                                    fill.key == "id_unit"
                                                )
                                                .map((map) => {
                                                  return {
                                                    value: map.value,
                                                    label: map.label,
                                                  };
                                                })
                                        }
                                        loadOptions={loadOptions}
                                        onChange={(e) => {
                                          HandleSelect(e, "id_unit", "id_unit");
                                          setFieldValue(
                                            `initialData[${index}].id_unit`,
                                            e.value
                                          );
                                        }} //values and name of select paginate
                                        placeholder="Selecione uma cidade..."
                                        components={{ NoOptionsMessage }}
                                        cacheUniqs={[valueSelect]} //Set your param that is going to change when you select another field. Just use this option when you are setting params and need to refresh cache.
                                        styles={colourStyles}
                                        additional={{
                                          page: 1,
                                          endpoint: "/units",
                                        }}
                                      />
                                      <ErrorMessage
                                        name={`initialData[${index}].id_customer`}
                                        component="span"
                                        className="errorMessages"
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <div className="formStep">
                                      <label class="important">Setor</label>
                                      <Field
                                        type="text"
                                        placeholder="Setor"
                                        name={`initialData[${index}].name`}
                                        onChange={handleChange}
                                      />
                                      <ErrorMessage
                                        name={`initialData[${index}].name`}
                                        component="span"
                                        className="errorMessages"
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </>
                            );
                          })}
                          <div>
                            <Row>
                              <Col className="d-flex justify-content-center ">
                                <CustomButton type="submit">
                                  <TextButton>Atualizar</TextButton>
                                </CustomButton>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      )}
                    </FieldArray>
                  </Alert>
                </Form>
              )}
            </Formik>
          </Modal.Body>
        </ModalContainer>
      )}
    </>
  );
};
export default IndexEditSectors;
