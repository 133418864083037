import React, { useState, useEffect, useRef } from "react";
import Fontawesome from "../../fontawesome/fontawesome";
import "../users.css";
import { Prefix } from "../../../services/prefix";
import { userType } from "../../data/userType";
import Loading from "../../loader/loading";
import Swal from "sweetalert2";
import api from "../../../services/api";
import { Link } from "react-router-dom";
import { useFetch } from "../../hooks/useFetch";
import { mutate as GlobalMutate } from "swr"; //importando o mutate do swr
import { Row, Col, Button, Modal } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Schema from "./schema";
import { ListUsersTypes } from "../listUsersTypes";
import { ModalContainer } from "../../../style/stylesModals";
import { CustomButton, TextButton } from "../../../style/stylesButton";

const IndexEditUser = ({ id, page, rowsPage, search, orderBy, order }) => {
  const [loader, setLoader] = useState();
  const [show, setShow] = useState(false);
  const [initialState, setInitialState] = useState([]);
  const [registerData, setRegisterData] = useState(initialState);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let pageExact = page + parseInt(1);
  let valueSearch = !search ? "" : search;

  const userList = useFetch(Prefix.replace(/[\\"]/g, "") + "/users/" + id);

  useEffect(() => {
    if (userList?.data) {
      setInitialState({
        name: userList.data?.model?.name,
        email: userList.data?.model?.email,
        type: userList.data?.model?.type,
      });
    }
  }, [userList.data]);

  if (!userList.data) return <Loading />;
  userList.mutate();

  function HandleRegister(values) {
    if (values.email == userList.data?.model?.email) {
      delete values.email;
    }
    setLoader(true);
    api
      .put(Prefix.replace(/[\\"]/g, "") + "/users/" + id, values)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Uow!",
          text: response.data.message,
          confirmButtonText: "Continuar",
        });
        setLoader();
        setShow(false);
        GlobalMutate(
          Prefix.replace(/[\\"]/g, "") +
            "/users?per_page=" +
            rowsPage +
            "&page=" +
            pageExact +
            "&search=" +
            valueSearch +
            "&order_by=" +
            orderBy +
            "&order=" +
            order
        );
        userList.mutate();
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: err.response.data.message,
          confirmButtonText: "Tentar novamente",
        });
        setLoader();
      });
  }

  return (
    <>
      <Link to="#" onClick={handleShow} className="link_color_datatable">
        <Fontawesome classe="fas fa-pencil-alt" />
      </Link>

      <ModalContainer
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h3>Atualização de Usuário</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik //--> Configuração do Formik
            initialValues={initialState}
            validationSchema={Schema}
            onSubmit={HandleRegister}
          >
            {(
              { values } //--> Captação de valores
            ) => (
              <Form>
                <Row>
                  {loader}
                  <Col>
                    <div className="formStep">
                      <label class="important">Nome do usuário</label>
                      <Field
                        type="text"
                        placeholder="Digite um nome..."
                        name="name"
                        defaultValue={values.name}
                        value={values.name}
                      />
                      <ErrorMessage
                        name="name"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="formStep">
                      <label class="important">E-mail</label>
                      <Field
                        type="email"
                        placeholder="Digite um e-mail..."
                        name="email"
                        defaultValue={values.email}
                      />
                      <ErrorMessage
                        name="email"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div className="formStep">
                      <label class="important">Tipo de conta</label>
                      <Field as="select" name="type" defaultValue={values.type}>
                        <option disabled selected>
                          Selecione...
                        </option>
                        {ListUsersTypes.map((type) => {
                          return (
                            <option value={type.value}>{type.label}</option>
                          );
                        })}
                      </Field>
                      <ErrorMessage
                        name="type"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col className="d-flex justify-content-center">
                    <CustomButton type="submit">
                      <TextButton>Atualizar</TextButton>
                    </CustomButton>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </ModalContainer>
    </>
  );
};
export default IndexEditUser;
