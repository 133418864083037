import React from 'react';
import { Link } from 'react-router-dom';
import Edit from '../editEmployer/editEmployer';
import Noc from '../noc_notifications/nocNotification';

const OptionsEmployer = ({
  id,
  page,
  rowsPage,
  search,
  orderBy,
  order,
  idUser,
}) => {
  return (
    <div className="dropdown">
      <Link
        to="#"
        className="dropdown link_color_datatable"
        id="dropdownNotification"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <img src={`/media/icons/elipse_options.svg`} width="5" />
      </Link>
      <ul
        className="dropdown-menu fade-in-top drop-options"
        aria-labelledby="dropdownNotification"
      >
        <li>
          <Edit
            id={id}
            page={page}
            rowsPage={rowsPage}
            search={search}
            orderBy={orderBy}
            order={order}
          />
        </li>
        <li>
          <Noc
            id={id}
            page={page}
            rowsPage={rowsPage}
            search={search}
            orderBy={orderBy}
            order={order}
            idUser={idUser}
          />
        </li>
      </ul>
    </div>
  );
};
export default OptionsEmployer;
