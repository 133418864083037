import * as Yup from 'yup';

Yup.setLocale({
  mixed: {
    required: 'Campo obrigatório',
  },
  string: {
    email: 'Preencha um e-mail válido',
    min: 'Mínimo de ${min} caracteres',
    max: 'Máximo de ${max} caracteres',
  },
  number: {
    min: 'Deve ser maior ou igual a ${min}',
    max: 'Deve ser menor ou igual a ${max}',
  },
});

const Schema = Yup.object().shape({
  id_equipment_category: Yup.number().required(),
  id_equipment_model: Yup.number().required(),
  serial: Yup.string().min(3).required(),
  installation_date: Yup.string().required(),
  preventive_frequency: Yup.number().required(),
  calibration_frequency: Yup.number().required(),
  nickname: Yup.string().min(3).max(30).required(),
  important: Yup.bool().required(),
  criticity: Yup.number().required(),
  additive: Yup.number().required(),
  patrimony: Yup.number().required(),
  identification: Yup.string().required(),
  alienated: Yup.string().required(),
  id_unit: Yup.number().required(),
  id_sector: Yup.number().required(),
  id_room: Yup.number().required(),
});
export default Schema;
