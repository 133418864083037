import React from 'react';
import Edit from '../../components/users/editusers/indexEditUsers';
import UpdateStatus from '../../components/users/updateStatus/updateStatus';
import Permission from '../../components/users/userPermission/permission';
import OpenModal from '../../components/modal/openModal';
import Password from '../../components/users/password/password';
import IndexLogo from '../../components/users/changeLogo/changeLogo';
import Avatar from '../../components/topbar/image-user';
import UserAvatar from '../../components/topbar/changeAvatar/userAvatar';
import OptionsUsers from '../../components/users/optionsUsers/optionsUsers';


export const columns = [
  {
    name: 'cover',
    label: 'Avatar',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      display: false,
      customBodyRender: (value, tableMeta) => {
        let idUser = tableMeta?.rowData[1];
        return <UserAvatar idUser={idUser} />;
      },
    },
  },

  {
    name: 'id',
    label: '',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      display: false,
    },
  },
  {
    name: 'when',
    label: 'Data',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
    },
  },
  {
    name: 'who',
    label: 'Nome',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      customBodyRender: (value, tableMeta) => {
        return value.name;
      },
    },
  },

  {
    name: 'where',
    label: 'Local',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
    },
  },
  {
    name: 'which',
    label: 'Descrição',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      customBodyRender: (value, tableMeta) => {
        if (value?.description == "created") {
          return "Criação";
        }  
        if (value?.description == "updated") {
          return "Atualização";
        } 
        if (value?.description == "deleted") {
          return "Exclusão";
        }
      },
    },
  },
  {
    name: 'active',
    label: '',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      display: 'excluded',
    },
  },
];
