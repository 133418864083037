import styled from 'styled-components';

export const Arrow = styled.img`
  cursor: pointer;
  width: 30px;

  &: hover {
    width: 40px;
    -webkit-transition: width 1s ease-in-out;
    -moz-transition: width 1s ease-in-out;
    -o-transition: width 1s ease-in-out;
    transition: width 1s ease-in-out;
  }
`;

export const ContentArrow = styled.div`
  height: 40px
`;
