import React, { useState, useContext } from 'react';
import { Row, Col, Modal } from 'react-bootstrap';
import { Prefix } from '../../../services/prefix';
import MUIDataTable from 'mui-datatables';
import { useFetch } from '../../hooks/useFetch';
import { DropContainer, UploadMessage } from '../uploadFile';
import Dropzone from 'react-dropzone';
import Swal from 'sweetalert2';
import CustomFooter from '../../CustomFooter/CustomFooter';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Loading from '../../loader/loading';
import { uniqueId } from 'lodash';
import api from '../../../services/api';
import CustomSearchRender from '../../customSearchRender/CustomSearchRender';
import { ColumnsAttachmentsOs } from './columnsAttachmentsOs';
import { mutate as GlobalMutate } from 'swr';
import { Link } from 'react-router-dom';
import Fontawesome from '../../fontawesome/fontawesome';
import { Context } from '../../../services/context';

const AttachmentOs = ({ id }) => {
  const { osItemServices, setOsItemServices } = useContext(Context);
  const [loader, setLoader] = useState();
  const [pagina, setPagina] = useState(0);
  const [perPage, setPerPage] = useState(15);
  const [search, setSearch] = useState('');
  let pageExact = pagina + parseInt(1);
  const [nameAttach, setNameAttach] = useState();
  const [uploaded, setUploaded] = useState([]);

  const listAttachmentOS = useFetch(
    Prefix.replace(/[\\"]/g, '') +
      '/os-attachments?all=false&per_page=' +
      perPage +
      '&page=' +
      pageExact +
      '&search=' +
      search,
  );

  const handleShow = () => {
    setOsItemServices({ ...osItemServices, service: 2, attach: id });
  };

  const handleName = (e) => {
    setNameAttach(e.target.value);
  };

  function renderDragMessage(isDragActive, isDragReject) {
    if (!isDragActive) {
      return <UploadMessage>Arraste os arquivos aqui</UploadMessage>;
    }
    if (isDragReject) {
      return <UploadMessage type="error">Arquivo não suportado</UploadMessage>;
    }

    return (
      <UploadMessage type="success">
        Solte os arquivos aqui para enviar
      </UploadMessage>
    );
  }

  const HandleUpload = (files) => {
    const uploadedFiles = files.map((file) => ({
      file,
      id: uniqueId(),
      name: nameAttach,
      readableSize: file.size,
      path: URL.createObjectURL(file),
      progress: 0,
      uploaded: false,
      error: false,
      url: null,
      id_os: id,
    }));

    setUploaded({
      ...uploaded,
      uploadedFiles,
    });

    uploadedFiles.forEach(processUpload);
  };

  const processUpload = (uploadedFile) => {
    setLoader(<Loading />);
    const data = new FormData();

    data.append('path', uploadedFile.file);
    data.append('name', uploadedFile.name);
    data.append('id_os', uploadedFile.id_os);
    data.append('active', 1);

    api
      .post(Prefix.replace(/[\\"]/g, '') + '/os-attachments', data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then((response) => {
        Swal.fire({
          icon: 'success',
          title: 'Uow!',
          text: response.data.message,
          confirmButtonText: 'Fechar',
        });
        setLoader(<Loading />);
        GlobalMutate(
          Prefix.replace(/[\\"]/g, '') +
            '/os-attachments?all=false&per_page=' +
            perPage +
            '&page=' +
            pageExact +
            '&search=',
        );
      })
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: err.response.data.message,
          confirmButtonText: 'Tentar novamente',
        });
        setLoader();
      });
  };

  const options = {
    filterType: 'dropdown',
    draggableColumns: {
      enabled: true,
    },
    filter: false,

    serverSide: true,
    onTableChange: (action, tableState) => {
      setPagina(tableState.page);
      setPerPage(tableState.rowsPerPage);
    },
    onSearchChange: (searchText) => {
      setSearch(!searchText ? '' : searchText);
    },
    customSearchRender: (searchText, handleSearch, hideSearch, options) => {
      return (
        <CustomSearchRender
          placeholderSearch="Pesquisar anexo"
          searchText={searchText}
          search={search}
          onSearch={handleSearch}
          onHide={hideSearch}
          options={options}
        />
      );
    },
    filter: false,
    page: pagina,
    count: listAttachmentOS.data?.models?.total,
    expandableRows: false,
    expandableRowsHeader: false,
    expandableRowsOnClick: false,
    rowsPerPage: perPage,
    rowsPerPageOptions: [15, 30, 100],
    onRowsDelete: (rowsDeleted, newData) => {
      HandleDelete(rowsDeleted.data);
    },

    textLabels: {
      body: {
        noMatch: 'Desculpe, não encontramos equipamentos!',
        toolTip: 'Sort',
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
      pagination: {
        next: 'Avançar',
        previous: 'Voltar',
        rowsPerPage: 'Linhas por página:',
        displayRows: 'de',
      },

      toolbar: {
        search: 'Pesquisar',
        downloadCsv: 'Baixar CSV',
        print: 'Imprimir',
        viewColumns: 'Ver Colunas',
        filterTable: 'Filtrar tabela',
      },
      filter: {
        all: 'Tudo',
        title: 'FILTROS',
        reset: 'RESETAR',
      },
      viewColumns: {
        title: 'Exibir colunas',
        titleAria: 'Exibir/Ocultar colunas da tabela',
      },
      selectedRows: {
        text: 'Coluna(s) selecionada(s)',
        delete: 'Excluir',
        deleteAria: 'Excluir linhas selecionadas',
      },
    },
    customFooter: (
      count,
      page,
      rowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels,
    ) => {
      let numberPages = !listAttachmentOS?.data
        ? 0
        : listAttachmentOS.data?.models?.last_page;
      let rowsPerPageOptions = [15, 30, 100];
      return (
        <CustomFooter
          page={page}
          rowsPerPage={rowsPerPage}
          changeRowsPerPage={changeRowsPerPage}
          changePage={changePage}
          numberPages={numberPages}
          rowsPerPageOptions={rowsPerPageOptions}
        />
      );
    },
  };
  function HandleDelete(e) {
    setLoader(<Loading />);
    let idArr = [];

    e.map((del) => {
      idArr.push(
        listAttachmentOS.data?.models?.data.filter(
          (filtro, index) => index == del.index,
        )[0].id,
      );
    });

    idArr.map(
      (
        idDel, //Faz exclusão com map um a um para evitar bugs
      ) =>
        api
          .delete(Prefix.replace(/[\\"]/g, '') + '/os-attachments/' + idDel)
          .then((response) => {
            Swal.fire({
              icon: 'success',
              title: 'Uow!',
              text: response.data.message,
              confirmButtonText: 'Fechar',
            });
            setLoader();
            GlobalMutate(
              Prefix.replace(/[\\"]/g, '') +
                '/os-attachments?all=false&per_page=' +
                perPage +
                '&page=' +
                pageExact +
                '&search=',
            );
          })
          .catch((err) => {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: err.response.data.message,
              confirmButtonText: 'Tentar novamente',
            });
            setLoader();
          }),
    );
  }

  return (
    <>
      {!osItemServices.attach ? (
        <Link className="link_color_datatable" to="#" onClick={handleShow}>
          <Fontawesome classe="fas fa-paperclip mr-3" />
        </Link>
      ) : (
        <>
          <div className="customerStep flip-in-hor-top">
            <MUIDataTable
              data={listAttachmentOS.data?.models?.data.filter(
                (filtro) => filtro.id_os == id,
              )}
              columns={ColumnsAttachmentsOs}
              options={options}
            />
          </div>
        </>
      )}
    </>
  );
};
export default AttachmentOs;
