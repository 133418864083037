import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import Fontawesome from '../fontawesome/fontawesome';
import { Context } from '../../services/context';
import IndexEmployers from './indexEmployers';
import { ModalContainer } from '../../style/stylesModals';

const OptionEmployeeClients = ({ id }) => {
  const { employeeView, setEmployeeView } = useContext(Context);
  const [show, setShow] = useState(false);
  const handleShow = () => {
    setShow(true);
  };
  const handleClose = () => {
    setEmployeeView({
      employees: true,
      equipmentView: false,
      telegramView: false,
      addEmployees: false,
      linkTelegram: false,
      idUser: null,
      idCustomer: null,
      chatId: null,
    });
    setShow(false);
  };

  return (
    <>
      {show === false && (
        <Link className="dropdown-item" to="#" onClick={handleShow}>
          <Fontawesome classe="fas fa-users mr-2" /> Funcionários
        </Link>
      )}
      <ModalContainer
        show={show}
        size="lg"
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h3>
              {employeeView.employees
                ? 'Funcionários do cliente'
                : employeeView.addEmployees
                ? 'Adicionar funcionário'
                : employeeView.telegramView
                ? 'Vincular telegram para notificações'
                : employeeView.linkTelegram
                ? 'Vincular Telegram'
                : employeeView.equipmentView
                ? 'Visualização de equipamentos do cliente'
                : ''}
            </h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <IndexEmployers id={id} />
        </Modal.Body>
      </ModalContainer>
    </>
  );
};
export default OptionEmployeeClients;
