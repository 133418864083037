import React, { useState } from 'react';
import Sidebar from '../../components/sidebar/sidebar';
import './equipamentsBackup.css';
import Topbar from '../../components/topbar/topbar';
import Card from 'react-bootstrap/Card';
import { columns } from './columns.jsx';
import Content from '../../components/equipaments_backup/addEquipamentBackup/indexAddEquipamentBackup';
import MUIDataTable from 'mui-datatables';
import Fontawesome from '../../components/fontawesome/fontawesome';
import { Prefix } from '../../services/prefix';
import { useFetch } from '../../components/hooks/useFetch';
import Loading from '../../components/loader/loading';
import api from '../../services/api';
import Swal from 'sweetalert2';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CustomSearchRender from '../../components/customSearchRender/CustomSearchRender';
import CustomFooter from '../../components/CustomFooter/CustomFooter';
import { mutate as GlobalMutate } from 'swr';

export const EquipamentsBackup = () => {
  const [loader, setLoader] = useState();
  const [pagina, setPagina] = useState(0);
  const [perPage, setPerPage] = useState(15);
  const [search, setSearch] = useState('');

  let pageExact = pagina + parseInt(1);

  const backupList = useFetch(
    Prefix.replace(/[\\"]/g, '') +
      '/equipament-backup?per_page=' +
      perPage +
      '&page=' +
      pageExact +
      '&search=' +
      search,
  );

  const options = {
    filterType: 'dropdown',
    draggableColumns: {
      enabled: true,
    },
    filter: false,
    serverSide: true,
    onTableChange: (action, tableState) => {
      setPagina(tableState.page);
      setPerPage(tableState.rowsPerPage);
    },
    onSearchChange: (searchText) => {
      setSearch(!searchText ? '' : searchText);
    },
    customSearchRender: (searchText, handleSearch, hideSearch, options) => {
      return (
        <CustomSearchRender
          placeholderSearch="Pesquisar equipamento de backup"
          searchText={searchText}
          onSearch={handleSearch}
          onHide={hideSearch}
          options={options}
        />
      );
    },
    filter: false,
    page: pagina,
    count: backupList.data?.models?.total,
    expandableRows: false,
    expandableRowsHeader: false,
    expandableRowsOnClick: false,
    rowsPerPage: perPage,
    rowsPerPageOptions: [15, 30, 100],
    isRowExpandable: (dataIndex, expandedRows) => {
      if (dataIndex === 3 || dataIndex === 4) return false;
      if (
        expandedRows.data.length > 4 &&
        expandedRows.data.filter((d) => d.dataIndex === dataIndex).length === 0
      )
        return false;
      return true;
    },
    renderExpandableRow: (rowData, rowMeta) => {
      const colSpan = rowData.length + 1;
      return (
        <TableRow>
          <TableCell colSpan={colSpan}>
            <Row>
              <Col className="text-center">
                <strong>----</strong> {rowData[2]}
                <br />
                <strong>----</strong>{' '}
                {rowData[8] +
                  ', ' +
                  rowData[10] +
                  ', ' +
                  rowData[9] +
                  ', ' +
                  rowData[7]}
                <br />
                <strong>------</strong> {rowData[12]}
              </Col>
            </Row>
          </TableCell>
        </TableRow>
      );
    },
    onRowsDelete: (rowsDeleted, newData) => {
      HandleDelete(rowsDeleted.data);
    },

    searchPlaceholder: 'Pesquise um equipamento de backup',
    selectableRows: 'multiple',
    searchOpen: true,

    textLabels: {
      body: {
        noMatch: 'Desculpe, não encontramos equipamentos de backup!',
        toolTip: 'Sort',
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
      pagination: {
        next: 'Avançar',
        previous: 'Voltar',
        rowsPerPage: 'Linhas por página:',
        displayRows: 'de',
      },

      toolbar: {
        search: 'Pesquisar',
        downloadCsv: 'Baixar CSV',
        print: 'Imprimir',
        viewColumns: 'Ver Colunas',
        filterTable: 'Filtrar tabela',
      },
      filter: {
        all: 'Tudo',
        title: 'FILTROS',
        reset: 'RESETAR',
      },
      viewColumns: {
        title: 'Exibir colunas',
        titleAria: 'Exibir/Ocultar colunas da tabela',
      },
      selectedRows: {
        text: 'Coluna(s) selecionada(s)',
        delete: 'Excluir',
        deleteAria: 'Excluir linhas selecionadas',
      },
    },
    customFooter: (
      count,
      page,
      rowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels,
    ) => {
      let numberPages = backupList.data?.models?.last_page;
      let rowsPerPageOptions = [15, 30, 100];
      return (
        <CustomFooter
          page={page}
          rowsPerPage={rowsPerPage}
          changeRowsPerPage={changeRowsPerPage}
          changePage={changePage}
          numberPages={numberPages}
          rowsPerPageOptions={rowsPerPageOptions}
        />
      );
    },
  };

  function HandleDelete(e) {
    setLoader(<Loading />);
    let idArr = [];

    e.map((del) => {
      idArr.push(
        backupList.data?.models?.data.filter(
          (filtro, index) => index == del.index,
        )[0].id,
      );
    });

    idArr.map((idDel) =>
      api
        .delete(Prefix.replace(/[\\"]/g, '') + '/equipament-backup/' + idDel)
        .then((response) => {
          Swal.fire({
            icon: 'success',
            title: 'Uow!',
            text: response.data.message,
            confirmButtonText: 'Fechar',
          });
          setLoader();
          backupList.mutate();
          GlobalMutate(
            Prefix.replace(/[\\"]/g, '') +
              '/equipament-backup?per_page=' +
              perPage +
              '&page=' +
              pageExact +
              '&search=',
          );
        })
        .catch((err) => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: err.response.data.message,
            confirmButtonText: 'Tentar novamente',
          });
          setLoader();
        }),
    );
  }

  return (
    <div className="wrapper d-flex align-items-stretch">
      <Sidebar />
      {loader}
      <div id="content">
        <Topbar />
        <div className="content_all">
          <Card>
            <Card.Header>
              <h3 className="card-label header-card">
                <span>
                  <Fontawesome classe="fas fa-cogs mr-3" /> Equipamentos de
                  Backup <small>Gestão de Equipamentos de Backup</small>
                </span>
                <Content
                  titleLabel="Cadastro de Equipamentos de Backup"
                  buttonLabel="Adicionar"
                  iconClass="fas fa-plus-circle"
                  modalSize="md"
                  pageExact={pageExact}
                  perPage={perPage}
                />
              </h3>
            </Card.Header>
            <Card.Body>
              <Card.Text>
                <MUIDataTable
                  data={backupList.data?.models?.data}
                  columns={columns}
                  options={options}
                />
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default EquipamentsBackup;
