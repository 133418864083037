import React, { useState } from 'react';
import Swal from 'sweetalert2';
import Card from 'react-bootstrap/Card';
import { mutate as mutateGlobal } from 'swr';
import SidebarSisboard from '../../../components/sidebar/sidebar';
import Topbar from '../../../components/topbar/topbar';
import Fontawesome from '../../../components/fontawesome/fontawesome';
import MUIDataTable from 'mui-datatables';
import { Prefix } from '../../../services/prefix';
import { useFetch } from '../../../components/hooks/useFetch';
import Loading from '../../../components/loader/loading';
import { columns } from './columns';
import Content from '../../components/actionPlan/addPlan/indexAddPlan';
import CustomSearchRender from './CustomSearchRender';
import api from '../../../services/api';
import CustomFooter from '../../../components/CustomFooter/CustomFooter';
import GenerateActionPlan from '../../components/actionPlan/reports/GenerateActionPlan';
import {
  CardSt,
  CardHeaderSt,
  IconTitleSt,
  TitleSt,
  TextTitleSt,
  SmallTitleSt,
} from '../../../style/globalStyle';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { GetMuiTheme } from '../../../components/muiTheme/muiTheme';

export const ActionPlan = () => {
  const [loader, setLoader] = useState();
  const [pagina, setPagina] = useState(0);
  const [perPage, setPerPage] = useState(15);
  const [search, setSearch] = useState('');
  const [orderBy, setOrderBy] = useState('id');
  const [order, setOrder] = useState('desc');

  let pageExact = pagina + parseInt(1);

  const queryList = useFetch(
    Prefix.replace(/[\\"]/g, '') +
    '/contract-item-actions?per_page=' +
    perPage +
    '&page=' +
    pageExact +
    '&search=' +
    search +
    '&order_by=' +
    orderBy +
    '&order=' +
    order,
  );

  if (!queryList.data) return <Loading />;

  const options = {
    draggableColumns: {
      enabled: true,
    },
    sortOrder: {
      name: orderBy,
      direction: order,
    },
    serverSide: true,
    onTableChange: (action, tableState) => {
      setOrderBy(
        !tableState.sortOrder.name ? orderBy : tableState.sortOrder.name,
      );
      setOrder(
        !tableState.sortOrder.direction
          ? order
          : tableState.sortOrder.direction,
      );
      setPagina(tableState.page);
      setPerPage(tableState.rowsPerPage);
    },
    onSearchChange: (searchText) => {
      setSearch(!searchText ? '' : searchText);
    },
    customSearchRender: (searchText, handleSearch, hideSearch, options) => {
      return (
        <CustomSearchRender
          placeholderSearch="Pesquisar plano de ação"
          searchText={searchText}
          onSearch={handleSearch}
          onHide={hideSearch}
          options={options}
        />
      );
    },
    filter: false,
    page: pagina,
    count: queryList.data?.models?.total,
    expandableRows: false,
    expandableRowsHeader: false,
    expandableRowsOnClick: false,
    rowsPerPage: perPage,
    rowsPerPageOptions: [15, 30, 100],
    onRowsDelete: (rowsDeleted, newData) => {
      HandleDelete(rowsDeleted.data);
    },
    searchPlaceholder: 'Pesquise um plano de ação',
    selectableRows: 'multiple',
    searchOpen: true,

    textLabels: {
      body: {
        noMatch: 'Desculpe, não encontramos planos de ação!',
        toolTip: 'Sort',
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
      pagination: {
        next: 'Avançar',
        previous: 'Voltar',
        rowsPerPage: 'Linhas por página:',
        displayRows: 'de',
      },
      toolbar: {
        search: 'Pesquisar',
        downloadCsv: 'Baixar CSV',
        print: 'Imprimir',
        viewColumns: 'Ver Colunas',
        filterTable: 'Filtrar tabela',
      },
      filter: {
        all: 'Tudo',
        title: 'FILTROS',
        reset: 'RESETAR',
      },
      viewColumns: {
        title: 'Exibir colunas',
        titleAria: 'Exibir/Ocultar colunas da tabela',
      },
      selectedRows: {
        text: 'Coluna(s) selecionada(s)',
        delete: 'Excluir',
        deleteAria: 'Excluir linhas selecionadas',
      },
    },
    customFooter: (
      count,
      page,
      rowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels,
    ) => {
      let numberPages = queryList.data?.models?.last_page;
      let rowsPerPageOptions = [15, 30, 100];
      return (
        <CustomFooter
          page={page}
          rowsPerPage={rowsPerPage}
          changeRowsPerPage={changeRowsPerPage}
          changePage={changePage}
          numberPages={numberPages}
          rowsPerPageOptions={rowsPerPageOptions}
        />
      );
    },
  };

  function HandleDelete(e) {
    setLoader(<Loading />);
    let idArr = [];

    e.map((del) => {
      idArr.push(
        queryList.data?.models?.data.filter(
          (filtro, index) => index == del.index,
        )[0].id,
      );
    });

    idArr.map((idDel) =>
      api
        .delete(
          Prefix.replace(/[\\"]/g, '') + '/contract-item-actions/' + idDel,
        )
        .then((response) => {
          Swal.fire({
            icon: 'success',
            title: 'Uow!',
            text: response.data.message,
            confirmButtonText: 'Fechar',
          });
          setLoader();
          mutateGlobal(
            Prefix.replace(/[\\"]/g, '') +
            '/contract-item-actions?per_page=' +
            perPage +
            '&page=' +
            pageExact +
            '&search=' +
            search +
            '&order_by=' +
            orderBy +
            '&order=' +
            order,
          );
        })
        .catch((err) => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: err.response.data.message,
            confirmButtonText: 'Tentar novamente',
          });
          setLoader();
        }),
    );
  }

  return (
    <div className="wrapper d-flex align-items-stretch">
      <SidebarSisboard />
      <div id="content">
        {loader}
        <Topbar />
        <div className="content_all">
          <CardSt>
            <CardHeaderSt className="d-flex justify-content-between">
              <TitleSt>
                <IconTitleSt
                  style={{ width: '36px' }}
                  src="../../../media/icons-pages/actionplan-page-sis.svg"
                />
                <TextTitleSt>
                  Plano de Ação{' '}
                  <SmallTitleSt style={{ marginRight: '8px' }}>
                    Gestão de Plano de Ação{' '}
                  </SmallTitleSt>
                  {/* <GenerateActionPlan /> */}
                </TextTitleSt>
              </TitleSt>
              <Content
                titleLabel="Cadastro de Plano de Ação"
                buttonLabel="Adicionar"
                iconClass="fas fa-plus-circle"
                modalSize="md"
                pageExact={pageExact}
                perPage={perPage}
                orderBy={orderBy}
                order={order}
                search={search}
              />
            </CardHeaderSt>
            <CardSt.Body as="div">
              <CardSt.Text as="div">
                <MuiThemeProvider theme={GetMuiTheme()}>
                  <MUIDataTable
                    data={queryList.data?.models?.data}
                    columns={columns}
                    options={options}
                  />
                </MuiThemeProvider>
              </CardSt.Text>
            </CardSt.Body>
          </CardSt>
        </div>
      </div>
    </div>
  );
};

export default ActionPlan;
