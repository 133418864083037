import React, { useState } from 'react';
import Sidebar from '../../components/sidebar/sidebar';
import Topbar from '../../components/topbar/topbar';
import { Card, Row, Col, Table } from 'react-bootstrap';
import Fontawesome from '../../components/fontawesome/fontawesome';
import { FullCalendar } from 'primereact/fullcalendar';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import { useFetch } from '../../components/hooks/useFetch';
import { Prefix } from '../../services/prefix';
import { Modal } from 'react-bootstrap';
import esLocale from '@fullcalendar/core/locales/pt-br';
import api from '../../services/api';

const Planning = () => {
  const listPlanning = useFetch(Prefix.replace(/[\\"]/g, '') + '/os');
  const [show, setShow] = useState(false);
  const [osData, setOsData] = useState({});

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = (id) => {
    api
      .get(Prefix.replace(/[\\"]/g, '') + '/os/' + id)
      .then((response) => setOsData(response.data.model));
    setShow(true);
  };

  const date = new Date();
  const day = date.getDate();
  const month =
    date.getMonth() + 1 < 10
      ? '0' + (date.getMonth() + 1)
      : date.getMonth() + 1;
  const year = date.getFullYear();
  const fullDate = `${year}-${month}-${day}`;

  const options = {
    plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
    defaultView: 'dayGridMonth',
    defaultDate: fullDate,
    locale: esLocale,
    eventLimit: true,
    header: {
      left: 'prev,next',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay',
    },
    editable: false,
    dayMaxEventRows: true, // for all non-TimeGrid views
    views: {
      timeGrid: {
        dayMaxEventRows: 4,
      },
    },
    eventClick: function (info) {
      handleShow(info.event.id);
      // alert('Event: ' + info.event.title);
    },
  };

  const refactPlanning = listPlanning.data?.models.map((map) => ({
    id: map.id,
    title: map.service,
    start:
      map.scheduled_at.split(' ')[0] + 'T' + map.scheduled_at.split(' ')[1],
    backgroundColor:
      map.service == 'Preventiva' || map.service == 'Calibração'
        ? '#154293'
        : map.service == 'Calibração'
        ? '#4b9315'
        : '#154293',
    borderColor:
      map.service == 'Preventiva'
        ? '#c02b2b'
        : map.service == 'Calibração'
        ? '#4b9315'
        : '#154293',
  }));

  return (
    <>
      <div className="wrapper d-flex align-items-stretch">
        <Sidebar />
        <div id="content">
          <Topbar />
          <div className="content_all">
            <Card>
              <Card.Header>
                <h3 className="card-label header-card">
                  <span>
                    <Fontawesome classe="fas fa-bullseye mr-3" /> Planejamento{' '}
                    <small>Gestão de planejamento</small>
                  </span>
                </h3>
              </Card.Header>
              <Card.Body>
                <Card.Text>
                  <FullCalendar events={refactPlanning} options={options} />
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        size="lg"
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Ordem de Serviço</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Table striped bordered hover size="sm" className="text-center">
            <tbody>
              <tr>
                <th>Serviço</th>
                <th>Equipamento</th>
                <th>Serial</th>
                <th>Agendado para</th>
              </tr>
              <tr>
                <td>{osData.service}</td>
                <td>{osData.contract_itens?.nickname}</td>
                <td>{osData.contract_itens?.serial}</td>
                <td>
                  {osData.scheduled_at?.split(' ')[0].split('-')[2] +
                    '/' +
                    osData.scheduled_at?.split(' ')[0].split('-')[1] +
                    '/' +
                    osData.scheduled_at?.split(' ')[0].split('-')[0] +
                    ' às ' +
                    osData.scheduled_at?.split(' ')[1]}
                </td>
              </tr>
              <tr>
                <th>Técnico</th>
                <th>Empresa terceirizada</th>
                <th>Urgência</th>
                <th>Executado em</th>
              </tr>
              <tr>
                <td>
                  {!osData.technician_third_company
                    ? osData.technician?.technician_client.user.name
                    : osData.technician_third_company}
                </td>
                <td>
                  {!osData.third_companie
                    ? 'Nenhuma'
                    : osData.third_companie?.name}
                </td>
                <td>{osData.urgency}</td>
                <td>
                  {!osData.execution_at
                    ? 'Não executado'
                    : osData.execution_at?.split(' ')[0].split('-')[2] +
                      '/' +
                      osData.execution_at?.split(' ')[0].split('-')[1] +
                      '/' +
                      osData.execution_at?.split(' ')[0].split('-')[0] +
                      ' às ' +
                      osData.execution_at?.split(' ')[1]}
                </td>
              </tr>
              <tr>
                <th colspan="2">Descrição</th>
                <th colspan="2">Observação</th>
              </tr>
              <tr>
                <td colspan="2">{osData.description}</td>
                <td colspan="2">{osData.obs}</td>
              </tr>
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default Planning;
