import styled from 'styled-components';

export const CustomButton = styled.button`
  background-color: #822a2a;
  width: 160px;
  height: 60px;
  border: 0;
  border-radius: 30px;
  margin-top: 20px;
`;

export const TextButton = styled.span`
  font-family: Poppins;
  text-align: center;
  vertical-align: center;
  color: #ffffff;
`;

export const ButtonDesabled = styled.button`
  background-color: #F6CECE;
  width: 160px;
  height: 60px;
  border: 0;
  border-radius: 30px;
  margin-top: 20px;
`;
