import React, { useState, useContext } from 'react';
import { useFetch } from '../../hooks/useFetch';
import { Prefix } from '../../../services/prefix';
import { columns } from './columns.jsx';
import CustomSearchRender from '../../customSearchRender/CustomSearchRender';
import { TableRow, TableCell } from '@material-ui/core';
import { Row, Col } from 'react-bootstrap';
import Swal from 'sweetalert2';
import api from '../../../services/api';
import CustomFooter from '../../CustomFooter/CustomFooter';
import MUIDataTable from 'mui-datatables';
import { Button } from './equipmentsStyle';
import { Context } from '../../../services/context';
import { CustomButton, TextButton} from "../../../style/stylesButton";

const Equipments = ({ idContract, idCustomer }) => {
  const { viewEquipments, setViewEquipments } = useContext(Context);
  const [perPage, setPerPage] = useState(15);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState('');
  const [orderBy, setOrderBy] = useState('id');
  const [order, setOrder] = useState('desc');
  const pageExact = parseInt(page) + 1;

  const itemsList = useFetch(
    Prefix.replace(/[\\"]/g, '') +
      '/contract-items?id_contract=' +
      idContract +
      '&per_page=' +
      perPage +
      '&page=' +
      pageExact +
      '&search=' +
      search +
      '&order_by=' +
      orderBy +
      '&order=' +
      order,
  );

  function HandleDelete(e) {
    let idArr = [];

    e.map((del) => {
      idArr.push(
        itemsList.data?.models.data.filter(
          (filtro, index) => index == del.index,
        )[0].id,
      );
    });

    idArr.map(
      (
        idDel, //Faz exclusão com map um a um para evitar bugs
      ) =>
        api
          .delete(Prefix.replace(/[\\"]/g, '') + '/contract-items/' + idDel)
          .then((response) => {
            Swal.fire({
              icon: 'success',
              title: 'Uow!',
              text: response.data.message,
              confirmButtonText: 'Fechar',
            });
            itemsList.mutate();
          })
          .catch((err) => {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: err.response.data.message,
              confirmButtonText: 'Tentar novamente',
            });
          }),
    );
  }

  const options = {
    filterType: 'dropdown',
    draggableColumns: {
      enabled: true,
    },
    filter: false,
    serverSide: true,
    sortOrder: {
      name: orderBy,
      direction: order,
    },
    onTableChange: (action, tableState) => {
      setOrderBy(
        !tableState.sortOrder.name ? orderBy : tableState.sortOrder.name,
      );
      setOrder(
        !tableState.sortOrder.direction
          ? order
          : tableState.sortOrder.direction,
      );
      setPage(tableState.page);
      setPerPage(tableState.rowsPerPage);
    },
    onSearchChange: (searchText) => {
      setSearch(!searchText ? '' : searchText); //faz condição para string vazia exibir vazio no lugar de null ou undefined
    },
    customSearchRender: (searchText, handleSearch, hideSearch, options) => {
      return (
        <CustomSearchRender
          placeholderSearch="Pesquisar Equipamento"
          searchText={searchText}
          search={search}
          onSearch={handleSearch}
          onHide={hideSearch}
          options={options}
        />
      );
    },
    filter: false,
    page: page,
    count: !itemsList.data ? 0 : itemsList.data?.models.total,
    expandableRows: false,
    expandableRowsHeader: false,
    expandableRowsOnClick: false,
    rowsPerPage: perPage,
    rowsPerPageOptions: [15, 30, 100],
    // isRowExpandable: (dataIndex, expandedRows) => {
    //   if (dataIndex === 3 || dataIndex === 4) return false;
    //   if (
    //     expandedRows.data.length > 4 &&
    //     expandedRows.data.filter((d) => d.dataIndex === dataIndex).length === 0
    //   )
    //     return false;
    //   return true;
    // },
    renderExpandableRow: (rowData, rowMeta) => {
      const colSpan = rowData.length + 1;
      return (
        <TableRow>
          <TableCell colSpan={colSpan}>
            <Row>
              <Col className="text-center">
                <strong>Nome do cliente:</strong> {rowData[2]}
                <br />
                <strong>Endereço:</strong>{' '}
                {rowData[8] +
                  ', ' +
                  rowData[10] +
                  ', ' +
                  rowData[9] +
                  ', ' +
                  rowData[7]}
                <br />
                <strong>Telefone:</strong> {rowData[12]}
              </Col>
            </Row>
          </TableCell>
        </TableRow>
      );
    },
    onRowsDelete: (rowsDeleted, newData) => {
      HandleDelete(rowsDeleted.data);
    },
    textLabels: {
      body: {
        noMatch: 'Desculpe, não encontramos equipamentos!',
        toolTip: 'Sort',
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
      pagination: {
        next: 'Avançar',
        previous: 'Voltar',
        rowsPerPage: 'Linhas por página:',
        displayRows: 'de',
      },

      toolbar: {
        search: 'Pesquisar',
        downloadCsv: 'Baixar CSV',
        print: 'Imprimir',
        viewColumns: 'Ver Colunas',
        filterTable: 'Filtrar tabela',
      },
      filter: {
        all: 'Tudo',
        title: 'FILTROS',
        reset: 'RESETAR',
      },
      viewColumns: {
        title: 'Exibir colunas',
        titleAria: 'Exibir/Ocultar colunas da tabela',
      },
      selectedRows: {
        text: 'Coluna(s) selecionada(s)',
        delete: 'Excluir',
        deleteAria: 'Excluir linhas selecionadas',
      },
    },
    customFooter: (
      count,
      page,
      rowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels,
    ) => {
      let numberPages = !itemsList.data ? 0 : itemsList.data?.models.last_page;
      let rowsPerPageOptions = [15, 30, 100];
      return (
        <CustomFooter
          page={page}
          rowsPerPage={rowsPerPage}
          changeRowsPerPage={changeRowsPerPage}
          changePage={changePage}
          numberPages={numberPages}
          rowsPerPageOptions={rowsPerPageOptions}
        />
      );
    },
  };

  const HandleAdd = () => {
    setViewEquipments({
      ...viewEquipments,
      idCustomer: idCustomer,
      idContract: idContract,
      equipments: false,
      addEquipments: true,
      orderBy: orderBy,
      order: order,
    });
  };

  return (
    <>
      <Row>
        <Col className="d-flex justify-content-center">
          <CustomButton type='button' style={{width:'200px'}} onClick={HandleAdd} >
           <TextButton>Adicionar equipamentos</TextButton> 
            </CustomButton>
        </Col>
      </Row>
      <MUIDataTable
        title={'Lista de Equipamentos do Contrato'}
        data={itemsList.data?.models.data}
        columns={columns}
        options={options}
      />
    </>
  );
};
export default Equipments;
