import React, { useState, useContext, useEffect } from 'react';
import { Modal, Button, Col, Row, Form } from 'react-bootstrap';
import { Prefix } from '../../../services/prefix';
import MUIDataTable from 'mui-datatables';
import { useFetch } from '../../hooks/useFetch';
import Swal from 'sweetalert2';
import CustomFooter from '../../CustomFooter/CustomFooter';
import Loading from '../../loader/loading';
import api from '../../../services/api';
import CustomSearchRender from '../../customSearchRender/CustomSearchRender';
import { ColumnsCommentsOs } from './columnsCommentsOs';
import { mutate as GlobalMutate } from 'swr';
import { Link } from 'react-router-dom';
import Fontawesome from '../../fontawesome/fontawesome';
import Select from 'react-select';
import { Context } from '../../../services/context';

const CommentsOs = ({ id, idContractItem }) => {
  const { redirect, osItemServices, setOsItemServices } = useContext(Context);
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState();
  const [pagina, setPagina] = useState(0);
  const [perPage, setPerPage] = useState(15);
  const [search, setSearch] = useState('');
  const [add, setAdd] = useState(false);
  const [formData, setFormData] = useState({
    id: 0,
    id_os: id,
    restricted: 0,
    message: '',
  });
  let pageExact = pagina + parseInt(1);

  const listCommentsOs = useFetch(
    Prefix.replace(/[\\"]/g, '') +
      '/os-comments?all=false&per_page=' +
      perPage +
      '&page=' +
      pageExact +
      '&search=' +
      search +
      '&id_os=' +
      id,
  );

  useEffect(() => {
    listCommentsOs.mutate();
  }, [show]);

  const findContractItem = useFetch(
    Prefix.replace(/[\\"]/g, '') + '/contract-items/' + idContractItem,
  );
  const idEquipamentCategory = !findContractItem.data?.model
    ? ''
    : findContractItem.data?.model.equipament_model.id_equipament_category;

  const handleShow = () => {
    setOsItemServices({ ...osItemServices, service: 3, comment: id });
  };

  const options = {
    filterType: 'dropdown',
    draggableColumns: {
      enabled: true,
    },
    serverSide: true,
    onTableChange: (action, tableState) => {
      setPagina(tableState.page);
      setPerPage(tableState.rowsPerPage);
    },
    onSearchChange: (searchText) => {
      setSearch(!searchText ? '' : searchText);
    },
    customSearchRender: (searchText, handleSearch, hideSearch, options) => {
      return (
        <CustomSearchRender
          placeholderSearch="Pesquisar comentário"
          searchText={searchText}
          search={search}
          onSearch={handleSearch}
          onHide={hideSearch}
          options={options}
        />
      );
    },
    filter: false,
    page: pagina,
    count: listCommentsOs.data?.models.total,
    expandableRows: false,
    expandableRowsHeader: false,
    expandableRowsOnClick: false,
    rowsPerPage: perPage,
    rowsPerPageOptions: [15, 30, 100],
    onRowsDelete: (rowsDeleted, newData) => {
      HandleDelete(rowsDeleted.data);
    },
    searchOpen: true,
    textLabels: {
      body: {
        noMatch: 'Desculpe, não encontramos comentários!',
        toolTip: 'Sort',
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
      pagination: {
        next: 'Avançar',
        previous: 'Voltar',
        rowsPerPage: 'Linhas por página:',
        displayRows: 'de',
      },

      toolbar: {
        search: 'Pesquisar',
        downloadCsv: 'Baixar CSV',
        print: 'Imprimir',
        viewColumns: 'Ver Colunas',
        filterTable: 'Filtrar tabela',
      },
      filter: {
        all: 'Tudo',
        title: 'FILTROS',
        reset: 'RESETAR',
      },
      viewColumns: {
        title: 'Exibir colunas',
        titleAria: 'Exibir/Ocultar colunas da tabela',
      },
      selectedRows: {
        text: 'Coluna(s) selecionada(s)',
        delete: 'Excluir',
        deleteAria: 'Excluir linhas selecionadas',
      },
    },
    customFooter: (
      count,
      page,
      rowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels,
    ) => {
      let numberPages = !listCommentsOs.data
        ? 0
        : listCommentsOs.data?.models.last_page;
      let rowsPerPageOptions = [15, 30, 100];
      return (
        <CustomFooter
          page={page}
          rowsPerPage={rowsPerPage}
          changeRowsPerPage={changeRowsPerPage}
          changePage={changePage}
          numberPages={numberPages}
          rowsPerPageOptions={rowsPerPageOptions}
        />
      );
    },
  };

  function HandleRegisterComments() {
    api
      .post(Prefix.replace(/[\\"]/g, '') + '/os-comments', formData)
      .then((response) => {
        Swal.fire({
          icon: 'success',
          title: 'Uow...',
          text: response.data.message,
          confirmButtonText: 'Fechar',
        });
        setShow(false);
        listCommentsOs.mutate();
      })
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: err.response.data.message,
          confirmButtonText: 'Tentar novamente',
        });
      });
  }

  function HandleDelete(e) {
    setLoader(<Loading />);
    let idArr = [];

    e.map((del) => {
      idArr.push(
        listCommentsOs.data?.models.data.filter(
          (filtro, index) => index == del.index,
        )[0].id,
      );
    });

    idArr.map(
      (
        idDel, //Faz exclusão com map um a um para evitar bugs
      ) =>
        api
          .delete(Prefix.replace(/[\\"]/g, '') + '/os-comments/' + idDel)
          .then((response) => {
            Swal.fire({
              icon: 'success',
              title: 'Uow!',
              text: response.data.message,
              confirmButtonText: 'Fechar',
            });
            setLoader();
            listCommentsOs.mutate();
          })
          .catch((err) => {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: err.response.data.message,
              confirmButtonText: 'Tentar novamente',
            });
            setLoader();
          }),
    );
  }

  const HandleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: !e.target.checked ? 0 : 1,
    });
  };

  const HandleChangeText = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value.trim(),
    });
  };

  function HandleAddComment() {
    setAdd(true);
  }

  function HandleBack() {
    setAdd(false);
  }
  return (
    <>
      {!osItemServices.comment ? (
        <Link className="link_color_datatable" to="#" onClick={handleShow}>
          <Fontawesome classe="fas fa-comment mr-3" />
        </Link>
      ) : (
        <>
          <MUIDataTable
            data={listCommentsOs.data?.models.data}
            columns={ColumnsCommentsOs}
            options={options}
          />
        </>
      )}
    </>
  );
};
export default CommentsOs;
