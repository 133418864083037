import React from 'react';
import api from '../../../services/api';
import { Prefix } from '../../../services/prefix';
import { mutate as GlobalMutate } from 'swr';
import Swal from 'sweetalert2';

export default function UpdateStatus({
  idEquipment,
  page,
  rowsPage,
  active,
  search,
  orderBy,
  order,
  idContract,
}) {
  let pageExact = parseInt(page) + 1;

  function HandleStatus() {
    api
      .put(Prefix.replace(/[\\"]/g, '') + '/contract-items/' + idEquipment, {
        active: active == true || active == 1 ? 0 : 1,
      })
      .then(() => {
        GlobalMutate(
          Prefix.replace(/[\\"]/g, '') +
            '/contract-items?id_contract=' +
            idContract +
            '&per_page=' +
            rowsPage +
            '&page=' +
            pageExact +
            '&search=' +
            search +
            '&order_by=' +
            orderBy +
            '&order=' +
            order,
        );
      })
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          title: 'Uow!',
          text: err.response.data.message,
          confirmButtonText: 'Fechar',
        });
      });
  }

  return (
    <>
      <label class="switch">
        <input
          type="checkbox"
          id={idEquipment + 'active'}
          onChange={HandleStatus}
          checked={active == true || active == 1 ? true : false}
        />
        <span class="slider round"></span>
      </label>
    </>
  );
}
