import styled from "styled-components";

export const Caption = styled.p`
text-align: center;
color:#000000;
font-family: Poppins;
font-style: normal;
font-weight: normal;
font-size: 18px;
`;

export const ButtonProposal = styled.button`
  background-color: #822a2a;
  width: 200px;
  height: 60px;
  border: 0;
  border-radius: 30px;
  margin-top: 20px;
`;

export const ButtonDesabled = styled.button`
  background-color: #F6CECE;
  width: 200px;
  height: 60px;
  border: 0;
  border-radius: 30px;
  margin-top: 20px;
`;

export const TextProposal = styled.span`
  font-family: Poppins;
  text-align: center;
  vertical-align: center;
  color: #ffffff;
`;

export const Input = styled.input`
  height: 50px;
  background: #F6F6F6;
  border-radius: 13px;
  width: 100%;
  border:0;
  text-align:center;
  margin-top: 10px;
  margin-bottom: 10px;
  border-color: #e2e5ec;
  &:input:focus {
    border-color: #e2e5ec,
}
`;
export const BoxUpload = styled.div`
  width: 100%;
  background: #F6F6F6;
`;

export const ConfirmUpload = styled.img`
width: 65px;
padding: 10px;
`;

export const BoxConfirmUpload = styled.div`
text-align: center;
`;




