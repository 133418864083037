import React from 'react';
import EditNotificationView from './editNotificationView';
import MonitoringStatus from './monitoringStatus/monitoringStatus';

export const columns = [
  {
    //0
    name: 'id',
    label: '',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      display: 'excluded',
    },
  },
  {
    //1
    name: 'name',
    label: 'Nome',
    options: {
      print: false,
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: true,
      sort: false,
    },
  },
  {
    //2
    name: 'contract_item',
    label: 'Serial',
    options: {
      print: false,
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
          width: '30%',
        },
      }),
      filter: true,
      sort: false,
      customBodyRender: (value, tableMeta) => {
        return value.serial;
      },
    },
  },
  {
    //3
    name: 'identification',
    label: 'Whatsapp',
    options: {
      print: false,
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: true,
      sort: false,
    },
  },
  {
    //4
    name: 'monitoring',
    label: 'Monitorável',
    options: {
      print: false,
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: true,
      sort: false,
      display: true,
      customBodyRender: (value, tableMeta) => {
        return (
          <MonitoringStatus
            monitoringDefault={value}
            id={tableMeta.rowData[0]}
            page={tableMeta.tableState.page}
            rowsPage={tableMeta.tableState.rowsPerPage}
            search={
              !tableMeta.tableState.searchText
                ? ''
                : tableMeta.tableState.searchText
            }
            id_contract_item={tableMeta.rowData[6]}
          />
        );
      },
    },
  },
  {
    //5
    name: 'EditNotificationView',
    label: 'Editar',
    options: {
      print: false,
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: true,
      sort: false,
      display: 'excluded',
      customBodyRender: (value, tableMeta) => {
        return (
          <EditNotificationView
            id={tableMeta.rowData[0]}
            id_contract_item={tableMeta.rowData[6]}
          />
        );
      },
    },
  },

  {
    //6
    name: 'id_contract_item',
    label: 'id_contract_item',
    options: {
      print: false,
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: true,
      sort: false,
      display: 'excluded',
    },
  },
];
