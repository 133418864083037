import React from "react";
import { Link } from "react-router-dom";
import { IconOption } from "../../optionsProposalsStyle";

const SeeProposal = ({ idRequest, count }) => {
  return (
    <>
        <Link className="dropdown-item" to={'/gestao/propostas/verificar-propostas/' + idRequest + '/' + count}  target="_blank">
          <IconOption src="../../../media/icons/eye.svg" />
          Ver proposta
        </Link>
    </>
  );
};
export default SeeProposal;
