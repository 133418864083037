import React, { useState } from 'react';
import { Row, Col, Modal, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Fontawesome from '../../../fontawesome/fontawesome';
import Loading from '../../../loader/loading';
import Swal from 'sweetalert2';
import MUIDataTable from 'mui-datatables';
import { columns } from './columns';
import api from '../../../../services/api';
import { Prefix } from '../../../../services/prefix';
import { useFetch } from '../../../hooks/useFetch';
import CustomSearchRender from '../CustomSearchRender';
import CustomFooter from '../../../CustomFooter/CustomFooter';
import { mutate as GlobalMutate } from 'swr';
import CurrencyInput from '../../../hooks/useCurrency';
import { ModalContainer } from "../../../../style/stylesModals"; 
import { CustomButton, TextButton } from "../../../../style/stylesButton";

export const ContractRenewal = ({ idContract, iconClass, text }) => {
  const [pagina, setPagina] = useState(0);
  const [perPage, setPerPage] = useState(15);
  const [search, setSearch] = useState('');
  const [loader, setLoader] = useState();
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({
    id_contract: idContract,
  });
  let pageExact = pagina + parseInt(1);

  const handleShow = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };

  const listRenewalDate = useFetch(
    Prefix.replace(/[\\"]/g, '') +
      '/contract-extensions?per_page=' +
      perPage +
      '&page=' +
      pageExact +
      '&search=' +
      search +
      '&id_contract=' +
      idContract,
  );

  const HandleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value.trim(),
    });
  };

  const HandleChangeValue = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value.replace(/[^\d]+/g, ''),
    });
  };

  const HandleRegister = () => {
    setLoader(<Loading />);
    api
      .post(Prefix.replace(/[\"]/g, '') + '/contract-extensions', formData)
      .then((response) => {
        Swal.fire({
          icon: 'success',
          title: 'Uow!',
          text: response.data.message,
          confirmButtonText: 'Fechar',
        });
        setLoader();
        GlobalMutate(
          Prefix.replace(/[\\"]/g, '') +
            '/contract-extensions?per_page=' +
            perPage +
            '&page=' +
            pageExact +
            '&search=' +
            search +
            '&id_contract=' +
            idContract,
        );
      })
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: err.response.data.message,
          confirmButtonText: 'Tentar novamente',
        });
        setLoader();
      });
  };

  function HandleDelete(e) {
    setLoader(<Loading />);
    let idArr = [];
    e.map((del) => {
      idArr.push(
        listRenewalDate.data?.models.data?.filter(
          (filtro, index) => index == del.index,
        )[0].id,
      );
    });

    idArr.map((idDel) =>
      api
        .delete(
          Prefix.replace(/[\\"]/g, '') + '/contract-extensions/' + idDel,
          {
            id: idArr,
          },
        )
        .then((response) => {
          Swal.fire({
            icon: 'success',
            title: 'Uow!',
            text: response.data.message,
            confirmButtonText: 'Fechar',
          });
          setLoader();
        })
        .catch((err) => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: err.response.data.message,
            confirmButtonText: 'Tentar novamente',
          });
          setLoader();
        }),
    );
  }

  const options = {
    draggableColumns: {
      enabled: true,
    },
    serverSide: true,
    onTableChange: (action, tableState) => {
      setPagina(tableState.page);
      setPerPage(tableState.rowsPerPage);
    },
    onSearchChange: (searchText) => {
      setSearch(!search ? '' : searchText);
    },
    customSearchRender: (searchText, handleSearch, hideSearch, options) => {
      return (
        <CustomSearchRender
          placeholderSearch="Pesquisar renovação"
          searchText={searchText}
          onSearch={handleSearch}
          onHide={hideSearch}
          options={options}
        />
      );
    },
    filter: false,
    page: pagina,
    count: listRenewalDate.data?.models.data.total,
    expandableRows: false,
    expandableRowsHeader: false,
    expandableRowsOnClick: false,
    rowsPerPage: perPage,
    rowsPerPageOptions: [15, 30, 100],
    onRowsDelete: (rowsDeleted, newData) => {
      HandleDelete(rowsDeleted.data);
    },
    searchPlaceholder: 'Pesquise uma Renovação',
    selectableRows: false,
    searchOpen: false,

    textLabels: {
      body: {
        noMatch: 'Desculpe, não encontramos Renovação!',
        toolTip: 'Sort',
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
      pagination: {
        next: 'Avançar',
        previous: 'Voltar',
        rowsPerPage: 'Linhas por página:',
        displayRows: 'de',
      },
      toolbar: {
        search: 'Pesquisar',
        downloadCsv: 'Baixar CSV',
        print: 'Imprimir',
        viewColumns: 'Ver Colunas',
        filterTable: 'Filtrar tabela',
      },
      filter: {
        all: 'Tudo',
        title: 'FILTROS',
        reset: 'RESETAR',
      },
      viewColumns: {
        title: 'Exibir colunas',
        titleAria: 'Exibir/Ocultar colunas da tabela',
      },
      selectedRows: {
        text: 'Coluna(s) selecionada(s)',
        delete: 'Excluir',
        deleteAria: 'Excluir linhas selecionadas',
      },
    },
    customFooter: (
      count,
      page,
      rowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels,
    ) => {
      let numberPages = listRenewalDate.data?.models.data.last_page;
      let rowsPerPageOptions = [15, 30, 100];
      return (
        <CustomFooter
          page={page}
          rowsPerPage={rowsPerPage}
          changeRowsPerPage={changeRowsPerPage}
          changePage={changePage}
          numberPages={numberPages}
          rowsPerPageOptions={rowsPerPageOptions}
        />
      );
    },
  };

  return (
    <>
      {!show ? (
        <Link className="dropdown-item" to="#" onClick={handleShow}>
          <Fontawesome classe={iconClass} /> {text}
        </Link>
      ) : (
        <ModalContainer
          show={show}
          size="md"
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title><h3>Gestão de Renovação <br/>de Contrato</h3></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="formStep">
              <Row>
                <Col>
                  <div className="formStep">
                    <label class="important">Data de Extensão:</label>
                    <input
                      type="date"
                      name="extended_date"
                      onChange={HandleChange}
                    />
                  </div>
                </Col>
                <Col>
                  <div className="formStep">
                    <label class="important">Valor de Contrato:</label>
                    <CurrencyInput
                      placeholder="R$ 0,00"
                      type="text"
                      name="new_value"
                      onChange={HandleChangeValue}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="text-center">
                  <CustomButton type='button' onClick={HandleRegister}>
                    <TextButton>Renovar</TextButton>
                  </CustomButton>
                </Col>
              </Row>
            </div>{' '}
            <MUIDataTable
              data={listRenewalDate.data?.models.data}
              columns={columns}
              options={options}
            />
          </Modal.Body>
        </ModalContainer>
      )}
    </>
  );
};
export default ContractRenewal;
