import styled, { css } from 'styled-components';
import Select from 'react-select';
import { AsyncPaginate } from "react-select-async-paginate";

export const CardMonitoring = styled.div`
  width: 100%;
`;

export const styledField = styled.input`
  width: 100%;
  text-align: center;
  margin-top: 0.8rem;
  border-radius: 60px;
  background: #f7f8fa2b;
  color: #fff;
  border: 1px solid #f7f8fa2b;
`;

export const Textarea = styled.textarea`
  &::placeholder {
    color: #e0caca;
  }
  color: #ffffff;
  display: inline-block;
  text-align: center;
  padding-left: 4rem;
  padding-right: 4rem;
  margin-top: 1.5rem;
  border-radius: 60px;
  background: #f7f8fa2b;
  border: 1px solid #f7f8fa2b;
  height: 100px;
`;

export const errorMessages = styled.div`
  color: #ffffff;
  border-bottom: 1px solid #ffffff;
  padding: 2px;
  display: flex;
  justify-content: center;
  font-size: 9px;
`;

