import React, { useState } from 'react';
import UpdateModule from '../updateModule/updateModule';
import '../permission.css';
import { Alert, Row, Col, Tab } from 'react-bootstrap';
import { Prefix } from '../../../../services/prefix';
import { useFetch } from '../../../hooks/useFetch';
import api from '../../../../services/api';
import FormCheck from 'react-bootstrap/FormCheck';
import Swal from 'sweetalert2';

const TabUser = (props) => {

  const userProfile = useFetch(
    Prefix.replace(/[\\"]/g, '') +
    '/users/' + props.idUser
  );

  const defaultScheme = userProfile.data?.model?.schemes
    .filter(
      (esq) => esq.id == props.idScheme,
    )[0];

  const [permissoes, setPermissoes] = useState(defaultScheme.normal);
  const [changeModules, setChangeModules] = useState(null);

  function HandleChangeOption(e) {
    setChangeModules(e == false ? true : false);
    const formData = {
      id_user: props.idUser,
      id_scheme: props.idScheme,
      status: e == false ? true : false,
    };
    api
      .put(
        Prefix.replace(/[\\"]/g, '') + '/permission-profiles/toggle',
        formData,
      )
      .then(() => {
        setPermissoes(e == false ? true : false);
      })
      .catch(() => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Não foi possível alterar o status da licença! Tente novamente mais tarde.',
          showConfirmButton: true,
          confirmButtonText: 'Fechar',
          allowOutsideClick: false,
        });
      });
  }

  return (
    <Tab.Pane eventKey={props.eventKey}>
      {userProfile.data?.model?.schemes
        .filter((fill) => fill.id == props.idScheme)
        .map((map) => {
          return (
            <>
              <Row>
                <Col>
                  <Alert variant="primary" className="activeScheme">
                    <strong>{map.name}</strong>Ativar / Desativar
                    licença para esta função:
                    <div class="custom-control custom-switch">
                      <FormCheck
                        id={'scheme' + map.id}
                        type="switch"
                        checked={permissoes}
                        onChange={() => HandleChangeOption(permissoes)}
                      />
                    </div>
                  </Alert>
                </Col>
              </Row>
              <Row>
                {map.modules.map((mod, key) => (
                  <Col sm={6} key={key}>
                    <UpdateModule
                      changeModules={changeModules}
                      key={key}
                      modName={mod.name_module}
                      idModule={mod.id_module}
                      idUser={props.idUser}
                      idScheme={map.id}
                    />
                  </Col>
                ))}
              </Row>
            </>
          );
        })}
    </Tab.Pane>
  );
};
export default TabUser;
