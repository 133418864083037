import React, { useState, useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import MUIDataTable from 'mui-datatables';
import Dropzone from 'react-dropzone';
import { DropContainer, UploadMessage } from './uploadFile';
import { uniqueId } from 'lodash';
import Swal from 'sweetalert2';
import api from '../../../services/api';
import { Prefix } from '../../../services/prefix';
import { columns } from './columns';
import CustomFooter from '../../CustomFooter/CustomFooter';
import CustomSearchRender from '../../customSearchRender/CustomSearchRender';
import { useFetch } from '../../hooks/useFetch';
import { TableRow, TableCell } from '@material-ui/core';
import { Context } from '../../../services/context';
import { BackButton } from './attachmentsStyles';
import Fontawesome from '../../fontawesome/fontawesome';
import { Link } from 'react-router-dom';

const Attachments = ({ idEquipment, back }) => {
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(15);
  const [search, setSearch] = useState('');
  const [nameAttach, setNameAttach] = useState();
  const [typeAttach, setTypeAttach] = useState();
  const [uploaded, setUploaded] = useState([]);
  const pageExact = parseInt(page) + 1;
  const { viewEquipments, setViewEquipments } = useContext(Context);

  const attachmentsList = useFetch(
    !viewEquipments.attachments
      ? null
      : Prefix.replace(/[\\"]/g, '') +
          '/contract-items-attachment?id_contract_item=' +
          viewEquipments.idEquipment +
          '&per_page' +
          perPage +
          '&page=' +
          pageExact +
          '&search=' +
          search,
  );

  function renderDragMessage(isDragActive, isDragReject) {
    if (!isDragActive) {
      return <UploadMessage>Arraste os arquivos aqui</UploadMessage>;
    }
    if (isDragReject) {
      return <UploadMessage type="error">Arquivo não suportado</UploadMessage>;
    }

    return <UploadMessage type="success">Solte os arquivos aqui</UploadMessage>;
  }

  const HandleUpload = (files) => {
    if (!nameAttach || !typeAttach) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Digite um nome e um tipo de anexo antes de enviar o arquivo...',
        confirmButtonText: 'Continuar',
      });
    } else {
      const uploadedFiles = files.map((file) => ({
        file,
        id: uniqueId(),
        name: nameAttach,
        readableSize: file.size,
        path: URL.createObjectURL(file),
        progress: 0,
        uploaded: false,
        error: false,
        url: null,
        id_contract: viewEquipments.idEquipment,
      }));

      setUploaded({
        ...uploaded,
        uploadedFiles,
      });

      uploadedFiles.forEach(processUpload);
    }
  };

  const processUpload = (uploadedFile) => {
    const data = new FormData();

    data.append('path', uploadedFile.file);
    data.append('name', uploadedFile.name);
    data.append('id_contract_item', uploadedFile.id_contract);
    data.append('active', 1);
    data.append('type', typeAttach);

    api
      .post(Prefix.replace(/[\\"]/g, '') + '/contract-items-attachment', data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then((response) => {
        Swal.fire({
          icon: 'success',
          title: 'Uow!',
          text: response.data.message,
          confirmButtonText: 'Fechar',
        });
        attachmentsList.mutate();
      })
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: err.response.data.message,
          confirmButtonText: 'Tentar novamente',
        });
      });
  };

  function HandleDelete(e) {
    let listAttachFilter = attachmentsList.data?.models.data.filter(
      (filtro) => filtro.id_contract_item == viewEquipments.idEquipment,
    );

    let idArr = [];
    e.map((del) => {
      idArr.push(
        listAttachFilter.filter((filtro, index) => index == del.index)[0].id,
      );
    });

    idArr.map((idDel) =>
      api
        .delete(
          Prefix.replace(/[\\"]/g, '') + '/contract-items-attachment/' + idDel,
        )
        .then((response) => {
          Swal.fire({
            icon: 'success',
            title: 'Uow!',
            text: response.data.message,
            confirmButtonText: 'Fechar',
          });
          attachmentsList.mutate();
        })
        .catch((err) => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: err.response.data.message,
            confirmButtonText: 'Tentar novamente',
          });
        }),
    );
  }

  const optionsAttach = {
    filterType: 'dropdown',
    draggableColumns: {
      enabled: true,
    },
    filter: false,

    serverSide: true,
    onTableChange: (action, tableState) => {
      setPage(tableState.page);
      setPerPage(tableState.rowsPerPage);
    },
    onSearchChange: (searchText) => {
      setSearch(!searchText ? '' : searchText); //faz condição para string vazia exibir vazio no lugar de null ou undefined
    },
    customSearchRender: (searchText, handleSearch, hideSearch, options) => {
      return (
        <CustomSearchRender
          placeholderSearch="Pesquisar Anexo de Equipamento"
          searchText={searchText}
          search={search}
          onSearch={handleSearch}
          onHide={hideSearch}
          options={options}
        />
      );
    },
    filter: false,
    page: page,
    count: !attachmentsList.data ? 0 : attachmentsList.data?.models.total,
    expandableRows: false,
    expandableRowsHeader: false,
    expandableRowsOnClick: false,
    rowsPerPage: perPage,
    rowsPerPageOptions: [15, 30, 100],
    isRowExpandable: (dataIndex, expandedRows) => {
      if (dataIndex === 3 || dataIndex === 4) return false;
      if (
        expandedRows.data.length > 4 &&
        expandedRows.data.filter((d) => d.dataIndex === dataIndex).length === 0
      )
        return false;
      return true;
    },
    renderExpandableRow: (rowData, rowMeta) => {
      const colSpan = rowData.length + 1;
      return (
        <TableRow>
          <TableCell colSpan={colSpan}>
            <Row>
              <Col className="text-center">
                <strong>Nome do cliente:</strong> {rowData[2]}
                <br />
                <strong>Endereço:</strong>{' '}
                {rowData[8] +
                  ', ' +
                  rowData[10] +
                  ', ' +
                  rowData[9] +
                  ', ' +
                  rowData[7]}
                <br />
                <strong>Telefone:</strong> {rowData[12]}
              </Col>
            </Row>
          </TableCell>
        </TableRow>
      );
    },
    onRowsDelete: (rowsDeleted, newData) => {
      HandleDelete(rowsDeleted.data);
    },

    textLabels: {
      body: {
        noMatch: 'Desculpe, não encontramos anexos!',
        toolTip: 'Sort',
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
      pagination: {
        next: 'Avançar',
        previous: 'Voltar',
        rowsPerPage: 'Linhas por página:',
        displayRows: 'de',
      },

      toolbar: {
        search: 'Pesquisar',
        downloadCsv: 'Baixar CSV',
        print: 'Imprimir',
        viewColumns: 'Ver Colunas',
        filterTable: 'Filtrar tabela',
      },
      filter: {
        all: 'Tudo',
        title: 'FILTROS',
        reset: 'RESETAR',
      },
      viewColumns: {
        title: 'Exibir colunas',
        titleAria: 'Exibir/Ocultar colunas da tabela',
      },
      selectedRows: {
        text: 'Coluna(s) selecionada(s)',
        delete: 'Excluir',
        deleteAria: 'Excluir linhas selecionadas',
      },
    },
    customFooter: (
      count,
      page,
      rowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels,
    ) => {
      let numberPages = attachmentsList.data?.models.last_page;
      let rowsPerPageOptions = [15, 30, 100];
      return (
        <CustomFooter
          page={page}
          rowsPerPage={rowsPerPage}
          changeRowsPerPage={changeRowsPerPage}
          changePage={changePage}
          numberPages={numberPages}
          rowsPerPageOptions={rowsPerPageOptions}
        />
      );
    },
  };

  const HandleShow = () => {
    setViewEquipments({
      ...viewEquipments,
      idEquipment: idEquipment,
      attachments: true,
      equipments: false,
    });
  };

  const HandleClose = () => {
    setViewEquipments({
      ...viewEquipments,
      attachments: false,
      equipments: true,
    });
  };

  return (
    <>
      {!viewEquipments.attachments ? (
        <Link to="#" onClick={HandleShow} className="link_color_datatable">
          <Fontawesome classe="fas fa-paperclip mr-2" />
        </Link>
      ) : (
        <div className="customerStep flip-in-hor-top">
          <div className="customerStep">
            <Row>
              <Col>
                <div className="formStep">
                  <label class="important">Nome do anexo:</label>
                  <input
                    type="text"
                    name="name"
                    onKeyUp={(e) => setNameAttach(e.target.value)}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="formStep">
                  <label class="important">Tipo de anexo:</label>
                  <select
                    name="type"
                    onChange={(e) => setTypeAttach(e.target.value)}
                  >
                    <option select disabled>
                      Selecione...
                    </option>
                    <option value="Indefinido">Indefinido</option>
                    <option value="Laudo">Laudo</option>
                    <option value="Instalação">Instalação</option>
                    <option value="Visita técnica">Visita técnica</option>
                  </select>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="formStep">
                  <label class="important">Anexo:</label>
                  <Dropzone
                    maxFiles="1"
                    accept="application/pdf, image/*"
                    onDrop={(acceptedFiles) => HandleUpload(acceptedFiles)}
                  >
                    {({
                      getRootProps,
                      getInputProps,
                      isDragActive,
                      isDragReject,
                    }) => (
                      <DropContainer
                        {...getRootProps()}
                        isDragActive={isDragActive}
                        isDragReject={isDragReject}
                      >
                        <input {...getInputProps()} />
                        {renderDragMessage(isDragActive, isDragReject)}
                      </DropContainer>
                    )}
                  </Dropzone>
                </div>
              </Col>
            </Row>
            {
              // --MUIDATATABLE Anexo de Intens de Equipamentos---------
            }
          </div>
          <MUIDataTable
            title={'Anexos do Equipamento'}
            data={attachmentsList.data?.models.data}
            columns={columns}
            options={optionsAttach}
          />
          <Row className="justify-content-center">
            <Col className="mt-5 d-flex justify-content-center">
              {!viewEquipments.equipments && (
                <BackButton type="button" onClick={back}>
                  Voltar
                </BackButton>
              )}
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};
export default Attachments;
