import styled from 'styled-components';
import { Modal } from 'react-bootstrap';

export const ModalContainer = styled(Modal)`
  & .modal-content {
    border-radius: 25px;
  }
  ,
  & .modal-header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-bottom: 0;
  }

  & .modal-title {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 33.8636px;
    letter-spacing: 0.88843px;
    text-align: center;
    // margin-left: 35%;
    line-height: 55px;
    margin-top: 10px;
  }

  & .modal-body {
    border-color: #ffffff;
  }
  & .close {
    position: absolute;
    z-index: 9999;
    margin-right: 10px;
    color: #000000;
    margin-left: 90%;
  }
`;
