import * as Yup from 'yup';

Yup.setLocale({
  mixed: {
    required: 'Campo obrigatório',
  },
  string: {
    email: 'Preencha um e-mail válido',
    min: 'Mínimo de ${min} caracteres',
    max: 'Máximo de ${max} caracteres',
  },
  number: {
    min: 'Deve ser maior ou igual a ${min}',
    max: 'Deve ser menor ou igual a ${max}',
  },
});

const Schema = Yup.object().shape({
  name: Yup.string().min(3).required(),
  id_equipment_category: Yup.number().required(),
  id_equipment_brand: Yup.number().required(),
  anvisa_registry: Yup.string().required(),
  measures: Yup.array().of(
    Yup.object().shape({
      id_measure: Yup.number()
        .typeError('Escolha uma grandeza')
        .required('Escolha uma grandeza'),
      max: Yup.number().required('Escolha um máximo'),
      min: Yup.number().required('Escolha um mínimo'),
      safety_margin: Yup.number().required('Escolha uma margem de segurança'),
      safety_margin_time: Yup.string().required('Digite um tempo offline'),
    }),
  ),
});
export default Schema;
