import React, { useContext, useState } from 'react';
import Fontawesome from '../../fontawesome/fontawesome';
import { Context } from '../../../services/context';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import MUIDataTable from 'mui-datatables';
import { columns } from './columns';
import CustomSearchRender from '../../customSearchRender/CustomSearchRender';
import Swal from 'sweetalert2';
import api from '../../../services/api';
import { Prefix } from '../../../services/prefix';
import { useFetch } from '../../hooks/useFetch';
import { BackButton, Paragraph } from '../employeeStyle';

const TelegramView = ({ idUser, idCustomer }) => {
  const { employeeView, setEmployeeView } = useContext(Context);

  const listTelegram = useFetch(
    Prefix.replace(/[\\"]/g, '') + '/sismogran/notification/telegram/updates',
    5000,
  );

  let reverseTelegram = listTelegram.data?.models.reverse();

  const handleShow = () => {
    setEmployeeView({
      ...employeeView,
      telegramView: true,
      employees: false,
      idUser: idUser,
      idCustomer: idCustomer,
    });
  };

  const handleClose = () => {
    setEmployeeView({
      ...employeeView,
      telegramView: false,
      employees: true,
      idUser: null,
      idCustomer: null,
    });
  };

  function HandleDelete(e) {
    let idArr = [];
    e.map((del) => {
      idArr.push(
        listTelegram.data?.models.filter(
          (filtro, index) => index == del.index,
        )[0].id,
      );
    });

    idArr.map((idDel) =>
      api
        .delete(
          Prefix.replace(/[\\"]/g, '') + '/contract-item-views/' + idDel,
          {
            id: idArr,
          },
        )
        .then((response) => {
          Swal.fire({
            icon: 'success',
            title: 'Uow!',
            text: response.data.message,
            confirmButtonText: 'Fechar',
          });
        })
        .catch((err) => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: err.response.data.message,
            confirmButtonText: 'Tentar novamente',
          });
        }),
    );
  }

  const options = {
    draggableColumns: {
      enabled: true,
    },
    serverSide: true,
    customSearchRender: (searchText, handleSearch, hideSearch, options) => {
      return (
        <CustomSearchRender
          placeholderSearch="Pesquisar mensagem"
          searchText={searchText}
          onSearch={handleSearch}
          onHide={hideSearch}
          options={options}
        />
      );
    },
    filter: false,
    page: 1,
    expandableRows: false,
    expandableRowsHeader: false,
    expandableRowsOnClick: false,
    rowsPerPage: 100,
    rowsPerPageOptions: [15, 30, 100],
    onRowsDelete: (rowsDeleted, newData) => {
      HandleDelete(rowsDeleted.data);
    },
    searchPlaceholder: 'Pesquise uma chat',
    selectableRows: false,
    searchOpen: false,
    textLabels: {
      body: {
        noMatch: 'Desculpe, não encontramos chats!',
        toolTip: 'Sort',
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
      pagination: {
        next: 'Avançar',
        previous: 'Voltar',
        rowsPerPage: 'Linhas por página:',
        displayRows: 'de',
      },
      toolbar: {
        search: 'Pesquisar',
        downloadCsv: 'Baixar CSV',
        print: 'Imprimir',
        viewColumns: 'Ver Colunas',
        filterTable: 'Filtrar tabela',
      },
      filter: {
        all: 'Tudo',
        title: 'FILTROS',
        reset: 'RESETAR',
      },
      viewColumns: {
        title: 'Exibir colunas',
        titleAria: 'Exibir/Ocultar colunas da tabela',
      },
      selectedRows: {
        text: 'Coluna(s) selecionada(s)',
        delete: 'Excluir',
        deleteAria: 'Excluir linhas selecionadas',
      },
    },
  };
  return (
    <>
      {!employeeView.telegramView ? (
        <Link className="dropdown-item" to="#" onClick={handleShow}>
          <Fontawesome classe="fab fa-telegram-plane mr-2" /> Notificações
          Telegram
        </Link>
      ) : (
        <>
          <Paragraph>
            Escolha uma mensagem que identifique o usuário pelo canal do
            Telegram...
          </Paragraph>
          <MUIDataTable
            data={reverseTelegram}
            columns={columns}
            options={options}
          />
          <Row>
            <Col className="d-flex justify-content-center mt-4">
              <BackButton type="button" onClick={handleClose}>
                Voltar
              </BackButton>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};
export default TelegramView;
