import styled from 'styled-components';

export const CardTracks = styled.div`
  width: 100%;
  min-height: 223px;
  background: #fff;
  border-radius: 20px;
  padding: 35px;
`;

export const ButtonRange = styled.button`
  width: 130px;
  min-height: 35px;
  background: #00ba88;
  border-radius: 20px;
  border: 0;
  color: #fff;

  &:hover {
    opacity: 0.5;
    transition: 0.5s;
  }
`;
