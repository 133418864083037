import { Alert } from "react-bootstrap";
import styled from "styled-components";


export const ButtonClose = styled.div`
color: #808080;
font-size: 14px;
font-weight: 700;
font-family: cursive;
padding: 15px;
cursor: pointer;
`;

export const ContainerAlert = styled(Alert)`
border-radius: 15px;
margin-top: 20px;
`;