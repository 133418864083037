import React from 'react';
import IndexEditPlan from '../../components/actionPlan/editPlan/indexEditPlan';
import 'moment/locale/pt-br';
import * as moment from 'moment';



export const columns = [
  {
    name: 'id',
    label: 'Id',
    options: {
      filter: false,
      sort: false,
      display: 'excluded',
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
    },
  },
  {
    name: 'started_at',
    label: 'Registro',
    options: {
      filter: false,
      sort: false,
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      customBodyRender: (value, tableMeta) => {
        return (
          <>
            {moment(value).format('LT')} <br />
            {moment(value).calendar('LLL')}
          </>
        )
      },
    },
  },
  {
    name: 'created_at',
    label: 'Abertura',
    options: {
      filter: false,
      sort: false,
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      customBodyRender: (value, tableMeta) => {
        return (
          <>
            {moment(value).format('LT')} <br />
            {moment(value).calendar('LLL')}
          </>
        )
      },

    },
  },
  {
    name: 'contract_item',
    label: 'Equipamento',
    options: {
      filter: false,
      sort: false,
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      customBodyRender: (value, tableMeta) => {
        return value?.nickname;
      },
    },
  },
  {
    name: 'description',
    label: 'Ocorrência',
    options: {
      filter: false,
      sort: false,
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'Justify',
        },
      }),
    },
  },
  {
    name: 'solution',
    label: 'Ação',
    options: {
      filter: false,
      sort: false,
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'justify',
        },
      }),
    },
  },
  {
    name: 'cause',
    label: 'Causa',
    options: {
      filter: false,
      sort: false,
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'justify',
        },
      }),
    },
  },
  {
    name: 'id',
    label: 'Editar',
    options: {
      filter: false,
      sort: false,
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
          width: '10%',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      customBodyRender: (value, tableMeta) => {
        return (
          <IndexEditPlan
            id={tableMeta.rowData[0]}
            page={tableMeta.tableState.page}
            perPage={tableMeta.tableState.rowsPerPage}
            orderBy={tableMeta.tableState.sortOrder.name}
            order={tableMeta.tableState.sortOrder.direction}
            search={tableMeta.tableState.searchText}
          />
        );
      },
    },
  },
];
