import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import Fontawesome from '../../fontawesome/fontawesome';
import { Context } from '../../../services/context';
import { useFetch } from '../../hooks/useFetch';
import { Row, Col } from 'react-bootstrap';
import { Prefix } from '../../../services/prefix';
import { TitleSensor, ContentSensor, BackButton } from './tracksStyle';
import FindRanges from './findRanges';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const Tracks = ({ idEquipment, idEquipmentModel, back, monitoring }) => {
  const { viewEquipments, setViewEquipments } = useContext(Context);

  const profilesList = useFetch(
    !viewEquipments.tracks
      ? null
      : Prefix.replace(/[\\"]/g, '') +
          '/profile-attributes?id_equipment_model=' +
          viewEquipments.idEquipmentModel,
  );

  const HandleShow = () => {
    setViewEquipments({
      ...viewEquipments,
      idEquipment: idEquipment,
      idEquipmentModel: idEquipmentModel,
      tracks: true,
      equipments: false,
    });
  };

  return (
    <>
      {!viewEquipments.tracks && monitoring == 1 ? (
        <Link to="#" onClick={HandleShow} className="link_color_datatable">
          <Fontawesome classe="fas fa-sliders-h" />
        </Link>
      ) : !viewEquipments.tracks && monitoring == 0 ? (
        <OverlayTrigger
          overlay={
            <Tooltip id="tooltip-disabled">
              Equipamento não é de monitoramento
            </Tooltip>
          }
        >
          <div className="link_color_datatable">
            <Fontawesome classe="fas fa-ban" />
          </div>
        </OverlayTrigger>
      ) : (
        <>
          <Row className="justify-content-center">
            <>
              {profilesList.data?.models?.data.map((sns) => (
                <Col md={6}>
                  <ContentSensor>
                    <TitleSensor>
                      <strong className="ml-3">
                        {sns.unit_of_measure.measure.name}
                      </strong>
                    </TitleSensor>

                    <FindRanges
                      idEquipmentModel={viewEquipments.idEquipmentModel}
                      idEquipment={viewEquipments.idEquipment}
                      idProfileAttribute={sns.id}
                    />
                  </ContentSensor>
                </Col>
              ))}
            </>
          </Row>
          <Row>
            <Col className="mt-5 d-flex justify-content-center">
              {!viewEquipments.equipments && (
                <BackButton type="button" onClick={back}>
                  Voltar
                </BackButton>
              )}
            </Col>
          </Row>
        </>
      )}
    </>
  );
};
export default Tracks;
