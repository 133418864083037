export const cities = [
	{ id: 1, uf: 'AC', city: 'Teste', zipcode: '60740-550'},
	{ id: 2, uf: 'AL', city: 'Teste', zipcode: '60740-550'},
	{ id: 3, uf: 'AP', city: 'Teste', zipcode: '60740-550'},
	{ id: 4, uf: 'AM', city: 'Teste', zipcode: '60740-550'},
	{ id: 5, uf: 'BA', city: 'Teste', zipcode: '60740-550'},
	{ id: 6, uf: 'CE', city: 'Teste', zipcode: '60740-550'},
	{ id: 7, uf: 'DF', city: 'Teste', zipcode: '60740-550'},
	{ id: 8, uf: 'ES', city: 'Teste', zipcode: '60740-550'},
	{ id: 9, uf: 'GO', city: 'Teste', zipcode: '60740-550'},
	{ id: 10, uf: 'MA', city: 'Teste', zipcode: '60740-550'},
	{ id: 11, uf: 'MT', city: 'Teste', zipcode: '60740-550'},
	{ id: 12, uf: 'MS', city: 'Teste', zipcode: '60740-550'},
	{ id: 13, uf: 'MG', city: 'Teste', zipcode: '60740-550'},
	{ id: 14, uf: 'PA', city: 'Teste', zipcode: '60740-550'},
	{ id: 15, uf: 'PB', city: 'Teste', zipcode: '60740-550'},
	{ id: 16, uf: 'PR', city: 'Teste', zipcode: '60740-550'},
	{ id: 17, uf: 'PE', city: 'Teste', zipcode: '60740-550'},
	{ id: 18, uf: 'PI', city: 'Teste', zipcode: '60740-550'},
	{ id: 19, uf: 'RJ', city: 'Teste', zipcode: '60740-550'},
	{ id: 20, uf: 'RN', city: 'Teste', zipcode: '60740-550'},
	{ id: 21, uf: 'RS', city: 'Teste', zipcode: '60740-550'},
	{ id: 22, uf: 'RO', city: 'Teste', zipcode: '60740-550'},
	{ id: 23, uf: 'RR', city: 'Teste', zipcode: '60740-550'},
	{ id: 24, uf: 'SC', city: 'Teste', zipcode: '60740-550'},
	{ id: 25, uf: 'SP', city: 'Teste', zipcode: '60740-550'},
	{ id: 26, uf: 'SE', city: 'Teste', zipcode: '60740-550'},
	{ id: 27, uf: 'TO', city: 'Teste', zipcode: '60740-550'}
]

export default {
    cities,
}