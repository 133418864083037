import React from 'react';
import { components } from 'react-select';

export const NoOptionsMessage = (props) => {
  return (
    <components.NoOptionsMessage {...props}>
      Nenhum item...
    </components.NoOptionsMessage>
  );
};
