import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import GoogleMap from '../../googleMap/googleMap';
import { Link } from 'react-router-dom';
import Fontawesome from '../../fontawesome/fontawesome';
import Geocode from 'react-geocode';
import { keyMap } from '../../googleMap/keyMaps';
import { Prefix } from '../../../services/prefix';
import { useFetch } from '../../hooks/useFetch';
import { ModalContainer } from "../../../style/stylesModals"; 
import { CustomButton, TextButton, ButtonDesabled} from "../../../style/stylesButton";

const ShowMap = ({ address, number, city, uf, id }) => {
  const [show, setShow] = useState(false);
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const unitList = useFetch(
    !show ? null : Prefix.replace(/[\\"]/g, '') + '/units/' + id,
  );
  const data = unitList.data?.model;

  const completeAddress = address + ', ' + number + ', ' + city + ', ' + uf;

  Geocode.setApiKey(keyMap); //Import API key from a file
  Geocode.setLanguage('pt-BR'); //Specify language of the parsed address. List of the available language codes. https://developers.google.com/maps/faq#languagesupport
  Geocode.setRegion('pt-BR');
  Geocode.setLocationType('ROOFTOP'); //ROOFTOP => indicates that the returned result is a precise geocode for which we have location information accurate down to street address precision. https://developers.google.com/maps/documentation/geocoding/overview#:~:text=%22ROOFTOP%22%20indicates%20that%20the%20returned,points%20(such%20as%20intersections)
  Geocode.enableDebug(true); //Enable or disable logs. Its optional.

  useEffect(() => {
    if (!data?.lat || !data?.lng) {
      Geocode.fromAddress(completeAddress).then(
        (response) => {
          const { lat, lng } = response.results[0].geometry.location;
          setLatitude(lat);
          setLongitude(lng);
        },
        (error) => {
          //console.error(error);
        },
      );
    } else {
      const lat = data?.lat;
      const lng = data?.lng;
      setLatitude(lat);
      setLongitude(lng);
    }
  }, [show]);

  return (
    <>
      {!show ? (
        <Link to="#" onClick={handleShow} className="link_color_datatable">
          <Fontawesome classe="fas fa-map-marker-alt" />
        </Link>
      ) : (
        <ModalContainer 
          show={show}
          size="lg"
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title><h3>Localização da Unidade</h3></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <GoogleMap latitude={latitude} longitude={longitude} />
          </Modal.Body>
        </ModalContainer >
      )}
    </>
  );
};
export default ShowMap;
