import React, { useState } from 'react';
import { Prefix } from '../../../services/prefix';
import Dropzone from 'react-dropzone';
import { uniqueId } from 'lodash';
import api from '../../../services/api';
import { DropContainer, UploadMessage } from '../uploadFile';
import Swal from 'sweetalert2';
import { Row, Col, Modal } from 'react-bootstrap';
import { mutate as mutateGlobal } from 'swr';
import UserDefault from './UserDefault.svg';
import Loading from '../../loader/loading';

const ChangeLogo = ({ id, logo, page, rowsPerPage, search }) => {
  const [show, setShow] = useState(false);
  const [uploaded, setUploaded] = useState([]);
  const [loader, setLoader] = useState(false);
  const pageExact = parseInt(page) + 1;

  logo = logo == null || logo == undefined ? UserDefault : logo;

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const HandleUpload = (files) => {
    setLoader(true);
    const uploadedFiles = files.map((file) => ({
      file,
      id: uniqueId(),
      name: file.name,
      readableSize: file.size,
      path: URL.createObjectURL(file),
      progress: 0,
      uploaded: false,
      error: false,
      url: null,
    }));

    setUploaded({
      ...uploaded,
      uploadedFiles,
    });

    uploadedFiles.forEach(processUpload);
  };

  const processUpload = (uploadedFile) => {
    const data = new FormData();

    data.append('logo', uploadedFile.file);
    data.append('_method', 'put');

    api
      .post(Prefix.replace(/[\\"]/g, '') + '/customers/' + id, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then((response) => {
        Swal.fire({
          icon: 'success',
          title: 'Uow!',
          text: response.data.message,
          confirmButtonText: 'Fechar',
        }).then(() => {
          setLoader(false);
        });
        setShow(false);
        mutateGlobal(
          Prefix.replace(/[\\"]/g, '') +
            '/customers?per_page=' +
            rowsPerPage +
            '&page=' +
            pageExact +
            '&search=' +
            search,
        );
      })
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: err.response.data.message,
          confirmButtonText: 'Tentar novamente',
        }).then(() => {
          setLoader(false);
        });
      });
  };

  function renderDragMessage(isDragActive, isDragReject) {
    if (!isDragActive) {
      return <UploadMessage>Arraste os arquivos aqui</UploadMessage>;
    }
    if (isDragReject) {
      return <UploadMessage type="error">Arquivo não suportado</UploadMessage>;
    }

    return <UploadMessage type="success">Solte os arquivos aqui</UploadMessage>;
  }

  return (
    <>
      <div
        className="customer-logo"
        style={{ background: 'url(' + logo + ')', margin: '0 auto' }}
        onClick={handleShow}
      >
        <label>MUDAR AVATAR</label>
      </div>

      <Modal
        show={show}
        size="md"
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Trocar logotipo do cliente</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loader && <Loading />}
          <div className="customerStep">
            <Row>
              <Col>
                <div className="formStep">
                  <label>Anexo:</label>
                  <Dropzone
                    maxFiles="1"
                    accept="application/pdf, image/*"
                    onDrop={(acceptedFiles) => HandleUpload(acceptedFiles)}
                  >
                    {({
                      getRootProps,
                      getInputProps,
                      isDragActive,
                      isDragReject,
                    }) => (
                      <DropContainer
                        {...getRootProps()}
                        isDragActive={isDragActive}
                        isDragReject={isDragReject}
                      >
                        <input {...getInputProps()} />
                        {renderDragMessage(isDragActive, isDragReject)}
                      </DropContainer>
                    )}
                  </Dropzone>
                </div>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default ChangeLogo;
