import React, { useState, useContext } from 'react';
import Fontawesome from '../../fontawesome/fontawesome';
import { Link } from 'react-router-dom';
import { Context } from '../../../services/context';
import { Row, Col } from 'react-bootstrap';
import { useFetch } from '../../hooks/useFetch';
import { Prefix } from '../../../services/prefix';
import MUIDataTable from 'mui-datatables';
import CustomFooter from '../../CustomFooter/CustomFooter';
import CustomSearchRender from '../../customSearchRender/CustomSearchRender';
import { columns } from './columns';
import api from '../../../services/api';
import Swal from 'sweetalert2';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Button } from './notificationsStyle';
import Select from 'react-select';
import { AsyncPaginate } from 'react-select-async-paginate';
import { loadOptions } from '../../selectAsync/loadOptions';
import { colourStyles } from '../../selectAsync/colourStyles';
import { NoOptionsMessage } from '../../selectAsync/NoOptionsMessage';
import Schema from './schema';
import { maskTel } from '../../../services/mask';

const Notifications = ({ idEquipment, idCustomer }) => {
  const { viewEquipments, setViewEquipments } = useContext(Context);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(15);
  const [search, setSearch] = useState('');
  const [orderBy, setOrderBy] = useState('id');
  const [order, setOrder] = useState('desc');
  const [registerData, setRegisterData] = useState({
    name: '',
    plataform: 'whatsapp',
    identification: '',
    monitoring: '',
    noc: 1,
    id_contract_item: viewEquipments.idEquipment,
    id_user: '',
    active: 1,
  });
  const [valueSelect, setValueSelect] = useState([]);
  const pageExact = parseInt(page) + 1;

  const notificationList = useFetch(
    !viewEquipments.notifications
      ? null
      : Prefix.replace(/[\\"]/g, '') +
          '/contract-notifications?per_page=' +
          perPage +
          '&page=' +
          pageExact +
          '&search=' +
          search +
          '&order_by=' +
          orderBy +
          '&order=' +
          order +
          '&id_contract_item=' +
          viewEquipments.idEquipment,
  );

  const optionMonitoring = [
    { value: '1', label: 'Sim, permitir monitoramento' },
    { value: '0', label: 'Não permitir monitoramento' },
  ];

  function HandleDelete(e) {
    let listAttachFilter = notificationList?.data?.models?.data;
    let idArr = [];
    e.map((del) => {
      idArr.push(
        listAttachFilter.filter((filtro, index) => index == del.index)[0].id,
      );
    });

    idArr.map((idDel) =>
      api
        .delete(
          Prefix.replace(/[\\"]/g, '') + '/contract-notifications/' + idDel,
        )
        .then((response) => {
          Swal.fire({
            icon: 'success',
            title: 'Uow!',
            text: response.data.message,
            confirmButtonText: 'Fechar',
          });
          notificationList.mutate();
        })
        .catch((err) => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: err.response.data.message,
            confirmButtonText: 'Tentar novamente',
          });
        }),
    );
  }

  const options = {
    filterType: 'checkbox',
    draggableColumns: {
      enabled: true,
    },
    filter: false,
    serverSide: true,
    sortOrder: {
      name: orderBy,
      direction: order,
    },
    onTableChange: (action, tableState) => {
      setOrderBy(
        !tableState.sortOrder.name ? orderBy : tableState.sortOrder.name,
      );
      setOrder(
        !tableState.sortOrder.direction
          ? order
          : tableState.sortOrder.direction,
      );
      setPage(tableState.page);
      setPerPage(tableState.rowsPerPage);
    },
    onSearchChange: (searchText) => {
      setSearch(!searchText ? '' : searchText);
    },
    customSearchRender: (searchText, handleSearch, hideSearch, options) => {
      return (
        <CustomSearchRender
          placeholderSearch="Pesquisar"
          searchText={searchText}
          onSearch={handleSearch}
          onHide={hideSearch}
          options={options}
        />
      );
    },
    filter: false,
    page: page,
    count: notificationList?.data?.models?.total,
    expandableRows: false,
    expandableRowsHeader: false,
    expandableRowsOnClick: false,
    rowsPerPage: perPage,
    rowsPerPageOptions: [15, 30, 100],
    onRowsDelete: (rowsDeleted, newData) => {
      HandleDelete(rowsDeleted.data);
    },
    searchPlaceholder: 'Pesquisar',
    selectableRows: 'multiple',
    searchOpen: true,
    textLabels: {
      body: {
        noMatch: 'Desculpe, não encontramos dados!',
        toolTip: 'Sort',
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
      pagination: {
        next: 'Avançar',
        previous: 'Voltar',
        rowsPerPage: 'Linhas por página:',
        displayRows: 'de',
      },

      toolbar: {
        search: 'Pesquisar',
        downloadCsv: 'Baixar CSV',
        print: 'Imprimir',
        viewColumns: 'Ver Colunas',
        filterTable: 'Filtrar tabela',
      },
      filter: {
        all: 'Tudo',
        title: 'FILTROS',
        reset: 'RESETAR',
      },
      viewColumns: {
        title: 'Exibir colunas',
        titleAria: 'Exibir/Ocultar colunas da tabela',
      },
      selectedRows: {
        text: 'Coluna(s) selecionada(s)',
        delete: 'Excluir',
        deleteAria: 'Excluir linhas selecionadas',
      },
    },
    customFooter: (
      count,
      page,
      rowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels,
    ) => {
      let numberPages = notificationList?.data?.models?.last_page;
      let rowsPerPageOptions = [15, 30, 100];
      return (
        <CustomFooter
          page={page}
          rowsPerPage={rowsPerPage}
          changeRowsPerPage={changeRowsPerPage}
          changePage={changePage}
          numberPages={numberPages}
          rowsPerPageOptions={rowsPerPageOptions}
        />
      );
    },
  };

  const HandleShow = () => {
    setViewEquipments({
      ...viewEquipments,
      idEquipment: idEquipment,
      idCustomer: idCustomer,
      notifications: true,
      equipments: false,
    });
  };

  function HandleChange(e) {
    setRegisterData({
      ...registerData,
      [e.target.name]: e.target.value,
    });
  }

  const HandleSelect = (e, name) => {
    if (valueSelect.filter((fill) => fill.nameField == name).length > 0) {
      valueSelect
        .filter((fill) => fill.nameField == name)
        .map((map) => {
          let valueIndice = valueSelect.indexOf(map);
          valueSelect.splice(valueIndice);
        });

      setValueSelect((prev) => {
        return [...prev, { value: e.value, label: e.label, nameField: name }];
      });
      setRegisterData({ ...registerData, [name]: e.value });
    } else {
      setValueSelect((prev) => {
        return [...prev, { value: e.value, label: e.label, nameField: name }];
      });
      setRegisterData({ ...registerData, [name]: e.value });
    }
  };

  const HandleRegister = () => {
    api
      .post(
        Prefix.replace(/[\\"]/g, '') + '/contract-notifications',
        registerData,
      )
      .then((response) => {
        Swal.fire({
          icon: 'success',
          title: 'Uow...',
          text: response.data.message,
          confirmButtonText: 'Fechar',
        });
        notificationList.mutate();
      })
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: err.response.data.message,
          confirmButtonText: 'Tentar novamente',
        });
      });
  };

  return (
    <>
      {!viewEquipments.notifications ? (
        <Link to="#" onClick={HandleShow} className="link_color_datatable">
          <Fontawesome classe="far fa-comment-dots mr-2" />
        </Link>
      ) : (
        <>
          <Row>
            <Col>
              <Formik
                components={{ NoOptionsMessage }}
                initialValues={registerData}
                validationSchema={Schema}
                onSubmit={HandleRegister}
                enableReinitialize
              >
                {({ values, errors }) => (
                  <Form className="formStep">
                    <Row>
                      <Col>
                        <label className="important">Funcionário</label>
                        <AsyncPaginate
                          value={valueSelect
                            .filter((fill) => fill.nameField == 'id_user')
                            .map((map) => {
                              return {
                                value: map.value,
                                label: map.label,
                              };
                            })}
                          loadOptions={loadOptions}
                          onChange={(e) => {
                            HandleSelect(e, 'id_user');
                          }}
                          placeholder="Selecione..."
                          cacheUniqs={[valueSelect]}
                          styles={colourStyles}
                          additional={{
                            page: 1,
                            endpoint: '/customer-employees',
                            params: `id_customer=${viewEquipments.idCustomer}`,
                          }}
                        />
                        <ErrorMessage
                          name="id_user"
                          component="span"
                          className="errorMessages"
                        />
                      </Col>
                      <Col>
                        <label className="important">Nome personalizado</label>
                        <Field
                          type="text"
                          name="name"
                          className="form-control"
                          placeholder="Digite um nome..."
                          onChange={HandleChange}
                        />
                        <ErrorMessage
                          name="name"
                          component="span"
                          className="errorMessages"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <label className="important">WhatsApp</label>
                        <Field
                          type="text"
                          name="identification"
                          className="form-control"
                          placeholder="(00) 9 0000-0000"
                          onKeyPress={maskTel}
                          onChange={HandleChange}
                        />
                        <ErrorMessage
                          name="identification"
                          component="span"
                          className="errorMessages"
                        />
                      </Col>
                      <Col>
                        <div className="formStep">
                          <label className="important">Monitorável:</label>
                          <Select
                            value={valueSelect
                              .filter((fill) => fill.nameField == 'monitoring')
                              .map((map) => {
                                return {
                                  value: map.value,
                                  label: map.label,
                                };
                              })}
                            styles={colourStyles}
                            onChange={(e) => HandleSelect(e, 'monitoring')}
                            placeholder="Selecione..."
                            options={optionMonitoring}
                          />

                          <ErrorMessage
                            name="monitoring"
                            component="span"
                            className="errorMessages"
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="mt-4 d-flex justify-content-center">
                        <Button type="submit">Cadastrar</Button>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
          <Row>
            <Col>
              <MUIDataTable
                data={notificationList?.data?.models?.data}
                columns={columns}
                options={options}
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};
export default Notifications;
