import React, { useState, useContext } from 'react';
import { Button } from 'react-bootstrap';
import Fontawesome from '../fontawesome/fontawesome';
import IndexAddUnity from './unitWz/addUnitWz';
import IndexAddSector from './sectorWz/addSectorWz';
import IndexAddRoom from './roomWz/addRoomWz';
import { Context } from '../../services/context';

const WizardUnits = (props) => {
  const { 
    showUnitsWz, 
    setShowUnitsWz,
    showRoomsWz, 
    setShowRoomsWz,
    showSectorsWz, 
    setShowSectorsWz
   } = useContext(Context);
 

  return (
    <>
      <Button
        variant="success"
        style={{ background: '#2f84ec', border: '1px solid #2f84ec' }}
        className="ml-2"
        onClick={() => setShowUnitsWz(true)}
      >
        <Fontawesome classe="fas fa-rocket mr-2" />
        Cadastro rápido
      </Button>
      {showUnitsWz == true ? (
        <IndexAddUnity 
        pageExact={props.pageExact}
        perPage={props.perPage}
        search={props.search}
        orderBy={props.orderBy}
        order={props.order}
        />
      ) : showSectorsWz == true? (
        <IndexAddSector
        pageExact={props.pageExact}
        perPage={props.perPage}
        search={props.search}
        orderBy={props.orderBy}
        order={props.order}
        />
      ) : showRoomsWz == true? (
        <IndexAddRoom 
        pageExact={props.pageExact}
        perPage={props.perPage}
        search={props.search}
        orderBy={props.orderBy}
        order={props.order}
        />
      ) : (
        ''
      )}
    </>
  );
};
export default WizardUnits;
