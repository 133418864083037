import React, { useContext } from 'react';
import { Context } from '../../../../services/context';
import { useFetch } from '../../../hooks/useFetch';
import { Prefix } from '../../../../services/prefix';
import { BoxVerify } from './verifyMessageStyle';

const VerifyMessage = ({ message }) => {
  const { employeeView, setEmployeeView } = useContext(Context);

  const infoUser = useFetch(
    Prefix.replace(/[\\"]/g, '') + '/users/' + employeeView.idUser,
  );

  const verify = message == infoUser.data?.model?.email;

  return (
    <BoxVerify bgColor={verify ? '#1bc5bd' : '#fff0'}>{message}</BoxVerify>
  );
};
export default VerifyMessage;
