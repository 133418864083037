import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Fontawesome from '../../fontawesome/fontawesome';
import { Link } from 'react-router-dom';
import '../contracts.css';
import MUIDataTable from 'mui-datatables';
import { columns } from './columnsAttachments';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useFetch } from '../../hooks/useFetch';
import { Prefix } from '../../../services/prefix';
import Dropzone from 'react-dropzone';
import { uniqueId } from 'lodash';
import api from '../../../services/api';
import { DropContainer, UploadMessage } from '../uploadFile';
import Swal from 'sweetalert2';
import Loading from '../../loader/loading';
import { mutate as mutateGlobal } from 'swr';
import CustomSearchRender from './CustomSearchRender';
import CustomFooter from '../../CustomFooter/CustomFooter';
import { ModalContainer } from "../../../style/stylesModals";

export default function ModalAttachments({
  iconClass,
  text,
  modalSize,
  idContract,
}) {
  const [show, setShow] = useState(false);
  const [uploaded, setUploaded] = useState([]);
  const [pagina, setPagina] = useState(0);
  const [perPage, setPerPage] = useState(15);
  const [search, setSearch] = useState('');
  const [nameAttach, setNameAttach] = useState();
  const [loader, setLoader] = useState(false);

  let pageExact = pagina + parseInt(1);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const HandleUpload = (files) => {
    if (!nameAttach) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Digite um nome antes de enviar o arquivo...',
        confirmButtonText: 'Continuar',
      });
    } else {
      const uploadedFiles = files.map((file) => ({
        file,
        id: uniqueId(),
        name: nameAttach,
        readableSize: file.size,
        path: URL.createObjectURL(file),
        progress: 0,
        uploaded: false,
        error: false,
        url: null,
        id_contract: idContract,
      }));

      setUploaded({
        ...uploaded,
        uploadedFiles,
      });

      uploadedFiles.forEach(processUpload);
    }
  };

  const processUpload = (uploadedFile) => {
    setLoader(<Loading />);
    const data = new FormData();

    data.append('path', uploadedFile.file);
    data.append('name', uploadedFile.name);
    data.append('id_contract', uploadedFile.id_contract);
    api
      .post(Prefix.replace(/[\\"]/g, '') + '/contract-attachments', data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then((response) => {
        Swal.fire({
          icon: 'success',
          title: 'Uow!',
          text: response.data.message,
          confirmButtonText: 'Fechar',
        }).then(() => {
          setLoader(false);
        });
        mutateGlobal(
          Prefix.replace(/[\\"]/g, '') +
          '/contract-attachments?per_page=' +
          perPage +
          '&page=' +
          pageExact +
          '&search=',
        );
      })
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: err.response.data.message,
          confirmButtonText: 'Tentar novamente',
        });
        setLoader(false);
      });
  };

  function HandleDelete(e) {
    setLoader(<Loading />);
    let idArr = [];

    e.map((del) => {
      idArr.push(
        listAttachments.data?.models?.data.filter(
          (filtro, index) => index == del.index,
        )[0].id,
      );
    });

    idArr.map((idDel) =>
      api
        .delete(Prefix.replace(/[\\"]/g, '') + '/contract-attachments/' + idDel)
        .then((response) => {
          Swal.fire({
            icon: 'success',
            title: 'Uow!',
            text: 'Removido com sucesso!',
            confirmButtonText: 'Fechar',
          }).then(() => {
            setLoader(false);
          });
          mutateGlobal(
            Prefix.replace(/[\\"]/g, '') +
            '/contract-attachments?&per_page=' +
            perPage +
            '&page=' +
            pageExact +
            '&search=' +
            search,
          );
          listAttachments.mutate();
        })
        .catch((err) => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: err.response.data.message,
            confirmButtonText: 'Tentar novamente',
          });
          setLoader();
        }),
    );
  }

  const listAttachments = useFetch(
    Prefix.replace(/[\\"]/g, '') +
    '/contract-attachments?per_page=' +
    perPage +
    '&page=' +
    pageExact +
    '&search=' +
    search,
  );

  const options = {
    draggableColumns: {
      enabled: true,
    },
    serverSide: true,
    onTableChange: (action, tableState) => {
      setPagina(tableState.page);
      setPerPage(tableState.rowsPerPage);
    },
    onSearchChange: (searchText) => {
      setSearch(!search ? '' : searchText);
    },
    customSearchRender: (searchText, handleSearch, hideSearch, options) => {
      return (
        <CustomSearchRender
          placeholderSearch="Pesquisar Anexo"
          searchText={searchText}
          onSearch={handleSearch}
          onHide={hideSearch}
          options={options}
        />
      );
    },
    filter: false,
    page: pagina,
    expandableRows: false,
    expandableRowsHeader: false,
    expandableRowsOnClick: false,
    rowsPerPage: perPage,
    rowsPerPageOptions: [15, 30, 100],

    onRowsDelete: (rowsDeleted, newData) => {
      HandleDelete(rowsDeleted.data);
    },
    searchPlaceholder: 'Pesquise um anexo',
    selectableRows: 'multiple',
    searchOpen: true,

    textLabels: {
      body: {
        noMatch: 'Desculpe, não encontramos anexos!',
        toolTip: 'Sort',
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
      pagination: {
        next: 'Avançar',
        previous: 'Voltar',
        rowsPerPage: 'Linhas por página:',
        displayRows: 'de',
      },
      toolbar: {
        search: 'Pesquisar',
        downloadCsv: 'Baixar CSV',
        print: 'Imprimir',
        viewColumns: 'Ver Colunas',
        filterTable: 'Filtrar tabela',
      },
      filter: {
        all: 'Tudo',
        title: 'FILTROS',
        reset: 'RESETAR',
      },
      viewColumns: {
        title: 'Exibir colunas',
        titleAria: 'Exibir/Ocultar colunas da tabela',
      },
      selectedRows: {
        text: 'Coluna(s) selecionada(s)',
        delete: 'Excluir',
        deleteAria: 'Excluir linhas selecionadas',
      },
    },
    customFooter: (
      count,
      page,
      rowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels,
    ) => {
      let numberPages = listAttachments.data?.models?.last_page;
      let rowsPerPageOptions = [15, 30, 100];
      return (
        <CustomFooter
          page={page}
          rowsPerPage={rowsPerPage}
          changeRowsPerPage={changeRowsPerPage}
          changePage={changePage}
          numberPages={numberPages}
          rowsPerPageOptions={rowsPerPageOptions}
        />
      );
    },
  };

  function renderDragMessage(isDragActive, isDragReject) {
    if (!isDragActive) {
      return (
        <UploadMessage>Arraste os arquivos aqui (Máximo: 5mb)</UploadMessage>
      );
    }
    if (isDragReject) {
      return <UploadMessage type="error">Arquivo não suportado</UploadMessage>;
    }

    return <UploadMessage type="success">Solte os arquivos aqui</UploadMessage>;
  }

  return (
    <>
      {!show ? (
        <Link className="dropdown-item" to="#" onClick={handleShow}>
          <Fontawesome classe={iconClass} /> {text}
        </Link>
      ) : (
        <ModalContainer
          show={show}
          size={modalSize}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title><h3>{text}</h3></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {<Loading /> && loader}
            <div className="customerStep">
              <Row>
                <Col>
                  <div className="formStep">
                    <label class="important">Nome do anexo:</label>
                    <input
                      type="text"
                      name="name"
                      onBlur={(e) => setNameAttach(e.target.value)}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="formStep">
                    <label class="important">Anexo:</label>
                    <Dropzone
                      maxFiles="1"
                      accept="application/pdf, image/*"
                      onDrop={(acceptedFiles) => HandleUpload(acceptedFiles)}
                    >
                      {({
                        getRootProps,
                        getInputProps,
                        isDragActive,
                        isDragReject,
                      }) => (
                        <DropContainer
                          {...getRootProps()}
                          isDragActive={isDragActive}
                          isDragReject={isDragReject}
                        >
                          <input {...getInputProps()} />
                          {renderDragMessage(isDragActive, isDragReject)}
                        </DropContainer>
                      )}
                    </Dropzone>
                  </div>
                </Col>
              </Row>
            </div>

            <MUIDataTable
              title={'Lista de Anexos do Contrato'}
              data={listAttachments.data?.models?.data.filter(
                (filtro) => filtro.id_contract == idContract,
              )}
              columns={columns}
              options={options}
            />
          </Modal.Body>
        </ModalContainer >
      )}
    </>
  );
}
