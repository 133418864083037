import * as Yup from "yup";

Yup.setLocale({
  mixed: {
    required: "Campo obrigatório",
  },
  string: {
    email: "Preencha um e-mail válido",
    min: "Mínimo de ${min} caracteres",
    max: "Máximo de ${max} caracteres",
  },
  number: {
    min: "Deve ser maior ou igual a ${min}",
    max: "Deve ser menor ou igual a ${max}",
  },
});

const Schema = Yup.object().shape({
  initialData: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required(),
      number: Yup.string().required(),
      id_customer: Yup.number().required(),
      id_contract_type: Yup.number().required(),
      start_date: Yup.string().required(),

    })
  ),
});
export default Schema;
