import React from "react";
import moment from "moment";
import DownloadAttachment from "./downloadAttachments";

export const columns = [
  {
    //0
    name: "id",
    label: "",
    options: {
      setCellHeaderProps: () => ({
        style: {
          "text-align": "center",
          width: "20%"
        },
      }),
      setCellProps: () => ({
        style: {
          "text-align": "center",
          width: "20%"
        },
      }),
      filter: false,
      sort: false,
      display: "excluded",
    },
  },
  {
    //1
    name: "created_at",
    label: "Data",
    options: {
      setCellHeaderProps: () => ({
        style: {
          "text-align": "center",
          "width": " 32%",
        },
      }),
      setCellProps: () => ({
        style: {
          "text-align": "center",
          "width": " 32%",
        },
      }),
      filter: false,
      sort: false,
      display: true,
      customBodyRender: (value, tableMeta) => {
        const dateValue = value.split('T')
        return moment(dateValue[0]).format('DD/MM/YYYY')
      }
    },
  },
  {
    //2
    name: "name",
    label: "Nome",
    options: {
      setCellHeaderProps: () => ({
        style: {
          "text-align": "center",
          "width": " 32%",
        },
      }),
      setCellProps: () => ({
        style: {
          "text-align": "center",
          "width": " 32%",
        },
      }),
      filter: false,
      sort: false,
      display: true,
    },
  },
  {
    //3
    name: "DownloadAttachment",
    label: "Download",
    options: {
      setCellHeaderProps: () => ({
        style: {
          "text-align": "center",
          "width": " 32%",
        },
      }),
      setCellProps: () => ({
        style: {
          "text-align": "center",
          "width": " 32%",
        },
      }),
      filter: false,
      sort: false,
      display: true,
      customBodyRender: (value, tableMeta) => {
        return <DownloadAttachment id={tableMeta.rowData[0]} />;
      },
    },
  },
];
