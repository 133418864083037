import React, { useState, useContext } from 'react';
import { Modal } from 'react-bootstrap';
import { Context } from '../../services/context';
import Equipments from './equipments/equipments';
import AddEquipments from './addEquipments/addEquipments';
import EditEquipments from './editEquipments/editEquipments';
import Attachments from './attachments/attachments';
import Notifications from './notifications/notifications';
import Tracks from './tracks/tracks';
import { Link } from 'react-router-dom';
import Fontawesome from '../fontawesome/fontawesome';
import { ModalContainer } from '../../style/stylesModals';

const ContractsEquipments = ({ idContract, idCustomer }) => {
  const [show, setShow] = useState(false);
  const { viewEquipments, setViewEquipments } = useContext(Context);

  const handleShow = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setViewEquipments({
      ...viewEquipments,
      idEquipment: null,
      idCustomer: null,
      idEquipmentModel: null,
      equipments: true,
      addEquipments: false,
      editEquipments: false,
      attachments: false,
      notifications: false,
      tracks: false,
      orderBy: '',
      order: '',
    });
  };

  const handleBack = () => {
    if (viewEquipments.addEquipments) {
      setViewEquipments({
        ...viewEquipments,
        addEquipments: false,
        equipments: true,
      });
    } else if (viewEquipments.editEquipments) {
      setViewEquipments({
        ...viewEquipments,
        editEquipments: false,
        equipments: true,
      });
    } else if (viewEquipments.attachments) {
      setViewEquipments({
        ...viewEquipments,
        attachments: false,
        equipments: true,
      });
    } else if (viewEquipments.notifications) {
      setViewEquipments({
        ...viewEquipments,
        notifications: false,
        equipments: true,
      });
    } else {
      setViewEquipments({ ...viewEquipments, tracks: false, equipments: true });
    }
  };

  return (
    <>
      {!show ? (
        <Link className="dropdown-item" to="#" onClick={handleShow}>
          <Fontawesome classe="fas fa-laptop mr-2" /> Equipamentos
        </Link>
      ) : (
        <ModalContainer show={show} onHide={handleClose} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>
              <h3>Equipamentos do Contrato</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {viewEquipments.equipments ? (
              <Equipments
                idContract={idContract}
                idCustomer={idCustomer}
                back={handleBack}
              />
            ) : viewEquipments.attachments ? (
              <Attachments back={handleBack} />
            ) : viewEquipments.addEquipments ? (
              <AddEquipments back={handleBack} />
            ) : viewEquipments.editEquipments ? (
              <EditEquipments back={handleBack} />
            ) : viewEquipments.notifications ? (
              <Notifications back={handleBack} />
            ) : viewEquipments.tracks ? (
              <Tracks back={handleBack} />
            ) : (
              ''
            )}
          </Modal.Body>
        </ModalContainer>
      )}
    </>
  );
};
export default ContractsEquipments;
