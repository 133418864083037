import styled from 'styled-components';

export const TitleSensor = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 20px;
`;

export const ContentSensor = styled.div`
  color: #545454;
  background-color: #f3f3f3;
  border-radius: 20px;
  padding: 25px;
`;

export const BackButton = styled.button`
  width: 20%;
  height: 40px;
  border-radius: 100px;
  background: #1bc5bd;
  color: #fff;
  border: 0;

  &:hover {
    opacity: 0.5;
    transition: 0.5s;
  }
`;
