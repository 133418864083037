import React from 'react';
import DownloadAttachment from './downloadAttachmentsOs';

export const ColumnsAttachmentsOs = [
  {
    name: 'id',
    label: 'id',
    options: {
      filter: false,
      sort: false,
      display: 'excluded',
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      customBodyRender: (value, tableMeta) => {
        return value;
      },
    },
  },
  {
    name: 'name',
    label: 'Nome do arquivo',
    options: {
      filter: false,
      sort: false,
      display: true,
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      customBodyRender: (value, tableMeta) => {
        return value;
      },
    },
  },
  {
    name: 'id',
    label: 'Anexo',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: true,
      sort: false,
      customBodyRender: (value, tableMeta) => {
        return <DownloadAttachment id={value} />;
      },
    },
  },
];
