import React, { useState, useContext } from 'react';
import Fontawesome from '../../fontawesome/fontawesome';
import { Row, Col, Alert } from 'react-bootstrap';
import { useFetch } from '../../hooks/useFetch';
import { Prefix } from '../../../services/prefix';
import { Link } from 'react-router-dom';
import api from '../../../services/api';
import Loading from '../../loader/loading';
import { mutate as GlobalMutate } from 'swr';
import { Context } from '../../../services/context';
import './notificationStyle.css';

const Notification = () => {
  const { monitoringNotifications, setMonitoringNotifications } =
    useContext(Context);

  const [loader, setLoader] = useState();
  const [notificacoes, setNotificacoes] = useState([]);
  const perPage = useState(8);
  const [formData, setFormData] = useState({
    readed: '1',
  });
  const listNotification = useFetch('/notifications?per_page=8');

  const HandleReaded = (e) => {
    setLoader(true);
    api
      .put('/notifications/' + e, {
        readed: '1',
      })
      .then((response) => {
        listNotification.mutate()
        GlobalMutate('/notifications');
        setMonitoringNotifications(monitoringNotifications + 1);
      })
  };

  return (
    <>
      <div className="headerNotification">
        <h4>
          Notificações{' '}
          {/*<span className="btn btn-text btn-success btn-sm font-weight-bold btn-font-md ml-2">23+</span>*/}
        </h4>
      </div>
      <div className="containerNotification">
        {listNotification.data &&
          listNotification.data?.models?.data.map((list, index) =>
            <div className="lineNotification">
              <a onClick={() => HandleReaded(list.id)} key={index}>
                <Row key={list.id}>
                  <Col md={2} xs={2}>
                    <span className="iconNotification2">
                      <Fontawesome classe="far fa-bell" />
                    </span>
                  </Col>
                  <Col md={10} xs={10}>
                    {listNotification.data?.models?.data[index]?.readed == false ?
                      <b marginLeft="2">{list.title} falsa</b>
                      :
                      <p marginLeft="2">{list.title} verdadeira</p>
                    }
                  </Col>
                </Row>
              </a>
            </div>
          )}

        {(listNotification.data === undefined ||
          listNotification.data === '') && (
            <div className="lineNotification">
              <Col>
                ''
                <p className="noNotifications">Nenhuma notificação</p>
              </Col>
              <Link
                className="btn btn-success dropdown-item"
                to="/gestao/notificacoes"
                style={{ textAlign: 'center' }}
              >
                Ver mais
              </Link>
            </div>
          )}

        {listNotification.data?.models.data.length > 0 ? (
          <>
            <br></br>
            <div>
              <Col>
                <Link
                  className="btn btn-success dropdown-item"
                  to="/gestao/notificacoes"
                  style={{ textAlign: 'center' }}
                >
                  Ver mais
                </Link>
              </Col>
            </div>
          </>
        ) : (
          <div>
            <Row>
              <Col>
                <p className="noNotifications">Nenhuma notificação</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <Link
                  className="btn btn-success dropdown-item"
                  to="/gestao/notificacoes"
                  style={{ textAlign: 'center' }}
                >
                  Ver mais
                </Link>
              </Col>
            </Row>
          </div>
        )}
      </div>
    </>
  );
};
export default Notification;
