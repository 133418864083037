import React, { useState } from 'react';
import { Col, Row, Alert, Modal, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { IconOption } from '../../optionsProposalsStyle';
import { mutate as GlobalMutate } from 'swr';
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';
import { Schema } from './schema';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { GetMuiTheme } from '../../../../components/muiTheme/muiTheme';
import api from '../../../../services/api';
import { Prefix } from '../../../../services/prefix';
import Loading from '../../../loader/loading';
import Swal from 'sweetalert2';
import { useFetch } from '../../../hooks/useFetch';
import {
  Caption,
  ButtonProposal,
  TextProposal,
  Input,
  SelectInput,
  InputCurrency,
} from './styles';
import { ModalContainer } from '../../../../style/stylesModals';
import MUIDataTable from 'mui-datatables';
import CustomSearchRender from '../../../customSearchRender/CustomSearchRender';
import CustomFooter from '../../../CustomFooter/CustomFooter';
import { columns } from './columns';
import { AsyncPaginate } from 'react-select-async-paginate';
import { NoOptionsMessage } from '../../../selectAsync/NoOptionsMessage';
import { loadOptions } from '../../../selectAsync/loadOptions';
import './style.css';
import { formatMoeda, formatMoeda2 } from '../../../../services/mask';

const Equipments = ({ id }) => {
  const [Loader, setLoader] = useState(false);
  const [pagina, setPagina] = useState(0);
  const [perPage, setPerPage] = useState(15);
  const [search, setSearch] = useState('');
  const [show, setShow] = useState(false);
  let pageExact = pagina + parseInt(1);
  const [valueSelect, setValueSelect] = useState([]);
  const [registerData, setRegisterData] = useState({
    initialData: [
      {
        id_proposal: '',
        id_equipment_model: '',
        service: '',
        value: '',
        quantity: '',
      },
    ],
  });

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      padding: 0,
      height: '50px',
      borderColor: '#e2e5ec',
      zindex: 7,
    }),
    input: (styles) => ({
      ...styles,
      paddingBottom: '0px',
      height: '34px',
      color: '#495057',
      zindex: 7,
    }),
  };

  const [staticData, setStatiData] = useState({
    data: [
      {
        id: 3,
        date: '09/11/2021',
        comentary:
          'Lorem Ipsum é simplesmente uma simulação de texto da indústria tipográfica',
        restrict: true,
      },
    ],
  });

  const equipmentList = useFetch(
    Prefix.replace(/[\\"]/g, '') +
    '/proposal-equipments?per_page=' +
    perPage +
    '&page=' +
    pageExact +
    '&search=' +
    search +
    '&id_proposal=' +
    id,
  );

  const handleShow = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  const HandleSelect = (e, name, key) => {
    if (
      valueSelect.filter((fill) => fill.key == key && fill.nameField == name)
        .length > 0
    ) {
      valueSelect
        .filter((fill) => fill.key == key && fill.nameField == name)
        .map((map) => {
          let position = valueSelect.indexOf(map);
          valueSelect.splice(position, 1);
        });
      setValueSelect(() => {
        return [
          ...valueSelect,
          { value: e.value, label: e.label, nameField: name, key: key },
        ];
      });
    } else {
      setValueSelect((prev) => {
        return [
          ...prev,
          { value: e.value, label: e.label, nameField: name, key: key },
        ];
      });
    }
  };

  const HandleRegister = (values) => {
    setLoader(true);
    values.initialData.map((m) =>
      api
        .post(Prefix.replace(/[\\"]/g, '') + '/proposal-equipments', {
          id_proposal: id,
          id_equipment_model: m.id_equipment_model,
          service: m.service,
          value: m.value,
          quantity: parseInt(m.quantity),
        })
        .then((response) => {
          Swal.fire({
            icon: 'success',
            title: 'Uow!',
            text: response.data.message,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Fechar',
            cancelButtonText: 'Novo Cadastro',
            allowOutsideClick: false,
          }).then((result) => {
            if (!result.isConfirmed) {
              setShow(true);
            }
          });
          setLoader(false);
          GlobalMutate(
            Prefix.replace(/[\\"]/g, '') +
            '/proposal-equipments?per_page=' +
            perPage +
            '&page=' +
            pageExact +
            '&search=' +
            search +
            '&id_proposal=' +
            id,
          );
          setValueSelect([]);
          setRegisterData({
            initialData: [
              {
                id_proposal: '',
                id_equipment_model: '',
                service: '',
                value: '',
                quantity: '',
              },
            ],
          });
        })
        .catch((err) => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: err.response.data.message,
            confirmButtonText: 'Tentar novamente',
          });
        }),
    );
    setLoader(false);
  };

  function HandleDelete(e) {
    setLoader(true);
    let idArr = [];
    e.map((del) => {
      idArr.push(
        equipmentList.data?.models.data?.filter(
          (filtro, index) => index == del.index,
        )[0].id,
      );
    });

    idArr.map((idDel) =>
      api
        .delete(
          Prefix.replace(/[\\"]/g, '') + '/proposal-equipments/' + idDel,
          {
            id: idArr,
          },
        )
        .then((response) => {
          Swal.fire({
            icon: 'success',
            title: 'Uow!',
            text: response.data.message,
            confirmButtonText: 'Fechar',
          });
          setLoader(false);
          GlobalMutate(
            Prefix.replace(/[\\"]/g, '') +
            '/proposal-equipments?per_page=' +
            perPage +
            '&page=' +
            pageExact +
            '&search=' +
            search +
            '&id_proposal=' +
            id,
          );
        })
        .catch((err) => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: err.response.data.message,
            confirmButtonText: 'Tentar novamente',
          });
          setLoader(false);
        }),
    );
  }

  const options = {
    draggableColumns: {
      enabled: true,
    },
    serverSide: true,
    onTableChange: (action, tableState) => {
      setPagina(tableState.page);
      setPerPage(tableState.rowsPerPage);
    },
    onSearchChange: (searchText) => {
      setSearch(!search ? '' : searchText);
    },
    customSearchRender: (searchText, handleSearch, hideSearch, options) => {
      return (
        <CustomSearchRender
          placeholderSearch="Pesquisar Comentário"
          searchText={searchText}
          onSearch={handleSearch}
          onHide={hideSearch}
          options={options}
        />
      );
    },
    filter: false,
    page: pagina,
    count: equipmentList.data?.models.data.total,
    expandableRows: false,
    expandableRowsHeader: false,
    expandableRowsOnClick: false,
    rowsPerPage: perPage,
    rowsPerPageOptions: [15, 30, 100],
    onRowsDelete: (rowsDeleted, newData) => {
      HandleDelete(rowsDeleted.data);
    },
    searchPlaceholder: 'Pesquise uma comentário',
    selectableRows: true,
    searchOpen: false,

    textLabels: {
      body: {
        noMatch: 'Desculpe, não encontramos comentário!',
        toolTip: 'Sort',
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
      pagination: {
        next: 'Avançar',
        previous: 'Voltar',
        rowsPerPage: 'Linhas por página:',
        displayRows: 'de',
      },
      toolbar: {
        search: 'Pesquisar',
        downloadCsv: 'Baixar CSV',
        print: 'Imprimir',
        viewColumns: 'Ver Colunas',
        filterTable: 'Filtrar tabela',
      },
      filter: {
        all: 'Tudo',
        title: 'FILTROS',
        reset: 'RESETAR',
      },
      viewColumns: {
        title: 'Exibir colunas',
        titleAria: 'Exibir/Ocultar colunas da tabela',
      },
      selectedRows: {
        text: 'Coluna(s) selecionada(s)',
        delete: 'Excluir',
        deleteAria: 'Excluir linhas selecionadas',
      },
    },
    customFooter: (
      count,
      page,
      rowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels,
    ) => {
      let numberPages = equipmentList.data?.models.data.last_page;
      let rowsPerPageOptions = [15, 30, 100];
      return (
        <CustomFooter
          page={page}
          rowsPerPage={rowsPerPage}
          changeRowsPerPage={changeRowsPerPage}
          changePage={changePage}
          numberPages={numberPages}
          rowsPerPageOptions={rowsPerPageOptions}
        />
      );
    },
  };

  return (
    <>
      {!show ? (
        <Link className="dropdown-item" to="#" onClick={handleShow}>
          <IconOption src="../../../media/icons/equipments.svg" />
          Equipamentos
        </Link>
      ) : (
        <ModalContainer show={show} onHide={handleClose} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Equipamentos</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Caption>
              Preencha os equipamentos que farão parte da proposta...
            </Caption>
            <Formik
              initialValues={registerData}
              validationSchema={Schema}
              onSubmit={HandleRegister}
              enableReinitialize
            >
              {({ values, handleChange, setFieldValue }) => (
                <Form>
                  <Alert>
                    <FieldArray name="initialData">
                      {({ push, remove }) => (
                        <div>
                          {values?.initialData?.map((p, index) => {
                            return (
                              <>
                                <Row className="d-flex justify-content-center">
                                  <Col sm={4}>
                                    <div className="formStep">
                                      <AsyncPaginate
                                        value={valueSelect
                                          .filter(
                                            (fill) =>
                                              fill.nameField ==
                                              'id_equipment_category',
                                          )
                                          .map((map) => {
                                            return {
                                              value: map.value,
                                              label: map.label,
                                            };
                                          })}
                                        loadOptions={loadOptions}
                                        onChange={(e) =>
                                          HandleSelect(
                                            e,
                                            'id_equipment_category',
                                          )
                                        } //values and name of select paginate
                                        placeholder="Equipamento"
                                        classNamePrefix="filterProposal"
                                        cacheUniqs={[valueSelect]} //Set your param that is going to change when you select another field. Just use this option when you are setting params and need to refresh cache.
                                        styles={colourStyles}
                                        additional={{
                                          page: 1,
                                          endpoint: '/equipment-categories',
                                          // params: `uf=${values?.uf}`, //Separete with & (and commercial)
                                        }}
                                      />
                                    </div>
                                  </Col>

                                  <Col sm={4}>
                                    <div className="formStep">
                                      <AsyncPaginate
                                        value={valueSelect
                                          .filter(
                                            (fill) =>
                                              fill.nameField ==
                                              'id_equipment_model',
                                          )
                                          .map((map) => {
                                            return {
                                              value: map.value,
                                              label: map.label,
                                            };
                                          })}
                                        loadOptions={loadOptions}
                                        onChange={(e) => {
                                          HandleSelect(e, 'id_equipment_model');
                                          setFieldValue(
                                            `initialData[${index}].id_equipment_model`,
                                            e.value,
                                          );
                                        }} //values and name of select paginate
                                        placeholder="Modelo"
                                        components={{ NoOptionsMessage }}
                                        classNamePrefix="filterProposal"
                                        cacheUniqs={[valueSelect]} //Set your param that is going to change when you select another field. Just use this option when you are setting params and need to refresh cache.
                                        styles={colourStyles}
                                        additional={{
                                          page: 1,
                                          endpoint: '/equipment-models',
                                          params: `id_equipment_category=${valueSelect &&
                                            valueSelect
                                              .filter(
                                                (fill) =>
                                                  fill.nameField ==
                                                  'id_equipment_category',
                                              )
                                              .map((map) => map.value)
                                            }`, //Separete with & (and commercial)
                                        }}
                                      />

                                      <ErrorMessage
                                        name={`initialData[${index}].id_equipment_model`}
                                        component="span"
                                        className="errorMessages"
                                      />
                                    </div>
                                  </Col>
                                  <Col sm={4}>
                                    <div>
                                      <SelectInput
                                        name={`initialData[${index}].service`}
                                        onChange={handleChange}
                                      >
                                        <option selected disabled>
                                          Serviço
                                        </option>
                                        <option value="Calibra">
                                          {' '}
                                          Calibração{' '}
                                        </option>
                                        <option value="Preventiva">
                                          {' '}
                                          Preventiva{' '}
                                        </option>
                                        <option value="Corretiva">
                                          {' '}
                                          Corretiva{' '}
                                        </option>
                                      </SelectInput>
                                      <ErrorMessage
                                        name={`initialData[${index}].service`}
                                        component="span"
                                        className="errorMessages"
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col sm={4}>
                                    <div>
                                      <InputCurrency
                                        type="text"
                                        placeholder="Valor"
                                        name={`initialData[${index}].value`}
                                        onChange={handleChange}
                                      />
                                      <ErrorMessage
                                        name={`initialData[${index}].value`}
                                        component="span"
                                        className="errorMessages"
                                      />
                                    </div>
                                  </Col>
                                  <Col sm={4}>
                                    <div>
                                      <Input
                                        type="number"
                                        placeholder="Quantidade"
                                        name={`initialData[${index}].quantity`}
                                        onChange={handleChange}
                                      />
                                      <ErrorMessage
                                        name={`initialData[${index}].quantity`}
                                        component="span"
                                        className="errorMessages"
                                      />
                                    </div>
                                  </Col>
                                  <Col sm={4}>
                                    <div>
                                      <Input
                                        type="text"
                                        placeholder="Total"
                                        disabled=""
                                        value={
                                          !values?.initialData[0]?.quantity
                                            ? null
                                            : formatMoeda(
                                              values.initialData[0].value.replace(
                                                /[^0-9]/g,
                                                '',
                                              ) *
                                              values.initialData[0]
                                                .quantity,
                                            )
                                        }
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </>
                            );
                          })}
                          <div>
                            <Row>
                              <Col className="d-flex justify-content-center ">
                                <ButtonProposal type="submit">
                                  <TextProposal> Adicionar </TextProposal>
                                </ButtonProposal>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      )}
                    </FieldArray>
                  </Alert>
                </Form>
              )}
            </Formik>

            <div style={{ position: 'relative', zIndex: '0' }}>
              <MUIDataTable
                data={equipmentList.data?.models?.data}
                columns={columns}
                options={options}
              />
            </div>
          </Modal.Body>
        </ModalContainer>
      )}
    </>
  );
};
export default Equipments;
