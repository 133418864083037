import React, { useState, useEffect } from "react";
import { Row, Col, Button, Modal } from "react-bootstrap";
import "../unitsOfMeasures.css";
import { uf } from "../../data/uf";
import api from "../../../services/api";
import Fontawesome from "../../fontawesome/fontawesome";
import { Prefix } from "../../../services/prefix";
import Loading from "../../loader/loading";
import Swal from "sweetalert2";
import { useFetch } from "../../hooks/useFetch";
import { mutate as mutateGlobal } from "swr";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Schema from "./schema";
import axios from "axios";
import Select, { components } from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import { loadOptions } from "../../selectAsync/loadOptions";
import { colourStyles } from "../../selectAsync/colourStyles";
import { NoOptionsMessage } from "../../selectAsync/NoOptionsMessage";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import { useStyles } from "../../buttons/AddButtonStyle";
import { ModalContainer } from "../../../style/stylesModals";
import { CustomButton, TextButton } from "../../../style/stylesButton";

const IndexAddUnitsOfMeasures = ({
  iconClass,
  titleLabel,
  buttonLabel,
  modalSize,
  pageExact,
  perPage,
  search,
  orderBy,
  order,
}) => {
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const [initialState, setInitialState] = useState({
    name: "",
    active: 1,
    id_measure: "",
  });
  const [registerData, setRegisterData] = useState(initialState);
  const [valueSelect, setValueSelect] = useState([]);

  const handleClose = () => {
    setShow(false);
    setRegisterData(initialState);
  };
  const handleShow = () => {
    setShow(true);
  };

  const listMeasures = useFetch(Prefix.replace(/[\\"]/g, "") + "/measures");

  function HandleRegister(values) {
    setLoader(true);
    api
      .post(Prefix.replace(/[\\"]/g, "") + "/unit-of-measures", values)
      .then(() => {
        Swal.fire({
          icon: "success",
          title: "Uow!",
          text: "Cadastro realizado com sucesso!",
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: "Fechar",
          cancelButtonText: "Novo Cadastro",
          allowOutsideClick: false,
        }).then((result) => {
          if (!result.isConfirmed) {
            setShow(true);
          }
          setRegisterData({
            name: "",
            active: 1,
            id_measure: "",
          });
          setValueSelect([]);
        });
        setLoader(false);
        setRegisterData(initialState);
        setShow(false);
        mutateGlobal(
          Prefix.replace(/[\\"]/g, "") +
            "/unit-of-measures?per_page=" +
            perPage +
            "&page=" +
            pageExact +
            "&search=" +
            search +
            "&order_by=" +
            orderBy +
            "&order=" +
            order
        );
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: err.response.data.message,
          confirmButtonText: "Tentar novamente",
        });
        setLoader(false);
      });
  }

  const HandleChange = (e) => {
    if (e.target.name == "name") {
      setRegisterData({
        ...registerData,
        [e.target.name]: e.target.value.replace("%", ""),
      });
    } else {
      setRegisterData({
        ...registerData,
        [e.target.name]: e.target.value.trim(),
      });
    }
  };

  const HandleSelect = (e, name) => {
    if (valueSelect.filter((fill) => fill.nameField == name).length > 0) {
      valueSelect
        .filter((fill) => fill.nameField == name)
        .map((map) => {
          let valueIndice = valueSelect.indexOf(map);
          valueSelect.splice(valueIndice);
        });

      setValueSelect((prev) => {
        return [...prev, { value: e.value, label: e.label, nameField: name }];
      });
      setRegisterData({ ...registerData, [name]: e.value });
    } else {
      setValueSelect((prev) => {
        return [...prev, { value: e.value, label: e.label, nameField: name }];
      });
      setRegisterData({ ...registerData, [name]: e.value });
    }
  };

  const classes = useStyles();

  return (
    <>
      {show === false && (
        <Fab className={classes.fab} onClick={handleShow}>
          <AddIcon className={classes.icon} />
        </Fab>
      )}
      <ModalContainer
        show={show}
        size={modalSize}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h3>
              Cadastro <br /> Unidades de medida
            </h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loader && <Loading />}
          <Formik
            initialValues={registerData}
            validationSchema={Schema}
            onSubmit={HandleRegister}
            enableReinitialize
          >
            {({ values }) => (
              <Form>
                <Row>
                  <Col>
                    <div className="formStep">
                      <label className="important">Grandeza</label>
                      <AsyncPaginate
                        value={valueSelect
                          .filter((fill) => fill.nameField == "id_measure")
                          .map((map) => {
                            return {
                              value: map.value,
                              label: map.label,
                            };
                          })}
                        loadOptions={loadOptions}
                        onChange={(e) => HandleSelect(e, "id_measure")} //values and name of select paginate
                        placeholder="Selecione..."
                        styles={colourStyles}
                        additional={{
                          page: 1,
                          endpoint: "/measures",
                          //params: `uf=${values.uf}`, //Separete with & (and commercial)
                        }}
                      />
                      <ErrorMessage
                        name="id_measure"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="formStep">
                      <label className="important">Unidade de Medida</label>
                      <Field
                        type="text"
                        placeholder="Digite um nome"
                        name="name"
                        onBlur={HandleChange}
                      />
                      <ErrorMessage
                        name="name"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className="d-flex justify-content-center">
                    <CustomButton type="submit">
                      <TextButton> Adicionar </TextButton>
                    </CustomButton>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </ModalContainer>
    </>
  );
};
export default IndexAddUnitsOfMeasures;
