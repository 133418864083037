import api from '../../../services/api';
import { Prefix } from '../../../services/prefix';

export async function loadOptionsStates(
  search,
  loadedOptions,
  { page, endpoint, params },
) {
  const response = await api.get(
    Prefix.replace(/[\\"]/g, '') +
    endpoint +
    '?search=' +
    search +
    '&page=' +
    page +
    '&' +
    params,
  );
  const data = response.data.models.data.map((map) => {
    return { value: map.uf, label: map.uf };
  });
  const total = response.data.models.total;

  return {
    options: data.map(e => JSON.stringify(e))
      .reduce((acc, cur) => (acc.includes(cur) || acc.push(cur), acc), [])
      .map(e => JSON.parse(e)),
    hasMore: loadedOptions.length < total,
    additional: {
      page: page + 1,
      endpoint: endpoint,
      params: params,
    },
  };
}
