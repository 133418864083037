import 'leaflet/dist/leaflet.css';
import React, { useState, useEffect, useContext } from 'react';
import { Map, Marker, Popup, TileLayer } from 'react-leaflet';
import Leaflet from 'leaflet';
import { v4 as uuidv4 } from 'uuid';
import { loadOptions } from '../../../components/selectAsync/loadOptions';
import { AsyncPaginate } from 'react-select-async-paginate';
import { Modal, Row, Col, Table } from 'react-bootstrap';
import Image from '../../../components/mapBox/images/images';
import Loading from '../../../components/loader/loading';
import moment from 'moment';
import Device from '../../../components/mapBox/device/device';
import ListDevices from '../../../components/mapBox/listDevices/listDevices';
import Sidebar from '../../../components/sidebar/sidebar';
import Topbar from '../../../components/topbar/topbar';

import greenIcon from '../../components/mapBox/lights/green-light.svg';
import darkIcon from '../../components/mapBox/lights/black-light.svg';
import redIcon from '../../components/mapBox/lights/red-light.svg';
import yellowIcon from '../../components/mapBox/lights/yellow-light.svg';
import purpleIcon from '../../components/mapBox/lights/purple-light.svg';
import axios from 'axios';

import './map.css';

import api from '../../../services/api';
import { Prefix } from '../../../services/prefix';
import { useFetch } from '../../../components/hooks/useFetch';
import { Button } from 'react-bootstrap';
import { Context } from '../../../services/context';
import { fetchLocalMapBox } from '../../components/mapBox/fetchLocalMapBox/fetchLocalMapBox';
import AsyncSelect from 'react-select/async';
import { NoOptionsMessage } from '../../../components/selectAsync/NoOptionsMessage';
import Monitoring from '../../components/monitoring/monitoring';
import DetailsMonitoring from '../../components/detailsMonitoring/detailsMonitoring';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Notification } from './mapStyle';
import NotificationView from '../../components/notificationView/notificationView';

const App = () => {
  const { isActiveToggle, viewSismogran, setViewSismogran } =
    useContext(Context);
  const [valueSelect, setValueSelect] = useState([]);
  const [registerData, setRegisterData] = useState([]);
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const [page, setPage] = useState(1);
  const [detail, setDetail] = useState(null);
  const [perPage, setPerPage] = useState(5);
  const [listEquip, setListEquip] = useState({
    equips: [],
    to: 0,
  });
  const [search, setSearch] = useState({
    id_unit: '',
    name_unit: '',
  });
  const { REACT_APP_ACCESS_TOKEN_MAP_BOX } = process.env;
  const group = Prefix.replace(/[\\"]/g, '');
  const [position, setPosition] = useState({
    lng: -51.6197890205486,
    lat: -9.58890301712257,
  });
  const [zoom, setZoom] = useState(5);
  const [address, setAddress] = useState(null);
  const [view, setView] = useState(false);
  const [full, setFull] = useState(true);

  const handleClose = () => {
    setShow(false);
    setListEquip({
      equips: [],
      to: 0,
    });
    setSearch({ ...search, id_unit: '' });
    setValueSelect([]);
    setViewSismogran(null);
  };
  const handleShow = (e, name) => {
    setSearch({ ...search, id_unit: e, name_unit: name });
    setShow(true);
  };

  const loadMore = () => {
    setPage(parseInt(page) + 1);
  };

  const HandleDetail = (e) => {
    setDetail(e);
  };

  const loadOptionsAddress = async (inputValue, callback) => {
    if (inputValue.length < 3) return;
    let places = [];
    const response = await fetchLocalMapBox(inputValue);
    response.features.map((item) => {
      places.push({
        label: item.place_name,
        value: item.place_name,
        coords: item.center,
        place: item.place_name,
        place_type: item.place_type[0],
      });
    });

    callback(places);
  };

  const handleChangeSelect = (event) => {
    setPosition({
      lng: event.coords[0],
      lat: event.coords[1],
    });
    setZoom(
      event.place_type == 'country' ? 5 : event.place_type == 'place' ? 13 : 16,
    );
    setAddress({ label: event.place, value: event.place });
  };

  // const initialPosition = { lat: -12.74043, lng: -60.11515 };

  const idClient = !valueSelect
    .filter((fill) => fill.nameField == 'id_customer')
    .map((map) => map.value)
    ? ''
    : valueSelect
      .filter((fill) => fill.nameField == 'id_customer')
      .map((map) => map.value);

  const idSector = !valueSelect
    .filter((fill) => fill.nameField == 'id_sector')
    .map((map) => map.value)
    ? ''
    : valueSelect
      .filter((fill) => fill.nameField == 'id_sector')
      .map((map) => map.value);

  const idRoom = !valueSelect
    .filter((fill) => fill.nameField == 'id_room')
    .map((map) => map.value)
    ? ''
    : valueSelect
      .filter((fill) => fill.nameField == 'id_room')
      .map((map) => map.value);

  const units = useFetch(
    !registerData.id_customer
      ? Prefix.replace(/[\\"]/g, '') + '/sismogran/map/units'
      : Prefix.replace(/[\\"]/g, '') +
      '/sismogran/map/units?id_customer=' +
      registerData.id_customer,
  );

  const findEquip = useFetch(
    detail && Prefix.replace(/[\\"]/g, '') + '/contract-items/' + detail,
  );
  const infoEquip = findEquip.data?.model;

  useEffect(() => {
    setLoader(true);
    if (page > 1) {
      api
        .get(
          Prefix.replace(/[\\"]/g, '') +
          '/contract-items?id_unit=' +
          search.id_unit +
          '&id_sector=' +
          idSector +
          '&id_room=' +
          idRoom +
          '&per_page=' +
          perPage +
          '&page=' +
          page,
        )
        .then((response) => {
          setListEquip((prevState) => ({
            equips: [...prevState.equips, ...response.data?.models.data],
            to: response.data?.models.to,
          }));
          setLoader(false);
        });
    } else {
      api
        .get(
          Prefix.replace(/[\\"]/g, '') +
          '/contract-items?id_unit=' +
          search.id_unit +
          '&id_sector=' +
          idSector +
          '&id_room=' +
          idRoom +
          '&per_page=' +
          perPage +
          '&page=' +
          page,
        )
        .then((response) => {
          setListEquip({
            equips: response.data.models.data,
            to: response.data?.models.to,
          });
          setLoader(false);
        });
    }
  }, [show, page, valueSelect]);

  const mapGreenIcon = Leaflet.icon({
    iconUrl: greenIcon,
    iconSize: [20, 20],
    iconAnchor: [10],
    popupAnchor: [170, 2],
  });

  const mapDarkIcon = Leaflet.icon({
    iconUrl: darkIcon,
    iconSize: [20, 20],
    iconAnchor: [20],
    popupAnchor: [170, 2],
  });

  const mapRedIcon = Leaflet.icon({
    iconUrl: redIcon,
    iconSize: [20, 20],
    iconAnchor: [30],
    popupAnchor: [170, 2],
  });

  const mapYellowIcon = Leaflet.icon({
    iconUrl: yellowIcon,
    iconSize: [20, 20],
    iconAnchor: [40],
    popupAnchor: [170, 2],
  });

  const mapPurpleIcon = Leaflet.icon({
    iconUrl: purpleIcon,
    iconSize: [20, 20],
    iconAnchor: [50],
    popupAnchor: [170, 2],
  });

  const HandleSelect = (e, name) => {
    if (valueSelect.filter((fill) => fill.nameField == name).length > 0) {
      valueSelect
        .filter((fill) => fill.nameField == name)
        .map((map) => {
          let valueIndice = valueSelect.indexOf(map);
          valueSelect.splice(valueIndice);
        });

      setValueSelect((prev) => {
        return [...prev, { value: e.value, label: e.label, nameField: name }];
      });
      setRegisterData({ ...registerData, [name]: e.value });
    } else {
      setValueSelect((prev) => {
        return [...prev, { value: e.value, label: e.label, nameField: name }];
      });
      setRegisterData({ ...registerData, [name]: e.value });
    }
  };

  const handleBack = () => {
    if (!view) {
      setViewSismogran(null);
    } else {
      setView(false);
    }
  };

  return (
    <>
      <div className="wrapper d-flex align-items-stretch">
        <Sidebar />
        <div id="content">
          <Topbar />
          <div className="content_all">
            {!units.data ? (
              <Loading />
            ) : (
              <Row>
                <Col sm={12} className="card-mt-4">
                  <div
                    id="page-map"
                    key={isActiveToggle == true ? uuidv4() : ''}
                  >
                    <main>
                      <form className="landing-page-form">
                        <fieldset>
                          <legend>Unidades</legend>

                          <div className="input-block">
                            {group != 'customer' && (
                              <AsyncPaginate
                                value={valueSelect
                                  .filter(
                                    (fill) => fill.nameField == 'id_customer',
                                  )
                                  .map((map) => {
                                    return {
                                      value: map.value,
                                      label: map.label,
                                    };
                                  })}
                                loadOptions={loadOptions}
                                classNamePrefix="filter"
                                onChange={(e) => HandleSelect(e, 'id_customer')}
                                placeholder="Selecione um cliente..."
                                additional={{
                                  page: 1,
                                  endpoint: '/customers',
                                  params: 'active=1',
                                }}
                              />
                            )}

                            <AsyncSelect
                              placeholder="Digite seu endereço..."
                              classNamePrefix="filter"
                              cacheOptions
                              components={{ NoOptionsMessage }}
                              loadOptions={loadOptionsAddress}
                              onChange={handleChangeSelect}
                              value={address}
                            />
                          </div>
                        </fieldset>
                      </form>
                    </main>

                    <Map
                      center={position}
                      zoom={zoom}
                      style={{ width: '100%', height: '100%' }}
                    >
                      {/* <TileLayer url="https://a.tile.openstreetmap.org/{z}/{x}/{y}.png" /> */}
                      <TileLayer
                        url={`https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/256/{z}/{x}/{y}@2x?access_token=${REACT_APP_ACCESS_TOKEN_MAP_BOX}`}
                      />
                      {units.data?.models
                        ?.filter((fill) => fill.lat != null && fill.lng != null)
                        .map((unit) => (
                          <Marker
                            key={unit?.id}
                            icon={
                              unit?.situation == 'SUCCESS'
                                ? mapGreenIcon
                                : unit?.situation == 'WARNING'
                                  ? mapYellowIcon
                                  : unit?.situation == 'DARK'
                                    ? mapDarkIcon
                                    : unit?.situation == 'DANGER'
                                      ? mapRedIcon
                                      : mapPurpleIcon
                            }
                            position={[unit?.lat, unit?.lng]}
                          >
                            <Popup
                              closeButton={false}
                              minWidth={240}
                              maxWidth={240}
                              className="map-popup"
                            >
                              <div>
                                <h3>
                                  {unit?.name} - {unit?.customer}
                                </h3>
                                <p>
                                  <Button
                                    className="btn-map"
                                    onClick={() =>
                                      handleShow(unit.id, unit.name)
                                    }
                                  >
                                    Equipamentos
                                  </Button>
                                </p>
                              </div>
                            </Popup>
                          </Marker>
                        ))}
                    </Map>
                  </div>
                </Col>
              </Row>
            )}
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        size={viewSismogran && view ? 'lg' : 'xl'}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="d-flex justify-content-between">
              <div>
                {viewSismogran && (
                  <img
                    src="../../../media/icons/left-arrow.svg"
                    className="mr-4"
                    onClick={handleBack}
                    alt="Voltar"
                  />
                )}{' '}
                {!view ? (
                  <>
                    Detalhes da unidade: <strong>{search.name_unit}</strong>

                    {viewSismogran && (<Button
                      variant='success'
                      className='ml-4'
                      onClick={() => setFull(!full ? true : false)}
                    >
                      {full ? "Aumentar Visualização" : "Reduzir Visualização"}
                    </Button>)}
                  </>
                ) : (
                  <>Definições de notificações de whatsapp</>
                )}
              </div>
              {/* {viewSismogran && (
                <div>
                  <OverlayTrigger
                    overlay={
                      <Tooltip id="tooltip-disabled">Notificações</Tooltip>
                    }
                  >
                    <Notification
                      src="../../../media/icons/notification.svg"
                      onClick={() => setView(!view ? true : false)}
                    />
                  </OverlayTrigger>
                </div>
              )} */}
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!viewSismogran ? (
            <Monitoring idUnit={search.id_unit} nameUnit={search.name_unit} />
          ) : viewSismogran && view ? (
            <NotificationView
              idContractItem={viewSismogran}
            // HandleNotification={HandleNotification}
            />
          ) : (
            <DetailsMonitoring sensor={viewSismogran} full={full} />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default App;
