import styled from 'styled-components';
import { Card } from 'react-bootstrap';

export const CardSt = styled(Card)`
  border-radius: 15px;
`;

export const CardHeaderSt = styled(Card.Header)`
  border-radius: 15px 15px 0 0 !important;
`;

export const IconTitleSt = styled.img`
  margin-right: 20px;
`;

export const TitleSt = styled.div`
  display: flex;
  align-items: center;
`;

export const TextTitleSt = styled.div`
  display: flex;
  align-items: center;
  color: #000;
  font-weight: bold;
  font-size: 18px;
`;

export const SmallTitleSt = styled.div`
  display: flex;
  align-items: center;
  color: #959595;
  font-size: 12px;
  font-weight: normal;
  margin-left: 10px;
`;
