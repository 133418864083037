import React from 'react';
import UpdateStatus from '../../components/contracts/updateStatus/updateStatus';
import OptionsContracts from '../../components/contracts/optionsContracts/optionsContracts';

export const columns = [
  {
    //0
    name: 'id',
    label: 'ID Contrato',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      display: 'excluded',
    },
  },
  {
    //1
    name: 'id_customer',
    label: 'ID Cliente',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      display: 'excluded',
    },
  },
  {
    //2
    name: 'id_author',
    label: 'Autor do Contrato',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      display: 'excluded',
    },
  },
  {
    //3
    name: 'customer',
    label: 'Cliente',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: true,
      sort: false,
      customBodyRender: (value, tableMeta) => {
        return !value ? '' : value.fantasy_name;
      },
    },
  },
  {
    //4
    name: 'contract_type',
    label: 'Tipo',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: true,
      sort: false,
      customBodyRender: (value, tableMeta) => {
        return value.name;
      },
    },
  },
  {
    //5
    name: 'name',
    label: 'Nome',
    options: {
      setCellHeaderProps: () => ({
        style: {
          display: 'flex',
          justifyContent: 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: true,
    },
  },
  {
    //6
    name: 'number',
    label: 'Número do Contrato',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      display: false,
    },
  },
  {
    //7
    name: 'start_date',
    label: 'Data inicial',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      display: false,
    },
  },
  {
    //8
    name: 'end_date',
    label: 'Data Final',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      display: false,
    },
  },
  {
    //9
    name: 'billing_information',
    label: 'Informações de pagamento',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      display: false,
    },
  },
  {
    //10
    name: 'total_value',
    label: 'Valor Total',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      display: false,
    },
  },
  {
    //11
    name: 'installments_quantity',
    label: 'Opção de Parcelamento',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      display: false,
    },
  },
  {
    //12
    name: 'installments_value',
    label: 'Valor das Parcelas',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      display: false,
    },
  },
  {
    //13
    name: 'cover_preventive',
    label: 'Preventivas',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: true,
      sort: false,
      customBodyRender: (value, tableMeta) => {
        return (
          <UpdateStatus
            id={tableMeta.rowData[0]}
            type="cover_preventive"
            activedefault={value}
            page={tableMeta.tableState.page}
            rowsPage={tableMeta.tableState.rowsPerPage}
            orderBy={tableMeta.tableState.sortOrder.name}
            order={tableMeta.tableState.sortOrder.direction}
            search={
              !tableMeta.tableState.searchText
                ? ''
                : tableMeta.tableState.searchText
            }
          />
        );
      },
    },
  },
  {
    //14
    name: 'cover_calibration',
    label: 'Calibrações',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: true,
      sort: false,
      customBodyRender: (value, tableMeta) => {
        return (
          <UpdateStatus
            id={tableMeta.rowData[0]}
            type="cover_calibration"
            activedefault={value}
            page={tableMeta.tableState.page}
            rowsPage={tableMeta.tableState.rowsPerPage}
            orderBy={tableMeta.tableState.sortOrder.name}
            order={tableMeta.tableState.sortOrder.direction}
            search={
              !tableMeta.tableState.searchText
                ? ''
                : tableMeta.tableState.searchText
            }
          />
        );
      },
    },
  },
  {
    //15
    name: 'cover_corretive',
    label: 'Corretivas',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: true,

      sort: false,
      customBodyRender: (value, tableMeta) => {
        return (
          <UpdateStatus
            id={tableMeta.rowData[0]}
            type="cover_corretive"
            activedefault={value}
            page={tableMeta.tableState.page}
            rowsPage={tableMeta.tableState.rowsPerPage}
            orderBy={tableMeta.tableState.sortOrder.name}
            order={tableMeta.tableState.sortOrder.direction}
            search={
              !tableMeta.tableState.searchText
                ? ''
                : tableMeta.tableState.searchText
            }
          />
        );
      },
    },
  },

  {
    //17
    name: 'active',
    label: 'Status',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
          width: '10%',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: true,
      sort: false,
      print: false,
      customBodyRender: (value, tableMeta) => {
        return (
          <UpdateStatus
            id={tableMeta.rowData[0]}
            activedefault={value}
            type="active"
            page={tableMeta.tableState.page}
            rowsPage={tableMeta.tableState.rowsPerPage}
            search={tableMeta.tableState.search}
            orderBy={tableMeta.tableState.sortOrder.name}
            order={tableMeta.tableState.sortOrder.direction}
            search={
              !tableMeta.tableState.searchText
                ? ''
                : tableMeta.tableState.searchText
            }
          />
        );
      },
    },
  },
  {
    //18
    name: 'options',
    label: 'Opções',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
          width: '10%',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      print: false,
      customBodyRender: (value, tableMeta) => {
        return (
          <OptionsContracts
            id={tableMeta.rowData[0]}
            idCustomer={tableMeta.rowData[1]}
            page={tableMeta.tableState.page}
            rowsPage={tableMeta.tableState.rowsPerPage}
            orderBy={tableMeta.tableState.sortOrder.name}
            order={tableMeta.tableState.sortOrder.direction}
            search={
              !tableMeta.tableState.searchText
                ? ''
                : tableMeta.tableState.searchText
            }
          />
        );
      },
    },
  },
  // {
  //   //19
  //   name: 'id',
  //   label: 'Editar',
  //   options: {
  //     setCellHeaderProps: () => ({
  //       style: {
  //         'text-align': 'center',
  //         width: '10%',
  //       },
  //     }),
  //     setCellProps: () => ({
  //       style: {
  //         'text-align': 'center',
  //       },
  //     }),
  //     filter: false,
  //     sort: false,
  //     print: false,
  //     customBodyRender: (value, tableMeta) => {
  //       return (
  //         <IndexEditContracts
  //           id={value}
  //           page={tableMeta.tableState.page}
  //           rowsPage={tableMeta.tableState.rowsPerPage}
  //           orderBy={tableMeta.tableState.sortOrder.name}
  //           order={tableMeta.tableState.sortOrder.direction}
  //           search={
  //             !tableMeta.tableState.searchText
  //               ? ''
  //               : tableMeta.tableState.searchText
  //           }
  //         />
  //       );
  //     },
  //   },
  // },
  {
    //20
    name: 'billing_information',
    label: 'Forma de Pagamento',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      display: false,
    },
  },
];
