import api from '../../services/api';
import { Prefix } from '../../services/prefix';

export async function loadOptions(
  search,
  loadedOptions,
  { page, endpoint, params, paramName, paramId, paramNameObs },
) {
  let route = !search
    ? endpoint + '?search=' + search + '&page=' + page + '&' + params
    : endpoint + '?search=' + search + '&page=' + page;
  const response = await api.get(Prefix.replace(/[\\"]/g, '') + route);

  const variantId = !paramId ? 'id' : paramId;
  const variantName = !paramName ? 'name' : paramName;

  const data = response.data.models.data.map((map) => {
    if (!paramNameObs) {
      return {
        value: eval('map.' + variantId),
        label: eval('map.' + variantName),
      };
    } else {
      return {
        value: eval('map.' + variantId),
        label:
          eval('map.' + variantName) + ' (' + eval('map.' + paramNameObs) + ')',
      };
    }
  });

  const total = response.data.models.data.length;

  return {
    options: data,
    hasMore: total != 0,
    // hasMore: loadedOptions.length < total,
    additional: {
      page: page + 1,
      endpoint: endpoint,
      params: params,
      paramNameObs: paramNameObs,
      paramName: paramName,
      paramId: paramId,
    },
  };
}
