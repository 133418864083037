import React, { useEffect, useState } from "react";
import { Row, Col, Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../contracts.css";
import api from "../../../services/api";
import Fontawesome from "../../fontawesome/fontawesome";
import { Prefix } from "../../../services/prefix";
import Loading from "../../loader/loading";
import Swal from "sweetalert2";
import { useFetch } from "../../../components/hooks/useFetch";
import { mutate as GlobalMutate } from "swr";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Schema from "./schema";
import { ModalContainer } from "../../../style/stylesModals"; 
import { CustomButton, TextButton } from "../../../style/stylesButton";

const IndexEditContractType = ({
  id,
  page,
  rowsPage,
  search,
  orderBy,
  order,
}) => {
  const [loader, setLoader] = useState();
  const [show, setShow] = useState(false);
  const [initialState, setInitialState] = useState([]);
  const [registerData, setRegisterData] = useState(initialState);

  let pageExact = page + parseInt(1);
  let valueSearch = !search ? "" : search;

  const handleShow = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };

  const listContractType = useFetch(
    Prefix.replace(/[\\"]/g, "") + "/contract-types/" + id
  );

  useEffect(() => {
    if (listContractType.data) {
      setInitialState({
        name: listContractType.data?.model.name,
      });
    }
  }, [listContractType.data]);

  if (!listContractType.data) return <Loading />;

  function HandleRegister(values) {
    setLoader(true);
    api
      .put(Prefix.replace(/[\\"]/g, "") + "/contract-types/" + id, values)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Uow!",
          text: response.data.message,
          confirmButtonText: "Fechar",
        });
        setLoader(false);
        setShow(false);
        GlobalMutate(
          Prefix.replace(/[\\"]/g, "") +
            "/contract-types?per_page=" +
            rowsPage +
            "&page=" +
            pageExact +
            "&search=" +
            valueSearch +
            "&order_by=" +
            orderBy +
            "&order=" +
            order
        );
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: err.response.data.message,
          confirmButtonText: "Tentar novamente",
        });
        setLoader(false);
      });
  }

  return (
    <>
      <Link
        variant="success"
        onClick={handleShow}
        className="link_color_datatable"
      >
        <Fontawesome classe="fas fa-pencil-alt" />
      </Link>

      <ModalContainer
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title><h3>Atualizar Tipo de Contrato</h3></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik //--> Configuração do Formik
            initialValues={initialState}
            validationSchema={Schema}
            onSubmit={HandleRegister}
          >
            {(
              { values } //--> Captação de valores
            ) => (
              <Form>
                <div>
                  {loader}
                  <Row>
                    <Col>
                      <div className="formStep">
                        <label class="important">
                          Nome do Tipo de Contrato
                        </label>
                        <Field
                          type="text"
                          placeholder="Digite um tipo de contrato"
                          name="name"
                          defaultValue={values?.name}
                        />
                        <ErrorMessage
                          name="name"
                          component="span"
                          className="errorMessages"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="d-flex justify-content-center">
                    <CustomButton  type="submit">
                       <TextButton>Atualizar</TextButton>  
                      </CustomButton>
                    </Col>
                  </Row>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </ModalContainer>
    </>
  );
};

export default IndexEditContractType;
