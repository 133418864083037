import React from 'react';
import Edit from '../../components/customer/editcustomer/indexEditCustomer';
import UpdateStatus from '../../components/customer/updateStatus/updateStatus';
import Foreign from '../../components/foreign/foreign';
import IndexLogo from '../../components/customer/changeLogo/changeLogo';
import '@fortawesome/fontawesome-free/js/all.js';
import OptionsClients from '../../components/customer/optionsClients/optionsClients';

export const columns = [
  {
    //0
    name: 'id',
    label: '',
    options: {
      filter: false,
      sort: false,
      display: 'excluded',
    },
  },
  {
    //1
    name: 'logo',
    label: 'Logotipo',
    options: {
      filter: false,
      sort: false,
      display: true,
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      customBodyRender: (value, tableMeta) => {
        let idUser = tableMeta?.rowData[0];
        let logo = tableMeta?.rowData[1];

        return (
          <IndexLogo
            id={idUser}
            logo={logo}
            page={tableMeta.tableState.page}
            rowsPerPage={tableMeta.tableState.rowsPerPage}
            search={
              !tableMeta.tableState.searchText
                ? ''
                : tableMeta.tableState.searchText
            }
          />
        );
      },
    },
  },
  {
    //2
    name: 'name',
    label: 'Nome',
    options: {
      filter: false,
      sort: true,
      display: false,
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
    },
  },
  {
    //3
    name: 'fantasy_name',
    label: 'Nome Fantasia',
    options: {
      filter: false,
      sort: true,
      display: true,
      setCellHeaderProps: () => ({
        style: {
          display: "flex",
          justifyContent: "center",
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
    },
  },
  {
    //4
    name: 'city',
    label: 'Região',
    options: {
      filter: true,
      sort: false,
      display: true,
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      customBodyRender: (value, tableMeta) => {
        return (
          /*O customBodyRender atribui um componente personalizado ao espaço no datatable, assim é definido no name o nome da posição do array, em seguinda
          utilizamos o value para retornar todos os dados deste array, e passamos estes dados para o componente Foreign que exibirá as informações
        */
          <Foreign value={value} nameField="name" secondNameField="uf" />
        );
      },
    },
  },
  {
    //5
    name: 'corporate_name',
    label: 'Razão Social',
    options: {
      filter: false,
      sort: false,
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
    },
  },

  {
    //6
    name: 'document',
    label: 'CNPJ',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
    },
  },
  {
    //7
    name: 'zipcode',
    label: 'CEP',
    options: {
      filter: false,
      sort: false,
      display: false,
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
    },
  },
  {
    //8
    name: 'address',
    label: 'Endereço',
    options: {
      filter: false,
      sort: false,
      display: false,
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
    },
  },
  {
    //9
    name: 'neighborhood',
    label: 'Bairro',
    options: {
      filter: false,
      sort: false,
      display: false,
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
    },
  },

  {
    //10
    name: 'number',
    label: 'Número',
    options: {
      filter: false,
      sort: false,
      display: false,
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
    },
  },
  {
    //11
    name: 'complement',
    label: 'Complemento',
    options: {
      filter: false,
      sort: false,
      display: false,
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
    },
  },
  {
    //12
    name: 'phone1',
    label: 'Telefone',
    options: {
      filter: false,
      sort: false,
      display: false,
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
    },
  },
  {
    //13
    name: 'phone2',
    label: 'Celular',
    options: {
      filter: false,
      sort: false,
      display: false,
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
    },
  },
  {
    //14
    name: 'city',
    label: 'Cidade',
    options: {
      filter: true,
      sort: false,
      display: false,
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      customBodyRender: (value, tableMeta) => {
        return <Foreign value={value} nameField="name" />;
      },
    },
  },
  {
    //15
    name: 'city',
    label: 'Estado',
    options: {
      filter: true,
      sort: false,
      display: false,
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      customBodyRender: (value, tableMeta) => {
        return <Foreign value={value} nameField="uf" />;
      },
    },
  },

  {
    //16
    name: 'active',
    label: 'Status',
    options: {
      filter: true,
      sort: false,
      print: false,
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
          width: '10%',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      customBodyRender: (value, tableMeta) => {
        return (
          <UpdateStatus
            activeDefault={value}
            id={tableMeta.rowData[0]}
            page={tableMeta.tableState.page}
            rowsPage={tableMeta.tableState.rowsPerPage}
            search={
              !tableMeta.tableState.searchText
                ? ''
                : tableMeta.tableState.searchText
            }
            orderBy={tableMeta.tableState.sortOrder.name}
            order={tableMeta.tableState.sortOrder.direction}
          />
        );
      },
    },
  },
  {
    //17
    name: 'options',
    label: 'Opções',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
          width: '10%',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      print: false,
      customBodyRender: (value, tableMeta) => {
        return <OptionsClients id={tableMeta.rowData[0]} />;
      },
    },
  },

  {
    name: 'Edit',
    label: 'Editar',
    options: {
      filter: false,
      sort: false,
      print: false,
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
          width: '10%',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      customBodyRender: (value, tableMeta) => {
        return (
          <Edit
            id={tableMeta.rowData[0]}
            idCustomer={value}
            page={tableMeta.tableState.page}
            rowsPerPage={tableMeta.tableState.rowsPerPage}
            search={
              !tableMeta.tableState.searchText
                ? ''
                : tableMeta.tableState.searchText
            }
            orderBy={tableMeta.tableState.sortOrder.name}
            order={tableMeta.tableState.sortOrder.direction}
          />
        );
      },
    },
  },
];
