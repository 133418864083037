import React from 'react';
import DownloadImageUpload from './downloadImageUpload';
import IndexLogo from './changeLogo'

export const columns = [
  {//0
    name: '',
    label: 'Imagem',
    options: {
      filter: false,
      sort: false,
      display: true,
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      customBodyRender: (value, tableMeta) => {
        let img = tableMeta?.rowData[2];
        return (
          <>
            {<div style={{ margin: '0 auto', width:'50%' }}>
              <div className="models-logo" style={{ background: 'url(' + img + ')', margin: '0 auto', }}>
              </div>
            </div>
            }
          </>
        );
      },
    },
  },
  {//1
    name: 'id',
    label: 'id',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      display: 'excluded',
    },
  },
  {//2
    name: 'path',
    label: 'id',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      display: 'excluded',
    },
  },
  {//3
    name: 'name',
    label: 'Nome do Anexo',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
    },
  },

];
