import React, { useState, useContext } from 'react';
import { useFetch } from '../../../components/hooks/useFetch';
import { Prefix } from '../../../services/prefix';
import { uf } from '../../data/uf';
import './room.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useOnClickOutside } from '../../../components/hooks/useOnClickOutSide';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Context } from '../../../services/context';

const FilterRoom = () => {
  const {
    selectclient,
    selectunit,
    selectsector,
    setSelectClient,
    setSelectUnit,
    setSelectSector,
  } = useContext(Context);

  const resetFilter = () => {
    setSelectClient('');
    setSelectUnit('');
    setSelectSector('');
  };

  const listClient = useFetch(Prefix.replace(/[\\"]/g, '') + '/customers');
  const listUnits = useFetch(Prefix.replace(/[\\"]/g, '') + '/units');
  const listSector = useFetch(Prefix.replace(/[\\"]/g, '') + '/sectors');

  return (
    <Row>
      <Col>
        <div className="filterAlerts">
          <select onChange={(e) => setSelectClient(e.target.value)}>
            <option selected disabled>
              Filtrar por Cliente
            </option>
            {listClient.data?.models.data.map((map, key) => {
              return (
                <option key={key} value={map.id}>
                  {map.name}
                </option>
              );
            })}
          </select>
          <select onChange={(e) => setSelectUnit(e.target.value)}>
            {!selectclient ? (
              <option selected disabled>
                Filtrar por Unidade
              </option>
            ) : (
              ''
            )}
            <option value="">Nenhuma Unidade</option>
            {listUnits.data?.models.data
              .filter((filtro) => filtro.id_customer == selectclient)
              .map((map, key) => {
                return (
                  <option key={key} value={map.id}>
                    {map.name}
                  </option>
                );
              })}
          </select>
          <select onChange={(e) => setSelectSector(e.target.value)}>
            {!selectunit ? (
              <option selected disabled>
                Filtrar por Setor
              </option>
            ) : (
              ''
            )}
            <option value="">Nenhum Setor</option>
            {listSector.data?.models.data
              .filter((filtro) => filtro.id_unit == selectunit)
              .map((map, key) => {
                return (
                  <option key={key} value={map.id}>
                    {map.name}
                  </option>
                );
              })}
          </select>

          <Link to="#" className="ml-4" onClick={resetFilter}>
            <i class="fas fa-undo-alt"></i>
          </Link>
        </div>
      </Col>
    </Row>
  );
};
export default FilterRoom;
