import React, { useState, useRef, useEffect } from "react";
import "../contracts.css";
import api from "../../../services/api";
import { Prefix } from "../../../services/prefix";
import Loading from "../../loader/loading";
import Swal from "sweetalert2";
import { Col, Row, Button, Modal, Alert } from "react-bootstrap";
import CurrencyInput from "../../hooks/useCurrency";
import { mutate as GlobalMutate } from "swr";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { AsyncPaginate } from "react-select-async-paginate";
import { loadOptions } from "../../selectAsync/loadOptions";
import { colourStyles } from "../../selectAsync/colourStyles";
import { NoOptionsMessage } from "../../selectAsync/NoOptionsMessage";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import { useStyles } from "../../buttons/AddButtonStyle";
import { ModalContainer } from "../../../style/stylesModals";
import { CustomButton, TextButton } from "../../../style/stylesButton";
import { Formik, Field, FieldArray, ErrorMessage, Form } from "formik";
import Schema from "./schema";
import { Switch } from "@material-ui/core";
import InputSpinner from "react-bootstrap-input-spinner";
import { formatMoeda } from "../../../services/mask";
import Fontawesome from "../../fontawesome/fontawesome";
import { Link } from "react-router-dom";
import { useFetch } from "../../hooks/useFetch";

const IndexEditContracts = ({ id, page, rowsPage, search, orderBy, order }) => {
  const [show, setShow] = useState(false);
  const [payment, setPayment] = useState(false);
  const [loader, setLoader] = useState();
  const [installments, setInstallments] = useState(12);
  const [valueSelect, setValueSelect] = useState([]);
  const [registerData, setRegisterData] = useState({});
  let pageExact = page + parseInt(1);

  const handleShow = () => setShow(true);

  const handleClose = () => {
    setShow(false);
    setPayment(false);
  };

  const dataContracts = useFetch(
    !show ? "" : Prefix.replace(/[\\"]/g, "") + "/contracts/" + id
  );

  useEffect(() => {
    if (dataContracts.data) {
      setRegisterData({
        initialData: [
          {
            name: dataContracts.data?.model?.name,
            active: dataContracts.data?.model?.active,
            number: dataContracts.data?.model?.number,
            id_customer: dataContracts.data?.model?.id_customer,
            id_contract_type: dataContracts.data?.model?.id_contract_type,
            start_date: dataContracts.data?.model?.start_date,
            end_date: dataContracts.data?.model?.end_date,
            billing_information: dataContracts.data?.model?.billing_information,
            total_value: dataContracts.data?.model?.total_value,
            installments_quantity:
              dataContracts.data?.model?.installments_quantity,
            cover_preventive: dataContracts.data?.model?.cover_preventive,
            cover_calibration: dataContracts.data?.model?.cover_calibration,
            cover_corretive: dataContracts.data?.model?.cover_corretive,
          },
        ],
      });
    }
  }, [show, dataContracts.data]);

  const HandleSelect = (e, name, key) => {
    if (
      valueSelect.filter((fill) => fill.key == key && fill.nameField == name)
        .length > 0
    ) {
      valueSelect
        .filter((fill) => fill.key == key && fill.nameField == name)
        .map((map) => {
          let position = valueSelect.indexOf(map);
          valueSelect.splice(position, 1);
        });
      setValueSelect(() => {
        return [
          ...valueSelect,
          { value: e.value, label: e.label, nameField: name, key: key },
        ];
      });
    } else {
      setValueSelect((prev) => {
        return [
          ...prev,
          { value: e.value, label: e.label, nameField: name, key: key },
        ];
      });
    }
  };

  function HandleRegister(values) {
    values.initialData.map((m) => {

      api
        .put(Prefix.replace(/[\\"]/g, "") + "/contracts/" + id, {
          name: m.name,
          active: m.active,
          number: m.number,
          id_customer: m.id_customer,
          id_contract_type: m.id_contract_type,
          start_date: m.start_date,
          end_date: m.end_date,
          billing_information: m.billing_information,
          total_value: !isNaN(values.initialData[0].total_value) ? m.total_value : m.total_value.replace(/\D/g, ""),
          installments_quantity: m.installments_quantity,
          cover_preventive: m.cover_preventive,
          cover_calibration: m.cover_calibration,
          cover_corretive: m.cover_corretive,
        })
        .then((response) => {
          Swal.fire({
            icon: "success",
            title: "Uow!",
            text: response.data.message,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: "Fechar",
            cancelButtonText: "Novo Cadastro",
            allowOutsideClick: false,
          }).then((result) => {
            if (!result.isConfirmed) {
              setShow(true);
            }
          });
          setShow(false);
          setPayment(false);
          setLoader(false);
          setValueSelect([]);
          dataContracts.mutate();
          GlobalMutate(
            Prefix.replace(/[\\"]/g, "") +
            "/contracts?per_page=" +
            rowsPage +
            "&page=" +
            pageExact +
            "&search=" +
            search +
            "&order_by=" +
            orderBy +
            "&order=" +
            order
          );
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: err.response.data.message,
            confirmButtonText: "Tentar novamente",
          });
          setLoader(false);
        });
    });
  }

  function HandleNext() {
    setPayment(true);
  }

  const classes = useStyles();

  return (
    <>
      <Link onClick={handleShow} className="dropdown-item">
        <Fontawesome classe="fas fa-pencil-alt mr-2" /> Editar
      </Link>

      <ModalContainer
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h3>Atualizar contrato</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik //--> Configuração do Formik
            initialValues={registerData}
            onSubmit={HandleRegister}
            enableReinitialize
          >
            {({ values, handleChange, setFieldValue }) => (
              <Form>
                <FieldArray name="initialData">
                  {({ push, remove }) => (
                    <div>
                      {values?.initialData?.map((p, index) => {
                        return (
                          <>
                            <div
                              className="customerStep"
                              style={{ display: !payment ? "block" : "none" }}
                            >
                              <Row>
                                <Col>
                                  <div className="formStep">
                                    <div className="labelForm">
                                      <label class="important">
                                        Nome do contrato
                                      </label>
                                    </div>
                                    <Field
                                      type="text"
                                      maxLength="20"
                                      placeholder="Digite um nome"
                                      name={`initialData[${index}].name`}
                                      onBlur={handleChange}
                                    />
                                    <ErrorMessage
                                      name={`initialData[${index}].name`}
                                      component="span"
                                      className="errorMessages"
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <div className="formStep">
                                    <label className="important">Cliente</label>
                                    <AsyncPaginate
                                      value={
                                        valueSelect.length == 0
                                          ? {
                                            value:
                                              dataContracts.data?.model
                                                ?.customer?.id,
                                            label:
                                              dataContracts.data?.model
                                                ?.customer?.name,
                                          }
                                          : valueSelect
                                            .filter(
                                              (fill) =>
                                                fill.nameField ==
                                                "id_customer"
                                            )
                                            .map((map) => {
                                              return {
                                                value: map.value,
                                                label: map.label,
                                              };
                                            })
                                      }
                                      loadOptions={loadOptions}
                                      components={{ NoOptionsMessage }}
                                      onChange={(e) => {
                                        HandleSelect(e, "id_customer");
                                        setFieldValue(
                                          `initialData[${index}].id_customer`,
                                          e.value
                                        );
                                      }} //values and name of select paginate
                                      placeholder="Selecione..."
                                      cacheUniqs={[valueSelect]} //Set your param that is going to change when you select another field. Just use this option when you are setting params and need to refresh cache.
                                      styles={colourStyles}
                                      additional={{
                                        page: 1,
                                        endpoint: "/customers",
                                        // params: `uf=${values.uf}`, //Separete with & (and commercial)
                                        paramName: "name",
                                        paramId: "id",
                                      }}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <div className="formStep text-center">
                                    <label class="important">
                                      Número do contrato
                                    </label>
                                    <Field
                                      type="text"
                                      placeholder="Digite o nº do contrato"
                                      name={`initialData[${index}].number`}
                                      onChange={handleChange}
                                    />
                                    <ErrorMessage
                                      name={`initialData[${index}].number`}
                                      component="span"
                                      className="errorMessages"
                                    />
                                  </div>
                                </Col>

                                <Col>
                                  <div className="formStep text-center">
                                    <label className="important">
                                      Tipo de contrato
                                    </label>
                                    <AsyncPaginate
                                      value={
                                        valueSelect.length == 0
                                          ? {
                                            value:
                                              dataContracts.data?.model
                                                ?.contract_type?.id,
                                            label:
                                              dataContracts.data?.model
                                                ?.contract_type?.name,
                                          }
                                          : valueSelect
                                            .filter(
                                              (fill) =>
                                                fill.nameField ==
                                                "id_contract_type"
                                            )
                                            .map((map) => {
                                              return {
                                                value: map.value,
                                                label: map.label,
                                              };
                                            })
                                      }
                                      loadOptions={loadOptions}
                                      components={{ NoOptionsMessage }}
                                      onChange={(e) => {
                                        HandleSelect(e, "id_contract_type");
                                        setFieldValue(
                                          `initialData[${index}].id_contract_type`,
                                          e.value
                                        );
                                      }} //values and name of select paginate
                                      placeholder="Selecione..."
                                      cacheUniqs={[valueSelect]} //Set your param that is going to change when you select another field. Just use this option when you are setting params and need to refresh cache.
                                      styles={colourStyles}
                                      additional={{
                                        page: 1,
                                        endpoint: "/contract-types",
                                        // params: `uf=${values.uf}`, //Separete with & (and commercial)
                                        paramName: "name",
                                        paramId: "id",
                                      }}
                                    />
                                    <ErrorMessage
                                      name={`initialData[${index}].id_contract_type`}
                                      component="span"
                                      className="errorMessages"
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <div className="formStep text-center">
                                    <label class="important">
                                      Data de início
                                    </label>
                                    <Field
                                      type="date"
                                      name={`initialData[${index}].start_date`}
                                      onChange={handleChange}
                                    />
                                    <ErrorMessage
                                      name={`initialData[${index}].start_date`}
                                      component="span"
                                      className="errorMessages"
                                    />
                                  </div>
                                </Col>
                                <Col>
                                  <div className="formStep text-center">
                                    <label class="important">
                                      Data de término
                                    </label>
                                    <Field
                                      type="date"
                                      name={`initialData[${index}].end_date`}
                                      onChange={handleChange}
                                    />
                                    <ErrorMessage
                                      name={`initialData[${index}].end_date`}
                                      component="span"
                                      className="errorMessages"
                                    />
                                  </div>
                                </Col>
                              </Row>

                              <Row className="mt-4 text-center">
                                <Col>
                                  <label class="important">
                                    Selecione ao menos um tipo de cobertura
                                  </label>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <div style={{ zIndex: 0 }}>
                                    <Switch
                                      size="small"
                                      color="primary"
                                      name={`initialData[${index}].cover_preventive`}
                                      checked={
                                        values.initialData[0]
                                          .cover_preventive === true
                                      }
                                      onChange={(e, checked) => {
                                        setFieldValue(
                                          `initialData[${index}].cover_preventive`,
                                          checked ? true : false
                                        );
                                      }}
                                    />
                                    <label>Preventiva</label>
                                    <ErrorMessage
                                      name={`initialData[${index}].cover_preventive`}
                                      component="span"
                                      className="errorMessages"
                                    />
                                  </div>
                                </Col>
                                <Col>
                                  <div style={{ zIndex: 0 }}>
                                    <Switch
                                      size="small"
                                      color="primary"
                                      name={`initialData[${index}].cover_corretive`}
                                      checked={
                                        values.initialData[0]
                                          .cover_corretive === true
                                      }
                                      onChange={(e, checked) => {
                                        setFieldValue(
                                          `initialData[${index}].cover_corretive`,
                                          checked ? true : false
                                        );
                                      }}
                                    />
                                    <label>Corretiva</label>
                                    <ErrorMessage
                                      name={`initialData[${index}].cover_corretive`}
                                      component="span"
                                      className="errorMessages"
                                    />
                                  </div>
                                </Col>
                                <Col>
                                  <div style={{ zIndex: 0 }}>
                                    <Switch
                                      size="small"
                                      color="primary"
                                      name={`initialData[${index}].cover_calibration`}
                                      checked={
                                        values.initialData[0]
                                          .cover_calibration === true
                                      }
                                      onChange={(event, checked) => {
                                        setFieldValue(
                                          `initialData[${index}].cover_calibration`,
                                          checked ? true : false
                                        );
                                      }}
                                    />
                                    <label>Calibração</label>
                                    <ErrorMessage
                                      name={`initialData[${index}].cover_calibration`}
                                      component="span"
                                      className="errorMessages"
                                    />
                                  </div>
                                </Col>
                              </Row>

                              <Row className="d-flex justify-content-center">
                                <Col className="d-flex justify-content-center mt-4">
                                  <Button
                                    style={{
                                      backgroundColor: "#1bc5bd",
                                      width: "160px",
                                      height: "60px",
                                      border: 0,
                                      borderRadius: "30px",
                                      marginTop: "20px",
                                    }}
                                    onClick={HandleNext}
                                  >
                                    Avançar
                                  </Button>
                                </Col>
                              </Row>
                            </div>

                            <div
                              className="customerStep"
                              style={{ display: payment ? "block" : "none" }}
                            >
                              <Row>
                                <Col>
                                  <div className="formStep">
                                    <label>Forma de Pagamento:</label>
                                    <OverlayTrigger
                                      overlay={
                                        <Tooltip id="tooltip-disabled">
                                          Informe o tipo de pagamento do
                                          contrato (Ex.: Entrada + 10x sem
                                          juros, pagamento bimestral, etc.)
                                        </Tooltip>
                                      }
                                    >
                                      <a
                                        href="#"
                                        style={{
                                          color: "#000",
                                          marginLeft: 5,
                                        }}
                                      >
                                        <i class="far fa-question-circle"></i>
                                      </a>
                                    </OverlayTrigger>
                                    <Field
                                      type="text"
                                      placeholder="Digite informações do pagamento"
                                      name={`initialData[${index}].billing_information`}
                                      onChange={handleChange}
                                    />
                                    <ErrorMessage
                                      name={`initialData[${index}].billing_information`}
                                      component="span"
                                      className="errorMessages"
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <div className="formStep text-center">
                                    <label>Valor total</label>
                                    <CurrencyInput
                                      placeholder="R$ 0,00"
                                      type="text"
                                      name={`initialData[${index}].total_value`}
                                      value={values.initialData[0].total_value}
                                      onChange={handleChange}
                                    />
                                  </div>
                                </Col>
                                <Col>
                                  <div className="formStep text-center">
                                    <label>Qtd. de parcelas</label>
                                    <InputSpinner
                                      name={`initialData[${index}].installments_quantity`}
                                      type="number"
                                      variant="success"
                                      size="md"
                                      precision={1}
                                      max={12}
                                      min={1}
                                      value={
                                        !values.initialData[0]
                                          .installments_quantity
                                          ? 0
                                          : values.initialData[0]
                                            .installments_quantity
                                      }
                                      onChange={(e) =>
                                        setFieldValue(
                                          `initialData[${index}].installments_quantity`,
                                          e
                                        )
                                      }
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <div className="formStep text-center">
                                    <label>Valor da parcela</label>
                                    <div>
                                      {!isNaN(values.initialData[0].total_value)
                                        ? formatMoeda(
                                          Math.floor(
                                            values.initialData[0].total_value
                                          ) /
                                          values.initialData[0]
                                            .installments_quantity
                                        )
                                        : formatMoeda(
                                          Math.floor(
                                            values.initialData[0].total_value?.replace(
                                              /\D/g,
                                              ""
                                            ) /
                                            values.initialData[0]
                                              .installments_quantity
                                          )
                                        )}
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                              <Row className="d-flex justify-content-between">
                                <Col className="d-flex justify-content-center mt-4">
                                  <Button
                                    style={{
                                      backgroundColor: "#1bc5bd",
                                      width: "160px",
                                      height: "60px",
                                      border: 0,
                                      borderRadius: "30px",
                                      marginTop: "20px",
                                    }}
                                    onClick={() => setPayment(false)}
                                  >
                                    Voltar
                                  </Button>
                                </Col>
                                <Col className="d-flex justify-content-center mt-4">
                                  <CustomButton type="submit">
                                    <TextButton> Atualizar </TextButton>
                                  </CustomButton>
                                </Col>
                              </Row>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  )}
                </FieldArray>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </ModalContainer>
    </>
  );
};
export default IndexEditContracts;
