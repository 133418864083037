import React, { useState, useRef, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import "../equipamentsFamilies.css";
import { Prefix } from "../../../services/prefix";
import Loading from "../../loader/loading";
import Swal from "sweetalert2";
import api from "../../../services/api";
import { mutate as GlobalMutate } from "swr";
import Fontawesome from "../../fontawesome/fontawesome";
import Modal from "react-bootstrap/Modal";
import { useFetch } from "../../hooks/useFetch";
import Schema from "./schema";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Switch } from "@material-ui/core";
import Button from "react-bootstrap/Button";
import { ModalContainer } from "../../../style/stylesModals";
import { CustomButton, TextButton } from "../../../style/stylesButton";

const IndexEditEquipamentsFamilies = ({
  id,
  page,
  rowsPage,
  search,
  orderBy,
  order,
}) => {
  const [loader, setLoader] = useState();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let pageExact = page + 1;
  let valueSearch = !search ? "" : search;

  const listFamily = useFetch(
    !show ? null : Prefix.replace(/[\\"]/g, "") + "/equipment-families/" + id
  );

  const [registerData, setRegisterData] = useState({});

  useEffect(() => {
    if (listFamily?.data) {
      setRegisterData({
        name: listFamily?.data?.model?.name,
        active: listFamily?.data?.model?.active,
        monitoring: listFamily?.data?.model?.monitoring,
      });
    }
  }, [listFamily.data]);

  const buttonSend = useRef(null);

  const HandleChange = (e) => {
    setRegisterData({
      ...registerData,
      [e.target.name]: e.target.value.trim(),
    });
  };

  /*Atualizar dados do cliente*/
  function handleRegister(values) {
    setLoader(<Loading />);

    api
      .put(Prefix.replace(/[\\"]/g, "") + "/equipment-families/" + id, values)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Uow!",
          text: response.data.message,
          confirmButtonText: "Fechar",
        });
        setLoader(false);
        setRegisterData({});
        setShow(false);
        GlobalMutate(
          Prefix.replace(/[\\"]/g, "") +
            "/equipment-families?per_page=" +
            rowsPage +
            "&page=" +
            pageExact +
            "&search=" +
            valueSearch +
            "&order_by=" +
            orderBy +
            "&order=" +
            order
        );
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: err.response.data.message,
          confirmButtonText: "Tentar novamente",
        });
        setLoader();
      });
  }

  return (
    <>
      {!show ? (
        <Link
          to="#"
          variant="success"
          onClick={handleShow}
          className="link_color_datatable"
        >
          <Fontawesome classe="fas fa-pencil-alt" />
        </Link>
      ) : (
        <ModalContainer
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title><h3>Atualizar<br/> Família de Equipamento</h3></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {loader && <Loading />}
            <Formik
              initialValues={registerData}
              validationSchema={Schema}
              onSubmit={handleRegister}
              enableReinitialize
            >
              {({ values }) => (
                <Form>
                  <Row>
                    <Col>
                      <div className="formStep">
                        <div className="labelForm">
                          <label className="important">Nome da Família:</label>
                        </div>
                        <Field
                          type="text"
                          placeholder="Digite o nome do equipamento"
                          name="name"
                          defaultValue={listFamily?.data?.model?.name}
                          onBlur={HandleChange}
                        />
                        <ErrorMessage
                          name="name"
                          component="span"
                          className="errorMessages"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="d-flex justify-content-center">
                    <CustomButton onClick={handleRegister}>
                       <TextButton> Atualizar </TextButton>
                     </CustomButton>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Modal.Body>
        </ModalContainer>
      )}
    </>
  );
};
export default IndexEditEquipamentsFamilies;
