import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Modal, Alert, Col, Row, Button } from "react-bootstrap";
import Loading from "../../loader/loading";
import api from "../../../services/api";
import { Prefix } from "../../../services/prefix";
import Swal from "sweetalert2";
import Fontawesome from "../../fontawesome/fontawesome";
import { Formik, Field, FieldArray, ErrorMessage, Form } from "formik";
import { mutate as GlobalMutate } from "swr";
import { AsyncPaginate, component } from "react-select-async-paginate";
import { NoOptionsMessage } from "../../selectAsync/NoOptionsMessage";
import { colourStyles } from "../../selectAsync/colourStyles";
import { loadOptions } from "../../selectAsync/loadOptions";
import Select, { components } from "react-select";
import Schema from "./schema";
import { uf } from "../../data/uf";
import { maskTel, maskTelFixo } from "../../../services/mask";
import { useStyles } from "../../buttons/AddButtonStyle";
import { Fab } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { ModalContainer } from "../../../style/stylesModals"; 
import { CustomButton, TextButton } from "../../../style/stylesButton";

const AddBases = (props) => {
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const [valueSelect, setValueSelect] = useState([]);
  const [registerData, setRegisterData] = useState({
    initialData: [
      {
        key: uuidv4(),
        name: "",
        active: 1,
        address: "",
        neighborhood: "",
        number: "",
        email: "",
        phone1: "",
        phone2: "",
        state: "",
        id_city: "",
      },
    ],
  });
  const classes = useStyles();

  const handleShow = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
    setValueSelect([]);
    setRegisterData({
      initialData: [
        {
          key: uuidv4(),
          name: "",
          active: 1,
          address: "",
          neighborhood: "",
          number: "",
          email: "",
          phone1: "",
          phone2: "",
          state: "",
          id_city: "",
        },
      ],
    });
  };

  const HandleSelect = (e, name, key) => {
    if (
      valueSelect.filter((fill) => fill.key == key && fill.nameField == name)
        .length > 0
    ) {
      valueSelect
        .filter((fill) => fill.key == key && fill.nameField == name)
        .map((map) => {
          let position = valueSelect.indexOf(map);
          valueSelect.splice(position, 1);
        });
      setValueSelect(() => {
        return [
          ...valueSelect,
          { value: e.value, label: e.label, nameField: name, key: key },
        ];
      });
    } else {
      setValueSelect((prev) => {
        return [
          ...prev,
          { value: e.value, label: e.label, nameField: name, key: key },
        ];
      });
    }
  };

  function HandleRegister(values) {
    setLoader(true);

    values.initialData.map((map) => {
      api
        .post(Prefix.replace(/[\\"]/g, "") + "/bases", {
          active: 1,
          name: map.name,
          address: map.address,
          neighborhood: map.neighborhood,
          number: map.number,
          email: map.email,
          phone1: map.phone1,
          phone2: map.phone2,
          id_city: map.id_city,
        })
        .then((response) => {
          Swal.fire({
            icon: "success",
            title: "Uow!",
            text: response.data.message,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: "Fechar",
            cancelButtonText: "Novo Cadastro",
            allowOutsideClick: false,
            focusCancel: true,
          }).then((result) => {
            if (!result.isConfirmed) {
              setShow(true);
            }
          });
          setLoader(false);
          setShow(false);
          setValueSelect([]);
          GlobalMutate(
            Prefix.replace(/[\\"]/g, "") +
              "/bases?per_page=" +
              props.perPage +
              "&page=" +
              props.pageExact +
              "&search=" +
              props.search +
              "&order_by=" +
              props.orderBy +
              "&order=" +
              props.order
          );
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: err.response.data.message,
            confirmButtonText: "Tentar novamente",
          });
          setLoader(false);
        });
    });
  }

  return (
    <>
      {show === false ? (
        <Fab className={classes.fab} onClick={handleShow}>
          <AddIcon className={classes.icon} />
        </Fab>
      ) : (
        ""
      )}
      <ModalContainer
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title><h3>Cadastro de Base</h3></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik //--> Configuração do Formik
            initialValues={registerData}
            validationSchema={Schema}
            onSubmit={HandleRegister}
            enableReinitialize
          >
            {({ values, handleChange, setFieldValue }) => (
              <Form>
                <FieldArray name="initialData">
                  {({ push, remove }) => (
                    <div>
                      {values.initialData.map((p, index) => {
                        return (
                          <>
                            <Row>
                              <Col>
                                <div className="formStep" style={{ zIndex: 0 }}>
                                  <label class="important">Base</label>{" "}
                                  <Field
                                    type="text"
                                    placeholder="Digite um nome para base..."
                                    name={`initialData[${index}].name`}
                                    onBlur={handleChange}
                                  />
                                  <ErrorMessage
                                    name={`initialData[${index}].name`}
                                    component="span"
                                    className="errorMessages"
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <div className="formStep">
                                  <label class="important">Email</label>
                                  <Field
                                    type="text"
                                    placeholder="Digite seu email..."
                                    name={`initialData[${index}].email`}
                                    onChange={handleChange}
                                  />
                                  <ErrorMessage
                                    name={`initialData[${index}].address`}
                                    component="span"
                                    className="errorMessages"
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col sm={9} md={9} lg={9} xl={9}>
                                <div className="formStep">
                                  <label class="important">Endereço</label>
                                  <Field
                                    type="text"
                                    placeholder="Digite um endereço..."
                                    name={`initialData[${index}].address`}
                                    onChange={handleChange}
                                  />
                                  <ErrorMessage
                                    name={`initialData[${index}].address`}
                                    component="span"
                                    className="errorMessages"
                                  />
                                </div>
                              </Col>
                              <Col sm={3} md={3} lg={3} xl={3}>
                                <div className="formStep">
                                  <label class="important">Nº</label>
                                  <Field
                                    type="text"
                                    name={`initialData[${index}].number`}
                                    onChange={handleChange}
                                  />
                                  <ErrorMessage
                                    name={`initialData[${index}].number`}
                                    component="span"
                                    className="errorMessages"
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col sm={8} md={8} lg={8} xl={8}>
                                <div className="formStep">
                                  <label class="important">Bairro</label>
                                  <Field
                                    type="text"
                                    placeholder="Digite o número..."
                                    name={`initialData[${index}].neighborhood`}
                                    onChange={handleChange}
                                  />
                                  <ErrorMessage
                                    name={`initialData[${index}].neighborhood`}
                                    component="span"
                                    className="errorMessages"
                                  />
                                </div>
                              </Col>
                              <Col sm={4} md={4} lg={4} xl={4}>
                                <div className="formStep">
                                  <label class="important">Estado</label>
                                  <Select
                                    closeMenuOnSelect={false}
                                    styles={colourStyles}
                                    placeholder="Selecione..."
                                    name={`initialData[${index}].state`}
                                    components={{ NoOptionsMessage }}
                                    options={uf}
                                    onChange={(e) => {
                                      HandleSelect(e, "state", p.key);
                                      setFieldValue(
                                        `initialData[${index}].state`,
                                        e.value
                                      );
                                    }}
                                  />
                                  <ErrorMessage
                                    name={`initialData[${index}].state`}
                                    component="span"
                                    className="errorMessages"
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col sm={12}>
                                <div className="formStep">
                                  <label class="important">Cidade</label>
                                  <AsyncPaginate
                                    value={valueSelect
                                      .filter(
                                        (fill) =>
                                          fill.nameField == "id_city" &&
                                          fill.key == "id_city"
                                      )
                                      .map((map) => {
                                        return {
                                          value: map.value,
                                          label: map.label,
                                        };
                                      })}
                                    loadOptions={loadOptions}
                                    // name={`initialData[${index}].list_clients`}
                                    onChange={(e) => {
                                      HandleSelect(e, "id_city", "id_city");
                                      setFieldValue(
                                        `initialData[${index}].id_city`,
                                        e.value
                                      );
                                    }} //values and name of select paginate
                                    placeholder="Selecione uma cidade..."
                                    components={{ NoOptionsMessage }}
                                    cacheUniqs={[
                                      valueSelect
                                        .filter(
                                          (fill) => fill.nameField == "state"
                                        )
                                        .map((map) => map.value)[0],
                                    ]} //Set your param that is going to change when you select another field. Just use this option when you are setting params and need to refresh cache.
                                    styles={colourStyles}
                                    additional={{
                                      page: 1,
                                      endpoint: "/cities",
                                      params: `uf=${
                                        valueSelect
                                          .filter(
                                            (fill) => fill.nameField == "state"
                                          )
                                          .map((map) => map.value)[0]
                                      }`,
                                    }}
                                  />
                                  <ErrorMessage
                                    name={`initialData[${index}].id_city`}
                                    component="span"
                                    className="errorMessages"
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <div className="formStep">
                                  <label class="important">Telefone</label>{" "}
                                  <Field
                                    maxlength="14"
                                    type="text"
                                    placeholder="Informe telefone fixo"
                                    name={`initialData[${index}].phone1`}
                                    value={
                                      !values.initialData[index].phone1
                                        ? null
                                        : maskTelFixo(
                                            values.initialData[index].phone1
                                          )
                                    }
                                    onChange={handleChange}
                                  />
                                  <ErrorMessage
                                    name={`initialData[${index}].phone1`}
                                    component="span"
                                    className="errorMessages"
                                  />
                                </div>
                              </Col>
                              <Col>
                                <div className="formStep">
                                  <label class="important">Celular</label>{" "}
                                  <Field
                                    type="text"
                                    maxlength="16"
                                    placeholder="Informe número de celular"
                                    name={`initialData[${index}].phone2`}
                                    value={
                                      !values.initialData[index].phone2
                                        ? null
                                        : maskTel(
                                            values.initialData[index].phone2
                                          )
                                    }
                                    onChange={handleChange}
                                  />
                                  <ErrorMessage
                                    name={`initialData[${index}].phone2`}
                                    component="span"
                                    className="errorMessages"
                                  />
                                </div>
                              </Col>
                            </Row>
                          </>
                        );
                      })}
                      <div>
                        <Row>
                          <Col className="d-flex justify-content-center ">
                            <CustomButton
                              type="submit"
                            >
                            <TextButton>Cadastrar</TextButton> 
                            </CustomButton>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  )}
                </FieldArray>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </ModalContainer>
    </>
  );
};
export default AddBases;
