import React, { useState } from "react";
import { Link } from "react-router-dom";
import Fontawesome from "../../fontawesome/fontawesome";
import { useFetch } from "../../hooks/useFetch";
import { Prefix } from "../../../services/prefix";
import api from "../../../services/api";
import Swal from "sweetalert2";
import { mutate as GlobalMutate } from "swr";
import { Col, Row, Button, Modal } from "react-bootstrap";
import CustomFooter from "../../CustomFooter/CustomFooter";
import CustomSearchRender from "../../customSearchRender/CustomSearchRender";
import { AsyncPaginate, components } from "react-select-async-paginate";
import { loadOptions } from "../../selectAsync/loadOptions";
import colourStyles from "../../selectAsync/colourStyles";
import MUIDataTable from "mui-datatables";
import { columns } from "./columns";
import { uf } from "../../data/uf";
import Select from "react-select";
import { ModalContainer } from "../../../style/stylesModals";
import {
  CustomButton,
  TextButton,
  ButtonDesabled,
} from "../../../style/stylesButton";

const CitiesBases = ({ id }) => {
  const [show, setShow] = useState(false);

  const handleShow = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
    setValueSelect([]);
  };

  const [pagina, setPagina] = useState(0);
  const [perPage, setPerPage] = useState(15);
  const [search, setSearch] = useState("");
  const [loader, setLoader] = useState();
  const [valueSelect, setValueSelect] = useState([]);
  const [formData, setFormData] = useState({
    id_base: id,
  });

  let pageExact = pagina + parseInt(1);

  const listCityBase = useFetch(
    Prefix.replace(/[\\"]/g, "") +
      "/base-cities?per_page=" +
      perPage +
      "&page=" +
      pageExact +
      "&search=" +
      search +
      "&id_base=" +
      id
  );

  const HandleSelect = (e, name) => {
    if (valueSelect.filter((fill) => fill.nameField == name).length > 0) {
      valueSelect
        .filter((fill) => fill.nameField == name)
        .map((map) => {
          let valueIndice = valueSelect.indexOf(map);
          valueSelect.splice(valueIndice);
        });

      setValueSelect((prev) => {
        return [...prev, { value: e.value, label: e.label, nameField: name }];
      });
      setFormData({ ...formData, [name]: e.value });
    } else {
      setValueSelect((prev) => {
        return [...prev, { value: e.value, label: e.label, nameField: name }];
      });
      setFormData({ ...formData, [name]: e.value });
    }
  };

  const HandleRegister = () => {
    setLoader(true);
    api
      .post(Prefix.replace(/[\"]/g, "") + "/base-cities", formData)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Uow!",
          text: response.data.message,
          confirmButtonText: "Fechar",
        });
        setLoader();
        setValueSelect([]);
        GlobalMutate(
          Prefix.replace(/[\\"]/g, "") +
            "/base-cities?per_page=" +
            perPage +
            "&page=" +
            pageExact +
            "&search=" +
            search +
            "&id_base=" +
            id
        );
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: err.response.data.message,
          confirmButtonText: "Tentar novamente",
        });
        setLoader();
      });
  };

  function HandleDelete(e) {
    setLoader(true);
    let idArr = [];
    e.map((del) => {
      idArr.push(
        listCityBase.data?.models.data?.filter(
          (filtro, index) => index == del.index
        )[0].id
      );
    });

    idArr.map((idDel) =>
      api
        .delete(Prefix.replace(/[\\"]/g, "") + "/base-cities/" + idDel, {
          id: idArr,
        })
        .then((response) => {
          Swal.fire({
            icon: "success",
            title: "Uow!",
            text: response.data.message,
            confirmButtonText: "Fechar",
          });
          setLoader();
          GlobalMutate(
            Prefix.replace(/[\\"]/g, "") +
              "/base-cities?per_page=" +
              perPage +
              "&page=" +
              pageExact +
              "&search=" +
              search +
              "&id_base=" +
              id
          );
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: err.response.data.message,
            confirmButtonText: "Tentar novamente",
          });
          setLoader();
        })
    );
  }

  const options = {
    draggableColumns: {
      enabled: true,
    },
    serverSide: true,
    onTableChange: (action, tableState) => {
      setPagina(tableState.page);
      setPerPage(tableState.rowsPerPage);
    },
    onSearchChange: (searchText) => {
      setSearch(!search ? "" : searchText);
    },
    customSearchRender: (searchText, handleSearch, hideSearch, options) => {
      return (
        <CustomSearchRender
          placeholderSearch="Pesquisar Equipamento"
          searchText={searchText}
          onSearch={handleSearch}
          onHide={hideSearch}
          options={options}
        />
      );
    },
    filter: false,
    page: pagina,
    count: listCityBase.data?.models?.data?.total,
    expandableRows: false,
    expandableRowsHeader: false,
    expandableRowsOnClick: false,
    rowsPerPage: perPage,
    rowsPerPageOptions: [15, 30, 100],
    onRowsDelete: (rowsDeleted, newData) => {
      HandleDelete(rowsDeleted.data);
    },
    searchPlaceholder: "Pesquise uma Equipamento",
    selectableRows: true,
    searchOpen: false,

    textLabels: {
      body: {
        noMatch: "Desculpe, não encontramos Equipamento!",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
      pagination: {
        next: "Avançar",
        previous: "Voltar",
        rowsPerPage: "Linhas por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Pesquisar",
        downloadCsv: "Baixar CSV",
        print: "Imprimir",
        viewColumns: "Ver Colunas",
        filterTable: "Filtrar tabela",
      },
      filter: {
        all: "Tudo",
        title: "FILTROS",
        reset: "RESETAR",
      },
      viewColumns: {
        title: "Exibir colunas",
        titleAria: "Exibir/Ocultar colunas da tabela",
      },
      selectedRows: {
        text: "Coluna(s) selecionada(s)",
        delete: "Excluir",
        deleteAria: "Excluir linhas selecionadas",
      },
    },
    customFooter: (
      count,
      page,
      rowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels
    ) => {
      let numberPages = listCityBase.data?.models?.data.last_page;
      let rowsPerPageOptions = [15, 30, 100];
      return (
        <CustomFooter
          page={page}
          rowsPerPage={rowsPerPage}
          changeRowsPerPage={changeRowsPerPage}
          changePage={changePage}
          numberPages={numberPages}
          rowsPerPageOptions={rowsPerPageOptions}
        />
      );
    },
  };

  return (
    <>
      {!show ? (
        <>
          <Link className="dropdown-item" to="#" onClick={setShow}>
            <Fontawesome classe="fas fa-users mr-2" /> Cidades
          </Link>
        </>
      ) : (
        <>
          <ModalContainer
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <h3>Cidade(s) de Base</h3>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col sm={4} md={4} lg={4} xl={4}>
                  <div className="formStep">
                    <label class="important">Estado</label>
                    <Select
                      closeMenuOnSelect={false}
                      styles={colourStyles}
                      placeholder="Selecione..."
                      // name={`initialData[${index}].state`}
                      // components={{ NoOptionsMessage }}
                      options={uf}
                      onChange={(e) => {
                        HandleSelect(e, "state", "state");
                        // setFieldValue(`initialData[${index}].state`, e.value)
                      }}
                    />
                  </div>
                </Col>
                <Col sm={8} md={8} lg={8} xl={8}>
                  <div className="formStep">
                    <label className="important">Cidade</label>
                    <AsyncPaginate
                      value={valueSelect
                        .filter((fill) => fill.nameField == "id_city")
                        .map((map) => {
                          return {
                            value: map.value,
                            label: map.label,
                          };
                        })}
                      loadOptions={loadOptions}
                      cacheUniqs={[
                        valueSelect
                          .filter((fill) => fill.nameField == "id_city")
                          .map((map) => map.label),
                      ]}
                      onChange={(e) => HandleSelect(e, "id_city")}
                      //values and name of select paginate
                      placeholder="Selecione a Cidade..."
                      styles={colourStyles}
                      additional={{
                        page: 1,
                        endpoint: "/cities",
                        params: `uf=${
                          valueSelect.length == 0
                            ? ""
                            : valueSelect
                                .filter((fill) => fill.nameField == "state")
                                .map((map) => map.value)
                        }`, //Separete with & (and commercial)
                        // paramName: 'nickname'
                      }}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="d-flex justify-content-center mt-4 mb-3">
                  <CustomButton onClick={HandleRegister}>
                    <TextButton>Vincular</TextButton>
                  </CustomButton>
                </Col>
              </Row>
              <div style={{ position: "relative", zIndex: "0" }}>
                <MUIDataTable
                  data={listCityBase.data?.models.data}
                  columns={columns}
                  options={options}
                />
              </div>
              <Row>
                <Col className="d-flex justify-content-center mt-4 mb-3">
                  <Button
                    style={{
                      backgroundColor: "#1bc5bd",
                      width: "160px",
                      height: "60px",
                      border: 0,
                      borderRadius: "30px",
                      marginTop: "20px",
                    }}
                    onClick={handleClose}
                  >
                    Voltar
                  </Button>
                </Col>
              </Row>
            </Modal.Body>
          </ModalContainer>
        </>
      )}
    </>
  );
};
export default CitiesBases;
