import React, { useState, useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import Fontawesome from '../../fontawesome/fontawesome';
import { Link } from 'react-router-dom';
import '../backup.css';
import MUIDataTable from 'mui-datatables';
import { columns } from './columnsAttachments';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { useFetch } from '../../hooks/useFetch';
import { Prefix } from '../../../services/prefix';
import Dropzone from 'react-dropzone';
import { uniqueId } from 'lodash';
import api from '../../../services/api';
import { DropContainer, UploadMessage } from '../uploadFile';
import Swal from 'sweetalert2';
import Loading from '../../loader/loading';
import { mutate as mutateGlobal } from 'swr';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import CustomSearchRender from './CustomSearchRender';
import CustomFooter from '../../CustomFooter/CustomFooter';
import { Context } from '../../../services/context';
import { mutate as GlobalMutate } from 'swr';

export default function IndexBackupAttachments({
  iconClass,
  text,
  buttonLabel,
  modalSize,
  idEquipamentBackup,
}) {
  const { backupAttachment, setBackupAttachment } = useContext(Context);
  const [show, setShow] = useState(false);
  const [uploaded, setUploaded] = useState([]);
  const [pagina, setPagina] = useState(0);
  const [perPage, setPerPage] = useState(15);
  const [search, setSearch] = useState();
  const [nameAttach, setNameAttach] = useState();
  const [loader, setLoader] = useState();

  let pageExact = pagina + parseInt(1);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const listBackupAttachments = useFetch(
    Prefix.replace(/[\\"]/g, '') +
      '/equipament-backup-attachments?per_page=' +
      perPage +
      '&page=' +
      pageExact +
      '&search=',
  );

  const HandleUpload = (files) => {
    const uploadedFiles = files.map((file) => ({
      file,
      id: uniqueId(),
      name: nameAttach,
      readableSize: file.size,
      path: URL.createObjectURL(file),
      progress: 0,
      uploaded: false,
      error: false,
      url: null,
      id_equipament_backup: idEquipamentBackup,
    }));
    setUploaded({
      ...uploaded,
      uploadedFiles,
    });

    uploadedFiles.forEach(processUpload);
  };

  const processUpload = (uploadedFile) => {
    setLoader(<Loading />);

    const data = new FormData();
    data.append('path', uploadedFile.file);
    data.append('name', uploadedFile.name);
    data.append('id_equipament_backup', uploadedFile.id_equipament_backup);
    data.append('active', 1);

    api
      .post(
        Prefix.replace(/[\\"]/g, '') + '/equipament-backup-attachments',
        data,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        },
      )
      .then((response) => {
        Swal.fire({
          icon: 'success',
          title: 'Uow!',
          text: response.data.message,
          confirmButtonText: 'Fechar',
        }).then(() => {
          setLoader(false);
        });
        mutateGlobal(
          Prefix.replace(/[\\"]/g, '') +
            '/equipament-backup-attachments?per_page=' +
            perPage +
            '&page=' +
            pageExact +
            '&search=',
        );
        listBackupAttachments.mutate();
      })
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: err.response.data.message,
          confirmButtonText: 'Tentar novamente',
        });
        setLoader();
      });
  };

  function HandleDelete(e) {
    setLoader(<Loading />);
    let idArr = [];

    e.map((del) => {
      idArr.push(
        listBackupAttachments.data?.models.data.filter(
          (filtro, index) => index == del.index,
        )[0].id,
      );
    });

    idArr.map((idDel) =>
      api
        .delete(
          Prefix.replace(/[\\"]/g, '') +
            '/equipament-backup-attachments/' +
            idDel,
        )
        .then((response) => {
          Swal.fire({
            icon: 'success',
            title: 'Uow!',
            text: response.data.message,
            confirmButtonText: 'Fechar',
          }).then(() => {
            setLoader(false);
          });
          listBackupAttachments.mutate();
          GlobalMutate(
            Prefix.replace(/[\\"]/g, '') +
              '/equipament-backup-attachments?per_page=' +
              perPage +
              '&page=' +
              pageExact +
              '&search=',
          );
        })
        .catch((err) => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: err.response.data.message,
            confirmButtonText: 'Tentar novamente',
          });
          setLoader();
        }),
    );
  }

  const options = {
    draggableColumns: {
      enabled: true,
    },
    serverSide: true,
    onTableChange: (action, tableState) => {
      setPagina(tableState.page);
      setPerPage(tableState.rowsPerPage);
    },
    onSearchChange: (searchText) => {
      setSearch(searchText);
    },
    customSearchRender: (searchText, handleSearch, hideSearch, options) => {
      return (
        <CustomSearchRender
          placeholderSearch="Pesquisar Anexo"
          searchText={searchText}
          kup
          onSearch={handleSearch}
          onHide={hideSearch}
          options={options}
        />
      );
    },
    filter: false,
    page: pagina,
    expandableRows: true,
    expandableRowsHeader: false,
    expandableRowsOnClick: false,
    rowsPerPage: perPage,
    rowsPerPageOptions: [15, 30, 100],
    isRowExpandable: (dataIndex, expandedRows) => {
      // if (dataIndex === 3 || dataIndex === 4) return false;
      if (
        expandedRows.data.length > 4 &&
        expandedRows.data.filter((d) => d.dataIndex === dataIndex).length === 0
      )
        return false;
      return true;
    },
    renderExpandableRow: (rowData, rowMeta) => {
      const colSpan = rowData.length + 1;
      return (
        <TableRow>
          <TableCell colSpan={colSpan}>
            <Row>
              <Col className="text-center">
                <strong>Nome do Anexo:</strong> {rowData[3]}
                <br />
                <strong>Número:</strong>
                {rowData[2]}
                <br />
              </Col>
            </Row>
          </TableCell>
        </TableRow>
      );
    },
    onRowsDelete: (rowsDeleted, newData) => {
      HandleDelete(rowsDeleted.data);
    },
    searchPlaceholder: 'Pesquise um anexo',
    selectableRows: 'multiple',
    searchOpen: true,

    textLabels: {
      body: {
        noMatch: 'Desculpe, não encontramos anexos!',
        toolTip: 'Sort',
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
      pagination: {
        next: 'Avançar',
        previous: 'Voltar',
        rowsPerPage: 'Linhas por página:',
        displayRows: 'de',
      },
      toolbar: {
        search: 'Pesquisar',
        downloadCsv: 'Baixar CSV',
        print: 'Imprimir',
        viewColumns: 'Ver Colunas',
        filterTable: 'Filtrar tabela',
      },
      filter: {
        all: 'Tudo',
        title: 'FILTROS',
        reset: 'RESETAR',
      },
      viewColumns: {
        title: 'Exibir colunas',
        titleAria: 'Exibir/Ocultar colunas da tabela',
      },
      selectedRows: {
        text: 'Coluna(s) selecionada(s)',
        delete: 'Excluir',
        deleteAria: 'Excluir linhas selecionadas',
      },
    },
    customFooter: (
      count,
      page,
      rowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels,
    ) => {
      let numberPages = listBackupAttachments.data?.models.last_page;
      let rowsPerPageOptions = [15, 30, 100];
      return (
        <CustomFooter
          page={page}
          rowsPerPage={rowsPerPage}
          changeRowsPerPage={changeRowsPerPage}
          changePage={changePage}
          numberPages={numberPages}
          rowsPerPageOptions={rowsPerPageOptions}
        />
      );
    },
  };

  function renderDragMessage(isDragActive, isDragReject) {
    if (!isDragActive) {
      return <UploadMessage>Arraste os arquivos aqui</UploadMessage>;
    }
    if (isDragReject) {
      return <UploadMessage type="error">Arquivo não suportado</UploadMessage>;
    }

    return <UploadMessage type="success">Solte os arquivos aqui</UploadMessage>;
  }

  return (
    <>
      <Button variant="success" onClick={handleShow}>
        <Fontawesome classe={iconClass} /> {buttonLabel}
      </Button>

      <Modal
        show={show}
        size={modalSize}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Anexos de Equipamentos de Backup</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {<Loading /> && loader}
          <div className="customerStep">
            <Row>
              <Col>
                <div className="formStep">
                  <label class="important">Nome do anexo:</label>
                  <input
                    type="text"
                    name="name"
                    onBlur={(e) => setNameAttach(e.target.value)}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="formStep">
                  <label class="important">Anexo:</label>
                  <Dropzone
                    maxFiles="1"
                    accept="application/pdf, image/*"
                    onDrop={(acceptedFiles) => HandleUpload(acceptedFiles)}
                  >
                    {({
                      getRootProps,
                      getInputProps,
                      isDragActive,
                      isDragReject,
                    }) => (
                      <DropContainer
                        {...getRootProps()}
                        isDragActive={isDragActive}
                        isDragReject={isDragReject}
                      >
                        <input {...getInputProps()} />
                        {renderDragMessage(isDragActive, isDragReject)}
                      </DropContainer>
                    )}
                  </Dropzone>
                </div>
              </Col>
            </Row>
          </div>
          <MUIDataTable
            title={'Lista de Anexos do Equipamento de Backup'}
            data={
              !listBackupAttachments.data?.models.data
                ? []
                : listBackupAttachments.data?.models.data.filter(
                    (filtro) =>
                      filtro.id_equipament_backup == idEquipamentBackup,
                  )
            }
            columns={columns}
            options={options}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}
