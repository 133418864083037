import React, { useState, useContext } from 'react';
import Fontawesome from '../../fontawesome/fontawesome';
import './permission.css';
import TabSchemes from './tabSchemes/tabSchemes';
import { useFetch } from '../../hooks/useFetch';
import { Prefix } from '../../../services/prefix';
import Loading from '../../loader/loading';
import { Modal, Nav, Row, Col, Tab } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import { Context } from '../../../services/context'

const Permission = (props) => {
  const context = useContext(Context);
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    context.setAttachments(false);
  };
  const handleShow = () => setShow(true);

  const usuarios = useFetch(Prefix.replace(/[\\"]/g, '') + '/users/' + props.idUser,);

  if (!usuarios.data) return <Loading />;

  const schemes = usuarios.data?.model.schemes;

  return (
    <>
      <Link className="dropdown-item" to="#" onClick={handleShow}>
        <Fontawesome classe={props.iconClass} /> {props.buttonLabel}
      </Link>

      <Modal
        show={show}
        size={props.modalSize}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{props.titleLabel}</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row>
              <Col sm={3}>
                <Nav variant="pills" className="flex-column">
                  {schemes.map((sche, key) => {
                    return (
                      <>
                        <Nav.Item>
                          <Nav.Link
                            eventKey={key == 0 ? 'first' : key}
                            className="btn btn-primary mb-2 text-left btnPermissionCustom"
                          >
                            {sche.name}
                            <Fontawesome classe="fas fa-chevron-right mr-2" />
                          </Nav.Link>
                        </Nav.Item>
                      </>
                    );
                  })}
                </Nav>
              </Col>
              <Col sm={9}>
                <Tab.Content>
                  {schemes.map((sch, key) => {
                    return (
                      <>
                        <TabSchemes
                          idScheme={sch.id}
                          idUser={props.idUser}
                          eventKey={key == 0 ? 'first' : key}
                          nameScheme={sch.name}
                        />
                      </>
                    );
                  })}
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>

        </Modal.Body>
      </Modal>
    </>
  );
};
export default Permission;
