import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Button, Alert, Modal, Form } from 'react-bootstrap';
import Fontawesome from '../../fontawesome/fontawesome';
import { Prefix } from '../../../services/prefix';
import Select, { components } from 'react-select';
import api from '../../../services/api';
import Loading from '../../../components/loader/loading';
import Swal from 'sweetalert2';
import download from 'downloadjs';
import './styleOs.css';
import { AsyncPaginate } from 'react-select-async-paginate';
import { loadOptions } from '../../selectAsync/loadOptions';
import { loadOptionsStates } from './loadOptionsStates';
import { NoOptionsMessage } from '../../selectAsync/NoOptionsMessage';
import { ModalContainer } from "../../../style/stylesModals";
import {
  CustomButton,
  TextButton,
  ButtonDesabled,
} from "../../../style/stylesButton";

const GenerateOs = () => {
  const [loader, setLoader] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [formData, setFormData] = useState([]);
  const [valueSelect, setValueSelect] = useState([]);
  const [filterEquipment, setFilterEquipment] = useState(false);

  let dayName = new Array(
    'domingo',
    'segunda',
    'terça',
    'quarta',
    'quinta',
    'sexta',
    'sábado',
  );
  let monName = new Array(
    '',
    'janeiro',
    'fevereiro',
    'março',
    'abril',
    'maio',
    'junho',
    'agosto',
    'outubro',
    'novembro',
    'dezembro',
  );
  const now = new Date();

  let birthday =
    dayName[now.getDay()] +
    ', ' +
    now.getDate() +
    ' de ' +
    monName[now.getMonth()] +
    ' de ' +
    now.getFullYear();

  const filterEquipmentShow = () => {
    if (filterEquipment) {
      setFilterEquipment(false);
    } else {
      setFilterEquipment(true);
    }
  };

  const HandleSelect = (e, name) => {
    if (valueSelect.filter((fill) => fill.nameField == name).length > 0) {
      valueSelect
        .filter((fill) => fill.nameField == name)
        .map((map) => {
          let valueIndice = valueSelect.indexOf(map);
          valueSelect.splice(valueIndice);
        });

      setValueSelect((prev) => {
        return [...prev, { value: e.value, label: e.label, nameField: name }];
      });
      setFormData({ ...formData, [name]: e.value });
    } else {
      setValueSelect((prev) => {
        return [...prev, { value: e.value, label: e.label, nameField: name }];
      });
      setFormData({ ...formData, [name]: e.value });
    }
  };

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      padding: 0,
      height: '34px',
      borderColor: '#e2e5ec',
    }),
    input: (styles) => ({
      ...styles,
      paddingBottom: '0px',
      height: '34px',
      color: '#495057',
    }),
  };

  const HandleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.checked,
    });
  };

  const HandleChangeText = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value.trim(),
    });
  };

  function HandleRegister() {
    setLoader(true);
    api
      .post(Prefix.replace(/[\\"]/g, '') + '/os-export', formData, {
        responseType: 'blob',
      })
      .then((response) => {
        const content = response.headers['content-type'];
        download(
          response.data,
          'Relatorio de Ordem de Serviço em ' + birthday,
          content,
        );
        Swal.fire({
          icon: 'success',
          title: 'Uow...',
          text: 'Relatório gerado com sucesso!',
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: 'Fechar',
          cancelButtonText: 'Novo Relatório',
          allowOutsideClick: false,
        }).then((result) => {
          setLoader(false);
          if (!result.isConfirmed) {
            setShow(true);
          }
        });

        setShow(false);
        setFormData({});
      })
      .catch((error) =>
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Não existe Relatório de Ordem de Serviço para filtro aplicado',
          confirmButtonText: 'Tentar novamente',
        })
      );
      setLoader(false);
  }
  return (
    <>
      <Button variant="success" onClick={handleShow}>
        <Fontawesome classe="fas fa-file-pdf mr-3" />
        Gerar Relatório
      </Button>
      {!show ? (
        ''
      ) : (
        <ModalContainer
          show={show}
          size="md"
          onHide={handleClose}
          backdrop="static"
          keyboard={true}
        >
          <Modal.Header closeButton>
            <Modal.Title><h3>Relatório de Ordem de Serviço</h3></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {loader && <Loading />}
            <Alert variant="success">
              <form className="customerStep" onSubmit={HandleRegister}>
                <Row>
                  <Col>
                    <div className="formStep">
                      <label className="important">Cliente</label>
                      <AsyncPaginate
                        value={
                          valueSelect.length == 0
                            ? null
                            : valueSelect.map((map) => {
                              return {
                                value: map.value,
                                label: map.label,
                              };
                            })
                        }
                        loadOptions={loadOptions}
                        components={{ NoOptionsMessage }}
                        cacheUniqs={[valueSelect]}
                        onChange={(e) => HandleSelect(e, 'id_customer')} //values and name of select paginate
                        placeholder="Selecione Cliente..."
                        styles={colourStyles}
                        additional={{
                          page: 1,
                          endpoint: '/customers',
                        }}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="formStep">
                      <label className="important">Contrato</label>
                      <AsyncPaginate
                        value={valueSelect
                          .filter((fill) => fill.nameField == 'id_contract')
                          .map((map) => {
                            return {
                              value: map.value,
                              label: map.label,
                            };
                          })}
                        loadOptions={loadOptions}
                        onChange={(e) => HandleSelect(e, 'id_contract')} //values and name of select paginate
                        placeholder="Selecione um contrato..."
                        cacheUniqs={[
                          valueSelect.length == 0
                            ? ''
                            : valueSelect
                              .filter(
                                (fill) => fill.nameField == 'id_customer',
                              )
                              .map((map) => map),
                        ]} //Set your param that is going to change when you select another field. Just use this option when you are setting params and need to refresh cache.
                        components={{ NoOptionsMessage }}
                        styles={colourStyles}
                        additional={{
                          page: 1,
                          endpoint: '/contracts',
                          params: `id_customer=${valueSelect.length == 0
                              ? ''
                              : valueSelect
                                .filter(
                                  (fill) => fill.nameField == 'id_customer',
                                )
                                .map((map) => map.value)
                            }`, //Separete with & (and commercial)
                        }}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="formStep">
                      <label className="important">Estado</label>
                      <AsyncPaginate
                        value={valueSelect
                          .filter((fill) => fill.nameField == 'uf')
                          .map((map) => {
                            return {
                              value: map.value,
                              label: map.label,
                            };
                          })}
                        loadOptions={loadOptionsStates}
                        onChange={(e) => HandleSelect(e, 'uf')} //values and name of select paginate
                        placeholder="Selecione Estado..."
                        cacheUniqs={[valueSelect]} //Set your param that is going to change when you select another field. Just use this option when you are setting params and need to refresh cache.
                        components={{ NoOptionsMessage }}
                        styles={colourStyles}
                        additional={{
                          page: 1,
                          endpoint: '/cities',
                          params: `id_customer=${valueSelect
                            .filter((fill) => fill.nameField == 'id_customer')
                            .map((map) => map.value)}
                                                    &id_contract=${valueSelect
                              .filter(
                                (fill) =>
                                  fill.nameField ==
                                  'id_contract',
                              )
                              .map(
                                (map) => map.value,
                              )}`,
                          //Separete with & (and commercial)
                        }}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="formStep">
                      <label className="important">Cidade</label>
                      <AsyncPaginate
                        value={valueSelect
                          .filter((fill) => fill.nameField == 'id_city')
                          .map((map) => {
                            return {
                              value: map.value,
                              label: map.label,
                            };
                          })}
                        loadOptions={loadOptions}
                        onChange={(e) => HandleSelect(e, 'id_city')} //values and name of select paginate
                        placeholder="Selecione Estado..."
                        components={{ NoOptionsMessage }}
                        cacheUniqs={[valueSelect]} //Set your param that is going to change when you select another field. Just use this option when you are setting params and need to refresh cache.
                        styles={colourStyles}
                        additional={{
                          page: 1,
                          endpoint: '/cities',
                          params: `id_customer=${valueSelect
                            .filter((fill) => fill.nameField == 'id_customer')
                            .map((map) => map.value)}
                                                    &uf=${valueSelect
                              .filter(
                                (fill) =>
                                  fill.nameField ==
                                  'uf',
                              )
                              .map(
                                (map) => map.value,
                              )}`,
                          //Separete with & (and commercial)
                        }}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="formStep">
                      <label className="important">Unidade</label>
                      <AsyncPaginate
                        value={valueSelect
                          .filter((fill) => fill.nameField == 'id_unit')
                          .map((map) => {
                            return {
                              value: map.value,
                              label: map.label,
                            };
                          })}
                        loadOptions={loadOptions}
                        components={{ NoOptionsMessage }}
                        onChange={(e) => HandleSelect(e, 'id_unit')} //values and name of select paginate
                        placeholder="Selecione uma Unidade..."
                        cacheUniqs={[valueSelect]} //Set your param that is going to change when you select another field. Just use this option when you are setting params and need to refresh cache.
                        styles={colourStyles}
                        additional={{
                          page: 1,
                          endpoint: '/units',
                          params: `id_city=${valueSelect
                            .filter((fill) => fill.nameField == 'id_city')
                            .map((map) => map.value)}
                                                    &id_customer=${valueSelect
                              .filter(
                                (fill) =>
                                  fill.nameField ==
                                  'id_customer',
                              )
                              .map(
                                (map) => map.value,
                              )}`,
                          //Separete with & (and commercial)
                        }}
                      />
                    </div>
                  </Col>
                </Row>
                {!formData.id_unit ? (
                  ''
                ) : (
                  <>
                    <Row>
                      <Col>
                        <div className="formStep">
                          <label className="important">Setor</label>
                          <AsyncPaginate
                            value={valueSelect
                              .filter((fill) => fill.nameField == 'id_sector')
                              .map((map) => {
                                return {
                                  value: map.value,
                                  label: map.label,
                                };
                              })}
                            loadOptions={loadOptions}
                            components={{ NoOptionsMessage }}
                            onChange={(e) => HandleSelect(e, 'id_sector')} //values and name of select paginate
                            placeholder="Selecione um Setor..."
                            cacheUniqs={[valueSelect]} //Set your param that is going to change when you select another field. Just use this option when you are setting params and need to refresh cache.
                            styles={colourStyles}
                            additional={{
                              page: 1,
                              endpoint: '/sectors',
                              params: `id_unit=${valueSelect
                                .filter((fill) => fill.nameField == 'id_unit')
                                .map((map) => map.value)}`,
                              //Separete with & (and commercial)
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                  </>
                )}
                {!formData.id_sector ? (
                  ''
                ) : (
                  <>
                    <Row>
                      <Col>
                        <div className="formStep">
                          <label className="important">Sala</label>
                          <AsyncPaginate
                            value={valueSelect
                              .filter((fill) => fill.nameField == 'id_room')
                              .map((map) => {
                                return {
                                  value: map.value,
                                  label: map.label,
                                };
                              })}
                            loadOptions={loadOptions}
                            components={{ NoOptionsMessage }}
                            onChange={(e) => HandleSelect(e, 'id_room')} //values and name of select paginate
                            placeholder="Selecione uma Sala..."
                            cacheUniqs={[valueSelect]} //Set your param that is going to change when you select another field. Just use this option when you are setting params and need to refresh cache.
                            styles={colourStyles}
                            additional={{
                              page: 1,
                              endpoint: '/rooms',
                              params: `id_sector=${!valueSelect
                                  ? ''
                                  : valueSelect
                                    .filter(
                                      (fill) => fill.nameField == 'id_sector',
                                    )
                                    .map((map) => map.value)
                                }`,
                              //Separete with & (and commercial)
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                  </>
                )}
                {!filterEquipment ? (
                  <>
                    <Row className="d-flex justify-content-center">
                      <Button
                        variant="secondary mt-3 d-flex"
                        onClick={filterEquipmentShow}
                      >
                        {' '}
                        + Filtro por Equipamento
                      </Button>
                    </Row>
                  </>
                ) : (
                  <>
                    <Row className="d-flex justify-content-center">
                      <Button
                        variant="secondary mt-3 d-flex"
                        onClick={filterEquipmentShow}
                      >
                        {' '}
                        - Filtro por Equipamento
                      </Button>
                    </Row>
                    <Alert variant="secondary  mt-3">
                      <Row>
                        <Col>
                          <div className="formStep">
                            <label className="important">
                              Família de Equipamento
                            </label>
                            <AsyncPaginate
                              value={valueSelect
                                .filter((fill) => fill.nameField == 'id_family')
                                .map((map) => {
                                  return {
                                    value: map.value,
                                    label: map.label,
                                  };
                                })}
                              loadOptions={loadOptions}
                              components={{ NoOptionsMessage }}
                              onChange={(e) => HandleSelect(e, 'id_family')} //values and name of select paginate
                              placeholder="Selecione uma Família..."
                              cacheUniqs={[valueSelect]} //Set your param that is going to change when you select another field. Just use this option when you are setting params and need to refresh cache.
                              styles={colourStyles}
                              additional={{
                                page: 1,
                                endpoint: '/equipment-families',
                                params: `id_customer=${valueSelect
                                  .filter(
                                    (fill) => fill.nameField == 'id_customer',
                                  )
                                  .map((map) => map.value)}&
                                                                id_contract=${valueSelect
                                    .filter(
                                      (fill) =>
                                        fill.nameField ==
                                        'id_contract',
                                    )
                                    .map(
                                      (map) =>
                                        map.value,
                                    )}`,
                                //Separete with & (and commercial)
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="formStep">
                            <label className="important">
                              Categoria de Equipamento
                            </label>
                            <AsyncPaginate
                              value={valueSelect
                                .filter(
                                  (fill) =>
                                    fill.nameField == 'id_equipmentCategory',
                                )
                                .map((map) => {
                                  return {
                                    value: map.value,
                                    label: map.label,
                                  };
                                })}
                              loadOptions={loadOptions}
                              onChange={(e) =>
                                HandleSelect(e, 'id_equipmentCategory')
                              } //values and name of select paginate
                              placeholder="Selecione uma Categoria..."
                              cacheUniqs={[valueSelect]} //Set your param that is going to change when you select another field. Just use this option when you are setting params and need to refresh cache.
                              styles={colourStyles}
                              additional={{
                                page: 1,
                                endpoint: '/equipment-categories',
                                params: `id_customer=${valueSelect
                                  .filter(
                                    (fill) => fill.nameField == 'id_customer',
                                  )
                                  .map((map) => map.value)}&
                                                                id_contract=${valueSelect
                                    .filter(
                                      (fill) =>
                                        fill.nameField ==
                                        'id_contract',
                                    )
                                    .map(
                                      (map) =>
                                        map.value,
                                    )}&
                                                                id_equipment_family=${valueSelect
                                    .filter(
                                      (fill) =>
                                        fill.nameField ==
                                        'id_equipment_family',
                                    )
                                    .map(
                                      (map) =>
                                        map.value,
                                    )}`,
                                //Separete with & (and commercial)
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="formStep">
                            <label className="important">
                              Modelo de Equipamento
                            </label>
                            <AsyncPaginate
                              value={valueSelect
                                .filter((fill) => fill.nameField == 'id_model')
                                .map((map) => {
                                  return {
                                    value: map.value,
                                    label: map.label,
                                  };
                                })}
                              loadOptions={loadOptions}
                              onChange={(e) => HandleSelect(e, 'id_model')} //values and name of select paginate
                              placeholder="Selecione um Modelo..."
                              cacheUniqs={[valueSelect]} //Set your param that is going to change when you select another field. Just use this option when you are setting params and need to refresh cache.
                              styles={colourStyles}
                              additional={{
                                page: 1,
                                endpoint: '/equipment-models',
                                params: `id_customer=${valueSelect
                                  .filter(
                                    (fill) => fill.nameField == 'id_customer',
                                  )
                                  .map((map) => map.value)}&
                                                                id_contract=${valueSelect
                                    .filter(
                                      (fill) =>
                                        fill.nameField ==
                                        'id_contract',
                                    )
                                    .map(
                                      (map) =>
                                        map.value,
                                    )}&
                                                                id_equipment_category=${valueSelect
                                    .filter(
                                      (fill) =>
                                        fill.nameField ==
                                        'id_equipmentCategory',
                                    )
                                    .map(
                                      (map) =>
                                        map.value,
                                    )}`,
                                //Separete with & (and commercial)
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                    </Alert>
                  </>
                )}
                <Row>
                  <Col>
                    <div className="formStep mb-3">
                      <label class="important">Data de início</label>
                      <input
                        type="date"
                        name="start_date"
                        onChange={HandleChangeText}
                      />
                    </div>
                  </Col>
                  <Col>
                    <div className="formStep mb-3">
                      <label class="important">Data de término</label>
                      <input
                        type="date"
                        name="end_date"
                        onChange={HandleChangeText}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="custom-control custom-switch-div formStep switch-reports">
                      <Form.Check
                        type="switch"
                        name="type"
                        id={!formData ? '' : 'type' + formData.id}
                        label="Simplificado"
                        checked={!formData ? '' : Boolean(formData.type)}
                        onChange={HandleChange}
                      />
                    </div>
                  </Col>
                  <Col>
                    <div className="custom-control custom-switch-div formStep switch-reports">
                      <Form.Check
                        type="switch"
                        name="pagebreak"
                        id={!formData ? '' : 'pagebreak' + formData.id}
                        label="Quebra de Páginas"
                        checked={!formData ? '' : Boolean(formData.pagebreak)}
                        onChange={HandleChange}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Label>Observações:</Form.Label>
                      <Form.Control
                        type="text"
                        as="textarea"
                        rows={3}
                        name="obs"
                        onChange={HandleChangeText}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="d-flex justify-content-center">
                <CustomButton type='button' onClick={HandleRegister}>
                    <TextButton>Gerar Relatório</TextButton>
                  </CustomButton>
                </Row>
              </form>
            </Alert>
          </Modal.Body>
        </ModalContainer>
      )}
    </>
  );
};
export default GenerateOs;
