import React, { useContext } from 'react';
import api from '../../../services/api';
import { Prefix } from '../../../services/prefix';
import { mutate as globalMutate } from 'swr';
import { Context } from '../../../services/context';

export default function UpdateStatus({
  id,
  idUser,
  page,
  rowsPage,
  activeDefault,
  search,
  orderBy,
  order,
}) {
  const { viewEquipments } = useContext(Context);
  let pageExact = page + parseInt(1);

  function HandleStatus() {
    api
      .put(Prefix.replace(/[\\"]/g, '') + '/contract-notifications/' + id, {
        monitoring: activeDefault === 1 || activeDefault === true ? 0 : 1,
      })
      .then(() => {
        globalMutate(
          Prefix.replace(/[\\"]/g, '') +
            '/contract-notifications?per_page=' +
            rowsPage +
            '&page=' +
            pageExact +
            '&search=' +
            search +
            '&order_by=' +
            orderBy +
            '&order=' +
            order +
            '&id_contract_item=' +
            viewEquipments.idEquipment,
        );
      });
  }

  return (
    <>
      <label class="switch">
        <input
          type="checkbox"
          id={id + 'monitoring'}
          onChange={HandleStatus}
          checked={activeDefault === 1 || activeDefault === true ? true : false}
        />
        <span class="slider round"></span>
      </label>
    </>
  );
}
