export const colourStyles = {
  control: (styles) => ({
    ...styles,
    padding: 0,
    height: '34px',
    borderColor: '#e2e5ec',
    zindex: 7,
  }),
  input: (styles) => ({
    ...styles,
    paddingBottom: '0px',
    height: '34px',
    color: '#495057',
    zindex: 7,
  }),
};
export default {
  colourStyles,
};
