import React from 'react';
import Fontawesome from '../fontawesome/fontawesome';
import { NavLink } from 'react-router-dom';

const NavSismogran = ({ group }) => {
  return (
    <>
      <li className="slide-in-left">
        <NavLink
          exact
          activeClassName="active"
          className="slide-in-left"
          to="/monitoramento/inicio"
        >
          <Fontawesome classe="fas fa-tachometer-alt mr-3" />
          Visão Geral
        </NavLink>
      </li>
      <li className="slide-in-left">
        <NavLink
          exact
          activeClassName="active"
          className="slide-in-left"
          to="/monitoramento/alertas"
        >
          <Fontawesome classe="fa fa-bell mr-3" />
          Alertas
        </NavLink>
      </li>
      {group == 'customer' && (
        <li className="slide-in-left">
          <NavLink
            exact
            activeClassName="active"
            className="slide-in-left"
            to="/monitoramento/plano-acao"
          >
            <Fontawesome classe="fas fa-file-powerpoint mr-3" />
            Plano de Ação
          </NavLink>
        </li>
      )}
      {/* {group == 'client' && (
        <li className="slide-in-left">
          <NavLink
            exact
            activeClassName="active"
            className="slide-in-left"
            to="/monitoramento/faixas-de-monitoramento"
          >
            <Fontawesome classe="fas fa-chart-line mr-3" />
            Faixas de Coletores
          </NavLink>
        </li>
      )} */}
      {/* {group == 'customer' && (
        <li className="slide-in-left">
          <NavLink
            exact
            activeClassName="active"
            className="slide-in-left"
            to="/monitoramento/colaboradores-de-cliente"
          >
            <Fontawesome classe="fas fa-users mr-3" />
            Colaboradores
          </NavLink>
        </li>
      )} */}
      {/* {group == 'customer' && (
        <li className="slide-in-left">
          <NavLink
            exact
            activeClassName="active"
            className="slide-in-left"
            to="/monitoramento/notificacao"
          >
            <Fontawesome classe="far fa-comment-dots mr-3" />
            Notificações
          </NavLink>
        </li>
      )} */}
    </>
  );
};
export default NavSismogran;
