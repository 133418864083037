import React, { useState, useContext } from 'react';
import { Button, Modal, Row, Col } from 'react-bootstrap';
import Fontawesome from '../../fontawesome/fontawesome';
import { useFetch } from '../../hooks/useFetch';
import { Prefix } from '../../../services/prefix';
import api from '../../../services/api';
import Swal from 'sweetalert2';
import { mutate as globalMutate } from 'swr';
import Select from 'react-select';
import { Context } from '../../../services/context';
import { AsyncPaginate } from 'react-select-async-paginate';
import { loadOptions } from '../../selectAsync/loadOptions';
import { colourStyles } from '../../selectAsync/colourStyles';
import { services } from '../../data/services';

const AddOs = ({ buttonLabel, iconClass, pageExact, perPage, search }) => {
  const { redirect } = useContext(Context);
  const [show, setShow] = useState(false);
  const [technician, setTechnician] = useState([]);
  const [idOs, setIdOs] = useState(null);
  const [view, setView] = useState(0);
  const [valueSelect, setValueSelect] = useState([]);
  const [repeat, setRepeat] = useState([
    {
      id: 0,
      id_service: null,
      id_os_technician: null,
    },
  ]);
  const [filter, setFilter] = useState({
    id_client: '',
    id_contract: '',
    id_equipament_category: '',
    id_contract_item: 0,
  });
  const [formData, setFormData] = useState({
    id_contract_item: null,
    realized: 0,
    technician_third_company: null,
    active: 1,
  });

  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
  };

  const contractTechList = useFetch(
    Prefix.replace(/[\\"]/g, '') + '/contract-technicians',
  );

  const technicianList = useFetch(
    Prefix.replace(/[\\"]/g, '') + '/os-technicians?id_os=' + idOs,
  );

  const findContractItem = useFetch(
    Prefix.replace(/[\\"]/g, '') + '/contract-items/' + filter.id_contract_item,
  );
  const idEquipamentCategory = !findContractItem.data?.model
    ? ''
    : findContractItem.data?.model?.equipament_model?.id_equipament_category;

  const servicesList = useFetch(
    Prefix.replace(/[\\"]/g, '') +
    '/services?id_equipament_category=' +
    idEquipamentCategory,
  );

  const optionsContractTech = contractTechList.data?.models.map((map) => {
    return { value: map.id, label: map?.technician_client?.user?.name };
  });

  const optionsTechnicians = technicianList.data?.models.map((map) => {
    return { value: map.id, label: map.technician.technician_client.user.name };
  });

  const optionsServices = servicesList.data?.models.map((map) => {
    return { value: map.id, label: map.name };
  });

  const handleChangeTechnician = (e) => {
    setTechnician(e);
  };

  const handleChangeService = (e, id) => {
    let newArray = [...repeat];
    newArray[id] = { ...newArray[id], id_service: e.value };
    setRepeat(newArray);
  };

  const handleChangeTechOs = (e, id) => {
    let newArray = [...repeat];
    newArray[id] = { ...newArray[id], id_os_technician: e.value };
    setRepeat(newArray);
  };

  const HandleSelect = (e, name) => {
    if (valueSelect.filter((fill) => fill.nameField == name).length > 0) {
      valueSelect
        .filter((fill) => fill.nameField == name)
        .map((map) => {
          let valueIndice = valueSelect.indexOf(map);
          valueSelect.splice(valueIndice);
        });

      setValueSelect((prev) => {
        return [...prev, { value: e.value, label: e.label, nameField: name }];
      });
    } else {
      setValueSelect((prev) => {
        return [...prev, { value: e.value, label: e.label, nameField: name }];
      });
    }
  };

  const HandleSelectMulti = (e, name) => {
    if (!e) {
      setValueSelect(valueSelect.filter((fill) => fill.nameField != name));
    } else {
      const listData = e.map((map) => {
        return { value: map.value, label: map.label };
      });

      if (valueSelect.filter((fill) => fill.nameField === name).length > 0) {
        let indexArray = valueSelect.findIndex(
          (find) => find.nameField === name,
        );
        let newArray = [...valueSelect];
        newArray[indexArray] = {
          ...newArray[indexArray],
          value: listData,
          nameField: name,
        };
        setValueSelect(newArray);
      } else {
        setValueSelect(() => {
          return [...valueSelect, { value: listData, nameField: name }];
        });
      }
    }
  };

  function HandleRegister() {
    api
      .post(Prefix.replace(/[\\"]/g, '') + '/os', formData)
      .then((response) => {
        Swal.fire({
          icon: 'success',
          title: 'Uow...',
          text: response.data.message,
          confirmButtonText: 'Continuar',
        });
        setView(1);
        setIdOs(response.data.model.id);
        globalMutate(
          Prefix.replace(/[\\"]/g, '') +
          '/os?per_page=' +
          perPage +
          '&page=' +
          pageExact +
          '&search=' +
          search,
        );
      })
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: err.response.data.message,
          confirmButtonText: 'Tentar novamente',
        });
      });
  }

  function HandleRegisterServices() {
    setView(2);
    // repeat.map((map) => {
    //   let infoServices = {
    //     id_service: map.id_service,
    //     id_os_technician: map.id_os_technician,
    //   };
    //   api
    //     .post(Prefix.replace(/[\\"]/g, '') + '/os-services', infoServices)
    //     .then((response) => {
    //       Swal.fire({
    //         icon: 'success',
    //         title: 'Uow...',
    //         text: response.data.message,
    //         confirmButtonText: 'Fechar',
    //       });
    //       setShow(false);
    //       setView(1);
    //       setFilter({
    //         id_client: '',
    //         id_contract: '',
    //         id_equipament_category: '',
    //         id_contract_item: 0,
    //       });
    //       globalMutate(
    //         Prefix.replace(/[\\"]/g, '') +
    //           '/os?all=false&per_page=' +
    //           perPage +
    //           '&page=' +
    //           pageExact +
    //           '&search=' +
    //           search,
    //       );
    //     })
    //     .catch((err) => {
    //       Swal.fire({
    //         icon: 'error',
    //         title: 'Oops...',
    //         text: err.response.data.message,
    //         confirmButtonText: 'Tentar novamente',
    //       });
    //     });
    // });
  }

  function HandleRegisterTechnician() {
    valueSelect
      .filter((fill) => fill.nameField == 'id_technician_contract')[0]
      .value.map((map) => {
        let infoTech = {
          id_technician_contract: map.value,
          id_os: idOs,
        };
        api
          .post(Prefix.replace(/[\\"]/g, '') + '/os-technicians', infoTech)
          .then((response) => {
            Swal.fire({
              icon: 'success',
              title: 'Uow...',
              text: response.data.message,
              confirmButtonText: 'Continuar',
            });
            setView(0);
            technicianList.mutate();
            servicesList.mutate();
            globalMutate(
              Prefix.replace(/[\\"]/g, '') +
              '/os?per_page=' +
              perPage +
              '&page=' +
              pageExact +
              '&search=' +
              search,
            );
          })
          .catch((err) => {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: err.response.data.message,
              confirmButtonText: 'Tentar novamente',
            });
          });
      });
  }

  function HandleChange(e) {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  }

  function HandleBack() {
    setValueSelect([]);
  }

  function addService() {
    setRepeat((oldArray) => [
      ...oldArray,
      {
        id: oldArray.length,
        id_service: null,
        id_os_technician: null,
        finished_at: null,
      },
    ]);
  }

  function removeService(e) {
    setRepeat(repeat.filter((item) => item.id != e));
  }

  return (
    <>
      {show === false ? (
        <Button variant="success" onClick={handleShow}>
          <Fontawesome classe={iconClass} /> {buttonLabel}
        </Button>
      ) : (
        ''
      )}
      <Modal
        show={show}
        size={
          valueSelect.filter((fill) => fill.nameField == 'id_client').length ==
            0 ||
            valueSelect.filter((fill) => fill.nameField == 'id_contract')
              .length == 0
            ? 'sm'
            : 'lg'
        }
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Ordens de serviços</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="customerStep">
            {valueSelect.filter((fill) => fill.nameField == 'id_client')
              .length == 0 ||
              valueSelect.filter((fill) => fill.nameField == 'id_contract')
                .length == 0 ? (
              <>
                <Row>
                  <Col>
                    <label className="important">Cliente</label>
                    <AsyncPaginate
                      value={valueSelect
                        .filter((fill) => fill.nameField == 'id_client')
                        .map((map) => {
                          return {
                            value: map.value,
                            label: map.label,
                          };
                        })}
                      loadOptions={loadOptions}
                      onChange={(e) => HandleSelect(e, 'id_client')} //values and name of select paginate
                      placeholder="Selecione..."
                      cacheUniqs={[valueSelect]} //Set your param that is going to change when you select another field. Just use this option when you are setting params and need to refresh cache.
                      styles={colourStyles}
                      additional={{
                        page: 1,
                        endpoint: '/clients',
                        // params: `uf=${values.uf}`, //Separete with & (and commercial)
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label className="important mt-2">Contrato</label>
                    <AsyncPaginate
                      value={valueSelect
                        .filter((fill) => fill.nameField == 'id_contract')
                        .map((map) => {
                          return {
                            value: map.value,
                            label: map.label,
                          };
                        })}
                      loadOptions={loadOptions}
                      onChange={(e) => HandleSelect(e, 'id_contract')} //values and name of select paginate
                      placeholder="Selecione..."
                      cacheUniqs={[valueSelect]} //Set your param that is going to change when you select another field. Just use this option when you are setting params and need to refresh cache.
                      styles={colourStyles}
                      additional={{
                        page: 1,
                        endpoint: '/contracts',
                        params: `id_client=${valueSelect.length == 0
                            ? ''
                            : valueSelect
                              .filter((fill) => fill.nameField == 'id_client')
                              .map((map) => map.value)
                          }`, //Separete with & (and commercial)
                      }}
                    />
                  </Col>
                </Row>
              </>
            ) : view == 0 ? (
              <>
                <Row>
                  <Col>
                    <label class="important">Agendamento para</label>
                    <input
                      type="datetime-local"
                      name="scheduled_at"
                      className="form-control"
                      onChange={HandleChange}
                    />
                  </Col>
                  <Col>
                    <label class="important">Urgência</label>
                    <select
                      name="urgency"
                      className="form-control"
                      onChange={HandleChange}
                    >
                      <option disabled selected>
                        Selecione a urgência...
                      </option>
                      <option value="Baixa">Baixa</option>
                      <option value="Média">Média</option>
                      <option value="Alta">Alta</option>
                      <option value="Muito Alta">Muito Alta</option>
                    </select>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <label>Observação</label>
                    <textarea
                      className="form-control"
                      name="obs"
                      onKeyUp={HandleChange}
                    ></textarea>
                  </Col>
                </Row>
                <Row>
                  <Col className="d-flex justify-content-center mt-4">
                    <Button
                      variant="success"
                      onClick={HandleBack}
                      className="mr-3"
                    >
                      Voltar
                    </Button>
                    <Button variant="success" onClick={HandleRegister}>
                      Avançar
                    </Button>
                  </Col>
                </Row>
              </>
            ) : view == 1 ? (
              <>
                <Row>
                  <Col className="d-flex justify-content-center mt-4 mb-4">
                    <h2>
                      Quais serão os serviços e equipamentos desta OS?{' '}
                      <Button
                        variant="success"
                        className="ml-4"
                        onClick={addService}
                      >
                        Adicionar
                      </Button>
                    </h2>
                  </Col>
                </Row>

                {repeat.map((map, key) => {
                  return (
                    <Row key={key} className="mb-3">
                      <Col md={6}>
                        {/* <Select
                          options={optionsServices}
                          placeholder="Selecione um serviço..."
                          onChange={(e) => handleChangeService(e, key)}
                        /> */}
                        <Select
                          value={valueSelect
                            .filter((fill) => fill.nameField == 'id_service')
                            .map((map) => {
                              return {
                                value: map.value,
                                label: map.label,
                              };
                            })}
                          isMulti
                          styles={colourStyles}
                          onChange={(e) => HandleSelect(e, 'id_service')}
                          placeholder="Selecione um serviço..."
                          options={services.map((state) => {
                            return { value: state.value, label: state.value };
                          })}
                        />
                      </Col>
                      <Col md={key == 0 ? 6 : 5}>
                        <AsyncPaginate
                          value={valueSelect
                            .filter(
                              (fill) => fill.nameField == 'id_contract_item',
                            )
                            .map((map) => {
                              return {
                                value: map.value,
                                label: map.label,
                              };
                            })}
                          loadOptions={loadOptions}
                          onChange={(e) => HandleSelect(e, 'id_contract_item')} //values and name of select paginate
                          placeholder="Selecione..."
                          cacheUniqs={[valueSelect]} //Set your param that is going to change when you select another field. Just use this option when you are setting params and need to refresh cache.
                          styles={colourStyles}
                          additional={{
                            page: 1,
                            endpoint: '/contract-items',
                            params: `id_contract=${valueSelect.length == 0
                                ? ''
                                : valueSelect
                                  .filter(
                                    (fill) => fill.nameField == 'id_contract',
                                  )
                                  .map((map) => map.value)
                              }`, //Separete with & (and commercial)
                            paramName: 'nickname',
                          }}
                        />
                      </Col>
                      {key == 0 ? (
                        ''
                      ) : (
                        <Col md={1}>
                          <Button
                            variant="danger"
                            onClick={() => removeService(key)}
                          >
                            X
                          </Button>
                        </Col>
                      )}
                    </Row>
                  );
                })}
                <Row>
                  <Col className="d-flex justify-content-center mt-4">
                    <Button variant="success" onClick={HandleRegisterServices}>
                      Avançar
                    </Button>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row>
                  <Col className="d-flex justify-content-center mt-4 mb-4">
                    <h2>Quais técnicos irão realizar a OS?</h2>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <AsyncPaginate
                      value={
                        valueSelect.filter(
                          (fill) => fill.nameField == 'id_technician_contract',
                        ).length == 0
                          ? null
                          : valueSelect
                            .filter(
                              (fill) =>
                                fill.nameField == 'id_technician_contract',
                            )[0]
                            .value.map((map) => {
                              return {
                                value: map.value,
                                label: map.label,
                              };
                            })
                      }
                      loadOptions={loadOptions}
                      isMulti
                      cacheUniqs={[
                        valueSelect
                          .filter(
                            (fill) =>
                              fill.nameField == 'id_technician_contract',
                          )
                          .map((map) => map.label),
                      ]}
                      onChange={(e) =>
                        HandleSelectMulti(e, 'id_technician_contract')
                      } //values and name of select paginate
                      placeholder="Selecione o(s) técnico(s)..."
                      styles={colourStyles}
                      additional={{
                        page: 1,
                        endpoint: '/contract-technicians',
                        params: `id_contract=${valueSelect
                          .filter((fill) => fill.nameField == 'id_contract')
                          .map((map) => map.value)}`,
                        paramName: 'technician_client.user.name',
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="d-flex justify-content-center mt-4">
                    <Button
                      variant="success"
                      onClick={HandleRegisterTechnician}
                    >
                      Finalizar
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default AddOs;
