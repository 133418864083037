export const colourStyles = {
  placeholder: (styles) => ({
    ...styles,
    color: '#ffffff',
    fontSize: '1rem',
  }),
  control: (styles) => ({
    ...styles,
    padding: 0,
    height: "45px",
    backgroundColor: "rgba(253, 253, 253, 0.2)",
    borderRadius: "60px",
    border: "0",
    color: "#ffffff",
  }),
  input: (styles) => ({
    ...styles,
    padding: 0,
    height: "45px",
    borderRadius: "60px",
    color: "#ffffff",
    marginLeft: '10px',
  }),
  menu: (styles) => ({
    ...styles,
    width: "100%",
    borderBottom: "0",
    color: "#000000",
    fontSize: '1rem'
  }),
  singleValue: (styles) => ({
    ...styles,
    marginTop: "15px",
    zIndex: 99,
    color: "#ffffff",
    marginLeft: "15px",
    fontSize: '1rem'
  }),
};
export default {
  colourStyles,
};

// export const colourStyles = {
//   options: (styles) => ({
//     ...styles,
//     backgroundColor: "#000000",
//   }),
//   control: (styles) => ({
//     ...styles,
//     padding: 0,
//     height: "45px",
//     backgroundColor: "rgba(253, 253, 253, 0.2)",
//     borderRadius: "60px",
//     border: "0",
//   }),
//   input: (styles) => ({
//     ...styles,
//     padding: 0,
//     height: "45px",
//     borderRadius: "60px",
//     color: "#000000",
//   }),
//   menu: (styles) => ({
//     ...styles,
//     width: "100%",
//     borderBottom: "0",
//     color: "#000000",
//   }),
//   singleValue: (styles) => ({
//     ...styles,
//     marginTop: "15px",
//     zIndex: 99,
//     color: "#e0caca",
//     marginLeft: "15px",
//   }),
// };
// export default {
//   colourStyles,
// };
