import React from 'react';
import IndexEditCity from '../../components/cities/editcities/indexEditCity';

export const columns = [
  {
    name: 'id',
    label: 'id',
    options: {
      filter: false,
      sort: false,
      display: 'excluded',
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
    },
  },
  {
    name: 'name',
    label: 'Cidade',
    options: {
      filter: false,
      sort: true,
      setCellHeaderProps: () => ({
        style: {
          display: "flex",
          justifyContent: "center",

        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
    },
  },
  
  {
    name: 'uf',
    label: 'Estado',
    options: {
      filter: false,
      sort: false,
      display:true,
      setCellHeaderProps: () => ({
        style: {
          textAlign: 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          textAlign: 'center',
        },
      }),
    },
  },

  {
    name: 'id',
    label: 'Editar',
    options: {
      filter: false,
      sort: false,
      print: false,
      setCellHeaderProps: () => ({
        style: {
          textAlign: 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          textAlign: 'center',
        },
      }),
      customBodyRender: (value, tableMeta) => {
        return (
          <IndexEditCity
            id={value}
            page={tableMeta.tableState.page}
            rowsPage={tableMeta.tableState.rowsPerPage}
            search={tableMeta.tableState.searchText}
            orderBy={tableMeta.tableState.sortOrder.name}
            order={tableMeta.tableState.sortOrder.direction}
          />
        );
      },
    },
  },
];
