import React, { useState, Component } from "react";
import { Card, Row, Col, Table } from "react-bootstrap";
import Topbar from "../../components/topbar/topbar";
import Sidebar from "../../components/sidebar/sidebar";
import Fontawesome from "../../components/fontawesome/fontawesome";
import { useFetch } from "../../components/hooks/useFetch";
import { Prefix } from "../../services/prefix";
import CustomSearchRender from "../../components/customSearchRender/CustomSearchRender";
import Loading from "../../components/loader/loading";
import api from "../../services/api";
import Swal from "sweetalert2";
import { columns } from "./columns";
import CustomFooter from "../../components/CustomFooter/CustomFooter";
import MUIDataTable from "mui-datatables";
import { TableCell, TableRow } from "@material-ui/core";
import { Teste } from "./teste";
import Content from "../../components/bases/addbases/addbases";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { GetMuiTheme } from "../../components/muiTheme/muiTheme";
import {
  CardSt,
  CardHeaderSt,
  IconTitleSt,
  TitleSt,
  TextTitleSt,
  SmallTitleSt,
} from "../../style/globalStyle";
import {mutate as GlobalMutate} from 'swr';

const Bases = () => {
  const [loader, setLoader] = useState();
  const [pagina, setPagina] = useState(0);
  const [perPage, setPerPage] = useState(15);
  const [search, setSearch] = useState("");
  const [orderBy, setOrderBy] = useState("id");
  const [order, setOrder] = useState("desc");

  let pageExact = pagina + parseInt(1);

  const basesList = useFetch(
    Prefix.replace(/[\\"]/g, "") +
      "/bases?per_page=" +
      perPage +
      "&page=" +
      pageExact +
      "&search=" +
      search +
      "&order_by=" +
      orderBy +
      "&order=" +
      order
  ); //Faz query params com per_page, page e search

  const options = {
    filterType: "dropdown",
    draggableColumns: {
      enabled: true,
    },
    sortOrder:{ 
      name: orderBy, 
      direction: order, 
    },
    selectableRows: true,
    filter: false,
    serverSide: true,
    onTableChange: (action, tableState) => {
      setOrderBy(
        !tableState.sortOrder.name ? orderBy : tableState.sortOrder.name,
      );
      setOrder(
        !tableState.sortOrder.direction
          ? order
          : tableState.sortOrder.direction,
      );
      setPagina(tableState.page);
      setPerPage(tableState.rowsPerPage);
    },
    onSearchChange: (searchText) => {
      setSearch(!searchText ? "" : searchText); //faz condição para string vazia exibir vazio no lugar de null ou undefined
    },
    customSearchRender: (searchText, handleSearch, hideSearch, options) => {
      return (
        <CustomSearchRender
          placeholderSearch="Pesquisar uma Base..."
          searchText={searchText}
          search={search}
          onSearch={handleSearch}
          onHide={hideSearch}
          options={options}
        />
      );
    },
    filter: false,
    page: pagina,
    count: Teste.data?.total, //Exibe o total vindo da constante cityList
    expandableRows: true,
    expandableRowsHeader: false,
    expandableRowsOnClick: false,
    rowsPerPage: perPage,
    rowsPerPageOptions: [15, 30, 100],
    isRowExpandable: (dataIndex, expandedRows) => {
      // if (dataIndex === 3 || dataIndex === 4) return false;
      if (
        expandedRows.data.length > 4 &&
        expandedRows.data.filter((d) => d.dataIndex === dataIndex).length === 0
      )
        return false;
      return true;
    },
    renderExpandableRow: (rowData, rowMeta) => {
      const colSpan = rowData.length + 1;
      return (
        <TableRow>
          <TableCell colSpan={colSpan}>
            <Row>
              <Col className="text-center">
                <h4>Dados da Base</h4>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Endereço:</th>
                      <th>Nº:</th>
                      <th>Bairro:</th>
                      <th>Estado:</th>
                      <th>Cidade:</th>
                      <th>Celular:</th>
                      <th>Telefone:</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td> {rowData[2]}</td>
                      <td> {rowData[3]}</td>
                      <td> {rowData[4]}</td>
                      <td> {rowData[5]}</td>
                      <td> {rowData[6]}</td>
                      <td> {rowData[7]}</td>
                      <td> {rowData[8]}</td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </TableCell>
        </TableRow>
      );
    },

    onRowsDelete: (rowsDeleted, newData) => {
      HandleDelete(rowsDeleted.data);
    },
    searchPlaceholder: "Pesquise uma Base",
    selectableRows: "multiple",
    searchOpen: true,

    textLabels: {
      body: {
        noMatch: "Desculpe, não encontramos nenhuma base!",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
      pagination: {
        next: "Avançar",
        previous: "Voltar",
        rowsPerPage: "Linhas por página:",
        displayRows: "de",
      },

      toolbar: {
        search: "Pesquisar",
        downloadCsv: "Baixar CSV",
        print: "Imprimir",
        viewColumns: "Ver Colunas",
        filterTable: "Filtrar tabela",
      },

      filter: {
        all: "Tudo",
        title: "FILTROS",
      },
      viewColumns: {
        title: "Exibir colunas",
        titleAria: "Exibir/Ocultar colunas da tabela",
      },
      selectedRows: {
        text: "Coluna(s) selecionada(s)",
        delete: "Excluir",
        deleteAria: "Excluir linhas selecionadas",
      },
    },
    customFooter: (
      count,
      page,
      rowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels
    ) => {
      let numberPages = Teste.data?.last_page;
      let rowsPerPageOptions = [15, 30, 100];
      return (
        <CustomFooter
          page={page}
          rowsPerPage={rowsPerPage}
          changeRowsPerPage={changeRowsPerPage}
          changePage={changePage}
          numberPages={numberPages}
          rowsPerPageOptions={rowsPerPageOptions}
        />
      );
    },
  };

  function HandleDelete(e) {
    setLoader(<Loading />);
    let idArr = [];

    e.map((del) => {
      idArr.push(
        basesList.data?.models?.data.filter(
          //Lista dados de cityList e filtra os que serão removidos
          (filtro, index) => index == del.index
        )[0].id
      );
    });

    idArr.map(
      (
        idDel //Faz exclusão com map um a um para evitar bugs
      ) =>
        api
          .delete(Prefix.replace(/[\\"]/g, "") + "/bases/" + idDel)
          .then((response) => {
            Swal.fire({
              icon: "success",
              title: "Uow!",
              text: response.data.message,
              confirmButtonText: "Fechar",
            });
            setLoader();
            basesList.mutate();
            GlobalMutate(
              Prefix.replace(/[\\"]/g, "") +
              "/bases?per_page=" +
              perPage +
              "&page=" +
              pageExact +
              "&search=" +
              search +
              "&order_by=" +
              orderBy +
              "&order=" +
              order
          );
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: err.response.data.message,
              confirmButtonText: "Tentar novamente",
            });
            setLoader();
          })
    );
  }

  return (
    <>
      <div className="wrapper d-flex align-items-stretch">
        <Sidebar />
        <div id="content">
          <Topbar />
          <div className="content_all">
            <CardSt>
              <CardHeaderSt>
                <TitleSt className="d-flex justify-content-between">
                  <div className="d-flex justify-content-between">
                    <IconTitleSt src="../../../media/icons/base.svg" />
                    <TextTitleSt>
                      Bases <SmallTitleSt>Gestão de Bases</SmallTitleSt>
                    </TextTitleSt>
                  </div>
                  <Content
                    titleLabel="Cadastro de Bases"
                    buttonLabel="Adicionar"
                    iconClass="fas fa-plus-circle"
                    pageExact={pageExact}
                    perPage={perPage}
                    search={search}
                  />
                </TitleSt>
              </CardHeaderSt>
              <CardSt.Body as="div">
                <CardSt.Text as="div">
                  <MuiThemeProvider theme={GetMuiTheme()}>
                    <MUIDataTable
                      data={basesList.data?.models.data} //Exibe a lista conforme cityList
                      columns={columns}
                      options={options}
                    />
                  </MuiThemeProvider>
                </CardSt.Text>
              </CardSt.Body>
            </CardSt>
            <Content
              titleLabel="Cadastro de Bases"
              buttonLabel="Adicionar"
              iconClass="fas fa-plus-circle"
              pageExact={pageExact}
              perPage={perPage}
              search={search}
              orderBy={orderBy}
              order={order}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default Bases;
