import React, { useState, useEffect } from "react";
import { Row, Col, Button, Alert, Modal } from "react-bootstrap";
import Fontawesome from "../../fontawesome/fontawesome";
import { Prefix } from "../../../services/prefix";
import { useFetch } from "../../hooks/useFetch";
import Select, { components } from "react-select";
import api from "../../../services/api";
import Loading from "../../loader/loading";
import Swal from "sweetalert2";
import download from "downloadjs";
import "./teste.css";
import { AsyncPaginate } from "react-select-async-paginate";
import { loadOptions } from "../../selectAsync/loadOptions";
import { loadOptionsStates } from "./loadOptionsStates";
import { NoOptionsMessage } from "../../selectAsync/NoOptionsMessage";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Schema from "./schema";
import { colourStyles } from "../../selectAsync/colourStyles";
import Switch from "@material-ui/core/Switch";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { ModalContainer } from "../../../style/stylesModals";
import {
  CustomButton,
  TextButton,
  ButtonDesabled,
} from "../../../style/stylesButton";

const GenerateUserLog = () => {
  const [loader, setLoader] = useState();
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setValueSelect([]);
    setValueSelectMulti([]);
    setRegisterData({
      pagebreak: false,
      obs: "",
      id_user: "",
      id_log: [],
      date: "",
      action: "",
    });
  };
  const handleShow = () => setShow(true);
  const [valueSelect, setValueSelect] = useState([]);
  const [valueSelectMulti, setValueSelectMulti] = useState([]);
  const [registerData, setRegisterData] = useState({
    pagebreak: false,
    obs: "",
    id_user: "",
    id_log: [],
    date: "",
    action: "",
  });

  let dayName = new Array(
    "domingo",
    "segunda",
    "terça",
    "quarta",
    "quinta",
    "sexta",
    "sábado"
  );
  let monName = new Array(
    "janeiro",
    "fevereiro",
    "março",
    "abril",
    "maio",
    "junho",
    "agosto",
    "outubro",
    "novembro",
    "dezembro"
  );
  const now = new Date();

  let birthday =
    dayName[now.getDay()] +
    ", " +
    now.getDate() +
    " de " +
    monName[now.getMonth()] +
    " de " +
    now.getFullYear();

  const actionList = [
    { value: "created", label: "Criação" },
    { value: "updated", label: "Atualização" },
    { value: "deleted", label: "Exclusão" },
  ];

  const toggleChecked = (name) => {
    setRegisterData({
      ...registerData,
      [name]: eval("!registerData?." + name) ? true : false,
    });
  };

  const HandleSelectMulti = (e, name) => {
    let updateId = [];
    if (!e) {
      setValueSelect([]);
    } else {
      if (valueSelectMulti.length > e.length) {
        setValueSelectMulti(
          e.map((map) => {
            return { value: map.value, label: map.label, nameField: name };
          })
        );
        e.map((map) => {
          updateId.push(map.value);
        });
        setRegisterData({ ...registerData, [name]: updateId });
      } else {
        e.map((map) => {
          //inicio do map
          updateId.push(map.value);
          if (valueSelectMulti.length > 0) {
            // se a contagem do valueselect maior que 0
            setValueSelectMulti(() => {
              return [
                ...valueSelectMulti,
                { value: map.value, label: map.label, nameField: name },
              ];
            });
          } else {
            setValueSelectMulti(() => {
              return [{ value: map.value, label: map.label, nameField: name }];
            });
            setRegisterData({ ...registerData, [name]: [map.value] });
          }
          setRegisterData({ ...registerData, [name]: updateId });
        });
      }
    }
  };

  const HandleSelect = (e, name) => {
    if (valueSelect.filter((fill) => fill.nameField == name).length > 0) {
      valueSelect
        .filter((fill) => fill.nameField == name)
        .map((map) => {
          let valueIndice = valueSelect.indexOf(map);
          valueSelect.splice(valueIndice);
        });

      setValueSelect((prev) => {
        return [...prev, { value: e.value, label: e.label, nameField: name }];
      });
      setRegisterData({ ...registerData, [name]: e.value });
    } else {
      setValueSelect((prev) => {
        return [...prev, { value: e.value, label: e.label, nameField: name }];
      });
      setRegisterData({ ...registerData, [name]: e.value });
    }
  };

  const HandleChange = (e) => {
    let eventName = e.target.name;
    let eventValue = e.target.value;
    setRegisterData({
      ...registerData,
      [eventName]: eventValue,
    });
  };

  function HandleRegister(registerData) {
    setLoader(true);
    api
      .post(Prefix.replace(/[\\"]/g, "") + "/logs-export", registerData, {
        responseType: "blob",
      })
      .then((response) => {
        setLoader();
        const content = response.headers["content-type"];
        download(
          response.data,
          "Relatorio de Históricos em " + birthday,
          content
        );
        Swal.fire({
          icon: "success",
          title: "Uow...",
          text: "Download realizado com sucesso!",
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: "Fechar",
          cancelButtonText: "Novo Relatório",
          allowOutsideClick: false,
        }).then((result) => {
          if (!result.isConfirmed) {
            setShow(false);
          }
        });
        setLoader(false);
        setShow(false);
        setValueSelect([]);
        setValueSelectMulti([]);
        setRegisterData({
          pagebreak: false,
          obs: "",
          id_user: "",
          id_log: [],
          date: "",
          action: "",
        });
      })
      .catch(
        (error) =>
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error,
            confirmButtonText: "Tentar novamente",
          }),
        setLoader(false)
      );
  }

  return (
    <>
      <Button variant="success" onClick={handleShow}>
        <Fontawesome classe="fas fa-file-pdf mr-3" />
        Gerar Relatório
      </Button>
      {!show ? (
        ""
      ) : (
        <ModalContainer
          show={show}
          size="md"
          onHide={handleClose}
          backdrop="static"
          keyboard={true}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <h3>Relatório de Históricos<br/> de Usuários</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik //--> Configuração do Formik
              initialValues={registerData}
              validationSchema={Schema}
              onSubmit={HandleRegister}
              enableReinitialize
            >
              {(
                { values, setFieldValue } //--> Captação de valores
              ) => (
                <Form>
                  <Alert variant="success">
                    <AsyncPaginate
                      value={valueSelect
                        .filter((fill) => fill.nameField == "id_user")
                        .map((map) => {
                          return {
                            value: map.value,
                            label: map.label,
                          };
                        })}
                      loadOptions={loadOptions}
                      components={{ NoOptionsMessage }}
                      cacheUniqs={[valueSelect]}
                      onChange={(e) => HandleSelect(e, "id_user")} //values and name of select paginate
                      placeholder="Selecione Usuário..."
                      styles={colourStyles}
                      additional={{
                        page: 1,
                        endpoint: "/users",
                      }}
                    />
                    <ErrorMessage
                      name="id_user"
                      component="span"
                      className="errorMessages"
                    />
                    <Row>
                      <Col>
                        <div
                          className="formStep py-3"
                          style={{ display: "absolute" }}
                        >
                          <Select
                            placeholder="Selecione uma ação..."
                            name="action"
                            options={actionList}
                            components={{ NoOptionsMessage }}
                            styles={colourStyles}
                            onChange={(e) => HandleSelect(e, "action")}
                          />
                          <ErrorMessage
                            name="action"
                            component="span"
                            className="errorMessages"
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <AsyncPaginate
                          value={valueSelectMulti
                            .filter((fill) => fill.nameField == "id_log")
                            .map((map) => {
                              return {
                                value: map.value,
                                label: map.label,
                              };
                            })}
                          loadOptions={loadOptions}
                          isMulti
                          components={{ NoOptionsMessage }}
                          cacheUniqs={[valueSelect]}
                          onChange={(e) => HandleSelectMulti(e, "id_log")} //values and name of select paginate
                          placeholder="Selecione Histórico(s)..."
                          styles={colourStyles}
                          additional={{
                            page: 1,
                            endpoint: "/logs",
                            params: `id_user=${
                              !registerData?.id_user
                                ? ""
                                : registerData?.id_user
                            }&action=${
                              !registerData?.action ? "" : registerData?.action
                            }`,
                            paramName: "id",
                            paramId: "id",
                          }}
                        />
                        <ErrorMessage
                          name="id_log"
                          component="span"
                          className="errorMessages"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="formStep mb-3">
                          <label class="important">Data</label>
                          <Field
                            type="date"
                            name="date"
                            onBlur={HandleChange}
                          />
                          <ErrorMessage
                            name="date"
                            component="span"
                            className="errorMessages"
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="d-flex justify-content-center">
                        <div className="custom-control custom-switch-div formStep switch-reports">
                          <label>Quebra de Página</label>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Switch
                                  color="secondary"
                                  size="small"
                                  checked={registerData.pagebreak}
                                  onChange={() => toggleChecked("pagebreak")}
                                />
                              }
                            />
                          </FormGroup>
                          <ErrorMessage
                            name="pagebreak"
                            component="span"
                            className="errorMessages"
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <label class="important">Obs:</label>
                        <Field
                          type="text"
                          as="textarea"
                          rows={5}
                          name="obs"
                          onBlur={HandleChange}
                        />
                      </Col>
                    </Row>
                    <Row className="d-flex justify-content-center">
                      <CustomButton type="submit">
                        <TextButton>Gerar Relatório</TextButton>
                      </CustomButton>
                    </Row>
                  </Alert>
                </Form>
              )}
            </Formik>
          </Modal.Body>
        </ModalContainer>
      )}
    </>
  );
};
export default GenerateUserLog;
