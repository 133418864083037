import React from 'react';
import { Link } from 'react-router-dom';
import { IconOption } from '../optionsProposalsStyle';
import api from '../../../services/api';
import { Prefix } from '../../../services/prefix';
import Swal from 'sweetalert2';

const Duplicate = ({ id }) => {
  const Duplicate = () => {
    api
      .get(Prefix.replace(/[\\"]/g, '') + '/proposal/replicate/' + id)
      .then((r) => {
        Swal.fire({
          icon: 'success',
          title: 'Uow!',
          text: 'Proposta duplicada com sucesso!',
          confirmButtonText: 'Fechar',
        });
      });
  };

  return (
    <>
      <Link className="dropdown-item" to="#" onClick={Duplicate}>
        <IconOption src="../../../media/icons/duplicate.svg" />
        Duplicar
      </Link>
    </>
  );
};
export default Duplicate;
