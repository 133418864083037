import React from 'react';
import Attachments from '../attachments/attachments';
import EditEquipments from '../editEquipments/editEquipments';
import UpdateStatus from '../updateStatus/updateStatus';
import Tracks from '../tracks/tracks';
import Types from '../types/types';

export const columns = [
  {
    //0
    name: 'id',
    label: 'Id',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      display: 'excluded',
    },
  },
  {
    //1
    name: 'monitoring',
    label: 'Tipo',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      customBodyRender: (value, tableMeta) => {
        return <Types value={value} />;
      },
    },
  },
  {
    //2
    name: 'equipment_model',
    label: 'Equipamento / Marca / Modelo / Família',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      customBodyRender: (value, tableMeta) => {
        return (
          value?.equipment_category?.name +
          ' / ' +
          value?.equipment_brand?.name +
          ' / ' +
          value?.name +
          ' / ' +
          value?.equipment_category?.equipment_family?.name
        );
      },
    },
  },
  {
    //3
    name: 'serial',
    label: 'Serial',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: true,
      sort: false,
    },
  },

  {
    //4
    name: 'nickname',
    label: 'Apelido',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: true,
      sort: false,
    },
  },

  {
    //5
    name: 'id_contract',
    label: 'id_contract',
    options: {
      filter: true,
      sort: false,
      display: 'excluded',
    },
  },

  {
    //6
    name: 'id',
    label: 'Anexos',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
          width: '5%',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      print: false,
      customBodyRender: (value, tableMeta) => {
        return <Attachments idEquipment={value} />;
      },
    },
  },
  {
    //7
    name: 'id_equipment_model',
    label: 'Faixas',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
          width: '5%',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      print: false,
      customBodyRender: (value, tableMeta) => {
        return (
          <Tracks
            idEquipment={tableMeta.rowData[0]}
            idEquipmentModel={value}
            monitoring={tableMeta.rowData[1]}
          />
        );
      },
    },
  },

  {
    //8
    name: 'active',
    label: 'Status',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
          width: '5%',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      print: false,
      customBodyRender: (value, tableMeta) => {
        return (
          <UpdateStatus
            idEquipment={tableMeta.rowData[0]}
            idContract={tableMeta.rowData[5]}
            active={value}
            orderBy={tableMeta.tableState.sortOrder.name}
            order={tableMeta.tableState.sortOrder.direction}
            page={tableMeta.tableState.page}
            rowsPage={tableMeta.tableState.rowsPerPage}
            search={
              !tableMeta.tableState.searchText
                ? ''
                : tableMeta.tableState.searchText
            }
          />
        );
      },
    },
  },

  {
    //9
    name: 'contract',
    label: 'Editar',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
          width: '5%',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      print: false,
      customBodyRender: (value, tableMeta) => {
        return (
          <EditEquipments
            idEquipment={tableMeta.rowData[0]}
            idCustomer={value.id_customer}
            idContract={value.id}
            page={tableMeta.tableState.page}
            rowsPage={tableMeta.tableState.rowsPerPage}
            search={
              !tableMeta.tableState.searchText
                ? ''
                : tableMeta.tableState.searchText
            }
            orderBy={tableMeta.tableState.sortOrder.name}
            order={tableMeta.tableState.sortOrder.direction}
          />
        );
      },
    },
  },
];
