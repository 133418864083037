import React from 'react';
import api from '../../../services/api';
import { Prefix } from '../../../services/prefix';
import { mutate as globalMutate } from 'swr';

export default function UpdateStatus({ id, page, rowsPage, activeDefault, search, orderBy, order }) {
  let pageExact = page + parseInt(1);

  function HandleStatus() {
    api
      .put(Prefix.replace(/[\\"]/g, '') + '/equipment-models/' + id, {
        active: Boolean(activeDefault === true ? false : true),
      })
      .then(() => {
        globalMutate(
          Prefix.replace(/[\\"]/g, '') +
            '/equipment-models?per_page=' +
            rowsPage +
            '&page=' +
            pageExact +
            '&search=' + search + '&order_by=' + orderBy + '&order=' + order,
        );
      });
  }

  return (
    <>
      <label class="switch">
        <input
          type="checkbox"
          id={id + 'equipament-models'}
          onChange={HandleStatus}
          checked={activeDefault}
        />
        <span class="slider round"></span>
      </label>
    </>
  );
}
