import React from 'react';
import UpdateStatus from '../updateStatus/updateManagers';

export const columns = [
  {
    name: 'id',
    label: '',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      display: 'excluded',
    },
  },

  {
    name: 'employee',
    label: 'Nome',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      customBodyRender: (value, tableMeta) => {
        return !value ? '' : value.name;
      },
    },
  },

  {
    name: 'attribuition',
    label: 'Observação',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      customBodyRender: (value, tableMeta) => {
        return !value ? '' : value;
      },
    },
  },

  {
    name: 'active',
    label: 'Status',
    options: {
      print: false,
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellHeaderProps: () => ({
        style: {
          width: '10%',
        },
      }),
      filter: true,
      sort: false,
      customBodyRender: (value, tableMeta) => {
        return <UpdateStatus 
        activeDefault={value} 
        id={tableMeta.rowData[0]} 
        page={tableMeta.tableState.page}
        perPage={tableMeta.tableState.rowsPerPage}
        search={!tableMeta.tableState.searchText ? '' : tableMeta.tableState.searchText}/>;
      },
    },
  },
];
