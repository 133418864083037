import React, { useState } from 'react';
import Sidebar from '../../components/sidebar/sidebar';
import Topbar from '../../components/topbar/topbar';
import {
  CardSt,
  CardHeaderSt,
  IconTitleSt,
  TitleSt,
  TextTitleSt,
  SmallTitleSt,
} from '../../style/globalStyle';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { GetMuiTheme } from '../../components/muiTheme/muiTheme';
import MUIDataTable from 'mui-datatables';
import { TableCell, TableRow } from '@material-ui/core';
import CustomFooter from '../../components/CustomFooter/CustomFooter';
import CustomSearchRender from '../../components/customSearchRender/CustomSearchRender';
import { PageItem } from 'react-bootstrap';
import { columns } from './columns';
import { useFetch } from '../../components/hooks/useFetch';
import { Prefix } from '../../services/prefix';
import {
  TableThSt,
  TableTrSt,
  ColSt,
  BoldSt,
  RegularSt,
  TagStatusSt,
  TableThStClient,
  BoxTableClient,
  TableTrStClient,
} from './proposalsStyles';
import OptionsProposals from '../../components/proposals/optionsProposals';
import '../../style/global.css';
import AddProposalRequest from '../../components/proposals/addProposalRequest/addProposalRequest';
import Swal from 'sweetalert2';
import api from '../../services/api';
import moment from 'moment';
import ClipBoard from './clipboard';

const Proposals = () => {
  const [loader, setLoader] = useState();
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(15);
  const [search, setSearch] = useState('');

  let pageExact = PageItem + parseInt(1);

  const proposalList = useFetch(
    Prefix.replace(/[\\"]/g, '') +
      '/proposal-requests?per_page=' +
      perPage +
      '&page=' +
      pageExact +
      '&search=' +
      search,
  );

  proposalList.mutate();

  const options = {
    filterType: 'dropdown',
    draggableColumns: {
      enabled: true,
    },
    selectableRows: true,
    filter: false,
    serverSide: true,
    onTableChange: (action, tableState) => {
      setPage(tableState.page);
      setPerPage(tableState.rowsPerPage);
    },
    onSearchChange: (searchText) => {
      setSearch(!searchText ? '' : searchText);
    },
    customSearchRender: (searchText, handleSearch, hideSearch, options) => {
      return (
        <CustomSearchRender
          placeholderSearch="Pesquisar uma proposta..."
          searchText={searchText}
          search={search}
          onSearch={handleSearch}
          onHide={hideSearch}
          options={options}
        />
      );
    },
    filter: false,
    page: page,
    count: 100, //Exibe o total vindo da constante cityList
    expandableRows: true,
    expandableRowsHeader: false,
    expandableRowsOnClick: false,
    rowsPerPage: perPage,
    rowsPerPageOptions: [15, 30, 100],
    renderExpandableRow: (rowData, rowMeta) => {
      const colSpan = rowData.length + 1;
      const widthCol = 95 / 4;
      let count = parseInt(rowData[7].length) + 1;
      return (
        <>
          <TableRow>
            <BoxTableClient colSpan={colSpan}>
              <TableThStClient>
                <ColSt widthCol={widthCol}>CLIENTE</ColSt>
                <ColSt widthCol={widthCol}>CNPJ</ColSt>
                <ColSt widthCol={widthCol}>TELEFONE</ColSt>
                <ColSt widthCol={widthCol}>REGIÃO</ColSt>
                <ColSt widthCol="5"></ColSt>
              </TableThStClient>
              <TableTrStClient>
                <ColSt widthCol={widthCol}>
                  <BoldSt>{rowData[9]}</BoldSt>
                </ColSt>
                <ColSt widthCol={widthCol}>
                  <BoldSt>{rowData[10]}</BoldSt>
                </ColSt>
                <ColSt widthCol={widthCol}>
                  <BoldSt>{rowData[13]}</BoldSt>
                </ColSt>
                <ColSt widthCol={widthCol}>
                  <BoldSt>{rowData[14]}</BoldSt>
                </ColSt>
              </TableTrStClient>
            </BoxTableClient>
          </TableRow>

          <TableRow>
            <TableCell colSpan={colSpan}>
              <TableThSt>
                <ColSt widthCol={widthCol}>PROPOSTA</ColSt>
                <ColSt widthCol={widthCol}>STATUS</ColSt>
                <ColSt widthCol={widthCol}>COTAÇÃO</ColSt>
                <ColSt widthCol={widthCol}>COMPARATIVO</ColSt>
                <ColSt widthCol="5"></ColSt>
              </TableThSt>
              {rowData[7].map((map, key) => {
                count -= 1;
                return (
                  <TableTrSt>
                    <ColSt widthCol={widthCol}>
                      <BoldSt>
                        <strong>Proposta</strong> {count}
                      </BoldSt>
                      <RegularSt>
                        <strong>Abertura:</strong>{' '}
                        {moment(map.started_at).format('DD/MM/YYYY')}
                      </RegularSt>
                    </ColSt>
                    <ColSt widthCol={widthCol}>
                      <BoldSt>
                        <TagStatusSt status={map.active}>
                          <i
                            class="fas fa-circle"
                            style={{ fontSize: '7px', marginRight: '5px' }}
                          ></i>{' '}
                          {!map.active ? 'Reprovado' : 'Aprovado'}
                        </TagStatusSt>
                      </BoldSt>
                      <RegularSt>
                        <strong>Por:</strong> Francisco Alves
                      </RegularSt>
                    </ColSt>
                    <ColSt widthCol={widthCol}>
                      <BoldSt>
                        <strong>Total:</strong> R$ 10.000,00
                      </BoldSt>
                      <RegularSt>
                        <strong>Equipamentos:</strong> 3000
                      </RegularSt>
                    </ColSt>
                    <ColSt widthCol={widthCol}>
                      <BoldSt>
                        <strong>Total:</strong> R$ 5.000,00
                      </BoldSt>
                      <RegularSt>
                        <strong>Equipamentos:</strong> 1000
                      </RegularSt>
                    </ColSt>
                    <ColSt widthCol="5">
                      <OptionsProposals id={map.id} count={count} idRequest={rowData[8]}/>
                    </ColSt>
                  </TableTrSt>
                );
              })}
            </TableCell>
          </TableRow>
        </>
      );
    },

    onRowsDelete: (rowsDeleted, newData) => {
      HandleDelete(rowsDeleted.data);
    },
    searchPlaceholder: 'Pesquise uma Base',
    selectableRows: 'multiple',
    searchOpen: true,

    textLabels: {
      body: {
        noMatch: 'Desculpe, não encontramos nenhuma base!',
        toolTip: 'Sort',
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
      pagination: {
        next: 'Avançar',
        previous: 'Voltar',
        rowsPerPage: 'Linhas por página:',
        displayRows: 'de',
      },

      toolbar: {
        search: 'Pesquisar',
        downloadCsv: 'Baixar CSV',
        print: 'Imprimir',
        viewColumns: 'Ver Colunas',
        filterTable: 'Filtrar tabela',
      },

      filter: {
        all: 'Tudo',
        title: 'FILTROS',
      },
      viewColumns: {
        title: 'Exibir colunas',
        titleAria: 'Exibir/Ocultar colunas da tabela',
      },
      selectedRows: {
        text: 'Coluna(s) selecionada(s)',
        delete: 'Excluir',
        deleteAria: 'Excluir linhas selecionadas',
      },
    },
    customFooter: (
      count,
      page,
      rowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels,
    ) => {
      let numberPages = 100;
      let rowsPerPageOptions = [15, 30, 100];
      return (
        <CustomFooter
          page={page}
          rowsPerPage={rowsPerPage}
          changeRowsPerPage={changeRowsPerPage}
          changePage={changePage}
          numberPages={numberPages}
          rowsPerPageOptions={rowsPerPageOptions}
        />
      );
    },
  };

  const HandleDelete = (e) => {
    let idArr = [];

    e.map((del, key) => {
      idArr.push(
        proposalList.data?.models.data.filter(
          (filtro, index) => index == del.index,
        )[0].id,
      );
    });

    idArr.map((idDel) =>
      api
        .delete(Prefix.replace(/[\\"]/g, '') + '/proposal-requests/' + idDel)
        .then((response) => {
          Swal.fire({
            icon: 'success',
            title: 'Uow!',
            text: response.data.message,
            confirmButtonText: 'Fechar',
          });
          proposalList.mutate();
        })
        .catch((err) => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: err.response.data.message,
            confirmButtonText: 'Tentar novamente',
          });
        }),
    );
  };

  return (
    <div className="wrapper d-flex align-items-stretch">
      <Sidebar />
      <div id="content">
        <Topbar />
        <div className="content_all">
          <AddProposalRequest
            titleLabel="Cadastro de Solicitações de Propostas"
            modalSize="md"
            pageExact={pageExact}
            perPage={perPage}
            search={!search ? '' : search}
          />
          <CardSt>
            <CardHeaderSt className="d-flex justify-content-between">
              <TitleSt>
                <IconTitleSt src="../../../media/icons/proposal.png" />
                <TextTitleSt>
                  Propostas{' '}
                  <SmallTitleSt>
                    Lista de processos e propostas recebidos
                  </SmallTitleSt>
                </TextTitleSt>
              </TitleSt>
              <ClipBoard/>
            </CardHeaderSt>
            <CardSt.Body as="div">
              <CardSt.Text as="div">
                <MuiThemeProvider theme={GetMuiTheme()}>
                  <MUIDataTable
                    data={proposalList.data?.models.data} //Exibe a lista conforme cityList
                    columns={columns}
                    options={options}
                  />
                </MuiThemeProvider>
              </CardSt.Text>
            </CardSt.Body>
          </CardSt>
        </div>
      </div>
    </div>
  );
};
export default Proposals;
