import React from 'react';
import Notification from '../../components/topbar/notification/notification';

export const columns = [
  {
    name: 'id',
    label: 'id',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      display: false,
    },
  },
  {
    name: 'description',
    label: 'Notificação',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
    },
  },
  {
    name: 'readed_at',
    label: 'Data',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      customBodyRender: (value, tableMeta) => {
        if (!value) {
        } else {
          return value.split('-').reverse().join('/');
        }
      },
    },
  },
  {
    //16
    name: 'readed',
    label: 'Lida',
    options: {
      filter: true,
      sort: false,
      print: false,
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
          width: '10%',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      customBodyRender: (value, tableMeta) => {
        return (
          <>
            {value === true ?
              <img src="/media/icons/confirm-check.png" width='24px' />
              :
              <img src="/media/icons/confirm-nocheck.png" width='20px' />
            }
          </>
        )
      },
    },
  },
  {
    //16
    name: '',
    label: 'notification',
    options: {
      filter: true,
      sort: false,
      print: false,
      display: 'excluded',
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
          width: '10%',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      customBodyRender: (value, tableMeta) => {
        return (
          <Notification
            page={tableMeta.tableState.page}
            rowsPage={tableMeta.tableState.rowsPerPage}
            search={tableMeta.tableState.searchText}
          />
        );
      },
    },
  },
];
