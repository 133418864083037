import React from 'react';
import { Prefix } from '../../../services/prefix';
import { useFetch } from '../../../components/hooks/useFetch';
import './images.css';

const Image = ({ idModel, widthSize, heightSize }) => {
  const images = useFetch(
    Prefix.replace(/[\\"]/g, '') +
      '/equipment-images?id_equipment_model=' +
      idModel,
  );

  const state = !images.data ? [] : images.data?.models?.data;
  const linkImage =
    state.length == 0 ? '../../media/icons/no-image.jpg' : state[0].path;

  return (
    <div
      className="img-device-model"
      style={{
        backgroundImage: 'url(' + linkImage + ')',
        width: widthSize,
        height: heightSize,
      }}
    ></div>
  );
};
export default Image;
