import { filter } from 'lodash';
import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Alert, Modal, Form } from 'react-bootstrap';
import Fontawesome from '../../fontawesome/fontawesome';
import { Prefix } from '../../../services/prefix';
import { useFetch } from '../../hooks/useFetch';
import Select, { components } from 'react-select';
import { cities } from '../../../components/data/city';
import mutate from 'swr';
import api from '../../../services/api';
import Loading from '../../loader/loading';
import Swal from 'sweetalert2';
import download from 'downloadjs';
import './styleContracts.css';
import { AsyncPaginate } from 'react-select-async-paginate';
import { NoOptionsMessage } from '../../selectAsync/NoOptionsMessage';
import { ModalContainer } from "../../../style/stylesModals";
import {
  CustomButton,
  TextButton,
  ButtonDesabled,
} from "../../../style/stylesButton";

const GenerateContracts = () => {
  const [loader, setLoader] = useState();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [formData, setFormData] = useState([]);

  let dayName = new Array(
    'domingo',
    'segunda',
    'terça',
    'quarta',
    'quinta',
    'sexta',
    'sábado',
  );
  let monName = new Array(
    'janeiro',
    'fevereiro',
    'março',
    'abril',
    'maio',
    'junho',
    'agosto',
    'outubro',
    'novembro',
    'dezembro',
  );
  const now = new Date();

  let birthday =
    dayName[now.getDay()] +
    ', ' +
    now.getDate() +
    ' de ' +
    monName[now.getMonth()] +
    ' de ' +
    now.getFullYear();

  const clients = useFetch(Prefix.replace(/[\\"]/g, '') + '/customers');

  const clientsList = clients.data?.models?.data.map((map) => {
    return { value: map.id, label: map.name };
  });

  const contracts = useFetch(Prefix.replace(/[\\"]/g, '') + '/contracts');

  const contractList = contracts.data?.models?.data
    .filter((filtro) => filtro.id_customer == formData.customer)
    .map((map) => {
      return { value: map.id, label: map.name };
    });

  const handleChangeClients = (e) => {
    setFormData({
      ...formData,
      customer: e.value,
    });
  };

  const handleChangeContracts = (e) => {
    if (!e || e.length == 0) {
    } else {
      let ArrId = [];
      e.map((map) => {
        ArrId.push(map.value);
      });
      setFormData({
        ...formData,
        id: ArrId,
      });
    }
  };

  const HandleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.checked,
    });
  };

  const HandleChangeText = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value.trim(),
    });
  };

  function HandleRegister() {
    setLoader(<Loading />);
    api
      .post(Prefix.replace(/[\\"]/g, '') + '/contracts-export', formData, {
        responseType: 'blob',
      })
      .then((response) => {
        setLoader();
        const content = response.headers['content-type'];
        download(
          response.data,
          'Relatorio de Contrato(s) em ' + birthday,
          content,
        );
        Swal.fire({
          icon: 'success',
          title: 'Uow...',
          text: 'Download realizado com sucesso!',
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: 'Fechar',
          cancelButtonText: 'Novo Relatório',
          allowOutsideClick: false,
        }).then((result) => {
          if (!result.isConfirmed) {
            setShow(true);
          }
        });
        setLoader();
        setShow(false);
        setFormData({});
      })
      .catch(
        (error) =>
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error,
            confirmButtonText: 'Tentar novamente',
          }),
        setLoader(),
      );
  }

  return (
    <>
      <Button variant="success" onClick={handleShow}>
        <Fontawesome classe="fas fa-file-pdf mr-3" />
        Gerar Relatório
      </Button>
      {!show ? (
        ''
      ) : (
        <ModalContainer
          show={show}
          size="md"
          onHide={handleClose}
          backdrop="static"
          keyboard={true}
        >
          <Modal.Header closeButton>
            <Modal.Title><h3>Relatório de Contrato(s)</h3></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Alert variant="success">
              <form className="customerStep" onSubmit={HandleRegister}>
                <Row>
                  <Col>
                    <div className="formStep py-3">
                      <AsyncPaginate
                        className="basic-single"
                        components={{ NoOptionsMessage }}
                        classNamePrefix="select"
                        closeMenuOnSelect={false}
                        placeholder="Selecione o Cliente..."
                        name="customer"
                        options={clientsList}
                        onChange={handleChangeClients}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="formStep py-3">
                      <Select
                        closeMenuOnSelect={false}
                        placeholder="Selecione Contrato(s)..."
                        isMulti
                        name="id"
                        options={contractList}
                        onChange={handleChangeContracts}
                        components={{ NoOptionsMessage }}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="custom-control custom-switch-div formStep switch-reports">
                      <Form.Check
                        type="switch"
                        name="contractsItens"
                        id={!formData ? '' : 'contractsItens' + formData.id}
                        label="Equipamentos"
                        checked={
                          !formData ? '' : Boolean(formData?.contractsItens)
                        }
                        onChange={HandleChange}
                        className="switch-reports"
                      />
                    </div>
                  </Col>
                  <Col>
                    <div className="custom-control custom-switch-div formStep switch-reports">
                      <Form.Check
                        type="switch"
                        name="pagebreak"
                        id={!formData ? '' : 'pagebreak' + formData.id}
                        label="Quebra de Páginas"
                        checked={!formData ? '' : Boolean(formData?.pagebreak)}
                        onChange={HandleChange}
                        className="switch-reports"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Label>Observações:</Form.Label>
                      <Form.Control
                        type="text"
                        as="textarea"
                        rows={3}
                        name="obs"
                        onChange={HandleChangeText}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="d-flex justify-content-center">
                <CustomButton type='button' onClick={HandleRegister}>
                    <TextButton>Gerar Relatório</TextButton>
                  </CustomButton>
                </Row>
              </form>
            </Alert>
          </Modal.Body>
        </ModalContainer>
      )}
    </>
  );
};
export default GenerateContracts;
