import styled from 'styled-components';

export const Button = styled.button`
  width: 20%;
  height: 40px;
  border-radius: 100px;
  background: #852b2b;
  color: #fff;
  border: 0;
`;
