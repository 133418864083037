import React, { useState } from "react";
import { Row, Col, Button, Modal } from "react-bootstrap";
import "../contracts.css";
import { Prefix } from "../../../services/prefix";
import Loading from "../../loader/loading";
import Swal from "sweetalert2";
import Fontawesome from "../../fontawesome/fontawesome";
import { mutate as GlobalMutate } from "swr";
import api from "../../../services/api";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Schema from "./schema";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import { useStyles } from "../../buttons/AddButtonStyle";
import { ModalContainer } from "../../../style/stylesModals"; 
import { CustomButton, TextButton } from "../../../style/stylesButton";

const IndexAddContractType = (props) => {
  const [loader, setLoader] = useState();
  const [show, setShow] = useState(false);
  const [initialState, setInitialState] = useState({
    //--> estado recebendo parametros de envio de post.
    name: "",
    active: 1,
  });
  const [registerData, setRegisterData] = useState(initialState); //--> Estado recebendo State com paramentro

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function HandleRegister(values) {
    setLoader(true);
    api
      .post(Prefix.replace(/[\\"]/g, "") + "/contract-types", values)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Uow!",
          text: response.data.message,
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: "Fechar",
          cancelButtonText: "Novo Cadastro",
          allowOutsideClick: false,
        }).then((result) => {
          if (!result.isConfirmed) {
            setShow(true);
          }
        });
        setLoader(false);
        setShow(false);
        GlobalMutate(
          Prefix.replace(/[\\"]/g, "") +
            "/contract-types?per_page=" +
            props.perPage +
            "&page=" +
            props.pageExact +
            "&search=" +
            "&order_by=" +
            props.orderBy +
            "&order=" +
            props.order
        );
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: err.response.data.message,
          confirmButtonText: "Tentar novamente",
        });
        setLoader(false);
      });
  }

  const classes = useStyles();

  return (
    <>
      {show === false ? (
        <Fab className={classes.fab} onClick={handleShow}>
          <AddIcon className={classes.icon} />
        </Fab>
      ) : (
        ""
      )}
      <ModalContainer
        show={show}
        size={props.modalSize}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title><h3>{props.titleLabel}</h3></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik //--> Configuração do Formik
            initialValues={initialState}
            validationSchema={Schema}
            onSubmit={HandleRegister}
          >
            {(
              { values } //--> Captação de valores
            ) => (
              <Form>
                <div className="customerStep">
                  {loader}
                  <Row>
                    <Col>
                      <div className="formStep">
                        <div className="labelForm">
                          <label class="important">
                            Nome do tipo de contrato
                          </label>
                        </div>
                        <Field
                          type="text"
                          placeholder="Digite um tipo de contrato"
                          name="name"
                        />
                        <ErrorMessage
                          name="name"
                          component="span"
                          className="errorMessages"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="d-flex justify-content-center">
                      <CustomButton  type="submit">
                       <TextButton>Cadastrar</TextButton>  
                      </CustomButton>
                    </Col>
                  </Row>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </ModalContainer>
    </>
  );
};
export default IndexAddContractType;
