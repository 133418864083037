import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../../../public/media/icons/elipse_options.svg';
import CitiesBases from './citiesBases/citiesBases';
import EditBases from './editbases/editbases';
import TechniciansBases from './techniciansBase/techniciansBases';
import './optionsStyle.css';

const OptionsBases = (props) => {
  return (
    <>
      <div className="dropdown drop-bases">
        <Link
          to="#"
          className="dropdown link_color_datatable"
          id="dropdownNotification"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <img src={`/media/icons/elipse_options.svg`} width="5" />
        </Link>
        <ul
          className="dropdown-menu fade-in-top drop-options "
          aria-labelledby="dropdownNotification"
        >
          <li>
            <EditBases
              iconClass="fas fa-key mr-2"
              id={props.id}
              page={props.page}
              rowsPage={props.rowsPage}
              search={props.search}
              orderBy={props.orderBy}
              order={props.order}
            />
          </li>
          <li>
            <CitiesBases iconClass="fas fa-key mr-2" 
             id={props.id}
             />
          </li>
          <li>
            <TechniciansBases iconClass="fas fa-key mr-2" 
             id={props.id}
            />
          </li>
        </ul>
      </div>
    </>
  );
};
export default OptionsBases;
