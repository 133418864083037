import React from 'react';
import { maskTel, MaskTel } from '../../../services/mask'
import OptionNotificationView from '../../components/notificationView/optionsNotificationView'

export const columns = [
  { //0
    name: 'id',
    label: 'Id',
    options: {
      filter: false,
      sort: false,
      display: 'excluded',
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
    },
  },
  {//1
    name: 'user',
    label: 'Nome',
    options: {
      filter: false,
      sort: false,

      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      customBodyRender: (value, tableMeta) => {
        return value.name;
      },
    },
  },
  {//2
    name: 'user',
    label: 'Nome',
    options: {
      filter: false,
      sort: false,

      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      customBodyRender: (value, tableMeta) => {
        return value.email;
      },
    },
  },
  {//3
    name: 'user',
    label: 'Plataforma',
    options: {
      filter: false,
      sort: false,
      display: false,
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      customBodyRender: (value, tableMeta) => {
        return value;
      },
    },
  },
  {//04
    name: '',
    label: 'user.coletor',
    options: {
      filter: false,
      sort: false,
      display: false,
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      customBodyRender: (value, tableMeta) => {

        return value
      },
    },
  },

  // {
  //   name: 'identification',
  //   label: 'Contato',
  //   options: {
  //     filter: false,
  //     sort: false,
  //     setCellHeaderProps: () => ({
  //       style: {
  //         'text-align': 'center',
  //       },
  //     }),
  //     setCellProps: () => ({
  //       style: {
  //         'text-align': 'center',
  //       },
  //     }),
  //     customBodyRender: (value, tableMeta) => {
  //       return maskTel(value);
  //     },
  //   },
  // },
  // {
  //   name: 'contract_item',
  //   label: 'Equipamento',
  //   options: {
  //     filter: false,
  //     sort: false,
  //     setCellHeaderProps: () => ({
  //       style: {
  //         'text-align': 'center',
  //       },
  //     }),
  //     setCellProps: () => ({
  //       style: {
  //         'text-align': 'center',
  //       },
  //     }),
  //     customBodyRender: (value, tableMeta) => {
  //       return value.nickname;
  //     },
  //   },
  // },
  // {
  //   name: 'contract_item',
  //   label: 'Serial',
  //   options: {
  //     filter: false,
  //     sort: false,
  //     setCellHeaderProps: () => ({
  //       style: {
  //         'text-align': 'center',
  //       },
  //     }),
  //     setCellProps: () => ({
  //       style: {
  //         'text-align': 'center',
  //       },
  //     }),
  //     customBodyRender: (value, tableMeta) => {
  //       return value.serial;
  //     },
  //   },
  // },

];