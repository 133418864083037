import React from 'react';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from 'react-google-maps';
import { keyMap } from './keyMaps';

const GoogleMaps = ({ latitude, longitude }) => {
  const MyMapComponent = withScriptjs(
    withGoogleMap((props) => (
      <GoogleMap
        defaultZoom={15}
        defaultCenter={{ lat: latitude, lng: longitude }}
      >
        {props.isMarkerShown && (
          <Marker position={{ lat: latitude, lng: longitude }} />
        )}
      </GoogleMap>
    )),
  );

  const googleMapUrl =
    'https://maps.googleapis.com/maps/api/js?key=' +
    keyMap +
    '&v=3.exp&libraries=geometry,drawing,places';

  return (
    <MyMapComponent
      isMarkerShown
      googleMapURL={googleMapUrl}
      loadingElement={<div style={{ height: `100%` }} />}
      containerElement={<div style={{ height: `400px` }} />}
      mapElement={<div style={{ height: `100%` }} />}
    />
  );
};

export default GoogleMaps;
