import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { IconOption } from '../optionsProposalsStyle';
import { Modal, Col, Row, Button } from 'react-bootstrap';

const ModalRejectProposal = (props) => {
  const [show, setShow] = useState(false);
  const [registerData, setRegisterData] = useState({
    description: '',
  });

  const handleShow = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  const HandleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setRegisterData({ ...registerData, [name]: value });
  };

  return (
    <>
      {show === false ? (
        <Button
          style={{
            marginLeft: '2px',
            backgroundColor: 'transparent',
            color: '#ffffff',
            borderColor: '#ffffff',
            borderRadius: '30px',
            fontSize: '10px',
            width: '150px',
            height: '40px',
          }}
          onClick={handleShow}
        >
          {props.buttonLabel}
        </Button>
      ) : (
        ''
      )}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h2
            style={{
              textAlign: 'center',
              fontWeight: 'bold',
              color: '#000000',
            }}
          >
            Não gostou da proposta?
          </h2>
          <p style={{ textAlign: 'center', color: '#000000' }}>
            Diga para nós o motivo de ter recusado a proposta que faremos o
            melhor para poder lhe ajudar...
          </p>
          <textarea
            style={{
              height: '80px',
              textAlign: 'center',
              backgroundColor: '#e3e3e3',
              borderColor: '#e3e3e3',
              color: '#000000',
              borderRadius: '5px',
            }}
            type="description"
            placeholder="Digite o motivo"
            name="description"
            onChange={HandleChange}
          />
        </Modal.Body>
        <div style={{ textAlign: 'center' }}>
          <Button
            style={{
              marginLeft: '2px',
              backgroundColor: '#782e2e',
              color: '#ffffff',
              borderColor: '#782e2e',
              borderRadius: '30px',
              fontSize: '10px',
              width: '150px',
              height: '40px',
            }}
            variant="success"
            type="submit"
          >
            ENVIAR
          </Button>
        </div>
        <br />
      </Modal>
    </>
  );
};
export default ModalRejectProposal;
