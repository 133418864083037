import React from 'react';
import {PhoneValidation} from '../../services/mask'

export const columns = [
  {
    name: 'id',
    label: 'id',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      display: 'excluded',
    },
  },
  {
    name: 'created_at',
    label: 'Data',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
    },
  },
  {
    name: 'name',
    label: 'Nome',
    options: {
      setCellHeaderProps: () => ({
        style: {
          display: "flex", 
          justifyContent: "center", 
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: true,
    },
  },
  {
    name: 'email',
    label: 'E-mail',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
    },
  },
  {
    name: 'phone',
    label: 'Telefone',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      customBodyRender: (value, tableMeta) => {
        return PhoneValidation(value)
      },
    },
  },
];
