import React, { useState } from 'react';
import { Col, Row, Alert, Modal, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { IconOption } from '../../optionsProposalsStyle';
import { mutate as GlobalMutate } from 'swr';
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import { Schema } from './schema';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { GetMuiTheme } from '../../../../components/muiTheme/muiTheme';
import api from '../../../../services/api';
import { Prefix } from '../../../../services/prefix';
import Loading from '../../../loader/loading';
import Swal from 'sweetalert2';
import { useFetch } from '../../../hooks/useFetch';
import {
  Caption,
  ButtonProposal,
  TextProposal,
  Input,
  SelectInput,
} from './styles';
import { ModalContainer } from '../../../../style/stylesModals';
import MUIDataTable from 'mui-datatables';
import CustomSearchRender from '../../../customSearchRender/CustomSearchRender';
import CustomFooter from '../../../CustomFooter/CustomFooter';
import { columns } from './columns';

const Comments = ({ id }) => {
  const [Loader, setLoader] = useState(false);
  const [pagina, setPagina] = useState(0);
  const [perPage, setPerPage] = useState(15);
  const [search, setSearch] = useState('');
  const [show, setShow] = useState(false);
  let pageExact = pagina + parseInt(1);
  const [valueSelect, setValueSelect] = useState([]);
  const [registerData, setRegisterData] = useState({
    initialData: [
      {
        id_proposal: '',
        comments: '',
        restricted: '',
      },
    ],
  });

  const commentsList = useFetch(
    Prefix.replace(/[\\"]/g, '') +
      '/proposal-comments?per_page=' +
      perPage +
      '&page=' +
      pageExact +
      '&search=' +
      search +
      '&id_proposal=' +
      id,
  );

  const restricted = [
    {
      value: 1,
      label: 'Restrito',
      nameField: 'restricted',
    },
    {
      value: 0,
      label: 'Não Restrito',
      nameField: 'restricted',
    },
  ];

  const handleShow = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  const HandleSelect = (e, name, key) => {
    if (
      valueSelect.filter((fill) => fill.key == key && fill.nameField == name)
        .length > 0
    ) {
      valueSelect
        .filter((fill) => fill.key == key && fill.nameField == name)
        .map((map) => {
          let position = valueSelect.indexOf(map);
          valueSelect.splice(position, 1);
        });
      setValueSelect(() => {
        return [
          ...valueSelect,
          { value: e.value, label: e.label, nameField: name, key: key },
        ];
      });
    } else {
      setValueSelect((prev) => {
        return [
          ...prev,
          { value: e.value, label: e.label, nameField: name, key: key },
        ];
      });
    }
  };

  const HandleRegister = (values) => {
    setLoader(true);
    values.initialData.map((map) =>
      api
        .post(Prefix.replace(/[\\"]/g, '') + '/proposal-comments', {
          id_proposal: id,
          comments: map.comments,
          restricted: map.restricted,
        })
        .then((response) => {
          Swal.fire({
            icon: 'success',
            title: 'Uow!',
            text: response.data.message,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Fechar',
            cancelButtonText: 'Novo Cadastro',
            allowOutsideClick: false,
          }).then((result) => {
            if (!result.isConfirmed) {
              setShow(true);
            }
          });
          setLoader(false);
          setRegisterData({
            initialData: [
              {
                id_proposal: '',
                comments: '',
                restricted: '',
              },
            ],
          });
          GlobalMutate(
            Prefix.replace(/[\\"]/g, '') +
              '/proposal-comments?per_page=' +
              perPage +
              '&page=' +
              pageExact +
              '&search=' +
              search +
              '&id_proposal=' +
              id,
          );
        })
        .catch((err) => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: err.response.data.message,
            confirmButtonText: 'Tentar novamente',
          });
        }),
    );
    setLoader(false);
  };

  function HandleDelete(e) {
    setLoader(<Loading />);
    let idArr = [];
    e.map((del) => {
      idArr.push(
        commentsList.data?.models?.data?.filter(
          (filtro, index) => index == del.index,
        )[0].id,
      );
    });

    idArr.map((idDel) =>
      api
        .delete(Prefix.replace(/[\\"]/g, '') + '/proposal-comments/' + idDel, {
          id: idArr,
        })
        .then((response) => {
          Swal.fire({
            icon: 'success',
            title: 'Uow!',
            text: response.data.message,
            confirmButtonText: 'Fechar',
          });
          setLoader();
          GlobalMutate(
            Prefix.replace(/[\\"]/g, '') +
              '/proposal-comments?per_page=' +
              perPage +
              '&page=' +
              pageExact +
              '&search=' +
              search +
              '&id_proposal=' +
              id,
          );
        })
        .catch((err) => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: err.response.data.message,
            confirmButtonText: 'Tentar novamente',
          });
          setLoader();
        }),
    );
  }

  const options = {
    draggableColumns: {
      enabled: true,
    },
    serverSide: true,
    onTableChange: (action, tableState) => {
      setPagina(tableState.page);
      setPerPage(tableState.rowsPerPage);
    },
    onSearchChange: (searchText) => {
      setSearch(!search ? '' : searchText);
    },
    customSearchRender: (searchText, handleSearch, hideSearch, options) => {
      return (
        <CustomSearchRender
          placeholderSearch="Pesquisar Comentário"
          searchText={searchText}
          onSearch={handleSearch}
          onHide={hideSearch}
          options={options}
        />
      );
    },
    filter: false,
    page: pagina,
    count: commentsList.data?.models?.data?.total,
    expandableRows: false,
    expandableRowsHeader: false,
    expandableRowsOnClick: false,
    rowsPerPage: perPage,
    rowsPerPageOptions: [15, 30, 100],
    onRowsDelete: (rowsDeleted, newData) => {
      HandleDelete(rowsDeleted.data);
    },
    searchPlaceholder: 'Pesquise uma comentário',
    selectableRows: true,
    searchOpen: false,

    textLabels: {
      body: {
        noMatch: 'Desculpe, não encontramos comentário!',
        toolTip: 'Sort',
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
      pagination: {
        next: 'Avançar',
        previous: 'Voltar',
        rowsPerPage: 'Linhas por página:',
        displayRows: 'de',
      },
      toolbar: {
        search: 'Pesquisar',
        downloadCsv: 'Baixar CSV',
        print: 'Imprimir',
        viewColumns: 'Ver Colunas',
        filterTable: 'Filtrar tabela',
      },
      filter: {
        all: 'Tudo',
        title: 'FILTROS',
        reset: 'RESETAR',
      },
      viewColumns: {
        title: 'Exibir colunas',
        titleAria: 'Exibir/Ocultar colunas da tabela',
      },
      selectedRows: {
        text: 'Coluna(s) selecionada(s)',
        delete: 'Excluir',
        deleteAria: 'Excluir linhas selecionadas',
      },
    },
    customFooter: (
      count,
      page,
      rowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels,
    ) => {
      let numberPages = commentsList.data?.models?.data?.last_page;
      let rowsPerPageOptions = [15, 30, 100];
      return (
        <CustomFooter
          page={page}
          rowsPerPage={rowsPerPage}
          changeRowsPerPage={changeRowsPerPage}
          changePage={changePage}
          numberPages={numberPages}
          rowsPerPageOptions={rowsPerPageOptions}
        />
      );
    },
  };
  return (
    <>
      {!show ? (
        <Link className="dropdown-item" to="#" onClick={handleShow}>
          <IconOption src="../../../media/icons/comments.svg" />
          Comentários
        </Link>
      ) : (
        <ModalContainer show={show} onHide={handleClose} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Comentários</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Caption>Interação entre cliente e comercial...</Caption>
            <Formik //--> Configuração do Formik
              initialValues={registerData}
              validationSchema={Schema}
              onSubmit={HandleRegister}
              enableReinitialize
            >
              {({ values, handleChange, setFieldValue }) => (
                <Form>
                  <Alert>
                    <FieldArray name="initialData">
                      {({ push, remove }) => (
                        <div>
                          {values?.initialData?.map((p, index) => {
                            return (
                              <>
                                <Row className="d-flex justify-content-center">
                                  <Col sm={3}>
                                    <div className="formStep">
                                      <SelectInput
                                        name={`initialData[${index}].restricted`}
                                        onChange={handleChange}
                                      >
                                        <option selected disabled>
                                          Restrito ?
                                        </option>
                                        <option value="0"> Irrestrito </option>
                                        <option value="1"> Restrito </option>
                                      </SelectInput>
                                      <ErrorMessage
                                        name={`initialData[${index}].restricted`}
                                        component="span"
                                        className="errorMessages"
                                      />
                                    </div>
                                  </Col>
                                  <Col sm={7}>
                                    <div>
                                      <Input
                                        type="text"
                                        placeholder="Comentário..."
                                        name={`initialData[${index}].comments`}
                                        onChange={handleChange}
                                      />
                                      <ErrorMessage
                                        name={`initialData[${index}].comments`}
                                        component="span"
                                        className="errorMessages"
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </>
                            );
                          })}
                          <div>
                            <Row>
                              <Col className="d-flex justify-content-center ">
                                <ButtonProposal type="submit">
                                  <TextProposal> Adicionar </TextProposal>
                                </ButtonProposal>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      )}
                    </FieldArray>
                  </Alert>
                </Form>
              )}
            </Formik>

            <div style={{ position: 'relative', zIndex: '0' }}>
              <MUIDataTable
                data={commentsList.data?.models?.data}
                columns={columns}
                options={options}
              />
            </div>
          </Modal.Body>
        </ModalContainer>
      )}
    </>
  );
};
export default Comments;
