import React, { useState } from "react";
import { Row, Col, Button, Modal, Alert } from "react-bootstrap";
import Fontawesome from "../../fontawesome/fontawesome";
import "../actionPlan.css";
import Swal from "sweetalert2";
import { mutate as mutateGlobal } from "swr";
import api from "../../../../services/api";
import { Prefix } from "../../../../services/prefix";
import Loading from "../../../../components/loader/loading";
import { useFetch } from "../../../../components/hooks/useFetch";
import { useStyles } from "../../../../components/buttons/AddButtonStyle";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import { ModalContainer } from "../../../../style/stylesModals";
import {
  CustomButton,
  TextButton,
  ButtonDesabled,
} from "../../../../style/stylesButton";
import { AsyncPaginate, component } from "react-select-async-paginate";
import { NoOptionsMessage } from "../../../../components/selectAsync/NoOptionsMessage";
import { colourStyles } from "../../../../components/selectAsync/colourStyles";
import { loadOptions } from "../../../../components/selectAsync/loadOptions";
import { Formik, Form, FieldArray, getIn, ErrorMessage } from "formik";
import Schema from "./schema";

const IndexAddPlan = ({
  iconClass,
  titleLabel,
  buttonLabel,
  pageExact,
  perPage,
  modalSize,
  orderBy,
  order,
  search,
}) => {
  const [loader, setLoader] = useState();
  const [show, setShow] = useState(false);
  const [valueSelect, setValueSelect] = useState([]);
  const [registerData, setRegisterData] = useState({
    initialData: [
      {
        description: '',
        solution: '',
        id_contract_item: '',
        started_at: '',
        cause: ''
      },
    ],
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const HandleSelect = (e, name, key) => {
    if (
      valueSelect.filter((fill) => fill.key == key && fill.nameField == name)
        .length > 0
    ) {
      valueSelect
        .filter((fill) => fill.key == key && fill.nameField == name)
        .map((map) => {
          let position = valueSelect.indexOf(map);
          valueSelect.splice(position, 1);
        });
      setValueSelect(() => {
        return [
          ...valueSelect,
          { value: e.value, label: e.label, nameField: name, key: key },
        ];
      });
    } else {
      setValueSelect((prev) => {
        return [
          ...prev,
          { value: e.value, label: e.label, nameField: name, key: key },
        ];
      });
    }
  };

  function handleRegister(values) {
    setLoader(true);
    values.initialData.map((m) => {
      api
        .post(Prefix.replace(/[\\"]/g, "") + "/contract-item-actions", {
          description: m.description,
          solution: m.solution,
          id_contract_item: m.id_contract_item,
          started_at: m.started_at,
          cause: m.cause
        })
        .then((response) => {
          Swal.fire({
            icon: "success",
            title: "Uow!",
            text: response.data.message,
            confirmButtonText: "Continuar",
          });
          mutateGlobal(
            Prefix.replace(/[\\"]/g, "") +
            "/contract-item-actions?per_page=" +
            perPage +
            "&page=" +
            pageExact +
            "&search=" +
            search +
            "&order_by=" +
            orderBy +
            "&order=" +
            order
          );
          setValueSelect([])
          setShow(false);
          setLoader(false);
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: err.response.data.message,
            confirmButtonText: "Tentar novamente",
          });
          setLoader(false);
        });
    });
  };

  const classes = useStyles();

  return (
    <>
      <Fab className={classes.fab} onClick={handleShow}>
        <AddIcon className={classes.icon} />
      </Fab>

      <ModalContainer
        show={show}
        size="md"
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h3>{titleLabel}</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={registerData}
            validationSchema={Schema}
            onSubmit={handleRegister}
            enableReinitialize
          >
            {({ values, handleChange, setFieldValue }) => (
              <Form>
                <Alert variant="success">
                  <FieldArray name="initialData">
                    {({ push, remove }) => (
                      <div>
                        {values?.initialData.map((p, index) => {
                          return (
                            <>
                              <Row>
                                <Col>
                                  <div className="formStep">
                                    <div className="labelForm">
                                      <label class="important">
                                        Ocorrência
                                      </label>
                                    </div>
                                    <textarea
                                      type="text"
                                      name={`initialData[${index}].description`}
                                      placeholder="Descreva a Ocorrência"
                                      maxlength="180"
                                      rows="7"
                                      cols="10"
                                      onChange={handleChange}
                                    ></textarea>
                                    <ErrorMessage
                                      name={`initialData[${index}].description`}
                                      component="span"
                                      className="errorMessages"
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <div className="formStep">
                                    <div className="labelForm">
                                      <label class="important">
                                        Causa
                                      </label>
                                    </div>
                                    <textarea
                                      type="text"
                                      name={`initialData[${index}].cause`}
                                      placeholder="Descreva a(as) causa(as)"
                                      maxlength="180"
                                      rows="7"
                                      cols="10"
                                      onChange={handleChange}
                                    ></textarea>
                                    <ErrorMessage
                                      name={`initialData[${index}].cause`}
                                      component="span"
                                      className="errorMessages"
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <div className="formStep">
                                    <div className="labelForm">
                                      <label class="important">Ação</label>
                                    </div>
                                    <textarea
                                      type="text"
                                      name={`initialData[${index}].solution`}
                                      placeholder="Descreva a(as) Ação(ões) tomada(s)"
                                      maxlength="180"
                                      rows="7"
                                      cols="10"
                                      onChange={handleChange}
                                    ></textarea>
                                    <ErrorMessage
                                      name={`initialData[${index}].solution`}
                                      component="span"
                                      className="errorMessages"
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <div className="formStep">
                                    <div className="labelForm">
                                      <label class="important">
                                        Equipamento
                                      </label>
                                    </div>
                                    <AsyncPaginate
                                      value={valueSelect
                                        .filter(
                                          (fill) =>
                                            fill.nameField ==
                                            "id_contract_item" &&
                                            fill.key == "id_contract_item"
                                        )
                                        .map((map) => {
                                          return {
                                            value: map.value,
                                            label: map.label,
                                          };
                                        })}
                                      loadOptions={loadOptions}
                                      // name={`initialData[${index}].list_clients`}
                                      onChange={(e) => {
                                        HandleSelect(
                                          e,
                                          "id_contract_item",
                                          "id_contract_item"
                                        );
                                        setFieldValue(
                                          `initialData[${index}].id_contract_item`,
                                          e.value
                                        );
                                      }} //values and name of select paginate
                                      placeholder="Equipamento"
                                      components={{ NoOptionsMessage }}
                                      cacheUniqs={[valueSelect]} //Set your param that is going to change when you select another field. Just use this option when you are setting params and need to refresh cache.
                                      styles={colourStyles}
                                      additional={{
                                        page: 1,
                                        endpoint: "/contract-items",
                                        // params: `uf=${ }`,
                                        paramNameObs: 'serial',
                                        paramName: "nickname",
                                        paramId: "id",
                                      }}
                                    />
                                    <ErrorMessage
                                      name={`initialData[${index}].id_contract_item`}
                                      component="span"
                                      className="errorMessages"
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <div className="formStep">
                                    <label class="important">
                                      Data de início
                                    </label>
                                    <input
                                      type="datetime-local"
                                      name={`initialData[${index}].started_at`}
                                      onChange={handleChange}
                                    />
                                    <ErrorMessage
                                      name={`initialData[${index}].started_at`}
                                      component="span"
                                      className="errorMessages"
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </>
                          );
                        })}
                        <div>
                          <Row>
                            <Col className="d-flex justify-content-center ">
                              <CustomButton
                                type="submit"
                              >
                                <TextButton>Cadastrar</TextButton>
                              </CustomButton>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    )}
                  </FieldArray>
                </Alert>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </ModalContainer>
    </>
  );
};

export default IndexAddPlan;
