import React from 'react';
import Edit from '../../components/contractsTypes/editcontractstypes/indexEditContractType';
import UpdateStatus from '../../components/contractsTypes/updateStatus/updateStatus';

export const columns = [
  {
    name: 'id',
    label: 'ID Contrato',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      display: 'excluded',
    },
  },
  {
    name: 'name',
    label: 'Tipo de Contrato',
    options: {
      setCellHeaderProps: () => ({
        style: {
          display: "flex", 
          justifyContent: "center", 
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: true,
      sort: true,
    },
  },
  {
    name: 'active',
    label: 'Status',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
          width: '10%',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: true,
      sort: false,
      print: false,

      customBodyRender: (value, tableMeta) => {
        return (
          <UpdateStatus
            activeDefault={value}
            id={tableMeta.rowData[0]}
            page={tableMeta.tableState.page}
            rowsPage={tableMeta.tableState.rowsPerPage}
            orderBy={tableMeta.tableState.sortOrder.name}
            order={tableMeta.tableState.sortOrder.direction}
            search={tableMeta.tableState.searchText}
          />
        );
      },
    },
  },
  {
    name: 'id',
    label: 'Editar',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
          width: '10%',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      print: false,

      customBodyRender: (value, tableMeta) => {
        return (
          <Edit
            id={value}
            page={tableMeta.tableState.page}
            rowsPage={tableMeta.tableState.rowsPerPage}
            search={tableMeta.tableState.searchText}
            orderBy={tableMeta.tableState.sortOrder.name}
            order={tableMeta.tableState.sortOrder.direction}
          />
        );
      },
    },
  },
];
