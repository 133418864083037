import React from 'react';
import EditBases from '../../components/bases/editbases/editbases';
import OptionsBases from '../../components/bases/optionsBases';
import { maskTel, maskTelFixo } from '../../services/mask';

export const columns = [
  {//0
    name: 'id',
    label: 'id',
    options: {
      filter: false,
      sort: false,
      display: 'excluded',
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
    },
  },
  {//1
    name: 'name',
    label: 'Nome',
    options: {
      filter: false,
      sort: true,
      setCellHeaderProps: () => ({
        style: {
          display: "flex", 
          justifyContent: "center", 
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
    },
  },
  {//2
    name: 'address',
    label: 'Endereço',
    options: {
      filter: false,
      sort: false,
      display: false,
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
    },
  },
  {//3
    name: 'number',
    label: 'Número',
    options: {
      filter: false,
      sort: false,
      display: false,
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
    },
  },
  {//4
    name: 'neighborhood',
    label: 'Bairro',
    options: {
      filter: false,
      sort: false,
      display: false,
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
    },
  },
  {//5
    name: 'city',
    label: 'Estado',
    options: {
      filter: false,
      sort: false,
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      customBodyRender: (value, tableMeta) => {
        return value.uf;
      },
    },
  },
  {//6
    name: 'city',
    label: 'Cidade',
    options: {
      filter: false,
      sort: false,
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      customBodyRender: (value, tableMeta) => {
        return value.name;
      },
    },
  },
  {//7
    name: 'email',
    label: 'Email',
    options: {
      filter: false,
      sort: false,
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
    },
  },
  {//8
    name: 'phone1',
    label: 'Telefone',
    options: {
      filter: false,
      sort: false,
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      customBodyRender: (value, tableMeta) => {
        return (maskTelFixo(value));
      },
    },
  },
  {//9
    name: 'phone2',
    label: 'Celular',
    options: {
      filter: false,
      sort: false,
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      customBodyRender: (value, tableMeta) => {
        return (maskTel(value));
      },
    },
  },
  {
    name: 'id',
    label: 'Opções',
    options: {
      filter: false,
      sort: false,
      print: false,
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
          with: '10%',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      customBodyRender: (value, tableMeta) => {
        return (
          <>
            <OptionsBases
              id={value}
              page={tableMeta.tableState.page}
              rowsPage={tableMeta.tableState.rowsPerPage}
              search={tableMeta.tableState.searchText}
              orderBy={tableMeta.tableState.sortOrder.name}
              order={tableMeta.tableState.sortOrder.direction}
            />
          </>
        );
      },
    },
  },
];
