import React from 'react';

export const Teste = [
  {
    name: 'Base 01',
    active: 1,
    address: 'A',
    neighborhood: 'A',
    number: '100',
    cep: '60.600-001',
    id_city: 2,
    phone: '85999060504'
  },
  {
    name: 'Base 02',
    active: 1,
    address: 'B',
    neighborhood: 'B',
    number: '200',
    cep: '60.600-002',
    id_city: 3,
    phone: '85999060503'
  },
  {
    name: 'Base 03',
    active: 1,
    address: 'C',
    neighborhood: 'C',
    number: '300',
    cep: '60.600-003',
    id_city: 6,
    phone: '85999060502'
  },

]