import React, { Component } from 'react'
import '@fortawesome/fontawesome-free/js/all.js';

class Fontawesome extends Component{
    render(){
        return(
            <i className={this.props.classe} style={{color: this.props.color,fontSize: this.props.fontSize, height: this.props.height,width: this.props.width}}></i>
        )
    }
}
export default Fontawesome;