import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import { TableCell } from '@material-ui/core';


export const BoxTableClient = styled(TableCell)`
border: 1.3px solid #D9D5EC;
box-sizing: border-box;
border-radius: 10px;
`;

export const TableThStClient = styled(Row)`
  font-size: 10px;
  font-weight: bolder;
  padding-left: 10px;
  border-radius: 10px;
`;

export const TableTrStClient = styled(Row)`
  background: #fff;
  font-size: 10px;
  padding-left: 10px;
`;

export const TableThSt = styled(Row)`
  background: #eef0f8;
  padding: 10px;
  border-radius: 10px;
  font-size: 10px;
  font-weight: bold;
`;

export const TableTrSt = styled(Row)`
  background: #fff;
  border-bottom: 1px solid #d9d5ec;
  padding: 10px;
  font-size: 10px;
  font-weight: bold;
`;

export const ColSt = styled.div`
  width: ${(props) => props.widthCol}%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const BoldSt = styled.div`
  font-weight: bold;
  height: 20px;
  display: flex;
  align-items: center;
`;
export const RegularSt = styled.div`
  font-weight: normal;
  color: #808080;
`;

export const TagStatusSt = styled.div`
  background: ${(props) => (props.status == 1 ? '#D6FFF0' : '#000')};
  color: ${(props) => (props.status == 1 ? '#389D79' : '#000')};
  width: 100%;
  border-radius: 50px;
  padding: 2px 10px;
`;