import React from 'react';
import { Table, Row, Col } from 'react-bootstrap';
import Image from '../images/images';
import moment from 'moment';
import Loading from '../../loader/loading';

const Device = ({ infoEquip, loader }) => {
  return (
    <>
      {!loader.data ? (
        <Row>
          <Col>
            <Loading />
          </Col>
        </Row>
      ) : (
        <>
          <Row>
            <Col className="d-flex flex-column align-items-center mb-4">
              <div className="box-equipment-data d-flex justify-content-center">
                <Image
                  idModel={infoEquip?.id_model}
                  widthSize="120px"
                  heightSize="120px"
                />
              </div>
              <h2 className="title-device">
                Equipamento{' '}
                {infoEquip?.nickname + ' (' + infoEquip?.serial + ')'}
              </h2>
            </Col>
          </Row>
          <Table bordered hover responsive>
            <tbody>
              <tr>
                <td>
                  <strong>Serial: </strong>
                  {infoEquip?.serial}
                </td>
                <td>
                  <strong>Nickname: </strong>
                  {infoEquip?.nickname}
                </td>
                <td>
                  <strong>Localização: </strong>
                  {infoEquip?.room?.sector?.unit?.name +
                    ', ' +
                    infoEquip?.room?.sector?.name +
                    ', ' +
                    infoEquip?.room?.name}
                </td>
                <td>
                  <strong>Endereço:</strong>{' '}
                  {infoEquip?.room?.sector?.unit?.address +
                    ', ' +
                    infoEquip?.room?.sector?.unit?.number +
                    ', ' +
                    infoEquip?.room?.sector?.unit?.city?.name +
                    ', ' +
                    infoEquip?.room?.sector?.unit?.city?.uf}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Instalação:</strong>{' '}
                  {moment(infoEquip?.installation_date).format('DD/MM/YYYY')}
                </td>
                <td>
                  <strong>Aditivo:</strong>{' '}
                  {!infoEquip?.additive ? 'Não' : 'Sim'}
                </td>
                <td>
                  <strong>Alienado: </strong>
                  {!infoEquip?.alienated ? 'Não' : 'Sim'}
                </td>
                <td>
                  <strong>Criticidade: </strong>
                  {!infoEquip?.criticity == 1
                    ? 'Apoio'
                    : !infoEquip?.criticity == 2
                    ? 'Rotina'
                    : 'Crítico'}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Tipo:</strong>{' '}
                  {infoEquip?.monitor_environment == false
                    ? 'Equipamento'
                    : infoEquip?.monitor_environment == true
                    ? 'Ambiente'
                    : 'Não monitorável'}
                </td>
                <td>
                  <strong>Preventiva: </strong>
                  {infoEquip?.preventive_frequency + ' meses'}
                </td>
                <td>
                  <strong>Calibração: </strong>
                  {infoEquip?.calibration_frequency + ' meses'}
                </td>
                <td>
                  <strong>Marca: </strong>
                  {infoEquip?.equipament_model?.equipament_brand?.name}
                </td>
              </tr>

              <tr>
                <td>
                  <strong>Modelo:</strong> {infoEquip?.equipament_model?.name}
                </td>
                <td>
                  <strong>Família: </strong>{' '}
                  {
                    infoEquip?.equipament_model?.equipament_category
                      ?.equipament_family.name
                  }
                </td>
                <td>
                  <strong>Identificação: </strong>
                  {infoEquip?.identification}
                </td>
                <td>
                  <strong>Reg. Anvisa: </strong>
                  {infoEquip?.equipament_model?.anvisa_registry}
                </td>
              </tr>
            </tbody>
          </Table>
          <h2 className="mt-4 mb-4 text-center">Contrato</h2>
          <Table bordered hover responsive>
            <tbody>
              <tr>
                <td>
                  <strong>Nome: </strong>
                  {infoEquip?.contract?.name}
                </td>
                <td>
                  <strong>Número: </strong>
                  {infoEquip?.contract?.number}
                </td>
                <td>
                  <strong>Tipo de contrato: </strong>
                  {infoEquip?.contract?.contract_type?.name}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Corretiva:</strong>{' '}
                  {!infoEquip?.contract?.cover_corretive ? 'Não' : 'Sim'}
                </td>
                <td>
                  <strong>Preventiva:</strong>{' '}
                  {!infoEquip?.contract?.cover_preventive ? 'Não' : 'Sim'}
                </td>
                <td>
                  <strong>Calibração:</strong>{' '}
                  {!infoEquip?.contract?.cover_calibration ? 'Não' : 'Sim'}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Data inicial:</strong>{' '}
                  {moment(infoEquip?.contract?.start_date).format('DD/MM/YYYY')}
                </td>
                <td colSpan="2">
                  <strong>Data final:</strong>{' '}
                  {moment(infoEquip?.contract?.end_date).format('DD/MM/YYYY')}
                </td>
              </tr>
            </tbody>
          </Table>
        </>
      )}
    </>
  );
};
export default Device;
