import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Modal } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import '../backup.css';
import { uf } from '../../data/uf';
import api from '../../../services/api';
import Fontawesome from '../../fontawesome/fontawesome';
import { Prefix } from '../../../services/prefix';
import Loading from '../../loader/loading';
import Swal from 'sweetalert2';
import { useFetch } from '../../hooks/useFetch';
import { mutate as mutateGlobal } from 'swr';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Switch } from '@material-ui/core';
import Schema from './schema';
import axios from 'axios';
import Select from 'react-select';
import { AsyncPaginate } from 'react-select-async-paginate';
import { loadOptions } from '../../selectAsync/loadOptions';
import { colourStyles } from '../../selectAsync/colourStyles';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const IndexAddBackup = ({
  iconClass,
  titleLabel,
  buttonLabel,
  modalSize,
  pageExact,
  perPage,
  search,
}) => {
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const [initialState, setInitialState] = useState({
    id_contract_item: '',
    id_equipament_model: '',
    serial: '',
    obs: '',
    active: 1,
    start_at: '',
    end_at: '',
  });
  const [registerData, setRegisterData] = useState(initialState);
  const [valueSelect, setValueSelect] = useState([]);
  const [nameCity, setNameCity] = useState('');

  const handleClose = () => {
    setShow(false);
    setRegisterData(initialState);
  };
  const handleShow = () => {
    setShow(true);
  };

  const itemList = useFetch(Prefix.replace(/[\\"]/g, '') + '/contract-items');

  function HandleRegister(values) {
    setLoader(true);
    api
      .post(Prefix.replace(/[\\"]/g, '') + '/equipament-backup', values)
      .then(() => {
        Swal.fire({
          icon: 'success',
          title: 'Uow!',
          text: 'Cadastro realizado com sucesso!',
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: 'Fechar',
          cancelButtonText: 'Novo Cadastro',
          allowOutsideClick: false,
        }).then((result) => {
          if (!result.isConfirmed) {
            setShow(true);
          }
        });
        setLoader(false);
        setRegisterData(initialState);
        setShow(false);
        mutateGlobal(
          Prefix.replace(/[\\"]/g, '') +
          '/equipament-categories?per_page=' +
          perPage +
          '&page=' +
          pageExact +
          '&search=',
        );
        itemList.mutate();
      })
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: err.response.data.message,
          confirmButtonText: 'Tentar novamente',
        });
        setLoader(false);
      });
  }

  const HandleSelect = (e, name) => {
    if (valueSelect.filter((fill) => fill.nameField == name).length > 0) {
      valueSelect
        .filter((fill) => fill.nameField == name)
        .map((map) => {
          let valueIndice = valueSelect.indexOf(map);
          valueSelect.splice(valueIndice);
        });

      setValueSelect((prev) => {
        return [...prev, { value: e.value, label: e.label, nameField: name }];
      });
      setRegisterData({ ...registerData, [name]: e.value });
    } else {
      setValueSelect((prev) => {
        return [...prev, { value: e.value, label: e.label, nameField: name }];
      });
      setRegisterData({ ...registerData, [name]: e.value });
    }
  };

  const handleEquipament = (e) => {
    const itemContract = itemList.data?.models.filter(
      (filtro) => filtro.id == e.target.value,
    );
    setRegisterData({
      ...registerData,
      id_contract_item: e.target.value,
      id_equipament_model: itemContract[0].id_model,
      serial: itemContract[0].serial,
      active: '1',
    });
  };

  const HandleChangeString = (e) => {
    setRegisterData({
      ...registerData,
      [e.target.name]: e.target.value.trim(),
    });
  };

  const HandleChange = (e) => {
    setRegisterData({
      ...registerData,
      [e.target.name]: e.target.checked,
    });
  };

  return (
    <>
      {show === false && (
        <Button variant="success" onClick={handleShow}>
          <Fontawesome classe={iconClass} /> {buttonLabel}
        </Button>
      )}
      <Modal
        show={show}
        size={modalSize}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{titleLabel}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loader && <Loading />}
          <Formik
            initialValues={registerData}
            validationSchema={Schema}
            onSubmit={HandleRegister}
            enableReinitialize
          >
            {({ values }) => (
              <Form>
                <Row>
                  <Col>
                    <div className="formStep">
                      <label className="important">Equipamento</label>

                      <AsyncPaginate
                        value={valueSelect
                          .filter(
                            (fill) => fill.nameField == 'id_contract_item',
                          )
                          .map((map) => {
                            return {
                              value: map.value,
                              label: map.label,
                            };
                          })}
                        loadOptions={loadOptions}
                        onChange={(e) => HandleSelect(e, 'id_contract_item')} //values and name of select paginate
                        placeholder="Selecione..."
                        cacheUniqs={[valueSelect]} //Set your param that is going to change when you select another field. Just use this option when you are setting params and need to refresh cache.
                        styles={colourStyles}
                        additional={{
                          page: 1,
                          endpoint: '/contract-items',
                          paramName: 'nickname',
                          params: 'id_contract=',
                        }}
                      />

                      <ErrorMessage
                        name="id_contract_item"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="formStep">
                      <div className="labelForm">
                        <label>Observações:</label>
                      </div>
                      <Field
                        as="textarea"
                        placeholder="Digite uma observação"
                        name="obs"
                        onChange={HandleChange}
                      />
                      <ErrorMessage
                        name="obs"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="formStep">
                      <div className="labelForm">
                        <label className="important">Data de Início:</label>
                      </div>
                      <Field
                        type="date"
                        name="start_at"
                        onChange={HandleChange}
                      />
                      <ErrorMessage
                        name="start_at"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="formStep">
                      <div className="labelForm">
                        <label className="important">Data de Término:</label>
                      </div>
                      <Field
                        type="date"
                        name="end_at"
                        onChange={HandleChange}
                      />
                      <ErrorMessage
                        name="end_at"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className="d-flex justify-content-center">
                    <Button
                      variant="success"
                      className="mt-3"
                      onClick={HandleRegister}
                    >
                      Cadastrar
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default IndexAddBackup;
