import React, { useState } from 'react';
import Fontawesome from '../../fontawesome/fontawesome';
// import '../showEquipament.css';
import { Prefix } from '../../../services/prefix';
import api from '../../../services/api';

const DownloadAttachment = ({ id }) => {
  const [path, setPath] = useState();

  const handlePath = () => {
    api
      .get(Prefix.replace(/[\\"]/g, '') + '/contract-items-attachment/' + id)
      .then((r) => {
        setPath(r.data?.model.path);
      });
  };

  return (
    <>
      <a href={path} target="_blank" onClick={handlePath}>
        <Fontawesome classe="far fa-file-pdf icon-attachment" />
      </a>
    </>
  );
};
export default DownloadAttachment;
