import { filter } from "lodash";
import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Button, Alert, Modal, Form } from "react-bootstrap";
import Fontawesome from "../../fontawesome/fontawesome";
import { Prefix } from "../../../services/prefix";
import { useFetch } from "../../hooks/useFetch";
import Select, { components } from "react-select";
import { cities } from "../../../components/data/city";
import mutate from "swr";
import api from "../../../services/api";
import Loading from "../../loader/loading";
import Swal from "sweetalert2";
import download from "downloadjs";
import "./teste.css";
import { NoOptionsMessage } from "../../selectAsync/NoOptionsMessage";
import { ModalContainer } from "../../../style/stylesModals";
import {
  CustomButton,
  TextButton,
  ButtonDesabled,
} from "../../../style/stylesButton";

const GenerateActionPlan = () => {
  const [loader, setLoader] = useState();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [formData, setFormData] = useState([]);
  const [itemsContract, setItemsContract] = useState([]);
  const [listIdContracts, setListIdContracts] = useState([]);
  const [listItemsSelect, setListItemsSelect] = useState();

  let dayName = new Array(
    "domingo",
    "segunda",
    "terça",
    "quarta",
    "quinta",
    "sexta",
    "sábado"
  );
  let monName = new Array(
    "",
    "janeiro",
    "fevereiro",
    "março",
    "abril",
    "maio",
    "junho",
    "agosto",
    "outubro",
    "novembro",
    "dezembro"
  );
  const now = new Date();

  let birthday =
    dayName[now.getDay()] +
    ", " +
    now.getDate() +
    " de " +
    monName[now.getMonth()] +
    " de " +
    now.getFullYear();

  const listClient = useFetch(Prefix.replace(/[\\"]/g, "") + "/customers");
  const listContract = useFetch(Prefix.replace(/[\\"]/g, "") + "/contracts");
  const listItem = useFetch(Prefix.replace(/[\\"]/g, "") + "/contract-items");

  const listClients = listClient.data?.models.data.map((map) => {
    return { value: map.id, label: map.name };
  });

  const listContracts = !formData?.id_customer
    ? ""
    : listContract.data?.models
        .filter((filtro) => filtro.id_customer == formData?.id_customer)
        .map((map) => {
          return { value: map.id, label: map.name };
        });

  const listSelectItems = !itemsContract
    ? ""
    : itemsContract.map((map) => {
        return { value: map.id, label: map.nickname };
      });

  const handleChangeClients = (e) => {
    setFormData([]);
    setFormData({
      ...formData,
      id_customer: e.value,
    });
  };

  const handleChangeItem = (e) => {
    let ArrItem = [];
    if (!e || e.length == 0) {
    } else {
      e.map((map) => {
        ArrItem.push(map.value);
      });
      setFormData({
        ...formData,
        id_item: ArrItem,
      });
    }
  };

  const handleChangeContract = (e) => {
    if (!e || e.length == 0 || e == undefined) {
      setItemsContract([]);
    } else {
      setItemsContract([]);
      setListIdContracts(e);

      e.map((map) => {
        return listItem.data?.models
          .filter((fill) => fill.id_contract == map.value)
          .map((map) => {
            return setItemsContract((oldArray) => {
              return [
                ...oldArray,
                {
                  id: map.id,
                  nickname: map.nickname,
                  id_contract: map.id_contract,
                },
              ];
            });
          });
      });
    }
  };

  const HandleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.checked,
    });
  };

  const HandleChangeText = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value.trim(),
    });
  };

  function HandleRegister() {
    setLoader(<Loading />);
    api
      .post(
        Prefix.replace(/[\\"]/g, "") + "/contract-item-actions-export",
        formData,
        {
          responseType: "blob",
        }
      )
      .then((response) => {
        setLoader();
        const content = response.headers["content-type"];
        download(
          response.data,
          "Relatorio de Plano de Ação em " + birthday,
          content
        );
        Swal.fire({
          icon: "success",
          title: "Uow...",
          text: "Relatório gerado com sucesso!",
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: "Fechar",
          cancelButtonText: "Novo Relatório",
          allowOutsideClick: false,
        }).then((result) => {
          if (!result.isConfirmed) {
            setShow(true);
          }
        });
        setLoader();
        setShow(false);
        setFormData({});
      })
      .catch(
        (error) =>
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Não existe Relatório de plano de ação para filtro aplicado",
            confirmButtonText: "Tentar novamente",
          }),
        setLoader()
      );
  }

  return (
    <>
      <Button variant="success" onClick={handleShow}>
        <Fontawesome classe="fas fa-file-pdf mr-3" />
        Gerar Relatório
      </Button>
      {!show ? (
        ""
      ) : (
        <ModalContainer
          show={show}
          size="md"
          onHide={handleClose}
          backdrop="static"
          keyboard={true}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <h3>Relatório de Plano de Ação</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Alert variant="success">
              <form className="customerStep" onSubmit={HandleRegister}>
                <Row>
                  <Col>
                    <div className="formStep py-3">
                      <Select
                        closeMenuOnSelect={false}
                        placeholder="Selecione um Cliente..."
                        name="id_customer"
                        components={{ NoOptionsMessage }}
                        options={listClients}
                        onChange={handleChangeClients}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="formStep py-3">
                      <Select
                        closeMenuOnSelect={false}
                        placeholder="Selecione Contrato(s)..."
                        isMulti
                        name="contract"
                        options={listContracts}
                        onChange={handleChangeContract}
                        components={{ NoOptionsMessage }}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="formStep py-3">
                      <Select
                        closeMenuOnSelect={false}
                        placeholder="Selecione itens de Contrato(s)..."
                        isMulti
                        name="id_item"
                        options={listSelectItems}
                        onChange={handleChangeItem}
                        components={{ NoOptionsMessage }}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className=" ">
                    <div className="custom-control custom-switch-div formStep switch-reports">
                      <Form.Check
                        type="switch"
                        name="type"
                        id={!formData ? "" : "type" + formData.id}
                        label="Simplificado"
                        checked={!formData ? "" : Boolean(formData?.type)}
                        onChange={HandleChange}
                      />
                    </div>
                  </Col>
                  <Col className=" ">
                    <div className="custom-control custom-switch-div formStep switch-reports">
                      <Form.Check
                        type="switch"
                        name="pagebreak"
                        id={!formData ? "" : "pagebreak" + formData.id}
                        label="Quebra de Páginas"
                        checked={!formData ? "" : Boolean(formData?.pagebreak)}
                        onChange={HandleChange}
                        className="switch-reports"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Label>Observações:</Form.Label>
                      <Form.Control
                        type="text"
                        as="textarea"
                        rows={3}
                        name="obs"
                        onChange={HandleChangeText}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="formStep mb-3">
                      <label class="important">Data de início</label>
                      <input
                        type="date"
                        name="start_date"
                        onChange={HandleChangeText}
                      />
                    </div>
                  </Col>

                  <Col>
                    <div className="formStep mb-3">
                      <label class="important">Data de término</label>
                      <input
                        type="date"
                        name="end_date"
                        onChange={HandleChangeText}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="d-flex justify-content-center">
                  <CustomButton type='button' onClick={HandleRegister}>
                    <TextButton>Gerar Relatório</TextButton>
                  </CustomButton>
                </Row>
              </form>
            </Alert>
          </Modal.Body>
        </ModalContainer>
      )}
    </>
  );
};
export default GenerateActionPlan;
