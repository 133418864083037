import React from 'react';
import UpdateExecution from '../../components/os/update/updateExecution';
import Edit from '../../components/os/editOs/editOs';
import Options from '../../components/os/options/options';

export const columns = [
  {
    name: 'id',
    label: 'id',
    options: {
      filter: false,
      sort: false,
      display: 'excluded',
    },
  },
  {
    name: 'id_contract_item',
    label: 'id',
    options: {
      filter: false,
      sort: false,
      display: 'excluded',
    },
  },
  {
    name: 'scheduled_at',
    label: 'Data de Agendamento',
    options: {
      filter: false,
      sort: false,
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      customBodyRender: (value, tableMeta) => {
        return (
          value.split(' ')[0].split('-')[2] +
          '/' +
          value.split(' ')[0].split('-')[1] +
          '/' +
          value.split(' ')[0].split('-')[0] +
          ' às ' +
          value.split(' ')[1]
        );
      },
    },
  },

  {
    name: 'execution_at',
    label: 'Data de Execução',
    options: {
      filter: false,
      sort: false,
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      customBodyRender: (value, tableMeta) => {
        if (!value) {
          return 'Não executado';
        } else {
          return (
            value.split(' ')[0].split('-')[2] +
            '/' +
            value.split(' ')[0].split('-')[1] +
            '/' +
            value.split(' ')[0].split('-')[0] +
            ' às ' +
            value.split(' ')[1]
          );
        }
      },
    },
  },
  {
    name: 'description',
    label: 'Descrição',
    options: {
      filter: false,
      sort: false,
      display: 'excluded',
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      customBodyRender: (value, tableMeta) => {
        return value;
      },
    },
  },

  {
    name: 'urgency',
    label: 'Urgência',
    options: {
      filter: false,
      sort: false,
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      customBodyRender: (value, tableMeta) => {
        if (value == 'Alta') {
          return (
            <>
              <i
                class="fas fa-exclamation-circle"
                style={{ color: '#ff0000', fontSize: '20px' }}
              ></i>{' '}
            </>
          );
        } else if (value == 'Baixa') {
          return (
            <>
              <i
                class="fas fa-exclamation-circle"
                style={{ color: '#2bac23', fontSize: '20px' }}
              ></i>{' '}
            </>
          );
        } else if (value == 'Média') {
          return (
            <>
              <i
                class="fas fa-exclamation-circle"
                style={{ color: '#d7690a', fontSize: '20px' }}
              ></i>{' '}
            </>
          );
        } else {
          return (
            <>
              <i
                class="fas fa-exclamation-circle"
                style={{ color: '#000', fontSize: '20px' }}
              ></i>
            </>
          );
        }
      },
    },
  },
  {
    name: 'obs',
    label: 'obs',
    options: {
      filter: false,
      sort: false,
      display: 'excluded',
    },
  },

  // {
  //   name: 'contract_itens',
  //   label: 'Itens de Contrato',
  //   options: {
  //     filter: true,
  //     sort: false,
  //     display: false,
  //     customBodyRender: (value, tableMeta) => {
  //       return value.id_contract;
  //     },
  //   },
  // },
  {
    name: 'id',
    label: 'Opções',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: true,
      sort: false,
      customBodyRender: (value, tableMeta) => {
        return <Options id={value} />;
      },
    },
  },
  {
    name: 'realized',
    label: 'Execução',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: true,
      sort: false,
      display: 'excluded',
    },
  },
  {
    name: 'third_companie',
    label: 'Empresa terceirizada',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: true,
      sort: false,
      display: 'excluded',
      customBodyRender: (value, tableMeta) => {
        return !value ? '' : value.name;
      },
    },
  },
  {
    name: 'technician_third_company',
    label: 'Técnico terceirizado',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: true,
      sort: false,
      display: 'excluded',
    },
  },
  {
    name: 'id',
    label: 'Editar',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: true,
      sort: false,
      customBodyRender: (value, tableMeta) => {
        return (
          <Edit
            id={value}
            page={tableMeta.tableState.page}
            perPage={tableMeta.tableState.rowsPerPage}
            search={
              !tableMeta.tableState.searchText
                ? ''
                : tableMeta.tableState.searchText
            }
            idContract={tableMeta.rowData[9]}
          />
        );
      },
    },
  },
];
