import React, { useState, useEffect, memo } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './style.css';
import '../../style/global.css';
import Login from '../../components/login/login/login';
import Logotipo from '../../components/logotipo/logotipo';
import ForgotPassword from '../../components/login/forgotPassword/forgotPassword';
import RequestRegister from '../../components/login/requestRegister/requestRegister';
import api from '../../services/api';
import history from '../../services/history';

const LoginAccess = () => {
  const [state, setState] = useState({
    login: true,
    register: false,
    forgot: false,
  });

  function refreshPage() {
    window.location.reload();
  }

  useEffect(() => {
    api.get('/user').then((response) => {
      if (
        response.data.group == 'client' &&
        response.data.client.licences[0].active == 1
      ) {
        history.push('/gestao/cliente/inicio');
        refreshPage();
      } else if (
        response.data.group == 'client' &&
        response.data.client.licences[0].active == 0 &&
        response.data.client.licences[1].active == 1
      ) {
        history.push('/monitoramento/inicio');
        refreshPage();
      } else if (response.data.group == 'tech') {
        history.push('/planejamento/inicio');
        refreshPage();
      } else {
        history.push('/gestao/inicio');
        refreshPage();
      }
    });
  }, []);

  const forgotPassword = () => {
    setState({ login: false, register: false, forgot: true });
  };

  const requestRegister = () => {
    setState({ login: false, forgot: false, register: true });
  };

  const backLogin = () => {
    setState({ login: true, forgot: false, register: false });
  };

  return (
    <div className="container-fluido ">
      <div className="container mt_container">
        <div className="row mt_login_top">
          <div className="col-12 text-center">
            <Logotipo
              src="./media/logos/logo-dtlab.png"
              classe="mt_img_logo img-fluid"
              alt="Logo DTLAB"
            />
          </div>
        </div>
        <div className="row mt_signin">
          {/* Componentes de Login, Recuperação de senha e Solicitação de Cadastro */}
          {state.login === true && (
            <Login
              functionPassword={forgotPassword}
              functionRegister={requestRegister}
            />
          )}
          {state.forgot === true && <ForgotPassword functionBack={backLogin} />}
          {state.register === true && (
            <RequestRegister functionBack={backLogin} />
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(LoginAccess);
