import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { IconOption } from '../optionsProposalsStyle';
import { Modal } from 'react-bootstrap';

const Info = () => {
  const [show, setShow] = useState(false);

  const handleShow = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  return (
    <>
      {!show ? (
        <Link className="dropdown-item" to="#" onClick={handleShow}>
          <IconOption src="../../../media/icons/downloadpdf.svg" />
          Download PDF
        </Link>
      ) : (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Modal heading</Modal.Title>
          </Modal.Header>
          <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
        </Modal>
      )}
    </>
  );
};
export default Info;
