import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.js';
import SidebarSisboard from '../../../components/sidebar/sidebar';
import Topbar from '../../../components/topbar/topbar';
import Card from 'react-bootstrap/Card';
//import OpenModal from '../../components/modal/openModal';
//import Content from '../../components/users/addusers/indexStep';
import Fontawesome from '../../../components/fontawesome/fontawesome';
import './boards.css';

class Boards extends Component {
  render() {
    return (
      <div className="wrapper d-flex align-items-stretch">
        <SidebarSisboard />
        <div id="content">
          <Topbar />
          <div className="content_all">
            <Card>
              <Card.Header as="h5">
                <h3 class="card-label header-card">
                  <span>
                    <Fontawesome classe="fas fa-memory mr-3" /> Placas
                    Controladoras <small>Gestão de Placas de Automação</small>
                  </span>

                  {/*<OpenModal content={<Content/>} 
                                           titleLabel="Cadastro de Usuário" 
                                           buttonLabel="Adicionar" 
                                    iconClass="fas fa-plus-circle"/>*/}
                </h3>
              </Card.Header>
              <Card.Body>
                <Card.Text></Card.Text>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    );
  }
}

export default Boards;
