import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { CustomButton, TextButton } from "../../style/stylesButton";
import Fontawesome from "../../components/fontawesome/fontawesome";
import "./styles.css";

const ClipBoard = () => {

  const [valueCopy, setValueCopy] = useState({
    value: "http://dtlab4-dev.mtechsp.com.br/solicitacoes",
    copied: false,
  });
  const handleClipeBoarder = () => {
    setValueCopy({
      ...valueCopy,
      copied: true
    })
    setTimeout(function () {
      setValueCopy({
        ...valueCopy,
        copied: false
      })
    }, 3000);
  };

  return (
    <>
      <CopyToClipboard
        text={valueCopy.value}
        onCopy={handleClipeBoarder}
      >
        <div className="d-flex justitfy-content-between ">
          <div>
            {!valueCopy.copied ? (
              ""
            ) : (
              <div class="alert-box success">Copiado!</div>
            )}
          </div>
          <div>
            <CustomButton style={{ height: "50px" }}>
              <TextButton>
                <Fontawesome classe="far fa-copy" />
                Link
              </TextButton>
            </CustomButton>
          </div>
        </div>
      </CopyToClipboard>
    </>
  );
};
export default ClipBoard;
