import React, { Component, useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.js';
import Sidebar from '../../components/sidebar/sidebar';
import './contracts.css';
import Topbar from '../../components/topbar/topbar';
import { columns } from './columns.jsx';
import ContainerAdd from '../../components/contracts/addcontracts/indexAddContracts';
import MUIDataTable from 'mui-datatables';
import Fontawesome from '../../components/fontawesome/fontawesome';
import { useFetch } from '../../components/hooks/useFetch';
import { Prefix } from '../../services/prefix';
import Loading from '../../components/loader/loading';
import api from '../../services/api';
import Swal from 'sweetalert2';
import { mutate as mutateGlobal } from 'swr';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import CustomFooter from '../../components/CustomFooter/CustomFooter';
import CustomSearchRender from '../../components/customSearchRender/CustomSearchRender';
import { formatMoeda } from '../../services/mask';
import { Card, Row, Col, Table } from 'react-bootstrap';
import {
  CardSt,
  CardHeaderSt,
  IconTitleSt,
  TitleSt,
  TextTitleSt,
  SmallTitleSt,
} from '../../style/globalStyle';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { GetMuiTheme } from '../../components/muiTheme/muiTheme';
import { mutate as GlobalMutate } from 'swr';

export const Contracts = () => {
  const [loader, setLoader] = useState();
  const [pagina, setPagina] = useState(0);
  const [perPage, setPerPage] = useState(15);
  const [search, setSearch] = useState('');
  const [orderBy, setOrderBy] = useState('id');
  const [order, setOrder] = useState('desc');

  let pageExact = pagina + parseInt(1);

  const contractList = useFetch(
    Prefix.replace(/[\\"]/g, '') +
      '/contracts?per_page=' +
      perPage +
      '&page=' +
      pageExact +
      '&search=' +
      search +
      '&order_by=' +
      orderBy +
      '&order=' +
      order,
  );

  const options = {
    filterType: 'dropdown',
    draggableColumns: {
      enabled: true,
    },
    sortOrder: {
      name: orderBy,
      direction: order,
    },
    filter: false,

    serverSide: true,
    onTableChange: (action, tableState) => {
      setOrderBy(
        !tableState.sortOrder.name ? orderBy : tableState.sortOrder.name,
      );
      setOrder(
        !tableState.sortOrder.direction
          ? order
          : tableState.sortOrder.direction,
      );
      setPagina(tableState.page);
      setPerPage(tableState.rowsPerPage);
    },
    onSearchChange: (searchText) => {
      setSearch(!searchText ? '' : searchText); //faz condição para string vazia exibir vazio no lugar de null ou undefined
    },
    customSearchRender: (searchText, handleSearch, hideSearch, options) => {
      return (
        <CustomSearchRender
          placeholderSearch="Pesquisar Contrato"
          searchText={searchText}
          search={search}
          onSearch={handleSearch}
          onHide={hideSearch}
          options={options}
        />
      );
    },
    filter: false,
    page: pagina,
    count: contractList.data?.models?.total, //Exibe o total vindo da constante cityList
    expandableRows: true,
    expandableRowsHeader: false,
    expandableRowsOnClick: false,
    rowsPerPage: perPage,
    rowsPerPageOptions: [15, 30, 100],
    isRowExpandable: (dataIndex, expandedRows) => {
      // if (dataIndex === 3 || dataIndex === 4) return false;
      if (
        expandedRows.data.length > 4 &&
        expandedRows.data.filter((d) => d.dataIndex === dataIndex).length === 0
      )
        return false;
      return true;
    },
    renderExpandableRow: (rowData, rowMeta) => {
      const colSpan = rowData.length + 1;
      return (
        <TableRow>
          <TableCell colSpan={colSpan}>
            <Row>
              <Col className="text-center">
                <h4>Dados de Contrato</h4>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Nome do Contrato:</th>
                      <th>Cliente:</th>
                      <th>Tipo de Contrato:</th>
                      <th>Data de Início:</th>
                      <th>Data de Término:</th>
                      <th>Número do Contrato::</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{rowData[5]}</td>
                      <td> {rowData[3]}</td>
                      <td> {rowData[4]}</td>
                      <td>{rowData[7].split('-').reverse().join('/')}</td>
                      <td>{rowData[8].split('-').reverse().join('/')}</td>
                      <td>{rowData[6]}</td>
                    </tr>
                  </tbody>
                </Table>

                <h4>Dados de Pagamento</h4>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Valor de Contrato:</th>
                      <th>Parcela:</th>
                      <th>Valor de Parcela:</th>
                      <th>Forma de Pagamento:</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td> {formatMoeda(rowData[10])}</td>
                      <td>{rowData[11]}x</td>
                      <td>
                        {!rowData[10]
                          ? 'Sem Parcelamento'
                          : formatMoeda(rowData[10] / rowData[11])}
                      </td>
                      <td>{rowData[18]}</td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </TableCell>
        </TableRow>
      );
    },

    onRowsDelete: (rowsDeleted, newData) => {
      HandleDelete(rowsDeleted.data);
    },

    searchPlaceholder: 'Pesquise um cliente',
    selectableRows: 'multiple',

    searchOpen: true,

    textLabels: {
      body: {
        noMatch: 'Desculpe, não encontramos contratos!',
        toolTip: 'Sort',
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
      pagination: {
        next: 'Avançar',
        previous: 'Voltar',
        rowsPerPage: 'Linhas por página:',
        displayRows: 'de',
      },

      toolbar: {
        search: 'Pesquisar',
        downloadCsv: 'Baixar CSV',
        print: 'Imprimir',
        viewColumns: 'Ver Colunas',
        filterTable: 'Filtrar tabela',
      },
      filter: {
        all: 'Tudo',
        title: 'FILTROS',
        reset: 'RESETAR',
      },
      viewColumns: {
        title: 'Exibir colunas',
        titleAria: 'Exibir/Ocultar colunas da tabela',
      },
      selectedRows: {
        text: 'Coluna(s) selecionada(s)',
        delete: 'Excluir',
        deleteAria: 'Excluir linhas selecionadas',
      },
    },
    customFooter: (
      count,
      page,
      rowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels,
    ) => {
      let numberPages = contractList.data?.models?.last_page;
      let rowsPerPageOptions = [15, 30, 100];
      return (
        <CustomFooter
          page={page}
          rowsPerPage={rowsPerPage}
          changeRowsPerPage={changeRowsPerPage}
          changePage={changePage}
          numberPages={numberPages}
          rowsPerPageOptions={rowsPerPageOptions}
        />
      );
    },
  };
  function HandleDelete(e) {
    setLoader(<Loading />);
    let idArr = [];

    e.map((del) => {
      idArr.push(
        contractList.data?.models?.data.filter(
          (filtro, index) => index == del.index,
        )[0].id,
      );
    });

    idArr.map((idDel) =>
      api
        .delete(Prefix.replace(/[\\"]/g, '') + '/contracts/' + idDel)
        .then((response) => {
          Swal.fire({
            icon: 'success',
            title: 'Uow!',
            text: response.data.message,
            confirmButtonText: 'Fechar',
          });
          setLoader();
          contractList.mutate();
          GlobalMutate(
            Prefix.replace(/[\\"]/g, '') +
              '/contracts?per_page=' +
              perPage +
              '&page=' +
              pageExact +
              '&search=' +
              search +
              '&order_by=' +
              orderBy +
              '&order=' +
              order,
          );
        })
        .catch((err) => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: err.response.data.message,
            confirmButtonText: 'Tentar novamente',
          });
          setLoader();
        }),
    );
  }

  return (
    <div className="wrapper d-flex align-items-stretch">
      <Sidebar />
      <div id="content">
        {loader}
        <Topbar />
        <div className="content_all">
          <CardSt>
            <CardHeaderSt className="d-flex justify-content-between">
              <TitleSt>
                <IconTitleSt
                  style={{ width: '36px' }}
                  src="../../../media/icons-pages/contract-page.svg"
                />
                <TextTitleSt>
                  Contratos <SmallTitleSt>Gestão de Contratos</SmallTitleSt>
                </TextTitleSt>
              </TitleSt>
            </CardHeaderSt>
            <CardSt.Body>
              <CardSt.Text>
                <MuiThemeProvider theme={GetMuiTheme()}>
                  <MUIDataTable
                    data={contractList.data?.models?.data}
                    columns={columns}
                    options={options}
                  />
                </MuiThemeProvider>
              </CardSt.Text>
            </CardSt.Body>
          </CardSt>
          <ContainerAdd
            titleLabel="Adicionar Contrato"
            buttonLabel="Adicionar"
            iconClass="fas fa-plus-circle"
            pageExact={pageExact}
            perPage={perPage}
            search={search}
            orderBy={orderBy}
            order={order}
          />
        </div>
      </div>
    </div>
  );
};

export default Contracts;
