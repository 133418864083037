import React, { useState } from 'react';
import './style.css';
import '../../style/global.css';
import Logotipo from '../../components/logotipo/logotipo';
import history from '../../services/history';
import api from '../../services/api';
import Swal from 'sweetalert2';
import Loading from '../../components/loader/loading';

const ForgotPassword = (props) => {
  let divOne = props.location.search.split('?token=');
  let divTwo = divOne[1].split('&email=');
  let params = {
    token: divTwo[0],
    email: divTwo[1],
  };

  const [loader, setLoader] = useState();
  const [formData, setFormData] = useState({
    token: params.token,
    email: params.email,
  });

  function HandleBack() {
    history.push('/');
    window.location.reload();
  }

  function HandleChange(e) {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  }

  function HandleExecute() {
    setLoader(<Loading />);
    api
      .post('recuperar-senha', formData)
      .then((response) => {
        Swal.fire({
          icon: 'success',
          title: 'Uow...',
          text: response.data.message,
          confirmButtonText: 'Fechar',
        }).then((result) => {
          setLoader();
          if (result.isConfirmed) {
            history.push('/');
            window.location.reload();
          }
        });
      })
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: err.response.data.message,
          confirmButtonText: 'Tentar novamente',
        });
        setLoader();
      });
  }

  return (
    <div className="container-fluido ">
      {loader}
      <div className="container mt_container">
        <div className="row mt_login_top">
          <div className="col-12 text-center">
            <Logotipo
              src="./media/logos/logo-dtlab.png"
              classe="mt_img_logo img-fluid"
              alt="Logo DTLAB"
            />
          </div>
        </div>
        <div className="row mt_signin">
          <div className="container_login">
            <div className="kt-login__forgot">
              <div className="kt-login__head">
                <h3 className="kt-login__title">Redefinição de senha</h3>
                <div className="kt-login__desc">
                  Digite uma nova senha e confirme:
                </div>
              </div>
              <div className="mt_form flip-in-hor-bottom">
                <div className="input-group">
                  <input
                    className="form-control"
                    type="password"
                    placeholder="Nova senha"
                    name="password"
                    autoComplete="off"
                    onBlur={HandleChange}
                  />
                </div>
                <div className="input-group">
                  <input
                    className="form-control"
                    type="password"
                    placeholder="Confirme a senha"
                    name="password_confirmation"
                    autoComplete="off"
                    onBlur={HandleChange}
                  />
                </div>
                <div className="kt-login__actions">
                  <button
                    id="kt_login_forgot_submit"
                    className="kt-login__btn-primary"
                    onClick={HandleExecute}
                  >
                    Enviar
                  </button>
                  &nbsp;&nbsp;
                  <button className="mt_button_second" onClick={HandleBack}>
                    Voltar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
