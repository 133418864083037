import React from 'react';
import ContractRenewal from './contractRenewal';

export const columns = [
  {
    name: 'id',
    label: 'id',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      display: 'excluded',
    },
  },
  {
    name: 'contract',
    label: 'Contrato',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      display: 'true',
      customBodyRender: (value, tableMeta) => {
        return value.number;
      },
    },
  },
  {
    name: 'contract',
    label: 'Início',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      display: 'true',
      customBodyRender: (value, tableMeta) => {
        return value.start_date.split('-').reverse().join('/');;
      },
    },
  },
  {
    name: 'contract',
    label: 'Termino',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      display: 'true',
      customBodyRender: (value, tableMeta) => {
        return value.end_date.split('-').reverse().join('/');;
      },
    },
  },
  {
    name: 'extended_date',
    label: 'Extensão',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      display: 'true',
      customBodyRender: (value, tableMeta) => {
        return value.split('-').reverse().join('/');
      },
    },
  },
  {
    name: 'new_value',
    label: 'Valor',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      display: 'true',
      customBodyRender: (value, tableMeta) => {
        return  "R$ "+value
      },
    },
  },
];