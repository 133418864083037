import React from 'react';
import api from '../../../services/api';
import { Prefix } from '../../../services/prefix';
import { mutate as globalMutate } from 'swr';

export default function UpdateStatus({
  id,
  page,
  rowsPage,
  activeDefault,
  search,
  orderBy,
  order,
}) {
  let pageExact = page + parseInt(1);

  function HandleStatus() {
    api
      .put(Prefix.replace(/[\\"]/g, '') + '/customers/' + id, {
        active: Boolean(activeDefault === 1 ? 0 : 1),
      })
      .then(() => {
        globalMutate(
          Prefix.replace(/[\\"]/g, '') +
            '/customers?per_page=' +
            rowsPage +
            '&page=' +
            pageExact +
            '&search=' +
            search +
            '&order_by=' +
            orderBy +
            '&order=' +
            order,
        );
      });
  }

  return (
    <>
      <label className="switch">
        <input
          type="checkbox"
          id={id + 'customer'}
          onChange={HandleStatus}
          checked={activeDefault}
        />
        <span className="slider round"></span>
      </label>
    </>
  );
}
