import React, { useEffect, useState } from 'react';
import api from '../../../../services/api';
import { Prefix } from '../../../../services/prefix';
import { useFetch } from '../../../hooks/useFetch';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import FormCheck from 'react-bootstrap/FormCheck';

const UpdateModule = ({
  modName,
  idModule,
  idUser,
  idScheme,
  changeModules,
}) => {
  const userList = useFetch(Prefix.replace(/[\\"]/g, '') + '/users/' + idUser);

  const schemes = userList.data?.model?.schemes.filter(
    (esquema) => esquema.id == idScheme,
  );

  const modules = schemes[0].modules.filter((md) => md.id_module == idModule)[0];
  const [module, setModule] = useState(modules);

  useEffect(() => {
    if (changeModules == null) {
      setModule({
        ...module,
        active: module.active,
        show: module.show,
        destroy: module.destroy,
        save: module.save,
        update: module.update,
      }); //children function of interest
    } else {
      setModule({
        ...module,
        active: changeModules,
        show: changeModules,
        destroy: changeModules,
        save: changeModules,
        update: changeModules,
      }); //children function of interest
    }
  }, [changeModules]);

  function HandleChangeModule(e) {
    const formData = {
      id_user: idUser,
      id_module: idModule,
      status: e,
    };

    api
      .put(
        Prefix.replace(/[\\"]/g, '') + '/permission-profiles/toggle',
        formData,
      )
      .then(() =>
        setModule({
          ...module,
          active: e,
          show: e,
          destroy: e,
          save: e,
          update: e,
        }),
      );
  }

  function HandleChangeOptions(e) {
    let name = e.target.name;
    let value =
      name == 'save'
        ? module.save == true
          ? false
          : true
        : name == 'update'
          ? module.update == true
            ? false
            : true
          : name == 'destroy'
            ? module.destroy == true
              ? false
              : true
            : module.show == true
              ? false
              : true;

    const formData = {
      id_module: idModule,
      id_user: idUser,
      action: name,
    };
    api
      .put(
        Prefix.replace(/[\\"]/g, '') + '/permission-profiles/toggle', formData)
      .then(() => setModule({ ...module, [name]: value }));
  }

  return (
    <>
      {
        <>
          <Row>
            <Col sm={12}>
              <Alert className="titleMenu">
                <h4>{modName}</h4>
                <div class="custom-control custom-switch">
                  <FormCheck
                    id={idUser + 'mod' + idModule}
                    type="switch"
                    label=""
                    checked={
                      module.active === true ||
                        module.show === true ||
                        module.update === true ||
                        module.destroy === true ||
                        module.save === true
                        ? true
                        : false
                    }
                    onChange={() =>
                      HandleChangeModule(module.active === true ? false : true)
                    }
                  />
                </div>
              </Alert>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Row className="optionPermission">
                <Col sm={6}>
                  <div class="custom-control custom-switch">
                    <FormCheck
                      id={idUser + 'show' + idModule}
                      type="switch"
                      label="Visualizar"
                      name="show"
                      checked={module.show}
                      onChange={HandleChangeOptions}
                    />
                  </div>
                  <div class="custom-control custom-switch">
                    <FormCheck
                      id={idUser + 'update' + idModule}
                      type="switch"
                      label="Atualizar"
                      name="update"
                      checked={module.update}
                      onChange={HandleChangeOptions}
                    />
                  </div>
                </Col>
                <Col sm={6}>
                  <div class="custom-control custom-switch">
                    <FormCheck
                      id={idUser + 'save' + idModule}
                      type="switch"
                      label="Salvar"
                      name="save"
                      checked={module.save}
                      onChange={HandleChangeOptions}
                    />
                  </div>
                  <div class="custom-control custom-switch">
                    <FormCheck
                      id={idUser + 'destroy' + idModule}
                      type="switch"
                      label="Excluir"
                      name="destroy"
                      checked={module.destroy}
                      onChange={HandleChangeOptions}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      }
    </>
  );
};
export default UpdateModule;
