import React, { useState } from 'react';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { useStyles } from '../../buttons/AddButtonStyle';
import { Modal, Col, Row, Button } from 'react-bootstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Schema from './schema';
import { NoOptionsMessage } from '../../selectAsync/NoOptionsMessage';
import { cnpj } from 'cpf-cnpj-validator';
import { maskCNPJ, maskTel } from '../../../services/mask';
import { uf } from '../../data/uf';
import Select from 'react-select';
import { AsyncPaginate } from 'react-select-async-paginate';
import { loadOptions } from '../../selectAsync/loadOptions';
import { colourStyles } from '../../selectAsync/colourStyles';
import { useFetch } from '../../hooks/useFetch';
import { Prefix } from '../../../services/prefix';
import Swal from 'sweetalert2';
import api from '../../../services/api';
import { mutate as mutateGlobal } from 'swr';
import { ModalContainer } from "../../../style/stylesModals"; 
import { CustomButton, TextButton } from "../../../style/stylesButton";

const AddProposal = ({ titleLabel, modalSize, pageExact, perPage, search }) => {
  const [show, setShow] = useState(false);
  const [registerData, setRegisterData] = useState({
    name: '',
    company: '',
    cnpj: '',
    phone: '',
    email: '',
    id_city: '',
    uf: '',
    description: '',
  });
  const [messageDocument, setMessageDocument] = useState(null);
  const [valueSelect, setValueSelect] = useState([]);

  const handleShow = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  const validateDocument = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    let document = value
      .replace('/', '')
      .replace('-', '')
      .replace('.', '')
      .replace('.', '');

    setRegisterData({
      ...registerData,
      [name]: value,
    });
    setMessageDocument(cnpj.isValid(document));
  };

  const HandleSelect = (e, name) => {
    if (valueSelect.filter((fill) => fill.nameField == name).length > 0) {
      valueSelect
        .filter((fill) => fill.nameField == name)
        .map((map) => {
          let valueIndice = valueSelect.indexOf(map);
          valueSelect.splice(valueIndice);
        });

      setValueSelect((prev) => {
        return [...prev, { value: e.value, label: e.label, nameField: name }];
      });
      setRegisterData({ ...registerData, [name]: e.value });
    } else {
      setValueSelect((prev) => {
        return [...prev, { value: e.value, label: e.label, nameField: name }];
      });
      setRegisterData({ ...registerData, [name]: e.value });
    }
  };

  const HandleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setRegisterData({ ...registerData, [name]: value });
  };

  const HandleRegister = (values) => {
    delete values.uf;

    api
      .post(Prefix.replace(/[\\"]/g, '') + '/proposal-requests', values)
      .then(() => {
        Swal.fire({
          icon: 'success',
          title: 'Uow!',
          text: 'Cadastro realizado com sucesso!',
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: 'Fechar',
          cancelButtonText: 'Novo Cadastro',
          allowOutsideClick: false,
        }).then((result) => {
          if (!result.isConfirmed) {
            setShow(true);
          }
        });
        // setRegisterData(initialState);
        setShow(false);
        mutateGlobal(
          Prefix.replace(/[\\"]/g, '') +
            '/proposal-requests?per_page=' +
            perPage +
            '&page=' +
            pageExact +
            '&search=' +
            search,
        );
      })
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: err.response.data.message,
          confirmButtonText: 'Tentar novamente',
        });
      });
  };

  const classes = useStyles();
  return (
    <>
      {!show ? (
        <Fab className={classes.fab} onClick={handleShow}>
          <AddIcon className={classes.icon} />
        </Fab>
      ) : (
        <ModalContainer
          show={show}
          onHide={handleClose}
          size={modalSize}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title><h3>Cadastro<br/> Solicitações de Propostas</h3></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              components={{ NoOptionsMessage }}
              initialValues={registerData}
              validationSchema={Schema}
              onSubmit={HandleRegister}
              enableReinitialize
            >
              {({ values }) => (
                <Form>
                  <Row>
                    <Col>
                      <div className="formStep">
                        <div className="labelForm">
                          <label className="important">Nome completo</label>
                        </div>
                        <Field
                          type="text"
                          placeholder="Digite um nome"
                          name="name"
                          onChange={HandleChange}
                        />
                        <ErrorMessage
                          name="name"
                          component="span"
                          className="errorMessages"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="formStep">
                        <div className="labelForm">
                          <label className="important">Empresa</label>
                        </div>
                        <Field
                          type="text"
                          placeholder="Digite a empresa"
                          name="company"
                          onChange={HandleChange}
                        />
                        <ErrorMessage
                          name="company"
                          component="span"
                          className="errorMessages"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="formStep">
                        <div className="labelForm">
                          <label className="important">CNPJ</label>
                        </div>
                        <Field
                          type="text"
                          placeholder={'Digite um CNPJ'}
                          name="cnpj"
                          onKeyPress={maskCNPJ}
                          onBlur={validateDocument}
                        />
                        {messageDocument === false ? (
                          <span className="errorMessages">CNPJ inválido</span>
                        ) : (
                          <ErrorMessage
                            name="cnpj"
                            component="span"
                            className="errorMessages"
                          />
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="formStep">
                        <div className="labelForm">
                          <label className="important">Telefone</label>
                        </div>
                        <Field
                          type="text"
                          placeholder="Digite um telefone"
                          name="phone"
                          onKeyPress={maskTel}
                          onChange={HandleChange}
                        />
                        <ErrorMessage
                          name="phone"
                          component="span"
                          className="errorMessages"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="formStep">
                        <div className="labelForm">
                          <label className="important">E-mail</label>
                        </div>
                        <Field
                          type="email"
                          placeholder="Digite um e-mail"
                          name="email"
                          onChange={HandleChange}
                        />
                        <ErrorMessage
                          name="email"
                          component="span"
                          className="errorMessages"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="formStep">
                        <label className="important">Estado</label>

                        <Select
                          value={valueSelect
                            .filter((fill) => fill.nameField == 'uf')
                            .map((map) => {
                              return {
                                value: map.value,
                                label: map.label,
                              };
                            })}
                          styles={colourStyles}
                          components={{ NoOptionsMessage }}
                          onChange={(e) => HandleSelect(e, 'uf')}
                          placeholder="Selecione..."
                          options={uf.map((state) => {
                            return { value: state.value, label: state.name };
                          })}
                        />

                        <ErrorMessage
                          name="uf"
                          component="span"
                          className="errorMessages"
                        />
                      </div>
                    </Col>
                    <Col>
                      <div className="formStep">
                        <label className="important">Cidade</label>
                        <AsyncPaginate
                          value={valueSelect
                            .filter((fill) => fill.nameField == 'id_city')
                            .map((map) => {
                              return {
                                value: map.value,
                                label: map.label,
                              };
                            })}
                          loadOptions={loadOptions}
                          onChange={(e) => HandleSelect(e, 'id_city')} //values and name of select paginate
                          placeholder="Selecione..."
                          cacheUniqs={[values?.uf]} //Set your param that is going to change when you select another field. Just use this option when you are setting params and need to refresh cache.
                          styles={colourStyles}
                          additional={{
                            page: 1,
                            endpoint: '/cities',
                            params: `uf=${values?.uf}`, //Separete with & (and commercial)
                          }}
                        />
                        <ErrorMessage
                          name="id_city"
                          component="span"
                          className="errorMessages"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="formStep">
                        <div className="labelForm">
                          <label className="important">Descrição</label>
                        </div>
                        <Field
                          as="textarea"
                          type="description"
                          placeholder="Digite uma descrição"
                          name="description"
                          onChange={HandleChange}
                        />
                        <ErrorMessage
                          name="description"
                          component="span"
                          className="errorMessages"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="d-flex justify-content-center mt-4">
                      <CustomButton type="submit">
                       <TextButton >Cadastrar</TextButton> 
                      </CustomButton>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Modal.Body>
        </ModalContainer>
      )}
    </>
  );
};
export default AddProposal;
