import 'leaflet/dist/leaflet.css';
import React, { useState, useEffect, useContext } from 'react';
import { Map, Marker, Popup, TileLayer } from 'react-leaflet';
import Leaflet from 'leaflet';
import { v4 as uuidv4 } from 'uuid';
import { loadOptions } from '../../components/selectAsync/loadOptions';
import { AsyncPaginate } from 'react-select-async-paginate';
import { Modal, Row, Col, Table } from 'react-bootstrap';
import Image from '../../components/mapBox/images/images';
import Loading from '../../components/loader/loading';
import moment from 'moment';
import Device from '../../components/mapBox/device/device';
import ListDevices from '../../components/mapBox/listDevices/listDevices';

import mapPackage from '../../components/mapBox/location.png';

import './map.css';

import api from '../../services/api';
import { Prefix } from '../../services/prefix';
import { useFetch } from '../../components/hooks/useFetch';
import { Button } from 'react-bootstrap';
import { Context } from '../../services/context';

const App = () => {
  const { isActiveToggle } = useContext(Context);
  const [valueSelect, setValueSelect] = useState([]);
  const [registerData, setRegisterData] = useState([]);
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const [page, setPage] = useState(1);
  const [detail, setDetail] = useState(null);
  const [perPage, setPerPage] = useState(5);
  const [listEquip, setListEquip] = useState({
    equips: [],
    to: 0,
  });
  const [search, setSearch] = useState({
    id_unit: '',
  });
  const { REACT_APP_ACCESS_TOKEN_MAP_BOX } = process.env;
  const group = Prefix.replace(/[\\"]/g, '');

  const handleClose = () => {
    setShow(false);
    setListEquip({
      equips: [],
      to: 0,
    });
    setSearch({ ...search, id_unit: '' });
    setValueSelect([]);
  };
  const handleShow = (e) => {
    setSearch({ ...search, id_unit: e });
    setShow(true);
  };

  const loadMore = () => {
    setPage(parseInt(page) + 1);
  };

  const HandleDetail = (e) => {
    setDetail(e);
  };

  const initialPosition = { lat: -12.74043, lng: -60.11515 };

  const idClient = !valueSelect
    .filter((fill) => fill.nameField == 'id_customer')
    .map((map) => map.value)
    ? ''
    : valueSelect
        .filter((fill) => fill.nameField == 'id_customer')
        .map((map) => map.value);

  const idContract = !valueSelect
    .filter((fill) => fill.nameField == 'id_contract')
    .map((map) => map.value)
    ? ''
    : valueSelect
        .filter((fill) => fill.nameField == 'id_contract')
        .map((map) => map.value);

  const idFamily = !valueSelect
    .filter((fill) => fill.nameField == 'id_family')
    .map((map) => map.value)
    ? ''
    : valueSelect
        .filter((fill) => fill.nameField == 'id_family')
        .map((map) => map.value);

  const idCategory = !valueSelect
    .filter((fill) => fill.nameField == 'id_category')
    .map((map) => map.value)
    ? ''
    : valueSelect
        .filter((fill) => fill.nameField == 'id_category')
        .map((map) => map.value);

  const idSector = !valueSelect
    .filter((fill) => fill.nameField == 'id_sector')
    .map((map) => map.value)
    ? ''
    : valueSelect
        .filter((fill) => fill.nameField == 'id_sector')
        .map((map) => map.value);

  const idRoom = !valueSelect
    .filter((fill) => fill.nameField == 'id_room')
    .map((map) => map.value)
    ? ''
    : valueSelect
        .filter((fill) => fill.nameField == 'id_room')
        .map((map) => map.value);

  const units = useFetch(
    Prefix.replace(/[\\"]/g, '') +
      '/units?id_customer=' +
      idClient +
      '&id_contract=' +
      idContract +
      '&id_equipment_family=' +
      idFamily +
      '&id_equipment_category=' +
      idCategory,
  );

  const findEquip = useFetch(
    detail && Prefix.replace(/[\\"]/g, '') + '/contract-items/' + detail,
  );
  const infoEquip = findEquip.data?.model;

  useEffect(() => {
    setLoader(true);
    if (page > 1) {
      api
        .get(
          Prefix.replace(/[\\"]/g, '') +
            '/contract-items?id_unit=' +
            search.id_unit +
            '&id_sector=' +
            idSector +
            '&id_room=' +
            idRoom +
            '&per_page=' +
            perPage +
            '&page=' +
            page,
        )
        .then((response) => {
          setListEquip((prevState) => ({
            equips: [...prevState.equips, ...response.data?.models.data],
            to: response.data?.models.to,
          }));
          setLoader(false);
        });
    } else {
      api
        .get(
          Prefix.replace(/[\\"]/g, '') +
            '/contract-items?id_unit=' +
            search.id_unit +
            '&id_sector=' +
            idSector +
            '&id_room=' +
            idRoom +
            '&per_page=' +
            perPage +
            '&page=' +
            page,
        )
        .then((response) => {
          setListEquip({
            equips: response.data.models.data,
            to: response.data?.models.to,
          });
          setLoader(false);
        });
    }
  }, [show, page, valueSelect]);

  const mapPackageIcon = Leaflet.icon({
    iconUrl: mapPackage,
    iconSize: [20, 20],
    iconAnchor: [0],
    popupAnchor: [170, 2],
  });

  const HandleSelect = (e, name) => {
    if (valueSelect.filter((fill) => fill.nameField == name).length > 0) {
      valueSelect
        .filter((fill) => fill.nameField == name)
        .map((map) => {
          let valueIndice = valueSelect.indexOf(map);
          valueSelect.splice(valueIndice);
        });

      setValueSelect((prev) => {
        return [...prev, { value: e.value, label: e.label, nameField: name }];
      });
      setRegisterData({ ...registerData, [name]: e.value });
    } else {
      setValueSelect((prev) => {
        return [...prev, { value: e.value, label: e.label, nameField: name }];
      });
      setRegisterData({ ...registerData, [name]: e.value });
    }
  };

  return (
    <>
      <div id="page-map" key={isActiveToggle == true ? uuidv4() : ''}>
        {group != 'customer' && (
          <main>
            <form className="landing-page-form">
              <fieldset>
                <legend>Unidades</legend>
                <div className="input-block">
                  <AsyncPaginate
                    value={valueSelect
                      .filter((fill) => fill.nameField == 'id_customer')
                      .map((map) => {
                        return {
                          value: map.value,
                          label: map.label,
                        };
                      })}
                    loadOptions={loadOptions}
                    classNamePrefix="filter"
                    onChange={(e) => HandleSelect(e, 'id_customer')}
                    placeholder="Selecione um cliente..."
                    additional={{
                      page: 1,
                      endpoint: '/customers',
                    }}
                  />
                </div>
                {valueSelect.filter((fill) => fill.nameField == 'id_customer')
                  .length > 0 && (
                  <div className="input-block">
                    <AsyncPaginate
                      value={valueSelect
                        .filter((fill) => fill.nameField == 'id_contract')
                        .map((map) => {
                          return {
                            value: map.value,
                            label: map.label,
                          };
                        })}
                      loadOptions={loadOptions}
                      classNamePrefix="filterProposal"
                      onChange={(e) => HandleSelect(e, 'id_contract')}
                      placeholder="Selecione um contrato..."
                      cacheUniqs={[valueSelect]}
                      additional={{
                        page: 1,
                        endpoint: '/contracts',
                        params: `id_customer=${valueSelect
                          .filter((fill) => fill.nameField == 'id_customer')
                          .map((map) => map.value)}`,
                      }}
                    />
                  </div>
                )}

                <div className="input-block">
                  <AsyncPaginate
                    value={valueSelect
                      .filter((fill) => fill.nameField == 'id_family')
                      .map((map) => {
                        return {
                          value: map.value,
                          label: map.label,
                        };
                      })}
                    loadOptions={loadOptions}
                    classNamePrefix="filter"
                    onChange={(e) => HandleSelect(e, 'id_family')}
                    placeholder="Selecione uma família de equipamento..."
                    cacheUniqs={[valueSelect]}
                    additional={{
                      page: 1,
                      endpoint: '/equipament-families',
                    }}
                  />
                </div>

                {valueSelect.filter((fill) => fill.nameField == 'id_family')
                  .length > 0 && (
                  <div className="input-block">
                    <AsyncPaginate
                      value={valueSelect
                        .filter((fill) => fill.nameField == 'id_category')
                        .map((map) => {
                          return {
                            value: map.value,
                            label: map.label,
                          };
                        })}
                      loadOptions={loadOptions}
                      classNamePrefix="filter"
                      onChange={(e) => HandleSelect(e, 'id_category')}
                      placeholder="Selecione uma categoria de equipamento..."
                      cacheUniqs={[valueSelect]}
                      additional={{
                        page: 1,
                        endpoint: '/equipament-categories',
                        params: `id_family=${valueSelect
                          .filter((fill) => fill.nameField == 'id_family')
                          .map((map) => map.value)}`,
                      }}
                    />
                  </div>
                )}
              </fieldset>
            </form>
          </main>
        )}

        <Map
          center={initialPosition}
          zoom={5}
          style={{ width: '100%', height: '100%' }}
        >
          {/* <TileLayer url="https://a.tile.openstreetmap.org/{z}/{x}/{y}.png" /> */}
          <TileLayer
            url={`https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/256/{z}/{x}/{y}@2x?access_token=${REACT_APP_ACCESS_TOKEN_MAP_BOX}`}
          />
          {units.data?.models.data
            .filter((fill) => fill.lat != null && fill.lng != null)
            .map((unit) => (
              <Marker
                key={unit?.id}
                icon={mapPackageIcon}
                position={[unit?.lat, unit?.lng]}
              >
                <Popup
                  closeButton={false}
                  minWidth={240}
                  maxWidth={240}
                  className="map-popup"
                >
                  <div>
                    <h3>
                      {unit?.name} - {unit?.customer?.name}
                    </h3>
                    <p>
                      {unit?.address}, {unit?.number}, {unit?.district},{' '}
                      {unit?.city?.name}, {unit?.city?.uf}
                    </p>
                    <p>
                      <Button
                        className="btn-map"
                        onClick={() => handleShow(unit.id)}
                      >
                        Equipamentos
                      </Button>
                    </p>
                  </div>
                </Popup>
              </Marker>
            ))}
        </Map>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Detalhes da unidade</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!detail && (
            <>
              <Row className="pl-5 pr-5 pb-5">
                <Col>
                  <AsyncPaginate
                    value={valueSelect
                      .filter((fill) => fill.nameField == 'id_sector')
                      .map((map) => {
                        return {
                          value: map.value,
                          label: map.label,
                        };
                      })}
                    loadOptions={loadOptions}
                    classNamePrefix="filter"
                    onChange={(e) => HandleSelect(e, 'id_sector')} //values and name of select paginate
                    placeholder="Selecione um setor..."
                    cacheUniqs={[valueSelect]} //Set your param that is going to change when you select another field. Just use this option when you are setting params and need to refresh cache.
                    additional={{
                      page: 1,
                      endpoint: '/sectors',
                      params: `id_unit=${search.id_unit}`, //Separete with & (and commercial)
                    }}
                  />
                </Col>
                <Col>
                  <AsyncPaginate
                    value={valueSelect
                      .filter((fill) => fill.nameField == 'id_room')
                      .map((map) => {
                        return {
                          value: map.value,
                          label: map.label,
                        };
                      })}
                    loadOptions={loadOptions}
                    classNamePrefix="filter"
                    onChange={(e) => HandleSelect(e, 'id_room')} //values and name of select paginate
                    placeholder="Selecione uma sala..."
                    cacheUniqs={[valueSelect]} //Set your param that is going to change when you select another field. Just use this option when you are setting params and need to refresh cache.
                    additional={{
                      page: 1,
                      endpoint: '/rooms',
                      params: `id_sector=${valueSelect
                        .filter((fill) => fill.nameField == 'id_sector')
                        .map((map) => map.value)}`, //Separete with & (and commercial)
                    }}
                  />
                </Col>
              </Row>

              <ListDevices
                listEquip={listEquip}
                setDetail={HandleDetail}
                loader={loader}
                detail={detail}
                loadMore={loadMore}
              />
            </>
          )}

          {detail && (
            <>
              {!findEquip.data && <Loading />}
              <Device infoEquip={infoEquip} loader={findEquip} />
              <Row>
                <Col className="d-flex justify-content-center">
                  <Button
                    variant="success mt-4"
                    onClick={() => setDetail(null)}
                  >
                    Voltar
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default App;
