import React, { useState } from "react";
import "./employers_clients.css";
import { columns } from "./columns.jsx";
import MUIDataTable from "mui-datatables";
import CustomSearchRender from "./CustomSearchRender";
import Fontawesome from "../../../components/fontawesome/fontawesome";
import { Prefix } from "../../../services/prefix";
import { useFetch } from "../../../components/hooks/useFetch";
import Loading from "../../../components/loader/loading";
import api from "../../../services/api";
import Swal from "sweetalert2";
import CustomFooter from "../../../components/CustomFooter/CustomFooter";
import { Row, Col, Button, Modal, Table, Card } from "react-bootstrap";
import { TableRow, TableCell } from "@material-ui/core";
import Topbar from "../../../components/topbar/topbar";
import Sidebar from "../../../components/sidebar/sidebar";
import {
  CardSt,
  CardHeaderSt,
  IconTitleSt,
  TitleSt,
  TextTitleSt,
  SmallTitleSt,
} from "../../../style/globalStyle";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { GetMuiTheme } from "../../../components/muiTheme/muiTheme";
import { mutate as GlobalMutate } from "swr";

export const IndexEmployers = () => {
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState();
  const [pagina, setPagina] = useState(0);
  const [perPage, setPerPage] = useState(15);
  const [search, setSearch] = useState("");
  const [employer, setEmployer] = useState(false);
  const [orderBy, setOrderBy] = useState("id");
  const [order, setOrder] = useState("desc");

  let pageExact = pagina + parseInt(1);

  const employeeClientList = useFetch(
    Prefix.replace(/[\\"]/g, "") +
      "/customer-employees?per_page=" +
      perPage +
      "&page=" +
      pageExact +
      "&search=" +
      search +
      "&order_by=" +
      orderBy +
      "&order=" +
      order
  );

  const options = {
    draggableColumns: {
      enabled: true,
    },
    serverSide: true,
    onTableChange: (action, tableState) => {
      setPagina(tableState.page);
      setPerPage(tableState.rowsPerPage);
    },
    onSearchChange: (searchText) => {
      setSearch(!searchText ? "" : searchText);
    },
    customSearchRender: (searchText, handleSearch, hideSearch, options) => {
      return (
        <CustomSearchRender
          placeholderSearch="Pesquisar funcionário"
          searchText={searchText}
          onSearch={handleSearch}
          onHide={hideSearch}
          options={options}
        />
      );
    },
    selectableRows: "none",
    filter: false,
    page: pagina,
    count: employeeClientList.data?.models?.total,
    expandableRows: true,
    expandableRowsHeader: false,
    expandableRowsOnClick: false,
    rowsPerPage: perPage,
    rowsPerPageOptions: [15, 30, 100],
    isRowExpandable: (dataIndex, expandedRows) => {
      //if (dataIndex === 3 || dataIndex === 4) return false;
      if (
        expandedRows.data.length > 4 &&
        expandedRows.data.filter((d) => d.dataIndex === dataIndex).length === 0
      )
        return false;
      return true;
    },
    renderExpandableRow: (rowData, rowMeta) => {
      const colSpan = rowData.length + 1;
      return (
        <TableRow>
          <TableCell colSpan={colSpan}>
            <Row>
              <Col className="text-center">
                <h4>Dados do Funcionário</h4>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Email:</th>
                      <th>Tipo:</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td> {!rowData[4] ? "Não informado" : rowData[4]}</td>
                      <td> {rowData[5] !== 1 ? "Funcionário" : "Técnico"}</td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </TableCell>
        </TableRow>
      );
    },
    onRowsDelete: (rowsDeleted, newData) => {
      HandleDelete(rowsDeleted.data);
    },
    searchPlaceholder: "Pesquise um funcionário",

    searchOpen: true,
    body: {
      noMatch: "Desculpe, não encontramos funcionários!",
      toolTip: "Sort",
      columnHeaderTooltip: (column) => `Sort for ${column.label}`,
    },
    textLabels: {
      pagination: {
        next: "Avançar",
        previous: "Voltar",
        rowsPerPage: "Linhas por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Pesquisar",
        downloadCsv: "Baixar CSV",
        print: "Imprimir",
        viewColumns: "Ver Colunas",
        filterTable: "Filtrar tabela",
      },
      filter: {
        all: "Tudo",
        title: "FILTROS",
        reset: "RESETAR",
      },
      viewColumns: {
        title: "Exibir colunas",
        titleAria: "Exibir/Ocultar colunas da tabela",
      },
      // selectedRows: {
      //   text: 'Coluna(s) selecionada(s)',
      //   delete: 'Excluir',
      //   deleteAria: 'Excluir linhas selecionadas',
      // },
    },
    customFooter: (
      count,
      page,
      rowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels
    ) => {
      let numberPages = employeeClientList.data?.models?.last_page;
      let rowsPerPageOptions = [15, 30, 100];
      return (
        <CustomFooter
          page={page}
          rowsPerPage={rowsPerPage}
          changeRowsPerPage={changeRowsPerPage}
          changePage={changePage}
          numberPages={numberPages}
          rowsPerPageOptions={rowsPerPageOptions}
        />
      );
    },
  };

  function HandleDelete(e) {
    setLoader(<Loading />);
    let idArr = [];

    e.map((del) => {
      idArr.push(
        employeeClientList.data?.models?.data.filter(
          (filtro, index) => index == del.index
        )[0].id
      );
    });

    idArr.map((idDel) =>
      api
        .delete(Prefix.replace(/[\\"]/g, "") + "/client-employees/" + idDel)
        .then((response) => {
          Swal.fire({
            icon: "success",
            title: "Uow!",
            text: response.data.message,
            confirmButtonText: "Fechar",
          });
          setLoader();
          employeeClientList.mutate();
          GlobalMutate(
            Prefix.replace(/[\\"]/g, "") +
              "/customer-employees?per_page=" +
              perPage +
              "&page=" +
              pageExact +
              "&search=" +
              search +
              "&order_by=" +
              orderBy +
              "&order=" +
              order
          );
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: err.response.data.message,
            confirmButtonText: "Tentar novamente",
          });
          setLoader();
        })
    );
  }

  return (
    <div className="wrapper d-flex align-items-stretch">
      <Sidebar />
      <div id="content">
        <Topbar />
        <div className="content_all">
          {loader}
          <CardSt>
            <CardHeaderSt className="d-flex justify-content-between">
              <TitleSt>
                <IconTitleSt
                  style={{ width: "36px" }}
                  src="../../../media/icons-pages/employers-page-sis.svg"
                />
                <TextTitleSt>
                  Colaboradores{" "}
                  <SmallTitleSt style={{ marginRight: "8px" }}>
                    Gestão de Colaboradores{" "}
                  </SmallTitleSt>
                </TextTitleSt>
              </TitleSt>
            </CardHeaderSt>
            <CardSt.Body as="div">
              <CardSt.Text as="div">
                <MuiThemeProvider theme={GetMuiTheme()}>
                  <MUIDataTable
                    data={employeeClientList.data?.models?.data}
                    columns={columns}
                    options={options}
                  />
                </MuiThemeProvider>
              </CardSt.Text>
            </CardSt.Body>
          </CardSt>
        </div>
      </div>
    </div>
  );
};
export default IndexEmployers;
