import React, { useState } from 'react';
import './style.css';
import { uf } from '../../data/uf';
import api from '../../../services/api';
import Swal from 'sweetalert2';
import Loading from '../../loader/loading';

const RequestRegister = (props) => {
  const [listCity, setListCity] = useState([]);
  const [formData, setFormData] = useState([]);
  const [loader, setLoader] = useState();

  function HandleChange(e) {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  function HandleCity(e) {
    let dataCity = {
      uf: e.target.value,
    };
    api
      .post('cities', dataCity)
      .then((response) => setListCity(response.data.models));
  }

  function HandleRegister() {
    setLoader(<Loading />);
    api
      .post('registration-request', formData)
      .then((response) => {
        Swal.fire({
          icon: 'success',
          title: 'Uow!',
          text: response.data.message,
          confirmButtonText: 'Continuar',
        });
        setLoader();
        props.functionBack();
      })
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: err.response.data.message,
          confirmButtonText: 'Tentar novamente',
        });
        setLoader();
      });
  }

  return (
    <div className="container_login">
      {loader}
      <div className="col-12 mt_form flip-in-hor-bottom">
        <div className="mt_register_head">
          <h3 className="mt_register_title">Solicitar cadastro</h3>
          <div className="mt_register_desc">
            Preencha os campos abaixo que em breve entraremos em contato:
          </div>
        </div>
        <div className="input-group">
          <input
            className="form-control"
            type="text"
            placeholder="Nome completo"
            name="name"
            onBlur={HandleChange}
          />
        </div>
        <div className="input-group">
          <input
            className="form-control"
            type="text"
            placeholder="E-mail"
            name="email"
            autocomplete="off"
            onBlur={HandleChange}
          />
        </div>
        <div className="input-group">
          <input
            className="form-control"
            type="text"
            placeholder="Telefone"
            name="phone"
            onBlur={HandleChange}
          />
        </div>
        <div className="input-group mt-4">
          <select
            name="uf"
            class="form-control select-form"
            onChange={HandleCity}
          >
            <option selected disabled>
              Selecione um estado...
            </option>
            {uf.map((estado) => {
              return <option value={estado.value}>{estado.label}</option>;
            })}
          </select>
        </div>
        <div className="input-group mt-4">
          <select
            name="id_city"
            class="form-control select-form"
            onChange={HandleChange}
          >
            <option selected disabled>
              Selecione a cidade...
            </option>
            {listCity.map((cidade) => {
              return <option value={cidade.id}>{cidade.name}</option>;
            })}
          </select>
        </div>

        <div className="mt_register_actions">
          <button
            id="kt_register_signup_submit"
            className="mt_button"
            onClick={HandleRegister}
          >
            Enviar
          </button>
          &nbsp;&nbsp;
          <button
            id="kt_register_signup_cancel"
            className="mt_button"
            onClick={props.functionBack}
          >
            Voltar
          </button>
        </div>
      </div>
    </div>
  );
};
export default RequestRegister;
