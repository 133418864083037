import React, { useState, useEffect, useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import Fontawesome from '../../fontawesome/fontawesome';
import { Link } from 'react-router-dom';
import '../contracts.css';
import MUIDataTable from 'mui-datatables';
import { columns } from './columnsScheme';
import { useFetch } from '../../hooks/useFetch';
import { Prefix } from '../../../services/prefix';
import Loading from '../../loader/loading';
import { Context } from '../../../services/context';
import { ModalContainer } from "../../../style/stylesModals"; 

const options = {
  filterType: ' ',
  filter: false,
  search: false,
  print: false,
  download: false,
  viewColumns: false,
  draggableColumns: {
    enabled: true,
  },
  searchPlaceholder: 'Pesquisar',
  selectableRows: 'multiple',

  searchOpen: false,

  customFooter: () => '',
  textLabels: {
    body: {
      noMatch: 'Desculpe, não encontramos licenças!',
      toolTip: 'Sort',
      columnHeaderTooltip: (column) => `Sort for ${column.label}`,
    },
    toolbar: {
      search: 'Pesquisar',
      downloadCsv: 'Baixar CSV',
      print: 'Imprimir',
      viewColumns: 'Ver Colunas',
      filterTable: 'Filtrar tabela',
    },
    filter: {
      all: 'Tudo',
      title: 'FILTROS',
      reset: 'RESETAR',
    },
    viewColumns: {
      title: 'Exibir colunas',
      titleAria: 'Exibir/Ocultar colunas da tabela',
    },
    selectedRows: {
      text: 'Coluna(s) selecionada(s)',
      delete: 'Excluir',
      deleteAria: 'Excluir linhas selecionadas',
    },
  },
};

export default function ModalAttachments(props) {
  const [show, setShow] = useState(false);
  const { setIdContractLicenses } = useContext(Context);

  useEffect(() => {
    return setIdContractLicenses(props.idContract);
  }, [show]);

  const licenses = useFetch(Prefix.replace(/[\\"]/g, '') + '/schemes');

  if (!licenses) return <Loading />;

  const handleClose = () => {
    setShow(false);
    setIdContractLicenses(null);
  };

  const handleShow = () => setShow(true);

  return (
    <>
      {!show ? (
        <Link className="dropdown-item" to="#" onClick={handleShow}>
          <Fontawesome classe={props.iconClass} /> {props.text}
        </Link>
      ) : (
        <ModalContainer 
          show={show}
          size={props.modalSize}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          idContract={props.idContract}
        >
          <Modal.Header closeButton>
            <Modal.Title><h3>{props.text}</h3></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <MUIDataTable
              data={licenses.data?.models?.data}
              columns={columns}
              options={options}
              components={{ Checkbox: () => false }}
            />
          </Modal.Body>
        </ModalContainer >
      )}
    </>
  );
}
