import styled from "styled-components";

export const Caption = styled.p`
text-align: center;
color:#000000;
font-family: Poppins;
font-style: normal;
font-weight: normal;
font-size: 18px;
`;

export const ButtonProposal = styled.button`
  background-color: #822a2a;
  width: 200px;
  height: 60px;
  border: 0;
  border-radius: 30px;
  margin-top: 50px;
`;

export const TextProposal = styled.span`
  font-family: Poppins;
  text-align: center;
  vertical-align: center;
  color: #ffffff;
`;

export const Input = styled.input`
  height: 50px;
  background: #F6F6F6;
  border-radius: 13px;
  width: 550px;
  border:0;
  border-color: #e2e5ec;
  &:input:focus {
    border-color: #e2e5ec,
}
`;

export const SelectInput = styled.select`
height: 50px;
background: #F6F6F6;
border-radius: 13px;

border:0;
border-color: #e2e5ec;
&:input:focus {
  border-color: #e2e5ec,
}
`;

export const BoxFeedback = styled.div`
width: 70%;
text-align: left;
color: #000000;
border-radius: 20px;
background-color:#E6E6E6;
padding: 30px;
`;

export const TextFeedback = styled.p`
padding: 10px;
`;

export const DateFeedback = styled.p`
padding: 10px;
`;