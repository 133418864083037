import styled from 'styled-components';
import { Alert } from 'react-bootstrap';
import InputSpinner from 'react-bootstrap-input-spinner';


export const AlertContainer = styled(Alert)`
border-radius: 15px;
`;

export const Title = styled.div`
margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
    color: #6C6D77;
    text-align: center;
`;

export const ButtonRange = styled.button`
  width: 20%;
  min-height: 35px;
  background: #00ba88;
  border-radius: 0px 5px 5px 0px;
  border: 0;
  color: #fff;

  &:hover {
    opacity: 0.5;
    transition: 0.5s;
  }
`;

export const InputTime = styled.input`
width: 70%;
&:focus { 
    outline: none !important;
    outline-style: solid;
    border-color: #719ECE;
}
`;

export const ImgButton = styled.img`
color: #fff;
`;