import React from 'react';
import UpdateMonitoring from './updateMonitoring';

export const columns = [
  {
    name: 'id',
    label: 'id',
    options: {
      filter: false,
      sort: false,
      display: 'excluded',
    },
  },
  {
    //4
    name: 'contract_item',
    label: 'Equipamento',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      customBodyRender: (value, tableMeta) => {
        return value.nickname;
      },
    },
  },
  {
    name: 'identification',
    label: 'Identificação',
    options: {
      filter: false,
      sort: false,
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
          width: '17%',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
    },
  },
  {
    name: 'plataform',
    label: 'Plataforma',
    options: {
      filter: false,
      sort: false,
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
          width: '17%',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      customBodyRender: (value, tableMeta) => {
        if (value == 'whatsapp') {
          return <i class="fab fa-whatsapp" style={{ fontSize: '25px' }}></i>;
        } else {
          return <i class="far fa-envelope" style={{ fontSize: '25px' }}></i>;
        }
      },
    },
  },

  {
    name: 'id_user',
    label: 'id_user',
    options: {
      filter: false,
      sort: false,
      display: 'excluded',
    },
  },

  {
    name: 'monitoring',
    label: 'Monitoramento',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
          width: '17%',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: true,
      sort: false,
      customBodyRender: (value, tableMeta) => {
        return (
          <UpdateMonitoring
            id={tableMeta.rowData[0]}
            page={tableMeta.tableState.page}
            rowsPage={tableMeta.tableState.rowsPerPage}
            search={
              !tableMeta.tableState.searchText
                ? ''
                : tableMeta.tableState.searchText
            }
            orderBy={tableMeta.tableState.sortOrder.name}
            order={tableMeta.tableState.sortOrder.direction}
            activeDefault={value}
            idUser={tableMeta.rowData[4]}
          />
        );
      },
    },
  },
];
