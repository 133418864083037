import * as Yup from 'yup';

Yup.setLocale({
  mixed: {
    required: 'Campo obrigatório',
  },
  string: {
    email: 'Preencha um e-mail válido',
    min: 'Mínimo de ${min} caracteres',
    max: 'Máximo de ${max} caracteres',
  },
  number: {
    min: 'Deve ser maior ou igual a ${min}',
    max: 'Deve ser menor ou igual a ${max}',
  },
});

const Schema = Yup.object().shape({
  name: Yup.string().min(3).required(),
  company: Yup.string().min(3).required(),
  cnpj: Yup.string().min(3).required(),
  phone: Yup.string().min(3).required(),
  email: Yup.string().email().min(3).required(),
  uf: Yup.string().min(1).required(),
  id_city: Yup.number().min(1).required(),
  description: Yup.string().min(1).required(),
});
export default Schema;
