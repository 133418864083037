import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Fontawesome from '../../fontawesome/fontawesome';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import api from '../../../services/api';
import { Prefix } from '../../../services/prefix';
import Swal from 'sweetalert2';
import { ModalContainer } from "../../../style/stylesModals"; 
import { CustomButton, TextButton, ButtonDesabled} from "../../../style/stylesButton";

const Password = ({ id }) => {
  const [show, setShow] = useState(false);
  const [pass, setPass] = useState();
  const [confirmPass, setConfirmPass] = useState();

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const HandleRegister = () => {
    const formData = {
      new_password: pass,
      password_confirmation: confirmPass,
    };
    api
      .put(
        Prefix.replace(/[\\"]/g, '') + '/users/' + id + '/edit-password',
        formData,
      )
      .then((response) => {
        Swal.fire({
          icon: 'success',
          title: 'Uow!',
          text: response.data.message,
          confirmButtonText: 'Fechar',
        });
        setShow(false);
      })
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: err.response.data.message,
          confirmButtonText: 'Tentar novamente',
        });
      });
  };

  return (
    <>
      <Link  className="dropdown-item" to="#" onClick={handleShow}>
        <Fontawesome classe="fas fa-lock" /> Trocar Senha
      </Link>

      <ModalContainer
        show={show}
        size="sm"
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title><h3>Atualização<br/> de senha</h3></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="formStep">
            <Row>
              <Col>
                <label>Nova senha:</label>
                <input
                  type="password"
                  name="new_password"
                  onBlur={(e) => setPass(e.target.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <label>Confirmar senha:</label>
                <input
                  type="password"
                  name="password_confirmation"
                  onBlur={(e) => setConfirmPass(e.target.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col className="d-flex justify-content-center mt-3">
                <CustomButton onClick={HandleRegister}>
                 <TextButton>Atualizar</TextButton> 
                </CustomButton>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </ModalContainer>
    </>
  );
};
export default Password;
