import styled from 'styled-components';

export const Proposal = styled.div`
  display: flex;
  font-size: 20px;
  padding: 0 10px;
  color: #fff;
  justify-content: end;
  font-weight: bold;
`;

export const DateProposal = styled.div`
  background: #fff;
  display: flex;
  font-size: 20px;
  padding: 0 10px;
  border-radius: 15px;
  color: #000;
`;

export const TitleProposal = styled.div`
  background: #782e2e;
  display: flex;
  font-size: 16px;
  padding: 0 10px;
  border-radius: 15px;
  color: #fff;
  justify-content: center;
`;

export const BoxInner = styled.div`
  background: #f3f6f9;
  display: flex;
  font-size: 14px;
  border-radius: 15px;
  flex-direction: column;
  padding: 10px 15px;
`;
