import React, { useState, useEffect, useRef } from 'react';
import { useFetch } from '../../hooks/useFetch';
import { Col, Row, Button, Modal } from 'react-bootstrap';
import Fontawesome from '../../fontawesome/fontawesome';
import '../employer.css';
import { Prefix } from '../../../services/prefix';
import Loading from '../../loader/loading';
import Swal from 'sweetalert2';
import api from '../../../services/api';
import { Link } from 'react-router-dom';
import {
  maskTel,
  maskCPF,
  maskRG,
  maskTelFixo,
  maskCpfCnpj,
} from '../../../services/mask';
import { mutate as GlobalMutate } from 'swr';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Schema from './schema';
import { AsyncPaginate } from 'react-select-async-paginate';
import { loadOptions } from '../../selectAsync/loadOptions';
import { colourStyles } from '../../selectAsync/colourStyles';
import { uf } from '../../data/uf';
import Select, { components } from 'react-select';
import { ListEmployeeTypes } from '../listEmployeeTypes';
import { NoOptionsMessage } from '../../selectAsync/NoOptionsMessage';
import { ModalContainer } from '../../../style/stylesModals';
import { CustomButton, TextButton } from '../../../style/stylesButton';

const EditEmployer = ({ id, rowsPage, page, search, orderBy, order }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setValueSelect([]);
  };
  const handleShow = () => setShow(true);
  const [loader, setLoader] = useState();
  const [valueSelect, setValueSelect] = useState([]);
  const [valueSelectUf, setValueSelectUf] = useState([]);

  let valueSearch = !search ? '' : search;
  let pageExact = page + parseInt(1);

  const listEmployees = useFetch(
    !show ? null : Prefix.replace(/[\\"]/g, '') + '/employees/' + id,
  );
  const cidades = useFetch(Prefix.replace(/[\\"]/g, '') + '/cities');
  const tipos = useFetch(Prefix.replace(/[\\"]/g, '') + '/employees/types');

  const [registerData, setRegisterData] = useState({}); //--> Estado recebendo State com paramentro

  useEffect(() => {
    if (listEmployees?.data) {
      setRegisterData({
        name:
          listEmployees?.data?.model?.name == null
            ? ''
            : listEmployees?.data?.model?.name,
        email:
          listEmployees?.data?.model?.email == null
            ? ''
            : listEmployees?.data?.model?.email,
        type:
          listEmployees?.data?.model?.user?.type == null
            ? ''
            : listEmployees?.data?.model?.user?.type,
        rg:
          listEmployees?.data?.model?.rg == null
            ? ''
            : listEmployees?.data?.model?.rg,
        cpf:
          listEmployees?.data?.model?.cpf == null
            ? ''
            : listEmployees?.data?.model?.cpf,
        address:
          listEmployees?.data?.model?.address == null
            ? ''
            : listEmployees?.data?.model?.address,
        district:
          listEmployees?.data?.model?.district == null
            ? ''
            : listEmployees?.data?.model?.district,
        number:
          listEmployees?.data?.model?.number == null
            ? ''
            : listEmployees?.data?.model?.number,
        phone1:
          listEmployees?.data?.model?.phone1 == null
            ? ''
            : listEmployees?.data?.model?.phone1,
        phone2:
          listEmployees?.data?.model?.phone2 == null
            ? ''
            : listEmployees?.data?.model?.phone2,
        is_ems:
          listEmployees?.data?.model?.is_ems == null
            ? ''
            : listEmployees?.data?.model?.is_ems,
        is_efs:
          listEmployees?.data?.model?.is_efs == null
            ? ''
            : listEmployees?.data?.model?.is_efs,
        admission_at:
          listEmployees?.data?.model?.admission_at == null
            ? ''
            : listEmployees?.data?.model?.admission_at,
        id_city:
          listEmployees?.data?.model?.id_city == null
            ? ''
            : listEmployees?.data?.model?.id_city,
        uf:
          listEmployees?.data?.model?.city.uf == null
            ? ''
            : listEmployees?.data?.model?.city?.uf,
        technician:
          listEmployees?.data?.model?.technician == null
            ? ''
            : listEmployees?.data?.model?.technician,
      });
    }
  }, [listEmployees?.data]);

  const HandleChange = (e) => {
    let eventName = e.target.name;
    let eventValue = e.target.value;
    setRegisterData({ ...registerData, [eventName]: eventValue });
  };

  const HandleSelect = (e, name) => {
    if (valueSelect.filter((fill) => fill.nameField == name).length > 0) {
      valueSelect
        .filter((fill) => fill.nameField == name)
        .map((map) => {
          let valueIndice = valueSelect.indexOf(map);
          valueSelect.splice(valueIndice);
        });

      setValueSelect((prev) => {
        return [...prev, { value: e.value, label: e.label, nameField: name }];
      });
      setRegisterData({ ...registerData, [name]: e.value });
    } else {
      setValueSelect((prev) => {
        return [...prev, { value: e.value, label: e.label, nameField: name }];
      });
      setRegisterData({ ...registerData, [name]: e.value });
    }
  };

  const HandleRegister = (registerData) => {
    if (registerData.email == listEmployees?.data?.model.email) {
      delete registerData.email;
    }
    setLoader(true);
    api
      .put(Prefix.replace(/[\\"]/g, '') + '/employees/' + id, registerData)
      .then((response) => {
        Swal.fire({
          icon: 'success',
          title: 'Uow!',
          text: response.data.message,
          confirmButtonText: 'Continuar',
        });
        setLoader(false);
        setShow(false);
        setValueSelect([]);
        GlobalMutate(
          Prefix.replace(/[\\"]/g, '') +
            '/employees?per_page=' +
            rowsPage +
            '&page=' +
            pageExact +
            '&search=' +
            valueSearch +
            '&order_by=' +
            orderBy +
            '&order=' +
            order,
        );
      })
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: err.response.data.message,
          confirmButtonText: 'Tentar novamente',
        });
        setLoader(false);
      });
  };

  return (
    <>
      <Link to="#" className="dropdown-item" onClick={handleShow}>
        <Fontawesome classe="fas fa-pencil-alt mr-2" /> Editar
      </Link>

      <ModalContainer
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h3>Atualização de Colaborador(a)</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik //--> Configuração do Formik
            initialValues={registerData}
            validationSchema={Schema}
            onSubmit={HandleRegister}
            enableReinitialize
          >
            {(
              { values }, //--> Captação de valores
            ) => (
              <Form>
                <Row>
                  {loader}
                  <Col>
                    <div className="formStep">
                      <label class="important">Nome</label>
                      <Field
                        type="text"
                        name="name"
                        placeholder="Digite o nome..."
                        defaultValue={registerData?.name}
                        onBlur={HandleChange}
                      />
                      <ErrorMessage
                        name="name"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div className="formStep">
                      <label>CPF</label>
                      <Field
                        maxlength="14"
                        type="text"
                        name="cpf"
                        placeholder="Digite o cpf..."
                        defaultValue={maskCPF(values?.cpf)}
                        value={maskCPF(registerData?.cpf)}
                        onChange={HandleChange}
                      />
                      <ErrorMessage
                        name="cpf"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>

                  <Col>
                    <div className="formStep">
                      <label>RG</label>
                      <Field
                        maxlength="17"
                        type="text"
                        name="rg"
                        placeholder="Digite o rg..."
                        defaultValue={registerData?.rg}
                        onChange={HandleChange}
                      />
                      <ErrorMessage
                        name="rg"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div className="formStep">
                      <label>Endereço</label>
                      <Field
                        type="text"
                        name="address"
                        placeholder="Digite o endereço..."
                        defaultValue={registerData?.address}
                        onChange={HandleChange}
                      />
                      <ErrorMessage
                        name="address"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div className="formStep">
                      <label>Número</label>
                      <Field
                        type="text"
                        name="number"
                        placeholder="Digite o número..."
                        defaultValue={registerData?.number}
                        onChange={HandleChange}
                      />
                      <ErrorMessage
                        name="number"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>

                  <Col>
                    <div className="formStep">
                      <label>Bairro</label>
                      <Field
                        type="text"
                        name="district"
                        placeholder="Digite o bairro..."
                        defaultValue={registerData?.district}
                        onChange={HandleChange}
                      />
                      <ErrorMessage
                        name="district"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div className="formStep">
                      <label>Estado</label>
                      <Select
                        value={
                          valueSelect.length == 0
                            ? uf
                                .filter(
                                  (fill) => fill.value == registerData?.uf,
                                )
                                .map((map) => {
                                  return {
                                    value: map.uf,
                                    label: map.name,
                                  };
                                })
                            : valueSelect
                                .filter((fill) => fill.nameField == 'uf')
                                .map((map) => {
                                  return {
                                    value: map.value,
                                    label: map.label,
                                  };
                                })
                        }
                        styles={colourStyles}
                        onChange={(e) => HandleSelect(e, 'uf')}
                        components={{ NoOptionsMessage }}
                        placeholder="Selecione..."
                        options={uf.map((state) => {
                          return { value: state.value, label: state.name };
                        })}
                      />
                    </div>
                  </Col>
                  <Col>
                    <div className="formStep">
                      <label className="important">Cidade</label>
                      <AsyncPaginate
                        value={
                          valueSelect.length < 2
                            ? {
                                value: listEmployees.data?.model.city.id,
                                label: listEmployees.data?.model.city.name,
                              }
                            : valueSelect
                                .filter((fill) => fill.nameField == 'id_city')
                                .map((map) => {
                                  return {
                                    value: map.value,
                                    label: map.label,
                                  };
                                })
                        }
                        loadOptions={loadOptions}
                        onChange={(e) => HandleSelect(e, 'id_city')} //values and name of select paginate
                        placeholder="Selecione..."
                        components={{ NoOptionsMessage }}
                        cacheUniqs={[registerData?.uf]} //Set your param that is going to change when you select another field. Just use this option when you are setting params and need to refresh cache.
                        styles={colourStyles}
                        additional={{
                          page: 1,
                          endpoint: '/cities',
                          params: `uf=${registerData?.uf}`, //Separete with & (and commercial)
                        }}
                      />
                      <ErrorMessage
                        name="id_city"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div className="formStep">
                      <label>Telefone</label>
                      <Field
                        maxlength="14"
                        type="text"
                        name="phone1"
                        placeholder="Digite o número do telefone..."
                        defaultValue={maskTelFixo(registerData?.phone1)}
                        value={
                          registerData?.phone1 == ''
                            ? ''
                            : maskTelFixo(registerData?.phone1)
                        }
                        onChange={HandleChange}
                      />
                      <ErrorMessage
                        name="phone1"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>

                  <Col>
                    <div className="formStep">
                      <label>Celular</label>
                      <Field
                        maxlength="16"
                        type="text"
                        name="phone2"
                        placeholder="Digite o número do celular..."
                        defaultValue={
                          registerData?.phone2 == ''
                            ? ''
                            : maskTel(registerData?.phone2)
                        }
                        value={
                          registerData?.phone2 == ''
                            ? ''
                            : maskTel(registerData?.phone2)
                        }
                        onChange={HandleChange}
                      />
                      <ErrorMessage
                        name="phone2"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div className="formStep">
                      <label class="important">Tipo</label>
                      <Field
                        as="select"
                        name="type"
                        defaultValue={registerData?.type}
                        onChange={HandleChange}
                      >
                        <option selected disabled>
                          Selecione...
                        </option>
                        {ListEmployeeTypes.map((array) => {
                          return (
                            <>
                              <option value={array.value}>{array.label}</option>
                            </>
                          );
                        })}
                      </Field>
                      <ErrorMessage
                        name="type"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                  <Col>
                    <div className="formStep">
                      <label class="important">Técnico(a) ?</label>
                      <Field
                        as="select"
                        name="technician"
                        defaultValue={registerData?.technician}
                        onChange={HandleChange}
                      >
                        <option selected>Selecione...</option>
                        <option value="1">Sim</option>
                        <option value="0">Não</option>
                      </Field>
                      <ErrorMessage
                        name="technician"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div className="formStep">
                      <label>Data da Admissão</label>
                      <Field
                        type="date"
                        name="admission_at"
                        placeholder="Informe à data de admissão..."
                        defaultValue={
                          registerData?.admission_at &&
                          registerData?.admission_at.split('/')[0]
                        }
                        onChange={HandleChange}
                      />
                      <ErrorMessage
                        name="admission_at"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>

                  <Col>
                    <div className="formStep">
                      <label>Data da Demissão</label>
                      <Field
                        type="date"
                        name="exoneration_at"
                        placeholder="Informe à data de demissão..."
                        defaultValue={registerData?.exoneration_at}
                        onChange={HandleChange}
                      />
                      <ErrorMessage
                        name="exoneration_at"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                </Row>
                <div
                  className="customerStep"
                  style={{
                    display:
                      registerData?.type == 7 || registerData?.type == 11
                        ? 'none'
                        : 'block',
                  }}
                ></div>
                <Row>
                  <Col className="d-flex justify-content-center">
                    <CustomButton type="submit">
                      <TextButton>Atualizar</TextButton>
                    </CustomButton>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </ModalContainer>
    </>
  );
};
export default EditEmployer;
