import React, { useState, useRef } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import '../equipamentsFamilies.css';
import { Prefix } from '../../../services/prefix';
import Loading from '../../loader/loading';
import Swal from 'sweetalert2';
import api from '../../../services/api';
import { mutate as GlobalMutate } from 'swr';
import Fontawesome from '../../fontawesome/fontawesome';
import Modal from 'react-bootstrap/Modal';
import Schema from './schema';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Select from 'react-select';
import { colourStyles } from '../../selectAsync/colourStyles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { useStyles } from '../../buttons/AddButtonStyle';
import { ModalContainer } from "../../../style/stylesModals";
import { CustomButton, TextButton, ButtonDesabled } from "../../../style/stylesButton";

const IndexAddEquipamentsFamilies = (props) => {
  const [loader, setLoader] = useState();
  const [show, setShow] = useState(false);
  const [initialState, setInitialState] = useState({
    name: '',
    active: 1,
    monitoring: '',
  });
  const [valueSelect, setValueSelect] = useState([]);
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    setRegisterData(initialState);
  };

  const options = [
    { value: '1', label: 'Sim' },
    { value: '0', label: 'Não' },
  ];

  const [registerData, setRegisterData] = useState(initialState);

  const buttonSend = useRef(null);

  const HandleSelect = (e, name) => {
    if (valueSelect.filter((fill) => fill.nameField == name).length > 0) {
      valueSelect
        .filter((fill) => fill.nameField == name)
        .map((map) => {
          let valueIndice = valueSelect.indexOf(map);
          valueSelect.splice(valueIndice);
        });

      setValueSelect((prev) => {
        return [...prev, { value: e.value, label: e.label, nameField: name }];
      });
      setRegisterData({ ...registerData, [name]: e.value });
    } else {
      setValueSelect((prev) => {
        return [...prev, { value: e.value, label: e.label, nameField: name }];
      });
      setRegisterData({ ...registerData, [name]: e.value });
    }
  };

  const HandleChange = (e) => {
    setRegisterData({
      ...registerData,
      [e.target.name]: e.target.value.trim(),
    });
  };

  function HandleRegister(e) {
    e.preventDefault();
    setLoader(<Loading />);
    api
      .post(Prefix.replace(/[\\"]/g, '') + '/equipment-families', registerData)
      .then((response) => {
        Swal.fire({
          icon: 'success',
          title: 'Uow!',
          text: response.data.message,
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: 'Fechar',
          cancelButtonText: 'Novo Cadastro',
          allowOutsideClick: false,
        }).then((result) => {
          if (!result.isConfirmed) {
            setShow(true);
          }
          setRegisterData({
            name: '',
            active: 1,
            monitoring: '',
          });
          setValueSelect([]);
        });
        setLoader();
        setShow(false);
        setRegisterData(initialState);
        GlobalMutate(
          Prefix.replace(/[\\"]/g, '') +
          '/equipment-families?per_page=' +
          props.perPage +
          '&page=' +
          props.pageExact +
          "&search=" +
          props.search +
          "&order_by=" +
          props.orderBy +
          "&order=" +
          props.order
        );
      })
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: err.response.data.message,
          confirmButtonText: 'Tentar novamente',
        });
        setLoader();
      });
  }

  const classes = useStyles();

  return (
    <>
      {show === false ? (
        <Fab className={classes.fab} onClick={handleShow}>
          <AddIcon className={classes.icon} />
        </Fab>
      ) : (
        ''
      )}
      <ModalContainer
        show={show}
        size="md"
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title><h3>{props.titleLabel}</h3></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loader && <Loading />}
          <Formik
            initialValues={registerData}
            validationSchema={Schema}
            onSubmit={HandleRegister}
            enableReinitialize
          >
            {({ values, errors, setFieldValue }) => (
              <Form>
                <Row>
                  <Col>
                    <div className="formStep text-center">
                      <div className="customerStep">
                        <label className="important">Nome da Família:</label>
                      </div>
                      <Field
                        type="text"
                        placeholder="Digite o nome da família"
                        name="name"
                        onChange={HandleChange}
                      />
                      <ErrorMessage
                        name="name"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                  <Col>
                    <div className="formStep">
                      <div className="customerStep text-center">
                        <label className="important">Monitorável:</label>
                      </div>
                      <Select
                        value={valueSelect
                          .filter((fill) => fill.nameField == 'monitoring')
                          .map((map) => {
                            return {
                              value: map.value,
                              label: map.label,
                            };
                          })}
                        styles={colourStyles}
                        onChange={(e) => HandleSelect(e, 'monitoring')}
                        placeholder="Selecione..."
                        options={options}
                      />
                      {values.monitoring.touched === true && (
                        <span component="span" className="errorMessages">
                          {errors.monitoring}
                        </span>
                      )}
                      <ErrorMessage
                        name="name"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className="d-flex justify-content-center">
                    {!values.monitoring || !values.name ?
                      <>
                        <ButtonDesabled onClick={HandleRegister} disabled>
                          <TextButton> Adicionar </TextButton>
                        </ButtonDesabled>
                      </>
                      :
                      <>
                        <CustomButton onClick={HandleRegister}>
                          <TextButton> Adicionar </TextButton>
                        </CustomButton>
                      </>
                    }
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </ModalContainer>
    </>
  );
};
export default IndexAddEquipamentsFamilies;
