import React, { useContext } from 'react';
import api from '../../../services/api';
import { Prefix } from '../../../services/prefix';
import { mutate as globalMutate } from 'swr';
import { Context } from '../../../services/context';
import { orderBy } from 'lodash';

export default function UpdateStatus({ id, page, rowsPage, activeDefault, orderBy, order }) {
 
 
  const { selectclient, selectunit } = useContext(Context);
  let pageExact = page + parseInt(1);

  function HandleStatus() {
    api
      .put(Prefix.replace(/[\\"]/g, '') + '/sectors/' + id, {
        active: Boolean(activeDefault === 1 ? 0 : 1),
      })
      .then(() => {
        globalMutate(
          Prefix.replace(/[\\"]/g, "") +
              "/sectors?per_page=" +
              rowsPage +
              "&page=" +
              pageExact +
              "&id_unit=" +
              selectunit +
              "&id_customer=" +
              selectclient +
              "&order_by=" +
              orderBy +
              "&order=" +
              order
          );
      });
  }

  return (
    <>
      <label class="switch">
        <input
          type="checkbox"
          id={id + 'sectors'}
          onChange={HandleStatus}
          checked={activeDefault}
        />
        <span class="slider round"></span>
      </label>
    </>
  );
}
