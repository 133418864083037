import React, { useState } from 'react';
import './style.css';
import api from '../../../services/api';
import Swal from 'sweetalert2';
import Loading from '../../loader/loading';

const ForgotPassword = (props) => {
  const [email, setEmail] = useState();
  const [loader, setLoader] = useState();

  function HandleSend() {
    setLoader(<Loading />);
    let formData = {
      email: email,
    };
    api
      .post('esqueci-minha-senha', formData)
      .then((response) => {
        Swal.fire({
          icon: 'success',
          title: 'Uow...',
          text: response.data.message,
          confirmButtonText: 'Tentar novamente',
        });
        setLoader();
      })
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: err.response.data.message,
          confirmButtonText: 'Tentar novamente',
        });
        setLoader();
      });
  }

  return (
    <div className="container_login">
      {loader}
      <div className="kt-login__forgot">
        <div className="kt-login__head">
          <h3 className="kt-login__title">Esqueceu sua senha ?</h3>
          <div className="kt-login__desc">
            Digite seu e-mail para trocar sua senha:
          </div>
        </div>
        <div className="mt_form flip-in-hor-bottom">
          <div className="input-group">
            <input
              className="form-control"
              type="text"
              placeholder="E-mail"
              name="email"
              id="kt_email"
              autocomplete="off"
              onBlur={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="kt-login__actions">
            <button
              id="kt_login_forgot_submit"
              className="kt-login__btn-primary"
              onClick={HandleSend}
            >
              Enviar
            </button>
            &nbsp;&nbsp;
            <button
              id="kt_login_forgot_submit"
              className="kt-login__btn-primary"
              onClick={props.functionBack}
            >
              Voltar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ForgotPassword;
