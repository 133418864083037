import styled from 'styled-components';

export const Button = styled.button`
  background-color: #822a2a;
  width: 180px;
  height: 40px;
  border: 0;
  border-radius: 30px;
  margin-top: 15px;
  color: #fff;

  &:hover {
    opacity: 0.5;
    transition: 0.5s;
  }
`;
