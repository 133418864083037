import React, { useState, useEffect } from "react";
import { Row, Col, Button, Modal } from "react-bootstrap";
import Fontawesome from "../../fontawesome/fontawesome";
import "../cities.css";
import { Prefix } from "../../../services/prefix";
import Loading from "../../loader/loading";
import Swal from "sweetalert2";
import api from "../../../services/api";
import { uf } from "../../data/uf";
import { Link } from "react-router-dom";
import { useFetch } from "../../hooks/useFetch";
import Alert from "../../alert/alert";
import { mutate as GlobalMutate } from "swr"; //importando o mutate do swr
import { Formik, Form, Field, ErrorMessage } from "formik";
import Schema from "./schema";
import { ModalContainer } from "../../../style/stylesModals";
import { CustomButton, TextButton } from "../../../style/stylesButton";

const IndexEditCity = ({ id, page, rowsPage, search, orderBy, order }) => {
  const [loader, setLoader] = useState();
  const [show, setShow] = useState(false);
  const [initialState, setInitialState] = useState([]);
  const [registerData, setRegisterData] = useState(initialState); //--> Estado recebendo State com paramentro

  let pageExact = page + parseInt(1);
  let valueSearch = !search ? "" : search;

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };

  const listCity = useFetch(
    !show ? null : Prefix.replace(/[\\"]/g, "") + "/cities/" + id
  );

  useEffect(() => {
    if (listCity.data) {
      setInitialState({
        name: listCity.data?.model.name,
        uf: listCity.data?.model.uf,
      });
    }
  }, [show, listCity.data]);

  function HandleRegister(values) {
    setLoader(true);
    api
      .put(Prefix.replace(/[\\"]/g, "") + "/cities/" + id, values)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Uow!",
          text: response.data.message,
          confirmButtonText: "Continuar",
        });
        setLoader(false);
        setShow(false);
        GlobalMutate(
          Prefix.replace(/[\\"]/g, "") +
          "/cities?per_page=" +
          rowsPage +
          "&page=" +
          pageExact +
          "&search=" +
          valueSearch +
          "&order_by=" +
          orderBy +
          "&order=" +
          order
        );
        listCity.mutate();
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: err.response.data.message,
          confirmButtonText: "Tentar novamente",
        });
        setLoader(false);
      });
  }

  return (
    <>
      {!show ? (
        <Link to="#" onClick={handleShow} className="link_color_datatable">
          <Fontawesome classe="fas fa-pencil-alt" />
        </Link>
      ) : (
        <ModalContainer
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title><h3>Atualização de Cidades</h3></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik //--> Configuração do Formik
              initialValues={initialState}
              validationSchema={Schema}
              onSubmit={HandleRegister}
              enableReinitialize
            >
              {(
                { values } //--> Captação de valores
              ) => (
                <Form>
                  <Row>
                    {loader}
                    <Col>
                      <div className="formStep">
                        <label class="important">Cidade</label>
                        <Field
                          type="text"
                          placeholder="Digite uma cidade"
                          name="name"
                          defaultValue={values.name}
                        />
                        <ErrorMessage
                          name="name"
                          component="span"
                          className="errorMessages"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="formStep">
                        <label>Estado</label>
                        <Field
                          as="select"
                          type="text"
                          name="uf"
                          defaultValue={values.uf}
                        >
                          <option disabled>Selecione...</option>
                          {uf.map((estado) => (
                            <option value={estado.value}>{estado.label}</option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="uf"
                          component="span"
                          className="errorMessages"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="d-flex justify-content-center">
                      <CustomButton type="submit">
                        <TextButton >Atualizar</TextButton>
                      </CustomButton>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Modal.Body>
        </ModalContainer>
      )}
    </>
  );
};
export default IndexEditCity;
