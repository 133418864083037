import React from 'react';
import Loading from '../../loader/loading';
import Image from '../images/images';
import { Button } from 'react-bootstrap';

const ListDevices = ({ listEquip, detail, setDetail, loader, loadMore }) => {
  return (
    <>
      <section className="box-equipments">
        {loader && <Loading />}
        <div className="box-equipments-inner">
          <ul>
            {!listEquip.equips || listEquip.equips.length == 0 ? (
              <li className="d-flex justify-content-center">
                Nenhum equipamento encontrado, tente novamente...
              </li>
            ) : (
              listEquip.equips.map((map, key) => {
                return (
                  <li onClick={() => setDetail(map.id)} key={key}>
                    <div className="box-equipments-info">
                      <Image
                        idModel={map.id_model}
                        widthSize="45px"
                        heightSize="45px"
                      />
                      <span>{map.nickname}</span>
                    </div>
                    <span style={{ marginRight: '20px' }}>
                      {map.room.sector.unit.address +
                        ', ' +
                        map.room.sector.unit.number +
                        ', ' +
                        map.room.sector.unit.city.name +
                        ', ' +
                        map.room.sector.unit.city.uf}
                    </span>
                  </li>
                );
              })
            )}
            <li className="load-more d-flex justify-content-center">
              {!listEquip.equips ||
              listEquip.equips.length == 0 ||
              !listEquip.to ||
              detail ? (
                ''
              ) : (
                <Button className="loadMore-maps" onClick={loadMore}>
                  Carregar mais...
                </Button>
              )}
            </li>
          </ul>
        </div>
      </section>
    </>
  );
};
export default ListDevices;
