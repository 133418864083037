import React, { useContext } from 'react';
import api from '../../../services/api';
import { Prefix } from '../../../services/prefix';
import { useFetch } from '../../hooks/useFetch';
import { Context } from '../../../services/context';

const UpdateStatus = ({ name }) => {
  const { idContractLicenses } = useContext(Context);

  const listLicense = useFetch(
    Prefix.replace(/[\\"]/g, '') +
      '/contract-licenses?id_contract=' +
      idContractLicenses +
      '&license=' +
      name,
  );

  function HandleStatus() {
    api
      .put(
        Prefix.replace(/[\\"]/g, '') +
          '/contract-licenses/' +
          listLicense.data?.models.data[0].id,
        {
          active: listLicense.data?.models.data[0].active === 1 ? 0 : 1,
        },
      )
      .then(() => {
        listLicense.mutate();
      });
  }

  function HandleCreateStatus() {
    api
      .post(Prefix.replace(/[\\"]/g, '') + '/contract-licenses', {
        active: 1,
        license: name,
        id_contract: idContractLicenses,
      })
      .then(() => {
        listLicense.mutate();
      });
  }

  return (
    <>
      {listLicense.data?.models.data.length == 0 ? (
        <label class="switch">
          <input
            type="checkbox"
            id={name + 'licenses' + idContractLicenses}
            onChange={HandleCreateStatus}
            checked={false}
          />
          <span class="slider round"></span>
        </label>
      ) : (
        <label class="switch">
          <input
            type="checkbox"
            id={name + 'licenses'}
            onChange={HandleStatus}
            checked={
              listLicense.data?.models.data[0].active == 1 ? true : false
            }
          />
          <span class="slider round"></span>
        </label>
      )}
    </>
  );
};

export default UpdateStatus;
