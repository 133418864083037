import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import "../equipamentsBrands.css";
import { Prefix } from "../../../services/prefix";
import Loading from "../../loader/loading";
import Swal from "sweetalert2";
import api from "../../../services/api";
import { mutate as GlobalMutate } from "swr";
import Fontawesome from "../../fontawesome/fontawesome";
import Modal from "react-bootstrap/Modal";
import { useFetch } from "../../hooks/useFetch";
import Schema from "./schema";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Button from "react-bootstrap/Button";
import { ModalContainer } from "../../../style/stylesModals"; 
import { CustomButton, TextButton, ButtonDesabled } from "../../../style/stylesButton";

const IndexEditEquipamentsBrands = ({
  id,
  page,
  rowsPage,
  search,
  orderBy,
  order,
}) => {
  const [loader, setLoader] = useState();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let pageExact = page + 1;
  let valueSearch = !search ? "" : search;

  const brandsList = useFetch(
    !show ? null : Prefix.replace(/[\\"]/g, "") + "/equipment-brands/" + id
  );

  const [registerData, setRegisterData] = useState({});

  useEffect(() => {
    if (brandsList?.data) {
      setRegisterData({
        name: brandsList?.data?.model?.name,
        active: brandsList?.data?.model?.active,
      });
    }
  }, [brandsList.data]);

  const HandleChange = (e) => {
    setRegisterData({
      ...registerData,
      [e.target.name]: e.target.value.trim(),
    });
  };

  /*Atualizar dados do cliente*/
  function handleRegister(values) {
    setLoader(<Loading />);
    api
      .put(Prefix.replace(/[\\"]/g, "") + "/equipment-brands/" + id, values)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Uow!",
          text: response.data.message,
          confirmButtonText: "Fechar",
        });
        setLoader(false);
        setRegisterData({});
        setShow(false);
        GlobalMutate(
          Prefix.replace(/[\\"]/g, "") +
            "/equipment-brands?per_page=" +
            rowsPage +
            "&page=" +
            pageExact +
            "&search=" +
            valueSearch +
            "&order_by=" +
            orderBy +
            "&order=" +
            order
        );
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: err.response.data.message,
          confirmButtonText: "Tentar novamente",
        });
        setLoader();
      });
  }

  return (
    <>
      <Link
        to="#"
        variant="success"
        onClick={handleShow}
        className="link_color_datatable"
      >
        <Fontawesome classe="fas fa-pencil-alt" />
      </Link>

      <ModalContainer
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title><h3>Atualizar<br/> Marca de Equipamento</h3></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loader && <Loading />}
          <Formik
            initialValues={registerData}
            validationSchema={Schema}
            onSubmit={handleRegister}
            enableReinitialize
          >
            {({ values }) => (
              <Form>
                <Row>
                  <Col>
                    <div className="formStep">
                      <div className="labelForm">
                        <label className="important">Nome da Marca:</label>
                      </div>
                      <Field
                        type="text"
                        placeholder="Digite o nome do equipamento"
                        name="name"
                        defaultValue={brandsList.data?.model?.name}
                        onBlur={HandleChange}
                      />
                      <ErrorMessage
                        name="name"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className="d-flex justify-content-center">
                  <CustomButton type="submit">
                      <TextButton> Atualizar </TextButton>
                    </CustomButton>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </ModalContainer>
    </>
  );
};
export default IndexEditEquipamentsBrands;
