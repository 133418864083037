import React from 'react';
import api from '../../../../services/api';
import { Prefix } from '../../../../services/prefix';
import { mutate as globalMutate } from 'swr';

export default function UpdateStatus({
  id,
  page,
  rowsPage,
  activeDefault,
  search,
  idUser,
}) {
  let pageExact = page + parseInt(1);

  function HandleStatus() {
    api
      .put(Prefix.replace(/[\\"]/g, '') + '/contract-item-views/' + id, {
        dark: activeDefault == 1 ? 0 : 1,
      })
      .then(() => {
        globalMutate(
          Prefix.replace(/[\\"]/g, '') +
            '/contract-item-views?per_page=' +
            rowsPage +
            '&page=' +
            pageExact +
            '&search=' +
            search +
            '&id_user=' +
            idUser,
        );
      });
  }

  return (
    <>
      <label class="switch">
        <input
          type="checkbox"
          id={id + 'warning'}
          onChange={HandleStatus}
          checked={activeDefault == 1 ? true : false}
        />
        <span class="slider round"></span>
      </label>
    </>
  );
}
