import React from 'react';
import Fontawesome from '../fontawesome/fontawesome';
import { NavLink } from 'react-router-dom';

const NavCommercial = ({ group }) => {
  return (
    <>
      <li className="slide-in-left">
        <NavLink
          exact
          activeClassName="active"
          className="slide-in-left"
          to="/comercial/propostas"
        >
          <Fontawesome classe="fas fa-tachometer-alt mr-3" />
          Propostas
        </NavLink>
      </li>
    </>
  );
};
export default NavCommercial;
