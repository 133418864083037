import React from 'react';
import { TagStatusSt } from './statusRequestProposalStyle';

const StatusRequestProposal = () => {
  return (
    <TagStatusSt status={1}>
      <i
        class="fas fa-circle"
        style={{ fontSize: '7px', marginRight: '5px' }}
      ></i>{' '}
      Aprovado
    </TagStatusSt>
  );
};
export default StatusRequestProposal;
