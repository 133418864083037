import React from 'react';
import StatusRequestProposal from '../../components/proposals/status/statusRequestProposal';
import Potential from '../../components/proposals/potential/potential';
import moment from 'moment';
import { findAllByDisplayValue } from '@testing-library/dom';
import AddProposal from '../../components/proposals/addProposal/addProposal';

export const columns = [
  {
    //0
    name: 'id',
    label: 'Potencial',
    options: {
      filter: false,
      sort: false,
      display: true,
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'left',
          width: '16.66%',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'left',
          width: '16.66%',
        },
      }),
      customBodyRender: (value, tableMeta) => {
        return (
          <div>
            <Potential />
            <strong>Proposta:</strong> {value}
          </div>
        );
      },
    },
  },
  {
    //1
    name: 'company',
    label: 'Nome',
    options: {
      filter: false,
      sort: false,
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'left',
          width: '16.66%',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'left',
          width: '16.66%',
        },
      }),
      customBodyRender: (value, tableMeta) => {
        return (
          <div>
            <strong>{value}</strong>
            <br />
            {tableMeta.rowData[2]}
          </div>
        );
      },
    },
  },
  {
    //2
    name: 'email',
    label: 'E-mail',
    options: {
      filter: false,
      sort: false,
      display: 'excluded',
    },
  },
  {
    //3
    name: 'id',
    label: 'Status',
    options: {
      filter: false,
      sort: false,
      display: true,
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'left',
          width: '16.66%',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'left',
          width: '16.66%',
        },
      }),
      customBodyRender: (value, tableMeta) => {
        return (
          <div>
            <StatusRequestProposal />
            <div>
              <strong>Data:</strong>{' '}
              {moment(tableMeta.rowData[4].split('T')[0]).format('DD/MM/YYYY')}
            </div>
          </div>
        );
      },
    },
  },
  {
    //4
    name: 'created_at',
    label: 'Data',
    options: {
      filter: false,
      sort: false,
      display: 'excluded',
    },
  },
  {
    //5
    name: 'city',
    label: 'Região',
    options: {
      filter: false,
      sort: false,
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'left',
          width: '16.66%',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'left',
          width: '16.66%',
        },
      }),
      customBodyRender: (value, tableMeta) => {
        return (
          <div>
            <strong>{value.name}</strong>
            <div>{value.uf}</div>
          </div>
        );
      },
    },
  },
  {
    //6
    name: 'city',
    label: 'Atendente',
    options: {
      filter: false,
      sort: false,
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'left',
          width: '16.66%',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'left',
          width: '16.66%',
        },
      }),
      customBodyRender: (value, tableMeta) => {
        return (
          <div>
            <strong>Ana Paula</strong>
            <div>Comercial</div>
          </div>
        );
      },
    },
  },
  {
    //7
    name: 'proposals',
    label: 'Propostas',
    options: {
      filter: false,
      sort: false,
      display: 'excluded',
    },
  },
  {
    //8
    name: 'id',
    label: 'proposals',
    options: {
      filter: false,
      sort: false,
      display: 'excluded',
    },
  },

  // Customer block
  {
    //9
    name: 'name',
    label: '',
    options: {
      filter: false,
      sort: false,
      display: 'excluded',
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'left',
          width: '16.66%',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'left',
          width: '16.66%',
        },
      }),
    },
  },
  {
    //10
    name: 'cnpj',
    label: 'proposals',
    options: {
      filter: false,
      sort: false,
      display: 'excluded',
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'left',
          width: '16.66%',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'left',
          width: '16.66%',
        },
      }),
    },
  },
  {
    //11
    name: 'phone',
    label: 'proposals',
    options: {
      filter: false,
      sort: false,
      display: 'excluded',
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'left',
          width: '16.66%',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'left',
          width: '16.66%',
        },
      }),
    },
  },
  {
    //12
    name: 'phone2',
    label: 'proposals',
    options: {
      filter: false,
      sort: false,
      display: 'excluded',
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'left',
          width: '16.66%',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'left',
          width: '16.66%',
        },
      }),
    },
  },
  {
    //13
    name: 'Telefone',
    label: '',
    options: {
      filter: false,
      sort: false,
      display: findAllByDisplayValue,
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'left',
          width: '16.66%',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'left',
          width: '16.66%',
        },
      }),
      customBodyRender: (value, tableMeta) => {
        return (
          <>
            {tableMeta.rowData[11]}
            {tableMeta.rowData[11] && tableMeta.rowData[12] ? ' | ' : ''}
            {tableMeta.rowData[12]}
          </>
        );
      },
    },
  },

  {
    //14
    name: 'city',
    label: 'Região',
    options: {
      filter: false,
      sort: false,
      display: 'excluded',
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'left',
          width: '16.66%',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'left',
          width: '16.66%',
        },
      }),
      customBodyRender: (value, tableMeta) => {
        return <div>{value.name + ' /' + value.uf}</div>;
      },
    },
  },

  {
    //14
    name: 'id',
    label: 'Adicionar',
    options: {
      filter: false,
      sort: false,
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
          width: '16.66%',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
          width: '16.66%',
        },
      }),
      customBodyRender: (value, tableMeta) => {
        const today = moment().format('YYYY-MM-DD');
        return (
          <AddProposal
            id={value}
            page={tableMeta.tableState.page}
            perPage={tableMeta.tableState.rowsPerPage}
            search={
              !tableMeta.tableState.searchText
                ? ''
                : tableMeta.tableState.searchText
            }
            started={today}
          />
        );
      },
    },
  },
];
