import React, { useState } from "react";
import { Textarea, errorMessages } from "./proposalsStyles.jsx";
import { Row, Col, Button } from "react-bootstrap";
import "../../../style/global.css";
import "./styles.css";
import Logotipo from "../../../components/logotipo/logotipo";
import history from "../../../services/history";
import api from "../../../services/api";
import Swal from "sweetalert2";
import Loading from "../../../components/loader/loading";
import { uf } from "../../../components/data/uf";
import { useFetch } from "../../../components/hooks/useFetch";
import { cnpj } from "cpf-cnpj-validator";
import {
  maskCpfCnpj,
  maskTel,
  maskTelDin,
  maskTelFixo,
} from "../../../services/mask";
import { Prefix } from "../../../services/prefix";
import Schema from "./schema";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import { NoOptionsMessage } from "../../../components/selectAsync/NoOptionsMessage";
import { AsyncPaginate } from "react-select-async-paginate";
import { loadOptions } from "../../../components/selectAsync/loadOptions";
import { colourStyles } from "./coloursStyles";
import Select from "react-select";

const ProposalRequests = (props) => {
  const [messageDocument, setMessageDocument] = useState(null);
  const [loader, setLoader] = useState();
  const [valueSelect, setValueSelect] = useState([]);
  const [registerData, setRegisterData] = useState({
    initialData: [
      {
        name: "",
        company: "",
        phone: "",
        cnpj: "",
        phone: "",
        phone2: "",
        email: "",
        uf: "",
        address: "",
        neighborhood: "",
        zipcode: "",
        id_city: "",
        description: "",
      },
    ],
  });

  const HandleSelect = (e, name, key) => {
    if (
      valueSelect.filter((fill) => fill.key == key && fill.nameField == name)
        .length > 0
    ) {
      valueSelect
        .filter((fill) => fill.key == key && fill.nameField == name)
        .map((map) => {
          let position = valueSelect.indexOf(map);
          valueSelect.splice(position, 1);
        });
      setValueSelect(() => {
        return [
          ...valueSelect,
          { value: e.value, label: e.label, nameField: name, key: key },
        ];
      });
    } else {
      setValueSelect((prev) => {
        return [
          ...prev,
          { value: e.value, label: e.label, nameField: name, key: key },
        ];
      });
    }
  };

  function HandleRegister(values) {
    setLoader(true);
    values.initialData.map((map) => {

      api.post(Prefix.replace(/[\\"]/g, "") + "/proposal-requests",
        {
          name: map.name,
          company: map.company,
          cnpj: map.cnpj,
          phone: map.phone,
          phone2: map.phone2,
          email: map.email,
          uf: map.uf,
          address: map.address,
          neighborhood: map.neighborhood,
          zipcode: "",
          id_city: map.id_city,
          description: map.description,
        }
      )
        .then((response) => {
          Swal.fire({
            icon: "success",
            title: "Uow...",
            text: response.data.message,
            confirmButtonText: "Fechar",
          }).then((result) => {
            setLoader(false);
            if (result.isConfirmed) {
              history.push("/");
              window.location.reload();
            }
          });
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: err.response.data.message,
            confirmButtonText: "Tentar novamente",
          });
          setLoader(false);
        });
    })
  }

  return (
    <div className="container-fluido ">
      {loader}
      <div className="container mt_container">
        <div className="row mt_login_top">
          <div className="col-12 text-center">
            <Logotipo
              src="./media/logos/logo-dtlab.png"
              classe="mt_img_logo img-fluid"
              alt="Logo DTLAB"
            />
          </div>
        </div>
        <div className="row mt_signin">
          <div className="container_login">
            <div className="kt-login__forgot">
              <div className="kt-login__head">
                <h3 className="kt-login__title">SOLICITAÇÃO DE PROPOSTA</h3>
                <div className="kt-login__desc">
                  Preencha os campos abaixo para solicitar uma proposta
                  conosco...
                </div>
              </div>
              <div className="mt_form flip-in-hor-bottom">
                {loader && <Loading />}
                <Formik //--> Configuração do Formik
                  initialValues={registerData}
                  validationSchema={Schema}
                  onSubmit={HandleRegister}
                  enableReinitialize
                >
                  {({ values, handleChange, errors, setFieldValue }) => (
                    <Form>
                      <FieldArray>
                        {({ push, remove }) => (
                          <div>
                            {values.initialData.map((p, index) => {
                              return (
                                <>
                                  <Row>
                                    <Col>
                                      <Field
                                        className="form-control"
                                        style={{ textAlign: "center" }}
                                        type="text"
                                        placeholder="Nome Completo"
                                        name={`initialData[${index}].name`}
                                        autocomplete="chrome-off"
                                        onChange={handleChange}
                                      />
                                      <ErrorMessage
                                        name={`initialData[${index}].name`}
                                        component="span"
                                        className="errorMessages2"
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <Field
                                        className="form-control"
                                        type="text"
                                        style={{ textAlign: "center" }}
                                        placeholder="Empresa"
                                        name={`initialData[${index}].company`}
                                        autocomplete="chrome-off"
                                        onChange={handleChange}
                                      />
                                      <ErrorMessage
                                        name={`initialData[${index}].company`}
                                        component="span"
                                        className="errorMessages2"
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <Field
                                        className="form-control"
                                        type="text"
                                        name={`initialData[${index}].email`}
                                        style={{ textAlign: "center" }}
                                        placeholder="Digite seu e-mail"
                                        autocomplete="chrome-off"
                                        onChange={handleChange}
                                      />
                                      <ErrorMessage
                                        name={`initialData[${index}].email`}
                                        component="span"
                                        className="errorMessages2"
                                      />
                                    </Col>
                                    <Col>
                                      <Field
                                        className="form-control"
                                        type="text"
                                        style={{ textAlign: "center" }}
                                        placeholder="Digite seu CNPJ"
                                        name={`initialData[${index}].cnpj`}
                                        maxLength="18"
                                        autocomplete="chrome-off"
                                        onKeyPress={maskCpfCnpj}
                                        onChange={handleChange}
                                      />
                                      <errorMessages>
                                        <ErrorMessage
                                          name={`initialData[${index}].cnpj`}
                                          component="span"
                                          className="errorMessages2"
                                        />
                                      </errorMessages>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <Field
                                        className="form-control"
                                        type="text"
                                        style={{ textAlign: "center" }}
                                        placeholder="Telefone"
                                        name={`initialData[${index}].phone`}
                                        autocomplete="chrome-off"
                                        onKeyPress={maskTelFixo}
                                        onChange={handleChange}
                                      />

                                      <ErrorMessage
                                        name={`initialData[${index}].phone`}
                                        component="span"
                                        className="errorMessages2"
                                      />
                                    </Col>
                                    <Col>
                                      <Field
                                        className="form-control"
                                        type="text"
                                        style={{ textAlign: "center" }}
                                        placeholder="Celular"
                                        name={`initialData[${index}].phone2`}
                                        autocomplete="chrome-off"
                                        onKeyPress={maskTel}
                                        onChange={handleChange}
                                      />

                                      <ErrorMessage
                                        name={`initialData[${index}].phone2`}
                                        component="span"
                                        className="errorMessages2"
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <Field
                                        className="form-control"
                                        type="text"
                                        style={{ textAlign: "center" }}
                                        placeholder="Endereço"
                                        name={`initialData[${index}].address`}
                                        autocomplete="chrome-off"
                                        onChange={handleChange}
                                      />

                                      <ErrorMessage
                                        name={`initialData[${index}].address`}
                                        component="span"
                                        className="errorMessages2"
                                      />
                                    </Col>
                                    <Col>
                                      <Field
                                        className="form-control"
                                        type="text"
                                        style={{ textAlign: "center" }}
                                        placeholder="Bairro"
                                        name={`initialData[${index}].neighborhood`}
                                        autocomplete="chrome-off"
                                        onChange={handleChange}
                                      />

                                      <ErrorMessage
                                        name={`initialData[${index}].neighborhood`}
                                        component="span"
                                        className="errorMessages2"
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col
                                      style={{
                                        marginTop: "20px",
                                      }}
                                    >
                                      <Select
                                        className="placeholder-custom"
                                        closeMenuOnSelect={false}
                                        styles={colourStyles}
                                        placeholder="Estado"
                                        name={`initialData[${index}].uf`}
                                        components={{ NoOptionsMessage }}
                                        options={uf.map((state) => {
                                          return {
                                            value: state.value,
                                            label: state.name,
                                          };
                                        })}
                                        onChange={(e) =>
                                          setFieldValue(
                                            `initialData[${index}].uf`,
                                            e.value
                                          )
                                        }
                                      />
                                      <ErrorMessage
                                        name={`initialData[${index}].uf`}
                                        component="span"
                                        className="errorMessages2"
                                      />
                                    </Col>
                                    <Col
                                      style={{
                                        marginTop: "20px",
                                      }}
                                    >
                                      <AsyncPaginate
                                        className="placeholder-custom"
                                        value={valueSelect
                                          .filter(
                                            (fill) =>
                                              fill.nameField == "id_city"
                                          )
                                          .map((map) => {
                                            return {
                                              value: map.value,
                                              label: map.label,
                                            };
                                          })}
                                        loadOptions={loadOptions}
                                        onChange={(e) => {
                                          HandleSelect(e, "id_city");
                                          setFieldValue(
                                            `initialData[${index}].id_city`,
                                            e.value
                                          );
                                        }} //values and name of select paginate
                                        placeholder="Cidade"
                                        cacheUniqs={[values.initialData[0].uf]} //Set your param that is going to change when you select another field. Just use this option when you are setting params and need to refresh cache.
                                        styles={colourStyles}
                                        additional={{
                                          page: 1,
                                          endpoint: "/cities",
                                          params: `uf=${values.initialData[0].uf}`, //Separete with & (and commercial)
                                        }}
                                      />
                                      <ErrorMessage
                                        name={`initialData[${index}].id_city`}
                                        component="span"
                                        className="errorMessages2"
                                      />
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col>
                                      <Textarea
                                        className="form-control"
                                        type="text"
                                        placeholder="Descreva sua solicitação"
                                        name={`initialData[${index}].description`}
                                        onChange={handleChange}
                                      />
                                      <errorMessages>
                                        <ErrorMessage
                                          name={`initialData[${index}].description`}
                                          component="span"
                                          className="errorMessages2"
                                        />
                                      </errorMessages>
                                    </Col>
                                  </Row>
                                </>
                              );
                            })}
                            <div>
                              <Row>
                                <Col className="d-flex justify-content-center ">
                                  <button
                                    style={{ margin: "auto" }}
                                    id="kt_login_forgot_submit"
                                    className="kt-login__btn-primary"
                                    type="submit"
                                  >
                                    Adicionar
                                  </button>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        )}
                      </FieldArray>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProposalRequests;
