import React, { useState, useContext, useEffect } from "react";
import Sidebar from "../../components/sidebar/sidebar";
import "./units.css";
import Topbar from "../../components/topbar/topbar";
import { columns } from "./columns.jsx";
import Content from "../../components/units/addunits/indexAddUnity";
import MUIDataTable from "mui-datatables";
import Fontawesome from "../../components/fontawesome/fontawesome";
import { Prefix } from "../../services/prefix";
import { useFetch } from "../../components/hooks/useFetch";
import Loading from "../../components/loader/loading";
import api from "../../services/api";
import Swal from "sweetalert2";
import { TableRow, TableCell } from "@material-ui/core";
import CustomFooter from "../../components/CustomFooter/CustomFooter";
import { Row, Col, Card, Table } from "react-bootstrap";
import WizardUnits from "../../components/WizardUnits/wizardUnits";
import FilterUnits from "../../components/units/filter/filterUnits";
import { Context } from "../../services/context";
import { mutate as GlobalMutate } from "swr";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { GetMuiTheme } from "../../components/muiTheme/muiTheme";
import {
  CardSt,
  CardHeaderSt,
  IconTitleSt,
  TitleSt,
  TextTitleSt,
  SmallTitleSt,
} from "../../style/globalStyle";

export const Units = () => {
  const {
    selectclient,
    selectuf,
    selectcity,
    setSelectClient,
    setSelectUf,
    setSelectCity,
  } = useContext(Context);

  const [loader, setLoader] = useState();
  const [pagina, setPagina] = useState(0);
  const [perPage, setPerPage] = useState(15);
  const [search, setSearch] = useState("");
  const [orderBy, setOrderBy] = useState("id");
  const [order, setOrder] = useState("desc");

  let pageExact = pagina + parseInt(1);

  useEffect(() => {
    setSelectClient("");
    setSelectUf("");
    setSelectCity("");
  }, []);

  const unitsList = useFetch(
    Prefix.replace(/[\\"]/g, "") +
      "/units?per_page=" +
      perPage +
      "&page=" +
      pageExact +
      "&id_customer=" +
      selectclient +
      "&id_city=" +
      selectcity +
      "&uf=" +
      selectuf +
      "&order_by=" +
      orderBy +
      "&order=" +
      order
  );

  const options = {
    draggableColumns: {
      enabled: true,
    },
    serverSide: true,
    sortOrder:{
      name: orderBy,
      direction: order,
    },
    onTableChange: (action, tableState) => {
      setOrderBy(
        !tableState.sortOrder.name ? orderBy : tableState.sortOrder.name
      );
      setOrder(
        !tableState.sortOrder.direction ? order : tableState.sortOrder.direction
      );
      setPagina(tableState.page);
      setPerPage(tableState.rowsPerPage);
    },
    onSearchChange: (searchText) => {
      setSearch(!searchText ? "" : searchText);
    },
    customSearchRender: () => {
      return <FilterUnits />;
    },
    filter: false,
    page: pagina,
    count: unitsList.data?.models?.total,
    expandableRows: true,
    expandableRowsHeader: false,
    expandableRowsOnClick: false,
    rowsPerPage: perPage,
    rowsPerPageOptions: [15, 30, 100],
    isRowExpandable: (dataIndex, expandedRows) => {
      if (
        expandedRows.data.length > 4 &&
        expandedRows.data.filter((d) => d.dataIndex === dataIndex).length === 0
      )
        return false;
      return true;
    },
    renderExpandableRow: (rowData, rowMeta) => {
      const colSpan = rowData.length + 1;
      return (
        <TableRow>
          <TableCell colSpan={colSpan}>
            <Row>
              <Col className="text-center">
                <h4>Dados da Unidade</h4>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Endereço:</th>
                      <th>Nº:</th>
                      <th>Bairro:</th>
                      <th>Cidade:</th>
                      <th>Estado:</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{rowData[4]}</td>
                      <td>{rowData[6]}</td>
                      <td>{rowData[5]}</td>
                      <td>{rowData[8]}</td>
                      <td>{rowData[9]}</td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </TableCell>
        </TableRow>
      );
    },
    onRowsDelete: (rowsDeleted, newData) => {
      HandleDelete(rowsDeleted.data);
    },
    searchPlaceholder: "Pesquise um unidade",
    selectableRows: "multiple",
    searchOpen: true,

    textLabels: {
      body: {
        noMatch: "Desculpe, não encontramos unidades!",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
      pagination: {
        next: "Avançar",
        previous: "Voltar",
        rowsPerPage: "Linhas por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Pesquisar",
        downloadCsv: "Baixar CSV",
        print: "Imprimir",
        viewColumns: "Ver Colunas",
        filterTable: "Filtrar tabela",
      },
      filter: {
        all: "Tudo",
        title: "FILTROS",
        reset: "RESETAR",
      },
      viewColumns: {
        title: "Exibir colunas",
        titleAria: "Exibir/Ocultar colunas da tabela",
      },
      selectedRows: {
        text: "Coluna(s) selecionada(s)",
        delete: "Excluir",
        deleteAria: "Excluir linhas selecionadas",
      },
    },
    customFooter: (
      count,
      page,
      rowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels
    ) => {
      let numberPages = unitsList.data?.models?.last_page;
      let rowsPerPageOptions = [15, 30, 100];
      return (
        <CustomFooter
          page={page}
          rowsPerPage={rowsPerPage}
          changeRowsPerPage={changeRowsPerPage}
          changePage={changePage}
          numberPages={numberPages}
          rowsPerPageOptions={rowsPerPageOptions}
        />
      );
    },
  };

  function HandleDelete(e) {
    setLoader(<Loading />);
    let idArr = [];

    e.map((del) => {
      idArr.push(
        unitsList.data?.models?.data.filter(
          (filtro, index) => index == del.index
        )[0].id
      );
    });

    idArr.map((idDel) =>
      api
        .delete(Prefix.replace(/[\\"]/g, "") + "/units/" + idDel)
        .then((response) => {
          Swal.fire({
            icon: "success",
            title: "Uow!",
            text: response.data.message,
            confirmButtonText: "Fechar",
          });
          setLoader();
          unitsList.mutate();
          GlobalMutate(
            Prefix.replace(/[\\"]/g, "") +
              "/units?per_page=" +
              perPage +
              "&page=" +
              pageExact +
              "&id_customer=" +
              selectclient +
              "&id_city=" +
              selectcity +
              "&uf=" +
              selectuf +
              "&order_by=" +
              orderBy +
              "&order=" +
              order
          );
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: err.response.data.message,
            confirmButtonText: "Tentar novamente",
          });
          setLoader();
        })
    );
  }

  return (
    <div className="wrapper d-flex align-items-stretch">
      <Sidebar />
      <div id="content">
        {loader}
        <Topbar />
        <div className="content_all">
          <CardSt>
            <CardHeaderSt>
              <TitleSt className="d-flex justify-content-between">
                <div className="d-flex justify-content-between">
                  <IconTitleSt src="../../../media/icons/base.svg" />
                  <TextTitleSt>
                    Unidades <SmallTitleSt>Gestão de Unidades</SmallTitleSt>
                  </TextTitleSt>
                </div>
                <WizardUnits
                  pageExact={pageExact}
                  perPage={perPage}
                  search={search}
                  orderBy={orderBy}
                  order={order}
                />
              </TitleSt>
            </CardHeaderSt>
            <CardSt.Body as="div">
              <CardSt.Text as="div">
                <MuiThemeProvider theme={GetMuiTheme()}>
                  <MUIDataTable
                    data={unitsList.data?.models?.data}
                    columns={columns}
                    options={options}
                  />
                </MuiThemeProvider>
              </CardSt.Text>
            </CardSt.Body>
          </CardSt>
          <Content
            titleLabel="Cadastro de Unidade"
            buttonLabel="Adicionar"
            iconClass="fas fa-plus-circle"
            modalSize="md"
            pageExact={pageExact}
            perPage={perPage}
            search={search}
            selectclient={selectclient}
            selectcity={selectcity}
            selectuf={selectuf}
            orderBy={orderBy}
            order={order}
          />
        </div>
      </div>
    </div>
  );
};

export default Units;
