import React, { useState, useContext, useRef } from 'react';
import { Row, Col, Modal, Alert, Form } from 'react-bootstrap';
import Fontawesome from '../fontawesome/fontawesome';
import Loading from '../../../components/loader/loading';
import Swal from 'sweetalert2';
import MUIDataTable from 'mui-datatables';
import { columns } from './columns';
import api from '../../../services/api';
import { Prefix } from '../../../services/prefix';
import { useFetch } from '../../../components/hooks/useFetch';
import CustomSearchRender from '../../../components/customSearchRender/CustomSearchRender';
import CustomFooter from '../../../components/CustomFooter/CustomFooter';
import { mutate as GlobalMutate } from 'swr';
import CurrencyInput from '../../../components/hooks/useCurrency';
import { Context } from '../../../services/context';
import { AsyncPaginate } from 'react-select-async-paginate';
import { loadOptionsStates } from './loadOptionsStates';
import { loadOptions } from '../../../components/selectAsync/loadOptions';
import { colourStyles } from '../../../components/selectAsync/colourStyles';
import { Button } from './notificationViewStyle';
import { maskTel } from '../../../services/mask';

export const NotificationView = ({ idContractItem }) => {
  const { showNotificationView, setShowNotificationView } = useContext(Context);

  const [pagina, setPagina] = useState(0);
  const [perPage, setPerPage] = useState(15);
  const [search, setSearch] = useState('');
  const [loader, setLoader] = useState();
  const [valueSelect, setValueSelect] = useState([]);
  const [formData, setFormData] = useState({
    active: '1',
    noc: '1',
    id_contract_item: idContractItem,
    monitoring: '1',
    plataform: 'whatsapp',
  });

  let pageExact = pagina + parseInt(1);

  const listEquipmentView = useFetch(
    Prefix.replace(/[\\"]/g, '') +
      '/contract-notifications?per_page=' +
      perPage +
      '&page=' +
      pageExact +
      '&search=' +
      search +
      '&id_contract_item=' +
      idContractItem,
  );

  const HandleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value.trim(),
    });
  };

  const HandleChecked = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.checked,
    });
  };

  const HandleSelect = (e, name) => {
    if (valueSelect.filter((fill) => fill.nameField == name).length > 0) {
      valueSelect
        .filter((fill) => fill.nameField == name)
        .map((map) => {
          let valueIndice = valueSelect.indexOf(map);
          valueSelect.splice(valueIndice);
        });

      setValueSelect((prev) => {
        return [...prev, { value: e.value, label: e.label, nameField: name }];
      });
      setFormData({ ...formData, [name]: e.value });
    } else {
      setValueSelect((prev) => {
        return [...prev, { value: e.value, label: e.label, nameField: name }];
      });
      setFormData({ ...formData, [name]: e.value });
    }
  };

  const HandleRegister = () => {
    setLoader(<Loading />);
    api
      .post(Prefix.replace(/[\"]/g, '') + '/contract-notifications', formData)
      .then((response) => {
        Swal.fire({
          icon: 'success',
          title: 'Uow!',
          text: response.data.message,
          confirmButtonText: 'Fechar',
        });
        setLoader();
        setFormData({
          active: '1',
          noc: '1',
          id_contract_item: idContractItem,
          monitoring: '1',
          platform: 'whatsapp',
        });
        setValueSelect([]);
        GlobalMutate(
          Prefix.replace(/[\\"]/g, '') +
            '/contract-notifications?per_page=' +
            perPage +
            '&page=' +
            pageExact +
            '&search=' +
            search +
            '&id_contract_item=' +
            idContractItem,
        );
      })
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: err.response.data.message,
          confirmButtonText: 'Tentar novamente',
        });
        setLoader();
      });
  };

  function HandleDelete(e) {
    setLoader(<Loading />);
    let idArr = [];
    e.map((del) => {
      idArr.push(
        listEquipmentView.data?.models.data?.filter(
          (filtro, index) => index == del.index,
        )[0].id,
      );
    });

    idArr.map((idDel) =>
      api
        .delete(
          Prefix.replace(/[\\"]/g, '') + '/contract-notifications/' + idDel,
          {
            id: idArr,
          },
        )
        .then((response) => {
          Swal.fire({
            icon: 'success',
            title: 'Uow!',
            text: response.data.message,
            confirmButtonText: 'Fechar',
          });
          setLoader();
          GlobalMutate(
            Prefix.replace(/[\\"]/g, '') +
              '/contract-notifications?per_page=' +
              perPage +
              '&page=' +
              pageExact +
              '&search=' +
              search +
              '&id_contract_item=' +
              idContractItem,
          );
        })
        .catch((err) => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: err.response.data.message,
            confirmButtonText: 'Tentar novamente',
          });
          setLoader();
        }),
    );
  }

  const options = {
    draggableColumns: {
      enabled: true,
    },
    serverSide: true,
    onTableChange: (action, tableState) => {
      setPagina(tableState.page);
      setPerPage(tableState.rowsPerPage);
    },
    onSearchChange: (searchText) => {
      setSearch(!search ? '' : searchText);
    },
    customSearchRender: (searchText, handleSearch, hideSearch, options) => {
      return (
        <CustomSearchRender
          placeholderSearch="Pesquisar Notificação"
          searchText={searchText}
          onSearch={handleSearch}
          onHide={hideSearch}
          options={options}
        />
      );
    },
    filter: false,
    page: pagina,
    count: listEquipmentView.data?.models.data.total,
    expandableRows: false,
    expandableRowsHeader: false,
    expandableRowsOnClick: false,
    rowsPerPage: perPage,
    rowsPerPageOptions: [15, 30, 100],
    onRowsDelete: (rowsDeleted, newData) => {
      HandleDelete(rowsDeleted.data);
    },
    searchPlaceholder: 'Pesquise uma Notificação',
    selectableRows: true,
    searchOpen: false,

    textLabels: {
      body: {
        noMatch: 'Desculpe, não encontramos colaborador(a) para este coletor!',
        toolTip: 'Sort',
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
      pagination: {
        next: 'Avançar',
        previous: 'Voltar',
        rowsPerPage: 'Linhas por página:',
        displayRows: 'de',
      },
      toolbar: {
        search: 'Pesquisar',
        downloadCsv: 'Baixar CSV',
        print: 'Imprimir',
        viewColumns: 'Ver Colunas',
        filterTable: 'Filtrar tabela',
      },
      filter: {
        all: 'Tudo',
        title: 'FILTROS',
        reset: 'RESETAR',
      },
      viewColumns: {
        title: 'Exibir colunas',
        titleAria: 'Exibir/Ocultar colunas da tabela',
      },
      selectedRows: {
        text: 'Coluna(s) selecionada(s)',
        delete: 'Excluir',
        deleteAria: 'Excluir linhas selecionadas',
      },
    },
    customFooter: (
      count,
      page,
      rowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels,
    ) => {
      let numberPages = listEquipmentView.data?.models.data.last_page;
      let rowsPerPageOptions = [15, 30, 100];
      return (
        <CustomFooter
          page={page}
          rowsPerPage={rowsPerPage}
          changeRowsPerPage={changeRowsPerPage}
          changePage={changePage}
          numberPages={numberPages}
          rowsPerPageOptions={rowsPerPageOptions}
        />
      );
    },
  };

  return (
    <>
      {loader && <Loading />}
      <form className="customerStep" onSubmit={HandleRegister}>
        <Row>
          <Col>
            <div className="formStep">
              <label className="important">Colaborador(a)</label>
              <AsyncPaginate
                value={valueSelect
                  .filter((fill) => fill.nameField == 'id_user')
                  .map((map) => {
                    return {
                      value: map.value,
                      label: map.label,
                    };
                  })}
                loadOptions={loadOptions}
                onChange={(e) => HandleSelect(e, 'id_user')} //values and name of select paginate
                placeholder="Selecione..."
                //Set your param that is going to change when you select another field. Just use this option when you are setting params and need to refresh cache.
                cacheUniqs={[valueSelect]}
                styles={colourStyles}
                additional={{
                  page: 1,
                  endpoint: '/customer-employees',
                  // params: `id_client=${idClient}`,
                  paramId: 'user.id',
                  paramName: 'user.name',
                }}
              />
            </div>
          </Col>
          <Col>
            <div className="formStep">
              <label class="important">Nome Preferêncial: </label>
              <input
                type="text"
                placeholder="Informe um nome preferencial..."
                name="name"
                onKeyUp={HandleChange}
              />
            </div>
          </Col>

          {/* <Col>
            <div className="formStep">
              <label class="important">Plataforma</label>
              <select name="plataform" onChange={HandleChange}>
                <option value="" selected="selected">
                  Selecione uma Plataforma...
                </option>
                <option value="email">E-Mail</option>
                <option value="whatsapp">WhatsApp</option>
              </select>
            </div>
          </Col> */}
          {formData?.plataform == 'email' ? (
            <Col>
              <div className="formStep">
                <label class="important"> E-Mail</label>
                <input
                  type="text"
                  placeholder="Informe seu E-mail..."
                  name="identification"
                  onKeyUp={HandleChange}
                />
              </div>
            </Col>
          ) : (
            <Col>
              <div className="formStep">
                <label class="important"> WhatsApp</label>
                <input
                  type="text"
                  placeholder="Informe seu WhatsApp..."
                  name="identification"
                  onKeyPress={maskTel}
                  onChange={HandleChange}
                />
              </div>
            </Col>
          )}
        </Row>
        <Row className="d-flex justify-content-center mt-4">
          <Button type="button" onClick={HandleRegister}>
            <Fontawesome classe="fas fa-link mr-3" />
            Vincular
          </Button>
        </Row>
      </form>{' '}
      <div style={{ position: 'relative', zIndex: '0' }}>
        <MUIDataTable
          data={listEquipmentView.data?.models?.data}
          columns={columns}
          options={options}
        />
      </div>
    </>
  );
};
export default NotificationView;
