import React, { useState, useContext } from "react";
import { Row, Col, Button, Modal } from "react-bootstrap";
import Fontawesome from "../fontawesome/fontawesome";
import Loading from "../../../components/loader/loading";
import Swal from "sweetalert2";
import MUIDataTable from "mui-datatables";
import { columns } from "./columns";
import api from "../../../services/api";
import { Prefix } from "../../../services/prefix";
import { useFetch } from "../../../components/hooks/useFetch";
import CustomSearchRender from "../../../components/customSearchRender/CustomSearchRender";
import CustomFooter from "../../../components/CustomFooter/CustomFooter";
import { mutate as GlobalMutate } from "swr";
import CurrencyInput from "../../../components/hooks/useCurrency";
import { Context } from "../../../services/context";
import { AsyncPaginate } from "react-select-async-paginate";
import { loadOptionsStates } from "./loadOptionsStates";
import { loadOptions } from "../../../components/selectAsync/loadOptions";
import { colourStyles } from "../../../components/selectAsync/colourStyles";
import Select from "react-select";
import { ModalContainer } from "../../../style/stylesModals";
import {
  CustomButton,
  TextButton,
  ButtonDesabled,
} from "../../../style/stylesButton";

export const EquipmentView = ({ id, idClient }) => {
  const [show, setShow] = useState(false);
  const [pagina, setPagina] = useState(0);
  const [perPage, setPerPage] = useState(15);
  const [search, setSearch] = useState("");
  const [loader, setLoader] = useState();
  const [valueSelect, setValueSelect] = useState([]);
  const [formData, setFormData] = useState({
    id_user: id,
    warning: null,
    danger: null,
    dark: null,
  });

  let pageExact = pagina + parseInt(1);

  const alerts = [
    { value: 1, name: "Sim, receber notificação" },
    { value: 0, name: "Não receber notificação" },
  ];

  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    setValueSelect([]);
    setFormData({
      id_user: id,
    });
  };

  const listEquipmentView = useFetch(
    Prefix.replace(/[\\"]/g, "") +
      "/contract-item-views?per_page=" +
      perPage +
      "&page=" +
      pageExact +
      "&search=" +
      search +
      "&id_user=" +
      id
  );

  const listEquipments = useFetch(
    !idClient
      ? null
      : Prefix.replace(/[\\"]/g, "") + "/contract-items?id_customer=" + idClient
  );
  const equipments = listEquipments.data?.models?.data;

  const HandleSelect = (e, name) => {
    if (valueSelect.filter((fill) => fill.nameField == name).length > 0) {
      valueSelect
        .filter((fill) => fill.nameField == name)
        .map((map) => {
          let valueIndice = valueSelect.indexOf(map);
          valueSelect.splice(valueIndice);
        });

      setValueSelect((prev) => {
        return [...prev, { value: e.value, label: e.label, nameField: name }];
      });
      setFormData({ ...formData, [name]: e.value });
    } else {
      setValueSelect((prev) => {
        return [...prev, { value: e.value, label: e.label, nameField: name }];
      });
      setFormData({ ...formData, [name]: e.value });
    }
  };

  const HandleRegister = () => {
    setLoader(<Loading />);
    api
      .post(Prefix.replace(/[\"]/g, "") + "/contract-item-views", formData)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Uow!",
          text: response.data.message,
          confirmButtonText: "Fechar",
        });
        setLoader();
        GlobalMutate(
          Prefix.replace(/[\\"]/g, "") +
            "/contract-item-views?per_page=" +
            perPage +
            "&page=" +
            pageExact +
            "&search=" +
            search +
            "&id_user=" +
            id
        );
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: err.response.data.message,
          confirmButtonText: "Tentar novamente",
        });
        setLoader();
      });
  };

  function HandleDelete(e) {
    setLoader(<Loading />);
    let idArr = [];
    e.map((del) => {
      idArr.push(
        listEquipmentView.data?.models.data?.filter(
          (filtro, index) => index == del.index
        )[0].id
      );
    });

    idArr.map((idDel) =>
      api
        .delete(
          Prefix.replace(/[\\"]/g, "") + "/contract-item-views/" + idDel,
          {
            id: idArr,
          }
        )
        .then((response) => {
          Swal.fire({
            icon: "success",
            title: "Uow!",
            text: response.data.message,
            confirmButtonText: "Fechar",
          });
          setLoader();
          GlobalMutate(
            Prefix.replace(/[\\"]/g, "") +
              "/contract-item-views?per_page=" +
              perPage +
              "&page=" +
              pageExact +
              "&search=" +
              search +
              "&id_user=" +
              id
          );
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: err.response.data.message,
            confirmButtonText: "Tentar novamente",
          });
          setLoader();
        })
    );
  }

  const options = {
    draggableColumns: {
      enabled: true,
    },
    serverSide: true,
    onTableChange: (action, tableState) => {
      setPagina(tableState.page);
      setPerPage(tableState.rowsPerPage);
    },
    onSearchChange: (searchText) => {
      setSearch(!search ? "" : searchText);
    },
    customSearchRender: (searchText, handleSearch, hideSearch, options) => {
      return (
        <CustomSearchRender
          placeholderSearch="Pesquisar Equipamento"
          searchText={searchText}
          onSearch={handleSearch}
          onHide={hideSearch}
          options={options}
        />
      );
    },
    filter: false,
    page: pagina,
    count: listEquipmentView.data?.models.data.total,
    expandableRows: false,
    expandableRowsHeader: false,
    expandableRowsOnClick: false,
    rowsPerPage: perPage,
    rowsPerPageOptions: [15, 30, 100],
    onRowsDelete: (rowsDeleted, newData) => {
      HandleDelete(rowsDeleted.data);
    },
    searchPlaceholder: "Pesquise uma Equipamento",
    selectableRows: true,
    searchOpen: false,

    textLabels: {
      body: {
        noMatch: "Desculpe, não encontramos equipamentos!",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
      pagination: {
        next: "Avançar",
        previous: "Voltar",
        rowsPerPage: "Linhas por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Pesquisar",
        downloadCsv: "Baixar CSV",
        print: "Imprimir",
        viewColumns: "Ver Colunas",
        filterTable: "Filtrar tabela",
      },
      filter: {
        all: "Tudo",
        title: "FILTROS",
        reset: "RESETAR",
      },
      viewColumns: {
        title: "Exibir colunas",
        titleAria: "Exibir/Ocultar colunas da tabela",
      },
      selectedRows: {
        text: "Coluna(s) selecionada(s)",
        delete: "Excluir",
        deleteAria: "Excluir linhas selecionadas",
      },
    },
    customFooter: (
      count,
      page,
      rowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels
    ) => {
      let numberPages = listEquipmentView.data?.models.data.last_page;
      let rowsPerPageOptions = [15, 30, 100];
      return (
        <CustomFooter
          page={page}
          rowsPerPage={rowsPerPage}
          changeRowsPerPage={changeRowsPerPage}
          changePage={changePage}
          numberPages={numberPages}
          rowsPerPageOptions={rowsPerPageOptions}
        />
      );
    },
  };

  const HandleLinkAll = () => {
    equipments.map((map) => {
      api
        .post(Prefix.replace(/[\"]/g, "") + "/contract-item-views", {
          id_user: id,
          id_contract_item: map.id,
          warning: formData.warning,
          danger: formData.danger,
          dark: formData.dark,
        })
        .then((response) => {
          Swal.fire({
            icon: "success",
            title: "Uow!",
            text: response.data.message,
            confirmButtonText: "Fechar",
          });
          setLoader();
          GlobalMutate(
            Prefix.replace(/[\\"]/g, "") +
              "/contract-item-views?per_page=" +
              perPage +
              "&page=" +
              pageExact +
              "&search=" +
              search +
              "&id_user=" +
              id
          );
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: err.response.data.message,
            confirmButtonText: "Tentar novamente",
          });
          setLoader();
        });
    });
  };

  return (
    <>
      <Button variant="success" className="dropdown-item" onClick={handleShow}>
        <Fontawesome classe="fas fa-tools" /> Equipamentos
      </Button>
      <ModalContainer
        show={show}
        size="lg"
        onHide={handleClose}
        backdrop="static"
        keyboard={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h3>Visualização de Equipamento</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loader && <Loading />}
          <form className="customerStep" onSubmit={HandleRegister}>
            <Row>
              <Col>
                <div className="formStep ">
                  <label className="important">Estado</label>
                  <AsyncPaginate
                    value={valueSelect
                      .filter((fill) => fill.nameField == "uf")
                      .map((map) => {
                        return {
                          value: map.value,
                          label: map.label,
                        };
                      })}
                    loadOptions={loadOptionsStates}
                    onChange={(e) => HandleSelect(e, "uf")} //values and name of select paginate
                    placeholder="Selecione Estado..."
                    //Set your param that is going to change when you select another field. Just use this option when you are setting params and need to refresh cache.
                    cacheUniqs={[valueSelect]}
                    styles={colourStyles}
                    additional={{
                      page: 1,
                      endpoint: "/states",
                      params: `id_customer=${idClient}`,
                      //Separete with & (and commercial)
                    }}
                  />
                </div>
              </Col>
              <Col>
                <div className="formStep">
                  <label className="important">Cidade</label>
                  <AsyncPaginate
                    value={valueSelect
                      .filter((fill) => fill.nameField == "id_city")
                      .map((map) => {
                        return {
                          value: map.value,
                          label: map.label,
                        };
                      })}
                    loadOptions={loadOptions}
                    cacheUniqs={[
                      valueSelect
                        .filter((fill) => fill.nameField == "id_city")
                        .map((map) => map.label),
                    ]}
                    onChange={(e) => HandleSelect(e, "id_city")}
                    //values and name of select paginate
                    placeholder="Selecione a Cidade..."
                    styles={colourStyles}
                    additional={{
                      page: 1,
                      endpoint: "/cities",
                      params: `uf=${
                        valueSelect.length == 0
                          ? ""
                          : valueSelect
                              .filter((fill) => fill.nameField == "uf")
                              .map((map) => map.value)
                      }&id_customer=${idClient}&cities_extend_contracts=1`, //Separete with & (and commercial)
                      // paramName: 'nickname'
                    }}
                  />
                </div>
              </Col>
              <Col>
                <div className="formStep ">
                  <label className="important">Unidade</label>
                  <AsyncPaginate
                    value={valueSelect
                      .filter((fill) => fill.nameField == "id_unit")
                      .map((map) => {
                        return {
                          value: map.value,
                          label: map.label,
                        };
                      })}
                    loadOptions={loadOptions}
                    cacheUniqs={[valueSelect]}
                    onChange={(e) => HandleSelect(e, "id_unit")} //values and name of select paginate
                    placeholder="Selecione uma Unidade..."
                    styles={colourStyles}
                    additional={{
                      page: 1,
                      endpoint: "/units",
                      params: `id_customer=${id}&
                      id_city=${
                        valueSelect.length == 0
                          ? ""
                          : valueSelect
                              .filter((fill) => fill.nameField == "id_city")
                              .map((map) => map.value)
                      }`,
                      //Separete with & (and commercial)
                      // paramName:
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="formStep ">
                  <label className="important">Setor</label>
                  <AsyncPaginate
                    value={valueSelect
                      .filter((fill) => fill.nameField == "id_sector")
                      .map((map) => {
                        return {
                          value: map.value,
                          label: map.label,
                        };
                      })}
                    loadOptions={loadOptions}
                    cacheUniqs={[valueSelect]}
                    onChange={(e) => HandleSelect(e, "id_sector")} //values and name of select paginate
                    placeholder="Selecione um Setor..."
                    styles={colourStyles}
                    additional={{
                      page: 1,
                      endpoint: "/sectors",
                      params: `id_customer=${
                        valueSelect.length == 0
                          ? ""
                          : valueSelect
                              .filter((fill) => fill.nameField == "id_customer")
                              .map((map) => map.value)
                      }&id_unit=${
                        valueSelect.length == 0
                          ? ""
                          : valueSelect
                              .filter((fill) => fill.nameField == "id_unit")
                              .map((map) => map.value)
                      }`, //Separete with & (and commercial)
                      // paramName: 'nickname'
                    }}
                  />
                </div>
              </Col>
              <Col>
                <div className="formStep ">
                  <label className="important">Sala</label>
                  <AsyncPaginate
                    value={valueSelect
                      .filter((fill) => fill.nameField == "id_room")
                      .map((map) => {
                        return {
                          value: map.value,
                          label: map.label,
                        };
                      })}
                    loadOptions={loadOptions}
                    cacheUniqs={[valueSelect]}
                    onChange={(e) => HandleSelect(e, "id_room")} //values and name of select paginate
                    placeholder="Selecione uma Sala..."
                    styles={colourStyles}
                    additional={{
                      page: 1,
                      endpoint: "/rooms",
                      params: `id_customer=${id}&id_sector=${
                        valueSelect.length == 0
                          ? ""
                          : valueSelect
                              .filter((fill) => fill.nameField == "id_sector")
                              .map((map) => map.value)
                      }`, //Separete with & (and commercial)
                      // paramName: 'nickname'
                    }}
                  />
                </div>
              </Col>
              <Col>
                <div className="formStep ">
                  <label className="important">Equipamento de Contrato</label>
                  <AsyncPaginate
                    value={valueSelect
                      .filter((fill) => fill.nameField == "id_contract_item")
                      .map((map) => {
                        return {
                          value: map.value,
                          label: map.label,
                        };
                      })}
                    loadOptions={loadOptions}
                    cacheUniqs={[valueSelect]}
                    onChange={(e) => HandleSelect(e, "id_contract_item")} //values and name of select paginate
                    placeholder="Selecione Equipamento..."
                    styles={colourStyles}
                    additional={{
                      page: 1,
                      endpoint: "/contract-items",
                      params: `id_customer=${id}
                      &uf=${
                        valueSelect.length == 0
                          ? ""
                          : valueSelect
                              .filter((fill) => fill.nameField == "uf")
                              .map((map) => map.value)
                      }
                      &id_city=${
                        valueSelect.length == 0
                          ? ""
                          : valueSelect
                              .filter((fill) => fill.nameField == "id_city")
                              .map((map) => map.value)
                      }
                      &id_unit=${
                        valueSelect.length == 0
                          ? ""
                          : valueSelect
                              .filter((fill) => fill.nameField == "id_unit")
                              .map((map) => map.value)
                      }
                      &id_sector=${
                        valueSelect.length == 0
                          ? ""
                          : valueSelect
                              .filter((fill) => fill.nameField == "id_sector")
                              .map((map) => map.value)
                      }
                      &id_room=${
                        valueSelect.length == 0
                          ? ""
                          : valueSelect
                              .filter((fill) => fill.nameField == "id_room")
                              .map((map) => map.value)
                      }`, //Separete with & (and commercial)
                      paramName: "nickname",
                      paramNameObs: "serial",
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="formStep ">
                  <label className="important">Receber alerta médio?</label>
                  <Select
                    value={valueSelect
                      .filter((fill) => fill.nameField == "warning")
                      .map((map) => {
                        return {
                          value: map.value,
                          label: map.label,
                        };
                      })}
                    styles={colourStyles}
                    onChange={(e) => HandleSelect(e, "warning")}
                    placeholder="Selecione..."
                    options={alerts.map((state) => {
                      return { value: state.value, label: state.name };
                    })}
                  />
                </div>
              </Col>
              <Col>
                <div className="formStep ">
                  <label className="important">Receber alerta urgente?</label>
                  <Select
                    value={valueSelect
                      .filter((fill) => fill.nameField == "danger")
                      .map((map) => {
                        return {
                          value: map.value,
                          label: map.label,
                        };
                      })}
                    styles={colourStyles}
                    onChange={(e) => HandleSelect(e, "danger")}
                    placeholder="Selecione..."
                    options={alerts.map((state) => {
                      return { value: state.value, label: state.name };
                    })}
                  />
                </div>
              </Col>
              <Col>
                <div className="formStep ">
                  <label className="important">Receber alerta grave?</label>
                  <Select
                    value={valueSelect
                      .filter((fill) => fill.nameField == "dark")
                      .map((map) => {
                        return {
                          value: map.value,
                          label: map.label,
                        };
                      })}
                    styles={colourStyles}
                    onChange={(e) => HandleSelect(e, "dark")}
                    placeholder="Selecione..."
                    options={alerts.map((state) => {
                      return { value: state.value, label: state.name };
                    })}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex justify-content-end mt-4 mb-3">
                <Button
                type='button'
                  style={{
                    backgroundColor: "#1bc5bd",
                    width: "160px",
                    height: "60px",
                    border: 0,
                    borderRadius: "30px",
                    marginTop: "20px",
                  }}
                  onClick={HandleRegister}
                >
                  Vincular
                </Button>
              </Col>
              <Col className="d-flex justify-content-start mt-4 mb-3">
                {formData.warning === null ||
                formData.danger === null ||
                formData.dark === null ? (
                  <ButtonDesabled disabled>
                    <TextButton>Vincular todos</TextButton>
                  </ButtonDesabled>
                ) : (
                  <CustomButton
                  type='button'
                  onClick={HandleLinkAll}>
                    <TextButton>Vincular todos</TextButton>
                  </CustomButton>
                )}
              </Col>
            </Row>
          </form>{" "}
          <div style={{ position: "relative", zIndex: "0" }}>
            <MUIDataTable
              data={listEquipmentView.data?.models.data}
              columns={columns}
              options={options}
            />
          </div>
          <Row>
            <Col className="d-flex justify-content-center mt-4 mb-3">
              <Button
              type='button'
                style={{
                  backgroundColor: "#1bc5bd",
                  width: "160px",
                  height: "60px",
                  border: 0,
                  borderRadius: "30px",
                  marginTop: "20px",
                }}
                onClick={handleClose}
              >
                Voltar
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </ModalContainer>
    </>
  );
};
export default EquipmentView;
