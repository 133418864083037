import React, { useState } from 'react';
import SidebarSisboard from '../../../components/sidebar/sidebar';
import Topbar from '../../../components/topbar/topbar';
import Card from 'react-bootstrap/Card';
import Fontawesome from '../../../components/fontawesome/fontawesome';
import './alerts.css';
import { useFetch } from '../../../components/hooks/useFetch';
import { Prefix } from '../../../services/prefix';
import Loading from '../../../components/loader/loading';
import MUIDataTable from 'mui-datatables';
import { columns } from './columns.jsx';
import FilterAlerts from './filterAlerts';
import CustomFooter from '../../../components/CustomFooter/CustomFooter';
import ReportsAlerts from './reportsAlerts';
import {
  CardSt,
  CardHeaderSt,
  IconTitleSt,
  TitleSt,
  TextTitleSt,
  SmallTitleSt,
} from '../../../style/globalStyle';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { GetMuiTheme } from '../../../components/muiTheme/muiTheme';
import moment from 'moment';

export const Alerts = () => {
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(15);
  const [startDate, setStartDate] = useState([
    {
      startDate: null,
      endDate: null,
      key: 'selection',
    },
  ]);
  const [unit, setUnit] = useState('');
  const [sector, setSector] = useState('');
  const [room, setRoom] = useState('');
  const [serial, setSerial] = useState('');
  const [alert, setAlert] = useState('');

  let pageExact = page + parseInt(1);

  const handleStartDate = (e) => {
    setStartDate(e);
  };
  const handleUnit = (e) => {
    setUnit(e);
  };
  const handleSector = (e) => {
    setSector(e);
  };
  const handleRoom = (e) => {
    setRoom(e);
  };
  const handleSerial = (e) => {
    setSerial(e);
  };
  const handleAlert = (e) => {
    setAlert(e);
  };

  let initialDate = !startDate[0].startDate
    ? moment().format('YYYY-MM-DD')
    : startDate[0].startDate.getFullYear() +
    '-' +
    (startDate[0].startDate.getMonth() + 1) +
    '-' +
    startDate[0].startDate.getDate();

  let finalDate = !startDate[0].endDate
    ? moment().format('YYYY-MM-DD')
    : startDate[0].endDate.getFullYear() +
    '-' +
    (startDate[0].endDate.getMonth() + 1) +
    '-' +
    startDate[0].endDate.getDate();

  let localization =
    room !== ''
      ? '&unit=&sector=&room=' + room
      : unit !== '' && sector !== ''
        ? '&unit=&sector=' + sector + '&room='
        : '&unit=' + unit + '&sector=&room=';

  const query = useFetch(
    Prefix.replace(/[\\"]/g, '') +
    '/readings/warnings?page=' +
    pageExact +
    '&per_page=' +
    perPage +
    '&epoch=' +
    initialDate +
    '&type=' +
    alert +
    '&serial=' +
    serial +
    localization,
  );

  const options = {
    draggableColumns: {
      enabled: true,
    },
    serverSide: true,
    search: false,
    onTableChange: (action, tableState) => {
      setPage(tableState.page);
      setPerPage(tableState.rowsPerPage);
    },
    customSearchRender: () => {
      return (
        <FilterAlerts
          startDate={startDate}
          setStartDate={handleStartDate}
          unit={unit}
          sector={sector}
          room={room}
          setUnit={handleUnit}
          setSector={handleSector}
          setRoom={handleRoom}
          serial={serial}
          setSerial={handleSerial}
          alert={alert}
          setAlert={handleAlert}
        />
      );
    },
    filter: false,
    page: page,
    expandableRows: false,
    expandableRowsHeader: false,
    expandableRowsOnClick: false,
    rowsPerPage: perPage,
    rowsPerPageOptions: [15, 30, 100],
    searchPlaceholder: 'Pesquise um alerta',
    selectableRows: 'multiple',
    searchOpen: true,

    textLabels: {
      body: {
        noMatch: 'Desculpe, não encontramos alertas!',
        toolTip: 'Sort',
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
      pagination: {
        next: 'Avançar',
        previous: 'Voltar',
        rowsPerPage: 'Linhas por página:',
        displayRows: 'de',
      },
      toolbar: {
        search: 'Pesquisar',
        downloadCsv: 'Baixar CSV',
        print: 'Imprimir',
        viewColumns: 'Ver Colunas',
        filterTable: 'Filtrar tabela',
      },
      filter: {
        all: 'Tudo',
        title: 'FILTROS',
        reset: 'RESETAR',
      },
      viewColumns: {
        title: 'Exibir colunas',
        titleAria: 'Exibir/Ocultar colunas da tabela',
      },
      selectedRows: {
        text: 'Coluna(s) selecionada(s)',
        delete: 'Excluir',
        deleteAria: 'Excluir linhas selecionadas',
      },
    },

    customFooter: (
      count,
      page,
      rowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels,
    ) => {
      let numberPages = query.data?.model?.last_page;
      let rowsPerPageOptions = [15, 30, 100];
      return (
        <CustomFooter
          page={page}
          rowsPerPage={rowsPerPage}
          changeRowsPerPage={changeRowsPerPage}
          changePage={changePage}
          numberPages={numberPages}
          rowsPerPageOptions={rowsPerPageOptions}
        />
      );
    },
  };

  return (
    <div className="wrapper d-flex align-items-stretch">
      <SidebarSisboard />
      <div id="content">
        <Topbar />
        <div className="content_all">
          {!query.data ? (
            <Loading />
          ) : (
            <CardSt>
              <CardHeaderSt className="d-flex justify-content-between">
                <TitleSt>
                  <IconTitleSt
                    style={{ width: '36px' }}
                    src="../../../media/icons-pages/alerts-page-sis.svg"
                  />
                  <TextTitleSt>
                    Alertas{' '}
                    <SmallTitleSt style={{ marginRight: '8px' }}>
                      Gestão de alertas de equipamentos
                    </SmallTitleSt>
                    <ReportsAlerts />
                  </TextTitleSt>
                </TitleSt>
              </CardHeaderSt>
              <CardSt.Body as="div">
                <CardSt.Text as="div">
                  <MuiThemeProvider theme={GetMuiTheme()}>
                    <MUIDataTable
                      data={query.data?.model?.data}
                      columns={columns}
                      options={options}
                      components={{ Checkbox: () => false }}
                    />
                  </MuiThemeProvider>
                </CardSt.Text>
              </CardSt.Body>
            </CardSt>
          )}
        </div>
      </div>
    </div>
  );
};

export default Alerts;
