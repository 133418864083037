import React, { useState } from "react";
import { useContext } from "react";
import Chart from "react-apexcharts";
import { Row, Col } from "react-bootstrap";
import { useFetch } from "../../../components/hooks/useFetch";
import { Context } from "../../../services/context";
import { Prefix } from "../../../services/prefix";
import { Arrow, ContentArrow } from "./readingSensorStyle";

const ReadingSensor = ({ sensor }) => {
  const { customerActionPlan, setCustomerActionPlan } = useContext(Context);
  const [page, setPage] = useState(1);
  const limiter = 725;

  const urlSensor =
    Prefix.replace(/[\\"]/g, "") +
    "/sismogran/map/items/profile/" +
    sensor +
    "/readings?limiter=" +
    limiter;
  
  const listSensor = useFetch(urlSensor, 30000);
  const isPort = listSensor.data?.models?.logs[0]?.door == undefined|| false ? false : true;

  const colorsLine = isPort
    ? ["#962624", "#85C1E9", "#9B59B6", "#834aad"]
    : ["#962624", "#834aad", "#834aad"];

  const widthLine = isPort ? [1, 1, 3, 1] : [1, 3, 1];

  const serie1 = [
    {
      name: "Máximo",
      type: "line",
      data: listSensor.data?.models?.logs.map((msr) => msr.max),
    },
    {
      name: "Porta",
      type: "area",
      data: listSensor.data?.models?.logs.map((msr) =>
        msr.door == false || msr.door == undefined
          ? null
          : parseFloat(msr.value).toFixed(2)
      ),
    },
    {
      name: "Leitura",
      type: "line",
      data: listSensor.data?.models?.logs.map((msr) =>
        msr.value == null ? null : parseFloat(msr.value).toFixed(2)
      ),
    },
    {
      name: "Mínimo",
      type: "line",
      data: listSensor.data?.models?.logs.map((msr) => msr.min),
    },
  ];

  const serie2 = [
    {
      name: "Máximo",
      type: "line",
      data: listSensor.data?.models?.logs.map((msr) => msr.max),
    },
    {
      name: "Leitura",
      type: "line",
      data: listSensor.data?.models?.logs.map((msr) =>
        msr.value == null ? null : parseFloat(msr.value).toFixed(2)
      ),
    },
    {
      name: "Mínimo",
      type: "line",
      data: listSensor.data?.models?.logs.map((msr) => msr.min),
    },
  ];

  const HandleNext = () => {
    setPage(parseInt(page) - 1);
  };

  const HandlePrevious = () => {
    setPage(parseInt(page) + 1);
  };

  return (
    <>
      <Row>
        <Col>
          <Chart
            options={{
              chart: {
                height: 400,
                zoom: {
                  type: "x",
                  enabled: true,
                  autoScaleYaxis: true,
                },
                events: {
                  markerClick: function (
                    event,
                    chartContext,
                    { seriesIndex, dataPointIndex, config }
                  ) {
                    setCustomerActionPlan(
                      listSensor.data?.models?.logs[dataPointIndex]
                    );
                  },
                },
              },
              plotOptions: {
                pie: {
                  expandOnClick: true,
                },
              },
              stroke: {
                width: widthLine,
              },
              colors: colorsLine,
              xaxis: {
                //definição horizontal para horas.
                categories: listSensor.data?.models?.logs.map((lb) =>
                  lb.epoch == null ? "" : lb.epoch.split("T")[1].split(".")[0]
                ),
              },
            }}
            series={isPort ? serie1 : serie2}
            width="100%"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <ContentArrow>
            <Arrow
              src="/media/icons/lef-arrow.svg"
              alt="Left Arrow"
              onClick={HandlePrevious}
            />
          </ContentArrow>
        </Col>
        <Col className="d-flex justify-content-end">
          {page > 1 && (
            <ContentArrow>
              <Arrow
                src="/media/icons/right-arrow.svg"
                alt="Right Arrow"
                onClick={HandleNext}
              />
            </ContentArrow>
          )}
        </Col>
      </Row>
    </>
  );
};
export default ReadingSensor;
