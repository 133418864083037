import React from 'react';
import Edit from '../../components/users/editusers/indexEditUsers';
import UpdateStatus from '../../components/users/updateStatus/updateStatus';
import UserType from './userType';
import UserAvatar from '../../components/users/changeAvatar/changeAvatar';
import OptionsUsers from '../../components/users/optionsUsers/optionsUsers';

export const columns = [
  {
    //0
    name: 'cover',
    label: 'Avatar',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
          display: 'flex',
          justifyContent: 'center',
        },
      }),
      filter: false,
      sort: false,
      display: true,
      customBodyRender: (value, tableMeta) => {
        let idUser = tableMeta?.rowData[1];
        return <UserAvatar idUser={idUser} />;
      },
    },
  },

  {
    //1
    name: 'id',
    label: '',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      display: 'excluded',
    },
  },
  {
    //2
    name: 'name',
    label: 'Nome',
    options: {
      setCellHeaderProps: () => ({
        style: {
          display: "flex",
          justifyContent: "center",
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: true,
    },
  },

  {
    //3
    name: 'type',
    label: 'Tipo',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      customBodyRender: (value, tableMeta) => {
        return <UserType type={tableMeta.rowData} typeId={3} />;
      },
    },
  },

  {
    //4
    name: 'email',
    label: 'Email',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
    },
  },

  {
    //5
    name: 'active',
    label: 'Status',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
          width: '10%',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: true,
      sort: false,
      print: false,

      customBodyRender: (value, tableMeta) => {
        return (
          <UpdateStatus
            id={tableMeta.rowData[1]}
            page={tableMeta.tableState.page}
            rowsPage={tableMeta.tableState.rowsPerPage}
            search={
              !tableMeta.tableState.searchText
                ? ''
                : tableMeta.tableState.searchText
            }
            activeDefault={value}
            orderBy={tableMeta.tableState.sortOrder.name}
            order={tableMeta.tableState.sortOrder.direction}
          />
        );
      },
    },
  },
  {
    //6
    name: 'options',
    label: 'Opções',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
          width: '10%',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      print: false,
      customBodyRender: (value, tableMeta) => {
        return (
          <OptionsUsers
            id={tableMeta.rowData[1]}
            active={tableMeta.rowData[8]}
          />
        );
      },
    },
  },

  {
    //7
    name: 'id',
    label: 'Editar',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
          width: '5%',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      print: false,

      customBodyRender: (value, tableMeta) => {
        return (
          <Edit
            id={value}
            page={tableMeta.tableState.page}
            rowsPage={tableMeta.tableState.rowsPerPage}
            search={tableMeta.tableState.searchText}
            orderBy={tableMeta.tableState.sortOrder.name}
            order={tableMeta.tableState.sortOrder.direction}
          />
        );
      },
    },
  },

  {
    //8
    name: 'active',
    label: '',
    options: {
      setCellHeaderProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          'text-align': 'center',
        },
      }),
      filter: false,
      sort: false,
      display: 'excluded',
    },
  },
];
