import React, { useState, useEffect } from "react";
import Fontawesome from "../../fontawesome/fontawesome";
import "../schemes.css";
import { Prefix } from "../../../services/prefix";
import Loading from "../../loader/loading";
import Swal from "sweetalert2";
import api from "../../../services/api";
import { Link } from "react-router-dom";
import { useFetch } from "../../hooks/useFetch";
import { mutate as GlobalMutate } from "swr";
import { Col, Row, Button, Modal } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Schema from "./schema";
import { ModalContainer } from "../../../style/stylesModals"; 
import { CustomButton, TextButton } from "../../../style/stylesButton";


const IndexEditSchemes = ({ id, page, rowsPage, search, orderBy, order }) => {
  const [loader, setLoader] = useState();
  const [show, setShow] = useState(false);

  let pageExact = page + parseInt(1);
  let valueSearch = !search? '':search;

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };

  const listLicenca = useFetch(
    !show ? null : Prefix.replace(/[\\"]/g, "") + "/schemes/" + id
  );
  listLicenca.mutate();

  const [registerData, setRegisterData] = useState({});

  useEffect(() => {
    if (listLicenca?.data) {
      setRegisterData({
        name: listLicenca.data?.model?.name,
      });
    }
  }, [listLicenca?.data]);

  const HandleChange = (e) => {
    let eventName = e.target.name;
    let eventValue = e.target.value;
    setRegisterData({
      ...registerData,
      [eventName]: eventValue,
    });
  };

  function HandleRegister(registerData) {
    setLoader(<Loading />);
    api
      .put(Prefix.replace(/[\\"]/g, "") + "/schemes/" + id, registerData)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Uow!",
          text: response.data.message,
          confirmButtonText: "Fechar",
        });
        setShow(false);
        setLoader();
        GlobalMutate(
          Prefix.replace(/[\\"]/g, "") +
            "/schemes?per_page=" +
            rowsPage +
            "&page=" +
            pageExact +
            "&search=" +
            valueSearch +
            "&order_by=" +
            orderBy +
            "&order=" +
            order
        );
        listLicenca.mutate();
      })
      .catch((err) => {
        // setErrorsForm(err.response.data.errors);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: err.response.data.message,
          confirmButtonText: "Tentar novamente",
        });
        setLoader();
      });
  }

  return (
    <>
      <Link to="#" onClick={handleShow} className="link_color_datatable">
        <Fontawesome classe="fas fa-pencil-alt" />
      </Link>

      <ModalContainer
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title><h3>Atualização de Licenças</h3></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik //--> Configuração do Formik
            initialValues={registerData}
            validationSchema={Schema}
            onSubmit={HandleRegister}
            enableReinitialize
          >
            {(
              { values } //--> Captação de valores
            ) => (
              <Form>
                <Row>
                  {loader}
                  <Col>
                    <div className="formStep">
                      <label class="important">Licença</label>
                      <Field
                        type="text"
                        placeholder="Digite uma licença"
                        name="name"
                        defaultValue={values?.name}
                        onChange={HandleChange}
                      />
                      <ErrorMessage
                        name="name"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col className="d-flex justify-content-center">
                    <CustomButton type="submit">
                     <TextButton >Atualizar</TextButton> 
                    </CustomButton>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </ModalContainer>
    </>
  );
};
export default IndexEditSchemes;
