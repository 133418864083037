import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ImageUser from '../image-user';
import ChangePassword from '../changePassword/changePassword';
import OpenModal from './modal/openModal';
import { useFetch } from '../../hooks/useFetch';
import { Prefix } from '../../../services/prefix';

const Account = () => {
  const listUser = useFetch('/user');
  const img =
    Prefix.replace(/[\\"]/g, '') == 'client'
      ? listUser.data?.client.logo
      : listUser.data?.cover;

  return (
    <>
      <div className="headerNotification">
        <h4>Minha conta</h4>
      </div>
      <div className="containerNotification">
        <Row>
          <Col md={3} xs={3}>
            <ImageUser
              classe="image-user"
              file={listUser.data ? img : 'media/avatar.png'}
            />
          </Col>
          <Col
            md={9}
            xs={9}
            className="d-flex justify-content-center align-items-center "
          >
            {localStorage.getItem('@user_mtechsp').replace(/[\\"]/g, '')}
          </Col>
        </Row>
        <Row className="mt-4">
          <Col className="d-flex justify-content-center align-items-center">
            <OpenModal
              iconClass="fas fa-key mx-0"
              buttonLabel="Trocar senha"
              modalSize="sm"
              titleLabel="Troca senha"
              content={<ChangePassword />}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};
export default Account;
